import React from "react"
import { Collections, HeadcountPlan, HeadcountPlanParticipant } from "types/graphql"
import { useHeadcountPlanDatasheet } from "v2/react/hooks/useHeadcountPlanDatasheet"
import { ParticipantBanner } from "./ParticipantBanner"
import { AddNewPositionButton } from "../AddNewPositionButton"
import { HeadcountPlanDatasheet } from "../HeadcountPlanDatasheet"

interface Props {
  headcountPlanId: string
  participantId: string
  displayState: "approved" | "original"
  setDisplayState: (value: "approved" | "original") => void
  headcountPlan: HeadcountPlan | undefined
  participant: HeadcountPlanParticipant | undefined
  dropdownCellCollections: Collections | undefined
  csvDownloadRef: React.RefObject<HTMLButtonElement>
}

export function ParticipantDatasheet({
  headcountPlanId,
  participantId,
  displayState,
  setDisplayState,
  headcountPlan,
  participant,
  dropdownCellCollections,
  csvDownloadRef,
}: Props) {
  const position = participant?.position
  const person = participant?.person
  const isFinalized = !!headcountPlan?.lockedAt
  const hasSubmitted = participant?.status === "complete"
  const newPositionsCount = participant?.planAggregations?.newPositionsCount
  const currentDate = new Date().toISOString().split("T")[0].replace(/-/g, "")
  const csvDownloadName = `${headcountPlan?.name}_${person?.name}_${currentDate}`.replace(/\s/g, "")
  const reportsTo = {
    id: position?.id ?? "",
    name: person?.name ?? "",
    title: position?.title ?? "",
    label: (person?.name || position?.title || position?.id) ?? "",
  }

  const { rows, columns } = useHeadcountPlanDatasheet({
    attributes: headcountPlan?.allowedAttributes ?? [],
    positions:
      (isFinalized && displayState === "approved"
        ? participant?.approvedPositions
        : participant?.allPositionsInPlan) ?? [],
    dropdownCellCollections: dropdownCellCollections ?? {},
    hideOptions: hasSubmitted,
    showStatus: isFinalized && displayState === "original",
    newPositionIdPrefix: headcountPlan?.newPositionIdPrefix,
  })

  return (
    <>
      <ParticipantBanner
        displayState={displayState}
        setDisplayState={setDisplayState}
        headcountPlan={headcountPlan}
        participant={participant}
      />
      {hasSubmitted ? null : (
        <div id="hcp-positions-addbar" className="page-margin-b">
          <div id="hcp-positions-addbar__positions-msg">
            <p>
              <span className="font-bold">
                {"plan_info_position_count".t(
                  "headcount_plan",
                  null,
                  "new positions added",
                  newPositionsCount,
                  [newPositionsCount],
                )}
              </span>
            </p>
          </div>
          <AddNewPositionButton
            primary
            reportsTo={reportsTo}
            positionId={position?.id}
            headcountPlanId={headcountPlanId}
            participantId={participantId}
            startDate={headcountPlan?.startDate ?? ""}
          />
        </div>
      )}
      <HeadcountPlanDatasheet
        csvDownloadRef={csvDownloadRef}
        csvDownloadName={csvDownloadName}
        headcountPlanId={headcountPlanId}
        headcountPlan={headcountPlan}
        participantId={participantId}
        participantPositionId={position?.id}
        rows={rows}
        columns={columns}
        readOnly={hasSubmitted}
      />
    </>
  )
}
