import React from "react"
import { ChangeHandler, RefCallBack } from "react-hook-form"
import { TextInput } from "v2/react/shared/TextInput"

function LocationField({
  name,
  textInputRef,
  defaultValue,
  onChange,
}: {
  name: string
  textInputRef: RefCallBack
  defaultValue: string | null | undefined
  onChange: ChangeHandler
}) {
  return (
    <div className="mb-4 cursor-default">
      <TextInput
        name={name}
        inputRef={textInputRef}
        label={"field_location".t("ats")}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </div>
  )
}

export { LocationField }
