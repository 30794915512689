import React from "react"

interface Props {
  headcountPlanId: string
  logoThumbUrl: string
}

function EntireOrgRow({ headcountPlanId, logoThumbUrl }: Props) {
  return (
    <tr>
      <td>
        <div className="hcp-overview-table__entire-org-label">
          <div className="hcp-overview-table__node-label">
            <div className="hcp-overview-table__entire-org-node-logo">
              <img src={logoThumbUrl} alt="logoThumbUrl" />
            </div>
          </div>
          <a
            href={`/headcount_plans/${headcountPlanId}/owner`}
            className="hcp-overview-table__env-link p-3"
          >
            {"overview_table_entire_organization".t("headcount_plan")}
          </a>
        </div>
      </td>
      <td aria-label="empty" />
      <td aria-label="empty" />
      <td aria-label="empty" />
    </tr>
  )
}

export { EntireOrgRow }
