import React, { useState, useRef, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { parseDate } from "@internationalized/date"
import { isEqual } from "lodash"

import { UrlHelper, setQueryString } from "v2/react/utils/urls"

import type { Option } from "types/graphql"
import type { DateRange } from "react-aria"
import { PositionsOverTimeMetrics, TimelineIntervalTypeEnum } from "types/graphql.enums"

import RootProvider from "v2/react/components/RootProvider"
import { PositionsNav } from "v2/react/components/positions/PositionsNav"
import { ReportControls } from "v2/react/components/positions/reports/PositionsOverTime/ReportControls"
import { ReportTable } from "v2/react/components/positions/reports/PositionsOverTime/ReportTable"
import { NONE_KEY } from "v2/react/constants"

type MetricOption = Omit<Option, "id"> & { id: PositionsOverTimeMetrics }
type IntervalOption = Omit<Option, "id"> & { id: TimelineIntervalTypeEnum }

interface PositionsOverTimeProps {
  intervalOptions: IntervalOption[]
  groupByOptions: Option[]
  metricOptions: MetricOption[]
  initialSelectedInterval: TimelineIntervalTypeEnum
  initialSelectedGroup: string
  initialSelectedMetrics: PositionsOverTimeMetrics[]
  initialStartDate: string
  initialEndDate: string
  timeZone: string
  maxReportDate: string
  minReportDate: string
}

// These metrics only make sense in the context of groups.
const METRICS_REQUIRING_GROUP = [
  PositionsOverTimeMetrics.TransferredIn,
  PositionsOverTimeMetrics.TransferredOut,
]

const PositionsOverTime = (props: PositionsOverTimeProps) => (
  <RootProvider>
    <PositionsOverTimeContent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  </RootProvider>
)

const PositionsOverTimeContent = ({
  intervalOptions,
  groupByOptions,
  metricOptions,
  initialSelectedInterval,
  initialSelectedGroup,
  initialSelectedMetrics,
  initialStartDate,
  initialEndDate,
  timeZone,
  minReportDate,
  maxReportDate,
}: PositionsOverTimeProps) => {
  const { t } = useTranslation()
  const exportRef = useRef<HTMLButtonElement>(null)
  const initialMetricMenuOptions = useMemo(
    () =>
      metricOptions
        .map((option) => ({
          ...option,
          selected: initialSelectedMetrics?.includes(option.id) ?? false,
        }))
        // Moves selected metrics to the top of the list in the order they were provided.
        .sort((a, b) => {
          const aIndex = initialSelectedMetrics?.indexOf(a.id) ?? -1
          const bIndex = initialSelectedMetrics?.indexOf(b.id) ?? -1
          if (aIndex >= 0 && bIndex >= 0) return aIndex - bIndex
          if (aIndex >= 0) return -1
          if (bIndex >= 0) return 1
          return 0
        })
        .filter((option) => {
          const isGroupSelected = initialSelectedGroup && initialSelectedGroup !== NONE_KEY
          return isGroupSelected || !METRICS_REQUIRING_GROUP.includes(option.id)
        }),
    [metricOptions, initialSelectedMetrics, initialSelectedGroup],
  )

  const [dateRange, setDateRange] = useState<DateRange>({
    start: parseDate(initialStartDate),
    end: parseDate(initialEndDate),
  })
  const [selectedInterval, setInterval] = useState(initialSelectedInterval)
  const [selectedGroup, setGroupBy] = useState(initialSelectedGroup)
  const [metricMenuOptions, setMetrics] = useState(initialMetricMenuOptions)
  const maxDate = useMemo(() => parseDate(maxReportDate), [maxReportDate])
  const minDate = useMemo(() => parseDate(minReportDate), [minReportDate])

  const selectedMetrics = metricMenuOptions
    .filter((option) => option.selected)
    .map((option) => option.id)

  const handleGroupByChange = (newGroup: string) => {
    // If no group is selected, then we need to remove metrics that require a
    // group.
    if (newGroup === NONE_KEY) {
      const newMetrics = metricMenuOptions.filter(
        (option) => !METRICS_REQUIRING_GROUP.includes(option.id),
      )
      handleSetMetrics(newMetrics)
    } else if (selectedGroup === NONE_KEY) {
      // If a group is selected after none was selected, then we need to add
      // back the metrics that require a group.
      const metricsToAdd = metricOptions
        .filter((option) => METRICS_REQUIRING_GROUP.includes(option.id))
        .map((option) => ({ ...option, selected: false }))
      const newMetrics = metricMenuOptions.concat(metricsToAdd)
      handleSetMetrics(newMetrics)
    }
    setGroupBy(newGroup)
    setQueryString({ group_by: newGroup === NONE_KEY ? null : newGroup })
  }

  const handleSetInterval = (newInterval: TimelineIntervalTypeEnum) => {
    setInterval(newInterval)
    setQueryString({ interval_type: newInterval })
  }

  const handleSetMetrics = (metricOptions: typeof initialMetricMenuOptions) => {
    setMetrics(metricOptions)
    setQueryString({
      metrics: metricOptions.filter((option) => option.selected).map((option) => option.id),
    })
  }

  // We only set the date range for the query once the user has selected a
  // a date range from the menu or closed the calendar picker.
  const handleDateRangeSelection = (activeDateRange?: DateRange | null) => {
    if (!activeDateRange) return
    if (isEqual(activeDateRange, dateRange)) return
    setDateRange(activeDateRange)
    setQueryString({
      start_date: activeDateRange.start.toString(),
      end_date: activeDateRange.end.toString(),
    })
  }

  return (
    <>
      <PositionsNav
        title={t("v2.position_reports.positions_over_time.title")}
        links={[]}
        parentTitle={t("v2.position_management.header.reports")}
        parentTitleUrl={UrlHelper.positionReportsPath()}
        exportRef={exportRef}
      />
      <div className="page-pad flex-col gap-6 flex">
        <ReportControls
          dateRange={dateRange}
          intervalOptions={intervalOptions}
          selectedInterval={selectedInterval}
          groupByOptions={groupByOptions}
          selectedGroup={selectedGroup}
          metricMenuOptions={metricMenuOptions}
          setMetrics={handleSetMetrics}
          setGroupBy={handleGroupByChange}
          setInterval={handleSetInterval}
          handleDateRangeSelection={handleDateRangeSelection}
          maxDate={maxDate}
          minDate={minDate}
          timeZone={timeZone}
        />
        <ReportTable
          startDate={dateRange.start}
          endDate={dateRange.end}
          selectedInterval={selectedInterval}
          selectedGroup={selectedGroup}
          selectedMetrics={selectedMetrics}
          csvDownloadRef={exportRef}
          timeZone={timeZone}
          maxDate={maxDate}
          minDate={minDate}
        />
      </div>
    </>
  )
}

export type { PositionsOverTimeProps }
export default PositionsOverTime
