import React from "react"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/Tooltip"
import { mergeCurrentSearchParamsWithUrl } from "v2/react/utils/urls"

interface Props {
  csvDownloadRef?: React.RefObject<HTMLButtonElement>
  setRef?: (element: HTMLButtonElement) => void
  className?: string
  disabled?: boolean
  exportUrl?: string
  /**
   * If true, the current search params will be used along with the exportUrl
   * when the export is clicked.
   */
  useCurrentSearchParamsWithExportUrl?: boolean
}

function ExportButton({
  csvDownloadRef,
  setRef,
  className,
  disabled,
  exportUrl,
  useCurrentSearchParamsWithExportUrl,
}: Props) {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (useCurrentSearchParamsWithExportUrl && exportUrl) {
      const newUrl = mergeCurrentSearchParamsWithUrl(exportUrl)
      e.currentTarget.href = newUrl
    }
  }

  return (
    <Tooltip>
      <TooltipTrigger>
        {exportUrl ? (
          <a
            id="export-csv"
            className={cn("btn--large btn--secondary w-11", className, {
              disabled,
            })}
            href={exportUrl}
            aria-disabled={disabled}
            onClick={handleClick}
          >
            <FontAwesomeIcon icon={["far", "download"]} />
          </a>
        ) : (
          <button
            id="export-csv"
            className={cn("btn--large btn--secondary w-11", className)}
            type="button"
            disabled={disabled}
            ref={csvDownloadRef ?? setRef}
          >
            <FontAwesomeIcon icon={["far", "download"]} />
          </button>
        )}
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content--sm">
        <span>{"export".t("defaults")}</span>
      </TooltipContent>
    </Tooltip>
  )
}

export { ExportButton }
