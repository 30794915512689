import React from "react"
import { truncateValue } from "v2/react/utils/strings"
import { Tooltip, TooltipContent, TooltipTrigger } from "../Tooltip"

type Props = {
  value: string
  maxTextLength?: number
}
export function StrikethroughCell({ value, maxTextLength = 45 }: Props) {
  return (
    <div className="strikethrough-cell">
      <div>
        <s className="text-neutral-64">{truncateValue(value, maxTextLength)}</s>
        {value.length > maxTextLength ? (
          <Tooltip placement="bottom">
            <TooltipTrigger style={{ background: "none", color: "rgb(25, 153, 219)" }}>
              Read More
            </TooltipTrigger>
            <TooltipContent className="react-tooltip-content--sm">{value}</TooltipContent>
          </Tooltip>
        ) : null}
      </div>
    </div>
  )
}
