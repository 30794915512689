import React, { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { CareerLadderStat } from "types/graphql"
import { Spinner } from "v2/react/shared/Spinner"

interface Props {
  stats: CareerLadderStat[]
  isLoading: boolean
}

function Stats({ stats, isLoading }: Props) {
  const { t } = useTranslation()

  const items: ReactNode[] = [
    stats.map((stat: CareerLadderStat) => (
      <ColumnRow
        content={t(`v2.career_ladders.stats.${stat.key}.value`, {
          count: formatStat(stat.value),
        })}
        isLoading={isLoading}
        key={stat.key}
        title={t(`v2.career_ladders.stats.${stat.key}.label`)}
      />
    )),
  ]
  return (
    <div className="module-card">
      <div className="module-card__header">
        <div className="module-title">{t("v2.position_types.show.stats")}</div>
      </div>
      <div className="module-card__body border-0 p-0 pb-4">{items}</div>
    </div>
  )
}

const ColumnRow = ({
  title,
  content,
  isLoading,
}: {
  title: string
  content: string
  isLoading: boolean
}) => (
  <div className="border-0 border-t border-solid border-neutral-8 px-4 pt-4 flex">
    <div className="basis-1/2 text-neutral-64">{title}</div>
    <div className="basis-2/2">
      {isLoading && <Spinner style={{ position: "relative", height: "1rem", width: "1rem" }} />}
      {!isLoading && content}
    </div>
  </div>
)

const formatStat = (value: number): number => Math.round(value * 100) / 100 || 0

export { Stats }
