import fp from "lodash/fp"

import { EnhancedNodeInterface, FieldKey } from "v2/redux/slices/NodeSlice/types"
import { Maybe, OrgUnitAttributes, UpdatePersonPositionNodeInput } from "types/graphql"

import {
  FIELD_TO_STATIC_COLLECTION_MAP,
  NONE_KEY_SUFFIX,
} from "v2/redux/slices/GridSlice/gridHelpers/collections"
import { getIdAndNameFromEncoded } from "v2/redux/slices/GridSlice/gridHelpers/orgUnits"
import { maybeGetIDFromUniqueKey } from "v2/react/utils/uniqueKey"

type Attributes = UpdatePersonPositionNodeInput["attributes"]

interface PrepareInput {
  fieldKey: FieldKey | keyof Attributes
  node: EnhancedNodeInterface
  value: Maybe<string>
}

const CastAsIdField = Object.freeze({
  location: "location_id",
  schedule: "schedule_id",
  employee_status: "employee_status_id",
  employee_type: "employee_type_id",
  reports_to: "parent_id",
  position_status: "position_status_id",
})

const matchCustomFieldValue = fp.startsWith("custom_field_")
const matchOrgUnit = fp.startsWith("org_unit_type_")
const matchVariablePay = fp.startsWith("variable_pay_type_")
const matchIdField = fp.has(fp.placeholder, CastAsIdField)
const matchStaticCollectionField = fp.has(fp.placeholder, FIELD_TO_STATIC_COLLECTION_MAP)
const matchAny = fp.always(true)

/**
 * @public
 * @returns an augmented hash that can be passed into the
 *   updatePersonPositionNodeMutation
 */
const preparePersonPositionNodeAttributes = (input: PrepareInput): Attributes =>
  fp.cond<string, Attributes>([
    [matchCustomFieldValue, () => customFieldValueAttributes(input)],
    [matchOrgUnit, () => orgUnitAttributes(input)],
    [matchVariablePay, () => variablePayAttributes(input)],
    [matchIdField, () => idFieldAttributes(input)],
    [matchStaticCollectionField, () => staticCollectionAttributes(input)],
    [matchAny, () => standardAttributes(input)],
  ])(input.fieldKey)

function staticCollectionAttributes({ fieldKey, node, value }: PrepareInput) {
  // Extract the value from the option id.
  // Example: "position_importance_high" => "high"
  let processedValue = maybeGetIDFromUniqueKey(value)

  // In cases where there is a none/blank options chosen, we need to
  // set the value to null.
  if (processedValue === NONE_KEY_SUFFIX) processedValue = null

  return withLockVersion(node, fieldKey, processedValue)
}

function customFieldValueAttributes({ fieldKey, node, value }: PrepareInput) {
  const cfvs = node.custom_field_values_original || []
  const maybeCfv = fp.find(fp.propEq("field_id", fieldKey), cfvs)
  if (!maybeCfv) throw new Error("CustomFieldValue hash not found")

  const attributes = fp.merge(fp.pick(["id"], maybeCfv), {
    value: value || "",
    custom_field_id: maybeCfv.field_id,
  })

  return withLockVersion(node, "custom_field_values_attributes", [attributes])
}

function orgUnitAttributes({ fieldKey, node, value }: PrepareInput) {
  const ous = node.org_units_original || []
  const maybeOu = fp.find(fp.propEq("field_id", fieldKey), ous)
  if (!maybeOu) throw new Error("OrgUnit hash not found")

  const { id, name } = getIdAndNameFromEncoded(value || "")
  const attributes: OrgUnitAttributes = {
    org_unit_type_id: maybeOu.field_id,
    org_unit_id: id,
    org_unit_full_name: name,
  }

  return withLockVersion(node, "org_units_attributes", [attributes])
}

function variablePayAttributes({ fieldKey, node, value }: PrepareInput) {
  const vps = node.variable_pays_original || []
  let maybeVp = fp.find(fp.propEq("field_id", fieldKey), vps)
  if (!maybeVp) {
    // In the case where the variable pay type or variable pay amount columns exist
    // on the grid without the other, and we need to save values to both, use the
    // variable pay metadata of the column that does exist.
    const key = fp.endsWith("type", fieldKey) ? fieldKey.replace(/_type$/, "") : `${fieldKey}_type`
    maybeVp = fp.find(fp.propEq("field_id", key), vps)
  }
  if (!maybeVp) throw new Error("VariablePay hash not found")

  const vpFieldKey = fp.endsWith("_type", fieldKey) ? "pay_type" : "amount"
  const otherField = vpFieldKey === "pay_type" ? "amount" : "pay_type"

  const attributes = fp.merge(fp.pick(["id", otherField], maybeVp), {
    [vpFieldKey]: maybeGetIDFromUniqueKey(value),
    destroy: !value,
    // Strip "_type" suffix to ensure the field_id matches the API's expectations.
    variable_pay_type_id: maybeVp.field_id.replace(/_type$/, ""),
  })

  return withLockVersion(node, "variable_pays_attributes", [attributes])
}

function idFieldAttributes({ fieldKey, node, value }: PrepareInput) {
  return withLockVersion(node, fp.prop(fieldKey, CastAsIdField) as string, value)
}

const standardAttributes = ({ fieldKey, node, value }: PrepareInput) =>
  withLockVersion(node, fieldKey, value)

const withLockVersion = <T>(node: EnhancedNodeInterface, as: string, value: T) => ({
  lock_version: node.lock_version,
  [as]: value,
})

export { preparePersonPositionNodeAttributes }
