import {
  closeSuccessionPlanSidebar,
  openSuccessionPlanSidebar,
  setActiveTab,
} from "v2/redux/slices/PanelSidebarSlice"
import { store } from "v2/redux/store"

/**
 * Provides some utility functions and properties for managing the PanelSidebar
 * (Profile/Position Panel and the Succession Plan Panel).
 */
class PanelSidebar {
  static openSuccessionPlanPanel(positionId) {
    store.dispatch(openSuccessionPlanSidebar({ positionId: positionId.toString() }))
  }
  static closeSuccessionPlanPanel() {
    store.dispatch(closeSuccessionPlanSidebar())
  }
  static setSuccessionPlanPanelTab(tabName) {
    store.dispatch(setActiveTab(tabName))
  }
}

export { PanelSidebar }
