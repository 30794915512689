import React from "react"
import classNames from "classnames"

import type { TabKey } from "v2/redux/slices/VisualizationSlice/types"
import { useAppSelector } from "v2/redux/store"

interface TabSwitchButtonProps {
  active: boolean
  disabled?: boolean
  icon: React.ReactNode
  onClick: (tabKey: TabKey) => void
  tabKey: TabKey
  title: string
}

function TabSwitchButton({ active, disabled, icon, onClick, tabKey, title }: TabSwitchButtonProps) {
  const superPanelOpen = useAppSelector((state) => state.visualization.superPanelOpen)
  const handleClick = () => onClick(tabKey)

  return (
    <li className={classNames("ui-tabs-tab m-0", { "ui-tabs-active": active })}>
      <button
        className={classNames(
          "tab btn tooltip sm:btn--large btn--secondary sm:btn--secondary w-8 sm:w-10",
          {
            "!bg-neutral-3": active && superPanelOpen,
            tooltip: !disabled,
            disabled,
          },
        )}
        onClick={handleClick}
        data-tab={tabKey}
        type="button"
        disabled={disabled}
      >
        {icon}
        <span className="tooltip-content tooltip-content--sm">{title}</span>
      </button>
    </li>
  )
}

export { TabSwitchButton }
