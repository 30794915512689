import React, { ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import { Modal, ModalFooter } from "v2/react/shared/Modal"

interface ConfirmationModalProps {
  handleRemoveLinkedData: (e: ChangeEvent<HTMLInputElement>) => void
  modalTitle: string
  onClose: () => void
  onSave: () => void
  properties: string[]
  removeLinkedData: boolean
}

function ConfirmationModal({
  handleRemoveLinkedData,
  modalTitle,
  onClose,
  onSave,
  properties,
  removeLinkedData,
}: ConfirmationModalProps) {
  const { t } = useTranslation()

  return (
    <Modal
      footer={
        <ModalFooter onClose={onClose} onSave={onSave} saveButtonText={t("v2.defaults.save")} />
      }
      isOpen
      onClose={onClose}
      size="md"
      title={modalTitle}
    >
      <div className="p-6">
        <p className="font-bold">
          {t("v2.position_types.form.update_position_type_confirmation.description")}
        </p>
        <ul>
          {properties.map((property) => (
            <li key={property} className="m-0">
              {t(`v2.position_types.show.${property}`)}
            </li>
          ))}
        </ul>
        <div className="input-group">
          <label htmlFor="true" className="checkbox">
            <input
              type="radio"
              id="true"
              value="true"
              checked={removeLinkedData}
              onChange={handleRemoveLinkedData}
            />
            {t("v2.defaults.yes")}
          </label>
          <label htmlFor="false" className="checkbox">
            <input
              type="radio"
              id="false"
              value="false"
              checked={!removeLinkedData}
              onChange={handleRemoveLinkedData}
            />
            {t("v2.defaults.no")}
          </label>
        </div>
      </div>
    </Modal>
  )
}

export { ConfirmationModal }
