import fp from "lodash/fp"

import { FieldKey } from "v2/redux/slices/NodeSlice/types"
import { GroupRow, NodeRow, RowType, SkeletonNodeRow } from "v2/redux/slices/GridSlice/types"
import { NodeInterface } from "types/graphql"

import { formattedNodeProps } from "v2/redux/slices/NodeSlice/nodeHelpers/nodeProps"

type Lookup<T> = { [key in string]: T }

/** @private */
const mapWithIndex = (func: (index: number, value: string) => string, values: string[]) => {
  const newArray: string[] = []
  let index = 0
  fp.each((value) => {
    newArray.push(func(index, value))
    index += 1
  }, values)

  return newArray
}

/** @returns a node annotated by the key 'rowType' */
const toNodeRow = (node: NodeInterface): NodeRow => ({
  ...node,
  rowType: RowType.Node,
})

/** @returns a group row with its "children" count and color. */
function augmentGroupRow(
  childrenCountLookup: Lookup<number>,
  chartSectionColorLookup: Lookup<string>,
  groupRow: GroupRow,
): GroupRow {
  const childrenCount = fp.propOr(0, groupRow.id, childrenCountLookup)
  const isChartSection = groupRow.fieldKey === "chart_section"
  const color = isChartSection ? chartSectionColorLookup[groupRow.value] : undefined

  return { ...groupRow, rowType: RowType.Group, childrenCount, color }
}

/** @returns a "skeleton" representation of the node row. */
const toSkeletonRow = (node: NodeInterface, groupId?: string): SkeletonNodeRow => ({
  id: node.id,
  data: node.id,
  rowType: RowType.Node,
  groupId,
})

const toGroupPart = (i: number, val: string) => `g${i}(${val})`

/** @returns a group id from groupValues (as strings) */
const groupIdFromGroupValues = (groupValues: string[]) =>
  `group:${fp.join(":", mapWithIndex(toGroupPart, groupValues))}`

/** @returns the group id of node */
const deriveGroupId = (selectedGroups: string[], node: NodeInterface) =>
  groupIdFromGroupValues(formattedNodeProps(selectedGroups as FieldKey[], node))

/**
 * @example
 *   const node = { chart_section: 'HR', title: 'HR Admin', ... }
 *   pickTieredGroupValues(["chart_section", "title"], node)
 *   //=> [ ["HR"], ["HR", "HR Admin"] ]
 * @returns an array of group values, per group "tier", pulled from node
 */
const pickTieredGroupValues = (selectedGroups: string[], node: NodeInterface) => {
  const groupValues = formattedNodeProps(selectedGroups as FieldKey[], node)
  const reducer = (priorTiers: string[][], value: string) => [
    ...priorTiers,
    [...(fp.last(priorTiers) || []), value],
  ]

  return fp.reduce(reducer, [], groupValues)
}

const curriedAugmentGroupRow = fp.curry(augmentGroupRow)
const curriedPickTieredGroupValues = fp.curry(pickTieredGroupValues)

export {
  curriedAugmentGroupRow as augmentGroupRow,
  curriedPickTieredGroupValues as pickTieredGroupValues,
  deriveGroupId,
  groupIdFromGroupValues,
  toNodeRow,
  toSkeletonRow,
}
