import { EntityId, EntityState, PayloadAction } from "@reduxjs/toolkit"
import { NotificationTopic } from "types/graphql"

export type NotificationState = {
  /** Counter used to trigger effects when a batch is "opened" */
  batchOpenedHits: number
  batches: EntityState<{ subjectId: EntityId; count: number }>
  entityEventLogs: EntityState<EntityEvents>
  processing: ProcessingState
}
export type SubscribePayload = {
  topic: NotificationTopic
  localOnly?: boolean
  subjectId: EntityId
}
export type SubscribedAction = PayloadAction<SubscribePayload>
export type UnsubscribedAction = PayloadAction<SubscribePayload>

export type EntityEvents = { subjectId: EntityId; events: any[] }
export type EntityEventOccurred = { subjectId: EntityId; data: any }
export type EntityEventOccurredAction = PayloadAction<EntityEventOccurred>
export type ProcessingRecord = {
  subjectId: EntityId
  status: ProcessingStatus
  current: number
  outOf: number
  log: string[]
}
export type ProcessingState = EntityState<ProcessingRecord>
export type ProcessingUpdatedPayload = { subjectId: EntityId; label?: string } & Partial<
  Pick<ProcessingRecord, "current" | "outOf" | "status">
>
export type ProcessingUpdatedAction = PayloadAction<ProcessingUpdatedPayload>
export type ProcessingIncrementedPayload = { subjectId: EntityId; label?: string; by: number }
export type ProcessingIncrementedAction = PayloadAction<ProcessingIncrementedPayload>
export type ProcessingDoneAction = PayloadAction<{ subjectId: EntityId }>
export enum ProcessingStatus {
  Subscribed = "subscribed",
  Inactive = "inactive",
  Active = "active",
  Done = "done",
}
export type ProcessingAction = PayloadAction<
  SubscribePayload & { topic: NotificationTopic.Processing }
>
export type BatchChangedAction = PayloadAction<{ subjectId: EntityId; count: number }>
