import { NodeInterface, FilterAttributes, Collections } from "types/graphql"
import { FilterType } from "types/graphql.enums"
import { Widget } from "v2/react/components/dashboard/types"
import { NONE_KEY_SUFFIX } from "v2/react/utils/collections"

type Config = {
  [key in Widget]: {
    columns: (keyof NodeInterface)[]
    filterOptions: (keyof Collections)[]
    baseFilters: FilterAttributes[]
  }
}

const DEFAULT_GROUP_BY = "location"
const PLACEHOLDER_KEY_SUFFIX = "placeholder-key"
const STATIC_OPTIONS = [
  "location",
  "chart_section",
  "employee_type",
  "job_family",
  "position_importance",
  "position_status",
]

const config: Config = {
  vacancyRate: {
    columns: [
      "system_identifier",
      "title",
      "reports_to",
      "chart_section",
      "open_since",
      "open_for",
      "position_status",
      "position_importance",
      "hiring_priority",
      "projected_hire_date",
    ],
    filterOptions: [
      "positionStatuses",
      "chartSections",
      "locations",
      "employeeTypes",
      "jobFamilies",
      "positionImportances",
      "orgUnitTypes",
    ],
    // This filter effectively ensures that
    // the query returns open positions.
    baseFilters: [
      {
        type: FilterType.Inclusion,
        field: "name",
        value: { in: [NONE_KEY_SUFFIX] },
      },
    ],
  },
}

export { config, DEFAULT_GROUP_BY, PLACEHOLDER_KEY_SUFFIX, STATIC_OPTIONS }
