import React, { useCallback, useState } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"

import RootProvider from "v2/react/components/RootProvider"
import { AdpChangeBatch } from "types/graphql"
import { Dropdown } from "v2/react/shared/Dropdown"
import { InlineSpinner } from "v2/react/shared/Spinner"
import {
  isBusy,
  isPausedWithErrors,
  useAdpInteractiveChangeBatchesQuery,
  useAdpProcessQueuedChangeRequestsMutation,
} from "v2/redux/GraphqlApi/AdpChangeQueueApi"
import {
  selectChangeBatchesOverview,
  useScreenStackForAdpChangeModal,
} from "v2/redux/slices/AdpChangeSlice"
import { useAppSelector } from "v2/redux/store"
import {
  useBatchNotifications,
  useEffectWhenBatchOpened,
  useProcessingNotifications,
} from "v2/redux/slices/NotificationSlice"

import { BusyState } from "./AdpIntegrationStatusBadge/BusyState"
import { GeneralStateContent } from "./AdpIntegrationStatusBadge/GeneralState"
import { MemoedChangeBatchScreenStackModal } from "./ChangeBatchScreenStackModal"
import {
  PausedWithErrorsBadgeDropdownTrigger,
  PausedWithErrorsContent,
} from "./AdpIntegrationStatusBadge/PausedWithErrors"
import { ScreenStackProvider } from "./ChangeBatchScreenStackModal/ScreenStackProvider"
import { Status } from "./types"

export type AdpIntegrationStatusBadgeProps = {
  className?: string
  contentOnly?: boolean
}

type TriggerTextContentProps = {
  batch?: AdpChangeBatch
  isLoading: boolean
  isMutating: boolean
  percent: number
  t: ReturnType<typeof useTranslation>["t"]
  unsentChangeCount: number
}

const StatusClass = {
  [Status.Caution]: "btn--caution-light",
  [Status.Excite]: "btn--excite-light",
  [Status.Info]: "btn--info-light",
  [Status.Success]: "btn--success-light",
}

const getStatus = (batch: AdpChangeBatch | undefined, isLoading: boolean, inSync: boolean) => {
  if (batch && isPausedWithErrors(batch)) return Status.Caution
  if (isLoading) return Status.Info
  if (!inSync) return Status.Excite
  return Status.Success
}

function TriggerTextContent({
  batch,
  isLoading,
  isMutating,
  t,
  percent,
  unsentChangeCount,
}: TriggerTextContentProps) {
  if (isLoading) return <InlineSpinner />

  if (batch && isPausedWithErrors(batch))
    return <PausedWithErrorsBadgeDropdownTrigger batch={batch} />

  if (isMutating) {
    return (
      <>
        <FontAwesomeIcon icon={["far", "sync-alt"]} spin size="1x" />
        {t("v2.adp.change_requests.sync_progress", { count: unsentChangeCount, percent })}
        <FontAwesomeIcon icon={["fas", "caret-down"]} size="1x" />
      </>
    )
  }

  return (
    <>
      {t("v2.adp.change_requests.sync_changes_call_to_action", { count: unsentChangeCount })}
      <FontAwesomeIcon icon={["fas", "caret-down"]} size="1x" />
    </>
  )
}

function AdpIntegrationStatusBadgeInner({
  className,
  contentOnly,
}: AdpIntegrationStatusBadgeProps) {
  const { closeStackModal, isStackOpen, pushScreens } = useScreenStackForAdpChangeModal()
  const { open, active } = useAppSelector(selectChangeBatchesOverview)
  const realTimeRecord = useBatchNotifications({ subjectId: open?.id ?? "NONE" })
  const { isFetching, isLoading, refetch } = useAdpInteractiveChangeBatchesQuery()
  useEffectWhenBatchOpened(refetch)

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const handleToggleDropdown = () => setIsDropdownOpen((val) => (isLoading ? false : !val))
  const handleToggleDropdownClose = () => setIsDropdownOpen(false)

  const handleReviewChangesClick = () => {
    if (!open) return

    pushScreens([{ screenKey: "TableOfBatches" }, { screenKey: "Batch", batch: open }])
  }
  const handleViewChangeLogClick = () => pushScreens([{ screenKey: "TableOfBatches" }])
  const handleViewDetailsClick = () => {
    const activeOrOpen = active ?? open
    if (!activeOrOpen) return

    pushScreens([{ screenKey: "TableOfBatches" }, { screenKey: "Batch", batch: activeOrOpen }])
  }

  useProcessingNotifications({ subjectId: open?.id })
  const { processing, percent } = useProcessingNotifications({
    subjectId: active?.id,
    onProcessingDone: refetch,
  })

  const [mutate, mutationState] = useAdpProcessQueuedChangeRequestsMutation()
  const isMutating = processing || mutationState.isLoading || !!(active && isBusy(active))
  const isNetworkBusy = isFetching || isMutating
  const openId = open?.id
  const handleSendChangesPress = useCallback(() => {
    if (openId) mutate({ changeBatchId: openId })
    closeStackModal()
  }, [closeStackModal, openId, mutate])

  const unsentChangeCount = realTimeRecord?.count ?? (active ?? open)?.changeRequests.length ?? 0
  const inSync = unsentChangeCount === 0
  const status = getStatus(active ?? open, isLoading, inSync)
  const styles = classNames(
    "status-badge font-bold btn rounded-3xl btn--xs btn--no-hover text-sm",
    StatusClass[status],
  )

  const { t } = useTranslation()

  const dropdownContent = (
    <>
      {active && isBusy(active) ? (
        <BusyState
          onReviewChangesClick={handleViewDetailsClick}
          percent={percent}
          contentOnly={contentOnly}
        />
      ) : null}
      {active && isPausedWithErrors(active) ? (
        <PausedWithErrorsContent
          batch={active}
          onViewDetailsPress={handleViewDetailsClick}
          contentOnly={contentOnly}
        />
      ) : null}
      {!active || (!isBusy(active) && !isPausedWithErrors(active)) ? (
        <GeneralStateContent
          inSync={inSync}
          isMutating={isMutating}
          isNetworkBusy={isNetworkBusy}
          onReviewChangesClick={handleReviewChangesClick}
          onSendChangesPress={handleSendChangesPress}
          onViewChangeLogClick={handleViewChangeLogClick}
          status={status}
          unsentChangeCount={unsentChangeCount}
          contentOnly={contentOnly}
        />
      ) : null}
    </>
  )

  return (
    <>
      {contentOnly ? (
        dropdownContent
      ) : (
        <Dropdown
          className={className}
          contentWrapperClassName="text-left whitespace-normal w-[20rem] mt-1"
          ignoreOutsideClick={isStackOpen}
          isOpen={isDropdownOpen}
          toggleOpen={handleToggleDropdown}
          toggleClose={handleToggleDropdownClose}
          triggerButtonClasses={styles}
          triggerTextContent={
            <TriggerTextContent
              batch={active ?? open}
              isLoading={isLoading}
              isMutating={isMutating}
              percent={percent}
              t={t}
              unsentChangeCount={unsentChangeCount}
            />
          }
        >
          {dropdownContent}
        </Dropdown>
      )}
      {isStackOpen && (
        <MemoedChangeBatchScreenStackModal
          isMutating={isMutating}
          isOpen={isStackOpen}
          onSendChangesPress={handleSendChangesPress}
        />
      )}
    </>
  )
}

export function AdpIntegrationStatusBadge({
  className,
  contentOnly,
}: AdpIntegrationStatusBadgeProps) {
  const canWriteChangesToAdp = useAppSelector((state) => state.session.canWriteChangesToAdp)
  if (!canWriteChangesToAdp) return null

  return (
    <ScreenStackProvider>
      <AdpIntegrationStatusBadgeInner className={className} contentOnly={contentOnly} />
    </ScreenStackProvider>
  )
}

export function AdpIntegrationStatusBadgeStandalone() {
  return (
    <RootProvider>
      <AdpIntegrationStatusBadge />
    </RootProvider>
  )
}
