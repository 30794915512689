import {
  OffsetOptions,
  ReferenceType,
  UseFloatingOptions,
  autoUpdate,
  flip,
  offset,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from "@floating-ui/react"
import { useRef, useState } from "react"

interface AutocompleteProps {
  floatOverrides?: Partial<UseFloatingOptions<ReferenceType>>
  listWidth?: number
  offsetOptions?: OffsetOptions
  showList: boolean
  setShowList: React.Dispatch<React.SetStateAction<boolean>>
}

const useAutoComplete = ({
  floatOverrides,
  listWidth,
  offsetOptions,
  showList,
  setShowList,
}: AutocompleteProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const listRef = useRef<Array<HTMLElement | null>>([])

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open: showList,
    onOpenChange: setShowList,
    middleware: [
      flip({ padding: 10 }),
      offset(offsetOptions),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${listWidth ?? rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          })
        },
        padding: 10,
      }),
    ],
    ...floatOverrides,
  })

  const role = useRole(context, { role: "listbox" })
  const dismiss = useDismiss(context)
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    role,
    dismiss,
    listNav,
  ])

  return {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  }
}

export { useAutoComplete }
