showTitleChangeNotice = (initialTitle, selectedTitle, attributesToChangeHash) ->
  $notice = $('#title-change-notice')
  $description = $notice.find("#title-change-notice-description")
  $description.find(".old-title").text(initialTitle)
  $description.find(".new-title").text(selectedTitle)
  $dataList = $notice.find("#title-change-notice-data-list")
  entries = _.sortBy Object.entries(attributesToChangeHash), (entry) -> entry[0]
  _.each entries, (entry) ->
    $dataList.append("<li>#{entry[0]}: #{entry[1]}</li>")
  $notice.removeClass('hidden')

hideTitleChangeNotice = ->
  $('#title-change-notice').addClass('hidden')

events = (scope) ->
  $titleInput = $(scope).find('#position_title')
  initialTitle = $(scope).find('#title-change-notice').data('initial-title')

  $titleInput.off 'autocomplete:inputChanged'
    .on 'autocomplete:inputChanged', (e) ->
      hideTitleChangeNotice()

  $titleInput.off 'autocomplete:selectSuggestion'
    .on 'autocomplete:selectSuggestion', (e, selection) ->
      $selected = $(selection)
      selectedTitle = $selected.data('title')
      return if selectedTitle == initialTitle
      attributesToChangeHash = $selected.data('set-attributes')
      return unless Object.keys(attributesToChangeHash).length > 0
      showTitleChangeNotice(initialTitle, selectedTitle, attributesToChangeHash)

export { events }
