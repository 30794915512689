import React from "react"

import RootProvider from "v2/react/components/RootProvider"
import { LinkBlockSmall } from "v2/react/components/navigation/PageNav/LinkBlock"
import PageNav from "v2/react/components/navigation/PageNav"
import {
  TitleBlock,
  TitleHeaderWithParent,
} from "v2/react/components/navigation/PageNav/TitleBlock"
import { Link, LinkGroup } from "v2/react/shared/PageNavigation/LinkGroup"
import { AdpIntegrationStatusBadgeStandalone } from "v2/react/components/adp/AdpIntegrationStatusBadge"
import { ActionBlock } from "v2/react/components/navigation/PageNav/ActionBlock"
import { ExportButton } from "v2/react/shared/ExportButton"
import { ImportExportButton } from "v2/react/shared/ImportExportButton"
import { ImportButton } from "v2/react/shared/ImportButton"
import { ExportButtonMenu } from "v2/react/shared/ExportButtonMenu"
import type { ExportUrl } from "v2/react/shared/ExportButtonMenu"

interface Props {
  links: Link[]
  parentTitle: string | null
  parentTitleUrl?: string
  title: string
  exportRef?: React.RefObject<HTMLButtonElement>
  /**
   * Some position pages use a backend endpoint to export data, for these we
   * provide the exportUrl.
   */
  exportUrl?: string
  /**
   * Some position pages use multiple backend endpoints to export data, for these we
   * provide the exportUrls.
   */
  exportUrls?: ExportUrl[]
  /**
   * Some position pages use a backend endpoint to import data, for these we
   * provide the importUrl.
   */
  importUrl?: string
  useCurrentSearchParamsWithExportUrl?: boolean
}

function PositionsNav({
  links,
  parentTitle,
  parentTitleUrl,
  title,
  exportRef,
  exportUrl,
  exportUrls,
  importUrl,
  useCurrentSearchParamsWithExportUrl,
}: Props) {
  return (
    <RootProvider>
      <PageNav>
        <TitleBlock>
          {parentTitle ? (
            <TitleHeaderWithParent
              parentTitle={parentTitle}
              parentTitleUrl={parentTitleUrl}
              title={title}
            />
          ) : (
            <h1>{title}</h1>
          )}
        </TitleBlock>
        <LinkBlockSmall>
          <LinkGroup links={links} />
        </LinkBlockSmall>
        <ActionBlock>
          <AdpIntegrationStatusBadgeStandalone />
          {exportRef && <ExportButton csvDownloadRef={exportRef} />}
          {exportUrls && (
            <ExportButtonMenu
              exportUrls={exportUrls}
              useCurrentSearchParamsWithExportUrl={useCurrentSearchParamsWithExportUrl}
            />
          )}
          {exportUrl && !importUrl && (
            <ExportButton
              exportUrl={exportUrl}
              useCurrentSearchParamsWithExportUrl={useCurrentSearchParamsWithExportUrl}
            />
          )}
          {!exportUrl && importUrl && <ImportButton importUrl={importUrl} />}
          {exportUrl && importUrl && (
            <ImportExportButton
              exportUrl={exportUrl}
              importUrl={importUrl}
              useCurrentSearchParamsWithExportUrl={useCurrentSearchParamsWithExportUrl}
            />
          )}
        </ActionBlock>
      </PageNav>
    </RootProvider>
  )
}

export { PositionsNav }
