import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { useState } from "react"
import { CodeBlock, tomorrowNight } from "react-code-blocks"
import { preventPropagationOfSyntheticEvent, useOutsideClick } from "v2/react/hooks/useOutsideClick"

interface Props {
  classes?: string
}

function DropdownMenu({ classes }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useOutsideClick<HTMLDivElement>(() => setIsOpen(false))

  return (
    <div
      role="button"
      tabIndex={0}
      className="dropdown"
      onClick={preventPropagationOfSyntheticEvent}
      onKeyDown={preventPropagationOfSyntheticEvent}
      ref={dropdownRef}
    >
      <button
        type="button"
        className="btn btn--ghost dropdown-link"
        onClick={() => setIsOpen(!isOpen)}
      >
        <FontAwesomeIcon icon={["far", "ellipsis-v"]} />
      </button>
      <div className={classNames("dropdown-menu", classes, { open: isOpen })}>
        <div className="dropdown-menu-link">Move</div>
        <div className="dropdown-menu-link">Copy</div>
        <div className="dropdown-menu-link">Delete</div>
      </div>
    </div>
  )
}

function Dropdowns() {
  return (
    <div>
      <h1>Dropdowns</h1>
      <div className="mb-4">
        <p>
          Dropdown menus are a commonly used component to allow the user to choose from a list of
          options by clicking or hovering over a specific area. These typically appear as a button,
          and when activated, display a list of choices to select from. The primary use case for
          drop-down menus is to provide a space-efficient way to present a set of related options or
          actions. This enhances user experience by organizing and simplifying navigation.
        </p>
        <p>
          In React, you will have to supply a boolean value to change the <code>open</code> class.
          Note, that to have the dropdown close when clicking outside, you will also want to utilize
          the <code>useOutsideClick</code> hook and supply the <code>dropdown</code> element with a
          ref and a click event to prevent event propagation.
        </p>
        <p>
          In a <code>slim</code> file, event listeners are already in place to handle that logic for
          you.
        </p>
      </div>

      <section>
        <h2 className="py-4">Basic Example</h2>

        <div className="items-center gap-4 flex">
          <p>Settings</p>
          <DropdownMenu />
        </div>
        <div className="col-span-5 py-4 font-mono text-base">
          <CodeBlock text={basicExample} theme={tomorrowNight} language="javascript" />
        </div>
      </section>

      <section>
        <h2 className="py-4">Alignment Examples</h2>
        <p className="mb-4">
          The alignment of the dropdown can be altered by adding an additional class to the
          dropdown.
          <br /> To display the dropdown above, simply add <code>display-above</code> to the{" "}
          <code>dropdown-menu</code> element. <br /> Alternatively, to right-align the dropdown, add{" "}
          <code>dropdown-menu-right</code> to the <code>dropdown-menu</code> element.
        </p>

        <div className="items-center gap-8 flex">
          <div className="items-center gap-4 flex">
            <p>Top Alignment</p>
            <DropdownMenu classes="display-above" />
          </div>
          <div className="items-center gap-4 flex">
            <p>Right Alignment</p>
            <DropdownMenu classes="dropdown-menu-right" />
          </div>
        </div>
        <div className="col-span-5 py-4 font-mono text-base">
          <CodeBlock text={alignmentExamples} theme={tomorrowNight} language="javascript" />
        </div>
      </section>
    </div>
  )
}

export default Dropdowns

const basicExample = `<div className="dropdown" onClick={preventPropagationOfSyntheticEvent} ref={dropdownRef}>
  <button className="btn btn--ghost dropdown-link" onClick={() => setIsOpen(!isOpen)}>
    <FontAwesomeIcon icon={["far", "ellipsis-v"]} />
  </button>
  <div className={classNames("dropdown-menu", { open: isOpen })}>
    <div className="dropdown-menu-link">Move</div>
    <div className="dropdown-menu-link">Copy</div>
    <div className="dropdown-menu-link">Delete</div>
  </div>
</div>`

const alignmentExamples = `...
  <div className={classNames("dropdown-menu display-above", { open: isOpen })}>
    // dropdown menu content here
  </div>
...
  <div className={classNames("dropdown-menu dropdown-menu-right", { open: isOpen })}>
    // dropdown menu content here
  </div>
...`
