import React, { ChangeEvent, FormEvent, useEffect, useState, useCallback } from "react"
import { isEqual } from "lodash/fp"
import cn from "classnames"
import { useTranslation } from "react-i18next"

import { useAppDispatch, useAppSelector } from "v2/redux/store"
import { asyncPatchPreferences } from "v2/redux/slices/ContainerSlice/containerThunks"
import {
  selectDataOptions,
  selectChartSettings,
} from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useDisplayFieldOptions } from "v2/react/components/orgChart/SuperPanel/DataOptionsTab/hooks/useDisplayFieldOptions"

import type { FieldKey } from "v2/redux/slices/NodeSlice/types"
import type { DisplayFieldOption } from "types/graphql"
import type { FieldsetWithSearchProps } from "v2/react/shared/FieldsetWithSearch"

import ClosePanelButton from "v2/react/components/orgChart/SuperPanel/ClosePanelButton"
import { DrawerFooter } from "v2/react/components/orgChart/SuperPanel/DrawerFooter"
import { toggleSuperPanelFooter } from "v2/redux/slices/VisualizationSlice"
import { FieldsetWithSearch } from "v2/react/shared/FieldsetWithSearch"

type OnCheckboxChange = FieldsetWithSearchProps<DisplayFieldOption>["onCheckboxChange"]

function DataOptionsTab() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [formKey, setFormKey] = useState(0)
  const initialDataOptionsState = useAppSelector(selectDataOptions)
  const [dataOptionsState, setDataOptionsState] = useState(initialDataOptionsState)
  const { childrenCount, countDottedRelationships, countOpenPositions, selectedDisplayFields } =
    dataOptionsState

  const { selectedTab, superPanelOpen } = useAppSelector(selectChartSettings)
  const superPanelFooter = useAppSelector((state) => state.visualization.superPanelFooter)
  const selectedChartType = useAppSelector((state) => state.visualization.selectedChartType)
  const isListView = selectedChartType === "lists"

  const { basicDisplayFieldOptions, advancedDisplayFieldOptions, advancedFieldMap, basicFieldMap } =
    useDisplayFieldOptions()

  const [visibleAdvancedFields, setVisibleAdvancedFields] = useState<DisplayFieldOption[]>(
    selectedDisplayFields.map((fieldKey) => advancedFieldMap[fieldKey]).filter(Boolean),
  )
  const [hiddenAdvancedFields, setHiddenAdvancedFields] = useState<DisplayFieldOption[]>(
    advancedDisplayFieldOptions.filter((field) => !visibleAdvancedFields.includes(field)),
  )
  const selectedBasicFieldKeys = selectedDisplayFields
    .filter((field) => field in basicFieldMap)
    .map((field) => field as FieldKey)

  /**
   * This compares the local dataOptionsState values to the store values.
   * If the local dataOptionsState has been changed back to its original state,
   * then we want to hide the footer again instead
   */
  useEffect(() => {
    if (superPanelFooter && isEqual(initialDataOptionsState, dataOptionsState)) {
      dispatch(toggleSuperPanelFooter(false))
    } else if (!superPanelFooter && !isEqual(initialDataOptionsState, dataOptionsState)) {
      dispatch(toggleSuperPanelFooter(true))
    }
  }, [dispatch, initialDataOptionsState, dataOptionsState, superPanelFooter])

  const resetOptionsForm = useCallback(() => {
    setVisibleAdvancedFields(
      initialDataOptionsState.selectedDisplayFields
        .map((field) => advancedFieldMap[field])
        .filter(Boolean),
    )
    setDataOptionsState(initialDataOptionsState)
    // We increment the form key in order to remount the form aspect of this
    // component, which will reset the form checkboxes to the initial state.
    // This is necessary b/c the advanced fields checkboxes aren't directly
    // controlled.
    setFormKey((prev) => prev + 1)
  }, [initialDataOptionsState, advancedFieldMap])

  /**
   * In the case where the tab is no longer selected or open, we reset the data.
   */
  useEffect(() => {
    if (selectedTab !== "tab-data-options" || !superPanelOpen) {
      resetOptionsForm()
    }
  }, [selectedTab, superPanelOpen, resetOptionsForm])

  const handleReorder = (updatedItems: DisplayFieldOption[]) => {
    setVisibleAdvancedFields(updatedItems)
    const newAdvancedDisplayFieldsOrder = updatedItems
      .filter((field) => selectedDisplayFields.includes(field.id as FieldKey))
      .map((field) => field.id as FieldKey)

    setDataOptionsState((prev) => ({
      ...prev,
      selectedDisplayFields: selectedBasicFieldKeys.concat(newAdvancedDisplayFieldsOrder),
    }))
  }

  const saveDataOptions = async (e: FormEvent) => {
    e.preventDefault()

    const preferences = {
      display_fields: selectedDisplayFields,
      children_count: childrenCount,
      count_dotted_relationships: countDottedRelationships,
      count_open_positions: countOpenPositions,
    }

    await dispatch(asyncPatchPreferences(preferences))
    window.location.reload()
  }

  const setSelectedDisplayFields: OnCheckboxChange = (e) => {
    const { checked, value } = e.target
    const name = value
    const isBasicField = basicDisplayFieldOptions.some((field) => field.id === name)
    const numBasicFieldsChecked = basicDisplayFieldOptions.filter((field) =>
      selectedDisplayFields.includes(field.id as FieldKey),
    ).length

    if (checked) {
      const indexOfCheckedField = isBasicField
        ? basicDisplayFieldOptions.indexOf(basicFieldMap[name])
        : visibleAdvancedFields.indexOf(advancedFieldMap[name]) + numBasicFieldsChecked

      const newSelectedDisplayFieldsOrder = [
        ...selectedDisplayFields.slice(0, indexOfCheckedField),
        name as FieldKey,
        ...selectedDisplayFields.slice(indexOfCheckedField),
      ]

      setDataOptionsState((prev) => ({
        ...prev,
        selectedDisplayFields: newSelectedDisplayFieldsOrder,
      }))
    } else {
      setDataOptionsState((prev) => ({
        ...prev,
        selectedDisplayFields: prev.selectedDisplayFields.filter((field) => field !== name),
      }))
    }
  }

  const setOptionCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setDataOptionsState((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }))
  }

  const setSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setDataOptionsState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  const handleSearchSelect = (field: DisplayFieldOption) => {
    setVisibleAdvancedFields((prevFields) => [...prevFields, field])
    setHiddenAdvancedFields((prevFields) => prevFields.filter((f) => f.id !== field.id))
    setDataOptionsState((prev) => ({
      ...prev,
      selectedDisplayFields: [...prev.selectedDisplayFields, field.id as FieldKey],
    }))
  }

  const makeLabel = (field: DisplayFieldOption) => (
    <div className="justify-between flex">
      <div className="min-w-0 break-words">{field.label}</div>
      {field.admin && (
        <div className="!ml-2 mt-0.5 flex-shrink-0 break-normal text-sm text-neutral-64">
          {t("v2.general_settings.admin_only")}
        </div>
      )}
    </div>
  )

  return (
    <div id="tab-data-options" className="drawer-contents panel grid-rows-[auto_1fr] grid">
      <div className="drawer-header">
        <p className="drawer-title">{t("v2.orgchart.utilitynav.data_options")}</p>
        <ClosePanelButton />
      </div>
      <form className="h-full overflow-auto" key={formKey}>
        <div className={cn("drawer-section-content p-3", { "!pb-14": superPanelFooter })}>
          <div className="input-group">
            <fieldset className="mb-0">
              <legend className="mb-1 text-base-bold">
                {t("v2.orgchart.super_panel.show_hide_basic_data")}
              </legend>
              {basicDisplayFieldOptions.map((field) => (
                <label htmlFor={`${field.id}ID`} className="checkbox" key={field.id}>
                  <input
                    name={field.id}
                    className="basic-data-field"
                    type="checkbox"
                    id={`${field.id}ID`}
                    data-show-field={field.id}
                    value={field.id}
                    checked={!!selectedDisplayFields.includes(field.id as FieldKey)}
                    onChange={setSelectedDisplayFields}
                    autoComplete="off"
                  />
                  {field.label}
                </label>
              ))}
            </fieldset>
          </div>
          <div className="input-group">
            <FieldsetWithSearch<DisplayFieldOption>
              id="advanced-display-options"
              label={t("v2.orgchart.super_panel.show_hide_advanced_data")}
              description={t("v2.orgchart.super_panel.show_hide_advanced_data_description")}
              selectedItems={visibleAdvancedFields}
              searchableItems={hiddenAdvancedFields}
              onReorder={handleReorder}
              onSearchSelect={handleSearchSelect}
              makeLabel={makeLabel}
              onCheckboxChange={setSelectedDisplayFields}
            />
          </div>

          {!isListView && (
            <div id="span_of_control_totals" className="input-group">
              <label htmlFor="childrenCount">
                {t("v2.orgchart.super_panel.span_of_control_totals")}
              </label>
              <div className="select">
                <select
                  id="childrenCount"
                  name="childrenCount"
                  value={childrenCount}
                  onChange={setSelect}
                >
                  <option value="all">{t("v2.orgchart.super_panel.all_subordinates")}</option>
                  <option value="immediate">{t("v2.orgchart.super_panel.direct_reports")}</option>
                </select>
              </div>
              <label htmlFor="countOpenPositions" className="checkbox extra-option">
                <input
                  checked={countOpenPositions}
                  id="countOpenPositions"
                  name="countOpenPositions"
                  onChange={setOptionCheckbox}
                  type="checkbox"
                />
                {t("v2.orgchart.super_panel.count_open_positions")}
              </label>
              <label htmlFor="countDottedRelationships" className="checkbox">
                <input
                  checked={countDottedRelationships}
                  id="countDottedRelationships"
                  name="countDottedRelationships"
                  onChange={setOptionCheckbox}
                  type="checkbox"
                />
                {t("v2.orgchart.super_panel.count_dotted_relationships")}
              </label>
            </div>
          )}
        </div>
        <DrawerFooter
          onCancel={resetOptionsForm}
          onSave={saveDataOptions}
          disableSubmit={!selectedDisplayFields.length}
        />
      </form>
    </div>
  )
}

export { DataOptionsTab }
