import * as d3 from "d3"
import { faLongArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { isEqual, omit, pick } from "lodash"

// Adapted from: https://observablehq.com/@d3/zoomable-sunburst

/**
 * Renders a semicircular sunburst chart. Hierarchical data is assumed to be
 * maximum 2 levels deep.
 */
function sunburst(
  data,
  { backButtonText = "All Jobs", totalNodeCountText, tooltipNodeCountText, onNodeClick } = {},
) {
  // Dimensions
  const width = 700
  const height = width / 2
  const radius = width / 6
  const rootNodeRadius = radius / 1.35

  // Constants
  const LABEL_FONT_SIZE = 10
  const LABEL_FONT_WEIGHT = 500
  const ROOT_LABEL_FONT_SIZE = 13
  const ROOT_LABEL_FONT_WEIGHT = 700
  const ROOT_BUTTON_ICON_SIZE = 13
  const BASE_OPACITY = 0.4
  const ACTIVE_OPACITY = 0.6
  const NODE_TRANSITION_DURATION = 750
  const ACTIVE_ARC_TRANSITION_DURATION = 200
  const ROOT_BUTTON_COLOR = "#1B62FFCC"
  // See: Careers::JobFamilySunburst::DEFAULT_NODE_KEY
  const DEFAULT_NODE_KEY = "DEFAULT_NODE"
  const OPACITY_CHANGE_ON_HOVER = 0.15

  // Create the color scale.
  const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, data.children.length + 1))

  // Compute the layout.
  const hierarchy = d3
    .hierarchy(data)
    .sum((d) => d.value)
    .sort((a, b) => b.value - a.value)

  // Adding 1 to the height ensures we only show 1 level at a time.
  // We use PI rather than 2PI to make the chart a semicircle.
  const root = d3.partition().size([Math.PI, hierarchy.height + 1])(hierarchy)
  // eslint-disable-next-line no-return-assign, no-param-reassign
  root.each((d) => (d.current = d))

  // We include specific handling for the innerRadius of the root node and
  // sub-nodes.
  const makeNodeInnerRadius = (d) => d.y0 * radius
  const makeInnerRadius = (d) => {
    if (d.innerRadius !== undefined) {
      return d.innerRadius
    }
    return makeNodeInnerRadius(d)
  }

  // Create the arc generator.
  const arc = d3
    .arc()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(radius * 2)
    .innerRadius(makeInnerRadius)
    .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1))
    .cornerRadius(4)

  // Create the SVG container.
  const svg = d3
    .create("svg")
    .attr("viewBox", [0, 0, width, height])
    .style("font", `${LABEL_FONT_SIZE}px Satoshi, sans-serif`)

  const parentGroup = svg
    .append("g")
    .attr("transform", `rotate(-90) translate(-${height}, ${width / 2})`)

  const tooltip = d3
    .select("body")
    .append("div")
    .attr("class", "sunburst-tooltip")
    .style("opacity", 0)
    .style("position", "absolute")
    .style("pointer-events", "none")

  // Append the arcs.
  const path = parentGroup
    .append("g")
    .selectAll("path")
    .data(root.descendants().slice(1))
    .join("path")
    .attr("id", (d) => `path-${d.data.key}`)
    .attr("fill", (d) => {
      // eslint-disable-next-line no-param-reassign
      while (d.depth > 1) d = d.parent
      return color(d.data.key)
    })
    .attr("fill-opacity", (d) => getNodeOpacity(d))
    .attr("pointer-events", (d) => (arcVisible(d.current) ? "auto" : "none"))
    .attr("d", (d) => arc(d.current))
    .on("mouseover", function (event, d) {
      showTooltip(event, d)
      toggleHoverOpacity(d3.select(this), d, true)
    })
    .on("mouseout", function (event, d) {
      hideTooltip(event, d)
      toggleHoverOpacity(d3.select(this), d, false)
    })

  // The only nodes that are unclickable are default nodes without any siblings.
  path
    .filter((d) => !isDefaultNodeWithoutSibling(d))
    .style("cursor", "pointer")
    .on("click", (event, d) => {
      const type = d.children ? "root" : "leaf"
      return clicked(event, d, type)
    })

  const label = parentGroup
    .append("g")
    .attr("pointer-events", "none")
    .attr("text-anchor", "middle")
    .style("user-select", "none")
    .selectAll("text")
    .data(root.descendants().slice(1))
    .join("text")
    .filter((d) => !isDefaultNodeWithoutSibling(d))
    .attr("dy", "0.35em")
    .attr("fill-opacity", (d) => +labelVisible(d.current))
    .attr("transform", (d) => labelTransform(d.current))
    .attr("font-size", `${LABEL_FONT_SIZE}px`)
    .attr("font-weight", LABEL_FONT_WEIGHT)
    .text((d) => {
      const arcWidth = radius / 2
      const availableWidth = arcWidth * 1.5
      return truncateText(d.data.name, availableWidth, LABEL_FONT_SIZE, LABEL_FONT_WEIGHT)
    })

  const totalNodesText = parentGroup
    .append("text")
    .attr("x", 0)
    .attr("y", -20)
    .attr("transform", "rotate(90)")
    .attr("text-anchor", "middle")
    .attr("opacity", 1)
    .attr("font-size", `${ROOT_LABEL_FONT_SIZE}px`)
    .attr("font-weight", "500")
    .attr("fill", "#0C144BA3")
    .style("user-select", "none")
    .text(totalNodeCountText)

  const backButtonGroup = parentGroup
    .append("g")
    .datum(root)
    .attr("transform", "translate(20, 0) rotate(90)")
    .attr("text-anchor", "middle")
    .attr("opacity", 0)
    .style("font", `${ROOT_LABEL_FONT_SIZE}px Satoshi, sans-serif`)
    .attr("fill", ROOT_BUTTON_COLOR)
    .style("user-select", "none")
    .style("cursor", "pointer")
    .on("click", (event, d) => {
      if (d.baseNode) {
        clicked(event, d, "backButton")
      }
    })

  // Calculate the dimensions of the clickable area
  const iconWidth = ROOT_BUTTON_ICON_SIZE
  const xPadding = 4
  const yPadding = 3
  const textWidth = getTextWidth(backButtonText, ROOT_LABEL_FONT_SIZE, ROOT_LABEL_FONT_WEIGHT)
  const totalWidth = iconWidth + textWidth + xPadding
  const totalHeight = ROOT_LABEL_FONT_SIZE + yPadding
  const fontWeightInterpolator = (t, startingFontWeight) => {
    if (t < 0.33) {
      return startingFontWeight
    }
    if (t < 0.66) {
      return 600
    }
    return startingFontWeight === 500 ? 700 : 500
  }

  // Append the FontAwesome icon as an SVG path
  backButtonGroup
    .append("path")
    .attr("d", faLongArrowLeft.icon[4])
    .attr(
      "transform",
      `translate(${-totalWidth / 2}, -${totalHeight * 0.75}) scale(${
        ROOT_BUTTON_ICON_SIZE / faLongArrowLeft.icon[0]
      })`,
    )
    .attr("fill", ROOT_BUTTON_COLOR)

  // Append the text element
  backButtonGroup
    .append("text")
    .attr("x", iconWidth / 2 + xPadding / 2)
    .attr("font-weight", "500")
    .text(backButtonText)

  // Append a transparent rectangle to cover the entire clickable area
  const backButtonRect = backButtonGroup
    .append("rect")
    .attr("id", "sunburst-back-button")
    .attr("class", "hover-border")
    .attr("x", -totalWidth / 2)
    .attr("y", -(totalHeight - yPadding))
    .attr("width", totalWidth)
    .attr("height", totalHeight)
    .attr("fill", "transparent")
    .style("pointer-events", "all")

  const underline = backButtonGroup
    .append("line")
    .attr("x1", -totalWidth / 2)
    .attr("x2", totalWidth / 2)
    .attr("y1", totalHeight / 4)
    .attr("y2", totalHeight / 4)
    .attr("stroke", "transparent")
    .attr("stroke-width", 1)

  backButtonRect
    .on("mouseover", () => underline.transition().attr("stroke", ROOT_BUTTON_COLOR))
    .on("mouseout", () => underline.transition().attr("stroke", "transparent"))

  // Handle zoom on click.
  function clicked(_event, p, clickedType = "backButton") {
    const clickedBack = clickedType === "backButton"
    const clickedRootNode = clickedType === "root"
    const clickedLeafNode = clickedType === "leaf"

    if (!clickedBack && isNodeTransitioning(p)) return

    // If we're clicking a leaf node, we handle setting/unsetting the active node class
    // and any associated callbacks, but we don't do any zooming/transitioning
    // etc.
    if (clickedLeafNode) {
      if (path.filter((node) => node === p).classed("active")) {
        unsetActiveArcs()
        setArcActive(p.parent)
        onNodeClick?.(pick(p.parent.data, "key", "name", "totalNestedNodes"))
        return
      }

      unsetActiveArcs()
      setArcActive(p)
      onNodeClick?.(pick(p.data, "key", "name", "totalNestedNodes", "defaultFor"))
      return
    }

    let parentDatum = p.parent || root
    if (clickedRootNode) {
      onNodeClick?.(pick(p.data, "key", "name", "totalNestedNodes"))

      // If the base is currently set and we click it, remove any active
      // classes, and set it to be active.
      if (backButtonGroup.datum()?.baseNode) {
        unsetActiveArcs()
        setArcActive(p)
        return
      }

      // If we're clicking onto a root node, we encode that data on the
      // backButtonGroup to keep track of which node is at the base.
      parentDatum = {
        ...parentDatum,
        baseNode: p,
      }

      // Set the base node active.
      setArcActive(p)
    }

    if (clickedBack) {
      parentDatum = {
        ...parentDatum,
        baseNode: null,
      }

      // Remove any active classes and reset the fill opacity.
      unsetActiveArcs()
      // We pass null b/c the back button doesn't technically represent a node.
      onNodeClick?.(null)
    }

    const t = svg.transition().duration(NODE_TRANSITION_DURATION)

    backButtonGroup.datum(parentDatum).style("cursor", clickedBack ? "default" : "pointer")

    root.each(
      // eslint-disable-next-line no-return-assign
      (d) =>
        // eslint-disable-next-line no-param-reassign
        (d.target = {
          x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * Math.PI,
          x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * Math.PI,
          y0: Math.max(0, d.y0 - p.depth),
          y1: Math.max(0, d.y1 - p.depth),
        }),
    )

    // Handle transitioning between the back button and the default display
    // text.
    backButtonGroup.transition(t).attr("opacity", +!clickedBack)
    totalNodesText.transition(t).attr("opacity", +clickedBack)

    // Transition the data on all arcs, even the ones that aren’t visible,
    // so that if this transition is interrupted, entering arcs will start
    // the next transition from the desired position.
    path
      .transition(t)
      .tween("data", (d) => {
        const i = d3.interpolate(d.current, d.target)
        // eslint-disable-next-line no-return-assign, no-param-reassign
        return (t) => (d.current = i(t))
      })
      .filter(function (d) {
        return +this.getAttribute("fill-opacity") || arcVisible(d.target)
      })
      .attr("fill-opacity", (d) =>
        // eslint-disable-next-line no-nested-ternary
        arcVisible(d.target) ? (d.children ? ACTIVE_OPACITY : BASE_OPACITY) : 0,
      )
      .attrTween("d", (d) => {
        const isClickedNode = clickedNode(d, p)
        const isClickedBackButton = clickedBack && clickedBackButton(d, p)
        const shouldTweenArcRadius = isClickedBackButton || isClickedNode

        if (!shouldTweenArcRadius) {
          return () => arc(d.current)
        }

        // Create interpolations for innerRadius and other properties separately
        const interpolateInnerRadius = d3.interpolate(
          isClickedNode ? makeNodeInnerRadius(d) : rootNodeRadius,
          isClickedNode ? rootNodeRadius : makeNodeInnerRadius(d.target),
        )

        const interpolateOtherProperties = d3.interpolate(d.current, d.target)

        return function (t) {
          // Interpolate both innerRadius and other properties
          const currentInnerRadius = interpolateInnerRadius(t)
          const currentProperties = interpolateOtherProperties(t)

          // eslint-disable-next-line no-param-reassign
          d.current = {
            ...currentProperties,
            innerRadius: currentInnerRadius,
          }

          return arc(d.current)
        }
      })

    // Handle managing click events
    path.attr("pointer-events", (d) => (arcVisible(d.target) ? "auto" : "none"))

    label
      .filter(function (d) {
        return +this.getAttribute("fill-opacity") || labelVisible(d.target)
      })
      .transition(t)
      .attr("fill-opacity", (d) => +labelVisible(d.target))
      .attr("font-size", (d) =>
        clickedNode(d, p) ? `${ROOT_LABEL_FONT_SIZE}px` : `${LABEL_FONT_SIZE}px`,
      )
      .attrTween(
        "font-weight",
        (d) =>
          function (t) {
            const isClickedNode = clickedNode(d, p)
            const isClickedBackButton = clickedBack && clickedBackButton(d, p)
            const shouldTween = isClickedBackButton || isClickedNode
            const startingFontWeight = clickedNode(d, p)
              ? LABEL_FONT_WEIGHT
              : ROOT_LABEL_FONT_WEIGHT
            if (shouldTween) {
              return fontWeightInterpolator(t, startingFontWeight)
            }
            return LABEL_FONT_WEIGHT
          },
      )
      .attrTween(
        "transform",
        (d) =>
          function (t) {
            const currentTransform = d3.select(this).attr("transform")

            const isClickedNode = clickedNode(d, p)
            const isClickedBackButton = clickedBack && clickedBackButton(d, p)
            const shouldTweenLabel = isClickedBackButton || isClickedNode

            if (shouldTweenLabel) {
              const interpolateTransform = d3.interpolateString(
                currentTransform,
                labelTransform(d.current, isClickedNode),
              )
              return interpolateTransform(t)
            }
            return labelTransform(d.current)
          },
      )
  }

  function setArcActive(d) {
    const t = svg.transition().duration(ACTIVE_ARC_TRANSITION_DURATION)
    path
      .filter((node) => node === d)
      .classed("active", true)
      .transition(t)
      .attr("fill-opacity", ACTIVE_OPACITY)
  }

  function unsetActiveArcs() {
    const t = svg.transition().duration(ACTIVE_ARC_TRANSITION_DURATION)

    path.filter(".active").classed("active", false).transition(t).attr("fill-opacity", BASE_OPACITY)
  }

  function arcVisible(d) {
    // y0/y1 are top/bottom of the arc respectively: https://d3js.org/d3-hierarchy/partition
    return d.y1 <= 2 && d.y0 >= 0 && d.x1 > d.x0
  }

  function clickedNode(d, p) {
    return d.data.key === p.data.key
  }

  function clickedBackButton(d, p) {
    return d.data.key === p.baseNode?.data?.key
  }

  function labelVisible(d) {
    return d.y1 <= 2 && d.y0 >= 0 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.08
  }

  function getTextWidth(text, fontSize, fontWeight) {
    const context = document.createElement("canvas").getContext("2d")
    context.font = `${fontWeight} ${fontSize}px Satoshi, sans-serif`
    return context.measureText(text).width
  }

  function truncateText(text, maxWidth, fontSize, fontWeight) {
    let truncated = text
    let textWidth = getTextWidth(truncated, fontSize, fontWeight)

    if (textWidth <= maxWidth) return truncated

    while (textWidth > maxWidth && truncated.length > 0) {
      truncated = truncated.slice(0, -1)
      textWidth = getTextWidth(`${truncated}...`, fontSize, fontWeight)
    }

    return `${truncated}...`
  }

  function labelTransform(d, isClickedNode = false) {
    const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI
    let y = ((d.y0 + d.y1) / 2) * radius
    let labelRotation = x > 90 ? 0 : 180

    // If we're clicking into a particular node, we want to rotate the label to
    // be horizontal. We also adjust the label y positioning.
    if (isClickedNode) {
      labelRotation = 90
      y -= 10
    }

    return `rotate(${x - 90}) translate(${y},0) rotate(${labelRotation})`
  }

  function showTooltip(_event, d) {
    if (isDefaultNodeWithoutSibling(d) || isNodeTransitioning(d)) return

    const tooltipContent = `
      <div>${d.data.name}</div>
      <div class="sunburst-tooltip__subtitle">${tooltipNodeCountText}: ${d.data.totalNestedNodes}</div>
    `
    tooltip.html(tooltipContent)
    moveTooltip(d)
    tooltip.transition().duration(200).style("opacity", 0.9)
  }

  function moveTooltip(d) {
    const svgBounds = svg.node().getBoundingClientRect()
    const svgWidth = svgBounds.width
    const svgHeight = svgBounds.height

    const scale = svgWidth / width

    const angle = (d.current.x0 + d.current.x1) / 2
    const radius = arc.outerRadius()(d.current) * scale

    const x = svgWidth / 2 - radius * Math.cos(angle)
    const y = svgHeight - radius * Math.sin(angle)

    const screenX = svgBounds.left + x
    const screenY = svgBounds.top + y

    const tooltipWidth = tooltip.node().offsetWidth
    const tooltipHeight = tooltip.node().offsetHeight
    const offsetY = 8

    tooltip
      .style("left", `${screenX - tooltipWidth / 2}px`)
      .style("top", `${screenY - tooltipHeight - offsetY}px`)
  }

  function hideTooltip() {
    tooltip.transition().duration(500).style("opacity", 0)
  }

  function toggleHoverOpacity(selection, datum, isHovering) {
    if (isDefaultNodeWithoutSibling(datum) || isNodeTransitioning(datum)) return

    let currentOpacity = nodeIsBase(datum) ? BASE_OPACITY : getNodeOpacity(datum)
    if (selection.classed("active")) {
      currentOpacity = ACTIVE_OPACITY
    }

    selection
      .transition(100)
      .attr("fill-opacity", () =>
        isHovering ? Math.min(currentOpacity + OPACITY_CHANGE_ON_HOVER, 1) : currentOpacity,
      )
  }

  function nodeIsBase(d) {
    const baseNode = backButtonGroup.datum()?.baseNode
    return baseNode && baseNode.data.key === d.data.key
  }

  /**
   * Returns the base opacity of node not considering the active state.
   */
  function getNodeOpacity(d) {
    // eslint-disable-next-line no-nested-ternary
    return arcVisible(d.current) ? (d.children ? ACTIVE_OPACITY : BASE_OPACITY) : 0
  }

  function isDefaultNodeWithoutSibling(d) {
    return d.data.key === DEFAULT_NODE_KEY && d.data.siblingCount === 0
  }

  function isNodeTransitioning(d) {
    return d.target && !isEqual(omit(d.current, "innerRadius"), d.target)
  }

  return svg.node()
}

export default sunburst
