import React from "react"
import { ControlProps, JsonSchema } from "@jsonforms/core"
import { useJsonForms } from "@jsonforms/react"

import { JsonAutocompleteInput } from "./AutocompleteControl"

type OrgUnitData = { id: string; name: string; typeId: string; typeName: string }
type ControlPropsSubset = Pick<
  ControlProps,
  "config" | "data" | "handleChange" | "path" | "schema" | "uischema" | "rootSchema" | "visible"
>

const JsonOrgUnitInput = ({
  config,
  data,
  handleChange,
  path,
  schema,
  uischema,
  rootSchema,
  visible,
}: ControlPropsSubset) => {
  const context = useJsonForms()
  const formData = context?.core?.data
  const rowIndex = uischema.options?.rowIndex || 0
  const startIndex = rowIndex * 3
  const endIndex = startIndex + 3

  if (!data || !data.length) return null

  const handleChangeEvent = (path: string, value: { id: string; name: string }, typeId: string) => {
    const item: OrgUnitData = data.find((orgUnit: OrgUnitData) => orgUnit.typeId === typeId)
    if (!item) return

    const updatedItem: OrgUnitData = { ...item, ...value }

    handleChange(path, updatedItem)
  }

  const disableDepartment = (schema.items as JsonSchema)?.properties?.disableDepartment

  return (
    <div className="horizontal-layout items-start">
      {data.slice(startIndex, endIndex).map((orgUnit: OrgUnitData, index: number) => (
        <div className="horizontal-layout-item" key={orgUnit.typeId}>
          <JsonAutocompleteInput
            config={config}
            label={orgUnit.typeName}
            uischema={uischema}
            data={{ id: orgUnit.id, name: orgUnit.name }}
            schema={schema.items as JsonSchema}
            id={`org-unit-type-${orgUnit.typeId}`}
            handleChange={(path, value) => handleChangeEvent(path, value, orgUnit.typeId)}
            path={`${path}.${startIndex + index}`}
            errors=""
            enabled={
              formData.reqType !== "backfill" &&
              !(orgUnit.typeName === "Department" && disableDepartment)
            }
            visible={visible}
            rootSchema={rootSchema}
            fieldKey={orgUnit.typeId}
          />
        </div>
      ))}
    </div>
  )
}

export { JsonOrgUnitInput }
