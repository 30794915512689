import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useOutsideClick } from "v2/react/hooks/useOutsideClick"

interface Props {
  showColorPicker: boolean
  colors: string[]
  currentColor: string
  onSubmit: (color: string) => void
  closeColorPicker: () => void
  pickerPosition: { top: number; left: number }
}

function ColorPicker({
  showColorPicker,
  colors,
  onSubmit,
  closeColorPicker,
  pickerPosition,
  currentColor,
}: Props) {
  const [selectedColor, setSelectedColor] = useState(currentColor)
  const ref = useOutsideClick<HTMLDivElement>(() => handleClose())

  useEffect(() => setSelectedColor(currentColor), [currentColor])

  const handleClose = () => closeColorPicker()

  const handleSubmit = () => {
    onSubmit(selectedColor)
    handleClose()
  }

  const selectColor = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    setSelectedColor(e.currentTarget.value)
  }

  if (!showColorPicker || !selectedColor) return null

  return (
    <div className="color-picker-tooltip-wrapper" style={pickerPosition} ref={ref}>
      <div className="form-control">
        <button className="modal-close-icon" onClick={handleClose} type="button">
          <FontAwesomeIcon icon={["far", "times"]} />
        </button>
        <label htmlFor="color">{"Select a color".t("calendar")}</label>
        <div className="color-palette-widget">
          {colors.map((color) => (
            <input
              readOnly
              type="button"
              key={color}
              id="color"
              value={color}
              className={`color-palette-color m-0 ${
                selectedColor === color ? "color-palette-color-selected" : ""
              }`}
              style={{ backgroundColor: color, color: "transparent" }}
              onClick={(e) => selectColor(e)}
            />
          ))}
        </div>
      </div>
      <div className="footer">
        <button onClick={handleClose} type="button" className="btn--large btn--secondary">
          {"Cancel".t("defaults")}
        </button>
        <button onClick={handleSubmit} type="submit" className="btn--large btn--primary">
          {"Save".t("defaults")}
        </button>
      </div>
    </div>
  )
}

export { ColorPicker }
