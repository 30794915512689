import React from "react"
import { ChangeHandler, RefCallBack } from "react-hook-form"
import { TextInput } from "v2/react/shared/TextInput"

function JobTitleField({
  show = true,
  name,
  textInputRef,
  defaultValue,
  onChange,
  error,
}: {
  show: boolean
  name: string
  textInputRef: RefCallBack
  defaultValue: string | null | undefined
  onChange: ChangeHandler
  error: boolean | undefined
}) {
  if (!show) {
    return null
  }
  return (
    <div className="mb-4 cursor-default">
      <TextInput
        name={name}
        inputRef={textInputRef}
        required
        label={"field_title".t("ats")}
        defaultValue={defaultValue}
        onChange={onChange}
      />
      {error && <div className="form-error-message">{"field_title_required".t("ats")}</div>}
    </div>
  )
}

export { JobTitleField }
