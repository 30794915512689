import classNames from "classnames"
import React, {
  CSSProperties,
  ChangeEvent,
  KeyboardEvent,
  useImperativeHandle,
  useState,
} from "react"
import { defaultMemoize } from "reselect"

import { Maybe, PositionAutocompleteConnectionQuery } from "types/graphql.d"
import { Column, CursorConnection, NodeRow } from "v2/react/shared/Datasheet/types"

import { AutocompleteResult } from "v2/react/shared/Datasheet/Cell/Autocomplete/PositionAutocomplete/AutocompleteResult"
import { DropdownMenu } from "v2/react/shared/DropdownMenu"
import { TransitionKeys } from "v2/react/components/orgChart/OrgChartDatasheet/hooks/cursorKeyMovements"
import { useAppSelector } from "v2/redux/store"
import { useAutoComplete } from "v2/react/hooks/useAutocomplete"
import {
  useFuncOnNextKeyUp,
  useStandardCellInputMouseDownHandler,
} from "v2/react/shared/Datasheet/Cell/hooks"
import { usePositionSearch } from "v2/react/hooks/usePositionSearch"

type Position = NonNullable<
  NonNullable<NonNullable<PositionAutocompleteConnectionQuery["positions"]>["nodes"]>[0]
>

interface PositionAutocompleteProps<TNode, CType> {
  cursorConnection: CursorConnection
  row: NodeRow<TNode>
  column: Column<CType>
  isFirst: boolean
  isLast: boolean
  noBorder?: boolean
  style: CSSProperties
  html: string | null
}

function PositionAutocomplete<TNode, CType = TNode>({
  cursorConnection,
  row,
  isFirst,
  isLast,
  noBorder,
  style,
  html,
}: PositionAutocompleteProps<TNode, CType>) {
  const [inputValue, setInputValue] = useState(html || "")
  const [showResultList, setShowResultList] = useState(false)
  const [handleKeyUp, deferUntilKeyUp] = useFuncOnNextKeyUp({
    stopPropagation: true,
    preventDefault: true,
  })
  const chartKey = useAppSelector((state) => state.container.containerKey)
  const isLimitedAdminUsingOfficialChart = useAppSelector(
    (state) => state.ability.isLimitedAdminUsingOfficialChart,
  )

  const { positions, returnEmpty, searchTerm } = usePositionSearch({
    filter: inputValue.trim(),
    chartKey,
    exclude: (row.data as { position_id: string }).position_id, // TODO MY: Harden This
    excludeWithReqs: false,
    subordinateOnly: isLimitedAdminUsingOfficialChart,
    includeSelf: isLimitedAdminUsingOfficialChart,
  })

  const {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useAutoComplete({ showList: showResultList, setShowList: setShowResultList })

  useImperativeHandle(
    cursorConnection.cellInputRef,
    () => ({
      blur: () => {
        setActiveIndex(null)
        setShowResultList(false)
        refs.domReference.current?.blur()
      },
      focus: (initial) => {
        const { current } = refs.domReference
        if (typeof initial !== "undefined" && current) current.value = initial
        if (typeof initial !== "undefined") setInputValue(initial)
        current?.focus?.()
      },
      getValue: () => inputValue,
    }),
    [inputValue, setActiveIndex, setShowResultList, setInputValue, refs.domReference],
  )

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    setInputValue(value)

    if (value.length > 0) {
      setShowResultList(true)
      setActiveIndex(0)
    } else {
      setShowResultList(false)
      setActiveIndex(null)
    }
  }

  const handleSubmit = (positionId?: string | null, event?: KeyboardEvent) => {
    setActiveIndex(null)
    setShowResultList(false)
    if (positionId && event)
      deferUntilKeyUp((keyUpEvent) => {
        keyUpEvent.preventDefault()
        keyUpEvent.stopPropagation()
        cursorConnection.saveWrite(positionId, getCursorOptions(keyUpEvent))
      })
    else if (positionId) cursorConnection.saveWrite(positionId, getCursorOptions())
    else cursorConnection.stopWriting()
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!TransitionKeys.matchEvent(event.nativeEvent)) return
    executeSubmit(event)
  }

  const stopWritingAndLeave = (ev?: KeyboardEvent) =>
    ev
      ? deferUntilKeyUp((nextEvent) => cursorConnection.stopWriting(getCursorOptions(nextEvent)))
      : cursorConnection.stopWriting(getCursorOptions())

  const handleResultClick = (
    positionId: string | null | undefined,
    positionLabel: string,
    ev: React.MouseEvent,
  ) => {
    ev.preventDefault()
    ev.stopPropagation()
    if (positionLabel === html) {
      setInputValue(html || "")
      stopWritingAndLeave()
      return
    }
    setInputValue(positionLabel)
    handleSubmit(positionId)
  }

  const executeSubmit = (ev?: KeyboardEvent) => {
    setActiveIndex(null)
    setShowResultList(false)

    const clickedDropdownResult = activeIndex !== null && positions[activeIndex]

    if (clickedDropdownResult) {
      const position = positions[activeIndex]
      const positionLabel = getPositionLabel(positions[activeIndex])

      if (positionLabel === html) {
        setInputValue(html || "")
        stopWritingAndLeave(ev)
        return
      }

      setInputValue(positionLabel)
      handleSubmit(position.uniqueKey, ev)
    } else {
      setInputValue(html || "")
      stopWritingAndLeave(ev)
    }
  }

  const handleMouseDown = useStandardCellInputMouseDownHandler(refs.domReference)

  return (
    <>
      <input
        style={prepareStyle(style, isFirst, row.color, noBorder)}
        className={nodeClassName(isLast)}
        aria-autocomplete="list"
        value={inputValue}
        ref={refs.setReference}
        /* eslint-disable react/jsx-props-no-spreading */
        {...getReferenceProps({
          onKeyUp: handleKeyUp,
          onKeyDown: handleKeyDown,
          onChange: handleChange,
          onMouseDown: handleMouseDown,
        })}
      />
      <DropdownMenu
        showList={showResultList && !!inputValue.trim().length}
        floatingRef={refs.setFloating}
        floatingStyles={floatingStyles}
        floatingProps={getFloatingProps}
        wrapperClasses="AutocompleteField"
        context={context}
      >
        <>
          {positions?.map((position, index) => (
            <AutocompleteResult
              key={position.uniqueKey}
              position={position}
              isSelected={activeIndex === index}
              className={positionClassName(activeIndex, index)}
              searchTerm={searchTerm}
              refFn={(node: HTMLDivElement | null) => {
                listRef.current[index] = node
              }}
              getItemProps={getItemProps}
              handleResultClick={handleResultClick}
            />
          ))}
          {!returnEmpty && positions?.length === 0 && inputValue.length > 0 && (
            <div className="AutocompleteField__no-result">
              <p className="AutocompleteField__no-result-text">
                {"No Positions Found".t("org_chart")}
              </p>
            </div>
          )}
        </>
      </DropdownMenu>
    </>
  )
}

const getCursorOptions = (ev?: KeyboardEvent) =>
  ev ? { moveAfterByEvent: ev.nativeEvent } : { transitionKeyCanMove: true }

const getPositionLabel = (position: Position) => {
  const people = position.people
  const peopleNames = people?.map((person) => person.name).join(", ")
  const positionTitle = position.title
  const positionSystemID = position.systemIdentifier
  return peopleNames || positionTitle || positionSystemID || ""
}

const nodeClassName = (isLast: boolean) =>
  classNames("GridBody-cell bg-transparent", { last: isLast })

const positionClassName = (activeIndex: number | null, index: number) =>
  classNames("AutocompleteField__result", { highlight: activeIndex === index })

const prepareStyle = defaultMemoize((style, isFirst, color?: Maybe<string>, noBorder?: boolean) => {
  const base = color && isFirst ? { ...(style || {}), borderLeft: `5px solid ${color}` } : style
  if (noBorder) {
    return {
      ...base,
      borderRightWidth: 0,
      borderTopWidth: 0,
      zIndex: 21,
    }
  }
  return { ...base, zIndex: 21 }
})

export { Position, PositionAutocomplete }
