import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { MouseEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"
import { asyncToggleChartSectionFilter } from "v2/redux/slices/GridSlice/gridThunks"
import { useAppDispatch } from "v2/redux/store"

interface DropdownMenuProps {
  editingDisabled: boolean
  hasAccessToEdit: boolean
  id: string
  isFiltered: boolean
  isHovered: boolean
  name: string
}

const ChartSectionDropdown = ({
  editingDisabled,
  hasAccessToEdit,
  id,
  isFiltered,
  isHovered,
  name,
}: DropdownMenuProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const dropdownMenuLink = classNames("dropdown-menu-link", { disabled: editingDisabled })
  const dropdown = document.getElementById(`dropdown-menu-${id}`)

  useEffect(() => {
    if (!isHovered && dropdown) {
      dropdown.style.display = "none"
      setDropdownOpen(false)
    }
  }, [isHovered, dropdown])

  const handleToggleChartSectionFilterClick = () =>
    dispatch(asyncToggleChartSectionFilter(id, name))

  const openSectionModal = (e: MouseEvent, url: string) => {
    e.preventDefault()
    pjaxModalFor(url)
  }

  const openDropdown = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setDropdownOpen(!dropdownOpen)
  }

  return (
    <div className="dropdown invisible">
      <button
        className="dropdown-link h-full cursor-pointer bg-transparent !p-2"
        onClick={openDropdown}
        type="button"
      >
        <FontAwesomeIcon icon={["far", "ellipsis-h"]} />
      </button>
      <div
        className={classNames("dropdown-menu dropdown-menu-right m-0", {
          hidden: true,
          "!block": dropdownOpen,
        })}
        id={`dropdown-menu-${id}`}
      >
        {!isFiltered ? (
          <button
            className="chart-sections dropdown-menu-link unfiltered-action"
            onClick={handleToggleChartSectionFilterClick}
            type="button"
          >
            {t("v2.orgchart.super_panel.view_chart_section")}
          </button>
        ) : null}
        {isFiltered ? (
          <button
            className="chart-sections dropdown-menu-link filtered-action whitespace-nowrap"
            onClick={handleToggleChartSectionFilterClick}
            type="button"
          >
            {t("v2.orgchart.super_panel.stop_viewing_chart_section")}
          </button>
        ) : null}
        {hasAccessToEdit && (
          <>
            <button
              onClick={(event) => openSectionModal(event, UrlHelper.chartSectionEditPath(id))}
              className={dropdownMenuLink}
              type="button"
            >
              {t("v2.orgchart.super_panel.edit_chart_section")}
            </button>
            <button
              onClick={(event) => openSectionModal(event, UrlHelper.chartSectionDeletePath(id))}
              className={dropdownMenuLink}
              type="button"
            >
              {t("v2.orgchart.super_panel.remove_chart_section")}
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export { ChartSectionDropdown }
