/* eslint-disable no-nested-ternary */
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FloatingPortal, useFloating } from "@floating-ui/react"
import { useOnClickOutside } from "usehooks-ts"
import { SubmitProposalButton, SubmitProposalModal } from "./ShowParticipant/SubmitProposalButton"

type Props = {
  headcountPlanId: string
  participantId: string
  setRef: (element: HTMLButtonElement) => void
  showExportButton?: boolean
  showSubmitProposalButton?: boolean
}
export function ParticipantTopbarMenu({
  headcountPlanId,
  participantId,
  setRef,
  showExportButton,
  showSubmitProposalButton,
}: Props) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [submitProposalModalIsOpen, setSubmitProposalModalIsOpen] = React.useState(false)
  const { refs, floatingStyles } = useFloating({ placement: "bottom-end" })

  useOnClickOutside(refs.floating, (event) => {
    if (refs.domReference?.current?.contains(event.target as Node)) return
    setIsOpen(false)
  })

  const showMenu = showExportButton || showSubmitProposalButton

  if (!showMenu) return null

  return (
    <div className="dropdown">
      <button
        ref={refs.setReference}
        type="button"
        id="plan-topbar-menu-button"
        className="btn--large btn--secondary dropdown-link w-11"
        onClick={() => setIsOpen(!isOpen)}
      >
        <FontAwesomeIcon icon={["far", "ellipsis-h"]} style={{ width: "1rem", height: "1rem" }} />
      </button>
      {isOpen ? (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className="dropdown-menu open z-20 mt-1"
          >
            {showExportButton ? (
              <button className="dropdown-menu-link gap-2 flex" type="button" ref={setRef}>
                <div className="justify-self-center">
                  <FontAwesomeIcon
                    icon={["fal", "download"]}
                    style={{ height: "0.75rem", width: "0.75rem" }}
                  />
                </div>
                {"export".t("defaults")}
              </button>
            ) : null}
            {showSubmitProposalButton ? (
              <SubmitProposalButton
                disabled={false}
                display="menuItem"
                onClick={() => setIsOpen(false)}
                setIsOpen={setSubmitProposalModalIsOpen}
              />
            ) : null}
          </div>
        </FloatingPortal>
      ) : null}
      <SubmitProposalModal
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        isOpen={submitProposalModalIsOpen}
        setIsOpen={setSubmitProposalModalIsOpen}
      />
    </div>
  )
}
