import React, { FC } from "react"
import { GroupLayout, JsonSchema, LayoutProps } from "@jsonforms/core"
import { JsonFormsDispatch } from "@jsonforms/react"

interface PanelGroupProps extends LayoutProps {
  schema: JsonSchema
  uischema: GroupLayout
}

const JsonModuleGroupLayout: FC<PanelGroupProps> = (props) => {
  const { schema, path, cells, enabled, uischema, renderers } = props as PanelGroupProps

  return (
    <div className="module-card">
      <div className="module-card__header">
        <span className="font-bold">{uischema.label}</span>
      </div>
      <div className="module-card__body border-none">
        {uischema.elements.map((child, index) => (
          <JsonFormsDispatch
            schema={schema}
            uischema={child}
            path={path}
            enabled={enabled}
            renderers={renderers}
            cells={cells}
            /* eslint-disable react/no-array-index-key */
            key={index}
          />
        ))}
      </div>
    </div>
  )
}

export { JsonModuleGroupLayout }
