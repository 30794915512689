import {
  FieldSuggestionsPerformActionsInput,
  FieldSuggestionsActionsAttributes,
  FieldSuggestionsPerformActionsMutation,
} from "types/graphql.d"

import { FieldSuggestionAction } from "types/graphql.enums"
import { flatMutationOperation } from "v2/redux/utils/endpoints"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { rtkQueryTagFromUniqueKey } from "v2/react/utils/uniqueKey"

const canActionInvalidateCache = ({ action }: FieldSuggestionsActionsAttributes) =>
  [FieldSuggestionAction.Accept, FieldSuggestionAction.Decline].includes(action)

export const FieldSuggestionsApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    fieldSuggestionsPerformActions: builder.mutation<
      FieldSuggestionsPerformActionsMutation,
      FieldSuggestionsPerformActionsInput
    >({
      query: flatMutationOperation("FieldSuggestionsPerformActions"),
      invalidatesTags: (result, _err, args) => {
        const uniqueKey = result?.fieldSuggestionsPerformActions?.record?.uniqueKey
        return uniqueKey && args.fieldSuggestionActions.some(canActionInvalidateCache)
          ? [rtkQueryTagFromUniqueKey(uniqueKey)]
          : []
      },
    }),
  }),
})

export const { useFieldSuggestionsPerformActionsMutation } = FieldSuggestionsApi
