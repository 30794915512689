import React from "react"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/Tooltip"

type TimelineHeaderContentProps = {
  tooltipMessage: string | null
  label: string
}
export function TimelineHeaderContent({ tooltipMessage, label }: TimelineHeaderContentProps) {
  if (tooltipMessage === null) {
    return label
  }

  return (
    <Tooltip>
      <TooltipTrigger>
        {label}
        <sup className="static text-base">*</sup>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content">{tooltipMessage}</TooltipContent>
    </Tooltip>
  )
}
