import React from "react"
import { useTranslation } from "react-i18next"
import { PositionType } from "types/graphql"
import { EmptyContent, EmptyState } from "v2/react/components/positionTypes/Show/EmptyState"
import { SmallEditButton } from "v2/react/components/positionTypes/Show/SmallEditButton"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"
import { UrlHelper } from "v2/react/utils/urls"

interface DescriptionProps {
  positionType: PositionType
}

const Description = ({ positionType }: DescriptionProps) => {
  const { t } = useTranslation()

  const handleEdit = () => {
    pjaxModalFor(UrlHelper.editPositionTypePath(positionType.id, "?selected_field=description"))
  }

  const emptyDescription: EmptyContent = {
    buttonText: t("v2.position_types.show.add_job_description_cta"),
    note: t("v2.position_types.show.add_job_description_note"),
    icon: "edit",
    onClick: handleEdit,
  }

  return (
    <div className="module-card mb-0">
      <div className="module-card__header">
        <div className="module-title">{t("v2.position_types.show.job_description")}</div>
        {positionType.description?.length ? <SmallEditButton onClick={handleEdit} /> : null}
      </div>
      <div className="module-card__body">
        {positionType.description?.length ? (
          <div>{sanitizeParse(positionType.description)}</div>
        ) : (
          <EmptyState
            buttonText={emptyDescription.buttonText}
            note={emptyDescription.note}
            icon={emptyDescription.icon}
            onClick={emptyDescription.onClick}
          />
        )}
      </div>
    </div>
  )
}

export { Description }
