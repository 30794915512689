import { Table } from "@tanstack/react-table"
import { Direction } from "v2/react/utils/enums"
import { onNothing } from "v2/redux/slices/DatasheetSlice/cursor/cursorStates"
import { CellCursor, CursorState } from "v2/redux/slices/DatasheetSlice/cursor/types"
import fp from "lodash/fp"
import { FieldType } from "../types"

export function createMovedCursor<TRow>(
  cursor: CellCursor,
  table: Table<TRow>,
  direction: Direction,
): CellCursor {
  if (onNothing(cursor)) throw new Error("Unexpected call; cursor must be on something")
  const rows = table.getRowModel().rows.filter((r) => !r.getIsGrouped())
  const selectableColumns = table.getAllFlatColumns().filter((c) => {
    const original = c.columnDef?.meta?.original
    return original?.editableFn && !original.hidden
  })

  const columnIndex = fp.findIndex(fp.propEq("id", cursor.columnId), selectableColumns)
  const rowIndex = fp.findIndex(fp.propEq("id", cursor.rowId), rows)
  const lastColumnIndex = selectableColumns.length - 1
  const lastRowIndex = rows.length - 1

  const [nextRowIndex, nextColumnIndex] = selectAdjacentCellIndices(
    {
      columnIndex,
      inFirstColumn: columnIndex === 0,
      inLastColumn: columnIndex === lastColumnIndex,
      lastColumnIndex,
      rowIndex,
    },
    direction,
  )

  let editable = cursor.editable

  // Edge case: if the next row would put the cursor out of bounds, use the
  // current row/column ids as the cursor should remain in place. This is unlike
  // the column, which will cause the cursor to "wrap" around to the last
  // column of the prior row or the first column of the next row.
  if (nextRowIndex < 0 || nextRowIndex > lastRowIndex) {
    return editable
      ? {
          rowId: cursor.rowId,
          columnId: cursor.columnId,
          state: CursorState.OnEditable,
          editable: true,
          currentValue: "",
          enteredBy: "placement",
          fieldType: cursor.fieldType ?? FieldType.NonEditable,
        }
      : {
          rowId: cursor.rowId,
          columnId: cursor.columnId,
          state: CursorState.OnNonEditable,
          editable: false,
          currentValue: "",
          enteredBy: "placement",
          fieldType: undefined,
        }
  }

  const nextRow = rows[fp.clamp(0, lastRowIndex, nextRowIndex)]
  const nextColumn = selectableColumns[fp.clamp(0, lastColumnIndex, nextColumnIndex)]

  editable = nextColumn.columnDef?.meta?.original?.editableFn?.(nextRow.original) ?? false

  return editable
    ? {
        rowId: nextRow.id,
        columnId: nextColumn.id,
        state: CursorState.OnEditable,
        editable: true,
        currentValue: "",
        enteredBy: getEnteredBy(nextRow.id, cursor.rowId, nextColumn.id, cursor.columnId),
        fieldType: nextColumn.columnDef?.meta?.original?.fieldType ?? FieldType.NonEditable,
      }
    : {
        rowId: nextRow.id,
        columnId: nextColumn.id,
        state: CursorState.OnNonEditable,
        editable: false,
        currentValue: "",
        enteredBy: getEnteredBy(nextRow.id, cursor.rowId, nextColumn.id, cursor.columnId),
        fieldType: undefined,
      }
}

function getEnteredBy(rowId: string, prevRowId: string, columnId: string, prevColumnId: string) {
  if (rowId !== prevRowId) return "movement"
  if (columnId !== prevColumnId) return "movement"
  return "placement"
}

type CursorPositionForChange = {
  columnIndex: number
  inFirstColumn: boolean
  inLastColumn: boolean
  lastColumnIndex: number
  rowIndex: number
}
function selectAdjacentCellIndices(
  { inFirstColumn, inLastColumn, lastColumnIndex, rowIndex, columnIndex }: CursorPositionForChange,
  direction: Direction,
) {
  const nextCell = {
    isLastCellOfPreviousRow: direction === Direction.Left && inFirstColumn,
    isCellOnLeft: direction === Direction.Left && !inFirstColumn,
    isFirstCellOfNextRow: direction === Direction.Right && inLastColumn,
    isCellOnRight: direction === Direction.Right && !inLastColumn,
    isCellBelow: direction === Direction.Down,
    isCellAbove: direction === Direction.Up,
  }

  if (nextCell.isLastCellOfPreviousRow) return [rowIndex - 1, lastColumnIndex]
  if (nextCell.isCellOnLeft) return [rowIndex, columnIndex - 1]
  if (nextCell.isFirstCellOfNextRow) return [rowIndex + 1, 0]
  if (nextCell.isCellOnRight) return [rowIndex, columnIndex + 1]
  if (nextCell.isCellAbove) return [rowIndex - 1, columnIndex]
  if (nextCell.isCellBelow) return [rowIndex + 1, columnIndex]

  return [rowIndex, columnIndex]
}
