import React from "react"
import { itemSuffix } from "./OrderListItem"

type IndexPanelColor = "primary-light" | "secondary" | "secondary-light"

interface Props {
  indexPanelContent: React.ReactNode
  indexPanelColor: IndexPanelColor
  mainPanelContent: React.ReactNode
  isFirst: boolean
  isLast: boolean
}

function OrderListItemStatic({
  indexPanelContent: colorPanelContent,
  mainPanelContent,
  indexPanelColor: colorPanelColor,
  isFirst,
  isLast,
}: Props) {
  return (
    <div className={`OrderedList__list-item${itemSuffix(isFirst, isLast)}`}>
      <div
        className={`OrderedList__list-item-index OrderedList__list-item-index-${colorPanelColor}`}
      >
        {colorPanelContent}
      </div>
      <div className="OrderedList__list-item-content">{mainPanelContent}</div>
    </div>
  )
}

export { OrderListItemStatic }
