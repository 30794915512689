import React from "react"

import { Table, flexRender } from "@tanstack/react-table"

import { SortIcon } from "./SortIcon"

interface HeaderProps<TData> {
  table: Table<TData>
}

export const TableHeader = <TData extends object>({ table }: HeaderProps<TData>) => (
  <thead>
    {table.getHeaderGroups().map((headerGroup) => (
      <tr key={headerGroup.id}>
        {headerGroup.headers.map((header) => (
          <th
            key={header.id}
            colSpan={header.colSpan}
            className={header.column.getCanSort() ? "sort" : ""}
            onClick={header.column.getToggleSortingHandler()}
          >
            {header.isPlaceholder ? null : (
              <div>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getCanSort() ? (
                  <SortIcon status={header.column.getIsSorted()} />
                ) : null}
              </div>
            )}
          </th>
        ))}
      </tr>
    ))}
  </thead>
)
