import React from "react"
import { CareerLadderDetailsQuery } from "types/graphql"
import { skipToken } from "@reduxjs/toolkit/dist/query"

import RootProvider from "v2/react/components/RootProvider"
import { Spinner } from "v2/react/shared/Spinner"
import { maybeGetIDFromUniqueKey } from "v2/react/utils/uniqueKey"
import { useCareerLadderDetailsQuery } from "v2/redux/GraphqlApi/CareerLaddersApi"

import { Builder } from "./Builder/Builder"

interface BuilderContainerProps {
  careerLadderUniqueKey: string
  positionAsLink?: boolean
  positionTypeUniqueKey: string
  showEditToggle?: boolean
  smallMode?: boolean
}

function WithProvider({
  careerLadderUniqueKey,
  positionAsLink,
  positionTypeUniqueKey,
  showEditToggle,
  smallMode,
}: BuilderContainerProps) {
  const { data: careerLadderData } = useCareerLadderDetailsQuery(
    careerLadderUniqueKey ? { uniqueKey: careerLadderUniqueKey } : skipToken,
  )

  const careerLadder: CareerLadderDetailsQuery["careerLadderDetails"] =
    careerLadderData?.careerLadderDetails

  if (!careerLadder) return null

  if (!careerLadderData) {
    return (
      <div className="relative h-screen">
        <Spinner />
      </div>
    )
  }

  return (
    <Builder
      activePositionTypeId={maybeGetIDFromUniqueKey(positionTypeUniqueKey) || ""}
      careerLadder={careerLadder}
      positionAsLink={positionAsLink}
      showEditToggle={showEditToggle}
      smallMode={smallMode}
    />
  )
}

const BuilderContainer = ({
  careerLadderUniqueKey,
  positionAsLink,
  positionTypeUniqueKey,
  showEditToggle,
  smallMode,
}: BuilderContainerProps) => (
  <RootProvider>
    <WithProvider
      careerLadderUniqueKey={careerLadderUniqueKey}
      positionAsLink={positionAsLink}
      positionTypeUniqueKey={positionTypeUniqueKey}
      showEditToggle={showEditToggle}
      smallMode={smallMode}
    />
  </RootProvider>
)

export { BuilderContainer }
