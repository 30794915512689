import React, { RefObject } from "react"
import { useEventListener } from "usehooks-ts"

import { useAppDispatch, useAppSelector } from "v2/redux/store"
import { transitionTableCursor } from "v2/redux/slices/DatasheetSlice"
import { Table } from "@tanstack/react-table"
import {
  CellCursor,
  CursorEventDetail,
  CursorState,
} from "v2/redux/slices/DatasheetSlice/cursor/types"
import { selectCursor } from "v2/redux/slices/DatasheetSlice/cursor/cursorSelectors"
import {
  inEitherRead,
  inEitherWriteState,
  onEditableCell,
  onNonEditableCell,
  onNothing,
} from "v2/redux/slices/DatasheetSlice/cursor/cursorStates"
import { Virtualizer } from "@tanstack/react-virtual"
import { useCustomEventListener } from "v2/react/hooks/useCustomEventListener"
import { cursorEventTarget } from "v2/redux/slices/DatasheetSlice/cursor/cursorEvents"
import { ArrowKeys, TransitionKeys, makeMovementMatchHandler } from "./cursorKeyMovements"
import { createMovedCursor } from "./moveCursor"

export type HookArg = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: Table<any>
  beaconRef: RefObject<HTMLButtonElement>
  cursorRef: RefObject<HTMLDivElement>
  tableContainerRef: RefObject<HTMLDivElement>
  virtualizer: Virtualizer<HTMLDivElement, Element>
}

interface HookState extends HookArg {
  cursor: CellCursor
}

/**
 * Listens to a variety of events in order to control the cell cursor. Manages
 * both moving the cursor, and transitioning the cursor into a "write" state.
 *
 * This hook is meant to be used at the top-level of the datasheet.
 */
export function useDatasheetCellCursor(arg: HookArg) {
  const hookState = {
    ...arg,
    cursor: useAppSelector(selectCursor),
  }

  useCellCursorTargetChangeListenerToRestoreFocus(hookState)
  useCellCursorTargetChangeListenerToScrollIntoView(hookState)
  useKeyDownListenerToPreventUnwantedDefaultBehavior(hookState)
  useKeyUpListenerToControlCursor(hookState)

  return hookState
}

/**
 * Prevents default behaviors of certain keydown events.
 *
 * While editing an input, a keyup event will not fire for the "Tab" key by
 * default. When not editing an input, "Tab" messes with the scroll position.
 * Squelching the event during a keydown remedies both issues.
 *
 * When not editing an input, an arrow key causes the sheet to scroll. This
 * squelches that as the arrow key now controls the cell cursor.
 */
function useKeyDownListenerToPreventUnwantedDefaultBehavior({ cursor }: { cursor: CellCursor }) {
  const listener = React.useCallback(
    (reactOrNativeEvent: KeyboardEvent | React.KeyboardEvent) => {
      const event = unwrapEvent(reactOrNativeEvent)
      if (matchModifierKey(event)) return

      // Ensure Enter + Tab behave appropriately.
      if (TransitionKeys.matchEvent(event)) squelchEvent(event)

      // When not writing to a cell, prevent default arrow key behavior to
      // prevent unwanted scrolling.
      if (!inEitherWriteState(cursor) && ArrowKeys.matchEvent(event)) squelchEvent(event)
    },
    [cursor],
  )

  useEventListener("keydown", listener)
}

/**
 * Handles moving the cursor, or when on an editable cell, possibly
 * transitioning to the "writingOnEditable" state.
 */
function useKeyUpListenerToControlCursor({ beaconRef, table }: HookState) {
  const cursor = useAppSelector(selectCursor)
  const appDispatch = useAppDispatch()

  const listener = React.useCallback(
    (reactOrNativeEvent: React.KeyboardEvent | KeyboardEvent) => {
      const event = unwrapEvent(reactOrNativeEvent)
      if (matchModifierKey(event)) return

      // Control is relinquished to the cell that is undergoing changes. If
      // writing, or if we're on nothing, return.
      if (onNothing(cursor) || inEitherWriteState(cursor)) return

      const applyMovementIfMatchFoundIn = makeMovementMatchHandler(event, ({ direction }) =>
        appDispatch(transitionTableCursor(createMovedCursor(cursor, table, direction))),
      )

      event.preventDefault()
      if (onNonEditableCell(cursor)) {
        applyMovementIfMatchFoundIn(ArrowKeys, TransitionKeys)
      } else if (onEditableCell(cursor) && TransitionKeys.matchEvent(event)) {
        appDispatch(transitionTableCursor({ ...cursor, state: CursorState.WritingOnEditable }))
      } else if (onEditableCell(cursor)) {
        applyMovementIfMatchFoundIn(ArrowKeys)
      }
    },
    [appDispatch, cursor, table],
  )

  useEventListener("keyup", listener, beaconRef)
}

/**
 * Focuses the beaconRef if it's not in focus, the cursor target changed, and
 * the cursor is in a read state.
 */
function useCellCursorTargetChangeListenerToRestoreFocus({ beaconRef }: HookState) {
  const listener = React.useCallback(
    ({ detail: { cursor } }: CustomEvent<CursorEventDetail>) => {
      if (inEitherRead(cursor) && beaconRef.current !== document.activeElement)
        beaconRef.current?.focus({ preventScroll: true })
    },
    [beaconRef],
  )

  useCustomEventListener(cursorEventTarget, "cellCursorTargetChange", listener)
}

const cellWidth = 160

/**
 * Focuses the beaconRef if it's not in focus, the cursor target changed, and
 * the cursor is in an editable state.
 */
function useCellCursorTargetChangeListenerToScrollIntoView({
  tableContainerRef,
  virtualizer,
}: HookState) {
  const listener = React.useCallback(
    ({ detail: { cursor } }: CustomEvent<CursorEventDetail>) => {
      if (!tableContainerRef.current) return
      if (onNothing(cursor)) return
      const cell = document.getElementById(`${cursor.columnId}-${cursor.rowId}`)
      if (!cell) return

      const cellRect = cell.getBoundingClientRect()
      const tableContainerRect = tableContainerRef.current.getBoundingClientRect()

      const isOffToRight = cellRect.left + cellWidth > tableContainerRect.right
      const isOffToLeft = cellRect.left < tableContainerRect.left

      if (isOffToRight || isOffToLeft)
        cell.scrollIntoView({
          behavior: "auto",
          block: "nearest",
          inline: "start",
        })

      const isOffToBottom = cellRect.bottom > tableContainerRect.bottom
      const isOffToTop = cellRect.top < tableContainerRect.top
      const rowIndex = cell.closest("tr")?.dataset?.virtualRowIndex

      if (isOffToBottom && typeof rowIndex === "string") {
        const rowIndexNumber = parseInt(rowIndex, 10)
        const scrollToIndex = rowIndexNumber + virtualizer.options.overscan - 1
        if (scrollToIndex >= virtualizer.options.count - 1) {
          cell.scrollIntoView({ behavior: "auto", block: "nearest", inline: "nearest" })
        } else {
          virtualizer.scrollToIndex(scrollToIndex, { behavior: "auto" })
        }
      } else if (isOffToTop && typeof rowIndex === "string") {
        const rowIndexNumber = parseInt(rowIndex, 10)
        const scrollToIndex = rowIndexNumber - virtualizer.options.overscan + 1
        if (scrollToIndex <= 0) {
          cell.scrollIntoView({ behavior: "auto", block: "nearest", inline: "nearest" })
        } else {
          virtualizer.scrollToIndex(scrollToIndex, { behavior: "auto" })
        }
      }
    },
    [tableContainerRef, virtualizer],
  )

  useCustomEventListener(cursorEventTarget, "cellCursorTargetChange", listener)
}

const unwrapEvent = (event: KeyboardEvent | React.KeyboardEvent) =>
  "nativeEvent" in event ? event.nativeEvent : event

const matchModifierKey = ({ metaKey, altKey, ctrlKey }: KeyboardEvent) =>
  metaKey || altKey || ctrlKey

const squelchEvent = (event: Event) => {
  event.preventDefault()
  event.stopPropagation()
}
