import React from "react"
import { flow } from "lodash"
import { maybeAddProperty } from "v2/react/utils/misc"
import {
  VisibilityState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { Column } from "../types"

export function useTableDatasheet<TRow extends { id: string }>(
  rows: TRow[],
  columns: Column<TRow>[],
) {
  const data = React.useMemo(() => rows, [rows])

  const columnDefs = React.useMemo(
    () =>
      columns.map((column) =>
        flow(
          (col) => maybeAddProperty(col, "id", column.id),
          (col) => maybeAddProperty(col, "header", column.label),
          (col) => maybeAddProperty(col, "enableHiding", column.hidden),
          (col) => maybeAddProperty(col, "enableColumnFilter", column.enableFiltering),
          (col) => maybeAddProperty(col, "enableGrouping", column.enableGrouping),
          (col) => maybeAddProperty(col, "enableSorting", column.enableSorting),
          (col) => maybeAddProperty(col, "accessorFn", column.accessorFn),
          (col) => maybeAddProperty(col, "accessorKey", column.accessorFn ? undefined : column.id),
          (col) => maybeAddProperty(col, "meta", { original: column }),
        )({}),
      ),
    [columns],
  )

  const table = useReactTable({
    data,
    columns: columnDefs,
    initialState: {
      expanded: true,
    },
    state: {
      columnVisibility: columns.reduce((acc, column) => {
        if (column.hidden) return { ...acc, [column.id]: false }
        return acc
      }, {} as VisibilityState),
    },
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    groupedColumnMode: false,
  })

  return table
}
