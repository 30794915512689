import React from "react"
import { t } from "i18next"

import { Modal } from "v2/react/shared/Modal"
import { SaveFooter } from "v2/react/shared/Modal/SaveFooter"

interface Props {
  isLoading: boolean
  isOpen: boolean
  onClose: () => void
  onSubmit: (event: React.FormEvent) => void
  title: string
}

const DeleteCareerLadderForm = ({ isLoading, isOpen, onClose, onSubmit, title }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} title={title} size="md">
    <form onSubmit={onSubmit}>
      <div className="react-modal__body flex-col gap-2 flex">
        {t("v2.career_ladders.index.confirm_delete")}
      </div>
      <SaveFooter
        isSaving={isLoading}
        onCancel={onClose}
        saveType="critical"
        enabledText={t("v2.defaults.delete")}
      />
    </form>
  </Modal>
)

export { DeleteCareerLadderForm }
