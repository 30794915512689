import React, { useState } from "react"

import { HeadcountPlanMember } from "types/graphql.d"
import { HeadcountPlanRole } from "types/graphql.enums"
import AnimateHeight from "react-animate-height"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { SelectInput } from "./SelectInput"

const ANIMATION_DURATION = 250

interface MemberListItemProps {
  currentPersonId: number
  isSaving: boolean
  canBeRemoved: boolean
  member: HeadcountPlanMember
  onItemRemoved: (event: React.MouseEvent<HTMLElement>) => void
  onItemRoleChange: (member: HeadcountPlanMember, role: HeadcountPlanRole) => void
}

const MemberListItem = ({
  currentPersonId,
  isSaving,
  member,
  canBeRemoved,
  onItemRemoved,
  onItemRoleChange,
}: MemberListItemProps) => {
  const [height, setHeight] = useState<0 | "auto">("auto")
  const handleRemoveValueClick = (e: React.MouseEvent<HTMLElement>) => {
    if (canBeRemoved) {
      setHeight(0)
    }
    onItemRemoved(e)
  }

  return (
    // For list item animations, an adaptation with credit to:
    // https://muffinman.io/react-animate-height/
    // https://github.com/Stanko/react-animate-height#demo
    <AnimateHeight
      className="plan-member-list-item highlight"
      duration={ANIMATION_DURATION}
      height={height}
    >
      <div className="plan-member-list-items__content bg-white">
        <div className="plan-member-list-items__left">
          <div className="plan-member-list-item-thumb">
            <img
              alt={member.person.name}
              src={
                member.person.avatarThumbUrl ||
                "/uploads/company/1/person/36/avatar/thumb_Built3.png"
              }
            />
          </div>
          <span>{member.person.name}</span>
        </div>
        <div className="plan-member-list-items__right">
          <SelectInput
            currentPersonId={currentPersonId}
            initialValue={member.role}
            selectedHeadcountPlanMember={member}
            updateRole={onItemRoleChange}
          />
          {!hideRemovalIcon(currentPersonId, member) && (
            <button
              disabled={isSaving}
              onClick={handleRemoveValueClick}
              data-value={member.uniqueKey}
              type="button"
            >
              <FontAwesomeIcon icon={["far", "times"]} size="lg" />
            </button>
          )}
        </div>
      </div>
    </AnimateHeight>
  )
}

const hideRemovalIcon = (currentPersonId: number, member: HeadcountPlanMember): boolean => {
  if (currentPersonId.toString() === member.person.id) return true
  return false
}

export { ANIMATION_DURATION, MemberListItem }
