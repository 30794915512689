import React, { useEffect, useState } from "react"
import { useGetSuccessionMatrixSettingsQuery } from "v2/redux/GraphqlApi"
import { StoreProvider } from "v2/redux/StoreProvider"
import {
  CardTypeSchema,
  GridItemSchema,
  PaletteSchema,
  setCardType,
  setGridItems,
  setPalette,
} from "v2/redux/slices/MatrixSlice"
import { useAppDispatch } from "v2/redux/store"
import { SuccessionMatrixModal } from "./SuccessionMatrixModal"

interface EditProps {
  handleReload: () => void
}

function EditMatrixSettingsButtonInner({ handleReload }: EditProps) {
  const dispatch = useAppDispatch()
  const { data: matrix } = useGetSuccessionMatrixSettingsQuery({})
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)

  useEffect(() => {
    if (matrix) {
      dispatch(setCardType(CardTypeSchema.parse(matrix.cardType)))
      dispatch(setPalette(PaletteSchema.parse(matrix.paletteType)))
      dispatch(setGridItems(GridItemSchema.array().parse(matrix.gridItems)))
    }
  }, [matrix, dispatch])

  if (!matrix) return null

  const handleOpenModal = () => setEditModalIsOpen(true)
  const handleCloseModal = () => {
    handleReload()
    setEditModalIsOpen(false)
  }

  return (
    <>
      <button className="btn--large btn--secondary" type="button" onClick={handleOpenModal}>
        {"Edit Settings".t("defaults")}
      </button>
      <SuccessionMatrixModal isOpen={editModalIsOpen} onClose={handleCloseModal} matrix={matrix} />
    </>
  )
}

function EditMatrixSettingsButton({ handleReload }: EditProps) {
  return (
    <StoreProvider>
      <EditMatrixSettingsButtonInner handleReload={handleReload} />
    </StoreProvider>
  )
}

export { EditMatrixSettingsButton }
