import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import fp from "lodash/fp"

type SuccessionPlanTabType = "summary" | "compare" | "matrix"
// In the near future: type ProfilePanelTabType = "person" | "position"

interface PanelSidebarState {
  activePersonId: string | null
  activePositionId: string | null
  activeTab: SuccessionPlanTabType
  isOpen: boolean
}

const InitialState: PanelSidebarState = {
  activePersonId: null,
  activePositionId: null,
  activeTab: "summary",
  isOpen: false,
}

const PanelSidebarSlice = createSlice({
  name: "profilePanel",
  initialState: InitialState,
  reducers: {
    setActivePersonId: (state, { payload }: PayloadAction<string | null>) =>
      fp.set("activePersonId", payload)(state),
    setActivePositionId: (state, { payload }: PayloadAction<string | null>) =>
      fp.set("activePositionId", payload)(state),
    setActiveTab: (state, { payload }: PayloadAction<SuccessionPlanTabType>) =>
      fp.set("activeTab", payload)(state),
    setIsOpen: (state, { payload }: PayloadAction<boolean>) => fp.set("isOpen", payload)(state),
    openSuccessionPlanSidebar: (
      state,
      { payload }: PayloadAction<{ positionId: string | null }>,
    ) => {
      return fp.pipe(
        fp.set("isOpen", true),
        fp.set("activePositionId", payload.positionId),
        fp.set("activeTab", "summary"),
      )(state)
    },
    closeSuccessionPlanSidebar: (state) => {
      return fp.pipe(fp.set("isOpen", false), fp.set("activePositionId", null))(state)
    },
  },
})

export { PanelSidebarSlice, PanelSidebarState }
export const { closeSuccessionPlanSidebar, openSuccessionPlanSidebar, setActiveTab } =
  PanelSidebarSlice.actions
