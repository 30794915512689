import fp from "lodash/fp"
import { VariablePayAttributes } from "types/graphql"
import { CompensationChangeMode } from "types/graphql.enums"
import { variablePayTypeCast } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/helpers"
import { FormEntry } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/types"
import { UpdateNodeInput } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/hooks/useUpdateNodeWithMultipleInputs"

const addCompField = (inputs: FormEntry[]) => [
  ...inputs,
  ["compensation_change_mode", CompensationChangeMode.Append],
]

const groupVariablePays = (fields: FormEntry[]) => {
  const variablePays: Record<string, Partial<VariablePayAttributes>> = {}
  const inputs = [] as UpdateNodeInput[]

  fields.forEach(([fieldKey, value]) => {
    const match = fieldKey.match(/variable_pay_type_(\d+)_?(type)?/)
    if (match) {
      const id = match[1]
      const type = match[2]
      if (!variablePays[id]) {
        variablePays[id] = { variable_pay_type_id: `variable_pay_type_${id}` }
      }
      if (type) {
        variablePays[id].pay_type = value ? variablePayTypeCast(value) : null
      } else {
        variablePays[id].amount = value as string
      }
    } else {
      inputs.push({
        fieldKey: fieldKey as UpdateNodeInput["fieldKey"],
        value: value as UpdateNodeInput["value"],
      })
    }
  })

  return [
    ...inputs,
    {
      fieldKey: "variable_pays_attributes",
      value: Object.values(variablePays).filter((vp) => vp.amount),
    },
  ] as UpdateNodeInput[]
}

const processInputs = (inputs: FormEntry[]) =>
  fp.pipe(addCompField, groupVariablePays)(inputs) as UpdateNodeInput[]

export { processInputs }
