import React from "react"
import { Chart } from "types/graphql"
import {
  configureApproval,
  requestApproval,
  submittedApproval,
} from "v2/react/components/orgChart/ChartApproval/approvalUtils"
import { toggleConfigureModal } from "v2/redux/slices/ApprovalSlice"
import { selectCurrentUser } from "v2/redux/slices/ApprovalSlice/approvalSelectors"
import { useAppDispatch, useAppSelector } from "v2/redux/store"
import { PendingDropdown } from "./ApprovalDropdown/PendingDropdown"
import { SubmittedDropdown } from "./ApprovalDropdown/SubmittedDropdown"

interface Props {
  chart: Chart
}

function ApprovalBadge({ chart }: Props) {
  const currentUser = useAppSelector(selectCurrentUser)
  const dispatch = useAppDispatch()
  const openConfigureModal = () => dispatch(toggleConfigureModal(true))

  if (!currentUser) return null

  return (
    <div className="inline">
      {configureApproval(currentUser, chart.chartApprovers) && (
        <button className="btn--large btn--secondary" type="button" onClick={openConfigureModal}>
          {"configure_approvers".t("org_chart")}
        </button>
      )}
      {requestApproval(currentUser, chart.chartApprovers, chart) && (
        <PendingDropdown
          approvers={chart.chartApprovers}
          canConfigure={currentUser.canConfigure}
          canEditConfiguration={chart?.status !== "approved"}
          chartId={chart.id.toString()}
        />
      )}
      {submittedApproval(chart) && (
        <SubmittedDropdown
          chart={chart}
          approvers={chart.chartApprovers}
          currentUser={currentUser}
          openConfigureModal={openConfigureModal}
        />
      )}
    </div>
  )
}

export { ApprovalBadge }
