import axios from "axios"
import fp from "lodash/fp"
import { EntityId } from "@reduxjs/toolkit"

import type { CurrentCompanyListsConnectionQuery, Maybe } from "types/graphql"
import type { AppDispatch, RootState } from "v2/redux/store"
import type {
  ChartSettings,
  ServerChartSettings,
  TabKey,
} from "v2/redux/slices/VisualizationSlice/types"

import OperationStore from "v2/operation_store"
import { ChartSectionsFetchResponsePayloadSchema } from "v2/redux/slices/ContainerSlice/types"
import {
  chartSectionSelectors,
  collapseOrExpandChartSection,
  setChartSectionTreeIndex,
  setChartSections,
  setLists,
} from "v2/redux/slices/ContainerSlice"
import { gqlFetch } from "v2/graphql_client"
import { patchSettings, toggleTab } from "v2/redux/slices/VisualizationSlice"
import { selectChartSettings } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"

const LoadListsOperationAlias = "CurrentCompanyListsConnection"
const FetchGetOptions = Object.freeze({
  headers: { "content-type": "application/json" },
  withCredentials: true,
})

type AsyncLoadListsInput = { maybeSetId?: Maybe<string> }

/**
 * @public
 * @returns {function} an async thunk which hydrates the redux state with list.
 *   The async call resolves to a list with `maybeSetId` (or `null`).
 */
function asyncLoadLists({ maybeSetId }: AsyncLoadListsInput) {
  return async (dispatch: AppDispatch) => {
    const operationId = OperationStore.getOperationId(LoadListsOperationAlias)

    const result = await gqlFetch<CurrentCompanyListsConnectionQuery>({
      operationId,
    })

    const company = result.data.currentCompany
    const lists = company && company.lists && company.lists.nodes
    if (!lists) return null
    if (lists.length > 0) dispatch(setLists(lists))

    return lists.find((list) => list.id === maybeSetId)
  }
}

function asyncFetchChartSections() {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { current_company_id: companyId } = window.gon
    const { endpoint } = window.App
    const chartSectionsEndpointUrl: string = endpoint([
      "companies",
      companyId.toString(),
      "chart_sections",
    ])

    const result = await axios.get(chartSectionsEndpointUrl, FetchGetOptions)
    const data = ChartSectionsFetchResponsePayloadSchema.parse(result.data)
    dispatch(setChartSectionTreeIndex(data.chart_sections_nested))
    dispatch(setChartSections(data.chart_sections))
    return chartSectionSelectors.selectAll(getState())
  }
}

function asyncCollapseOrExpandChartSection(
  id: EntityId,
  collapsed: boolean | undefined = undefined,
) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(collapseOrExpandChartSection({ id, collapsed }))
    const index = getState().container.chartSectionsCollapsedIndex
    const candidates = fp.keys(index)
    const collapsedIds = fp.filter((id) => !!index[id], candidates)
    dispatch(asyncPatchPreferences({ collapsed_chart_section_ids: collapsedIds }))
  }
}

function asyncPatchPreferences(patch: Partial<ServerChartSettings>) {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<ChartSettings> => {
    try {
      const containerKey = getState().container.containerKey
      if (!containerKey) throw new Error("No containerKey")

      // Update preferences right away in the UI. In order to support the user
      // in their current task, this doesn't revert local state if the server
      // rejects our request. The common downside to this is that the a
      // hard-refresh will not restore the user's changes.
      dispatch(patchSettings(castServerChartSettings(patch)))

      const payload = fp.set(["org_chart", "chart_settings", containerKey], patch, {})
      await window.App.Preferences.update(payload)

      return selectChartSettings(getState())
    } catch (error) {
      if (typeof window !== "undefined" && window.Sentry !== undefined) {
        const { Sentry } = window
        Sentry.captureException(error)
      } else if (typeof console !== "undefined" && "error" in console) {
        console.error(error) // eslint-disable-line no-console
      }

      return selectChartSettings(getState())
    }
  }
}

function asyncToggleTab(tabKey: TabKey) {
  return async (dispatch: AppDispatch) => {
    dispatch(toggleTab(tabKey))
    await dispatch(asyncPatchPreferences({ selected_tab: tabKey }))
  }
}

const castServerChartSettings = (patch: Partial<ServerChartSettings>) =>
  fp.mapKeys(fp.camelCase, patch)

export {
  asyncCollapseOrExpandChartSection,
  asyncFetchChartSections,
  asyncLoadLists,
  asyncPatchPreferences,
  asyncToggleTab,
  castServerChartSettings,
}
