import React from "react"
import cn from "classnames"
import { Reorder } from "framer-motion"
import { DraggableListItem } from "./DraggableListItem"

interface Props<ItemType> {
  children: React.ReactNode
  dragContainerRef: React.RefObject<HTMLDivElement>
  draggableListClasses?: string
  handleReorder: (updatedItems: ItemType[]) => void
  items: ItemType[]
  useListGroupStyle?: boolean
}

function DraggableList<ItemType>({
  children,
  dragContainerRef,
  draggableListClasses,
  handleReorder,
  items,
  useListGroupStyle = true,
}: Props<ItemType>) {
  return (
    <div
      className={cn(draggableListClasses, {
        "list-group is-draggable": useListGroupStyle,
      })}
      ref={dragContainerRef}
    >
      <Reorder.Group as="div" axis="y" values={items} onReorder={handleReorder}>
        {children}
      </Reorder.Group>
    </div>
  )
}

DraggableList.Item = DraggableListItem

export { DraggableList }
