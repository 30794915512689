import { isAnyOf } from "@reduxjs/toolkit"

import { entityEventOccurred } from "v2/redux/slices/NotificationSlice"
import {
  beginGeneratingFieldSuggestion,
  beginInitializingFieldSuggestion,
  fieldsSelectors,
  FieldSuggestionEntry,
  FieldSuggestionState,
  isSocketMessage,
  matchFieldSuggestionEvent,
  matchGenerateFieldSuggestionCancelation,
  prepareUpdate,
  subscribedField,
  unsubscribedField,
  updatedField,
} from "v2/redux/slices/FieldSuggestionSlice"
import { AppListenerEffectAPI, startAppListening } from "v2/redux/listenerMiddleware"

import {
  SubscriberIndex,
  SubscriberIndexInterface,
} from "./fieldSuggestionListeners/subscriberIndex"

type WaitForUpdateWithTimeoutArg = {
  errorState: FieldSuggestionState
  successState: FieldSuggestionState
  waitingState: FieldSuggestionState

  entry: FieldSuggestionEntry
  listener: AppListenerEffectAPI
  timeoutMs?: number
}

const DEFAULT_TIMEOUT_MS = 10_000
const SPECIAL_EVENT_TYPES = [
  FieldSuggestionState.Accepted,
  FieldSuggestionState.Declined,
  FieldSuggestionState.InitializeFailed,
  FieldSuggestionState.Initializing,
  FieldSuggestionState.Initialized,
]

export const startListeningForFieldSuggestionEvents = () => {
  const subscriberIndex = new SubscriberIndex()

  const cleanUpStack = [
    startListeningForSubscribed(subscriberIndex),
    startListeningForUnsubscribed(subscriberIndex),
    startListeningForRelevantSocketUpdatesRegardlessOfState(),
    startListeningForInitializeOrRegenerate(),
    subscriberIndex.cleanUp,
  ]

  return () => cleanUpStack.forEach((cleanUp) => cleanUp())
}

/** Adds a subscriber to our socket channel state. */
const startListeningForSubscribed = (subscriberIndex: SubscriberIndexInterface) =>
  startAppListening({
    actionCreator: subscribedField,
    effect: async ({ payload }, listener) => {
      subscriberIndex.addSubscriber(listener, payload)

      // If we're in the middle of initializing when we subscribe, kick off our
      // to timeout. This ensures we don't block the UI for too long in case
      // the server is taking longer than usual *or* the suggestion becomes
      // stuck.
      //
      // If the latter occurs, there's a bug on the backend that we need to
      // patch. In the interim, users can still interact with the field due to
      // this timeout behavior.
      if (payload.state === FieldSuggestionState.Initializing)
        await updateOrTimeout({
          entry: payload,
          listener,
          timeoutMs: DEFAULT_TIMEOUT_MS,

          errorState: FieldSuggestionState.InitializeFailed,
          successState: FieldSuggestionState.Initialized,
          waitingState: FieldSuggestionState.Initializing,
        })
    },
  })

/** Removes a subscriber from our socket channel state. */
const startListeningForUnsubscribed = (subscriberIndex: SubscriberIndexInterface) =>
  startAppListening({
    actionCreator: unsubscribedField,
    effect: async ({ payload: entryId }, listener) => {
      const entry = fieldsSelectors.selectById(listener.getOriginalState(), entryId)
      if (entry) subscriberIndex.removeSubscriber(entry)
    },
  })

/**
 * Handles `beginGeneratingFieldSuggestion` by determining the appropriate action and
 * then subsequently accommodating forthcoming server updates. Deals with
 * "timeouts" where it transitions into an error state after X ms.
 */
const startListeningForInitializeOrRegenerate = () =>
  startAppListening({
    predicate: isAnyOf(beginGeneratingFieldSuggestion, beginInitializingFieldSuggestion),
    effect: async (action, listener) => {
      const isGenerateAction = beginGeneratingFieldSuggestion.match(action)
      const isInitializeAction = beginInitializingFieldSuggestion.match(action)
      if (!isGenerateAction && !isInitializeAction) return

      const {
        payload: { field, entityId, timeoutMs },
      } = action
      const id = `${entityId}-${field}`
      const entry = fieldsSelectors.selectById(listener.getState(), id)

      if (!entry) return

      if (isGenerateAction) {
        await updateOrTimeout({
          entry,
          listener,
          timeoutMs,

          errorState: FieldSuggestionState.GenerateFailed,
          successState: FieldSuggestionState.Generated,
          waitingState: FieldSuggestionState.Generating,
        })
      } else {
        await updateOrTimeout({
          entry,
          listener,
          timeoutMs,

          errorState: FieldSuggestionState.InitializeFailed,
          successState: FieldSuggestionState.Initialized,
          waitingState: FieldSuggestionState.Initializing,
        })
      }
    },
  })

/**
 * Handles critical state changes by always patching state.Always patchListens for any `entityEventOccurred` messages and conditionally updates
 * field suggestion state. A relevant event must be a transition into
 * initializing, initialized, initialize_failed, accepted, or declined.
 */
const startListeningForRelevantSocketUpdatesRegardlessOfState = () =>
  startAppListening({
    actionCreator: entityEventOccurred,
    effect: async ({ payload }, listener) => {
      if (!isSocketMessage(payload)) return

      const { eventType, field } = payload.data
      if (!SPECIAL_EVENT_TYPES.includes(eventType)) return

      const id = `${payload.subjectId}-${field}`
      const entry = fieldsSelectors.selectById(listener.getState(), id)
      if (!entry) return

      listener.dispatch(updatedField({ id, changes: prepareUpdate(entry, payload) }))
    },
  })

const updateOrTimeout = ({
  entry,
  errorState,
  listener,
  successState,
  timeoutMs = DEFAULT_TIMEOUT_MS,
  waitingState,
}: WaitForUpdateWithTimeoutArg) => {
  const { id, entityId, field } = entry
  const targetStates = [errorState, successState]
  listener.dispatch(updatedField({ id, changes: { state: waitingState } }))

  const taskWaitingForUpdateFromServer = listener.fork(async (forkApi) => {
    const matchingEvent = matchFieldSuggestionEvent({ entityId, field, into: targetStates })
    const forRelevantUpdateFromServer = listener.take(matchingEvent)

    const [{ payload: message }] = await forkApi.pause(forRelevantUpdateFromServer)
    listener.dispatch(updatedField({ id, changes: prepareUpdate(entry, message) }))
  })

  const taskWaitingForCancelation = listener.fork(async (forkApi) => {
    const actionCancelingInitializeOrGenerate = matchGenerateFieldSuggestionCancelation({
      entityId,
      field,
    })
    const forCancelActionFromUser = listener.take(actionCancelingInitializeOrGenerate)

    await forkApi.pause(forCancelActionFromUser)
    listener.dispatch(updatedField({ id, changes: { state: entry.state } }))
  })

  const taskWaitingForTimeout = listener.fork(async (forkApi) => {
    await forkApi.delay(timeoutMs)
    const errorReason = `Timed out after ${timeoutMs}ms`
    listener.dispatch(updatedField({ id, changes: { state: errorState, errorReason } }))
  })

  const untilOneTaskResolves = Promise.race([
    taskWaitingForCancelation.result,
    taskWaitingForUpdateFromServer.result,
    taskWaitingForTimeout.result,
  ])

  return listener.pause(untilOneTaskResolves)
}
