import React from "react"
import { LinkBlock } from "v2/react/components/navigation/PageNav/LinkBlock"
import PageNav from "v2/react/components/navigation/PageNav"
import { TitleBlock } from "v2/react/components/navigation/PageNav/TitleBlock"
import { Link, LinkGroup } from "v2/react/shared/PageNavigation/LinkGroup"
import { ActionBlock } from "v2/react/components/navigation/PageNav/ActionBlock"
import { ExportButton } from "v2/react/shared/ExportButton"

interface Props {
  links: Link[]
  exportUrl?: string
}

function SuccessionNav({ links, exportUrl }: Props) {
  return (
    <PageNav>
      <TitleBlock>
        <h1>{"succession".t("succession")}</h1>
      </TitleBlock>
      <LinkBlock>
        <LinkGroup links={links} />
      </LinkBlock>
      <ActionBlock>
        {exportUrl && (
          <ExportButton
            exportUrl={exportUrl}
            // The table can be searched, so we need to ensure we use those
            // params when we export.
            useCurrentSearchParamsWithExportUrl
          />
        )}
      </ActionBlock>
    </PageNav>
  )
}

export { SuccessionNav }
