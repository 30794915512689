import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { AnimatePresence, motion } from "framer-motion"
import { opaqueBasic } from "v2/react/utils/framerAnimationVariants"

interface Props {
  showStyle: boolean
}

function SaveLayer({ showStyle }: Props) {
  return (
    <AnimatePresence>
      {showStyle && (
        <>
          <motion.div
            key="bg"
            variants={opaqueBasic}
            initial="hidden"
            animate="shown"
            exit="hidden"
            className="absolute bottom-0 left-0 right-0 top-0 bg-primary-10"
          />
          <motion.div
            key="border"
            variants={opaqueBasic}
            initial="hidden"
            animate="shown"
            exit="hidden"
            className="GridBody-cell__saving border border-solid border-primary-100"
          >
            <FontAwesomeIcon
              icon={["far", "check"]}
              className="GridBody-cell__saving-icon absolute right-4 top-1/2 text-[1.5rem] text-primary-100"
              style={{
                transform: "translate(0, -50%)",
              }}
            />
          </motion.div>
        </>
      )}
    </AnimatePresence>
  )
}

export { SaveLayer }
