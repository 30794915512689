import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CalendarDate, parseDate } from "@internationalized/date"
import dayjs, { Dayjs } from "dayjs"
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import { DateRange, DateValue } from "react-aria"
import { useTranslation } from "react-i18next"
import { buildDateRangeOptions } from "v2/react/shared/DateRange/dates"
import type { PresetDateRangeKey } from "v2/react/shared/DateRange/dates"

interface Props {
  selectedRange: string
  setActiveRange: ({ start, end }: DateRange) => void
  setDialogContent: (dialogContent: string) => void
  setIsOpen: (isOpen: boolean) => void
  setSelectedRange: (rangeOptionKey: PresetDateRangeKey, dateValue?: DateRange | null) => void
  dateRanges: ReturnType<typeof buildDateRangeOptions>
  /** Excludes options from the quick select menu that project the timeline
   * beyond the current date. */
  excludeFutureOptions?: boolean
}

function QuickSelectMenu({
  selectedRange,
  setActiveRange,
  setDialogContent,
  setIsOpen,
  setSelectedRange,
  dateRanges,
  excludeFutureOptions,
}: Props) {
  const { t } = useTranslation()
  dayjs.extend(quarterOfYear)
  const formatDate = (date: Dayjs) => parseDate(date.format("YYYY-MM-DD"))

  const selectDateRange = (start: DateValue, end: DateValue, selectedValue: PresetDateRangeKey) => {
    const value = { start, end }
    setActiveRange(value)
    setSelectedRange(selectedValue, value)
    setIsOpen(false)
  }

  const selectCustomRange = () => {
    setDialogContent("calendar")
    setSelectedRange("custom_date_range")
  }

  return (
    <>
      {!excludeFutureOptions && (
        <>
          <DefaultDateSelection
            isActive={selectedRange}
            dataValue={dateRanges.current_month.value}
            textLabel={t(`v2.date.ranges.${dateRanges.current_month.value}`)}
            dateLabel={dateRanges.current_month.label}
            startDate={formatDate(dateRanges.current_month.start)}
            endDate={formatDate(dateRanges.current_month.end)}
            onClick={selectDateRange}
          />
          <DefaultDateSelection
            isActive={selectedRange}
            dataValue={dateRanges.current_quarter.value}
            textLabel={t(`v2.date.ranges.${dateRanges.current_quarter.value}`)}
            dateLabel={dateRanges.current_quarter.label}
            startDate={formatDate(dateRanges.current_quarter.start)}
            endDate={formatDate(dateRanges.current_quarter.end)}
            onClick={selectDateRange}
          />
          <DefaultDateSelection
            isActive={selectedRange}
            dataValue={dateRanges.current_year.value}
            textLabel={t(`v2.date.ranges.${dateRanges.current_year.value}`)}
            dateLabel={dateRanges.current_year.label}
            startDate={formatDate(dateRanges.current_year.start)}
            endDate={formatDate(dateRanges.current_year.end)}
            onClick={selectDateRange}
          />
          <hr className="mx-[-.5rem] my-2 h-px border-none bg-neutral-8" />
        </>
      )}
      <DefaultDateSelection
        isActive={selectedRange}
        dataValue={dateRanges.month_to_date.value}
        textLabel={t(`v2.date.ranges.${dateRanges.month_to_date.value}`)}
        dateLabel={dateRanges.month_to_date.label}
        startDate={formatDate(dateRanges.month_to_date.start)}
        endDate={formatDate(dateRanges.month_to_date.end)}
        onClick={selectDateRange}
      />
      <DefaultDateSelection
        isActive={selectedRange}
        dataValue={dateRanges.quarter_to_date.value}
        textLabel={t(`v2.date.ranges.${dateRanges.quarter_to_date.value}`)}
        dateLabel={dateRanges.quarter_to_date.label}
        startDate={formatDate(dateRanges.quarter_to_date.start)}
        endDate={formatDate(dateRanges.quarter_to_date.end)}
        onClick={selectDateRange}
      />
      <DefaultDateSelection
        isActive={selectedRange}
        dataValue={dateRanges.year_to_date.value}
        textLabel={t(`v2.date.ranges.${dateRanges.year_to_date.value}`)}
        dateLabel={dateRanges.year_to_date.label}
        startDate={formatDate(dateRanges.year_to_date.start)}
        endDate={formatDate(dateRanges.year_to_date.end)}
        onClick={selectDateRange}
      />
      <hr className="mx-[-.5rem] my-2 h-px border-none bg-neutral-8" />
      <DefaultDateSelection
        isActive={selectedRange}
        dataValue={dateRanges.last_month.value}
        textLabel={t(`v2.date.ranges.${dateRanges.last_month.value}`)}
        dateLabel={dateRanges.last_month.label}
        startDate={formatDate(dateRanges.last_month.start)}
        endDate={formatDate(dateRanges.last_month.end)}
        onClick={selectDateRange}
      />
      <DefaultDateSelection
        isActive={selectedRange}
        dataValue={dateRanges.last_quarter.value}
        textLabel={t(`v2.date.ranges.${dateRanges.last_quarter.value}`)}
        dateLabel={dateRanges.last_quarter.label}
        startDate={formatDate(dateRanges.last_quarter.start)}
        endDate={formatDate(dateRanges.last_quarter.end)}
        onClick={selectDateRange}
      />
      <DefaultDateSelection
        isActive={selectedRange}
        dataValue={dateRanges.last_year.value}
        textLabel={t(`v2.date.ranges.${dateRanges.last_year.value}`)}
        dateLabel={dateRanges.last_year.label}
        startDate={formatDate(dateRanges.last_year.start)}
        endDate={formatDate(dateRanges.last_year.end)}
        onClick={selectDateRange}
      />
      <DefaultDateSelection
        isActive={selectedRange}
        dataValue={dateRanges.last_12_months.value}
        textLabel={t(`v2.date.ranges.${dateRanges.last_12_months.value}`)}
        dateLabel={dateRanges.last_12_months.label}
        startDate={formatDate(dateRanges.last_12_months.start)}
        endDate={formatDate(dateRanges.last_12_months.end)}
        onClick={selectDateRange}
      />
      <hr className="mx-[-.5rem] my-2 h-px border-none bg-neutral-8" />
      <button
        className="w-full cursor-pointer items-center justify-between rounded bg-transparent px-3 py-2.5 flex hover:bg-neutral-3"
        onClick={selectCustomRange}
        type="button"
      >
        <span>{t("v2.date.ranges.custom_date_range")}</span>
        {selectedRange === "custom_date_range" ? (
          <FontAwesomeIcon icon={["fas", "check-circle"]} className="h-3.5 w-3.5" />
        ) : (
          <div className="w-1.5" />
        )}
      </button>
    </>
  )
}

export default QuickSelectMenu

interface defaultDateSelectionProps {
  isActive: string
  dataValue: PresetDateRangeKey
  textLabel: string
  dateLabel: string
  endDate: CalendarDate
  startDate: CalendarDate
  onClick: (start: DateValue, end: DateValue, selectedValue: PresetDateRangeKey) => void
}

function DefaultDateSelection({
  isActive,
  dataValue,
  textLabel,
  dateLabel,
  startDate,
  endDate,
  onClick,
}: defaultDateSelectionProps) {
  return (
    <button
      className="w-full cursor-pointer items-center gap-x-3 whitespace-nowrap rounded bg-transparent px-3 py-2.5 flex hover:bg-neutral-3"
      onClick={() => onClick(startDate, endDate, dataValue)}
      type="button"
    >
      <p>{textLabel}</p>
      <p className="ml-auto text-neutral-64">{dateLabel}</p>
      {isActive === dataValue ? (
        <FontAwesomeIcon icon={["fas", "check-circle"]} className="h-3.5 w-3.5" />
      ) : (
        <div className="w-3.5" />
      )}
    </button>
  )
}
