import statsHelpers from "../utils/statsHelpers"
import options, { globals } from "../constants/options"
import { NodeData } from "../node/types"

/* eslint-disable no-param-reassign */

export const isCondensibleLeafNode = (d: NodeData): boolean =>
  !!d.parent &&
  statsHelpers.numberOfGrandchildren(d.parent) === 0 &&
  (d.parent.children?.length || 0) > 1

/**
 * Sets the x, y, visualDepth, and visualShift for leaf nodes in order to
 * display these nodes "condensed". Note: this mutates the given NodeData.
 */
export const condenseLeafNodes = (
  d: NodeData,
  nodeHeightWithSpacing: number,
  assistantsShownAbove: (d: NodeData) => boolean,
): NodeData => {
  if (!isCondensibleLeafNode(d)) {
    return d
  }

  const siblings = d.parent?.children || []
  siblings.forEach((child, i) => {
    child.visualDepth = child.depth + Math.floor(i / 2)
    child.y = child.visualDepth * nodeHeightWithSpacing
    if (assistantsShownAbove(child)) {
      child.y += nodeHeightWithSpacing - options.verticalSpacing
      child.visualDepth += 1
    }

    if (!child.parent) {
      return
    }
    child.x = child.parent.x
    if (i % 2) {
      child.x += globals.nodeWidth / 2 + globals.clusterHorizontalSpacing / 2
      return
    }
    child.x -= globals.nodeWidth / 2 + globals.clusterHorizontalSpacing / 2
  })

  return d
}

export default {
  condenseLeafNodes,
}
