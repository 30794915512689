import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { Option } from "types/graphql"
import React, { useState } from "react"

interface TagListProps {
  disabled?: boolean
  onRemove: (option: Option) => void
  tagList: Option[]
}

function TagListInteractive({ disabled, onRemove, tagList }: TagListProps) {
  const [removedTagIndex, setRemovedTagIndex] = useState<number | null>(null)
  const animatingTags = removedTagIndex ? tagList.slice(removedTagIndex) : []

  const handleRemove = (tag: Option) => {
    setRemovedTagIndex(tagList.indexOf(tag))
    onRemove(tag)
  }

  return (
    <AnimatePresence initial={false} mode="popLayout">
      <>
        {tagList.map((tag, i) => (
          <motion.div
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
            transition={{
              opacity: { duration: 0.1 },
              ease: [0.645, 0.045, 0.355, 1],
              layout: {
                duration: removedTagIndex ? animatingTags.indexOf(tag) * 0.15 + 0.85 : 1,
              },
            }}
            /* eslint-disable-next-line react/no-array-index-key */
            key={tag.id + i}
            className={cn({
              "badge--blue hover removable": !disabled,
              "badge--gray": disabled,
            })}
          >
            <span className="removable-text">{tag.label}</span>
            {!disabled && (
              <button
                onClick={() => handleRemove(tag)}
                className="h-full border-none bg-transparent p-0"
                type="button"
              >
                <FontAwesomeIcon className="removable-icon" icon={["far", "times"]} />
              </button>
            )}
          </motion.div>
        ))}
      </>
    </AnimatePresence>
  )
}

export { TagListInteractive }
