import React from "react"
import { useTranslation } from "react-i18next"
import { FieldType, SaveFn } from "./types"
import { Tooltip, TooltipContent, TooltipTrigger } from "../Tooltip"
import { useCellState } from "./hooks/useCellState"
import { StyleLayers } from "./StyleLayers"
import { COMPARE_VALUES_PADDING_X, CompareValues } from "./CompareValues"
import { useCellHandlers } from "./hooks/useCellHandlers"
import { useCursorSync } from "./hooks/useCursorSync"

type Props = {
  rowId: string
  columnId: string
  currentValue: string
  saveFn: SaveFn
}
export function LongTextEditableCell({ rowId, columnId, saveFn, currentValue }: Props) {
  const cellRef = React.useRef<HTMLDivElement>(null)

  const cell = useCellState({
    currentValue,
    fieldType: FieldType.Standard,
    rowId,
    columnId,
    saveFn: (state) => saveFn(state.value),
    onEditing: () => inputRef.current?.focus(),
  })

  const inputRef = React.useRef<HTMLInputElement>(null)

  useCursorSync({ cell, cellRef, value: inputRef.current?.value ?? currentValue })

  const { handleCellClick, handleCellKeyUp } = useCellHandlers(cell, cellRef, {
    getSaveValue: () => inputRef.current?.value ?? currentValue,
  })

  const { t } = useTranslation()
  const readMoreText = t("v2.defaults.read_more")

  const availableWidth = Math.max((cellRef.current?.offsetWidth || 0) - COMPARE_VALUES_PADDING_X, 0)
  const { truncatedText } = useTruncatedText(currentValue, availableWidth, `... ${readMoreText}`)
  const showTooltip = truncatedText.length < currentValue.length

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div
      id={`${columnId}-${rowId}`}
      role="button"
      ref={cellRef}
      className="editable-cell"
      onClick={handleCellClick}
      onKeyUp={handleCellKeyUp}
    >
      <CompareValues>
        {cell.isEditing || cell.isSaving || cell.isSaved ? (
          <input
            style={{ background: "white", width: "18rem" }}
            defaultValue={currentValue}
            disabled={!cell.isEditing}
            ref={inputRef}
          />
        ) : (
          <div className="editable-cell__long-text">
            <span>{`${truncatedText}${showTooltip ? "..." : ""}`}</span>
            {showTooltip ? (
              <Tooltip placement="bottom">
                <TooltipTrigger style={{ background: "none" }} className="text-primary-100">
                  {readMoreText}
                </TooltipTrigger>
                <TooltipContent className="react-tooltip-content--sm">
                  {currentValue}
                </TooltipContent>
              </Tooltip>
            ) : null}
          </div>
        )}
      </CompareValues>
      <StyleLayers cell={cell} fieldType={FieldType.Standard} />
    </div>
  )
}

function useTruncatedText(text: string, maxWidth: number, suffix: string) {
  const [truncatedText, setTruncatedText] = React.useState(text)

  React.useEffect(() => {
    const canvas = document.createElement("canvas")
    const context = canvas.getContext("2d")
    if (!context) return
    context.font = "700 13px Satoshi, sans-serif"

    let truncatedText = text
    let width = context.measureText(text).width
    while (width > maxWidth && truncatedText.length > 0) {
      truncatedText = truncatedText.slice(0, -1)
      width = context.measureText(`${truncatedText}${suffix}`).width
    }
    canvas.remove()

    setTruncatedText(truncatedText)
  }, [text, maxWidth, suffix])

  return { truncatedText }
}
