import React from "react"
import { HeadcountPlan } from "types/graphql.d"
import { FinalizedBanner } from "../banners/FinalizedBanner"

type Props = {
  headcountPlan: HeadcountPlan | undefined
}
export function OwnerBanner({ headcountPlan }: Props) {
  if (headcountPlan?.lockedAt) {
    return (
      <FinalizedBanner
        positionsApproved={
          headcountPlan.allPositionsInPlan?.filter(
            (position) => position.type === "new" && position.status === "approved",
          ).length ?? 0
        }
      />
    )
  }

  return null
}
