import React from "react"
import { SuccessionPlanPanel } from "v2/react/components/succession/SuccessionPlanPanel"

import RootProvider from "v2/react/components/RootProvider"
import { useAppDispatch, useAppSelector } from "v2/redux/store"
import { closeSuccessionPlanSidebar } from "v2/redux/slices/PanelSidebarSlice"
import { PanelSidebar } from "./PanelSidebar"

interface SuccessionPlanPanelContainerProps {
  afterClose?: () => void
}

// This component is intended to support server side views (in addition to React
// components) where components for opening the panel are also used in separate
// parts of the DOM. See: app/views/v2/succession/dashboard.html.slim, where
// each widget also includes a table with links that open the panel, and use
// button components that use:
// succession/SuccessionPlanPanel/PanelOpenerWithTooltip.tsx. These components
// rely on the global state provided by wrapping this component in the
// RootProvider.
const WithProvider = ({ afterClose }: SuccessionPlanPanelContainerProps) => {
  const dispatch = useAppDispatch()
  const activePositionId = useAppSelector((state) => state.panelSidebar.activePositionId)
  const activeTab = useAppSelector((state) => state.panelSidebar.activeTab)
  const isSidebarOpen = useAppSelector((state) => state.panelSidebar.isOpen)

  const hidePanel = () => {
    dispatch(closeSuccessionPlanSidebar())
    if (afterClose) afterClose()
  }

  const panelType = activeTab === "summary" ? "succession" : "successionLarge"

  return (
    <PanelSidebar isOpen={isSidebarOpen} panelType={panelType}>
      <SuccessionPlanPanel hidePanel={hidePanel} positionId={activePositionId} />
    </PanelSidebar>
  )
}

const SuccessionPlanPanelContainer = ({ afterClose }: SuccessionPlanPanelContainerProps) => (
  <RootProvider>
    <WithProvider afterClose={afterClose} />
  </RootProvider>
)

export { SuccessionPlanPanelContainer }
