import { ControlProps } from "@jsonforms/core"
import React, { FC } from "react"
import { useGetPositionForListQuery } from "v2/redux/GraphqlApi"
import { useJsonForms } from "@jsonforms/react"
import dayjs from "dayjs"

const JsonModuleSubtitledItem: FC<ControlProps> = ({ data, label, uischema, visible }) => {
  const context = useJsonForms()
  const formData = context?.core?.data

  const { data: positionData } = useGetPositionForListQuery(
    { positionId: data.id },
    { skip: !data.name },
  )

  if (!visible || !data) return null

  const formDataCreatedAtValue = formData.createdAt
  const formDataCreatedByValue = formData.createdBy

  const filledByDisplay = () => {
    if (!data.name) return <span />
    const personInPosition = data.name.split(", ")[0]

    return (
      <div>
        <div>{positionData?.position?.title || ""}</div>
        <div className="text-sm text-neutral-64">
          {"filled_by".t("job_requisition")} {personInPosition}
        </div>
      </div>
    )
  }

  const createdByDisplay = () => (
    <div>
      <div>{data}</div>
      <div className="text-sm text-neutral-64">
        {"created_by".t("job_requisition", null, null, null, [
          formDataCreatedByValue,
          dayjs(formDataCreatedAtValue).format("M/D/YY"),
        ])}
      </div>
    </div>
  )

  const determineSubtitleDisplay = () => {
    if (!uischema.options?.subtitleValue) return <span />
    if (uischema.options?.subtitleValue === "createdBy") return createdByDisplay()
    if (uischema.options?.subtitleValue === "filledBy") return filledByDisplay()

    return <span />
  }

  return (
    <div className="module-card-list-item">
      <label className="text-neutral-64">{label}</label>
      {determineSubtitleDisplay()}
    </div>
  )
}

export { JsonModuleSubtitledItem }
