Utils = {
  camelToSnakeCase: (str) -> str.replace(/[A-Z]/g, (letter) -> "_#{letter.toLowerCase()}"),
  camelToKebabCase: (str) -> str.replace(/[A-Z]/g, (letter) -> "-#{letter.toLowerCase()}")
}

Selectors = {
  fundingSourceRows: (d) -> fundingSourceRows(d),
  totalRow: () -> $('#fs-total-row'),
}

State = {
  allocationType: () -> $('.funding-source-position-table a.dropdown-menu-link.active').data('selectorValue'),
  rowCount: () -> fundingSourceRows().length,
  autocompleteData: (d) -> getFundingSourceAutocompleteData(d),
  totalAnnualAmount: (d) -> getTotalAnnualAmount(d),
  tooltip: (d) -> getDataForTooltip(d),
  fundingSourceIds: (d) -> fundingSourceIdsPresent(d),
  currentAmountForRow: (d) -> getCurrentAmountForRow(d),
}

Events = {
  addFundingSource: () -> addFundingSource,
  cancelAddFundingSource: () -> cancelAddFundingSource,
  addFundingSourceRow: () -> addFundingSourceRow,
  removeFundingSourceRow: () -> removeFundingSourceRow,
  toggleAllocationType: () -> toggleAllocationType,
}

Types = {
  PERCENT: 'percent_of_budget',
  AMOUNT: 'amount'
}

Constants = {
  SUB_FORM_MARGIN: 8,
}

# Selectors

fundingSourceRows = (include = []) ->
  selector = '.fs-row'
  include.forEach (klass) ->
    selector = selector.concat(', ', klass)
  $(selector)

# State

getFundingSourceAutocompleteData = (keys = null) ->
  dataKeys = keys || ['fundingSourceId', 'fundingSourceAmount', 'amountAllocatedToOtherPositions', 'name', 'dateRange']
  autocompleteData = $('#position_funding_source_autocomplete').data()
  data = {}
  dataKeys.forEach (key) -> data[key] = autocompleteData[key]
  return data

getTotalAnnualAmount = (asFloat = false) ->
  val = $('#total_').val()
  return val unless asFloat
  App.Helpers.parseCurrency(val) || 0.00

getDataForTooltip = ($row) ->
  data = $row.data()
  allocatedToThisPosition = State.currentAmountForRow($row)
  fundingSourceAmount = data['fundingSourceAmount']
  allocatedToOtherPositions = data['amountAllocatedToOtherPositions']
  calculatedData = {
    allocatedToThisPosition,
    remainingUnallocated: fundingSourceAmount - allocatedToOtherPositions - allocatedToThisPosition
  }
  Object.assign(data, calculatedData)

fundingSourceIdsPresent = () ->
  funding_source_ids = []
  Selectors.fundingSourceRows().each (index, row) ->
    funding_source_ids.push($(row).data('fundingSourceId'))
  return funding_source_ids

getCurrentAmountForRow = ($row) ->
  row_amount = null
  if State.allocationType() == Types.AMOUNT
    row_amount = $row.find('.funding-source-amount-field').val()
  else
    row_amount = $row.find('input[id^="amount_text"]').val()
  App.Helpers.parseCurrency(row_amount) || 0.00

# Events

addFundingSource = ->
  $fundingSourcePopover = $('#funding-sources-position-form')
  handleFundingSourcePopoverPositioning($fundingSourcePopover)
  $fundingSourcePopover.removeClass('hidden')
  $('#position_funding_source_autocomplete').focus()
  handleSettingButtonStates(disable = true)

cancelAddFundingSource = ->
  $('#funding-sources-position-form').addClass('hidden')
  $('#position_funding_source_autocomplete').val('')
  clearAddFundingSourceErrors()
  handleSettingButtonStates(disable = false)

addFundingSourceRow = (e) ->
  return unless validateAddFundingSourceForm()
  cancelAddFundingSource()
  renderFundingSourceRow()
  updateExcludedIds()

removeFundingSourceRow = ->
  rowToRemove =  $(this).closest('[data-funding-source-row]')
  if rowToRemove.find('.id-field').val()
    rowToRemove.addClass('fs-to-delete')
    rowToRemove.removeClass('fs-row')
    rowToRemove.find('.destroy-form').val(true)
    clearFundingSourceInputErrors(rowToRemove)
    rowToRemove.hide()
  else
    rowToRemove.remove()
  numRows = State.rowCount()
  toggleTotalRow(show = false) if numRows == 1
  updateFundingSourceAmounts()
  updateFundingSourceRowIndexes()
  updateExcludedIds()
  if numRows == 0
    toggleTypeButton(show = false)
    $('.funding-source-rows').addClass('hidden')

toggleAllocationType = ->
  clicked_type = $(this).data('selectorValue')
  return unless dropdownChanged(clicked_type)
  toggleFieldFormat(clicked_type)
  clearAllFields()
  clearFundingSourceInputErrors()
  updateFundingSourceAmounts()
  updateAllocationWarningTooltip()
  setAllocationFormValues(clicked_type)
  Selectors.fundingSourceRows().first().find('.funding-source-amount-field').focus()

# Helpers

handleFundingSourcePopoverPositioning = ($fundingSourcePopover) ->
  $triggerButton = $('#add-new-funding-source')

  if State.rowCount() <= 2
    # Positiom above
    formHeight = $fundingSourcePopover.outerHeight()

    $fundingSourcePopover
      .css('top', -($triggerButton.position().top + Constants.SUB_FORM_MARGIN + formHeight))
  else
    # Position below
    $fundingSourcePopover
      .css('top', $triggerButton.position().top + $triggerButton.outerHeight() + Constants.SUB_FORM_MARGIN)

setAddFundingSourceButtonState = (disable) ->
  $('#add-new-funding-source')?.prop('disabled', disable).toggleClass('disabled', disable)

setModalSubmitButtonState = (disable) ->
  $modalSubmitButton = $('.modal-footer input[type=submit]')
  $modalSubmitButton?.prop('disabled', disable).toggleClass('disabled', disable)

handleSettingButtonStates = (disable) ->
  setAddFundingSourceButtonState(disable)
  setModalSubmitButtonState(disable)

createFundingSourceRow = (index, data = {}) ->
  $newRow = fetchOrCloneRow(index, data)

  updateFundingSourceRowIndex(index, $newRow)
  setRowFormValues(index, $newRow)
  $('.funding-source-rows')
    .append($newRow)
    .removeClass('hidden')
  updateFundingSourceRowIndexes() if $('.fs-to-delete').length > 0
  return $newRow

fetchOrCloneRow = (index, data = {}) ->
  data = Object.assign(State.autocompleteData(), data)
  $newRow = null
  # Look for existing funding source in deleted rows, so as not to create a new record
  $existingRow = $(".fs-to-delete[data-funding-source-id='#{data.fundingSourceId}']")
  if $existingRow.length > 0
    $newRow = $existingRow
    $newRow.removeClass('fs-to-delete')
    $newRow.addClass('fs-row')
    $newRow.find('.destroy-form').val(false)
    $newRow.show()
    unless index == 0
      $newRow.find('.funding-source-amount-field').val('')
  else
    $newRow = cloneRow()
    $newRow.find('.funding-source-name').text(data.name)
    $newRow.data(data)
  return $newRow

cloneRow = () ->
  $newForm = $('.fs-template-row').clone()
  $newForm.removeClass('hidden fs-template-row')
  $newForm.addClass('fs-row')

setRowFormValues = (index, $row) ->
  data = $row.data()
  hiddenFields = ['fundingSourceId']
  hiddenFields.forEach (dataKey) ->
    field = $row.find("#position_funding_source_#{index}_#{Utils.camelToSnakeCase(dataKey)}")
    field.val(data[dataKey])
  if index == 0
    valForFirstRow = if State.allocationType() == Types.PERCENT then 100 else State.totalAnnualAmount()
    $row.find('.funding-source-amount-field').val(valForFirstRow)

renderFundingSourceRow = () ->
  numRows = State.rowCount()

  $newForm = createFundingSourceRow(numRows)

  toggleTypeButton(show = true) if numRows == 0
  toggleTotalRow(show = true) if numRows == 1
  updateFundingSourceAmounts()
  updateRowTooltip($newForm)
  attachFundingSourceRowEvents()
  $newForm.find('.funding-source-amount-field').focus() if numRows > 0

updateFundingSourceRowIndex = (index, $row) ->
  $row.attr('data-index', index)
  $row.find('[id^="position_funding_source_"]').each (i, el) ->
    $(this).attr('id', $(this).attr('id').replace(/[-]?\d+/, index))
  $row.find('[name^="position[funding_source_positions_attributes]"]').each (i, el) ->
    $(this).attr('name', $(this).attr('name').replace(/[-]?\d+/, index))

updateFundingSourceRowIndexes = () ->
  Selectors.fundingSourceRows().each((index, row) ->
    updateFundingSourceRowIndex(index, $(row))
  )
  numRows = State.rowCount()
  $('.fs-to-delete').each((index, row) ->
    updateFundingSourceRowIndex(index + numRows, $(row))
  )

updateFundingSourceAmounts = () ->
  updateFundingSourceAmountText()
  updateTotalRow()
  updateRowsTooltips()
  updateAllocationWarningTooltip()

updateAllocationWarningTooltip = () ->
  return toggleAllocationWarning(0.0) if State.rowCount() == 0
  $totalRow = Selectors.totalRow()
  if State.allocationType() == Types.PERCENT
    return toggleAllocationWarning(0.0) if State.totalAnnualAmount(asFloat = true) == 0.00
    percent = parseFloat($totalRow.find('#funding_source_total_funding').val()) || 0.00
    toggleAllocationWarning(100.0 - percent)
  else
    budgetTotal = State.totalAnnualAmount(asFloat = true)
    fundingTotal = App.Helpers.parseCurrency($totalRow.find('#funding_source_total_funding').val()) || 0.00
    toggleAllocationWarning(budgetTotal - fundingTotal)

updateRowsTooltips = () ->
  return unless State.rowCount() > 0
  Selectors.fundingSourceRows().each (i, el) ->
    $row = $(el)
    updateRowTooltip($row)

updateRowTooltip = ($row) ->
  $tooltip = $row.find('.tooltip-content')
  data = State.tooltip($row)
  dataKeys = Object.keys(data)
  dataKeys.forEach (key) ->
    $field = $tooltip.find(".#{Utils.camelToKebabCase(key)}")
    val = data[key]
    if val < 0 && key == 'remainingUnallocated'
      $field.addClass('badge--red')
    else
      $field.removeClass('badge--red')
    unless ['name', 'dataRange'].includes(key)
      val = App.Helpers.formatCurrency(val, trailing: true)
    $field.text(val)

toggleAllocationWarning = (budget_vs_funding_diff = 0.0) ->
  $warningWrapper = $('#fund-allocation-warning-wrapper')
  $warningWrapper.toggle(budget_vs_funding_diff != 0.0)
  $warningWrapper.find('#too_many_funds').toggle(budget_vs_funding_diff <= 0.0)
  $warningWrapper.find('#not_enough_funds').toggle(budget_vs_funding_diff >= 0.0)

updateFundingSourceAmountText = () ->
  return unless State.rowCount() > 0
  return unless State.allocationType() == Types.PERCENT
  $rows = Selectors.fundingSourceRows()
  $rows.each (i, el) ->
    $amount_text = $(el).find('input[id^="amount_text"]')
    percent = (parseFloat($(el).find('.funding-source-amount-field').val()) / 100.00) || 0.00
    total = State.totalAnnualAmount(asFloat = true)
    $amount_text.val(App.Helpers.formatCurrency((total * percent), omitSymbol: true, trailing: true))

updateTotalRow = () ->
  return unless State.rowCount() > 0
  type = State.allocationType()
  totalVal = 0
  totalAmount = 0
  Selectors.fundingSourceRows().each (i, el) ->
    $el = $(el)
    val = $el.find('.funding-source-amount-field').val() || 0.00
    if type == Types.PERCENT
      totalVal += parseFloat(val) || 0.00
      totalAmount += App.Helpers.parseCurrency($el.find('input[id^="amount_text"]').val())
    else
      totalVal += App.Helpers.parseCurrency(val)
  totalVal = App.Helpers.formatCurrency(totalVal, omitSymbol: true, trailing: true) if type == Types.AMOUNT
  totalVal = totalVal.toFixed(2) if type == Types.PERCENT
  Selectors.totalRow().find('#funding_source_total_funding').val(totalVal)
  if type == Types.PERCENT
    Selectors.totalRow().find('input[id^="amount_text"]').val(App.Helpers.formatCurrency(totalAmount, omitSymbol: true, trailing: true))

toggleTypeButton = (show = true) ->
  $('.funding-source-type-dropdown').toggle(show)

toggleTotalRow = (show = true) ->
  Selectors.totalRow().toggle(show)

toggleFieldFormat = (clicked_type = null) ->
  $input_add_on = $('.funding-source-position-table__amount-field .prefix')
  # On mount, there is no clicked type, so we retrive the current active link
  active_type = if clicked_type then clicked_type else $('.funding-source-type-dropdown .dropdown-menu-link.active').data('selectorValue')
  other_type = Object.values(Types).filter((k) -> k != active_type)[0]

  $input_add_on.each ->
    $(@).find("[data-type='#{active_type}']").show()
    $(@).find("[data-type='#{other_type}']").hide()
  Selectors.fundingSourceRows(['.fs-template-row']).each (index, row) ->
    $row = $(row)
    $row.find('[name^="position[funding_source_positions_attributes]"]').each (i, el) ->
      $(this).attr('name', $(this).attr('name').replace(other_type, active_type))
    $row.find('[id^="position_funding_source_"]').each (i, el) ->
      $(this).attr('id', $(this).attr('id').replace(other_type, active_type))
  toggleAmountText(active_type == Types.PERCENT)
  toggleFillerCloseIcon(active_type == Types.PERCENT)

setAllocationFormValues = (clicked_type) ->
  Selectors.fundingSourceRows(['.fs-template-row']).find('.allocation-type-field').val(clicked_type)

dropdownChanged = (clicked_type) ->
  $('.amount-text-wrapper').is(':visible') == (clicked_type == Types.AMOUNT)

clearAllFields = () ->
  Selectors.fundingSourceRows().each (index, row) ->
    $row = $(row)
    $row.find('.funding-source-amount-field').val('')
    $row.find('input[id^="amount_text"]').val(App.Helpers.formatCurrency(0, omitSymbol: true, trailing: true))
  Selectors.totalRow().find('.funding-source-amount-field').val(0)
  Selectors.totalRow().find('input[id^="amount_text"]').val(App.Helpers.formatCurrency(0, omitSymbol: true, trailing: true))

toggleAmountText = (show = false) ->
  $('.funding-source-position-table__row .amount-text-wrapper')
    .toggleClass('hidden', !show)
  $('.funding-source-position-table__amount-field')
    .toggleClass('sm:col-start-3', !show)
  $('.funding-source-label-wrapper')
    .toggleClass('sm:col-span-2', !show)

toggleFillerCloseIcon = (show = false) -> $('.filler-close-icon').toggle(show)

updateExcludedIds = () ->
  excluded_ids = State.fundingSourceIds()
  autocompleteData = $('#position_funding_source_autocomplete').data()
  autocompleteParams = autocompleteData['autocompleteParams']
  updatedAutocompleteParams = Object.assign(autocompleteParams, { 'exclude': excluded_ids.join(',') })
  updatedData = Object.assign(autocompleteData, updatedAutocompleteParams)
  $('#position_funding_source_autocomplete').data(updatedData)

validateAddFundingSourceForm = () ->
  $input = $('#position_funding_source_autocomplete')
  fundingSourceName = $input.data('name')
  fundingSourceId = $input.data('funding-source-id')
  fundingSourceIdPresent = State.fundingSourceIds().includes(fundingSourceId)
  if fundingSourceId && !fundingSourceIdPresent && (fundingSourceName == $input.val())
    return true
  else
    $fundingSourcePositionForm = $('#funding-sources-position-form')
    $fundingSourcePositionForm.addClass('form-error')
    $('#add-funding-source-error')
      .removeClass("hidden")
      .addClass("flex")
    handleFundingSourcePopoverPositioning($fundingSourcePositionForm)
    return false

clearAddFundingSourceErrors = () ->
  $('#add-funding-source-error')
    .removeClass("flex")
    .addClass("hidden")
  $('#funding-sources-position-form').removeClass('form-error')

clearFundingSourceInputErrors = ($rows = fundingSourceRows()) ->
  $rows.find('.form-error').removeClass('form-error')
  $rows.find('.icon-error-wrapper').remove()

addFundingSourceFormEvents = ($addFundingSourceForm) ->
  return if $addFundingSourceForm.length == 0
  formIsVisible = -> $addFundingSourceForm.is(':visible')

  # Ensures the popover closes when clicking outside of it
  $(document).mousedown (e) ->
    return unless formIsVisible()
    isFundingSourceFormClicked = $addFundingSourceForm.is(e.target) or $addFundingSourceForm.has(e.target).length > 0

    unless isFundingSourceFormClicked
      Events.cancelAddFundingSource()()

  # Ensures the popover closes when tabbing out of it
  $addFundingSourceForm.on 'focusout', (e) ->
    return unless formIsVisible()
    setTimeout ->
      return if document.activeElement == document.body
      return if $addFundingSourceForm.has(document.activeElement).length
      Events.cancelAddFundingSource()()
    , 0

  $addFundingSourceForm.find('[data-action=cancel-add-funding-source]').on 'click', Events.cancelAddFundingSource()
  $addFundingSourceForm.find('[data-action=add-funding-source-row]').on 'click', Events.addFundingSourceRow()

attachFundingSourceRowEvents = () ->
  $('[data-action=remove-funding-source-row]').off('click').on 'click', Events.removeFundingSourceRow()
  $('.fs-row .funding-source-amount-field').off('keyup').on 'keyup', () ->
    updateFundingSourceAmounts()
    updateRowTooltip($(this).closest('[data-funding-source-row]'))
  $('.select select, .relative .input:not([readonly])')
    .on('focus', () -> $(this).parents('.select, .relative').addClass('active'))
    .on('blur', () -> $(this).parents('.select, .relative').removeClass('active'))

fundingSourceEvents = (scope) ->
  toggleFieldFormat()
  toggleTotalRow(State.rowCount() > 1)
  $scope = $(scope)
  addFundingSourceFormEvents($scope.find('#funding-sources-position-form'))

  $scope.find('#add-new-funding-source').on 'click', Events.addFundingSource()
  $scope.find('[data-action=remove-funding-source-row]').on 'click', Events.removeFundingSourceRow()
  $scope.find('.fs-row .funding-source-amount-field').on 'keyup', () ->
    updateFundingSourceAmounts()
    updateRowTooltip($(this).closest('[data-funding-source-row]'))
  $scope.find('.funding-source-type-selector .dropdown-menu-link').on 'click', Events.toggleAllocationType()

  $fundingSourceAutocomplete = $scope.find('#position_funding_source_autocomplete')

  $fundingSourceAutocomplete.on 'autocomplete:renderSuggestions', (e) ->
    $autocompleteList = $('#funding-sources-position-form').find('.autocomplete-list')
    return unless $autocompleteList.length > 0

    $autocompleteTarget = $(e.target)
    $modalContainer = $scope.find('div[data-pjax-container="modal-content"]')
    modalOrWindowBottom = $modalContainer.offset().top + $modalContainer.outerHeight()
    inputBottom = $autocompleteTarget.offset().top + $autocompleteTarget.outerHeight()
    availableMaxHeight = modalOrWindowBottom - inputBottom - (3 * Constants.SUB_FORM_MARGIN)

    currentMaxHeight = parseInt($autocompleteList.css('max-height').replace('px', ''))
    autocompleteHeight = Math.min(availableMaxHeight, currentMaxHeight)

    $autocompleteList.css('max-height', "#{autocompleteHeight}px")

  $fundingSourceAutocomplete.on 'autocomplete:selectSuggestion', (e, selection) ->
    $('#position_funding_source_autocomplete').data(selection.data())
    if validateAddFundingSourceForm() then clearAddFundingSourceErrors()

  $fundingSourceAutocomplete.on 'autocomplete:inputChanged', (e) ->
    $input = $(e.currentTarget)
    inputValue = $input.val().trim()
    fundingSourceName = $input.data('name')
    if (inputValue != fundingSourceName)
      $input.removeData('funding-source-name')
      $input.removeData('funding-source-id')

export { fundingSourceEvents, updateFundingSourceAmounts }
