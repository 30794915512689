import classNames from "classnames"
import React, { ChangeEvent, MutableRefObject } from "react"
import { InputMaybe } from "types/graphql"

interface Props {
  defaultValue?: InputMaybe<string> | undefined
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  inputRef?: MutableRefObject<HTMLInputElement | null>
  label: string
  name: string
  showError?: boolean
  errorMessage?: string
}

function DateInput({
  inputRef,
  name,
  label,
  defaultValue,
  onChange,
  showError,
  errorMessage,
}: Props) {
  return (
    <div className={classNames("input-group", { "form-error": showError })}>
      <label htmlFor={name} className="cursor-default">
        {label}
      </label>
      <div style={{ width: "100%" }}>
        <input
          type="date"
          className="input"
          id={name}
          name={name}
          defaultValue={defaultValue || ""}
          onChange={onChange}
          ref={inputRef}
        />
      </div>
      {/* NOTE: This uses a style closer to the JsonFormControl version of InputErrorText */}
      {/* When redesigned sections are aligned, consolidate the 2 InputErrorText components */}
      {showError && errorMessage && <p className="my-1 text-status-critical">{errorMessage}</p>}
    </div>
  )
}

export { DateInput }
