handleShowAddNewPersonForm = (e, $nameInput) ->
  e.preventDefault()
  e.stopPropagation()

  # This is the distance the subform will be from the bottom of the input
  SUB_FORM_MARGIN = 8

  # Add a disabled state to the main modal button (if it exists)
  toggleModalSubmitButtonState(true)

  $autocompleteContainer = $nameInput.siblings('.autocomplete-container')
  $autocompleteContainer.hide()

  fullName = $nameInput.val().trim()
  firstName = fullName.substr(0, fullName.indexOf(' '))
  lastName = fullName.substr(fullName.indexOf(' ') + 1)

  $addNewPersonForm = $('#add-new-person-form')
  # Ensures that we have a pointer to the relevant name input
  $addNewPersonForm.data('for-name-input-id', $nameInput.attr('id'))

  $firstNameInput = $addNewPersonForm.find('#add-new-person-form-first-name')
  $lastNameInput = $addNewPersonForm.find('#add-new-person-form-last-name')

  $firstNameInput.val(if !firstName || !lastName then fullName else firstName)
  $lastNameInput.val(if !firstName || !lastName then '' else lastName)

  # Reinitialize create/cancel event handlers
  $createNewPerson = $addNewPersonForm.find('#create-person-action')
  $createNewPerson.off 'click'
  $createNewPerson.on 'click', (e) -> saveNewPerson(e, $nameInput)

  $cancelCreatePerson = $addNewPersonForm.find('#cancel-create-person')
  $cancelCreatePerson.off 'click'
  $cancelCreatePerson.on 'click', (e) -> handleCreatePersonFormCancel(e, $nameInput)

  # Position the add new person form below the name input, then focus the first name input
  $inputContainer = $nameInput.parent()
  $addNewPersonForm.css('top', $inputContainer.position().top + $inputContainer.height() + SUB_FORM_MARGIN)
  $addNewPersonForm.removeClass('hidden')
  $firstNameInput.focus()

toggleModalSubmitButtonState = (disable) ->
  $modalSubmitButton = $('.modal-footer input[type=submit]')

  if disable
    $modalSubmitButton?.prop('disabled', true)
    $modalSubmitButton?.addClass('disabled')
  else
    $modalSubmitButton?.prop('disabled', false)
    $modalSubmitButton?.removeClass('disabled')

removeCreatePersonErrors = ->
  $('#add-new-person-form .input-group').removeClass('form-error')
  $('.create-person-error .form-error-message').remove()

addPersonRow = (e) ->
  e.preventDefault()

  $inputGroup = $(this).parents('.input-group')
  $newForm = $inputGroup.find('.person-form').last().clone()
  $newForm.find('.autocomplete-container').remove()
  oldIndex = parseInt($newForm.data('index'))
  newIndex = oldIndex + 1
  $newForm.data('index', newIndex)
  $newForm.attr('data-index', newIndex)

  $idInput = $newForm.find('.person-id')
  $idInput.val('')

  $idInput.attr('name', $idInput.attr('name').replace(oldIndex, newIndex))
  $idInput.attr('id', $idInput.attr('id').replace(oldIndex, newIndex))

  $nameInput = $newForm.find('.person-name')
  $nameInput.val('')
  $nameInput.attr('name', $nameInput.attr('name').replace(oldIndex, newIndex))
  $nameInput.attr('id', $nameInput.attr('id').replace(oldIndex, newIndex))
  $nameInput.data('id', $nameInput.data('id').replace(oldIndex, newIndex))
  $nameInput.attr('data-id', $nameInput.data('id'))

  $positionManager = $newForm.find('.position-manager-wrapper')
  $positionManager.empty()
  $positionManager.data('pjax-container', $positionManager.data('pjax-container').replace(oldIndex, newIndex))
  $positionManager.attr('data-pjax-container', $positionManager.data('pjax-container'))

  $employeeTypeManager = $newForm.find('.employee-type-manager-wrapper')
  $employeeTypeManager.empty()
  $employeeTypeManager.data('pjax-container', $employeeTypeManager.data('pjax-container').replace(oldIndex, newIndex))
  $employeeTypeManager.attr('data-pjax-container', $employeeTypeManager.data('pjax-container'))

  $inputGroup.find('#filled-by-list').append($newForm)

  $.onmount()
  $newForm.each applyPersonEvents
  $('#add-person-row').addClass('hidden')
  $nameInput.trigger('focus')
  updateExcludedPersonIds()

handleUnassignButtonClick = (e, $unassignButton) ->
  e.preventDefault()

  $targetNameInput = $unassignButton.siblings('.person-name')
  $targetNameInput.val('')
  $targetNameInput.attr('value', '')
  $targetNameInput.trigger('focus')

  $inputWrapper = $unassignButton.parent()
  $inputWrapper.siblings('.person-id').val('')
  $inputWrapper.siblings('.position-manager-wrapper').empty()
  $inputWrapper.siblings('.employee-type-manager-wrapper').empty()

  if $('.person-form').length > 1
    $unassignButton.parents('.person-form').remove()

  handleUnassignButtonRendering()
  handleAddPersonButtonRendering()
  updateExcludedPersonIds()

applyPersonEvents = ->
  $personNameInput = $(this).find('.person-name')

  # Ensures the new person form can be accessed via keyboard
  $personNameInput.on 'keydown', (e) ->
    $highlighted = $(this).siblings('.autocomplete-container:visible').find('.highlight')
    return unless $highlighted?.hasClass('add-new-person')
    key = e.which
    if Keyboard.keyMatchesName(key, 'enter') then handleShowAddNewPersonForm(e, $personNameInput)

  # Handles clearing things out / rendering dependencies when the name field is changed
  $personNameInput.on 'input', (e) ->
    handleUnassignButtonRendering()
    personIdExists = $(this).parent().siblings('.person-id').val().length > 0
    return unless personIdExists

    $(this).parent().siblings('.person-id').val('')
    $(this).parent().siblings('.position-manager-wrapper').empty()
    $(this).parent().siblings('.employee-type-manager-wrapper').empty()
    $("#add-person-row").addClass('hidden')
    updateExcludedPersonIds()

  $(this).find('.unassign-button').on 'click', (e) ->
    handleUnassignButtonClick(e, $(this))

  $personNameInput.on 'blur', (e) ->
    $relatedTarget = $(e.relatedTarget)

    if $relatedTarget.hasClass('unassign-button')
      handleUnassignButtonClick(e, $relatedTarget)

    # If we're opening the add new person form, we don't want to clear the data.
    EXCLUDED_IDS = ['show-add-new-person-form', 'add-new-person-form-first-name']
    relatedTargetId = $relatedTarget.attr('id')
    return if EXCLUDED_IDS.includes(relatedTargetId)
    handleNameInputBlur($(this))

  $personNameInput.on 'autocomplete:selectSuggestion', (e, selection) ->
    personId = $(selection).data('id')
    loadPositionManager($(this), personId)
    loadEmployeeTypeManager($(this), personId)
    $('#add-person-row').removeClass('hidden')
    $personNameInput.attr('value', $personNameInput.val())
    $personNameInput.siblings('.unassign-button').removeClass('hidden')
    updateExcludedPersonIds()

handleNameInputBlur = ($input) ->
  personIdExists = $input.parent().siblings('.person-id').val().length > 0
  currentInput = $input.val()
  storedInput = $input.attr('value')

  # We've cleared the input or otherwise changed the value, so we need to
  # clear the person data and associated managers.
  if currentInput != storedInput || !personIdExists
    $inputWrapper = $input.parent()
    $inputWrapper.siblings('.person-id').val('')
    $inputWrapper.siblings('.position-manager-wrapper').empty()
    $inputWrapper.siblings('.employee-type-manager-wrapper').empty()
    $input.val('')
    $input.attr('value', '')
    handleUnassignButtonRendering()
    handleAddPersonButtonRendering()
    updateExcludedPersonIds()

handleUnassignButtonRendering = () ->
  $personForm = $('.person-form')
  if $personForm.length == 1
    valueIsPresent = $personForm.find('.person-name').val().length > 0
    $unassignButton = $personForm.find('.unassign-button')

    if valueIsPresent
      $unassignButton.removeClass('hidden')
    else
      $unassignButton.addClass('hidden')

handleAddPersonButtonRendering = () ->
  # We show the add person button if there are no empty name fields.
  $nameInputs = $('.person-form').find('.person-name')
  anyEmptyNameFields = false

  $nameInputs.each (index, nameInput) ->
    if $(nameInput).val().trim().length == 0
      anyEmptyNameFields = true
      return
  $addPersonButton = $('#add-person-row')

  if anyEmptyNameFields
    $addPersonButton.addClass('hidden')
  else
    $addPersonButton.removeClass('hidden')

# This ensures you can't attempt to add the same person to a position twice.
updateExcludedPersonIds = () ->
  $filledByAutocompletes = $('.person-name')
  excludedIds = []
  $('.person-id').each (index, personId) ->
    excludedIds.push($(personId).attr('value'))

  $filledByAutocompletes.each (index, autocompleteInstance) ->
    $autocompleteInstance = $(autocompleteInstance)
    autocompleteData = $autocompleteInstance.data()
    autocompleteParams = autocompleteData['autocompleteParams']
    updatedAutocompleteParams = Object.assign(autocompleteParams, { 'exclude': excludedIds.join(',') })
    updatedData = Object.assign(autocompleteData, updatedAutocompleteParams)
    $autocompleteInstance.data(updatedData)

saveNewPerson = (e, $nameInput) ->
  e.preventDefault()
  removeCreatePersonErrors()
  firstName = $('#add-new-person-form-first-name').val()
  lastName = $('#add-new-person-form-last-name').val()

  # Set button to saving state
  $createNewPerson = $('#create-person-action')
  $createNewPerson?.prop('disabled', true)
  $createNewPerson?.addClass('disabled')
  $createNewPerson?.text('add_disabled'.t("button_defaults"))

  $.ajax({
    type: 'POST',
    url: '/api/app/v1/people',
    data: {
      person: { first_name: firstName, last_name: lastName },
      use_detailed_errors: true,
    }
    success: (data) ->
      autocomplete = $nameInput.data('autocomplete-instance')
      autocomplete.cachedResponse = {}
      $nameInput.parent().siblings('.person-id').val("#{data['id']}")
      $nameInput.val(data['first_name'].concat(' ', data['last_name']))
      $nameInput.attr('value', $nameInput.val())
      $nameInput.parent().show()

      $('#add-new-person-form').addClass('hidden')
      handleUnassignButtonRendering()
      handleAddPersonButtonRendering()
      removeCreatePersonErrors()
      toggleModalSubmitButtonState(false)
    error: (e) ->
      errors = e['responseJSON']['errors']
      lastNameErrors = errors['last_name']
      firstNameErrors = errors['first_name']

      if firstNameErrors
        $('#add-new-person-form-first-name').closest(".input-group").addClass('form-error')
        $("#add-new-person-form-first-name-error")
          .append($('<div class="form-error-message"></div>')
          .text(firstNameErrors.join(', ')))
      if lastNameErrors
        $('#add-new-person-form-last-name').closest(".input-group").addClass('form-error')
        $("#add-new-person-form-last-name-error")
          .append($('<div class="form-error-message"></div>')
          .text(lastNameErrors.join(', ')))
    complete: ->
      $createNewPerson?.removeClass('disabled')
      $createNewPerson?.prop('disabled', false)
      $createNewPerson?.text('add_enabled'.t("button_defaults"))
  })

loadPositionManager = ($nameInput, personId) ->
  wrapper = $nameInput.parent().siblings('.position-manager-wrapper')
  containerName = wrapper.data('pjax-container')
  chartId = wrapper.data('chart-id')
  url = "/positions/positions_manager/person/#{personId}/chart/#{chartId}"
  if $('.modal').hasClass('edit-position-modal')
    positionId = $('.modal form').attr('action').split('/').pop()
    url = "/positions/#{positionId}/positions_manager/person/#{personId}/chart/#{chartId}"

  $.pjax
    url: url
    container: "[data-pjax-container=#{containerName}]"
    push: false
    data: { index: $nameInput.parents('.person-form').data('index') }
  $.pjax.xhr = null

loadEmployeeTypeManager = ($nameInput, personId) ->
  action = $('.modal form').prop('action')
  containerName = $nameInput.parent().siblings('.employee-type-manager-wrapper').data('pjax-container')

  $.pjax
    url: "#{action}/employee_type_manager/#{personId}"
    container: "[data-pjax-container=#{containerName}]"
    push: false
  .done ->
    if $(this).find('.employee-type-manager').text() == ''
      $('#position_employee_type_id').val($(this).find('.employee-type-manager').data('employee-type-id'))
  $.pjax.xhr = null


handleCreatePersonFormCancel = (e, $nameInput) ->
  # Remove the disabled state from the main modal button (if it exists)
  toggleModalSubmitButtonState(false)

  $addNewPersonForm = $('#add-new-person-form')

  # Hide the form and clear inputs
  $addNewPersonForm.addClass('hidden')
  $addNewPersonForm.find('#add-new-person-form-first-name').val('')
  $addNewPersonForm.find('#add-new-person-form-last-name').val('')
  removeCreatePersonErrors()

  # If the cancel button or the name input was clicked, focus the name input
  shouldFocusInput = $(e.target).attr('id') == 'cancel-create-person' || $nameInput.is(e.target)
  if shouldFocusInput
    e.preventDefault()
    $nameInput.focus()

  isFocusedOnInput = document.activeElement == $nameInput.get(0)
  # Show autocomplete if there's a value in the name input + we're focused on it
  if isFocusedOnInput && $nameInput.val().length > 0
    $autocompleteContainer = $nameInput.siblings('.autocomplete-container')
    $autocompleteContainer.show()
  else
    handleNameInputBlur($nameInput)

addNewPersonFormEvents = () ->
  $addNewPersonForm = $('#add-new-person-form')
  formIsVisible = -> $addNewPersonForm.is(':visible')
  nameInput = -> $("##{$addNewPersonForm.data('for-name-input-id')}")

  # Ensures the popover closes when clicking outside of it
  $(document).mousedown (e) ->
    return unless formIsVisible()
    unless $addNewPersonForm.is(e.target) or $addNewPersonForm.has(e.target).length > 0
      handleCreatePersonFormCancel(e, nameInput())

  # Ensures the popover closes when tabbing out of it
  $addNewPersonForm.on 'focusout', (e) ->
    return unless formIsVisible()
    setTimeout ->
      return if document.activeElement == document.body
      return if $addNewPersonForm.has(document.activeElement).length
      handleCreatePersonFormCancel(e, nameInput())
    , 0

  $addNewPersonForm.on 'keydown', (e) ->
    return unless formIsVisible()
    key = e.which
    # Prevents enter from saving the parent modal - instead we save the subform.
    if Keyboard.keyMatchesName(key, 'enter') && $(e.target).hasClass('input')
      saveNewPerson(e, nameInput())

events = (scope) ->
  addNewPersonFormEvents()
  $(scope).find('[data-action=add-person-row]').off('click').on 'click', addPersonRow
  $('.person-form').each applyPersonEvents
  $(scope).on 'autocomplete:renderSuggestions', (e) ->
    $nameInput = $(e.target)
    $showAddNewPersonForm = $nameInput.siblings('.autocomplete-container').find('#show-add-new-person-form')
    $showAddNewPersonForm
      .off 'mousedown'
      .on 'mousedown', (e) -> handleShowAddNewPersonForm(e, $nameInput)
  $(scope).on 'autocomplete:clearSelected', (e) ->
    targetInputId = $(e.target).data('id')
    if targetInputId?.startsWith('position_people_')
      handleAddPersonButtonRendering()
      updateExcludedPersonIds()

export { events }