import React, { MouseEvent, ReactNode, useRef } from "react"
import { AriaButtonProps, useButton } from "react-aria"

interface ButtonProps extends AriaButtonProps<"button"> {
  className?: string
  children: ReactNode
  onClick?: (e: MouseEvent) => void
}

/* eslint-disable react/jsx-props-no-spreading */
function Button({ children, className, onClick, ...props }: ButtonProps) {
  const ref = useRef(null)
  const { buttonProps } = useButton(props, ref)
  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <button {...buttonProps} ref={ref} className={className} onClick={onClick} type="button">
      {children}
    </button>
  )
}

export { Button }
