import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { ChangeEvent, useId } from "react"
import { InputMaybe } from "types/graphql"
import { RefCallBack } from "react-hook-form"
import { Info } from "./Info"

interface Props {
  error?: string
  name: string
  label: string
  inputRef?: RefCallBack
  required?: boolean
  infoText?: string
  defaultValue?: InputMaybe<string> | undefined | number
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

function TextInput({
  error,
  name,
  label,
  inputRef,
  required,
  infoText,
  defaultValue,
  onChange,
}: Props) {
  const id = useId()
  return (
    <div className="input-group">
      <label htmlFor={id} className="cursor-default">
        {label}
        {infoText && <Info description={infoText} tooltipSize="sm" />}
      </label>
      <div className="w-full">
        <input
          type="text"
          id={id}
          className={classNames("input", { "border--error": error })}
          ref={inputRef}
          required={required}
          name={name}
          defaultValue={`${defaultValue}`}
          onChange={onChange}
        />
        {error && (
          <div className="mt-1 items-center text-status-critical flex">
            <FontAwesomeIcon icon={["far", "exclamation-circle"]} className="mr-2 text-inherit" />
            <span>{error}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export { TextInput }
