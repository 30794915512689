import { Table } from "@tanstack/react-table"
import React from "react"
import { Dropzone, DropzoneFilter, EmptyDropzone } from "v2/react/shared/Dropzone/Dropzone"

interface TableHeaderProps<TRow> {
  table: Table<TRow>
}

function TableDropZone<TRow>({ table }: TableHeaderProps<TRow>) {
  const [isDraggingOver, setIsDraggingOver] = React.useState(false)
  const grouping = table.getState().grouping

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const data = JSON.parse(event.dataTransfer.getData("application/json"))
    table.getColumn(data.fieldKey)?.getToggleGroupingHandler()?.()
    setIsDraggingOver(false)
  }

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingOver(true)
  }

  const onDragExit = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingOver(false)
  }
  return (
    <Dropzone
      isDraggingOver={isDraggingOver}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragExit={onDragExit}
    >
      {grouping.length === 0 ? (
        <EmptyDropzone />
      ) : (
        grouping.map((col) => (
          <DropzoneFilter
            key={col}
            label={table.getColumn(col)?.columnDef.header as string}
            onClick={table.getColumn(col)?.getToggleGroupingHandler() as () => void}
          />
        ))
      )}
    </Dropzone>
  )
}

export { TableDropZone }
