import React from "react"
import { FeatureFlags } from "types/graphql"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSortedBoxes } from "v2/react/components/orgChart/ProfilePanel/useSortedBoxes"
import { AccordionModulePanel } from "v2/react/shared/AccordionModulePanel"
import { useAppSelector } from "v2/redux/store"

interface BioEducationProps {
  featureFlags: FeatureFlags
}

function BioEducation({ featureFlags }: BioEducationProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const { currentBox, toggleBox } = useSortedBoxes("bio_education", "person")

  if (!currentBox || !person) return null
  if (!featureFlags.bio || !person.bioEducations?.length) return null

  const headerContent = <div className="title">{"bio_education".t("org_chart")}</div>

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      {person.bioEducations?.map((education) => (
        <ModuleListItem key={education.id}>
          <div>
            <div>{education.degree}</div>
            <div>{education.minor || ""}</div>
            <div>
              {education.school}, {education.year}
            </div>
          </div>
        </ModuleListItem>
      ))}
    </AccordionModulePanel>
  )
}

export { BioEducation }
