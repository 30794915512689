import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { useRef } from "react"
import { asyncPatchPreferences } from "v2/redux/slices/ContainerSlice/containerThunks"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

function MetricsButton() {
  const dispatch = useAppDispatch()
  const showMetrics = useAppSelector((state) => state.visualization.showMetrics)
  const metricsPanel = document.querySelector("#org-chart-metrics")
  const showPanel = metricsPanel?.classList.contains("hidden")

  const initializePanel = () => {
    const { type, id } = window.App.OrgChart.getChartViewOptions()
    window.App.OrgChart.setChartViewOptions(type, id)
    window.App.OrgChart.initMetrics(showMetrics)
    window.App.OrgChart.reloadMetrics(true)
  }

  const initializedPanel = useRef(false)
  if (!initializedPanel.current) {
    initializePanel()
    initializedPanel.current = true
  }

  const toggleMetrics = () => {
    window.App.OrgChart.toggleMetrics(showPanel)
    window.App.OrgChart.reloadMetrics(showPanel)

    if (showPanel) {
      metricsPanel?.classList.remove("hidden")
    } else {
      metricsPanel?.classList.add("hidden")
    }

    dispatch(asyncPatchPreferences({ show_metrics: showPanel }))
  }

  return (
    <>
      <div className="m-0 my-[-.5rem] h-14 w-[1px] bg-neutral-8" />
      <button
        className={cn(
          "tab btn sm:btn--large btn--secondary sm:btn--secondary tooltip metrics-toggle w-8 sm:w-10",
          {
            "metrics-active": showMetrics,
            "!bg-neutral-3": showMetrics,
          },
        )}
        data-action="toggle-metrics"
        onClick={toggleMetrics}
        type="button"
      >
        <FontAwesomeIcon icon={["far", "analytics"]} />
        <span className="tooltip-content tooltip-content--sm">
          {`${showMetrics ? "Hide" : "Show"} Metrics Box`.t("org_chart")}
        </span>
      </button>
    </>
  )
}

export { MetricsButton }
