/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useMemo } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CalendarDate, createCalendar, getWeeksInMonth, parseDate } from "@internationalized/date"
import cn from "classnames"
import dayjs from "dayjs"
import localeData from "dayjs/plugin/localeData"
import {
  AriaRangeCalendarProps,
  DateRange,
  DateValue,
  Key,
  useCalendarCell,
  useCalendarGrid,
  useLocale,
  useRangeCalendar,
  usePress,
} from "react-aria"
import {
  Button as AriaButton,
  Heading as AriaHeading,
  ListBox as AriaListBox,
  ListBoxItem as AriaListBoxItem,
  Popover as AriaPopover,
  Select as AriaSelect,
  SelectValue as AriaSelectValue,
} from "react-aria-components"
import { RangeCalendarState, useRangeCalendarState } from "react-stately"
import { Button } from "v2/react/shared/DateRange/Button"
import { buildYearOptions } from "v2/react/shared/DateRange/dates"

const monthWithAdjustedIndex = (month?: number | null) => (month ? month - 1 : undefined)

function RangeCalendar<T extends DateValue>({
  minValue,
  maxValue,
  currentDate,
  ...props
}: AriaRangeCalendarProps<T> & {
  currentDate: CalendarDate
}) {
  dayjs.extend(localeData)
  dayjs().localeData()

  const { locale } = useLocale()
  const state = useRangeCalendarState({
    ...props,
    locale,
    createCalendar,
  })
  const ref = useRef(null)
  const { prevButtonProps, nextButtonProps, title } = useRangeCalendar(props, state, ref)
  const { pressProps } = usePress({
    onPress: () => {
      // If, while in the midst of selecting dates, the user clicks outside of
      // the calendar grid/controls, we set the current highlighted range as the
      // selected range.
      if (state.anchorDate) {
        state.setValue(state.highlightedRange)
      }
    },
  })

  const today = dayjs(currentDate.toString())
  const startMonth = monthWithAdjustedIndex(props.value?.start?.month) ?? today.month()
  const startYear = props.value?.start?.year ?? today.year()
  const endYear = props.value?.end?.year ?? today.year()
  const currentYear = today.year()

  const focusedDate = state.focusedDate
  const activeMonth = monthWithAdjustedIndex(focusedDate?.month) ?? startMonth
  const activeYear = focusedDate?.year ?? startYear

  const months: SelectOption[] = useMemo(
    () =>
      dayjs.months().map(
        (month: string, index: number): SelectOption => ({
          id: index,
          label: month,
        }),
      ),
    [],
  )

  const minYear = Math.min(minValue ? minValue.year : currentYear - 10, startYear, activeYear)
  const maxYear = Math.max(maxValue ? maxValue.year : currentYear + 10, endYear, activeYear)
  const years = useMemo(
    () => buildYearOptions({ minYear, maxYear, activeYear }),
    [minYear, maxYear, activeYear],
  )

  const setVisibleRangeByMonth = (key: Key) => {
    const date = parseDate(dayjs(new Date(Number(activeYear), Number(key), 1)).format("YYYY-MM-DD"))
    state.setFocusedDate(date)
  }

  const setVisibleRangeByYear = (key: Key) => {
    const date = parseDate(
      dayjs(dayjs(new Date(Number(key), Number(activeMonth), 1))).format("YYYY-MM-DD"),
    )
    state.setFocusedDate(date)
  }

  return (
    <div {...pressProps} ref={ref} className="calendar">
      <header className="items-center justify-between border-b-neutral-8 py-3 flex">
        <Button {...prevButtonProps} className="w-6 bg-transparent text-center">
          <FontAwesomeIcon icon={["far", "chevron-left"]} />
        </Button>
        <AriaHeading title={title}>
          <div className="items-center gap-2 flex">
            <VisibleRangeSelector
              ariaLabel="Select Month"
              items={months}
              onChange={setVisibleRangeByMonth}
              selectedKey={activeMonth}
            />
            <VisibleRangeSelector
              ariaLabel="Select Year"
              items={years}
              onChange={setVisibleRangeByYear}
              selectedKey={activeYear}
            />
          </div>
        </AriaHeading>
        <Button {...nextButtonProps} className="w-6 bg-transparent text-center">
          <FontAwesomeIcon icon={["far", "chevron-right"]} />
        </Button>
      </header>
      <CalendarGrid state={state} />
    </div>
  )
}

interface CalendarGridProps {
  state: RangeCalendarState
}

function CalendarGrid({ state, ...props }: CalendarGridProps) {
  const { locale } = useLocale()
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state)

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale)
  const weeksInMonthKeys = Array.from({ length: weeksInMonth }, (_, index) => index)

  return (
    <table {...gridProps}>
      <thead {...headerProps}>
        <tr>
          {/* eslint-disable react/no-array-index-key */}
          {weekDays.map((day, index) => (
            <th key={index}>{day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {weeksInMonthKeys.map((weekIndex) => (
          <tr key={weekIndex}>
            {/* eslint-disable react/no-array-index-key */}
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) =>
                date ? (
                  <CalendarCell key={i} state={state} date={date} ariaLabel={date.toString()} />
                ) : (
                  <td key={i} aria-label="No Content" />
                ),
              )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

interface CalendarCellProps {
  ariaLabel: string
  date: CalendarDate
  state: RangeCalendarState
}

function CalendarCell({ ariaLabel, date, state }: CalendarCellProps) {
  const ref = useRef<HTMLButtonElement>(null)
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    isUnavailable,
    formattedDate,
  } = useCalendarCell({ date }, state, ref)
  const today = dayjs().format("YYYY-MM-DD")

  const isStartDate = isRangeStart(date, state.highlightedRange)
  const isMiddleDate = isRangeMiddle(date, state.highlightedRange)
  const isEndDate = isRangeEnd(date, state.highlightedRange)
  const isSingleDate = isStartDate && isEndDate

  return (
    <td {...cellProps} aria-label={ariaLabel}>
      <div
        className={cn("cell-wrapper my-1.5 items-center justify-center flex", {
          "bg-primary-8": isMiddleDate && !isSingleDate,
          "start-date": isStartDate && !isEndDate,
          "end-date": isEndDate && !isStartDate,
        })}
      >
        <button
          {...buttonProps}
          ref={ref}
          hidden={isOutsideVisibleRange}
          tabIndex={0}
          className={cn("cell bg-transparent", {
            selected: isSelected || isStartDate || isEndDate,
            disabled: isDisabled,
            unavailable: isUnavailable,
            today: date.toString() === today,
            "start-date": isStartDate,
            "middle-date": isMiddleDate,
            "end-date": isEndDate,
            "single-date": isSingleDate,
          })}
          type="button"
        >
          {formattedDate}
        </button>
      </div>
    </td>
  )
}

const isRangeStart = (date: CalendarDate, range: DateRange) => {
  if (!range || !range.start) return false
  return date.toString() === range.start.toString()
}

const isRangeEnd = (date: CalendarDate, range: DateRange) => {
  if (!range || !range.end) return false
  return date.toString() === range.end.toString()
}

const isRangeMiddle = (date: CalendarDate, range: DateRange) => {
  if (!range || !range.start || !range.end) return false
  return date.toString() > range.start.toString() && date.toString() < range.end.toString()
}

interface SelectOption {
  id: number
  label: number | string
}

interface RangeSelectorProps {
  ariaLabel: string
  items: SelectOption[]
  onChange: (key: Key) => void
  selectedKey: Key
}

function VisibleRangeSelector({ ariaLabel, items, onChange, selectedKey }: RangeSelectorProps) {
  return (
    <AriaSelect
      onSelectionChange={onChange}
      selectedKey={selectedKey}
      aria-labelledby={ariaLabel}
      className="Select select--xs"
    >
      <AriaButton className="min-w-[3.5rem] items-center justify-between gap-2 !px-2 !py-0 !flex">
        <AriaSelectValue />
        <FontAwesomeIcon icon={["fas", "caret-down"]} />
      </AriaButton>
      <AriaPopover className="elevation !max-h-[17rem] overflow-y-auto rounded-lg bg-white p-2">
        <AriaListBox>
          {items.map((item) => (
            <AriaListBoxItem
              id={item.id}
              key={item.id}
              textValue={item.label.toString()}
              className="box-border w-full cursor-pointer items-center gap-x-1.5 rounded bg-transparent px-3 py-2.5 flex hover:bg-neutral-3"
            >
              <p className="mr-auto">{item.label}</p>
              {item.id === selectedKey ? (
                <FontAwesomeIcon icon={["fas", "check-circle"]} className="selected h-3.5 w-3.5" />
              ) : (
                <div className="w-3.5" />
              )}
            </AriaListBoxItem>
          ))}
        </AriaListBox>
      </AriaPopover>
    </AriaSelect>
  )
}

export { RangeCalendar }
