import classNames from "classnames"
import React, { useState } from "react"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/Tooltip"

interface ArrowNavigatorButtonProps {
  classes?: string
  children: React.ReactNode
  disableButton: boolean
  onClick: () => void
  tooltipContent: string
}

function ArrowNavigatorButton({
  classes,
  children,
  disableButton,
  onClick,
  tooltipContent,
}: ArrowNavigatorButtonProps) {
  const [showTooltip, setShowTooltip] = useState(false)
  const buttonStyles = classNames("btn", classes, { "btn-disabled": disableButton })

  return (
    <Tooltip open={showTooltip && !disableButton} placement="bottom-start">
      <TooltipTrigger
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <button className={buttonStyles} type="button" onClick={onClick} disabled={disableButton}>
          {children}
        </button>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content">{tooltipContent}</TooltipContent>
    </Tooltip>
  )
}

export { ArrowNavigatorButton }
