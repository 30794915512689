/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect, useState } from "react"
import { createCalendar } from "@internationalized/date"
import cn from "classnames"
import {
  useDateField,
  useDateSegment,
  useFocus,
  useLocale,
  useFocusWithin,
  useKeyboard,
} from "react-aria"
import { useDateFieldState } from "react-stately"

import type { DOMAttributes, KeyboardEvent } from "react"
import type { AriaDatePickerProps, DateValue } from "react-aria"
import type { DateFieldState, DateSegment } from "react-stately"

interface DateFieldProps extends AriaDatePickerProps<DateValue> {
  fieldRef: React.RefObject<HTMLDivElement>
  onSegmentFocus: (
    e: React.FocusEvent<HTMLDivElement, Element>,
    date?: DateValue,
    ref?: React.RefObject<HTMLDivElement>,
  ) => void
  onDateFieldChange: (date?: DateValue) => void
  onDateFieldSelection: (e: KeyboardEvent<HTMLDivElement>) => void
}

function DateField({
  fieldRef,
  onSegmentFocus,
  onDateFieldChange,
  onDateFieldSelection,
  ...props
}: DateFieldProps) {
  const { locale } = useLocale()
  const [isFieldFocused, setIsFieldFocused] = useState(false)
  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  })
  const { labelProps, fieldProps } = useDateField(props, state, fieldRef)

  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: (isFocused) => setIsFieldFocused(isFocused),
  })
  const { keyboardProps } = useKeyboard({
    onKeyDown: (e) => {
      if (e.key === "Enter") {
        onDateFieldSelection(e)
      }
    },
  })

  useEffect(() => {
    if (isFieldFocused) {
      onDateFieldChange(state.value)
    }
  }, [state.value, onDateFieldChange, isFieldFocused])

  const desiredIndexes = [
    { type: "year" },
    { type: "literal" },
    { type: "month" },
    { type: "literal" },
    { type: "day" },
  ]

  const sortedSegments: DateSegment[] = []
  desiredIndexes.forEach((value) => {
    const matched = state.segments.find((segment) => segment.type === value.type)
    if (matched) sortedSegments.push(matched)
  })

  return (
    <div className="Date-Field grow">
      <span {...labelProps}>{props.label}</span>
      <div
        ref={fieldRef}
        {...fieldProps}
        {...keyboardProps}
        {...focusWithinProps}
        className="Field h-6 cursor-pointer items-center justify-start border-0 border-r border-solid border-neutral-8 px-2.5 text-sm flex sm:h-10 sm:text-base"
      >
        {sortedSegments.map((segment, i) => (
          /* eslint-disable react/no-array-index-key */
          <DateSegmentPart
            key={i}
            segment={segment}
            state={state}
            onSegmentFocus={onSegmentFocus}
          />
        ))}
      </div>
    </div>
  )
}

interface DateSegmentProps extends DOMAttributes<DateSegment> {
  onSegmentFocus: DateFieldProps["onSegmentFocus"]
  segment: DateSegment
  state: DateFieldState
}

function DateSegmentPart({ onSegmentFocus, segment, state }: DateSegmentProps) {
  const ref = useRef<HTMLDivElement>(null)
  const { segmentProps } = useDateSegment(segment, state, ref)
  const { focusProps } = useFocus<HTMLDivElement>({
    onFocus: (e) => {
      onSegmentFocus(e, state.value, ref)
    },
  })

  return (
    <div
      {...segmentProps}
      {...focusProps}
      ref={ref}
      className={cn("segment rounded-sm bg-transparent p-px uppercase focus:bg-primary-100", {
        placeholder: segment.isPlaceholder,
        "px-0.5": segment.type === "literal",
      })}
    >
      {segment.text === "/" ? "-" : segment.text}
    </div>
  )
}

export { DateField }
export type { DateFieldProps }
