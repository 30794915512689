import React, { useState, useEffect } from "react"
import RootProvider from "v2/react/components/RootProvider"
import { useTranslation } from "react-i18next"

import { Modal } from "v2/react/shared/Modal"
import { Pulser } from "v2/react/shared/Pulser"
import { useGetCurrencyCodeQuery } from "v2/redux/GraphqlApi"
import { CurrencyInfo } from "types/graphql"
import { CurrencySettingsForm } from "./CurrencySettingsForm"

function CurrencySettingsInner() {
  const { t } = useTranslation()
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  const [currentCurrency, setCurrentCurrency] = useState<CurrencyInfo | null>(null)

  const handleEditButtonClick = () => {
    setEditModalIsOpen(true)
  }

  const handleCloseModal = () => {
    setEditModalIsOpen(false)
  }

  const handleSaveSuccess = (newCurrency: CurrencyInfo) => {
    handleCloseModal()
    setCurrentCurrency(newCurrency)
  }

  const { data, isLoading } = useGetCurrencyCodeQuery({})
  const company = data?.currentCompany
  const disambiguations =
    company?.collections.currencyDisambiguations.options.nodes.reduce(
      (mem: Record<string, string>, opt) => {
        const result = { ...mem }
        result[opt.id] = opt.label
        return result
      },
      {},
    ) ?? {}

  useEffect(() => {
    if (!currentCurrency && company?.currency) {
      setCurrentCurrency(company.currency ?? null)
    }
  }, [company?.currency, currentCurrency])

  return (
    <div>
      <div className="module-card">
        <div className="module-card__header">
          <div className="module-title">{t("v2.general_settings.currency.currency")}</div>
          <button
            className="module-action company-settings-currency-action"
            onClick={handleEditButtonClick}
            type="button"
          >
            {t("v2.defaults.edit")}
          </button>
        </div>
        <div className="module-card__body currency">
          {isLoading && <Pulser />}
          {company && currentCurrency && (
            <div className="module-card-list-item">
              <div>{t("v2.general_settings.currency.reporting_currency")}</div>
              <div>
                {currentCurrency.name} ({currentCurrency.isoCode}
                {disambiguations[currentCurrency.isoCode || ""] &&
                  `, ${disambiguations[currentCurrency.isoCode || ""]}`}
                )
              </div>
            </div>
          )}
          {company && (
            <div className="module-card-list-item">
              <div>{t("v2.general_settings.currency.unique_symbols")}</div>
              <div>{company.useUniqueCurrencySymbol ? "Yes" : "No"}</div>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={editModalIsOpen}
        onClose={handleCloseModal}
        title={t("v2.general_settings.currency.edit_currency")}
      >
        <CurrencySettingsForm onSaveSuccess={handleSaveSuccess} />
      </Modal>
    </div>
  )
}

function CurrencySettings() {
  return (
    <RootProvider>
      <CurrencySettingsInner />
    </RootProvider>
  )
}

export default CurrencySettings
