import React from "react"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import { IntegrationFlags, Position } from "types/graphql"
import { SmallEditButton } from "v2/react/components/positionTypes/Show/SmallEditButton"
import { StatusBadge } from "v2/react/shared/Status/StatusBadge"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"
import { CellData, ColumnCell, jobLevelCell } from "./Details/ColumnCell"
import { customFieldTitleLabel, customFieldValueLabel, eeocValue, flsaValue } from "./Details/utils"

interface DetailsProps {
  integrationFlags: IntegrationFlags
  position: Position
}

function Details({ integrationFlags, position }: DetailsProps) {
  const { t } = useTranslation()

  const handleEdit = () => pjaxModalFor(UrlHelper.editPositionPath(position.id))

  const cellData: CellData[] = cellDataForPosition(integrationFlags, position, t)

  return (
    <div className="module-card">
      <div className="module-card__header">
        <div className="module-title">{t("v2.positions.show.details")}</div>
        <SmallEditButton onClick={handleEdit} />
      </div>
      <div className="module-card__body gap-0 border-0 p-0">
        <div className="flex-wrap flex">
          {cellData.map((cell: CellData, index) => (
            <ColumnCell
              content={cell.content}
              cellCount={cellData.length}
              index={index}
              // eslint-disable-next-line react/no-array-index-key
              key={`cell-data-${index}`}
              title={cell.title}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const orgUnitDataForPosition = (position: Position): CellData[] => {
  const orgUnits = position.orgUnits || []
  return orgUnits.map((orgUnit) => ({
    content: orgUnit.full_name || "-",
    title: orgUnit.orgUnitType?.name,
  }))
}

const cellDataForPosition = (
  integrationFlags: IntegrationFlags,
  position: Position,
  t: TFunction,
): CellData[] => [
  {
    content: position.systemIdentifier || "-",
    title: t("v2.positions.show.position_id"),
  },

  {
    content: (
      <a
        className="link"
        href={position.positionType?.id && UrlHelper.positionTypePath(position.positionType?.id)}
      >
        {position.title || t("v2.positions.show.untitled_position")}
      </a>
    ),
    title: t("v2.positions.show.title"),
  },

  {
    content: position.externalIdentifier || "-",
    title: t("v2.positions.show.external_id"),
  },

  {
    content: position.isAssistant
      ? t("v2.positions.show.assistant")
      : t("v2.positions.show.standard"),
    title: t("v2.positions.show.position_type"),
  },

  {
    content: position.reportsToName || "-",
    title: t("v2.positions.show.reports_to"),
  },

  {
    content: position.employeeType?.labelTranslated || "-",
    title: t("v2.positions.show.employee_type"),
  },

  {
    content: position.jobFamily?.name || "-",
    title: t("v2.positions.show.job_family"),
  },

  {
    content: position.fte ? position.fte.toString() : "-",
    title: t("v2.positions.show.fte"),
  },

  {
    content: position.location?.label || "-",
    title: t("v2.positions.show.location"),
  },

  ...orgUnitDataForPosition(position),

  {
    content: <StatusBadge displayType="button" status={position.importance || "none"} />,
    title: t("v2.positions.show.importance"),
  },

  {
    content: eeocValue(position, t),
    title: t("v2.positions.show.eeoc_classification"),
  },

  {
    content: flsaValue(position, t),
    title: t("v2.positions.show.flsa_classification"),
  },

  {
    content: position.jobCode || "-",
    title: t("v2.positions.show.job_code"),
  },

  {
    content: position.companyCode || "-",
    title: t("v2.positions.show.company_code"),
  },
  ...jobLevelCell(t, integrationFlags, position.positionType),
  ...(position.customFieldValues || []).map((cfv) => ({
    title: customFieldTitleLabel(cfv),
    content: customFieldValueLabel(cfv),
  })),
]

export { Details }
