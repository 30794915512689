import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from "react"
import { ControlProps } from "@jsonforms/core"
import classNames from "classnames"
import { useOnClickOutside } from "usehooks-ts"

import { DropdownMenu } from "v2/react/shared/DropdownMenu"
import { useAutoComplete } from "v2/react/hooks/useAutocomplete"
import { useCollectionSearch } from "v2/react/hooks/useCollectionSearch"

import { isDisabledFromSchema } from "v2/react/shared/JsonFormControls/utils/forms"
import { InputErrorText } from "./InputErrorText"
import { useValidation } from "./hooks/useValidation"

interface Option {
  id: string
  label: string
}

/*
 * in the uischema we can place options for
 * allowCustomInput - to allow for custom input value
 * fieldKey - to lookup the collection
 */

const JsonAutocompleteInput: FC<ControlProps & { fieldKey?: string }> = ({
  config,
  data,
  enabled,
  id,
  handleChange,
  label,
  path,
  schema,
  uischema,
  fieldKey: fieldKeyOverride,
}) => {
  const fieldKey = fieldKeyOverride || uischema.options?.fieldKey
  if (!fieldKey) return null

  return (
    <JsonAutocompleteInputInner
      config={config}
      data={data}
      enabled={enabled}
      id={id}
      handleChange={handleChange}
      label={label}
      path={path}
      schema={schema}
      uischema={uischema}
      fieldKey={fieldKeyOverride}
    />
  )
}

type ControlPropsSubset = Pick<
  ControlProps,
  "config" | "data" | "enabled" | "handleChange" | "id" | "label" | "path" | "schema" | "uischema"
> & { fieldKey?: string }
const JsonAutocompleteInputInner: FC<ControlPropsSubset> = ({
  config,
  data,
  enabled,
  id,
  handleChange,
  label,
  path,
  schema,
  uischema,
  fieldKey: fieldKeyOverride,
}) => {
  const fieldKey = fieldKeyOverride || uischema.options?.fieldKey
  const allowCustomInput = uischema.options?.allowCustomInput
  const isDisabled = isDisabledFromSchema({ enabled, schema })

  const [inputValue, setInputValue] = useState<string>("")
  const { showError, errorMessage } = useValidation({
    data,
    path,
    schema,
    submitting: config.submitting,
  })
  const [isFocused, setIsFocused] = useState(false)
  const [showResultList, setShowResultList] = useState(false)
  const { collectionResult, returnEmpty } = useCollectionSearch({
    fieldKey,
    filter: inputValue.trim(),
  })

  const {
    activeIndex,
    context,
    floatingStyles,
    getFloatingProps,
    getItemProps,
    getReferenceProps,
    listRef,
    refs,
    setActiveIndex,
  } = useAutoComplete({ showList: showResultList, setShowList: setShowResultList })

  useEffect(() => {
    if (isFocused) {
      refs.domReference.current?.focus()
    } else {
      refs.domReference.current?.blur()
    }
  }, [isFocused, refs])

  useEffect(() => {
    setInputValue(data?.name || "")
  }, [data, isDisabled])

  const handleChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setInputValue(value)

    if (value.length > 0) {
      setShowResultList(true)
      setActiveIndex(0)
    } else {
      setShowResultList(false)
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && activeIndex != null && collectionResult[activeIndex]) {
      const selectedValue = collectionResult[activeIndex].label
        .toLowerCase()
        .includes(inputValue.toLowerCase())
        ? collectionResult[activeIndex]
        : { id: "", label: inputValue }
      handleResultClick(selectedValue)
    }
  }

  const handleResultClick = (value: Option) => {
    setActiveIndex(null)
    setInputValue(value.label)
    setIsFocused(false)
    setShowResultList(false)
    handleChange(path, { name: value.label, id: value.id })
  }

  const handleOutsideClick = () => {
    setActiveIndex(null)
    setIsFocused(false)
    setShowResultList(false)

    const clickedDropdownResult = activeIndex !== null && collectionResult[activeIndex]

    if (clickedDropdownResult) {
      setInputValue(clickedDropdownResult.label)
      handleChange(path, { name: clickedDropdownResult.label, id: clickedDropdownResult.id })
    } else if (allowCustomInput) {
      setInputValue(inputValue)
      // If we haven't changed the data at all, then we don't want
      // to trigger a change event.
      if (data?.name === inputValue && data?.id) return
      handleChange(path, { name: inputValue, id: "" })
    } else if (inputValue === "") {
      setInputValue("") // Allow the input to be cleared/reset
    } else {
      setInputValue(data?.name || "")
    }
  }

  useOnClickOutside(refs.domReference, handleOutsideClick)

  return (
    <div className={classNames("input-group", { "form-error": showError })}>
      <label htmlFor={id} className="cursor-default items-center whitespace-pre-wrap flex">
        {label}
      </label>
      <input
        aria-autocomplete="list"
        className="input"
        disabled={isDisabled}
        id={id}
        ref={refs.setReference}
        value={inputValue}
        /* eslint-disable react/jsx-props-no-spreading */
        {...getReferenceProps({
          onKeyDown: handleKeyDown,
          onChange: handleChangeEvent,
        })}
      />
      <InputErrorText message={errorMessage} showError={showError} />
      <DropdownMenu
        context={context}
        floatingProps={getFloatingProps}
        floatingRef={refs.setFloating}
        floatingStyles={floatingStyles}
        showList={showResultList && !!inputValue.trim().length && !isDisabled}
        wrapperClasses="AutocompleteField"
      >
        <>
          {collectionResult &&
            collectionResult.length > 0 &&
            collectionResult?.map((option, index) => (
              <div
                aria-selected={activeIndex === index}
                className={autocompleteClassName(activeIndex, index)}
                ref={(node) => {
                  listRef.current[index] = node
                }}
                key={option.id}
                role="option"
                /* eslint-disable react/jsx-props-no-spreading */
                {...getItemProps({
                  onClick: () => handleResultClick(option),
                  onKeyDown: () => handleKeyDown,
                })}
              >
                <div className="truncate">{option.label}</div>
              </div>
            ))}
          {!allowCustomInput &&
            !returnEmpty &&
            collectionResult?.length === 0 &&
            inputValue.length > 0 && (
              <div className="AutocompleteField__no-result">
                <p className="AutocompleteField__no-result-text">
                  {getNoneLabel(String(fieldKey))}
                </p>
              </div>
            )}
        </>
      </DropdownMenu>
    </div>
  )
}

export { JsonAutocompleteInput }

const autocompleteClassName = (activeIndex: number | null, index: number) =>
  classNames("AutocompleteField__result", { highlight: activeIndex === index })

const getNoneLabel = (fieldKey: string) => {
  if (fieldKey === `location`) {
    return "none_found_location".t("org_chart")
  }
  return "none_found_backup".t("org_chart")
}
