import { Table, Column as TanColumn } from "@tanstack/react-table"
import classNames from "classnames"
import { remove, uniq } from "lodash"
import React from "react"
import { ColumnSort } from "v2/react/shared/Datasheet/ColumnSort"
import { isMaxWidthColumn } from "v2/react/shared/Datasheet/utils/constants"
import { Select } from "v2/react/shared/Select"

interface TableHeaderProps<TRow> {
  table: Table<TRow>
}

function TableHeader<TRow>({ table }: TableHeaderProps<TRow>) {
  const determinHeaderClasses = (headerCount: number, index: number, columnId: string) =>
    classNames(
      `h-20 border border-t-0 border-b-0 border-solid border-neutral-8 p-0`,
      isMaxWidthColumn(columnId)
        ? `!min-w-[320px] w-[320px]`
        : `w-full min-w-[120px] max-w-[320px] first:!w-16 first:!min-w-[4rem]`,
      {
        "border-l-0": index === 0,
        "border-r-0": index === headerCount - 1,
      },
    )

  return (
    <thead id="table-datasheet-thead" className="border-b-0">
      {table.getHeaderGroups().map((headerGroup) => {
        const { headers } = headerGroup
        return (
          <tr key={headerGroup.id}>
            {headers.map((header, index) => (
              <th
                key={header.id}
                draggable={header.column.getCanGroup()}
                onDragStart={(event) => {
                  event.dataTransfer.setData(
                    "application/json",
                    JSON.stringify({
                      fieldKey: header.column.id,
                    }),
                  )
                  /* eslint-disable-next-line no-param-reassign */
                  event.dataTransfer.dropEffect = "copy"
                }}
                className={determinHeaderClasses(headers.length, index, header.column.id)}
              >
                <div className="h-20 flex-col gap-3.5 border-b-neutral-8 px-4 pb-2 pt-3.5 flex">
                  <ColumnSort
                    canSort={header.column.getCanSort()}
                    classes={!header.column.getCanFilter() ? "mb-6" : ""}
                    columnLabel={header.column.columnDef.header as string}
                    disabled={!header.column.getCanSort()}
                    onSort={header.column.getToggleSortingHandler()}
                    sorted={header.column.getIsSorted()}
                    info={header.column.columnDef.meta?.original.info}
                  />
                  {header.column.getCanFilter() ? (
                    <Filter
                      label={header.column.columnDef.header as string}
                      column={header.column}
                      table={table}
                    />
                  ) : null}
                </div>
              </th>
            ))}
          </tr>
        )
      })}
    </thead>
  )
}

export { TableHeader }

function Filter({
  label,
  column,
  table,
}: {
  label: string
  column: TanColumn<any, unknown> // eslint-disable-line @typescript-eslint/no-explicit-any
  table: Table<any> // eslint-disable-line @typescript-eslint/no-explicit-any
}) {
  const options = React.useMemo(() => {
    const values = remove(
      table.getCoreRowModel().flatRows.map((row) => row.getValue<string>(column.id)),
      (value) => value !== undefined && value !== "",
    )
    return uniq(values)
  }, [table, column])

  return (
    <Select
      className="select--xs"
      defaultValue={`Filter by ${label}`}
      options={options.map((o) => ({ id: o, label: o }))}
      onSelect={(event) => column.setFilterValue(event.target.value)}
    />
  )
}
