import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks"

import {
  CareerLadderDetailsQuery,
  CareerLadderDetailsQueryVariables,
  CareerLaddersArrangeCareerLadderTracksInput,
  CareerLaddersArrangeCareerLadderTracksMutation,
  CareerLaddersCollectionQuery,
  CareerLaddersCollectionQueryVariables,
  CareerLaddersCreateCareerLadderTrackInput,
  CareerLaddersCreateCareerLadderTrackMutation,
  CareerLaddersCreateCareerLadderTrackPositionTypeInput,
  CareerLaddersCreateCareerLadderTrackPositionTypeMutation,
  CareerLaddersCreateInput,
  CareerLaddersCreateMutation,
  CareerLaddersDestroyCareerLadderTrackInput,
  CareerLaddersDestroyCareerLadderTrackMutation,
  CareerLaddersDestroyCareerLadderTrackPositionTypeInput,
  CareerLaddersDestroyCareerLadderTrackPositionTypeMutation,
  CareerLaddersUpdateCareerLadderTrackInput,
  CareerLaddersUpdateCareerLadderTrackMutation,
  CareerLaddersUpdateCareerLadderTrackPositionTypeInput,
  CareerLaddersUpdateCareerLadderTrackPositionTypeMutation,
  CareerLaddersDestroyInput,
  CareerLaddersDestroyMutation,
  CareerLaddersUpdateInput,
  CareerLaddersUpdateMutation,
  CareerLadderTrackPositionType,
} from "types/graphql"
import {
  flatMutationOperation,
  flatSplitMutationOperation,
  queryOperation,
} from "v2/redux/utils/endpoints"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { OperationName } from "v2/operation_store"

type CareerLaddersConnection = {
  collection: NonNullable<CareerLaddersCollectionQuery["careerLadders"]>["collection"]
  metadata: NonNullable<CareerLaddersCollectionQuery["careerLadders"]>["metadata"]
}
type CareerLadderConnectionNode = CareerLaddersConnection["collection"][0]
type WithCareerLadderId<Arguments> = Arguments & {
  careerLadderId: string
}

const EMPTY_CAREER_LADDERS_CONNECTION: CareerLaddersConnection = {
  collection: [],
  metadata: {
    totalPages: 0,
    totalCount: 0,
    currentPage: 0,
    limitValue: 0,
  },
}

const invalidatesCareerLadderTags = (careerLadderId: string) => [
  "CareerLadder",
  "PositionTypesConnection",
  { type: "CareerLadder", id: careerLadderId },
]

const cacheBustingFlatMutationOperation =
  <Input>(operationName: OperationName) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ careerLadderId, ...input }: WithCareerLadderId<Input>) =>
    flatMutationOperation(operationName)(input)

const CareerLaddersApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    careerLadderDetails: builder.query<CareerLadderDetailsQuery, CareerLadderDetailsQueryVariables>(
      {
        query: queryOperation<CareerLadderDetailsQueryVariables>("CareerLadderDetails"),
        providesTags: (res) =>
          res?.careerLadderDetails?.uniqueKey
            ? ["CareerLadder", { type: "CareerLadder", id: res.careerLadderDetails.uniqueKey }]
            : ["CareerLadder"],
      },
    ),
    careerLaddersArrangeCareerLadderTracks: builder.mutation<
      CareerLaddersArrangeCareerLadderTracksMutation,
      WithCareerLadderId<CareerLaddersArrangeCareerLadderTracksInput>
    >({
      query: flatMutationOperation<CareerLaddersArrangeCareerLadderTracksInput>(
        "CareerLaddersArrangeCareerLadderTracks",
      ),
      invalidatesTags: (_res, _, arg) => invalidatesCareerLadderTags(arg.careerLadderId),
    }),
    careerLaddersConnection: builder.query<
      CareerLaddersConnection,
      CareerLaddersCollectionQueryVariables
    >({
      query: queryOperation<CareerLaddersCollectionQueryVariables>("CareerLaddersCollection"),
      providesTags: (res) => {
        const nodeTags = (res?.collection ?? [])
          .filter(({ uniqueKey }) => Boolean(uniqueKey))
          .map(({ uniqueKey }) => ({ type: "CareerLadder", id: uniqueKey as string }))
        return ["CareerLadder", ...nodeTags]
      },
      transformResponse: (res: CareerLaddersCollectionQuery) =>
        res.careerLadders ?? EMPTY_CAREER_LADDERS_CONNECTION,
    }),
    careerLaddersCreateCareerLadderTrack: builder.mutation<
      CareerLaddersCreateCareerLadderTrackMutation,
      CareerLaddersCreateCareerLadderTrackInput
    >({
      query: flatMutationOperation<CareerLaddersCreateCareerLadderTrackInput>(
        "CareerLaddersCreateCareerLadderTrack",
      ),
      invalidatesTags: (_1, _2, arg) => invalidatesCareerLadderTags(arg.careerLadderId),
    }),
    careerLaddersCreateCareerLadderTrackPositionType: builder.mutation<
      CareerLaddersCreateCareerLadderTrackPositionTypeMutation,
      CareerLaddersCreateCareerLadderTrackPositionTypeInput
    >({
      query: flatMutationOperation<CareerLaddersCreateCareerLadderTrackPositionTypeInput>(
        "CareerLaddersCreateCareerLadderTrackPositionType",
      ),
      invalidatesTags: (_1, _2, arg) => invalidatesCareerLadderTags(arg.careerLadderId),
    }),
    careerLaddersCreateSplitCareerLaddersTrack: builder.mutation<
      CareerLaddersCreateCareerLadderTrackMutation,
      {
        lhs: CareerLaddersCreateCareerLadderTrackInput
        rhs: CareerLaddersCreateCareerLadderTrackInput
      }
    >({
      query: flatSplitMutationOperation<{
        lhs: CareerLaddersCreateCareerLadderTrackInput
        rhs: CareerLaddersCreateCareerLadderTrackInput
      }>("CareerLaddersCreateSplitCareerLaddersTrack"),
      invalidatesTags: (_1, _2, arg) => invalidatesCareerLadderTags(arg.lhs.careerLadderId),
    }),
    careerLaddersCreate: builder.mutation<CareerLaddersCreateMutation, CareerLaddersCreateInput>({
      invalidatesTags: (res) =>
        invalidatesCareerLadderTags(res?.careerLaddersCreate?.careerLadder?.uniqueKey ?? ""),
      query: flatMutationOperation<CareerLaddersCreateInput>("CareerLaddersCreate"),
    }),
    careerLaddersDestroyCareerLadderTrack: builder.mutation<
      CareerLaddersDestroyCareerLadderTrackMutation,
      WithCareerLadderId<CareerLaddersDestroyCareerLadderTrackInput>
    >({
      query: flatMutationOperation<CareerLaddersDestroyCareerLadderTrackInput>(
        "CareerLaddersDestroyCareerLadderTrack",
      ),
      invalidatesTags: (_res, _, arg) => invalidatesCareerLadderTags(arg.careerLadderId),
    }),
    careerLaddersDestroyCareerLadderTrackPositionType: builder.mutation<
      CareerLaddersDestroyCareerLadderTrackPositionTypeMutation,
      WithCareerLadderId<CareerLaddersDestroyCareerLadderTrackPositionTypeInput>
    >({
      onQueryStarted: async ({ careerLadderId, careerLadderTrackPositionTypeId }, api) => {
        const { updateQueryData } = CareerLaddersApi.util
        const queryKey = { uniqueKey: careerLadderId }

        try {
          api.dispatch(
            updateQueryData(
              "careerLadderDetails",
              queryKey,
              destroyTrackPositionTypeOptimistically(careerLadderTrackPositionTypeId),
            ),
          )

          await api.queryFulfilled
        } catch {
          // Reload the latest from the server if there an error occurred with
          // mutation.
          api.dispatch(CareerLaddersApi.util.invalidateTags(["CareerLadder"]))
        }
      },
      query:
        cacheBustingFlatMutationOperation<CareerLaddersDestroyCareerLadderTrackPositionTypeInput>(
          "CareerLaddersDestroyCareerLadderTrackPositionType",
        ),
      invalidatesTags: (_res, _, arg) => invalidatesCareerLadderTags(arg.careerLadderId),
    }),
    careerLaddersUpdateCareerLadderTrack: builder.mutation<
      CareerLaddersUpdateCareerLadderTrackMutation,
      WithCareerLadderId<CareerLaddersUpdateCareerLadderTrackInput>
    >({
      query: cacheBustingFlatMutationOperation<CareerLaddersUpdateCareerLadderTrackInput>(
        "CareerLaddersUpdateCareerLadderTrack",
      ),
      invalidatesTags: (_res, _, arg) => invalidatesCareerLadderTags(arg.careerLadderId),
    }),
    careerLaddersUpdateCareerLadderTrackPositionType: builder.mutation<
      CareerLaddersUpdateCareerLadderTrackPositionTypeMutation,
      WithCareerLadderId<CareerLaddersUpdateCareerLadderTrackPositionTypeInput>
    >({
      query:
        cacheBustingFlatMutationOperation<CareerLaddersUpdateCareerLadderTrackPositionTypeInput>(
          "CareerLaddersUpdateCareerLadderTrackPositionType",
        ),
      invalidatesTags: (_res, _, arg) => invalidatesCareerLadderTags(arg.careerLadderId),
    }),
    careerLaddersDestroy: builder.mutation<CareerLaddersDestroyMutation, CareerLaddersDestroyInput>(
      {
        invalidatesTags: (_res, _, arg) => invalidatesCareerLadderTags(arg.careerLadderId),
        query: flatMutationOperation<CareerLaddersDestroyInput>("CareerLaddersDestroy"),
      },
    ),
    careerLaddersUpdate: builder.mutation<CareerLaddersUpdateMutation, CareerLaddersUpdateInput>({
      invalidatesTags: (_res, _, arg) => invalidatesCareerLadderTags(arg.careerLadderId),
      query: flatMutationOperation<CareerLaddersUpdateInput>("CareerLaddersUpdate"),
    }),
  }),
})

const destroyTrackPositionTypeOptimistically =
  (trackPositionTypeId: string) => (data: MaybeDrafted<CareerLadderDetailsQuery>) => {
    const { careerLadderDetails } = data
    if (!careerLadderDetails) return

    const { careerLadderTracks } = careerLadderDetails
    if (!careerLadderTracks) return

    const doesNotMatch = (node: CareerLadderTrackPositionType) =>
      node.uniqueKey !== trackPositionTypeId

    const patchedTracks = careerLadderTracks.map((track) => ({
      ...track,
      careerLadderTrackPositionTypes: track.careerLadderTrackPositionTypes?.filter(doesNotMatch),
    }))

    // eslint-disable-next-line no-param-reassign
    data.careerLadderDetails = { ...careerLadderDetails, careerLadderTracks: patchedTracks }
  }

export { CareerLadderConnectionNode, CareerLaddersApi, CareerLaddersConnection }

export const {
  useCareerLadderDetailsQuery,
  useCareerLaddersArrangeCareerLadderTracksMutation,
  useCareerLaddersConnectionQuery,
  useCareerLaddersCreateCareerLadderTrackMutation,
  useCareerLaddersCreateCareerLadderTrackPositionTypeMutation,
  useCareerLaddersCreateMutation,
  useCareerLaddersCreateSplitCareerLaddersTrackMutation,
  useCareerLaddersDestroyCareerLadderTrackMutation,
  useCareerLaddersDestroyCareerLadderTrackPositionTypeMutation,
  useCareerLaddersDestroyMutation,
  useCareerLaddersUpdateCareerLadderTrackMutation,
  useCareerLaddersUpdateCareerLadderTrackPositionTypeMutation,
  useCareerLaddersUpdateMutation,
} = CareerLaddersApi
