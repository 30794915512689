import { t } from "i18next"
import React, { useState } from "react"

import { Modal } from "v2/react/shared/Modal"
import { SaveFooter } from "v2/react/shared/Modal/SaveFooter"
import { TextInput } from "v2/react/shared/Inputs/TextInput"
import { CareerLadder, Error } from "types/graphql"

interface Props {
  careerLadder?: CareerLadder
  errors: Error[] | undefined | null
  isLoading: boolean
  isOpen: boolean
  onClose: () => void
  onSubmit: (event: React.FormEvent) => void
  title: string
}

const CareerLadderForm = ({
  careerLadder,
  errors,
  isLoading,
  isOpen,
  onClose,
  onSubmit,
  title,
}: Props) => {
  const [codeError, setCodeError] = useState("")

  const onCancel = () => {
    setCodeError("")
    onClose()
  }

  const validate = (event: React.FormEvent<Element>) => {
    event.preventDefault()

    const form = event.target as HTMLFormElement
    const formData = new FormData(form)
    if (!formData.get("code")) {
      setCodeError(t("v2.defaults.blank_validation"))
    } else {
      setCodeError("")
      onSubmit(event)
    }
  }

  const retrieveErrorFor = (path: string) => {
    const error = errors?.find((element) => element?.path && element.path[0] === path)
    if (error) return error.message
    return ""
  }

  const forceAlphaNumeric = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target) e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, "")
  }

  return (
    <Modal isOpen={isOpen} onClose={onCancel} title={title} size="md">
      <form onSubmit={validate}>
        <div className="react-modal__body flex-col gap-2 flex">
          {retrieveErrorFor("general") && (
            <div className="alert alert-critical mt-4">
              <span>{retrieveErrorFor("general")}</span>
            </div>
          )}

          <section className="mb-4 grid-cols-4 gap-6 grid">
            <TextInput
              id="code"
              name="code"
              label={t("v2.career_ladders.column_headers.code")}
              errors={codeError || retrieveErrorFor("code")}
              defaultValue={careerLadder?.code || ""}
              onChange={forceAlphaNumeric}
            />
            <TextInput
              id="name"
              name="name"
              label={t("v2.career_ladders.column_headers.name")}
              className="col-span-3"
              errors={retrieveErrorFor("name")}
              defaultValue={careerLadder?.name || ""}
            />
          </section>
          <div className="input-group">
            <label htmlFor="description">{t("v2.career_ladders.column_headers.description")}</label>
            <textarea
              name="description"
              className="input"
              defaultValue={careerLadder?.description || ""}
            />
          </div>
        </div>
        <SaveFooter isSaving={isLoading} onCancel={onCancel} />
      </form>
    </Modal>
  )
}

export { CareerLadderForm }
