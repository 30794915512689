import { useCallback } from "react"
import { z } from "zod"
import fp from "lodash/fp"

import { FieldKey } from "v2/redux/slices/NodeSlice/types"
import { NodeInterface, PersonPositionNodeAttributes } from "types/graphql"

import { useAppDispatch } from "v2/redux/store"
import { useUpdatePersonPositionNodeMutation } from "v2/redux/slices/NodeSlice/NodeApi"
import { preparePersonPositionNodeAttributes } from "v2/redux/slices/NodeSlice/mutations/preparePersonPositionNodeAttributes"

interface UpdateNodeInput {
  fieldKey: keyof PersonPositionNodeAttributes
  value: PersonPositionNodeAttributes[keyof PersonPositionNodeAttributes]
}

const PREPROCESSED_INPUT_KEYS: UpdateNodeInput["fieldKey"][] = ["variable_pays_attributes"]

const stringOrNull = z.string().nullable()

function useUpdateNodeWithMultipleInputs() {
  const dispatch = useAppDispatch()
  const [performUpdateMutation] = useUpdatePersonPositionNodeMutation()

  const updatePersonPositionNodeAttributes = useCallback(
    async (node: NodeInterface, inputs: UpdateNodeInput[], primaryTrigger: FieldKey) =>
      dispatch(async () => {
        const preparedAttributes = inputs.reduce(
          (acc: PersonPositionNodeAttributes, input: UpdateNodeInput) => {
            const { fieldKey, value } = input

            if (PREPROCESSED_INPUT_KEYS.includes(fieldKey)) {
              return { ...acc, [fieldKey]: value }
            }
            const newAttributes = preparePersonPositionNodeAttributes({
              fieldKey,
              node,
              value: stringOrNull.parse(value),
            })
            const result = mabyeMergeVariablePays(newAttributes, acc, fieldKey)
            return result
          },
          {} as PersonPositionNodeAttributes,
        )

        const result = await performUpdateMutation({
          id: node.id,
          attributes: preparedAttributes,
          primaryTrigger,
          cancelOptimisticUpdate: true,
        }).unwrap()

        const topLevel = result.updatePersonPositionNode
        const errors = (topLevel && topLevel.errors) || []
        return { ok: topLevel && errors.length === 0, errors }
      }),
    [dispatch, performUpdateMutation],
  )

  return [updatePersonPositionNodeAttributes]
}

const mabyeMergeVariablePays = (
  newAttributes: PersonPositionNodeAttributes,
  acc: PersonPositionNodeAttributes,
  fieldKey: keyof PersonPositionNodeAttributes | FieldKey,
) => {
  if (newAttributes.variable_pays_attributes && acc.variable_pays_attributes) {
    // Merge any members of variable_pays_attributes that share an id
    // with the existing variable_pays_attributes
    const vpFieldKey = fp.endsWith("_type", fieldKey) ? "pay_type" : "amount"
    const otherField = vpFieldKey === "pay_type" ? "amount" : "pay_type"

    const mergedVariablePaysAttributes = acc.variable_pays_attributes.map((accVPA) => {
      const newVPA = newAttributes.variable_pays_attributes?.find(
        (newVPA) => newVPA.id === accVPA.id,
      )

      return fp.merge(
        fp.pick(["id", "variable_pay_type_id", otherField], accVPA),
        fp.pick([vpFieldKey], newVPA),
      )
    })

    return {
      ...acc,
      variable_pays_attributes: mergedVariablePaysAttributes,
    }
  }
  return { ...acc, ...newAttributes }
}

export { useUpdateNodeWithMultipleInputs, UpdateNodeInput }
