import LegacyDepartmentKeyMigrator from "./legacyDepartmentKeyMigrator"
import options from "../constants/options"

const uniqueIntegers = (array) => {
  // eslint-disable-next-line no-bitwise
  const integers = array.map((n) => n | 0)

  return [...new Set(integers)]
}

const DEFAULT_SETTINGS = { collapsedNodes: [], zoom: options.initialZoom }
const NAMESPACE = "chart_state"

/**
 * Use this utility to help manage the chart state. This will store the chart
 * settings in local storage. Currently this manages the chart's collapsed
 * nodes, the chart's position, and the zoom level.
 */
class ChartStateManager {
  constructor(chartId) {
    if (window.localStorage) {
      this.storage = localStorage
      this.setChartContext(chartId)
      LegacyDepartmentKeyMigrator.maybeRunAsync(this.storage, NAMESPACE)
    } else {
      this.setNullChartContext()
    }
  }

  /**
   * @param {integer} chartId
   * @param {integer} chartSectionId The chart section ID (if viewing a chart
   *   section chart)
   * @param {integer} topId The ID of the top node (if viewing by top position)
   */
  setChartContext(chartId, chartSectionId = null, topId = null) {
    this.chartId = chartId
    this.chartSectionId = chartSectionId
    this.topId = topId
    this.settings = this.getSettings()
  }

  /**
   * The null chart context is used for charts that should not be tracked in
   * lucal storage. Currently the only charts that are tracked are top-level
   * charts and chart section views.
   */
  setNullChartContext() {
    this.chartId = null
    this.chartSectionId = null
    this.settings = null
  }

  /**
   * The storage key is based on the current chart context and is used for key
   * values in localStorage.
   *
   * @returns string
   */
  storageKey() {
    let key = `${NAMESPACE}.chart_${this.chartId}`

    if (this.chartSectionId) {
      key += `_chart_section_${this.chartSectionId}`
    }

    if (this.topId) {
      key += `_top_${this.topId}`
    }

    return key
  }

  /**
   * Add a node to the current context collapsed nodes list.
   *
   * @param {integer} nodeId The id of the org chart node
   */
  nodeCollapsed(nodeId) {
    if (this.isNullContext()) return
    const a = this.settings.collapsedNodes
    const b = [nodeId]
    this.settings.collapsedNodes = Array.from(new Set([...a, ...b]))
    this.persistSettings()
  }

  /**
   * Handler for when a node is expanded.
   * @param {integer} nodeId The id of the org chart node
   */
  nodeExpanded(nodeId) {
    if (this.isNullContext()) return
    const a = this.settings.collapsedNodes
    const b = new Set([nodeId])
    const filtered = a.filter((x) => !b.has(x))
    const difference = new Set(filtered)
    this.settings.collapsedNodes = Array.from(difference)
    this.persistSettings()
  }

  /**
   * Update stored zoom and coordinate level when coordinates change.
   *
   * @param {object} coordinateInfo The [x, y] coordinates and zoom level.
   *
   * The coordinateInfo object should look like this:
   * {
   *   zoom: 1,
   *   chart_position: [x, y]
   * }
   *
   * See the orgchart #handleZoom, where a 'coordinate-change' backbone event
   * is triggered and this information is passed through.
   */
  coodinatesChanged(coordinateInfo) {
    this.settings = { ...this.settings, ...coordinateInfo }
    this.persistSettings()
  }

  /**
   * Get the settings for the current chart's context.
   *
   * @returns {object} settings
   *
   * Settings object example:
   * {
   *   zoom: #{the zoom level; a number between 0 and 1},
   *   collapsedNodes: #{array of collapsed node ids},
   *   chartPosition: #{array of x and y coordinates}
   * }
   *
   */
  getSettings() {
    const localStorageSettings = localStorage ? localStorage.getItem(this.storageKey()) : null

    if (localStorageSettings) {
      try {
        return JSON.parse(localStorageSettings)
      } catch (e) {
        return DEFAULT_SETTINGS
      }
    }

    return DEFAULT_SETTINGS
  }

  /**
   * @returns {array} list of collapsed node ids
   */
  getCollapsedNodes() {
    const storedSettings = this.getSettings().collapsedNodes

    // Just in case localStorage is messed up somehow, coerce all values to
    // integers, then return the unique list.
    if (Array.isArray(storedSettings)) {
      return uniqueIntegers(storedSettings)
    }

    return []
  }

  /**
   * Get the stored zoom level. The max zoom value is 1..
   *
   * @returns {float} zoom level
   */
  getZoom() {
    return parseFloat(
      Math.min(parseFloat(this.getSettings().zoom) || 1, options.maximumZoom).toFixed(2),
    )
  }

  getCoordinates() {
    const storedCoordinates = this.getSettings().chart_position

    if (
      Array.isArray(storedCoordinates) &&
      storedCoordinates.every((coordinate) => typeof coordinate === "number")
    ) {
      return storedCoordinates
    }

    return []
  }

  /**
   * Clear all stored settings
   */
  clearAll() {
    return Object.keys(this.storage)
      .filter((key) => key.startsWith(NAMESPACE))
      .forEach((key) => localStorage.removeItem(key))
  }

  clear() {
    localStorage.removeItem(this.storageKey())
  }

  /**
   * Save chart settings in local storage
   */
  persistSettings() {
    localStorage.setItem(this.storageKey(), JSON.stringify(this.settings))
  }

  isNullContext() {
    return this.chartId == null
  }
}

export default ChartStateManager
