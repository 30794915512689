import $ from "jquery"
import hoverintent from "hoverintent"

const hoverintentOptions = {
  interval: 200,
  /**
   * Explicitly disable `handleFocus` as it can trip errors that break all
   * tooltips after a user drags/drops a position to report to someone else.
   * Docs state that when this is enabled, it adds handlers for keyboard
   * navigation.
   *
   * @see https://github.com/tristen/hoverintent
   */
  handleFocus: false,
}

// This is built to handle tooltips on <text> elements within an svg g.node
class ToolTipHandler {
  constructor() {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const elem = window.d3.select(entry.target)
        this.createMouseTarget(elem, elem.attr("data-position"), entry.contentRect)
        this.resizeObserver.unobserve(entry.target)
      }
    })

    this.tooltip = window.d3
      .select("body")
      .append("span")
      .style({
        "max-width": "250px",
        "white-space": "normal",
      })
      .attr("class", "tooltip-content tooltip-center orgchart-tooltip")
  }

  add(element, value, position) {
    element.attr("data-value", value).attr("data-position", position ? position : "center")
    this.resizeObserver.observe(element.node())
  }

  // Handing mouse events on a text element directly ends up causing many more
  // events than you might expect, so instead the <text> is wrapped in a group
  // with a rectangle of the same size as the <text> element. The rectangle
  // will handle mouse events.
  createMouseTarget(element, position = "center", textBoundingRect) {
    // JS will not complain when adding values of mixed types, so cast any node
    // attributes to numbers ASAP to avoid silent/tricky errors.
    const originalX = parseFloat(element.attr("x"))
    let rectForMouseTargetX = 0
    let mouseTargetX = -textBoundingRect.width / 2
    let mouseTargetY = parseFloat(element.attr("y"))
    if (!mouseTargetY) {
      return
    }
    element.attr("x", 0).attr("y", 0)

    if (position === "right" && element.attr("text-anchor") === "end") {
      // If position is set to 'right' and text-anchor is 'end', the original x
      // ensures proper alignment of the text. Preserve it by using it as
      // `mouseTargetX` and leave the text anchor alone.
      //
      // A consequence of this is that the `rect` element also shifts by `x`,
      // which will place it to the right of the text. This is a problem, shown
      // below (we want rect on top of the text):
      //                                  original x
      //     (0, 0) --------------------------|
      //                   text flows back... | [click rect .... ....]
      mouseTargetX = originalX

      // Shift the rect back by a value equivalent to truncated text width, so
      // end result looks like this (rect over text again):
      //
      //                                  original x
      //     (0, 0) --------------------------|
      //               [click rect over text] |
      rectForMouseTargetX = -textBoundingRect.width
    } else if (position === "right") {
      mouseTargetX = 0
      element.attr("text-anchor", "start")
    } else if (position === "left") {
      // Try using the original x value as it will be the most accurate
      // coordinate, but fallback if it's not defined. This deals with an edge
      // case where card labels aren't left-aligned properly.
      // We fall back to using the textBoundingRect's full width if an original
      // x was not given. Otherwise, we use the original x
      mouseTargetX = Number.isFinite(parseFloat(originalX)) ? originalX : -textBoundingRect.width
      element.attr("text-anchor", "")
    } else {
      element.attr("text-anchor", "start")
    }

    const tooltipContainer = window.d3
      .select(element.node().parentNode)
      .insert("g")
      .attr("class", "mouse-target")
      .attr("transform", `translate(${mouseTargetX},${mouseTargetY})`)
    tooltipContainer.append(() => element.node())

    // If the target is an SVG, it does not need an adjusted `y` value.
    const mouseTargetRectY = element.node().nodeName === "svg" ? 0 : -textBoundingRect.height + 3

    const mouseTarget = tooltipContainer.append("rect").attr({
      // Fill is required; otherwise mouse events will not work
      fill: "#fff",
      opacity: 0,
      width: textBoundingRect.width,
      height: textBoundingRect.height,
      x: rectForMouseTargetX,
      y: mouseTargetRectY,
    })
    element.targetHoverListener = hoverintent(
      mouseTarget.node(),
      () => this.show(element, position),
      () => this.hide(),
    ).options(hoverintentOptions)
  }

  show(element, position = "center") {
    const textBoundingRect = element.node().getBoundingClientRect()
    const cardBoundingRect = ToolTipHandler.getCardBoundingRect(element)
    this.tooltip.text($(element.node()).data("value"))
    const topOffset = textBoundingRect.top + textBoundingRect.height
    let positionOffset = 0.5
    if (position === "right") {
      positionOffset = 0.75
    }
    if (position === "left") {
      positionOffset = 0.25
    }
    if (element.node().nodeName === "svg") {
      positionOffset = 0.92
    }
    const leftOffset = cardBoundingRect.x + cardBoundingRect.width * positionOffset
    this.tooltip.style({
      visibility: "visible",
      opacity: 1,
      top: `${topOffset}px`,
      left: `${leftOffset}px`,
    })
  }

  hide() {
    this.tooltip.style("visibility", "hidden")
  }

  static getCardBoundingRect(element) {
    const nodeGrouping = $(element.node()).closest("g.node")
    const card = nodeGrouping.find("rect.card")
    if (!card[0]) return {}

    return card[0].getBoundingClientRect()
  }
}

export default ToolTipHandler
