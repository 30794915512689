import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/Tooltip"

interface PhoneIconProps {
  phone: string
  phoneTooltip: string
}

function PhoneIcon({ phone, phoneTooltip }: PhoneIconProps) {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <Tooltip open={showTooltip}>
      <TooltipTrigger
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <a href={`tel:${phone}`} className="text-neutral-80 inline-block">
          <FontAwesomeIcon icon={["fas", "phone"]} size="1x" />
        </a>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content--sm">
        {"click to call".t("org_chart", null, null, null, [phoneTooltip])}
      </TooltipContent>
    </Tooltip>
  )
}

export { PhoneIcon }
