import React, { PropsWithChildren, useEffect, useState } from "react"
import i18n from "i18next"
import { initReactI18next, I18nextProvider } from "react-i18next"
import { I18nProvider } from "react-aria"

import i18nManifest from "v2/i18n-manifest.json"
import { AppStore, useAppDispatch, useAppSelector } from "v2/redux/store"
import { loadedI18n } from "v2/redux/slices/SessionSlice"
import { StoreProvider } from "v2/redux/StoreProvider"

interface I18nManifest {
  [localeFile: string]: string
}

interface LocaleValues {
  [key: string]: string | LocaleValues
}

interface LocaleData {
  [key: string]: LocaleValues
}

type RootProviderProps = PropsWithChildren<{
  canLoadI18nResources?: boolean
  i18n?: typeof i18n
  store?: AppStore
}>

const loadLocaleData = (locale: string): Promise<LocaleData | null> => {
  const manifest: I18nManifest = i18nManifest as I18nManifest
  const localeFileOriginalFilename = `app-${locale}.json`
  const localeFileWithDigest = manifest[localeFileOriginalFilename]

  return fetch(`/javascripts/i18n/${localeFileWithDigest}`)
    .then((response) => response.json())
    .catch(() => null)
}

const currentLanguage = window.gon?.current_user_locale ?? "en"

export const i18nInstance = i18n

i18nInstance.use(initReactI18next).init({
  lng: currentLanguage,
  fallbackLng: "en",
  debug: false,
  interpolation: {
    prefix: "%{",
    suffix: "}",
    escapeValue: true,
  },
  nsSeparator: ".",
  resources: {},
})

export const I18nDataLoader = ({
  canLoadI18nResources,
  children,
}: PropsWithChildren<{ canLoadI18nResources: boolean }>) => {
  const isI18nLoaded = useAppSelector((state) => state.session.loadedI18n)
  const [loading, setLoading] = useState(canLoadI18nResources && !isI18nLoaded)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isI18nLoaded || !canLoadI18nResources) return

    loadLocaleData(currentLanguage)
      .then((localeData) => {
        if (localeData && localeData[currentLanguage]) {
          Object.keys(localeData[currentLanguage]).forEach((key) => {
            i18n.addResourceBundle(
              currentLanguage,
              key,
              localeData[currentLanguage][key],
              true,
              true,
            )
          })
        }
        setLoading(false)
        dispatch(loadedI18n(true))
      })
      .catch(() => {
        setLoading(false)
      })
  }, [canLoadI18nResources, dispatch, isI18nLoaded])

  return loading ? null : children
}

export default function RootProvider({
  canLoadI18nResources = true,
  children,
  i18n: i18nFromCaller,
  store,
}: RootProviderProps) {
  return (
    <I18nextProvider i18n={i18nFromCaller ?? i18nInstance}>
      <I18nProvider locale={currentLanguage}>
        <StoreProvider store={store}>
          <I18nDataLoader canLoadI18nResources={canLoadI18nResources}>{children}</I18nDataLoader>
        </StoreProvider>
      </I18nProvider>
    </I18nextProvider>
  )
}
