import React, { useState } from "react"
import { ChartApprover } from "types/graphql"
import { EditApproversButton } from "v2/react/components/orgChart/ChartApproval/ApprovalDropdown/EditApproversButton"
import { OrderListItemStatic } from "v2/react/shared/OrderedList/OrderListItemStatic"
import { PersonName } from "v2/react/shared/Status/ApproverList/PersonName"
import { toggleConfigureModal } from "v2/redux/slices/ApprovalSlice"
import { useAppDispatch } from "v2/redux/store"

interface Props {
  approvers: ChartApprover[]
  canConfigure: boolean
  canEditConfiguration: boolean
}

function PendingDisplay({ approvers, canConfigure, canEditConfiguration }: Props) {
  const dispatch = useAppDispatch()
  const openConfigureModal = () => dispatch(toggleConfigureModal(true))
  const [noteValue, setNoteValue] = useState("")

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setNoteValue(e.currentTarget.value)

  return (
    <div className="grid-rows-[auto_1fr] grid sm:max-h-[60vh]">
      <div className="pb-4 text-large hidden sm:block">{"request_approval".t("org_chart")}</div>
      <div className="overflow-x-hidden px-0.5 sm:overflow-y-auto">
        <div className="items-center justify-between pb-4 flex">
          <p className="margin-0 text-base-bold hidden sm:block">{"approvers".t("org_chart")}</p>
          <p className="margin-0 text-base-bold sm:hidden">{"request_approval".t("org_chart")}</p>
          <EditApproversButton
            canSeeButton={canConfigure}
            canUseButton={canEditConfiguration}
            onClick={openConfigureModal}
          />
        </div>
        <section className="OrderedList">
          {approvers.map((approver: ChartApprover, index: number) => (
            <OrderListItemStatic
              key={approver.id}
              isFirst={index === 0}
              isLast={index === approvers.length - 1}
              indexPanelColor="secondary-light"
              indexPanelContent={cardColorPanel(index)}
              mainPanelContent={cardMainPanel(approver)}
            />
          ))}
        </section>
        <label htmlFor="requestor-note" className="mb-1 mt-4 text-base-bold flex">
          {"note".t("org_chart")}
        </label>
        <textarea
          className="input mb-4 max-h-[12rem] min-h-[6.25rem] w-full resize-y block"
          onChange={handleNoteChange}
          id="requestor-note"
          name="note"
          value={noteValue}
        />
      </div>
    </div>
  )
}

export default PendingDisplay

const cardColorPanel = (index: number) => <p>{index + 1}</p>

const cardMainPanel = (approver: ChartApprover) => (
  <>
    {approver.person.avatarThumbUrl && (
      <img
        className="circle margin-0"
        src={approver.person.avatarThumbUrl}
        alt={approver.person.name}
      />
    )}
    <div className="card-item__content ml-2">
      <PersonName person={approver.person} />
      {approver.person.primaryPosition?.title && (
        <p className="margin-0 text-sm">{approver.person.primaryPosition.title}</p>
      )}
    </div>
  </>
)
