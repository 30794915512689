import React, { StrictMode, ChangeEvent, useEffect } from "react"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import fp from "lodash/fp"

import RootProvider from "v2/react/components/RootProvider"
import {
  selectActiveDisplayFields,
  selectDisplayFieldOptions,
} from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useAppSelector } from "v2/redux/store"

import type { DisplayFieldOption } from "types/graphql"

import { FieldsetWithSearch } from "v2/react/shared/FieldsetWithSearch"
import { useFieldsetWithSearch } from "v2/react/shared/FieldsetWithSearch/hooks/useFieldsetWithSearch"

export type Column = {
  id: string
  label: string
  isSelected?: boolean
  isVisible?: boolean
}

const displayFieldsIgnoredForExport = ["avatar"]
const defaultCheckedFields = [
  "system_identifier",
  "external_identifier",
  "name",
  "employee_id",
  "work_email",
  "title",
  "is_primary",
  "reports_to_position_id",
  "reports_to_position_external_identifier",
  "reports_to_name",
  "reports_to_employee_id",
  "reports_to_work_email",
  "reports_to_title",
]

const SELECTED_COLUMNS_SESSION_KEY = `chart-${window.gon?.chart_settings_key}-export-selected-columns`
const FIELD_KEY_TO_TRANSLATION_KEY: Record<string, string> = {
  fte: "fte_full_label",
}

const sessionSelectedColumnsOrDefault = fp.flow(
  () => window.sessionStorage?.getItem(SELECTED_COLUMNS_SESSION_KEY),
  fp.defaultTo(""),
  fp.split(","),
  fp.compact,
)

function getOptionLabel(field: string, t: TFunction, i18nExists: (key: string) => boolean): string {
  const translationKey = FIELD_KEY_TO_TRANSLATION_KEY[field] ?? field
  if (i18nExists(`lib.org_chart.export_data.headers.${translationKey}`)) {
    return t(`lib.org_chart.export_data.headers.${translationKey}`)
  }
  return window.RelationalNodeDataStore?.instance?.fieldLabelOrTranslatedValue(field) || field
}

function getInitialColumns(
  displayFieldOptions: DisplayFieldOption[],
  displayFields: string[],
  sessionSelectedColumns: string[],
  t: TFunction,
  i18nExists: (key: string) => boolean,
) {
  const displayOptionKeys = displayFieldOptions.map((option) => option.id)
  const allFields = Array.from(
    new Set([...displayFields, ...displayOptionKeys, ...defaultCheckedFields]),
  ).filter((field) => !displayFieldsIgnoredForExport.includes(field))

  const columnObjects = allFields.map((field) => {
    const isSelected =
      sessionSelectedColumns.length > 0
        ? sessionSelectedColumns.includes(field)
        : displayFields.includes(field) || defaultCheckedFields.includes(field)
    return {
      id: field,
      label: getOptionLabel(field, t, i18nExists),
      isSelected,
      isVisible: isSelected,
    }
  })

  return columnObjects.sort((a, b) => {
    const isSelectedCompare = Number(b.isSelected) - Number(a.isSelected)
    if (isSelectedCompare !== 0) return isSelectedCompare

    const sessionOrderA = sessionSelectedColumns.indexOf(a.id)
    const sessionOrderB = sessionSelectedColumns.indexOf(b.id)

    if (sessionOrderA !== -1 || sessionOrderB !== -1) {
      return sessionOrderA - sessionOrderB
    }

    return displayFields.indexOf(a.id) - displayFields.indexOf(b.id)
  })
}

interface ChooseColumnsListFormProps {
  onSelectedColumnsChange?: (selectedColumns: string[]) => void
}

export function ChooseColumnsListFormInner({
  onSelectedColumnsChange,
}: ChooseColumnsListFormProps) {
  const { t, i18n } = useTranslation()
  const displayFields = useAppSelector(selectActiveDisplayFields)
  const displayFieldOptions = useAppSelector(selectDisplayFieldOptions)
  const sessionSelectedColumns = sessionSelectedColumnsOrDefault()
  const initialColumns = getInitialColumns(
    displayFieldOptions,
    displayFields,
    sessionSelectedColumns,
    t,
    i18n.exists,
  )

  const {
    selectedFields: visibleColumns,
    unselectedFields: hiddenColumns,
    handleReorder,
    setSelectedFields: setVisibleColumns,
    setUnselectedFields: setHiddenColumns,
  } = useFieldsetWithSearch<Column>({
    selectedFieldOptions: initialColumns?.filter((c) => c.isVisible),
    allFields: initialColumns,
  })

  useEffect(() => {
    const selectedColumnIds = visibleColumns?.filter((c) => c.isSelected)?.map((c) => c.id) || []

    if (selectedColumnIds) {
      sessionStorage?.setItem(SELECTED_COLUMNS_SESSION_KEY, selectedColumnIds.join(","))
    }
    window.App?.OrgChart?.toolbar?.exportModal?.setSelectedColumns(selectedColumnIds)
    if (onSelectedColumnsChange && selectedColumnIds) {
      onSelectedColumnsChange(selectedColumnIds)
    }
  }, [visibleColumns, onSelectedColumnsChange])

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const columnId = event.target.value
    setVisibleColumns((currentColumns) => {
      if (currentColumns === null) {
        return []
      }

      return currentColumns.map((col) =>
        col.id === columnId ? { ...col, isSelected: event.target.checked } : col,
      )
    })
  }

  const handleSearchSelect = (column: Column) => {
    setVisibleColumns((currentColumns) => {
      if (!currentColumns) {
        return [{ ...column, isSelected: true, isVisible: true }]
      }
      return [...currentColumns, { ...column, isSelected: true, isVisible: true }]
    })
    setHiddenColumns((currentColumns) => {
      if (!currentColumns) {
        return []
      }
      return currentColumns.filter((col) => col.id !== column.id)
    })
  }

  return (
    <div>
      <div className="input-group">
        <FieldsetWithSearch<Column>
          id="export-columns"
          label={"csv_export_fields_title".t("org_chart")}
          selectedItems={visibleColumns || []}
          searchableItems={hiddenColumns || []}
          onReorder={handleReorder}
          onCheckboxChange={handleCheckboxChange}
          onSearchSelect={handleSearchSelect}
          makeCheckboxId={(col) => `orgchart_export_columns_${col.id}`}
          makeCheckboxName={() => `columns[]`}
          useBorder
        />
      </div>
    </div>
  )
}

function ChooseColumnsListForm({ onSelectedColumnsChange }: ChooseColumnsListFormProps) {
  return (
    <StrictMode>
      <RootProvider>
        <ChooseColumnsListFormInner onSelectedColumnsChange={onSelectedColumnsChange} />
      </RootProvider>
    </StrictMode>
  )
}

export default ChooseColumnsListForm
