import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"

import { Person, Position } from "types/graphql"

import { totalTenureInYears } from "./utils"

interface Props {
  position: Position
}

function PreviouslyFilledBy({ position }: Props) {
  const people = position.previouslyFilledBy || []
  const { t } = useTranslation()

  if (!people) return null

  return (
    <div className="module-card">
      <div className="module-card__header">
        <div className="module-title">{t("v2.positions.show.previously_filled_by")}</div>
      </div>
      <div className="module-card__body max-h-[400px] gap-0 overflow-y-auto border-0 p-0">
        <PersonRows people={people} t={t} />
      </div>
    </div>
  )
}

const PersonRows = ({ people, t }: { people: Person[]; t: TFunction }) =>
  (people || []).map((person: Person) => <PersonRow person={person} key={person.id} t={t} />)

const PersonRow = ({ person, t }: { person: Person; t: TFunction }) => (
  <div className="border-0 border-t border-solid border-neutral-8 px-4 py-4 flex">
    <div className="items-center pr-2 flex">
      {person.avatarThumbUrl ? (
        <img className="circle" src={person.avatarThumbUrl} alt="user avatar" />
      ) : (
        <div className="h-9 w-9 shrink-0 grow-0 items-center justify-center rounded-full bg-primary-8-solid text-primary-100 flex">
          <FontAwesomeIcon icon={["far", "chair-office"]} />
        </div>
      )}
    </div>
    <div>
      <div className="text-neutral-100">{person.name}</div>
      <div className="text-neutral-64">
        {t("v2.positions.show.tenure_in_years", {
          count: totalTenureInYears(person.hireDate, person.createdAt),
        })}
      </div>
    </div>
  </div>
)

export { PreviouslyFilledBy }
