import dayjs from "dayjs"
import React from "react"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSortedBoxes } from "v2/react/components/orgChart/ProfilePanel/useSortedBoxes"
import { ProfilePanelAbilities } from "v2/react/components/orgChart/ProfilePanel/ProfilePanel"
import { AccordionModulePanel } from "v2/react/shared/AccordionModulePanel"
import { useAppSelector } from "v2/redux/store"
import { FeatureFlags } from "types/graphql"

interface EmployeeInfoProps {
  abilities: ProfilePanelAbilities
  featureFlags: FeatureFlags
}

function EmployeeInfo({ abilities, featureFlags }: EmployeeInfoProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const { currentBox, toggleBox } = useSortedBoxes("employee_info", "person")

  if (!currentBox || !person || !abilities.canReadSensitivePersonData) return null

  const canViewSuccessionFields = featureFlags.successionPlanning && abilities.canReadSuccessionPlan

  const headerContent = <div className="title">{"employee_info".t("org_chart")}</div>

  const hireDateDisplay = (
    <ModuleListItem>
      <div>{"Hire Date".t("org_chart")}</div>
      <div>{person.hireDate && dayjs(person.hireDate).format("MMM D, YYYY")}</div>
    </ModuleListItem>
  )

  const yearsAtCompany = (
    <ModuleListItem>
      <div>{"Years at Company".t("org_chart")}</div>
      <div>
        {person.employmentTenure
          ? "years_label".t("defaults", null, "%d years", person.employmentTenure, [
              person.employmentTenure,
            ])
          : ""}
      </div>
    </ModuleListItem>
  )

  const employeeStatus = (
    <ModuleListItem className="profile-panel-section-employee-info">
      <div>{"Status".t("org_chart")}</div>
      <div className={`status ${person.employeeStatus?.systemType}`}>
        {person.employeeStatus?.label
          ? person.employeeStatus?.label.t("profile", "header", null, null, [])
          : ""}
      </div>
    </ModuleListItem>
  )

  const employeeType = (
    <ModuleListItem>
      <div>{"Employee Type".t("org_chart")}</div>
      <div>{person.employeeType?.labelTranslated}</div>
    </ModuleListItem>
  )

  const employeeId = (
    <ModuleListItem>
      <div>{"Employee ID".t("org_chart")}</div>
      <div>{person.employeeId}</div>
    </ModuleListItem>
  )

  const performance = (
    <ModuleListItem>
      <div>{"Performance".t("profile", "employment_details")}</div>
      <div>{person.performance ? person.performance.t("profile", "job") : ""}</div>
    </ModuleListItem>
  )

  const potential = (
    <ModuleListItem>
      <div>{"Potential".t("profile", "employment_details")}</div>
      <div>{person.potential ? person.potential.t("profile", "job") : ""}</div>
    </ModuleListItem>
  )

  const flightRisk = (
    <ModuleListItem>
      <div>{"Flight Risk".t("profile", "employment_details")}</div>
      <div>{person.flightRisk ? person.flightRisk.t("profile", "job") : ""}</div>
    </ModuleListItem>
  )

  const successionPlanFields = () => {
    if (!canViewSuccessionFields) return null
    return (
      <>
        {performance} {potential} {flightRisk}
      </>
    )
  }

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      {hireDateDisplay}
      {yearsAtCompany}
      {employeeStatus}
      {employeeType}
      {employeeId}
      {successionPlanFields()}
    </AccordionModulePanel>
  )
}

export { EmployeeInfo }
