import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CellContext } from "@tanstack/react-table"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { CareerLadder } from "types/graphql"
import { DeleteCareerLadderButton } from "v2/react/components/careerLadders/Index/DeleteCareerLadderButton"
import { Header } from "v2/react/components/careerLadders/Index/Header"
import { NewCareerLadderButton } from "v2/react/components/careerLadders/Index/NewCareerLadderButton"
import { EditCareerLadderModal } from "v2/react/components/careerLadders/Show/EditCareerLadderModal"
import { UtilityNav } from "v2/react/components/navigation/UtilityNav"
import RootProvider from "v2/react/components/RootProvider"
import { useClientTable } from "v2/react/hooks/useClientTable"
import { useSelectList } from "v2/react/hooks/useSelectList"
import { DropdownMenu } from "v2/react/shared/DropdownMenu"
import { Link } from "v2/react/shared/Links/Link"
import { Spinner } from "v2/react/shared/Spinner"
import { Table } from "v2/react/shared/Table"
import { UrlHelper } from "v2/react/utils/urls"
import { useCareerLaddersConnectionQuery } from "v2/redux/GraphqlApi/CareerLaddersApi"

function WithProvider() {
  const { t } = useTranslation()
  const { data, isLoading } = useCareerLaddersConnectionQuery({})

  const rows = data?.collection || []

  const table = useClientTable({
    columns: [
      { accessorKey: "actions", header: "", enableSorting: false, cell: MoreActionsCell },
      { accessorKey: "code", header: t("v2.career_ladders.column_headers.code"), cell: LinkedRow },
      { accessorKey: "name", header: t("v2.career_ladders.column_headers.name"), cell: LinkedRow },
      {
        accessorKey: "description",
        header: t("v2.career_ladders.column_headers.description"),
        cell: WrapRow,
      },
      {
        accessorKey: "numJobDefinitions",
        header: t("v2.career_ladders.column_headers.num_job_definitions"),
      },
    ],
    data: rows,
  })

  return (
    <>
      <Header />

      <UtilityNav>
        <div className="w-full items-center justify-end gap-2 flex">
          <NewCareerLadderButton />
        </div>
      </UtilityNav>
      <div className="page-pad">
        {isLoading ? (
          <Spinner />
        ) : (
          <Table
            table={table}
            footerContent={t("v2.career_ladders.index.found", {
              count: data?.metadata?.totalCount || 0,
            })}
          />
        )}
      </div>
    </>
  )
}
const Index = () => (
  <RootProvider>
    <WithProvider />
  </RootProvider>
)

export { Index }

const LinkedRow = ({ cell }: CellContext<CareerLadder, unknown>) => (
  <Link to={UrlHelper.careerLadderDetailsPath(cell.row.original.id)}>
    {cell.getValue() as string}
  </Link>
)

const WrapRow = ({ cell }: CellContext<CareerLadder, unknown>) => (
  <div className="min-w-[400px] whitespace-normal">{cell.getValue() as string}</div>
)

const MoreActionsCell = ({ row: { original } }: CellContext<CareerLadder, unknown>) => {
  const { t } = useTranslation()
  const [showList, setShowList] = useState(false)
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)

  const openEditModal = () => setEditModalIsOpen(true)

  const onEditModalClose = () => {
    setEditModalIsOpen(false)
    setShowList(false)
  }

  const { context, floatingStyles, getFloatingProps, refs } = useSelectList({
    showList,
    setShowList,
    floatOverrides: { placement: "bottom-start" },
    offset: -10,
  })

  const handleEllipsisClick = () => setShowList((curr) => !curr)

  return (
    <>
      <div className="dropdown--react nav-overflow-menu">
        <button
          className="btn dropdown-link"
          type="button"
          onClick={handleEllipsisClick}
          ref={refs.setReference}
        >
          <FontAwesomeIcon icon={["far", "ellipsis-h"]} className="icon-14" />
        </button>

        <DropdownMenu
          context={context}
          showList={showList}
          floatingRef={refs.setFloating}
          floatingStyles={floatingStyles}
          floatingProps={getFloatingProps}
          wrapperClasses="dropdown-menu !block !w-fit right-0 p-2 z-10 mt-2"
        >
          <Link to={UrlHelper.careerLadderDetailsPath(original.id)} classes="dropdown-menu-link">
            <FontAwesomeIcon icon={["far", "eye"]} className="icon-14 mr-2" />
            {t("v2.defaults.view")}
          </Link>
          <button className="dropdown-menu-link" type="button" onClick={openEditModal}>
            <FontAwesomeIcon icon={["far", "pencil"]} className="icon-14 mr-2" />
            {t("v2.defaults.edit")}
          </button>
          <hr className="my-2" />
          <DeleteCareerLadderButton careerLadder={original} setShowList={setShowList} />
        </DropdownMenu>
      </div>
      {editModalIsOpen && (
        <EditCareerLadderModal careerLadder={original} onClose={onEditModalClose} />
      )}
    </>
  )
}
