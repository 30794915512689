import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useRef, useState } from "react"

interface EllipsisDropdownProps {
  children: React.ReactNode | React.ReactNode[] | null
}

function EllipsisDropdown({ children }: EllipsisDropdownProps) {
  const [dropdownMenu, setDropdownMenu] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target as Node)) {
        setDropdownMenu(false)
      }
    }

    if (dropdownMenu) {
      document.addEventListener("mousedown", handleClick)
    }

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [dropdownMenu])

  const delayMenuClose = () => setTimeout(() => setDropdownMenu(false), 100)

  return (
    <div className="no-print relative ml-4 content-center grid" ref={dropdownRef}>
      <button
        className="dropdown-link btn--sm btn--ghost"
        type="button"
        onClick={() => setDropdownMenu(!dropdownMenu)}
      >
        <FontAwesomeIcon icon={["far", "ellipsis-h"]} />
      </button>
      {dropdownMenu && (
        <div
          className="arrow-dropdown-menu"
          tabIndex={0}
          role="menu"
          onClick={delayMenuClose}
          onKeyDown={delayMenuClose}
        >
          {children}
        </div>
      )}
    </div>
  )
}

export { EllipsisDropdown }
