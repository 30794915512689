import { startCase } from "lodash"
import { TFunction } from "i18next"

import { CustomFieldValue, Position, PositionType } from "types/graphql"
import { formatCurrency } from "v2/react/utils/currency"

const eeocValue = (position: Position | PositionType, t: TFunction): string =>
  position.eeocClassification
    ? t(`v2.simple_form.options.defaults.eeoc_classification.${position.eeocClassification}`)
    : "-"

const flsaValue = (position: Position | PositionType, t: TFunction): string =>
  position.flsaClassification
    ? t(`v2.simple_form.options.defaults.flsa_classification.${position.flsaClassification}`)
    : "-"

const customFieldTitleLabel = (customFieldValue: CustomFieldValue): string => {
  const fieldName = customFieldValue.name || ""
  return startCase(fieldName)
}

const customFieldValueLabel = (customFieldValue: CustomFieldValue): string => {
  if (!customFieldValue.formatted_value) return "-"

  // Ensure currencies are formatted correctly
  if (customFieldValue.custom_field?.field_type === "currency") {
    if (!customFieldValue.value) return "-"
    const numericValue = parseFloat(customFieldValue.value)
    if (!numericValue) return "-"
    return formatCurrency({ value: numericValue })
  }

  return customFieldValue.formatted_value
}

export { eeocValue, flsaValue, customFieldTitleLabel, customFieldValueLabel }
