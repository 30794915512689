import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface DrawerTabFragmentProps extends React.PropsWithChildren {
  contentClassName?: string
  isActive: boolean
  onClickClose?: () => void
  title: string
}

function DrawerTabFragment({
  children,
  contentClassName,
  isActive,
  onClickClose,
  title,
}: DrawerTabFragmentProps) {
  const contentClass =
    contentClassName ||
    classNames("spreadsheet-drawer-tab drawer-section-content", { "is-inactive": !isActive })

  return (
    <>
      <div className="drawer-header items-center justify-between flex">
        <p className="drawer-title">{title}</p>
        <button type="button" className="close" onClick={onClickClose}>
          <FontAwesomeIcon icon={["far", "times"]} className="my-1" />
        </button>
      </div>
      <div className={contentClass}>{children}</div>
    </>
  )
}

export { DrawerTabFragment, DrawerTabFragmentProps }
