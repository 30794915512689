import { ControlProps } from "@jsonforms/core"
import classNames from "classnames"
import React, { ChangeEvent, FC } from "react"
import { Info } from "v2/react/shared/Info"
import { isDisabledFromSchema } from "v2/react/shared/JsonFormControls/utils/forms"
import { useValidation } from "./hooks/useValidation"

const JsonTextInput: FC<ControlProps> = ({
  config,
  data,
  handleChange,
  id,
  label,
  path,
  schema,
  uischema,
  visible,
  enabled,
}) => {
  const isDisabled = isDisabledFromSchema({ enabled, schema })
  const { showError, errorMessage } = useValidation({
    data,
    path,
    schema,
    submitting: config.submitting,
  })

  const handleChangeEvent = (event: ChangeEvent<HTMLInputElement>) =>
    handleChange(path, event.target.value)

  if (!visible) return null

  return (
    <div className={classNames("input-group", { "form-error": showError })}>
      <label htmlFor={id} className="items-center whitespace-pre-wrap flex">
        {label}
        {uischema.options?.tooltip && <Info description={uischema.options.tooltip} />}
      </label>
      <input
        type="text"
        className="input"
        name={id}
        id={id}
        placeholder={uischema.options?.placeholder && uischema.options.placeholder}
        defaultValue={data}
        onChange={handleChangeEvent}
        disabled={isDisabled}
      />
      {showError && <p className="my-1 text-status-critical">{errorMessage}</p>}
    </div>
  )
}

export { JsonTextInput }
