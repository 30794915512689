import React, {
  CSSProperties,
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"
import classNames from "classnames"
import { defaultMemoize } from "reselect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isEmpty } from "lodash/fp"

import { Maybe, Person } from "types/graphql.d"
import { PositionSeatChangeIntent } from "types/graphql.enums"
import { CursorConnection, NodeRow } from "v2/react/shared/Datasheet/types"

import { useAppSelector } from "v2/redux/store"
import { useAutoComplete } from "v2/react/hooks/useAutocomplete"
import { useDatasheetListenerActions } from "v2/redux/listeners/datasheetListeners"
import {
  useFuncOnNextKeyUp,
  useStandardCellInputMouseDownHandler,
} from "v2/react/shared/Datasheet/Cell/hooks"
import { usePersonSearch } from "v2/react/hooks/usePersonSearch"
import { usePositionSeatChange } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/hooks/usePositionSeatChange"

import { idFromUniqueKey } from "v2/react/utils/uniqueKey"
import { CreatePersonModal } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/CreatePersonModal"
import { PositionSeatChangeModal } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/PositionSeatChangeModal"
import { DropdownMenu } from "v2/react/shared/DropdownMenu"
import { TransitionKeys } from "v2/react/components/orgChart/OrgChartDatasheet/hooks/cursorKeyMovements"

interface PersonCompleteProps<TNode> {
  cursorConnection: CursorConnection
  row: NodeRow<TNode>
  isFirst: boolean
  isLast: boolean
  noBorder?: boolean
  style: CSSProperties
  html: string | null
  focusCell: boolean
}

function PersonComplete<TNode>({
  cursorConnection,
  row,
  isFirst,
  isLast,
  noBorder,
  style,
  html,
  focusCell,
}: PersonCompleteProps<TNode>) {
  const [modalOpen, setModalOpen] = useState({
    createPerson: false,
    positionSeatChange: false,
  })
  const [selectedPerson, setSelectedPerson] = useState<Maybe<Person>>(null)
  const [changePositionSeat] = usePositionSeatChange()
  const { fieldSaved } = useDatasheetListenerActions()
  const chartId = useAppSelector((state) => state.container.containerKey)
  const anyModalsOpen = modalOpen.createPerson || modalOpen.positionSeatChange
  const afterModalClose = useRef<"none" | (() => void)>("none")
  const [handleKeyUp, deferUntilKeyUp] = useFuncOnNextKeyUp({
    preventDefault: true,
    stopPropagation: true,
  })
  const maybeDefer = (ev?: KeyboardEvent) => (func: () => void) =>
    ev ? deferUntilKeyUp(func) : func()

  const currentPersonId = (row.data as { person_id: string }).person_id || null
  // We need to convert the person_id (unique_key) to just the id to omit it from the search results.
  // If we convert the id -> the unique_key in our graphql types, this can be removed.
  const currentPersonIdNumber = currentPersonId && idFromUniqueKey(currentPersonId)
  const omitValues = useMemo(
    () => (currentPersonIdNumber ? [currentPersonIdNumber] : []),
    [currentPersonIdNumber],
  )

  const {
    showResultList,
    setShowResultList,
    inputValue,
    setInputValue,
    peopleResult,
    handleInputChange,
  } = usePersonSearch({
    omitValues,
    fieldValue: html || "",
    chartId: chartId || undefined,
  })

  const {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useAutoComplete({ showList: showResultList, setShowList: setShowResultList })

  useImperativeHandle(
    cursorConnection.cellInputRef,
    () => ({
      blur: () => {
        setActiveIndex(null)
        setShowResultList(false)
        refs.domReference.current?.blur?.()
      },
      focus: (initial) => {
        const { current } = refs.domReference
        if (initial) setInputValue(initial)
        if (initial && current) current.value = initial
        current?.focus?.()
      },
      getValue: () => inputValue,
    }),
    [inputValue, refs.domReference, setActiveIndex, setShowResultList, setInputValue],
  )

  // Update the input value if the parent indicates it has changed. Skip if the
  // cell is also focused to prevent overriding user input.
  if (cleanString(inputValue) !== cleanString(html) && !focusCell && !selectedPerson) {
    setInputValue(html || "")
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    handleInputChange(event)

    if (value.length > 0) {
      setShowResultList(true)
      setActiveIndex(0)
    } else {
      setShowResultList(false)
    }
  }

  const handleEnterAsSubmit = (event: KeyboardEvent<HTMLInputElement>) => {
    if (anyModalsOpen) return
    if (activeIndex && event.key !== "Enter") return
    if (!activeIndex && !TransitionKeys.matchEvent(event.nativeEvent)) return

    event.preventDefault()
    event.stopPropagation()
    handleSubmit(event)
  }

  const handleSubmit = async (event?: KeyboardEvent) => {
    const clickedDropdownResult = activeIndex !== null && peopleResult[activeIndex]
    const emptyInput = isEmpty(inputValue.trim())

    if (clickedDropdownResult) {
      handlePersonSelect(peopleResult[activeIndex], event)
    } else if (emptyInput && currentPersonId) {
      await handlePositionSeatChange({
        event,
        personId: currentPersonId,
        rowId: row.id,
        intent: PositionSeatChangeIntent.Remove,
      })
    } else if (showResultList && peopleResult.length === 0 && inputValue.length > 0) {
      handleCreatePerson(event)
    } else {
      maybeDefer(event)(() => {
        cursorConnection.stopWriting(getCursorOptions(event))
      })
    }
  }

  const handleModalClose = () => {
    if (afterModalClose.current !== "none") {
      afterModalClose.current()
      afterModalClose.current = "none"
    } else {
      cursorConnection.stopWriting({ transitionKeyCanMove: true })
    }
    setModalOpen({ positionSeatChange: false, createPerson: false })
    setSelectedPerson(null)
  }

  const handleCreatePerson = (event?: KeyboardEvent) => {
    maybeDefer(event)(() => {
      setActiveIndex(null)
      setShowResultList(false)
      refs.domReference.current?.blur()
      afterModalClose.current = () => {
        cursorConnection.stopWriting(getCursorOptions(event))
      }
      setModalOpen({ ...modalOpen, createPerson: true })
    })
  }

  handleCreatePerson.onClick = () => handleCreatePerson()

  const handleMouseDown = useStandardCellInputMouseDownHandler(refs.domReference)

  const handlePositionSeatChange = useCallback(
    async ({
      event,
      personId,
      rowId,
      intent,
    }: {
      event?: KeyboardEvent
      personId: string
      rowId: string
      intent: PositionSeatChangeIntent
    }) => {
      const response = await changePositionSeat({
        personId,
        rowId,
        intent,
      })

      if (!response?.ok) return
      cursorConnection.stopWriting(getCursorOptions(event))
      fieldSaved({ id: rowId, fieldKey: "name" })
    },
    [changePositionSeat, cursorConnection, fieldSaved],
  )

  const handlePersonSelect = async (person: Person, event?: KeyboardEvent) => {
    setInputValue(person.name)
    setActiveIndex(null)
    setShowResultList(false)

    if (person.primaryPosition) {
      maybeDefer(event)(() => {
        refs.domReference.current?.blur?.()
        setSelectedPerson(person)
        afterModalClose.current = () => {
          cursorConnection.stopWriting(getCursorOptions(event))
        }
        setModalOpen({ ...modalOpen, positionSeatChange: true })
      })
    } else {
      // If the person doesn't already have a position, we
      // can make this position their primary.
      if (!person.uniqueKey) throw new Error("No person unique key found")

      await handlePositionSeatChange({
        event,
        personId: person.uniqueKey,
        rowId: row.id,
        intent: PositionSeatChangeIntent.Primary,
      })
    }
  }

  return (
    <>
      <input
        style={prepareStyle(style, isFirst, row.color, noBorder)}
        className={nodeClassName(isLast)}
        aria-autocomplete="list"
        value={inputValue}
        ref={refs.setReference}
        /* eslint-disable react/jsx-props-no-spreading */
        {...getReferenceProps({
          onKeyDown: handleEnterAsSubmit,
          onKeyUp: handleKeyUp,
          onChange: handleChange,
          onMouseDown: handleMouseDown,
        })}
      />
      {!anyModalsOpen && (
        <DropdownMenu
          showList={showResultList && !!inputValue.trim().length}
          floatingRef={refs.setFloating}
          floatingStyles={floatingStyles}
          floatingProps={getFloatingProps}
          wrapperClasses="AutocompleteField"
          context={context}
        >
          <>
            {peopleResult &&
              peopleResult.length > 0 &&
              peopleResult.map((person, index) => (
                <div
                  role="option"
                  data-id={person.id}
                  data-name={person.name}
                  aria-selected={activeIndex === index}
                  key={person.id}
                  className={personClassName(activeIndex, index)}
                  ref={(node) => {
                    listRef.current[index] = node
                  }}
                  /* eslint-disable react/jsx-props-no-spreading */
                  {...getItemProps({
                    onClick: () => handlePersonSelect(person),
                  })}
                >
                  <div className="AutocompleteField__result-thumb">
                    <img alt={person.name || ""} src={person.avatarThumbUrl || ""} />
                  </div>
                  <div className="AutocompleteField__result-text">
                    <div className="AutocompleteField__result-title">{person.name}</div>
                    <div>{person.primaryPosition?.title || " "}</div>
                  </div>
                </div>
              ))}
            {peopleResult.length === 0 && inputValue.length > 0 && (
              <div className="AutocompleteField__no-result">
                <p className="AutocompleteField__no-result-text">
                  {"add person hook".t("org_chart")}
                </p>
                <button
                  type="button"
                  className="btn--sm btn--primary"
                  onClick={handleCreatePerson.onClick}
                >
                  <FontAwesomeIcon icon={["far", "user-plus"]} />
                  {"add new person".t("org_chart")}
                </button>
              </div>
            )}
          </>
        </DropdownMenu>
      )}
      {modalOpen.createPerson && (
        <CreatePersonModal
          modalOpen={modalOpen.createPerson}
          handleModalClose={handleModalClose}
          rowId={row.id}
          personName={inputValue}
        />
      )}
      {modalOpen.positionSeatChange && selectedPerson && (
        <PositionSeatChangeModal
          modalOpen={modalOpen.positionSeatChange}
          handleModalClose={handleModalClose}
          rowId={row.id}
          person={selectedPerson}
        />
      )}
    </>
  )
}

export { PersonComplete }

const getCursorOptions = (event?: KeyboardEvent) =>
  event ? { moveAfterByEvent: event.nativeEvent } : { transitionKeyCanMove: true }
const nodeClassName = (isLast: boolean) =>
  classNames("GridBody-cell bg-transparent", { last: isLast })
const personClassName = (activeIndex: number | null, index: number) =>
  classNames("AutocompleteField__result", { highlight: activeIndex === index })

const prepareStyle = defaultMemoize((style, isFirst, color?: Maybe<string>, noBorder?: boolean) => {
  const base = color && isFirst ? { ...(style || {}), borderLeft: `5px solid ${color}` } : style
  if (noBorder) {
    return {
      ...base,
      borderRightWidth: 0,
      borderTopWidth: 0,
      zIndex: 21,
    }
  }
  return { ...base, zIndex: 21 }
})

const cleanString = (value: Maybe<string> | undefined) => value || ""
