import OperationStore from "v2/operation_store"
import { AppListenerEffectAPI } from "v2/redux/listenerMiddleware"
import { createChannelSubscription } from "v2/redux/listeners/cableListeners"
import { FieldSuggestionEntry } from "v2/redux/slices/FieldSuggestionSlice"
import { NotificationTopic } from "types/graphql.enums"

type ActiveSubscription = ReturnType<typeof createChannelSubscription>

export interface SubscriberIndexInterface {
  addSubscriber: (listener: AppListenerEffectAPI, entry: FieldSuggestionEntry) => void
  cleanUp: () => void
  removeSubscriber: (entry: FieldSuggestionEntry) => void
}

export class SubscriberIndex implements SubscriberIndexInterface {
  /**
   * An internal index that maintains open channel subscriptions based on
   * added/removed subscribers. We only need one open channel per record (it can
   * be shared for different fields within that record).
   *
   * We maintain a counter for each unique entityId and only release a channel
   * subscription when that counter hits zero.
   */
  private channelSubscriptions: { [key in string]: ActiveSubscription }

  /**
   * Tracks the count of subscribers for a given entityId.
   *
   * @see channelSubscriptions
   */
  private entityIdCounter: { [key in string]: number }

  constructor() {
    this.channelSubscriptions = {}
    this.entityIdCounter = {}
  }

  /** @public */
  public addSubscriber(listener: AppListenerEffectAPI, { entityId }: FieldSuggestionEntry) {
    this.updateCount(entityId, (count) => count + 1)
    // Establish subscription if necessary, and ensure we dispatch updates from
    // the server into Redux.
    this.channelSubscriptions[entityId] ||= createChannelSubscription({
      args: {
        operationId: OperationStore.getOperationId("NotificationPublishedSubscription"),
        variables: { subjectId: entityId, topic: NotificationTopic.EntityEvents },
      },
      dispatch: listener.dispatch,
      received({ result: { data } }) {
        Object.entries(data ?? {}).forEach(([, action]) => listener.dispatch(action))
      },
      subjectId: entityId,
    })
  }

  public removeSubscriber({ entityId }: FieldSuggestionEntry) {
    this.updateCount(entityId, (count) => count - 1)
    if (this.getCount(entityId) > 0) return

    this.channelSubscriptions[entityId]?.unsubscribe()
    delete this.entityIdCounter[entityId]
    delete this.channelSubscriptions[entityId]
  }

  public cleanUp() {
    Object.values(this.channelSubscriptions).forEach(({ unsubscribe }) => unsubscribe())
    this.channelSubscriptions = {}
    this.entityIdCounter = {}
  }

  private getCount(entityId: string) {
    return this.entityIdCounter[entityId] ?? 0
  }

  private updateCount(entityId: string, updater: (count: number) => number) {
    this.entityIdCounter[entityId] = Math.max(0, updater(this.getCount(entityId)))
  }
}
