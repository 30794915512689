/* eslint-disable no-nested-ternary */
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FloatingPortal, useFloating } from "@floating-ui/react"
import { useOnClickOutside } from "usehooks-ts"
import { FinalizePlanButton, FinalizePlanModal } from "./Overview/FinalizePlanButton"

type Props = {
  displayState?: "original" | "approved"
  headcountPlanId: string
  isFinalized: boolean
  setDisplayState?: (value: "original" | "approved") => void
  setRef?: (element: HTMLButtonElement) => void
  showExportButton?: boolean
  showFinalizeButton?: boolean
}
export function PlanTopbarMenu({
  displayState,
  setDisplayState,
  isFinalized,
  headcountPlanId,
  setRef,
  showExportButton,
  showFinalizeButton,
}: Props) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [finalizePlanModalIsOpen, setFinalizePlanModalIsOpen] = React.useState(false)
  const { refs, floatingStyles } = useFloating({ placement: "bottom-end" })

  const showOriginalView = () => {
    setDisplayState?.("original")
    setIsOpen(false)
  }

  const showApprovedView = () => {
    setDisplayState?.("approved")
    setIsOpen(false)
  }

  useOnClickOutside(refs.floating, (event) => {
    if (refs.domReference?.current?.contains(event.target as Node)) return
    setIsOpen(false)
  })

  const showManagePlanLinks = !isFinalized
  const showViewApprovedPlan = isFinalized && displayState === "original"
  const showViewOriginalProposal = isFinalized && displayState === "approved"

  const showMenu =
    showManagePlanLinks ||
    showViewApprovedPlan ||
    showViewOriginalProposal ||
    showExportButton ||
    showFinalizeButton

  if (!showMenu) return null

  return (
    <div className="dropdown">
      <button
        ref={refs.setReference}
        type="button"
        id="plan-topbar-menu-button"
        className="btn--large btn--secondary dropdown-link w-11"
        onClick={() => setIsOpen(!isOpen)}
      >
        <FontAwesomeIcon icon={["far", "ellipsis-h"]} style={{ width: "1rem", height: "1rem" }} />
      </button>
      {isOpen ? (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className="dropdown-menu open z-20 mt-1"
          >
            {showManagePlanLinks ? <ManagePlanLinks headcountPlanId={headcountPlanId} /> : null}
            {showViewApprovedPlan ? (
              <button
                className="dropdown-menu-link gap-2 flex"
                type="button"
                onClick={() => showApprovedView()}
              >
                <div className="justify-self-center">
                  <FontAwesomeIcon
                    icon={["far", "check-circle"]}
                    style={{ height: "0.75rem", width: "0.75rem" }}
                  />
                </div>
                <span>{"view_approved_plan".t("headcount_plan")}</span>
              </button>
            ) : null}
            {showViewOriginalProposal ? (
              <button
                className="dropdown-menu-link gap-2 flex"
                type="button"
                onClick={() => showOriginalView()}
              >
                <div className="justify-self-center">
                  <FontAwesomeIcon
                    icon={["far", "undo"]}
                    style={{ height: "0.75rem", width: "0.75rem" }}
                  />
                </div>
                {"view_original_proposal".t("headcount_plan")}
              </button>
            ) : null}
            {showExportButton ? (
              <button className="dropdown-menu-link gap-2 flex" type="button" ref={setRef}>
                <div className="justify-self-center">
                  <FontAwesomeIcon
                    icon={["fal", "download"]}
                    style={{ height: "0.75rem", width: "0.75rem" }}
                  />
                </div>
                {"export".t("defaults")}
              </button>
            ) : null}
            {showFinalizeButton ? (
              <FinalizePlanButton
                display="menuItem"
                canBeFinalized
                setIsOpen={setFinalizePlanModalIsOpen}
                onClick={() => setIsOpen(false)}
              />
            ) : null}
          </div>
        </FloatingPortal>
      ) : null}
      <FinalizePlanModal
        headcountPlanId={headcountPlanId}
        headcountPlanName=""
        isOpen={finalizePlanModalIsOpen}
        setIsOpen={setFinalizePlanModalIsOpen}
      />
    </div>
  )
}

function ManagePlanLinks({ headcountPlanId }: { headcountPlanId: string }) {
  return (
    <>
      <a
        href={`/headcount_plans/${headcountPlanId}/setup`}
        className="dropdown-menu-link gap-2 flex"
      >
        <div className="justify-self-center">
          <FontAwesomeIcon icon={["far", "cog"]} style={{ height: "0.75rem", width: "0.75rem" }} />
        </div>
        <span>{"plan_settings".t("headcount_plan")}</span>
      </a>
      <a
        href={`/headcount_plans/${headcountPlanId}/invitations`}
        className="dropdown-menu-link gap-2 flex"
      >
        <div className="justify-self-center">
          <FontAwesomeIcon
            icon={["far", "users"]}
            style={{ height: "0.75rem", width: "0.75rem" }}
          />
        </div>
        <span>{"manage_participants_button".t("headcount_plan")}</span>
      </a>
    </>
  )
}
