import React from "react"
import { ColumnDef, CellContext } from "@tanstack/react-table"

import { NodeInterface, Person } from "types/graphql"
import { Widget } from "v2/react/components/dashboard/types"

import { PositionLink, PersonLink } from "v2/react/shared/Links"

import { config as widgetConfig } from "v2/react/components/dashboard/config"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"
import { orderBy } from "lodash"

const getColumnsFromKeys = (keys: (keyof NodeInterface)[]): ColumnDef<NodeInterface>[] =>
  keys.map((key) => ({
    accessorKey: key,
    header: `field_${key}`.t("org_chart"),
    sortDescFirst: false,
    cell: (props) => handleCellRendering(props, key),
  }))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleCellRendering = (props: CellContext<NodeInterface, any>, key: keyof NodeInterface) => {
  const value: string = props.getValue()
  const row = props.row.original

  if (key === "reports_to") {
    if (row.reports_to_position?.people?.length) {
      return renderPersonLinks(row.reports_to_position.people)
    }
    if (row.reports_to_position?.id) {
      return <PositionLink id={row.reports_to_position.id} value={value} />
    }
  }

  if (key === "system_identifier" && row.position_id) {
    return <PositionLink id={idFromUniqueKey(row.position_id)} value={value} />
  }

  return value
}

const renderPersonLinks = (people: Person[]) => {
  const sortedPeople = orderBy(people, ["name"], ["asc"])

  return sortedPeople.map(({ id, name }, index) => (
    <span key={id}>
      <PersonLink id={id} value={name} />
      {index < sortedPeople.length - 1 && ", "}
    </span>
  ))
}

const getColumnsForDrillDown = (name: Widget) => getColumnsFromKeys(widgetConfig[name].columns)

export { getColumnsForDrillDown }
