import React, { useState } from "react"

import { AtsOptions } from "types/graphql"
import { InitFailure } from "v2/react/components/jobRequisitions/SendToAts/utils"
import { JobRequisitionAbilities } from "v2/react/components/jobRequisitions/types"
import { Pulser } from "v2/react/shared/Pulser"
import { StatusBadge } from "v2/react/shared/Status/StatusBadge"
import {
  GreenhouseForm,
  initGreenhouseForm,
} from "v2/react/components/jobRequisitions/SendToAts/GreenhouseForm"
import { useGetAtsOptionsQuery } from "v2/redux/GraphqlApi/JobRequisitionsApi"
import { LeverForm, initLeverForm } from "v2/react/components/jobRequisitions/SendToAts/LeverForm"

const showStates = ["approved", "closed"]

interface Props {
  abilities: JobRequisitionAbilities
  approvalState: string
  atsIdentifier: string
  clientIdentifier: string
  externalStatus: string
  jobRequisitionId: string
  systemUid: string
  title: string
}

function IntegrationInfo({
  abilities,
  approvalState,
  atsIdentifier,
  clientIdentifier,
  externalStatus,
  jobRequisitionId,
  systemUid,
  title,
}: Props) {
  const [isOpen, setIsOpen] = useState(false)

  if (atsIdentifier === "") return null
  if (!showStates.includes(approvalState)) return null

  const handleShowModalButtonClick = () => {
    setIsOpen(true)
  }

  const handleCancelModal = () => {
    setIsOpen(false)
  }

  const idSuffix = "ID"

  return (
    <div className="module-card mb-0">
      <div className="module-card__header">
        <p className="mb-0 font-bold">
          {integrationLabel(atsIdentifier, "requisition".t("job_requisition"))}
        </p>
      </div>
      <div className="module-card__body gap-0 !p-0">
        <div className="module-card-list-item h-14 p-4">
          <div className="justify-between gap-2 flex">
            <img
              alt="logoThumbUrl"
              src={window.App.Helpers.imagePath(
                `/integrations/${integrationImageFile(atsIdentifier)}`,
              )}
            />
            <div className="items-center flex">{integrationLabel(atsIdentifier, idSuffix)}</div>
          </div>

          <div className="items-center flex">
            {clientIdentifier === "" ? "-" : clientIdentifier}
          </div>
        </div>

        <div className="module-card-list-item h-14 border-t-neutral-8 p-4">
          <div>{"status".t("ats")}</div>
          <div>
            {externalStatus === "" ? (
              "-"
            ) : (
              <StatusBadge badgeClasses="badge--sm" displayType="button" status={externalStatus} />
            )}
          </div>
        </div>
      </div>
      {abilities.canManage && showButton(approvalState, atsIdentifier, clientIdentifier) && (
        <div className="module-card-list-item h-14 border-t-neutral-8 p-4">
          <SendToAts
            closeModal={handleCancelModal}
            handleShowModalButtonClick={handleShowModalButtonClick}
            isOpen={isOpen}
            approvalState={approvalState}
            jobRequisitionId={jobRequisitionId}
            systemUid={systemUid}
            title={title}
          />
        </div>
      )}
    </div>
  )
}

// Placeholder logic falls back to greenhouse, pending logic to be merged
const integrationImageFile = (atsIdentifier: string): string => {
  if (atsIdentifier === "greenhouse") return "icon-greenhouse.png"
  if (atsIdentifier === "lever") return "icon-lever.png"
  if (["adp_base", "adp_rm"].includes(atsIdentifier || "")) return "icon-adp.png"
  return ""
}

const integrationLabel = (atsIdentifier: string, suffix: string): string => {
  if (atsIdentifier === "greenhouse") return `Greenhouse ${suffix}`
  if (atsIdentifier === "lever") return `Lever ${suffix}`
  if (["adp_base", "adp_rm"].includes(atsIdentifier || "")) return `ADP ${suffix}`
  return suffix
}

const showButton = (approvalState: string, atsIdentifier: string, clientIdentifier: string) => {
  // Future work to support ADP and Lever
  if (!showStates.includes(approvalState)) return false

  const supportedConnections = ["greenhouse", "lever"]
  if (!supportedConnections.includes(atsIdentifier)) return false

  const isConnectedToAts = clientIdentifier !== ""
  if (isConnectedToAts) return false

  return true
}

interface SendToAtsProps {
  closeModal: () => void
  handleShowModalButtonClick: () => void
  isOpen: boolean
  approvalState: string
  jobRequisitionId: string
  systemUid: string
  title: string
}

const SendToAts = ({
  closeModal,
  handleShowModalButtonClick,
  isOpen,
  approvalState,
  jobRequisitionId,
  systemUid,
  title,
}: SendToAtsProps) => {
  const { data, isLoading } = useGetAtsOptionsQuery()

  if (isLoading)
    return (
      <div>
        <Pulser />
      </div>
    )

  if (!data?.atsOptions) {
    return (
      <div>
        <p>ATS Integration Error</p>
      </div>
    )
  }

  const initData = initAtsForm(title, [], "", data.atsOptions)

  if (!initData.success) {
    return (
      <div>
        <p>ATS Integration Error</p>
        <p>{initData.message}</p>
      </div>
    )
  }

  return (
    <>
      <div className="ml-auto">
        <button
          type="button"
          className="btn btn--primary"
          onClick={handleShowModalButtonClick}
          disabled={approvalState === "closed"}
        >
          {"send_to_ats".t("ats")}
        </button>
      </div>
      {initData.integration === "greenhouse" && (
        <GreenhouseForm
          closeModal={closeModal}
          formSchema={initData.schema}
          initialFormData={initData.data}
          isOpen={isOpen}
          jobRequisitionId={jobRequisitionId}
          options={initData.options}
          positionId={null}
          systemUid={systemUid}
        />
      )}
      {initData.integration === "lever" && (
        <LeverForm
          closeModal={closeModal}
          formSchema={initData.schema}
          initialFormData={initData.data}
          isOpen={isOpen}
          jobRequisitionId={jobRequisitionId}
          positionId={null}
          systemUid={systemUid}
        />
      )}
    </>
  )
}

function initAtsForm(
  title: string,
  departments: string[],
  location: string,
  atsOptions: AtsOptions,
) {
  if (atsOptions.integration === "greenhouse") {
    return initGreenhouseForm(title, departments, location, atsOptions)
  }
  if (atsOptions.integration === "lever") {
    return initLeverForm(title, departments, location)
  }
  return {
    success: false,
    message: "unable_to_get_ats_data".t("ats"),
  } as InitFailure
}

export { IntegrationInfo }
