import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import fp from "lodash/fp"
import { basePalettes } from "v2/react/components/successionSettings/palettes"
import { z } from "zod"

const GridItemSchema = z
  .object({
    id: z.number(),
    label: z.string().nonempty(),
    color: z.string().nonempty(),
  })
  .required()

const CardTypeSchema = z.union([
  z.literal("color_full"),
  z.literal("color_edge"),
  z.literal("color_none"),
])

const PaletteSchema = z.union([
  z.literal("palette_traditional"),
  z.literal("palette_blue"),
  z.literal("palette_red"),
  z.literal("palette_custom"),
])

const FullMatrixSchema = z.object({
  cardType: CardTypeSchema,
  paletteType: PaletteSchema,
  gridItems: z.array(GridItemSchema).nonempty(),
})

type GridItem = z.infer<typeof GridItemSchema>
type CardType = z.infer<typeof CardTypeSchema>
type PaletteType = z.infer<typeof PaletteSchema>

interface PaletteOption {
  palette: string[]
  text: PaletteType
}

interface MatrixState {
  basePaletteOptions: PaletteType[]
  viewablePaletteOptions: PaletteType[]
  cardOptions: CardType[]
  gridItems: GridItem[]
  cardType: CardType
  paletteType: PaletteType
  labelErrors: boolean
}

const checkForPaletteMatch = (items: GridItem[], palettes: PaletteType[]) => {
  let matched: PaletteType | undefined

  palettes.forEach((option) => {
    if (basePalettes[option].every((color, i) => items[i].color === color)) matched = option
  })

  return matched
}

const updateItemColorByPalette = (item: GridItem, index: number, palette: PaletteType) => ({
  ...item,
  color: basePalettes[palette][index],
})

const initialState: MatrixState = {
  basePaletteOptions: ["palette_traditional", "palette_blue", "palette_red"],
  viewablePaletteOptions: ["palette_traditional", "palette_blue", "palette_red"],
  cardOptions: ["color_full", "color_edge", "color_none"],
  gridItems: [],
  cardType: "color_full",
  paletteType: "palette_traditional",
  labelErrors: false,
}

const MatrixSlice = createSlice({
  name: "matrix",
  initialState,
  reducers: {
    setCardType: (state, { payload }: PayloadAction<CardType>) =>
      fp.set("cardType", payload)(state),
    setGridItems: (state, { payload }: PayloadAction<GridItem[]>) =>
      fp.set("gridItems", payload)(state),
    setLabelErrors: (state, { payload }: PayloadAction<boolean>) =>
      fp.set("labelErrors", payload)(state),
    setPalette: (state, { payload }: PayloadAction<PaletteType>) =>
      fp.set("paletteType", payload)(state),

    updateGridItemColor: (
      state,
      { payload: { gridItem, newColor } }: PayloadAction<{ gridItem: GridItem; newColor: string }>,
    ) => {
      const updatedItems = state.gridItems.map((item) =>
        item.id === gridItem.id ? { ...item, color: newColor } : item,
      )
      const matched = checkForPaletteMatch(updatedItems, state.basePaletteOptions)
      const updatedPalette = matched ? matched : "palette_custom"

      return fp.pipe(
        fp.set("gridItems", updatedItems),
        fp.set("paletteType", updatedPalette),
      )(state)
    },
    updateGridItemLabel: (
      state,
      { payload: { gridItem, newLabel } }: PayloadAction<{ gridItem: GridItem; newLabel: string }>,
    ) => {
      const updatedItems = state.gridItems.map((item) =>
        item.id === gridItem.id ? { ...item, label: newLabel } : item,
      )

      return fp.set("gridItems", updatedItems)(state)
    },
    updatePalette: (state, { payload }: PayloadAction<PaletteType>) => {
      const updatedItems =
        payload !== "palette_custom"
          ? state.gridItems.map((item, index) => updateItemColorByPalette(item, index, payload))
          : state.gridItems
      const updatedPalettes =
        payload !== "palette_custom"
          ? state.basePaletteOptions
          : [...state.basePaletteOptions, "palette_custom"]

      return fp.pipe(
        fp.set("gridItems", updatedItems),
        fp.set("viewablePaletteOptions", updatedPalettes),
        fp.set("paletteType", payload),
      )(state)
    },
  },
})

export const {
  setGridItems,
  setCardType,
  setLabelErrors,
  setPalette,
  updatePalette,
  updateGridItemColor,
  updateGridItemLabel,
} = MatrixSlice.actions
export {
  MatrixSlice,
  MatrixState,
  GridItem,
  PaletteType,
  CardType,
  PaletteOption,
  FullMatrixSchema,
  GridItemSchema,
  CardTypeSchema,
  PaletteSchema,
}
