import React from "react"
import {
  TitleBlockLarge,
  TitleHeaderWithParent,
} from "v2/react/components/navigation/PageNav/TitleBlock"
import PageNav from "v2/react/components/navigation/PageNav"

interface Props {
  parentTitle: string | null
  parentTitleUrl?: string
  title: string
}

function PositionTypePageNav({ parentTitle, parentTitleUrl, title }: Props) {
  return (
    <PageNav>
      <TitleBlockLarge>
        <TitleHeaderWithParent
          parentTitle={parentTitle}
          parentTitleUrl={parentTitleUrl}
          title={title}
        />
      </TitleBlockLarge>
    </PageNav>
  )
}

export { PositionTypePageNav }
