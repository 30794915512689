import React from "react"
import cn from "classnames"
import { NotificationRecipient } from "types/graphql"
import { RecipientList } from "v2/react/shared/Status/RecipientList/RecipientList"

interface NotificationRecipientProps {
  approvalState: string
  onShowUpdateModal: React.MouseEventHandler<HTMLButtonElement>
  recipients: NotificationRecipient[]
}

function NotificationRecipients({
  approvalState,
  onShowUpdateModal,
  recipients,
}: NotificationRecipientProps) {
  const noEditStates = ["approved", "canceled", "closed"]
  return (
    <div className="module-card mb-0">
      <div className="module-card__header">
        <p className="mb-0 text-base-bold">{"notification_recipients".t("job_requisition")}</p>
        {!window.gon.limited_or_nonadmin_manager && !noEditStates.includes(approvalState) && (
          <button className="btn--sm btn--secondary" onClick={onShowUpdateModal} type="button">
            {"Edit".t("defaults")}
          </button>
        )}
      </div>
      <div
        className={cn("module-card__body gap-0", {
          "border-none !p-0": recipients.length,
        })}
      >
        {!recipients.length && <p>{"notification_recipients_empty".t("job_requisition")}</p>}
        {recipients.length > 0 && <RecipientList recipients={recipients} />}
      </div>
    </div>
  )
}

export { NotificationRecipients }
