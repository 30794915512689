import fp from "lodash/fp"
import { useCallback, useEffect, useState } from "react"

import { cancelGeneratingFieldSuggestion } from "v2/redux/slices/FieldSuggestionSlice"
import { normalizeErrors } from "v2/react/utils/errors"
import {
  PerformSuggestionActionState,
  useFieldSuggestionsActions,
} from "v2/react/hooks/useFieldSuggestionsActions"
import { PositionType, PositionTypesUpdateInput } from "types/graphql"
import { useAppDispatch } from "v2/redux/store"
import { useUpdatePositionTypeMutation } from "v2/redux/GraphqlApi/PositionTypesApi"

type ResetFn = () => void
type UpdatePositionTypeMutation = ReturnType<typeof useUpdatePositionTypeMutation>
type UpdatePositionTypeFn = UpdatePositionTypeMutation[0]
type UpdatePositionTypeState = UpdatePositionTypeMutation[1]

export interface EditPositionType {
  // Primary actions
  addError: (error: string) => void
  cancelGeneratingFieldSuggestions: (fieldOrFields: string | string[]) => void
  clearAllErrors: () => void
  acceptFieldSuggestions: (fieldOrFields: string | string[]) => Promise<boolean>
  declineFieldSuggestions: (fieldOrFields: string | string[]) => Promise<boolean>
  generateFieldSuggestions: (fieldOrFields: string | string[]) => Promise<boolean>
  initializeFieldSuggestions: (fieldOrFields: string | string[]) => Promise<boolean>
  removeError: (error: string) => void
  reset: ResetFn
  updatePositionType: (input: Omit<PositionTypesUpdateInput, "id">) => Promise<boolean>

  // Primary state
  errors: string[]
  isUpdatingPositionType: boolean
  isPerformingFieldSuggestionActions: boolean
  isLoading: boolean
  updatePositionTypeState: UpdatePositionTypeState
  suggestionActionsState: PerformSuggestionActionState
}

/**
 * Actions and state for editing a position type.
 *
 * Besides `reset`, all primary actions are async and resolve to a boolean that
 * indicates whether the action was successful. The actions do not throw and
 * handle any exceptions that might be thrown. `reset` clears out errors and
 * resets the state of backing mutations. It can be handy for handling certain
 * UI events, e.g. a modal closed.
 *
 * @note `generateFieldSuggestions` might be best thought of as a background
 *   task. The mutation can, and likely will, finish before the suggestion has
 *   been generated. Whether a field is busy/loading should consider the
 *   `isBusy` flag provided by our `use<FieldType>WithFieldSuggestion` hooks.
 */
export function useEditPositionType(positionType: PositionType): EditPositionType {
  const entityId = positionType.uniqueKey ?? `position_type_${positionType.id}`

  const {
    acceptFieldSuggestions: doAcceptFieldSuggestions,
    declineFieldSuggestions: doDeclineFieldSuggestions,
    generateFieldSuggestions: doGenerateFieldSuggestions,
    initializeFieldSuggestions: doInitializeFieldSuggestions,
    state: suggestionActionsState,
  } = useFieldSuggestionsActions(entityId)
  const [doUpdate, updatePositionTypeState] = useUpdatePositionTypeMutation()
  const { addError, clearAllErrors, errors, removeError } = useEditPositionTypeErrors(
    updatePositionTypeState,
    suggestionActionsState,
  )

  const reset = useResetAction(clearAllErrors, suggestionActionsState, updatePositionTypeState)

  const updatePositionType = useUpdatePositionTypeAction(entityId, clearAllErrors, doUpdate)

  const dispatch = useAppDispatch()
  const cancelGeneratingFieldSuggestions = useCallback(
    (fieldOrFields: string | string[]) => {
      const fields = Array.isArray(fieldOrFields) ? fieldOrFields : [fieldOrFields]
      fields.forEach((field) => {
        dispatch(cancelGeneratingFieldSuggestion({ field, entityId }))
      })
    },
    [dispatch, entityId],
  )

  const isUpdatingPositionType = updatePositionTypeState.isLoading
  const isPerformingFieldSuggestionActions = suggestionActionsState.isLoading

  const acceptFieldSuggestions = useCallback(
    (fieldOrFields: string | string[]) => {
      clearAllErrors()
      return doAcceptFieldSuggestions(fieldOrFields)
    },
    [clearAllErrors, doAcceptFieldSuggestions],
  )
  const declineFieldSuggestions = useCallback(
    (fieldOrFields: string | string[]) => {
      clearAllErrors()
      return doDeclineFieldSuggestions(fieldOrFields)
    },
    [clearAllErrors, doDeclineFieldSuggestions],
  )
  const generateFieldSuggestions = useCallback(
    (fieldOrFields: string | string[]) => {
      clearAllErrors()
      return doGenerateFieldSuggestions(fieldOrFields)
    },
    [clearAllErrors, doGenerateFieldSuggestions],
  )
  const initializeFieldSuggestions = useCallback(
    (fieldOrFields: string | string[]) => {
      clearAllErrors()
      return doInitializeFieldSuggestions(fieldOrFields)
    },
    [clearAllErrors, doInitializeFieldSuggestions],
  )

  return {
    acceptFieldSuggestions,
    declineFieldSuggestions,
    generateFieldSuggestions,
    initializeFieldSuggestions,

    addError,
    cancelGeneratingFieldSuggestions,
    clearAllErrors,
    removeError,
    reset,
    updatePositionType,

    errors,
    isUpdatingPositionType,
    isPerformingFieldSuggestionActions,
    isLoading: isUpdatingPositionType || isPerformingFieldSuggestionActions,
    suggestionActionsState,
    updatePositionTypeState,
  }
}

/**
 * Builds the backing errors collection and synchronizes the collection to
 * mutation errors.
 *
 * @private
 */
const useEditPositionTypeErrors = (
  updatePositionTypeState: UpdatePositionTypeState,
  suggestionActionsState: PerformSuggestionActionState,
) => {
  const [errors, setErrors] = useState<string[]>([])
  const suggestionErrors = suggestionActionsState.error
  const suggestionFieldErrors =
    suggestionActionsState.data?.performFieldSuggestionActionsForPositionType?.errors
  const updateErrors = updatePositionTypeState.error
  const updateFieldErrors = updatePositionTypeState.data?.positionTypesUpdate?.errors

  const addError = useCallback(
    (error: string) =>
      setErrors((errorsInState) =>
        errorsInState.includes(error) ? errorsInState : [...errorsInState, error],
      ),
    [setErrors],
  )
  const removeError = useCallback(
    (error: string) => setErrors((errorsInState) => fp.without([error], errorsInState)),
    [setErrors],
  )
  const clearAllErrors = useCallback(() => setErrors([]), [setErrors])

  useEffect(() => {
    const mutationErrors = [
      suggestionErrors,
      suggestionFieldErrors,
      updateErrors,
      updateFieldErrors,
    ]
      .flatMap(normalizeErrors)
      .map(({ error }) => error)
    setErrors((errorsInState) => errorsInState.concat(mutationErrors))

    return () => {
      setErrors((errorsInState) => fp.without(mutationErrors, errorsInState))
    }
  }, [setErrors, suggestionErrors, suggestionFieldErrors, updateErrors, updateFieldErrors])

  return { addError, clearAllErrors, errors, removeError, setErrors }
}

/**
 * Builds an action that clears all errors and resets all mutation state.
 *
 * @private
 */
const useResetAction = (
  clearAllErrors: () => void,
  { reset: resetSuggestionActions }: PerformSuggestionActionState,
  { reset: resetUpdatePositionType }: UpdatePositionTypeState,
) =>
  useCallback(() => {
    clearAllErrors()
    resetSuggestionActions()
    resetUpdatePositionType()
  }, [clearAllErrors, resetUpdatePositionType, resetSuggestionActions])

/**
 * Builds an action that updates the position type using the action's input.
 * The action sets the position type id so an action caller does not need to
 * provide it.
 *
 * @private
 */
const useUpdatePositionTypeAction = (
  positionTypeId: string,
  clearAllErrors: () => void,
  doUpdate: UpdatePositionTypeFn,
) =>
  useCallback(
    async (input: Omit<PositionTypesUpdateInput, "id">) => {
      clearAllErrors()
      try {
        const inputWithId = { ...input, id: positionTypeId }
        const result = await doUpdate(inputWithId).unwrap()
        const errors = result.positionTypesUpdate?.errors
        return !errors || errors.length === 0
      } catch (_error) {
        // Don't need any handling for the error since RTK Query puts it in the
        // backing hook's errors. It is only thrown since we unwrap the
        // mutation. Return false to indicate failure.
        return false
      }
    },
    [doUpdate, positionTypeId, clearAllErrors],
  )
