import React from "react"
import { Chart, RequestChartApprovalInput } from "types/graphql"
import CancelApprovalButton from "v2/react/components/orgChart/ChartApproval/ApprovalDropdown/CancelApprovalButton"
import PendingDisplay from "v2/react/components/orgChart/ChartApproval/ApprovalDropdown/PendingDisplay"
import { SubmittedDisplay } from "v2/react/components/orgChart/ChartApproval/ApprovalDropdown/SubmittedDisplay"
import { SubmittedFooter } from "v2/react/components/orgChart/ChartApproval/ApprovalDropdown/SubmittedFooter"
import {
  configureApproval,
  requestApproval,
  submittedApproval,
} from "v2/react/components/orgChart/ChartApproval/approvalUtils"
import { useRequestChartApprovalMutation } from "v2/redux/GraphqlApi/ChartApprovalsApi"
import { toggleConfigureModal, toggleDropdown } from "v2/redux/slices/ApprovalSlice"
import { selectCurrentUser } from "v2/redux/slices/ApprovalSlice/approvalSelectors"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface Props {
  chart: Chart
}

function ApprovalBadgeSm({ chart }: Props) {
  const currentUser = useAppSelector(selectCurrentUser)
  const dispatch = useAppDispatch()
  const [requestChartApproval] = useRequestChartApprovalMutation()
  const closeDropdownModal = () => dispatch(toggleDropdown(false))
  const openConfigureModal = () => dispatch(toggleConfigureModal(true))

  const submitRequest = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const formData = new FormData(form)

    const input: RequestChartApprovalInput = {
      chartId: chart.id.toString(),
      note: formData.get("note"),
    } as RequestChartApprovalInput

    await requestChartApproval({ input }).unwrap()
    closeDropdownModal()
  }

  if (!currentUser) return null

  return (
    <div className="w-[300px] max-w-[90vw] min-[425px]:w-[350px] sm:w-[400px]">
      {configureApproval(currentUser, chart.chartApprovers) && (
        <button className="btn btn--secondary" onClick={openConfigureModal} type="button">
          {"configure_approvers".t("org_chart")}
        </button>
      )}
      {requestApproval(currentUser, chart.chartApprovers, chart) && (
        <form onSubmit={submitRequest}>
          <PendingDisplay
            approvers={chart.chartApprovers}
            canConfigure={currentUser.canConfigure}
            canEditConfiguration={chart?.status !== "approved"}
          />
          <button type="submit" className="btn--large btn--primary ml-auto">
            {"submit_request".t("org_chart")}
          </button>
        </form>
      )}
      {submittedApproval(chart) && (
        <>
          <SubmittedDisplay
            approvers={chart.chartApprovers}
            chart={chart}
            currentUser={currentUser}
            openConfigureModal={openConfigureModal}
          />
          <SubmittedFooter chart={chart} currentUser={currentUser} />
          <hr className="m-0 mx-[-1rem]" />
          <div className="justify-end pt-4 flex">
            <CancelApprovalButton chart={chart} />
          </div>
        </>
      )}
    </div>
  )
}

export { ApprovalBadgeSm }
