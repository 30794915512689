import React from "react"
import { Chart } from "types/graphql.d"
import { SubmittedStatusButton } from "v2/react/components/orgChart/ChartApproval/ApprovalDropdown/SubmittedStatusButton"
import { CurrentUser } from "v2/redux/slices/ApprovalSlice"
import { EditApproversButton } from "./EditApproversButton"

interface Props {
  currentUser: CurrentUser
  chart: Chart
  openConfigureModal: () => void
}

function SubmittedListHeader({ currentUser, chart, openConfigureModal }: Props) {
  return (
    <div className="mb-4 items-center justify-between flex">
      <div className="items-center gap-2 flex">
        <p className="margin-0 text-base-bold hidden sm:block">{"approvers".t("org_chart")}</p>
        <p className="margin-0 text-base-bold sm:hidden">{"approval_request".t("org_chart")}</p>
        <div className="sm:hidden">
          <SubmittedStatusButton
            approvers={chart.chartApprovers}
            chart={chart}
            displayType="badge"
          />
        </div>
      </div>
      <EditApproversButton
        canSeeButton={currentUser.canConfigure}
        canUseButton={currentUser.canConfigure && chart?.status !== "approved"}
        onClick={openConfigureModal}
      />
    </div>
  )
}

export { SubmittedListHeader }
