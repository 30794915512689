import React from "react"

interface RadioGroupProps<TValue = string> {
  groupName: string
  options: {
    id: string
    value: TValue
    label: string
  }[]
  defaultChecked?: TValue
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

const RadioGroup = <TValue extends string | number = string>({
  groupName,
  options,
  defaultChecked,
  onChange,
}: RadioGroupProps<TValue>) => (
  <div className="radio-group">
    {options.map((option) => (
      <div key={option.id} className="radio-input">
        <input
          type="radio"
          id={option.id}
          name={groupName}
          value={option.value}
          defaultChecked={defaultChecked === option.value}
          onChange={onChange}
        />
        <label htmlFor={option.id}>{option.label}</label>
      </div>
    ))}
  </div>
)

export { RadioGroup }
