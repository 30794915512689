import { BasePay, SourcePay } from "types/graphql.enums"
import { FollowUpModalProps } from "v2/react/components/orgChart/OrgChartDatasheet/FollowUpModals"
import {
  ActionType,
  BudgetedPayFieldType,
} from "v2/react/components/orgChart/OrgChartDatasheet/Modals/BudgetedPayTypeFollowUp"
import { FieldKey } from "v2/redux/slices/NodeSlice/types"
import { Changes } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/types"

import {
  hasPreviousPositionBasePay,
  hasPreviousVariablePay,
  isVariablePayAmount,
  isVariablePayType,
  basePayTypeCast,
  variablePayTypeCast,
} from "v2/react/components/orgChart/OrgChartDatasheet/Modals/helpers"
import { maybeGetIDFromUniqueKey } from "v2/react/utils/uniqueKey"
import { prepareCurrencyValue } from "v2/react/utils/currency"

const getFieldType = (fieldKey: FieldKey) => {
  if (isVariablePayType(fieldKey)) {
    return "variablePayType"
  }
  if (isVariablePayAmount(fieldKey)) {
    return "variablePayAmount"
  }
  if (fieldKey === "position_base_pay") {
    return "budgetedPayAmount"
  }
  if (fieldKey === "position_hours_per_week") {
    return "budgetedHoursPerWeek"
  }
  return "budgetedPayType"
}

const getActionType = (
  fieldType: BudgetedPayFieldType,
  fieldKey: FieldKey,
  row: FollowUpModalProps["row"],
) => {
  if (
    ["budgetedPayAmount", "budgetedPayType", "budgetedHoursPerWeek"].includes(fieldType) &&
    !hasPreviousPositionBasePay(row)
  ) {
    return "add"
  }
  if (
    ["variablePayAmount", "variablePayType"].includes(fieldType) &&
    !hasPreviousVariablePay(fieldKey, row)
  ) {
    return "add"
  }
  return "edit"
}

const getChangedFrom = (
  fieldType: BudgetedPayFieldType,
  actionType: ActionType,
  fieldKey: FieldKey,
  row: FollowUpModalProps["row"],
) => {
  if (actionType === "add") {
    // If adding, there is no 'from' type
    return null
  }

  if (fieldType === "budgetedPayType") {
    return basePayTypeCast(row.data.position_base_pay_type?.toLowerCase())
  }
  if (fieldType === "budgetedPayAmount") {
    return basePayTypeCast(row.data.position_base_pay?.toLowerCase())
  }
  if (fieldType === "variablePayType") {
    return variablePayTypeCast(
      row.data.variable_pays_original?.find((vp) => vp.field_id === fieldKey)?.pay_type,
    )
  }
  if (fieldType === "variablePayAmount") {
    return variablePayTypeCast(
      row.data.variable_pays_original?.find((vp) => vp.field_id === fieldKey)?.amount,
    )
  }
  return null
}

const getChangedTo = (
  fieldType: BudgetedPayFieldType,
  value: FollowUpModalProps["field"]["value"],
) => {
  const inputID = maybeGetIDFromUniqueKey(value)

  if (["budgetedPayAmount", "variablePayAmount", "budgetedHoursPerWeek"].includes(fieldType)) {
    return value
  }
  if (fieldType === "budgetedPayType") {
    return inputID === BasePay.Hourly ? BasePay.Hourly : BasePay.Salary
  }
  if (fieldType === "variablePayType") {
    return inputID === SourcePay.Amount ? SourcePay.Amount : SourcePay.Percent
  }
  return null
}

const getLabel = (actionType: ActionType, label: string, fieldType: BudgetedPayFieldType) => {
  if (actionType === "add") {
    // Labels for the "add" modals should be of the form "Budgeted Base Pay" / [Variable Pay Name]
    // i.e. no "Type" or "Rate"
    if (["budgetedPayType", "budgetedPayAmount", "budgetedHoursPerWeek"].includes(fieldType)) {
      return "Budgeted Base Pay".t("org_chart")
    }
    return label?.replace(/ Type$/, "")
  }
  return label
}

const getTitle = (actionType: ActionType, label: string) => {
  if (actionType === "edit") {
    return "Edit %s".t("defaults", null, null, null, [label])
  }
  return "Add %s".t("defaults", null, null, null, [label])
}

const getDescription = (
  actionType: ActionType,
  fieldType: BudgetedPayFieldType,
  label: string,
  changes: Changes,
  data: FollowUpModalProps["row"]["data"],
) => {
  const positionTitle = data.title || "Untitled Position".t("org_chart")
  const positionSystemID = data.system_identifier
  if (actionType === "edit") {
    return "edit_budgeted_pay_follow_up_description".t("org_chart", null, null, null, [
      label,
      positionTitle,
      positionSystemID,
      `${changes.from}`.t("org_chart"),
      `${changes.to}`.t("org_chart"),
    ])
  }

  let changedTo = changes.to
  if (["budgetedPayType", "variablePayType"].includes(fieldType)) {
    changedTo = `${changes.to}`.t("org_chart")
  } else if (["budgetedPayAmount", "variablePayAmount"].includes(fieldType)) {
    changedTo = prepareCurrencyValue({ value: changedTo, omitSymbol: false }) || null
  }

  const labelForDescription =
    fieldType === "budgetedHoursPerWeek" ? "Budgeted Hours Per Week".t("org_chart") : label

  return "add_budgeted_pay_follow_up_description".t("org_chart", null, null, null, [
    labelForDescription,
    positionTitle,
    positionSystemID,
    changedTo,
  ])
}

export {
  getActionType,
  getChangedFrom,
  getChangedTo,
  getFieldType,
  getLabel,
  getTitle,
  getDescription,
}
