import React, { ChangeEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { AITextArea } from "v2/react/components/positionTypes/modals/AITextArea"
import { ConfirmationModal } from "v2/react/components/positionTypes/modals/ConfirmationModal"
import { ErrorBanner } from "v2/react/components/positionTypes/modals/ErrorBanner"
import { FieldSuggestionState } from "v2/redux/slices/FieldSuggestionSlice"
import { InfoBanner } from "v2/react/components/positionTypes/modals/InfoBanner"
import { ContentType } from "v2/react/components/positionTypes/modals/types"
import { Modal, ModalFooter } from "v2/react/shared/Modal"
import { RecordWithSuggestions } from "v2/react/hooks/useTypedFieldWithSuggestion/types"
import { useStringWithFieldSuggestion } from "v2/react/hooks/useTypedFieldWithSuggestion"

type RecordWithResponsibilities = {
  tasksAndResponsibilities?: string | null
} & RecordWithSuggestions

interface Props<Record extends RecordWithResponsibilities> {
  contentType: ContentType
  errors: string[]
  isAiAvailable?: boolean
  isOpen: boolean
  isPerformingFieldSuggestionAction: boolean
  isSaving: boolean
  modalTitle: string
  onClose: () => void
  onRegenerateFieldSuggestion: (field: string) => void
  onSave: (data: string, removeLinkedData: boolean) => Promise<boolean>
  positionResponsibilities: string
  record: Record
  uniqueKey: string
}

function ResponsibilitiesModal<Record extends RecordWithResponsibilities>({
  contentType,
  errors,
  isAiAvailable = true,
  isOpen,
  isPerformingFieldSuggestionAction,
  isSaving,
  modalTitle,
  onClose,
  onRegenerateFieldSuggestion,
  onSave,
  positionResponsibilities,
  record,
  uniqueKey,
}: Props<Record>) {
  const { t } = useTranslation()
  const {
    value: actualResponsibilities,
    isAwaitingAction,
    isBusy: websocketIsBusy,
  } = useStringWithFieldSuggestion({
    record,
    field: "tasks_and_responsibilities",
    getActual: ({ tasksAndResponsibilities }) => tasksAndResponsibilities ?? null,
    getUniqueKey: () => uniqueKey,
    onStateChange: {
      handler: ({ suggested, fallback }) => setResponsibilities(suggested ?? fallback),
      whenChangesTo: [FieldSuggestionState.Initialized, FieldSuggestionState.Generated],
    },
    withEphemeralEventsApplied: true,
  })

  const isBusy = isPerformingFieldSuggestionAction || websocketIsBusy
  const [responsibilities, setResponsibilities] = useState(actualResponsibilities)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [removeLinkedResponsibilities, setRemoveLinkedResponsibilities] = useState(false)

  // `responsibilities` is controlled by the user when the modal is open, so we
  // refrain from updating it except when an action triggered by the user
  // finishes. We do want to ensure this value synchronizes with the latest
  // state when the modal is closed.
  useEffect(() => {
    if (!isOpen) setResponsibilities(actualResponsibilities)
  }, [isOpen, actualResponsibilities])

  const confirmationNeeded = () =>
    contentType === "definition" &&
    positionResponsibilities.length &&
    !isAwaitingAction &&
    !responsibilities.length &&
    !confirmationModalOpen

  const handleGenerateResponsibilities = () => {
    onRegenerateFieldSuggestion("tasks_and_responsibilities")
  }

  const handleSave = () => {
    const saveResponsibilities = async () => {
      if (await onSave(responsibilities, removeLinkedResponsibilities)) {
        handleClose()
      } else {
        setConfirmationModalOpen(false)
      }
    }

    if (confirmationNeeded()) {
      setConfirmationModalOpen(true)
    } else {
      saveResponsibilities()
    }
  }

  const handleClose = () => {
    setConfirmationModalOpen(false)
    onClose()
  }

  const handleCloseConfirmation = () => {
    setConfirmationModalOpen(false)
    handleClose()
  }

  const handleConfirmation = (e: ChangeEvent<HTMLInputElement>) => {
    setRemoveLinkedResponsibilities(e.target.value === "true")
  }

  if (confirmationModalOpen) {
    return (
      <ConfirmationModal
        handleRemoveLinkedData={handleConfirmation}
        modalTitle={modalTitle}
        onClose={handleCloseConfirmation}
        onSave={handleSave}
        properties={["responsibilities"]}
        removeLinkedData={removeLinkedResponsibilities}
      />
    )
  }

  return (
    <Modal
      footer={
        <ModalFooter
          onClose={handleClose}
          onSave={handleSave}
          saveButtonText={t("v2.defaults.save")}
          disabled={isSaving || isBusy}
        />
      }
      isOpen={isOpen}
      onClose={handleClose}
      size="md"
      testId="responsibilities-modal"
      title={modalTitle}
    >
      <div className="flex-col gap-y-6 p-6 flex">
        {isAiAvailable && <InfoBanner infoBannerType={contentType} />}
        <ErrorBanner errors={errors} />

        <AITextArea
          content={responsibilities}
          handleGenerate={handleGenerateResponsibilities}
          isAiAvailable={isAiAvailable}
          labelText={t("v2.position_types.show.responsibilities")}
          loadingContent={isBusy}
          setData={setResponsibilities}
        />
      </div>
    </Modal>
  )
}

export { ResponsibilitiesModal }
