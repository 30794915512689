import { useCallback, useState } from "react"
import { Error } from "types/graphql"

type ErrorsType = Record<string, Error["message"]>

type ClearErrors = (keys: string[]) => void

const prepareErrors = (errors: Error[]) =>
  errors.reduce((acc, error) => {
    const fieldKey = error.path?.[1]
    if (fieldKey) {
      acc[fieldKey] = error.message
    }

    return acc
  }, {} as ErrorsType)

const errorMessagesFor = (name: string, errors?: ErrorsType) => (errors ? errors[name] : undefined)

const useErrors = () => {
  const [errors, setErrors] = useState<ErrorsType>({})

  const clearErrors = useCallback(
    (keys: string[]) => {
      // Remove keys from errors state
      const newErrors = { ...errors }
      keys.forEach((key) => {
        delete newErrors[key]
      })
      setErrors(newErrors)
    },
    [errors],
  )

  return { errors, setErrors, clearErrors }
}

export { prepareErrors, errorMessagesFor, useErrors, ErrorsType, ClearErrors }
