import React, { useState, useRef, useMemo, useCallback } from "react"
import fp from "lodash/fp"

import { BasePay, SourcePay } from "types/graphql.enums"

import { parseCurrency, formatCurrency } from "v2/react/utils/currency"

import { BasePayInput } from "v2/react/shared/Inputs/BasePayInput"
import { CurrencyInput } from "v2/react/shared/Inputs/CurrencyInput"
import { VariablePayInput } from "v2/react/shared/Inputs/VariablePayInput"
import { useGetCurrencyCodeQuery } from "v2/redux/GraphqlApi"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { preventPropagationOfSyntheticEvent, useOutsideClick } from "v2/react/hooks/useOutsideClick"

interface VariablePayItem {
  label: string
  id: string
  value?: string | null
  payType?: SourcePay | null
  useAttentionState?: boolean
  error?: string
}

interface VariablePayMap {
  [key: string]: Omit<VariablePayItem, "id" | "label">
}

interface BasePayErrors {
  currency?: string
  hoursPerWeek?: string
}

interface BasePayItem {
  modelType: "position" | "compensation"
  currencyCode: string
  initialCurrencyValue: string
  initialPayType: BasePay
  initialHoursPerWeek: number | null | undefined
  label: string
  useAttentionState?: boolean
  errors?: BasePayErrors
}

interface UpdateSumProps {
  key: string
  value: string
  payType: BasePay | SourcePay
  hoursPerWeek?: string | null
}

interface CompensationTableProps {
  headerLabel: string
  basePay: BasePayItem
  variablePays: VariablePayItem[]
  clearErrors?: (keys: string[]) => void
}

export interface SelectOption {
  value: string
  icon: string
  headerDisplay: React.ReactNode
  fieldDisplay: React.ReactNode
  text: string
}

interface Props<ItemOption extends SelectOption> {
  options: ItemOption[]
  initialOption: ItemOption
  onSelection: (selected: ItemOption) => void
}

function CurrencyDropdown<ItemOption extends SelectOption>({
  options,
  initialOption,
  onSelection,
}: Props<ItemOption>) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<ItemOption>(initialOption)
  const toggleDropdown = () => setIsOpen(!isOpen)
  const ref = useOutsideClick<HTMLDivElement>(() => setIsOpen(false))

  const onOptionClicked = (value: ItemOption) => {
    setSelectedOption(value)
    onSelection(value)
    setIsOpen(false)
  }

  return (
    <div
      onClick={preventPropagationOfSyntheticEvent}
      onKeyDown={preventPropagationOfSyntheticEvent}
      role="presentation"
      ref={ref}
    >
      <input name="currency_code" type="hidden" value={selectedOption.value} />
      <button
        onClick={toggleDropdown}
        type="button"
        className="elevation items-center justify-between rounded bg-white p-1 text-neutral-80 shadow flex"
      >
        <span className="ml-1">{selectedOption.headerDisplay}</span>
        <FontAwesomeIcon icon={["fas", "caret-down"]} className="ml-1.5 mr-0.5 !h-3 !w-3" />
      </button>
      {isOpen && (
        <ul className="select-dropdown__short-list gap-1 overflow-scroll">
          {options.map((option) => (
            <li
              role="menuitem"
              onClick={() => onOptionClicked(option)}
              onKeyDown={() => onOptionClicked(option)}
              className={classNames("select-dropdown__option secondary", {
                active: option.text === selectedOption.text,
              })}
              key={option.text}
            >
              <span className="w-full grid-cols-[12fr_88fr] items-center text-sm font-normal grid">
                <i className={`${option.icon}`} />
                <span>{option.fieldDisplay}</span>
              </span>
              <FontAwesomeIcon
                icon={["fas", "check-circle"]}
                className="selected-indicator ml-auto"
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

const CompensationTable = ({
  headerLabel,
  basePay,
  variablePays,
  clearErrors,
}: CompensationTableProps) => {
  const { data, isLoading } = useGetCurrencyCodeQuery({})
  const company = data?.currentCompany || null
  const [currentCurrencyIcon, setCurrentCurrencyIcon] = useState<string>()

  const [basePayTotal, setBasePayTotal] = useState<number>(
    calculateBasePay({
      amount: basePay.initialCurrencyValue,
      type: basePay.initialPayType,
      hoursPerWeek: basePay.initialHoursPerWeek ? basePay.initialHoursPerWeek : null,
    }),
  )

  // This is used for calculating variable pays total amount. When variable
  // pays are updated, the amount and pay type are stored in this map,
  // and the total is recalculated.
  const [variablePayMap, setVariablePayMap] = useState<VariablePayMap>(
    fp.keyBy(fp.prop("id"))(variablePays),
  )

  // If the variable pay type is percent, then changing the base pay
  // amount affects that variable pay amount. This is why we
  // need to recalculate these values when the base pay amount changes.
  const variablePayTotal = useMemo(
    () => calculateVariablePayTotal(variablePayMap, basePayTotal),
    [variablePayMap, basePayTotal],
  )

  const total = basePayTotal + variablePayTotal

  const totalAnnualRef = useRef<HTMLInputElement>(null)

  const updateSum = useCallback(({ key, value, payType, hoursPerWeek }: UpdateSumProps) => {
    if (key === "base_pay") {
      setBasePayTotal(
        calculateBasePay({
          amount: value,
          type: payType as BasePay,
          hoursPerWeek: hoursPerWeek ? parseFloat(hoursPerWeek) : null,
        }),
      )
    } else {
      setVariablePayMap((prev) => ({
        ...prev,
        [key]: { value, payType: payType as SourcePay },
      }))
    }
  }, [])

  if (totalAnnualRef.current) {
    totalAnnualRef.current.value = formatCurrency({
      value: total,
      omitSymbol: true,
      trailing: true,
    })
  }

  const onSelection = (option: SelectOption) => {
    setCurrentCurrencyIcon(option.icon)
  }

  if (isLoading || !company) {
    return null
  }

  const options = (company.collections.currencies.options.nodes || []).map((option) => ({
    value: option.id.split(":")[0],
    label: option.label,
    noCodeLabel: option.label.split("(")[0],
    icon: option.id.split(":")[1],
  }))

  const initialOption = options.find((option) => option.value === basePay.currencyCode)
  const initialIcon = initialOption?.icon
  if (!initialOption) return null

  return (
    <div className="compensation-table">
      <div className="compensation-table__row compensation-table__row--header flex-row items-center justify-between flex">
        {headerLabel}
        <CurrencyDropdown
          options={generateCardTypeOptions(options)}
          initialOption={generateCardTypeOption(initialOption)}
          onSelection={onSelection}
        />
      </div>
      <div className="compensation-table__row">
        <BasePayInput
          modelType={basePay.modelType}
          label={basePay.label}
          labelPlacement="left"
          iconClass={currentCurrencyIcon ?? initialIcon}
          initialCurrencyValue={basePay.initialCurrencyValue || undefined}
          initialPayType={basePay.initialPayType || undefined}
          initialHoursPerWeek={basePay.initialHoursPerWeek?.toString()}
          updateSum={updateSum}
          useAttentionState={basePay.useAttentionState}
          clearErrors={clearErrors}
          currencyErrors={basePay.errors?.currency}
          hoursErrors={basePay.errors?.hoursPerWeek}
        />
      </div>
      {variablePays?.map((variablePay) => {
        const { id, label, value, payType } = variablePay
        return (
          <div className="compensation-table__row" key={id}>
            <VariablePayInput
              label={label}
              labelPlacement="left"
              iconClass={currentCurrencyIcon ?? initialIcon}
              initialAmountValue={value || undefined}
              initialPayType={payType || undefined}
              name={id.replace(/_type$/, "")}
              updateSum={updateSum}
              useAttentionState={variablePay.useAttentionState}
              amountErrors={variablePay.error}
              clearErrors={clearErrors}
            />
          </div>
        )
      })}
      <div className="compensation-table__row compensation-table__row--footer">
        <span className="compensation-table__row--footer--label">
          {"Total Annual".t("compensation", "Total Annual")}
        </span>
        <div className="compensation-table__row--footer--total">
          <CurrencyInput
            ref={totalAnnualRef}
            id="totalAnnual"
            name="totalAnnual"
            iconClass={currentCurrencyIcon ?? initialIcon}
            defaultValue={formatCurrency({
              value: total,
              omitSymbol: true,
              trailing: true,
            })}
            disabled
          />
        </div>
      </div>
    </div>
  )
}

const calculateVariablePayTotal = (variablePayMap: VariablePayMap, basePayAmount: number) => {
  let total = 0
  Object.values(variablePayMap).forEach((variablePay) => {
    total += calculateVariablePay({
      amount: variablePay.value || "",
      type: variablePay.payType || SourcePay.Amount,
      basePayAmount,
    })
  })
  return total
}

const calculateVariablePay = ({
  amount,
  type,
  basePayAmount,
}: {
  amount: string
  type: SourcePay
  basePayAmount: number
}): number => {
  if (type === SourcePay.Percent) {
    return (parseCurrency(amount || "") / 100.0) * basePayAmount
  }
  return parseCurrency(amount || "") || 0.0
}

const calculateBasePay = ({
  amount,
  type,
  hoursPerWeek,
}: {
  amount: string
  type: BasePay
  hoursPerWeek: number | null
}): number => {
  let total = parseCurrency(amount) || 0
  if (type === BasePay.Hourly && hoursPerWeek) {
    total *= hoursPerWeek * 52.0
  }

  return total
}

interface Option {
  icon: string
  value: string
  label: string
  noCodeLabel: string
}

const generateMiniCard = (option: Option, showLabel: boolean) => (
  <p className="text-left text-sm normal-case">{showLabel ? option.label : option.value}</p>
)

const generateCardTypeOption = (option: Option) => ({
  value: option.value,
  icon: option.icon,
  text: option.label,
  fieldDisplay: generateMiniCard(option, true),
  headerDisplay: generateMiniCard(option, false),
})

const generateCardTypeOptions = (options: Option[]) => options.map(generateCardTypeOption)

export { CompensationTable, VariablePayItem, UpdateSumProps, BasePayItem }
