import {
  FormOrgUnit,
  FormShape,
} from "v2/react/components/jobRequisitions/RequisitionForm/types/FormShape"
import { GenericFormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/GenericFormShape"
import { PositionType, JsonForm } from "types/graphql"
import { OrgUnitTypeSystemType } from "types/graphql.enums"

import { set, get } from "lodash"

type ReqOption = { id: string; name: string }

const positionPropertyPath = (attribute: string) => `position.properties.${attribute}.properties`

const JOB_TITLE_FIELD_PATHS = {
  eeoc_classification: positionPropertyPath("eeocClassification"),
  flsa_classification: positionPropertyPath("flsa"),
  job_code: positionPropertyPath("jobCode"),
  department: positionPropertyPath("department"),
  description: "jobDescription.properties",
}

/**
 * If a position type controlled attribute is set on the position type, then
 * we disable the field as it cannot be edited from individual position
 * instances.
 *
 * We do this by setting the readOnly property to true on the matching schema.
 * Ideally the JsonForms library would then take things from there and properly
 * set the enabled prop in the control, if there's already a rule present on the
 * schema, it ignores the readOnly property set on the Json Schema.
 * see: https://jsonforms.io/docs/readonly/#evaluation-order
 *
 * In order to get around this, we manually check for this property in the
 * controls and set the disabled state there.
 * see: webpack/v2/react/shared/JsonFormControls/utils/forms.ts
 */
const handleJobTitleFieldsDisabledState = ({
  jsonForm,
  positionType,
}: {
  jsonForm: JsonForm
  // When the positionType is null, all fields are editable.
  positionType?: PositionType | null
}) => {
  const dataSchema = jsonForm.schema?.data_schema?.properties
  if (!dataSchema) return jsonForm

  const editableFieldKeys = positionType?.editablePositionTypeControlledAttributeKeys
  const editableFields = editableFieldKeys ? [...editableFieldKeys] : []

  const departmentPresentOnPositionType = !!positionType?.orgUnits?.find(
    (ou) => ou.orgUnitType?.systemTypeKey === OrgUnitTypeSystemType.Department,
  )

  if (!departmentPresentOnPositionType) {
    // If there's no department on the position type, then it's editable.
    // This handling is necessary b/c the department's uniqueKey is what's used
    // in editablePositionTypeControlledAttributeKeys, and right now there's not
    // a great way to get access to that key in the client without loading more
    // data.
    editableFields.push(OrgUnitTypeSystemType.Department)
  }

  Object.entries(JOB_TITLE_FIELD_PATHS).forEach(([field, path]) => {
    const isEditable = positionType ? editableFields.includes(field) : true
    const schemaValue = get(dataSchema, path)
    set(dataSchema, path, {
      ...schemaValue,
      readOnly: !isEditable,
    })
  })

  // handle department in the org units field for generic schemas
  if (!editableFields.includes(OrgUnitTypeSystemType.Department)) {
    const path = "position.properties.orgUnits.items.properties"
    const schemaValue = get(dataSchema, path)
    set(dataSchema, path, {
      ...schemaValue,
      disableDepartment: true,
    })
  }

  return jsonForm
}

/**
 * When a position type is selected, we want to autofill the form with any
 * values that are set on the position type. Note that in addition to this,
 * we also need to handle the disabled state of the fields, which is done in
 * the handleJobTitleFieldsDisabledState function.
 */
const loadJobTitleFormData = (formData: FormShape, positionType: PositionType): FormShape => {
  if (formData.schemaType === "posigen") {
    // Posigen forms only have 2 title-related fields at the moment.
    return {
      ...formData,
      position: {
        ...formData.position,
        flsa: loadFlsaClassification(formData, positionType),
        department: loadDepartment(formData, positionType),
      },
    }
  }

  return {
    ...formData,
    jobDescription: loadJobDescription(formData, positionType),
    position: {
      ...formData.position,
      eeocClassification: loadEeocClassification(formData, positionType),
      flsa: loadFlsaClassification(formData, positionType),
      jobCode: loadJobCode(formData, positionType),
      orgUnits: loadDepartmentInOrgUnits(formData, positionType),
    },
  }
}

const loadDepartmentInOrgUnits = (
  data: GenericFormShape,
  positionType: PositionType,
): FormOrgUnit[] => {
  const department = positionType.orgUnits?.find(
    (ou) => ou.orgUnitType?.systemTypeKey === OrgUnitTypeSystemType.Department,
  )

  if (typeof department !== "undefined") {
    return data.position.orgUnits.map((orgUnit) => {
      if (orgUnit.typeId === department.field_id) {
        return {
          id: department.id || "",
          name: department.full_name || "",
          typeId: orgUnit.typeId,
          typeName: orgUnit.typeName,
        }
      }
      return orgUnit
    })
  }

  return data.position.orgUnits
}

const loadDepartment = (data: FormShape, positionType: PositionType): ReqOption => {
  const department = positionType.orgUnits?.find(
    (ou) => ou.orgUnitType?.systemTypeKey === OrgUnitTypeSystemType.Department,
  )

  if (department?.id && department?.full_name) {
    return {
      id: department.id,
      name: department.full_name,
    }
  }

  return data.position.department
}

const loadJobDescription = (data: GenericFormShape, positionType: PositionType): string => {
  if (positionType.description) return positionType.description
  return data.jobDescription || ""
}

const loadEeocClassification = (data: GenericFormShape, positionType: PositionType): ReqOption => {
  if (positionType.eeocClassification)
    return {
      id: `eeoc_classification_${positionType.eeocClassification}`,
      name: positionType.eeocClassification.t("eeoc_classification"),
    }
  return data.position.eeocClassification
}

const loadFlsaClassification = (data: FormShape, positionType: PositionType): ReqOption => {
  if (positionType.flsaClassification)
    return {
      id: `flsa_classification_${positionType.flsaClassification}`,
      name: `flsa_classification_${positionType.flsaClassification}`.t("position"),
    }
  return data.position.flsa
}

const loadJobCode = (data: GenericFormShape, positionType: PositionType): string => {
  if (positionType.jobCode) return positionType.jobCode
  return data.position.jobCode || ""
}

export { loadJobTitleFormData, handleJobTitleFieldsDisabledState }
