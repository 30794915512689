import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import pluralize from "pluralize"
import React, { useRef, useState } from "react"
import { Position } from "types/graphql"
import { useOnClickOutside } from "usehooks-ts"
import { JobRequisitionAbilities } from "v2/react/components/jobRequisitions/types"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"

interface Props {
  abilities: JobRequisitionAbilities
  jobRequisitionId: string
  positions: Position[]
  status: string
}

function ConnectedPositions({ abilities, jobRequisitionId, positions, status }: Props) {
  const [showList, setShowList] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setShowList(false))

  const positionText = "position".t("job_requisition")
  const buttonText = (
    <>
      {positions.length > 1 && `${positions.length}`}{" "}
      {"positions_connected".t("job_requisition", null, null, null, [
        positions.length > 1 ? pluralize(positionText) : positionText,
      ])}
    </>
  )

  const editConnections = () => {
    setShowList(false)
    pjaxModalFor(UrlHelper.editConnectionPath(jobRequisitionId))
  }

  const positionLinkButtons = positions.map((position: Position) => (
    <a
      className="dropdown-menu-link whitespace-nowrap"
      href={UrlHelper.positionOnChartPath(position.id)}
      key={position.id}
    >
      {"view_position_id".t("job_requisition")}{" "}
      <span className="text-primary-100">{position.systemIdentifier}</span>
    </a>
  ))

  const editPositionConnectionButton = abilities.canCreatePosition && status === "approved" && (
    <button
      className="dropdown-menu-link whitespace-nowrap"
      onClick={editConnections}
      type="button"
    >
      {"edit_position_connection".t("job_requisition", null, null, positions.length)}
    </button>
  )

  return (
    <>
      <div className="hidden sm:flex">
        <div className="btn--large btn--secondary rounded-r-none border-r-0">{buttonText}</div>
        <div className="dropdown" ref={ref}>
          <button
            className="btn--large btn--secondary dropdown-link ml-[-1px] rounded-l-none border-l-0"
            onClick={() => setShowList(!showList)}
            type="button"
          >
            <FontAwesomeIcon icon="caret-down" />
          </button>
          <div className={classNames("dropdown-menu dropdown-menu-right", { open: showList })}>
            {positionLinkButtons}
            {editPositionConnectionButton}
          </div>
        </div>
      </div>

      <div className="w-full block sm:hidden">
        <p className="mb-2 text-base-bold">{buttonText}</p>
        {positionLinkButtons}
        {editPositionConnectionButton}
      </div>
    </>
  )
}

export { ConnectedPositions }
