import React, { useContext, useMemo, useRef, useState } from "react"
import {
  arrow,
  autoUpdate,
  Boundary,
  flip,
  offset,
  Padding,
  Placement,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react"

import { Maybe } from "types/graphql"

// Credit to: https://codesandbox.io/s/xenodochial-grass-js3bo9?file=/src/App.tsx:52-82
interface TooltipOptions {
  /**
   * @see https://floating-ui.com/docs/detectOverflow#altboundary
   */
  altBoundary?: boolean
  /**
   * Optional boundary
   */
  boundary?: Boundary
  /**
   * Optional padding applied for detecting overflow and to shift the tooltip.
   */
  boundaryPadding?: Padding
  /**
   * Override the default gap size of 2.
   */
  gapSize?: number
  /**
   * Indicates whether the tooltip should initially be shown or not.
   */
  initialOpen?: boolean
  /**
   * Indicates whether the tooltip should be shown. Leave undefined if this can
   * be controlled by the component itself.
   */
  open?: boolean
  /**
   * Where to place the tooltip (defaults to the best option if undefined).
   */
  placement?: Placement
  /**
   * Callback to set whether the tooltip is shown or not. Leave undefined if
   * this can be managed by the component itself.
   */
  setOpen?: (open?: boolean) => void
  /**
   * Adds a delay in ms before the tooltip is shown. (default: 50ms)
   */
  showDelay?: number
  /**
   * Adds a delay in ms before the tooltip is closed. (default: 50ms)
   */
  closeDelay?: number
}

type TooltipContextState = Maybe<ReturnType<typeof useTooltip>>

const TooltipContext = React.createContext<TooltipContextState>(null)

function useTooltip({
  altBoundary,
  boundary,
  boundaryPadding,
  gapSize = 8,
  initialOpen = false,
  open: controlledOpen,
  placement,
  setOpen: controlledSetOpen,
  showDelay = 50,
  closeDelay = 50,
}: TooltipOptions) {
  const [uncontrolledOpen, uncontrolledSetOpen] = useState(initialOpen)

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = controlledSetOpen ?? uncontrolledSetOpen
  const arrowRef = useRef(null)

  const OFFSET = gapSize

  const floating = useFloating({
    whileElementsMounted: autoUpdate,
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [
      flip({ boundary, padding: boundaryPadding, altBoundary }),
      arrow({ element: arrowRef }),
      offset(OFFSET),
    ],
  })

  const hover = useHover(floating.context, {
    move: false,
    enabled: true,
    delay: { open: showDelay, close: closeDelay },
  })
  const dismiss = useDismiss(floating.context)
  const role = useRole(floating.context, { role: "tooltip" })

  const interactions = useInteractions([dismiss, hover, role])

  return useMemo(
    () => ({
      open,
      setOpen,
      arrowRef,
      ...interactions,
      ...floating,
    }),
    [open, setOpen, interactions, floating],
  )
}

function useTooltipContext() {
  const context = useContext(TooltipContext)

  if (context === null) {
    throw new Error("Tooltip components must be wrapped in <Tooltip />")
  }

  return context
}

export { TooltipContext, TooltipContextState, TooltipOptions, useTooltip, useTooltipContext }
