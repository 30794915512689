import React, { useEffect, useRef, useState } from "react"
import cn from "classnames"
import { useTranslation } from "react-i18next"

interface Props {
  children: React.ReactNode
}

function ActionMenu({ children }: Props) {
  const { t } = useTranslation()
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(true)

  const checkForVisibleChildren = () => {
    if (dropdownRef && dropdownRef.current) {
      const visible = Array.from(dropdownRef.current.children).filter(
        (c: Element) => c instanceof HTMLElement && window.getComputedStyle(c).display !== "none",
      )
      setIsVisible(visible.length > 0)
    }
  }

  useEffect(() => checkForVisibleChildren(), [])

  useEffect(() => {
    window.addEventListener("resize", checkForVisibleChildren)

    return () => {
      window.removeEventListener("resize", checkForVisibleChildren)
    }
  }, [])

  return (
    <div className="dropdown orgchart-dropdown">
      <button
        type="button"
        className={cn("btn--large btn--secondary dropdown-link tooltip tooltip-right w-10", {
          hidden: !isVisible,
        })}
      >
        <i className="far fa-ellipsis-h" />
        <span className="tooltip-content tooltip-content--sm">
          {t("v2.orgchart.pagenav.more_options")}
        </span>
      </button>
      <div
        ref={dropdownRef}
        className="dropdown-menu left-auto right-0 z-20 max-h-[75vh] overflow-y-auto p-4 md:min-w-max 1225:p-2"
      >
        {children}
      </div>
    </div>
  )
}

export { ActionMenu }
