import React, { useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TFunction } from "i18next"
import cn from "classnames"
import { useTranslation } from "react-i18next"

import { Candidate, Person, Position } from "types/graphql"
import { EmptyState } from "v2/react/components/positionTypes/Show/EmptyState"
import { PositionsApi } from "v2/redux/GraphqlApi/PositionsApi"
import { SuccessionPlanPanelContainer } from "v2/react/components/succession/SuccessionPlanPanelContainer"
import { openSuccessionPlanSidebar } from "v2/redux/slices/PanelSidebarSlice"
import { useAppDispatch } from "v2/redux/store"

interface Props {
  abilities: {
    canManageSuccessionPlan: boolean
  }
  positionId: string
}

const SuccessionPlan = ({ abilities, positionId }: Props) => {
  const { t } = useTranslation()
  const [getPositionPlanTrigger, positionPlanData] =
    PositionsApi.endpoints.getPositionSuccessionPlan.useLazyQuery()
  const dispatch = useAppDispatch()

  // This is not ideal. Since the position panel uses pjax, we need to manually
  // manage refetching the plan data when its state changes with updates in the
  // succession plan panel.
  useEffect(() => {
    getPositionPlanTrigger({ positionId })
  }, [positionId, getPositionPlanTrigger])

  const position: Position | null | undefined = positionPlanData?.data?.position
  if (!position) return null

  const handleClose = () => {
    getPositionPlanTrigger({ positionId })
  }

  const showPlanPanel = () => {
    dispatch(openSuccessionPlanSidebar({ positionId: position.id }))
  }

  return (
    <>
      <SuccessionPlanPanelContainer afterClose={handleClose} />
      <div className="module-card">
        <div className="module-card__header">
          <div className="module-title gap-2 flex">
            <span>{t("v2.positions.show.succession_plan")}</span>
            {position.successionPlanStatus && position.successionPlanStatus !== "not_defined" && (
              <StatusBadge
                label={t(`v2.defaults.${position.successionPlanStatus}`)}
                status={position.successionPlanStatus || ""}
              />
            )}
          </div>

          {!isPlanEmpty(position) && abilities.canManageSuccessionPlan && (
            <button className="module-action" onClick={showPlanPanel} type="button">
              {t("v2.positions.show.view_plan")}
            </button>
          )}
        </div>
        <div className="module-card__body border-0 p-0">
          {!isPlanEmpty(position) ? (
            <SuccessionPlanCandidates
              candidates={position.successionPlan?.candidates || []}
              t={t}
            />
          ) : (
            <EmptyState
              buttonText={t("v2.positions.show.add_succession_plan_cta")}
              icon="key"
              note={t("v2.positions.show.add_succession_plan_note")}
              onClick={showPlanPanel}
            />
          )}
        </div>
      </div>
    </>
  )
}

const isPlanEmpty = (position: Position) =>
  !position.successionPlanStatus ||
  !position.successionPlan ||
  !position.successionPlan.candidates?.length

const SuccessionPlanCandidates = ({ candidates, t }: { candidates: Candidate[]; t: TFunction }) => (
  <div className="module-card__body border-0 p-0">
    <div className="max-h-[400px] overflow-y-auto">
      {(candidates || []).map((candidate: Candidate, index: number) => (
        <PersonRow candidate={candidate} index={index} key={candidate.id} t={t} />
      ))}
    </div>
  </div>
)

interface PersonRowProps {
  candidate: Candidate
  index: number
  t: TFunction
}

const PersonRow = ({ candidate, index, t }: PersonRowProps) => {
  if (!candidate.person && !candidate.outsiderName) return null

  return (
    <div className="flex">
      <div className="border-0 border-t border-solid border-neutral-8 flex">
        <div className="w-6 bg-neutral-3 pt-4 text-center">{index + 1}</div>
      </div>
      {candidate.person ? (
        <div className="grow">
          <PersonRowPerson person={candidate.person} t={t} />
          <PersonRowLevels candidate={candidate} t={t} />
        </div>
      ) : (
        <PersonRowOutsider outsiderName={candidate.outsiderName || ""} t={t} />
      )}
    </div>
  )
}

const PersonRowOutsider = ({ outsiderName, t }: { outsiderName: string; t: TFunction }) => (
  <div className="grow border-0 border-t border-solid border-neutral-8 px-4 py-4 flex ">
    <div className="flex">
      <div className="items-center pr-2 flex">
        <div className="h-9 w-9 items-center justify-center rounded-full bg-primary-8-solid text-primary-100 flex">
          <FontAwesomeIcon icon={["far", "briefcase"]} />
        </div>
      </div>
      <div>
        <div className="text-neutral-100">{outsiderName}</div>
        <div className="text-neutral-64">{t("v2.positions.show.external_candidate")}</div>
      </div>
    </div>
  </div>
)

const PersonRowPerson = ({ person, t }: { person: Person; t: TFunction }) => (
  <div className="border-0 border-t border-solid border-neutral-8 px-4 py-4 flex ">
    <div className="flex">
      <div className="items-center pr-2 flex">
        {person.avatarThumbUrl ? (
          <img className="circle" src={person.avatarThumbUrl} alt="user avatar" />
        ) : (
          <div className="h-9 w-9 items-center justify-center rounded-full bg-primary-8-solid flex">
            <FontAwesomeIcon icon={["far", "chair-office"]} />
          </div>
        )}
      </div>
      <div>
        <div className="text-neutral-100">{person.name}</div>
        {person.positions?.length ? (
          <div className="text-neutral-64">{person.positions[0].title}</div>
        ) : (
          <div className="text-neutral-64">{t("v2.positions.show.no_position_assigned")}</div>
        )}
      </div>
    </div>
  </div>
)

const PersonRowLevels = ({ candidate, t }: { candidate: Candidate; t: TFunction }) => (
  <div className="grid-cols-2 px-4 grid 1335:grid-cols-4 ">
    <PersonRowLevel
      value={candidate.readiness}
      label={t("v2.positions.show.succession_plan_readiness")}
      t={t}
    />
    <PersonRowLevel
      value={candidate.person?.performance}
      label={t("v2.positions.show.succession_plan_performance")}
      t={t}
    />
    <PersonRowLevel
      value={candidate.person?.potential}
      label={t("v2.positions.show.succession_plan_potential")}
      t={t}
    />
    <PersonRowInvertedLevel
      value={candidate.person?.flightRisk}
      label={t("v2.positions.show.succession_plan_flight_risk")}
      t={t}
    />
  </div>
)

interface PersonRowLevelProps {
  label: string
  t: TFunction
  value?: string | null
}

const PersonRowLevel = ({ label, t, value }: PersonRowLevelProps) => (
  <div className="pb-4 pr-2">
    <div className="text-sm text-neutral-64">{label}</div>
    <div>{value ? <StatusBadge label={t(`v2.defaults.${value}`)} status={value} /> : "-"}</div>
  </div>
)

// For the purposes of badges, we invert the status to match the color scheme
// for things like "Flight Risk," where "high" is bad a bad thing.
const PersonRowInvertedLevel = ({ label, t, value }: PersonRowLevelProps) => {
  const uiStatus = invertStatus(value)
  return (
    <div className="pb-4 pr-2">
      <div className="text-sm text-neutral-64">{label}</div>
      <div>
        {uiStatus ? <StatusBadge label={t(`v2.defaults.${value}`)} status={uiStatus} /> : "-"}
      </div>
    </div>
  )
}

function invertStatus(status?: string | null): string | null {
  if (status === "high") return "low"
  if (status === "low") return "high"
  return status || null
}

interface StatusBadgeProps {
  label: string
  status: string
}

const StatusBadge = ({ label, status }: StatusBadgeProps) => {
  const baseClasses = "w-fit rounded-xl p-1 py-[.125rem] px-1 text-sm "
  return <div className={cn(baseClasses, statusBadgeClasses(status))}>{label}</div>
}

function statusBadgeClasses(status: string): string {
  if (status === "complete" || status === "high") {
    return "bg-status-success-light text-status-success"
  }
  if (status === "at_risk" || status === "medium") {
    return "bg-status-caution-light text-status-caution"
  }
  if (status === "at_risk" || status === "low") {
    return "bg-status-critical-light text-status-critical"
  }

  return ""
}

export { SuccessionPlan }
