import { flow } from "lodash"

import defineAvatarClipPath from "./defineAvatarClipPath"
import defineCardBackgroundLinearGradient from "./defineCardBackgroundLinearGradient"
import defineCardPulseShadow from "./defineCardPulseShadow"
import defineColorClip from "./defineColorClip"
import defineDescendantsIcon from "./defineDescendantsIcon"
import defineEllipsisIcon from "./defineEllipsisIcon"
import defineInfoCircleIcon from "./defineInfoCircleIcon"
import defineParentPageNumTriangleMarker from "./defineParentPageNumTriangleMarker"
import defineShadowElevation from "./defineShadowElevation"

// Append various <defs> to the given SVG element to be used for filters,
// masks, clipPath, etc.
const defineChartDefs = (
  svg: d3.Selection<SVGElement>,
  nodeHeight: number,
): d3.Selection<SVGElement> => {
  svg.append("defs")

  return flow(
    defineAvatarClipPath,
    defineCardBackgroundLinearGradient,
    defineCardPulseShadow,
    (svg) => defineColorClip(svg, nodeHeight),
    defineDescendantsIcon,
    defineEllipsisIcon,
    defineInfoCircleIcon,
    defineParentPageNumTriangleMarker,
    defineShadowElevation,
  )(svg)
}

export default defineChartDefs

export {
  defineAvatarClipPath,
  defineCardBackgroundLinearGradient,
  defineColorClip,
  defineDescendantsIcon,
  defineParentPageNumTriangleMarker,
  defineShadowElevation,
  defineEllipsisIcon,
  defineInfoCircleIcon,
  defineCardPulseShadow,
}
