import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { ACCCORDION_DURATION } from "v2/react/shared/AccordionModulePanel"

interface AccordionModulePanelOverlayProps {
  headerContent: React.ReactNode
  children: React.ReactNode
  closePanel: () => void
  isOpen: boolean
}

function AccordionModulePanelOverlay({
  headerContent,
  closePanel,
  children,
  isOpen,
}: AccordionModulePanelOverlayProps) {
  const headerClasses = classNames("flex gap-4 items-center w-full accordion-module-panel__header")

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          className="accordion-module-panel__overlay"
          key="overlay"
          initial={{ height: "auto", opacity: 0 }}
          animate="open"
          exit="closed"
          variants={variants}
        >
          <div className={headerClasses}>
            <button
              className="accordion-module-panel__header-icon"
              onClick={closePanel}
              type="button"
            >
              <FontAwesomeIcon
                className="m-0 cursor-pointer"
                icon={["far", "chevron-left"]}
                size="1x"
              />
            </button>
            {headerContent}
          </div>
          <div className="accordion-module-panel__content">
            <div className="accordion-module-panel__content-items">{children}</div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export { AccordionModulePanelOverlay }

const variants = {
  open: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: ACCCORDION_DURATION / 2,
      opacity: { delay: ACCCORDION_DURATION / 2 },
    },
  },
  closed: {
    opacity: 0,
    height: 0,
    transition: {
      duration: ACCCORDION_DURATION / 2,
    },
  },
}
