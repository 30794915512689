import React from "react"
import classNames from "classnames"
import { PercentChange } from "./PercentChange"

interface Comparison {
  changeSinceRelativeDate: string
  relativeDate: string
}

interface ValueProps {
  value: number
  comparison?: Comparison
  handleClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const Value = ({ value, comparison, handleClick }: ValueProps) => {
  const WrapperComponent = handleClick ? "button" : "div"

  const wrapperClasses = classNames("f-28 widget-big-number", {
    clickable: handleClick,
    "bg-transparent": handleClick,
  })

  const widgetGroupClasses = classNames("widget-number-group", {
    "mb-2": comparison,
    "mb-1": !comparison,
  })

  return (
    <div className={widgetGroupClasses}>
      <WrapperComponent className={wrapperClasses} onClick={handleClick}>
        {`${value}%`}
      </WrapperComponent>
      {comparison && (
        <div className="widget-comparison-text">
          <PercentChange
            changeSinceRelativeDate={parseFloat(comparison.changeSinceRelativeDate)}
            relativeDate={comparison.relativeDate}
          />
        </div>
      )}
    </div>
  )
}
