import {
  ColumnDef,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  InitialTableState,
  TableState,
  useReactTable,
} from "@tanstack/react-table"

import { DEFAULT_PAGE_SIZE } from "v2/react/constants"

interface TableProps<TData> {
  autoResetPageIndex?: boolean
  data: TData[]
  columns: ColumnDef<TData>[]
  initialState?: InitialTableState
  state?: Partial<TableState>
}

export const useClientTable = <TData extends object>({
  autoResetPageIndex,
  data,
  columns,
  initialState,
  state,
}: TableProps<TData>) => {
  const table = useReactTable({
    data,
    columns,
    initialState: { pagination: { pageSize: DEFAULT_PAGE_SIZE }, ...initialState },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex,
    state,
  })

  return table
}
