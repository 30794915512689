import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { useRef, useState } from "react"
import { FeatureFlags } from "types/graphql"
import { useOnClickOutside } from "usehooks-ts"

interface Props {
  ff: FeatureFlags
}

function UtilitySearch({ ff }: Props) {
  const [searchExpanded, setSearchExpanded] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const shrinkSearch = () => {
    if (!searchExpanded) return
    setSearchExpanded(false)
  }

  useOnClickOutside(ref, shrinkSearch)

  return (
    <div ref={ref} className={cn("utility-search-wrapper", cn({ expanded: searchExpanded }))}>
      <button
        className={cn("btn btn--secondary h-8 w-8 480:hidden", {
          hidden: searchExpanded,
        })}
        type="button"
        onClick={() => setSearchExpanded(true)}
      >
        <FontAwesomeIcon icon={["far", "search"]} />
      </button>

      <div
        className={cn("input-group utility-search relative mb-0 shrink hidden 480:flex", {
          "!max-w-full !flex": searchExpanded,
        })}
      >
        <input
          className={cn(
            "input prefix-pad suffix-pad h-8 !p-4 480:w-full 480:max-w-[100%] 480:!px-7 sm:h-10",
            { "!px-7": searchExpanded },
          )}
          type="search"
          placeholder={"Search Org Chart".t("org_chart")}
          data-autocomplete="search"
          data-autocomplete-url="/positions/search"
          data-exclude=""
          data-chart-id={window.gon.selected_chart.id}
        />
        <div className="prefix">
          <FontAwesomeIcon icon={["far", "search"]} />
        </div>
        <div className="suffix hidden" data-action="clear-search">
          <FontAwesomeIcon icon={["fas", "times"]} className="self-center" />
        </div>

        <div className="list-group autocomplete-list search-filters z-100 hidden">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="list-group-item active" data-filter-text="" href="#">
            {"Everything".t("org_chart")}
          </a>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="list-group-item" data-filter-text="People: " href="#">
            {"People".t("org_chart")}
          </a>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="list-group-item" data-filter-text="Positions: " href="#">
            {"Positions".t("org_chart")}
          </a>
          {ff.bio && (
            <>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="list-group-item" data-filter-text="Skills: " href="#">
                {"Skills".t("org_chart")}
              </a>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="list-group-item" data-filter-text="Lic&Cert: " href="#">
                {"Lic & Cert".t("org_chart")}
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export { UtilitySearch }
