import { ProfileBox, Mode, setSortedBoxes } from "v2/redux/slices/ProfilePanelSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

function useSortedBoxes(boxName: string, mode: Mode) {
  const dispatch = useAppDispatch()
  const sortedBoxes = useAppSelector((state) => state.profilePanel.sortedBoxes)

  const currentBox = sortedBoxes.find(
    (box: ProfileBox) => box.name === boxName && box.mode === mode,
  )

  const toggleBox = () => {
    const updatedBoxes = sortedBoxes.map((box) =>
      box.name === boxName && box.mode === mode ? { ...box, collapsed: !box.collapsed } : box,
    )
    dispatch(setSortedBoxes(updatedBoxes))

    window.App.Preferences.update({ org_chart: { profile_panel: updatedBoxes } })
  }

  return {
    currentBox,
    toggleBox,
  }
}

export { useSortedBoxes }
