import React, { FormEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import { CareerLadderForm } from "v2/react/components/careerLadders/CareerLadderForm"
import { useCareerLaddersUpdateMutation } from "v2/redux/GraphqlApi/CareerLaddersApi"
import { CareerLaddersUpdateInput, CareerLadder, Error } from "types/graphql"

interface Props {
  careerLadder: CareerLadder
  onClose: () => void
}

const EditCareerLadderModal = ({ careerLadder, onClose }: Props) => {
  const { t } = useTranslation()
  const [errors, setErrors] = useState<Error[] | undefined | null>(null)
  const generalError = [
    { message: t("v2.career_ladders.errors.could_not_be_updated"), path: ["general"] },
  ]
  const [mutate, { isLoading }] = useCareerLaddersUpdateMutation()

  const onSubmit = async (event: FormEvent<Element>) => {
    event.preventDefault()

    const form = event.target as HTMLFormElement
    const formData = new FormData(form)
    const input: CareerLaddersUpdateInput = {
      careerLadderId: careerLadder.uniqueKey,
      code: formData.get("code"),
      name: formData.get("name"),
      description: formData.get("description"),
    } as CareerLaddersUpdateInput

    try {
      const result = await mutate(input).unwrap()

      if (result.careerLaddersUpdate?.careerLadder?.id) {
        onClose()
      } else if (result.careerLaddersUpdate?.errors) {
        setErrors(result.careerLaddersUpdate?.errors)
      } else {
        setErrors(generalError)
      }
    } catch (error) {
      setErrors(generalError)
    }
  }

  return (
    <CareerLadderForm
      isOpen
      isLoading={isLoading}
      onClose={onClose}
      title={t("v2.career_ladders.index.edit_career_ladder", {
        code: careerLadder.code,
        name: careerLadder.name ? ` - ${careerLadder.name}` : "",
      })}
      onSubmit={onSubmit}
      errors={errors}
      careerLadder={careerLadder}
    />
  )
}

export { EditCareerLadderModal }
