import React from "react"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

export enum ButtonTypes {
  Previous = "previous",
  Next = "next",
  Page = "page",
}

interface PaginateButtonsProps {
  handleClick: () => void
  disabled?: boolean
  current?: boolean
  type: ButtonTypes
  pageNumber?: number
}

export const PaginateButton = ({
  handleClick,
  disabled,
  current,
  type,
  pageNumber,
}: PaginateButtonsProps) => {
  const { t } = useTranslation()
  const buttonLabels = {
    [ButtonTypes.Previous]: `← ${t("v2.defaults.previous")}`,
    [ButtonTypes.Next]: `${t("v2.defaults.next")} →`,
    [ButtonTypes.Page]: pageNumber?.toString() || "",
  }

  return (
    <button
      type="button"
      className={classNames({
        "!opacity-100": true,
        [`${type}_page`]: true,
        disabled: current ? false : disabled,
        current,
      })}
      disabled={disabled}
      onClick={handleClick}
    >
      {buttonLabels[type]}
    </button>
  )
}
