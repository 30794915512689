import React, { useState, useCallback, useMemo } from "react"
import cn from "classnames"
import { useTranslation } from "react-i18next"

import type { CareerLadderPositionTypes } from "v2/react/components/careers/Index/LadderSection"

import { LadderSection } from "v2/react/components/careers/Index/LadderSection"
import { PaginatedPositionTypeCards } from "v2/react/components/careers/Index/PaginatedPositionTypeCards"
import { Spinner } from "v2/react/shared/Spinner"

const MemoedInterestSection = React.memo(PaginatedPositionTypeCards)
const MemoedRecommendedSection = React.memo(PaginatedPositionTypeCards)

interface PositionTypesPlatterProps {
  careerLadderPositionTypes: CareerLadderPositionTypes | null
}

const PositionTypesPlatter = ({ careerLadderPositionTypes }: PositionTypesPlatterProps) => {
  const [loadedState, setLoadedState] = useState({
    indicatedInterestPositionTypes: false,
    recommendedPositionTypes: false,
  })
  const { t } = useTranslation()

  const createLoadedStateUpdater = useCallback(
    (key: string) => (isLoaded: boolean) => {
      setLoadedState((prev) => ({ ...prev, [key]: isLoaded }))
    },
    [],
  )

  const updaters = useMemo(
    () => ({
      indicatedInterestPositionTypes: createLoadedStateUpdater("indicatedInterestPositionTypes"),
      recommendedPositionTypes: createLoadedStateUpdater("recommendedPositionTypes"),
    }),
    [createLoadedStateUpdater],
  )

  const allLoaded = Object.values(loadedState).every((loaded) => loaded)

  return (
    <>
      {careerLadderPositionTypes && <LadderSection positionTypes={careerLadderPositionTypes} />}
      {!allLoaded && (
        <div className="relative min-h-[13.5rem]">
          <Spinner />
        </div>
      )}
      <div
        className={cn("flex-col gap-8 transition-opacity flex", {
          "opacity-0": !allLoaded,
          "opacity-100": allLoaded,
        })}
      >
        <MemoedInterestSection
          jobFamily={null}
          title={t("v2.careers.index.jobs_favorited")}
          setParentLoadedState={updaters.indicatedInterestPositionTypes}
          filterByIndicatedInterest
          hideSectionIfEmpty
        />
        <MemoedRecommendedSection
          jobFamily={null}
          title={t("v2.careers.index.jobs_of_interest")}
          setParentLoadedState={updaters.recommendedPositionTypes}
          filterByRecommended
          hideSectionIfEmpty
        />
      </div>
    </>
  )
}

export { PositionTypesPlatter }
