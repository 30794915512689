import React, { ReactNode, useEffect, useRef } from "react"
import classNames from "classnames"
import { useBoolean, useOnClickOutside } from "usehooks-ts"

import {
  useCareerLaddersCreateSplitCareerLaddersTrackMutation,
  useCareerLaddersDestroyCareerLadderTrackPositionTypeMutation,
  useCareerLaddersUpdateCareerLadderTrackPositionTypeMutation,
} from "v2/redux/GraphqlApi/CareerLaddersApi"
import { CareerLadderTrackPositionType, PositionType } from "types/graphql"
import { StyledJobDefinitionInput } from "v2/react/components/careerLadders/Builder/AddJobDefinitionItem"
import { PositionTypesConnectionNode } from "v2/redux/GraphqlApi/PositionTypesApi"
import { UrlHelper } from "v2/react/utils/urls"

import { TrackJobDefinitionMenu } from "./TrackJobDefinitionMenu"
import { PositionConnector } from "./Connectors"

type JobDefinitionForTrackProps = {
  activePositionTypeId?: string
  canDestroy: boolean
  canSplitTrack?: boolean
  careerLadderId: string
  editMode: boolean
  positionAsLink?: boolean
  positionType?: PositionType | null
  showConnector?: boolean
  showSplitTrackAbove?: boolean
  smallMode?: boolean
  trackId: string
  trackPositionType: CareerLadderTrackPositionType
}

const JobDefinitionForTrack = ({
  activePositionTypeId,
  canDestroy,
  careerLadderId,
  editMode,
  trackId,
  trackPositionType,
  canSplitTrack = false,
  positionAsLink = false,
  positionType,
  showConnector = true,
  showSplitTrackAbove = false,
  smallMode = false,
}: JobDefinitionForTrackProps) => {
  const [mutate] = useCareerLaddersCreateSplitCareerLaddersTrackMutation()
  const [destroyTrackPositionType] = useCareerLaddersDestroyCareerLadderTrackPositionTypeMutation()
  const [updateTrackPositionType] = useCareerLaddersUpdateCareerLadderTrackPositionTypeMutation()
  const cardWrapperRef = useRef<HTMLDivElement>(null)

  const {
    setFalse: exitReplaceMode,
    setTrue: enterReplaceMode,
    value: inReplaceMode,
  } = useBoolean()
  useEffect(() => {
    if (!editMode) exitReplaceMode()
  }, [exitReplaceMode, editMode])

  useOnClickOutside(cardWrapperRef, () => {
    if (inReplaceMode) exitReplaceMode()
  })

  if (!positionType) return null

  const handleDestroyPress = () =>
    destroyTrackPositionType({
      careerLadderId,
      careerLadderTrackPositionTypeId: trackPositionType.uniqueKey ?? "",
    })
  const handleReplacePress: React.MouseEventHandler = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
    enterReplaceMode()
  }
  const handleSelectJobDefinition = (positionTypeNode: PositionTypesConnectionNode) =>
    updateTrackPositionType({
      careerLadderId,
      careerLadderTrackPositionTypeId: trackPositionType.uniqueKey ?? "",
      positionTypeId: positionTypeNode.uniqueKey ?? "",
    })

  const splitTrack = (splitFromTop: boolean) => {
    mutate({
      lhs: {
        careerLadderId,
        parentTrackId: trackId,
        positionTypeId: splitFromTop ? null : positionType.uniqueKey,
      },
      rhs: { careerLadderId, parentTrackId: trackId },
    })
  }

  return (
    <div key={positionType.id} className="justify-center flex">
      <div className="w-full flex-col items-center justify-center flex">
        {showSplitTrackAbove && (
          <PositionConnector
            canSplitTrack={canSplitTrack}
            editMode={editMode}
            onSplitTrack={() => splitTrack(true)}
            showLine={false}
          />
        )}
        <div
          className={classNames("w-full rounded-lg bg-white", {
            "min-w-[140px] max-w-[280px] sm:min-w-[240px]": !smallMode,
            "min-w-[120px] max-w-[160px]": smallMode,
          })}
          ref={cardWrapperRef}
        >
          {inReplaceMode && editMode ? (
            <PositionReplace onSelectJobDefinition={handleSelectJobDefinition} />
          ) : (
            <PositionCard
              activePositionTypeId={activePositionTypeId}
              canDestroy={canDestroy}
              editMode={editMode}
              onDestroyPress={handleDestroyPress}
              onReplacePress={handleReplacePress}
              positionAsLink={positionAsLink}
              positionType={positionType}
              smallMode={smallMode}
            />
          )}
        </div>
        {showConnector && (
          <PositionConnector
            canSplitTrack={canSplitTrack}
            editMode={editMode}
            onSplitTrack={() => splitTrack(false)}
          />
        )}
      </div>
    </div>
  )
}

type PositionCardProps = {
  activePositionTypeId?: string
  canDestroy: boolean
  editMode: boolean
  positionAsLink: boolean
  positionType: PositionType
  onDestroyPress: React.MouseEventHandler
  onReplacePress: React.MouseEventHandler
  smallMode: boolean
}

const PositionCard = ({
  activePositionTypeId,
  canDestroy,
  editMode,
  positionAsLink,
  positionType,
  onDestroyPress,
  onReplacePress,
  smallMode,
}: PositionCardProps) => (
  <PositionWrapper positionAsLink={positionAsLink} positionType={positionType}>
    <div
      className={classNames(
        "elevation relative m-0 box-border flex-col items-center justify-center gap-0 rounded-lg py-2 flex",
        {
          " sm:py-3": !smallMode,
          "border-2 border-solid border-primary-50 bg-primary-8":
            activePositionTypeId === positionType.id,
        },
      )}
    >
      {editMode ? (
        <TrackJobDefinitionMenu
          canDestroy={canDestroy}
          onDestroyPress={onDestroyPress}
          onReplacePress={onReplacePress}
          viewHref={UrlHelper.positionTypePath(positionType.id)}
        />
      ) : null}
      {smallMode && <PositionTitle positionType={positionType} smallMode={smallMode} />}
      {!smallMode && positionType.jobCode && (
        <>
          <PositionTitle positionType={positionType} smallMode={smallMode} />
          <div className="ellipsis-text max-w-6 text-xs hidden sm:max-w-12 sm:text-sm sm:block">
            {positionType.jobCode}
          </div>
        </>
      )}
      {!smallMode && !positionType.jobCode && (
        <div className="py-0 sm:py-2">
          <PositionTitle positionType={positionType} smallMode={smallMode} />
        </div>
      )}
    </div>
  </PositionWrapper>
)

const PositionReplace = ({
  onSelectJobDefinition,
}: {
  onSelectJobDefinition: (selected: PositionTypesConnectionNode) => void
}) => {
  const hackySwallowClick: React.MouseEventHandler = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
  }

  return (
    <StyledJobDefinitionInput
      isFocused
      onPointerDown={hackySwallowClick}
      onSelectJobDefinition={onSelectJobDefinition}
    />
  )
}

const PositionTitle = ({
  positionType,
  smallMode,
}: {
  positionType: PositionType
  smallMode: boolean
}) => (
  <div
    className={classNames("ellipsis-text", {
      "max-w-6 text-xs sm:max-w-12 sm:text-sm-bold": !smallMode,
      "max-w-6 text-xs": smallMode,
    })}
  >
    {positionType.title}
  </div>
)

const PositionWrapper = ({
  children,
  positionAsLink,
  positionType,
}: {
  children: ReactNode
  positionType: PositionType
  positionAsLink: boolean
}) =>
  positionAsLink ? (
    <a
      href={UrlHelper.careerDetailsPath(positionType.id)}
      className="hover:border--main-hover active:border--main-hover cursor-pointer rounded-lg bg-white text-neutral-100 !no-underline transition-all duration-200 ease-out active:bg-neutral-3"
    >
      {children}
    </a>
  ) : (
    <div className="bg-white">{children}</div>
  )

export { JobDefinitionForTrack }
