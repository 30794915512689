import React from "react"
import { Person } from "types/graphql"

interface Props {
  person: Person
}

function PersonTitle({ person }: Props) {
  return (
    person.primaryPosition?.title && (
      <p className="person-title m-0 text-sm text-neutral-64">{person.primaryPosition.title}</p>
    )
  )
}

export { PersonTitle }
