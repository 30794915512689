import React from "react"

import PageNav from "v2/react/components/navigation/PageNav"
import { TitleBlock } from "v2/react/components/navigation/PageNav/TitleBlock"
import { ActionBlock } from "v2/react/components/navigation/PageNav/ActionBlock"
import { ExportButton } from "v2/react/shared/ExportButton"

interface Props {
  title: string
  exportUrl?: string | null
}

function AssetsNav({ title, exportUrl }: Props) {
  return (
    <PageNav>
      <TitleBlock>
        <h1>{title}</h1>
      </TitleBlock>
      <ActionBlock>
        {exportUrl && <ExportButton exportUrl={exportUrl} useCurrentSearchParamsWithExportUrl />}
      </ActionBlock>
    </PageNav>
  )
}

export { AssetsNav }
