import {
  width as originalWidth,
  svgPathData,
} from "@fortawesome/pro-regular-svg-icons/faInfoCircle"

export const dimensions = {
  width: 14,
  height: 14,
}

const scale = dimensions.width / originalWidth

// Define an SVG icon that is used next to the children count in a node stats
// bars (bottom of the card)
const defineInfoCirleIcon = (svg: d3.Selection<SVGElement>): d3.Selection<SVGElement> => {
  svg
    .select("defs")
    .append("path")
    .attr("id", "info-circle-icon")
    .attr("d", svgPathData)
    .attr("viewBox", `0 0 ${dimensions.width} ${dimensions.height}`)
    .attr("transform", `scale(${scale})`)
    .attr("fill", "rgba(12, 20, 75, 0.64)") // Set fill for dark text
  svg
    .select("defs")
    .append("path")
    .attr("id", "info-circle-icon-light")
    .attr("d", svgPathData)
    .attr("viewBox", `0 0 ${dimensions.width} ${dimensions.height}`)
    .attr("transform", `scale(${scale})`)
    .attr("fill", "rgba(255, 255, 255, 0.64)") // Set fill for light text

  return svg
}

export default defineInfoCirleIcon
