/* eslint-disable @typescript-eslint/no-unused-vars */ // TODO: remove this after connecting to the backend
// @ts-ignore
import { RowData } from "@tanstack/react-table" // eslint-disable-line import/no-unresolved
import { Ref } from "react"
import { Option, Maybe, AllowedAttributeTypeEnum } from "types/graphql.d"
import type { Direction } from "v2/react/utils/enums"

export enum FieldType {
  Standard = "Standard",
  SuggestedAutocomplete = "SuggestedAutocomplete",
  ForcedAutocomplete = "ForcedAutocomplete",
  SelectDropdown = "SelectDropdown",
  DatePicker = "DatePicker",
  NonEditable = "NonEditable",
}

export type Column<TRow> = {
  id: string
  label: string
  enableSorting?: boolean
  enableFiltering?: boolean
  enableGrouping?: boolean
  hidden?: boolean
  options?: Option[] | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accessorFn?: (row: TRow) => any
  editableFn?: (row: TRow) => boolean
  width: "small" | "medium" | "large"
  type?: AllowedAttributeTypeEnum | null
  fieldType?: FieldType | null
  info?: string
}

declare module "@tanstack/table-core" {
  interface ColumnMeta<TData extends RowData, TValue> {
    original: Column<TData>
  }
}
export type SaveFn<T = string> = (
  value?: Maybe<T>,
) => Promise<{ ok: true } | { ok: false; error: { message: string } }>

export type CellInput = {
  blur?: () => void
  focus?: (initial?: string) => void
  getValue?: () => string
}

export type CursorSaveOptions =
  | { moveAfterTo: Direction }
  | { moveAfterByEvent: KeyboardEvent }
  | { transitionKeyCanMove: boolean }

export type CursorConnection = {
  /**
   * Optional ref object providing functions to control the cell. When defined,
   * the cursor can use it to implement common behavior.
   */
  cellInputRef: Ref<CellInput>

  /**
   * Requests the cursor focus the cell (handy for handling an event like
   * onClick). This supports calls regardless of whether the cursor is on the
   * calling cell.
   */
  requestCursorTransition: () => void

  /**
   * Primarily used to prevent the default behavior of a key depending on the
   * cursor state (i.e., prevent arrow key defaults if they should submit a
   * write).
   */
  keyDownListenerWhileWriting: (event: KeyboardEvent | React.KeyboardEvent) => {
    handled: boolean
    event: KeyboardEvent | React.KeyboardEvent
  }

  /**
   * Submits the form and moves the cursor given a matching keyboard event.
   * A cell can opt out of using this when it needs more flexibility.
   */
  keyUpListenerWhileWriting: (event: KeyboardEvent | React.KeyboardEvent) => {
    handled: boolean
    event: KeyboardEvent | React.KeyboardEvent
  }

  /**
   * Indicates whether the cursor is on the cell (regardless of state).
   */
  inCursor: boolean

  /**
   * Indicates whether the cursor is "writing" changes to the cell.
   */
  inWrite: boolean

  /**
   * Set when the cursor is "writing" changes to the cell, and the write began
   * with an initial value.
   */
  initialWriteValue: string | undefined

  /**
   * Stop writing changes to the cell. Omitting cursor options will leave the
   * cursor on the cell and a transition keypress will start writing again.
   * This does not save any changes that have been made.
   */
  stopWriting: (cursorOptions?: CursorSaveOptions) => void

  /**
   * Begins the update process using the given value. If the commit was
   * triggered by a keyboard event, it can be optionally provided in order to
   * move the cursor to a new cell.
   */
  saveWrite: (value: string, options?: CursorSaveOptions) => void
}
