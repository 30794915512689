import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import {
  SuccessionMatrixSettings,
  UpdateCompanySuccessionMatrixSettingsInputObject,
} from "types/graphql.d"
import { MatrixGridItem } from "v2/react/components/successionSettings/MatrixGridItem"
import { SelectDropdown } from "v2/react/components/successionSettings/SelectDropdown"
import { basePalettes } from "v2/react/components/successionSettings/palettes"
import { InputErrorBanner } from "v2/react/shared/Inputs/InputErrorBanner"
import { Modal } from "v2/react/shared/Modal"
import { SaveFooter } from "v2/react/shared/Modal/SaveFooter"
import { useSaveSuccessionMatrixSettingsMutation } from "v2/redux/GraphqlApi"
import {
  CardType,
  GridItem,
  GridItemSchema,
  PaletteSchema,
  PaletteType,
  setCardType,
  setGridItems,
  setPalette,
  updateGridItemColor,
  updateGridItemLabel,
  updatePalette,
} from "v2/redux/slices/MatrixSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface SelectOption {
  headerDisplay: React.ReactNode
  fieldDisplay: React.ReactNode
  text: CardType
}
interface PaletteSelection {
  headerDisplay: React.ReactNode
  fieldDisplay: React.ReactNode
  palette: string[]
  text: PaletteType
}

interface Props {
  isOpen: boolean
  onClose: () => void
  matrix: SuccessionMatrixSettings
}

function SuccessionMatrixModal({ isOpen, onClose, matrix }: Props) {
  const dispatch = useAppDispatch()
  const [saveMatrixSettings, { isLoading }] = useSaveSuccessionMatrixSettingsMutation()
  const [errors, setErrors] = useState<string[]>([])
  const labelErrors = useAppSelector((state) => state.matrix.labelErrors)
  const cardOptions = useAppSelector((state) => state.matrix.cardOptions)
  const viewablePaletteOptions = useAppSelector((state) => state.matrix.viewablePaletteOptions)

  const gridItems = useAppSelector((state) => state.matrix.gridItems)
  const chosenCardStyle: CardType = useAppSelector((state) => state.matrix.cardType)
  const chosenPaletteStyle: PaletteType = useAppSelector((state) => state.matrix.paletteType)

  const changeCardStyle = (selected: SelectOption) => dispatch(setCardType(selected.text))

  const changePaletteStyle = (selected: PaletteSelection) => {
    if (selected.text === chosenPaletteStyle) return
    dispatch(updatePalette(selected.text))
  }

  const changeGridItemColor = (gridItem: GridItem, newColor: string) =>
    dispatch(updateGridItemColor({ gridItem, newColor }))

  const changeGridItemLabel = (gridItem: GridItem, newLabel: string) =>
    dispatch(updateGridItemLabel({ gridItem, newLabel }))

  const handleClose = () => {
    const initGridItems: GridItem[] = GridItemSchema.array().parse(matrix.gridItems)
    const initPaletteType: PaletteType = PaletteSchema.parse(matrix.paletteType)
    setErrors([])

    dispatch(setGridItems(initGridItems))
    dispatch(setPalette(initPaletteType))
    onClose()
  }

  const handleSave = async () => {
    if (labelErrors) {
      setErrors(["label_required".t("succession_plan")])
      return
    }
    setErrors([])

    const settings: UpdateCompanySuccessionMatrixSettingsInputObject = {
      cardType: chosenCardStyle,
      paletteType: chosenPaletteStyle,
      gridItems,
    }

    const result = await saveMatrixSettings({ input: { settings } }).unwrap()
    if (!result.updateCompanySuccessionMatrixSettings?.errors.length) {
      onClose()
    } else {
      const errors = (result.updateCompanySuccessionMatrixSettings?.errors || []).map(
        (s: Error) => s.message,
      )
      setErrors(errors)
    }
  }

  const dropdownPaletteOptions =
    chosenPaletteStyle === "palette_custom"
      ? [...viewablePaletteOptions, chosenPaletteStyle]
      : viewablePaletteOptions

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="xl"
      title={"matrix_settings".t("succession_plan")}
    >
      <div className="matrix-settings p-6">
        <AnimatePresence>
          {errors.length > 0 && (
            <motion.div
              key="error-banner"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: {
                  opacity: 1,
                  height: "auto",
                  transition: { duration: 0.2, opacity: { delay: 0.1 } },
                },
                collapsed: {
                  opacity: 0,
                  height: 0,
                  transition: { duration: 0.2, height: { delay: 0.1 } },
                },
              }}
            >
              <InputErrorBanner errors={errors} />
            </motion.div>
          )}
        </AnimatePresence>
        <div className="mb-2 grid-cols-2 items-center gap-2 grid">
          <span>{"configure_matrix".t("succession_plan")}</span>
          <div className="matrix-settings__fields flex">
            <SelectDropdown
              options={generateCardTypeOptions(cardOptions)}
              initialOption={generateCardTypeOption(chosenCardStyle)}
              onSelection={changeCardStyle}
            />
            {chosenCardStyle !== "color_none" && (
              <SelectDropdown
                options={generatePaletteOptions(dropdownPaletteOptions, gridItems)}
                initialOption={generatePaletteOption(chosenPaletteStyle, gridItems)}
                onSelection={changePaletteStyle}
              />
            )}
          </div>
        </div>
        <div className="matrix-height grid-cols-3 gap-4 grid">
          {gridItems.map((box) => (
            <MatrixGridItem
              key={box.id}
              item={box}
              cardStyle={chosenCardStyle}
              onUpdateItemLabel={changeGridItemLabel}
              onUpdateItemColor={changeGridItemColor}
            />
          ))}
        </div>
      </div>
      <SaveFooter isSaving={isLoading} onSave={handleSave} onCancel={handleClose} />
    </Modal>
  )
}

export { SuccessionMatrixModal }

const generateMiniMatrix = (palette: string[], text: string, isField: boolean) => (
  <div className="items-center flex">
    <div className="mr-2 h-6 w-8 grid-cols-3 gap-[1px] grid">
      {palette.map((color, index) => (
        <div
          className="rounded-sm bg-neutral-8"
          style={{ backgroundColor: color }}
          // eslint-disable-next-line react/no-array-index-key
          key={`${color}-${index}`}
        />
      ))}
    </div>
    <span className={isField ? "font-bold" : ""}>{text.t("succession_plan")}</span>
  </div>
)

const generatePaletteOption = (option: PaletteType, gridItems: GridItem[]): PaletteSelection => {
  const palette = basePalettes[option].length
    ? basePalettes[option]
    : gridItems.map((item) => item.color)

  return {
    palette,
    text: option,
    fieldDisplay: generateMiniMatrix(palette, option, true),
    headerDisplay: generateMiniMatrix(palette, option, false),
  }
}

const generatePaletteOptions = (
  options: PaletteType[],
  gridItems: GridItem[],
): PaletteSelection[] => options.map((option) => generatePaletteOption(option, gridItems))

const generateMiniCard = (option: string) => (
  <div className="items-center flex">
    <div className={`matrix-card-type matrix-card-type__${option} mr-2`} />
    <p>{option.t("succession_plan")}</p>
  </div>
)

const generateCardTypeOption = (option: CardType) => ({
  text: option,
  fieldDisplay: generateMiniCard(option),
  headerDisplay: generateMiniCard(option),
})

const generateCardTypeOptions = (options: CardType[]) => options.map(generateCardTypeOption)
