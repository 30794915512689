import React from "react"
import { FeatureFlags } from "types/graphql"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { ProfilePanelAbilities } from "v2/react/components/orgChart/ProfilePanel/ProfilePanel"
import { useSortedBoxes } from "v2/react/components/orgChart/ProfilePanel/useSortedBoxes"
import { AccordionModulePanel } from "v2/react/shared/AccordionModulePanel"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"
import { useAppSelector } from "v2/redux/store"

interface JobDescriptionProps {
  abilities: ProfilePanelAbilities
  featureFlags: FeatureFlags
}

function JobDescription({ abilities, featureFlags }: JobDescriptionProps) {
  const { currentBox, toggleBox } = useSortedBoxes("job_description", "position")
  const position = useAppSelector((state) => state.profilePanel.position)

  if (!currentBox) return null
  if (!featureFlags.positionManagement) return null

  const headerContent = <div className="title">{"job_description".t("org_chart")}</div>

  return abilities.canReadSensitivePositionData && position?.description ? (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      <ModuleListItem>
        <div>{sanitizeParse(position.description)}</div>
      </ModuleListItem>
    </AccordionModulePanel>
  ) : null
}

export { JobDescription }
