import React from "react"
// @ts-ignore
import DatePicker from "react-pikaday-datepicker"
import { EliminateHeadcountPlanPositionInput } from "types/graphql.d"
import { Modal } from "v2/react/shared/Modal"
import { errorFor } from "v2/react/utils/errors"
import { useEliminateHeadcountPlanPositionMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSelectedDate } from "v2/react/hooks/useSelectedDate"
import { HeadcountPlanDatasheetRow } from "./types"

interface Props {
  initialEndDateValue?: string
  row: HeadcountPlanDatasheetRow
  headcountPlanId: string
  isOpen: boolean
  onClose: () => void
  participantId?: string
}

export function EliminatePositionModal({
  initialEndDateValue,
  row,
  headcountPlanId,
  isOpen,
  onClose,
  participantId,
}: Props) {
  const [mutate, mutation] = useEliminateHeadcountPlanPositionMutation()
  const [endDate, setEndDate] = useSelectedDate(initialEndDateValue || "")
  const [eliminateDescendingPositions, setEliminateDescendingPositions] = React.useState(false)

  const errorMessage = errorFor(
    "endDateForPlanner",
    mutation.data?.eliminateHeadcountPlanPosition?.errors,
  )

  const endDateErrorMessage = errorFor(
    "endDate",
    mutation.data?.eliminateHeadcountPlanPosition?.errors,
  )

  const handleSubmit = () => {
    const input: EliminateHeadcountPlanPositionInput = {
      eliminateDescendingPositions,
      endDate: typeof endDate === "string" ? "invalid date" : endDate.toISOString().split("T")[0],
      headcountPlanId,
      headcountPlanParticipantId: participantId,
      positionId: row.action === null ? row.position?.id : null,
      revisionNumber: row.action === null ? 0 : row.revisionNumber + 1,
      rootEventId: row.rootEventId,
    }

    const doEliminatePosition = async () => {
      const result = await mutate({ input }).unwrap()
      if (result.eliminateHeadcountPlanPosition?.success) {
        onClose()
      }
    }

    doEliminatePosition()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={"eliminate_position".t("headcount_plan")}
      footer={
        mutation.isLoading || !isOpen ? (
          <button className="btn--large btn--critical btn-disabled" type="button">
            {"eliminate_position_pending".t("headcount_plan")}
          </button>
        ) : (
          <button className="btn--large btn--critical" onClick={handleSubmit} type="button">
            {"eliminate_position".t("headcount_plan")}
          </button>
        )
      }
    >
      <div className="p-6">
        {errorMessage && (
          <div className="alert alert-critical mb-4">
            <span>{errorMessage}</span>
          </div>
        )}
        <div className={classNames({ "form-error": endDateErrorMessage })}>
          <label htmlFor="endDate">{"position_end_date".t("headcount_plan")}</label>
          <p className="form-error-message">{endDateErrorMessage}</p>
        </div>
        <DatePicker
          id="endDate"
          name="endDate"
          value={typeof endDate === "string" ? undefined : endDate}
          onChange={(value: Date) => setEndDate(value)}
          format="YYYY-MM-DD"
          autocomplete="off"
          placeholder="YYYY-MM-DD"
          className={classNames("w-full border border-solid p-2", {
            "border-status-critical": endDateErrorMessage,
            "border-neutral-8": !endDateErrorMessage,
          })}
        />
        {typeof row.position?.childrenCount === "number" && row.position.childrenCount > 0 ? (
          <>
            <div className="py-4">
              <label htmlFor="endDate">{"eliminate_what_should_happen".t("headcount_plan")}</label>
            </div>
            <div className="gap-8 flex">
              <div className="flex-1">
                <button
                  type="button"
                  className={classNames("module selector-module p-0 focus:border-primary-100", {
                    selected: !eliminateDescendingPositions,
                  })}
                  onClick={() => setEliminateDescendingPositions(false)}
                >
                  <div className="module-body text-center">
                    <img src={window.App.Helpers.imagePath("delete-and-move.png")} alt="" />
                    <hr />
                    <p className="text-center">{"eliminate_move".t("headcount_plan")}</p>
                  </div>
                  <div className="check">
                    <FontAwesomeIcon icon={["far", "check"]} />
                  </div>
                </button>
              </div>
              <div className="flex-1">
                <button
                  type="button"
                  className={classNames("module selector-module p-0 focus:border-primary-100", {
                    selected: eliminateDescendingPositions,
                  })}
                  onClick={() => setEliminateDescendingPositions(true)}
                >
                  <div className="module-body text-center">
                    <img src={window.App.Helpers.imagePath("delete-all.png")} alt="" />
                    <hr />
                    <p className="text-center">{"eliminate_all".t("headcount_plan")}</p>
                  </div>
                  <div className="check">
                    <FontAwesomeIcon icon={["far", "check"]} />
                  </div>
                </button>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Modal>
  )
}
