import React from "react"

import type { OrgUnit, OrgUnitType } from "types/graphql"

import { errorHash } from "v2/react/utils/errors"
import { Modal } from "v2/react/shared/Modal"
import { SaveFooter } from "v2/react/shared/Modal/SaveFooter"

import { AddOrgUnitFieldset, FieldsetFooterProps } from "./AddOrgUnitFieldset"
import { useAddOrgUnitMutationWithHandling } from "./hooks/useAddOrgUnitMutationWithHandling"

type AddOrgUnitModalProps = {
  initialData?: { code?: string; name?: string }
  isOpen?: boolean
  onClose: () => void
  onSave?: (orgUnit: Pick<OrgUnit, "id" | "name" | "code" | "org_unit_type_id">) => void
  orgUnitType: OrgUnitType
  saving?: boolean
}

function AddOrgUnitModal({
  isOpen = true,
  initialData,
  onClose,
  orgUnitType,
  onSave,
  saving,
}: AddOrgUnitModalProps) {
  const { generalError, handleSave, state } = useAddOrgUnitMutationWithHandling(onSave)
  const renderFooter = ({ onSavePress, saving }: FieldsetFooterProps) => (
    <SaveFooter onSave={onSavePress} isSaving={saving} />
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      shouldReturnFocusAfterClose={false}
      size="md"
      title={"Add %s".t("defaults", null, null, null, [orgUnitType.name])}
    >
      <form>
        <AddOrgUnitFieldset
          className="mt-3"
          errors={errorHash(state.data?.addOrgUnit?.errors)}
          fieldsContainerClassName="px-6"
          generalError={generalError}
          initialData={initialData}
          onCancel={onClose}
          onSave={handleSave}
          orgUnitTypeId={orgUnitType.id}
          renderFooter={renderFooter}
          saving={saving || state.isLoading}
          translations={{
            codeLabel: "Code Label".t("org_units", null, null, null, [orgUnitType.name]),
            nameLabel: "Name Label".t("org_units", null, null, null, [orgUnitType.name]),
            save: "Save".t("defaults"),
            saving: "Saving".t("defaults"),
            cancel: "Cancel".t("defaults"),
          }}
        />
      </form>
    </Modal>
  )
}

export { AddOrgUnitModal, AddOrgUnitModalProps }
