import React from "react"
import { ChangeHandler, RefCallBack } from "react-hook-form"
import { Option } from "types/graphql"
import { FormSelect } from "v2/react/shared/FormSelect"

function ExternalLocationIdField({
  show = true,
  selectRef,
  onChange,
  options,
}: {
  show?: boolean
  selectRef: RefCallBack
  onChange: ChangeHandler
  options: Option[]
}) {
  if (!show) {
    return null
  }
  return (
    <div className="mb-4 cursor-default">
      <FormSelect
        label={"field_location".t("ats")}
        selectRef={selectRef}
        onSelect={onChange}
        options={options}
      />
    </div>
  )
}

export { ExternalLocationIdField }
