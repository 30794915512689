import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { SuccessionMatrixModal } from "v2/react/components/successionSettings/SuccessionMatrixModal"
import { generateConditionalColors } from "v2/react/components/successionSettings/matrixUtils"
import { useGetSuccessionMatrixSettingsQuery } from "v2/redux/GraphqlApi"
import { StoreProvider } from "v2/redux/StoreProvider"
import {
  CardType,
  CardTypeSchema,
  GridItem,
  GridItemSchema,
  PaletteSchema,
  setCardType,
  setGridItems,
  setPalette,
} from "v2/redux/slices/MatrixSlice"
import { useAppDispatch } from "v2/redux/store"

function SuccessionMatrixSettingsInner() {
  const dispatch = useAppDispatch()
  const { data: matrix } = useGetSuccessionMatrixSettingsQuery({})
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)

  useEffect(() => {
    if (matrix) {
      dispatch(setCardType(CardTypeSchema.parse(matrix.cardType)))
      dispatch(setPalette(PaletteSchema.parse(matrix.paletteType)))
      dispatch(setGridItems(GridItemSchema.array().parse(matrix.gridItems)))
    }
  }, [matrix, dispatch])

  if (!matrix) return null

  const handleOpenModal = () => setEditModalIsOpen(true)
  const handleCloseModal = () => setEditModalIsOpen(false)

  const mediumMatrix = generateMediumMatrix(
    GridItemSchema.array().parse(matrix.gridItems),
    CardTypeSchema.parse(matrix.cardType),
  )

  return (
    <>
      <div className="module-card">
        <div className="module-card__header items-center justify-between flex">
          <div className="text-base-bold">{"matrix_settings".t("succession_plan")}</div>
          <button className="btn--sm btn--secondary" onClick={handleOpenModal} type="button">
            {"Edit".t("defaults")}
          </button>
        </div>
        <div className="module-card__body">{mediumMatrix}</div>
      </div>
      <SuccessionMatrixModal isOpen={editModalIsOpen} onClose={handleCloseModal} matrix={matrix} />
    </>
  )
}

function SuccessionMatrixSettings() {
  return (
    <StoreProvider>
      <SuccessionMatrixSettingsInner />
    </StoreProvider>
  )
}

export default SuccessionMatrixSettings

const generateMediumMatrix = (gridItems: GridItem[], cardType: CardType) => (
  <div className="relative grid-cols-3 gap-2 grid">
    {gridItems.map((item, index) => (
      <div
        className="matrix-medium__grid-item"
        style={generateConditionalColors(cardType, item.color)}
        // eslint-disable-next-line react/no-array-index-key
        key={`${item.color}-${index}`}
      >
        <div
          className={classNames("matrix-medium__grid-item-header text-base", {
            opaque: cardType !== "color_edge",
          })}
        >
          <p>{item.label}</p>
        </div>
      </div>
    ))}
  </div>
)
