import dayjs from "dayjs"
import React from "react"
import { Chart, ChartNote } from "types/graphql.d"
import { createdAtDesc } from "v2/react/utils/sorts"
import { selectCurrentUser } from "v2/redux/slices/ApprovalSlice/approvalSelectors"
import { useAppSelector } from "v2/redux/store"
import { currentUserIsNextApprover } from "v2/react/shared/Status/statusUtils"

interface Props {
  chart: Chart
}

function SubmissionNote({ chart }: Props) {
  const currentUser = useAppSelector(selectCurrentUser)
  const submitterNote: ChartNote | null = mostRecentNote(chart)

  if (!currentUser) return null

  const isCurrentApprover = currentUserIsNextApprover(
    chart?.chartApprovers || [],
    currentUser.currentPersonId,
  )

  return (
    <>
      <hr className="mx-[-1rem] my-4" />
      <div className={!isCurrentApprover ? "mb-4 sm:mb-0" : ""}>
        <p className="margin-0 text-base-bold">{"request_details".t("org_chart")}</p>
        {chart.approvalRequestedAt && (
          <p>
            {"requested_approval".t("org_chart", null, null, null, [
              chart.submitterName,
              dayjs(chart.approvalRequestedAt).format("MM/DD/YY"),
            ])}
          </p>
        )}
        {submitterNote && submitterNote.body !== "" && (
          <p className="mt-1 whitespace-normal bg-primary-3 p-2">{submitterNote.body}</p>
        )}
      </div>
    </>
  )
}

export { SubmissionNote }

const mostRecentNote = (chart?: Chart): ChartNote | null => {
  if (!chart) return null
  const mostRecentNote = (chart?.chartNotes || [])
    .slice()
    .sort(createdAtDesc)
    .filter((note) => note && note.status === "request_approval")

  return mostRecentNote && mostRecentNote.length > 0 ? mostRecentNote[0] : null
}
