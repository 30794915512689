import classNames from "classnames"
import React, { useRef } from "react"
import tinycolor from "tinycolor2"
import { selectColorCodingConfigForActiveTab } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useAppSelector } from "v2/redux/store"

interface ColorCodeProps {
  color: string
  isActive: boolean
  name: string
  editColor: (domPosition: { top: number; left: number }, color: string) => void
  style: React.CSSProperties
}

function ColorCode({ color, isActive, name, editColor, style }: ColorCodeProps) {
  const { isEnabled } = useAppSelector(selectColorCodingConfigForActiveTab)
  const divRef = useRef<HTMLButtonElement>(null)
  const handleClick: React.MouseEventHandler = (ev) => {
    if (!isEnabled) return
    if (ev && ev.nativeEvent && ev.nativeEvent.stopImmediatePropagation) {
      ev.nativeEvent.stopImmediatePropagation()
    }

    const ref = divRef.current
    if (!ref) return

    const top = ref.getBoundingClientRect().top
    const position = { top, left: ref.clientWidth }
    editColor(position, color)
  }

  return (
    <button
      type="button"
      ref={divRef}
      className={classNames(
        "color-coding-row relative m-0 items-center gap-2 rounded bg-transparent px-1 py-0.5 flex hover:bg-neutral-8",
        { "bg-neutral-8": isActive, "cursor-auto hover:bg-transparent": !isActive },
      )}
      onClick={handleClick}
      style={style}
      aria-label={name}
    >
      <div
        className="color-box border--main h-4 w-4 rounded"
        style={{ backgroundColor: color, border: `1px solid ${tinycolor(color).darken(10)}` }}
      />
      <span
        className={classNames("color-coding-color-label text-base", {
          "cursor-auto": !isActive,
        })}
      >
        {name}
      </span>
    </button>
  )
}

export { ColorCode, ColorCodeProps }
