import React, { ReactNode } from "react"
import { ActionBlock, ActionBlockLarge } from "v2/react/components/navigation/PageNav/ActionBlock"
import {
  LinkBlock,
  LinkBlockLarge,
  LinkBlockSmall,
  LinkBlockXSmall,
} from "v2/react/components/navigation/PageNav/LinkBlock"
import {
  TitleBlock,
  TitleBlockLarge,
  TitleBlockSmall,
} from "v2/react/components/navigation/PageNav/TitleBlock"

function Navigation() {
  return (
    <div>
      <section>
        <h1>Navigation</h1>
        <h2>General Terminology</h2>
        <p>The navigation system involves a few different components:</p>
        <ul className="pt-2">
          <li>The &quot;Left Nav&quot; is how we refer to the primary left side navigation.</li>
          <li>
            The &quot;Page Nav&quot; is what we call the the secondary navigation on the top of each
            page.
          </li>
          <li>A &quot;Utility Nav&quot; appears under the PageNav on some pages.</li>
        </ul>

        <h2>Page Navigation</h2>
        <h3>Basic Pages without Tabs</h3>
        <ul className="pt-2">
          <li>
            The Page Nav can be basic with no tabs, e.g. on the Calendar Page. The{" "}
            <code>BasicPageNav</code> is available for these cases.
          </li>
          <li>
            If a subpage exists under a page/tab, the Page Nav will not utilize tabs. It may have a
            title section that includes a link back to the parent page. Accordingly one can use the{" "}
            <code>BasicPageNav</code> or <code>BasicPageNavWithLinkBack</code> component.
          </li>
        </ul>
        <h3>Pages with Tabs</h3>
        <ul className="pt-2">
          <li>
            A Page Nav with tabs is used when a page has multiple subpages, e.g. Position Management
            Page. Each page uses the <code>PageNav</code> component, and defines the child blocks
            needed within the PageNav. E.g. see the <code>ReportsNav</code>,{" "}
            <code>PositionsNav</code>, etc. components that define each specific variation of the
            PageNav.
          </li>
          <li>
            Note that the sizing differences becomes most apparent between 640px and 1025px screen
            widths. These differences can be managed using the variations for each block:
            <ul className="py-2 pl-4">
              <li>
                Title blocks comes in 3 different variations: <code>TitleBlock</code>,{" "}
                <code>TitleBlockSmall</code> and <code>TitleBlockLarge</code>
              </li>
              <li>
                Link blocks comes in 3 different variations: <code>LinkBlock</code>,{" "}
                <code>LinkBlockSmall</code> and <code>LinkBlockLarge</code>
              </li>
              <li>
                Action blocks come in 1 variation: <code>ActionBlock</code>
              </li>
            </ul>
          </li>
        </ul>

        <div className="flex-col gap-4 flex">
          <div className="page-nav relative">
            <TitleBlock>
              <ContentWrapper>Title</ContentWrapper>
            </TitleBlock>
          </div>
          <div className="page-nav relative">
            <TitleBlockSmall>
              <ContentWrapper>Title Small</ContentWrapper>
            </TitleBlockSmall>
          </div>
          <div className="page-nav relative">
            <TitleBlockLarge>
              <ContentWrapper>Title Large</ContentWrapper>
            </TitleBlockLarge>
          </div>

          <div className="page-nav relative">
            <LinkBlock>
              <ContentWrapper>Link</ContentWrapper>
            </LinkBlock>
          </div>
          <div className="page-bar relative">
            <LinkBlockXSmall>
              <ContentWrapper>Link XSmall</ContentWrapper>
            </LinkBlockXSmall>
          </div>
          <div className="page-bar relative">
            <LinkBlockSmall>
              <ContentWrapper>Link Small</ContentWrapper>
            </LinkBlockSmall>
          </div>
          <div className="page-nav relative">
            <LinkBlockLarge>
              <ContentWrapper>Link Large</ContentWrapper>
            </LinkBlockLarge>
          </div>

          <div className="page-nav relative">
            <ActionBlock>
              <ContentWrapper>Action</ContentWrapper>
            </ActionBlock>
          </div>
          <div className="page-bar relative">
            <ActionBlockLarge>
              <ContentWrapper>Action Large</ContentWrapper>
            </ActionBlockLarge>
          </div>
        </div>
      </section>

      <section>
        <div className="page-nav relative gap-x-1">
          <TitleBlock>
            <ContentWrapper>Title</ContentWrapper>
          </TitleBlock>
          <LinkBlock>
            <ContentWrapper>Link</ContentWrapper>
          </LinkBlock>
          <ActionBlock>
            <ContentWrapper>Action</ContentWrapper>
          </ActionBlock>
        </div>
      </section>

      <section>
        <div className="page-nav relative gap-x-1">
          <TitleBlockLarge>
            <ContentWrapper>Title Large</ContentWrapper>
          </TitleBlockLarge>
          <LinkBlockSmall>
            <ContentWrapper>Link Small</ContentWrapper>
          </LinkBlockSmall>
          <ActionBlock>
            <ContentWrapper>Action</ContentWrapper>
          </ActionBlock>
        </div>
      </section>

      <section>
        <div className="page-nav relative gap-x-1">
          <TitleBlockSmall>
            <ContentWrapper>Title Small</ContentWrapper>
          </TitleBlockSmall>
          <LinkBlockLarge>
            <ContentWrapper>Link Large</ContentWrapper>
          </LinkBlockLarge>
        </div>
      </section>

      <section>
        <div className="page-bar relative gap-x-1">
          <TitleBlockLarge>
            <ContentWrapper>Title Large</ContentWrapper>
          </TitleBlockLarge>
          <LinkBlockXSmall>
            <ContentWrapper>Link XSmall</ContentWrapper>
          </LinkBlockXSmall>
          <ActionBlockLarge>
            <ContentWrapper>Action Large</ContentWrapper>
          </ActionBlockLarge>
        </div>
      </section>
    </div>
  )
}

export default Navigation

const ContentWrapper = ({ children }: { children: ReactNode }) => (
  <div className="h-full w-full place-content-center bg-status-excite-light text-center grid">
    {children}
  </div>
)
