import fp from "lodash/fp"
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"

import {
  RemoveNodeAction,
  EndFieldTransitionAction,
  MarkNodeIdForDeletionAction,
  NodeChangeset,
  NodeChangesetLoadedAction,
  NodeErrorSet,
  NodeState,
  NodeUpdateBeginAction,
  NodeUpdateFailAction,
  StartFieldTransitionAction,
} from "v2/redux/slices/NodeSlice/types"
import { RootState } from "v2/redux/store"

import {
  clearFieldErrors,
  makeNextNodeErrorSet,
} from "v2/redux/slices/NodeSlice/nodeHelpers/nodeErrorSets"
import { NodeApi } from "v2/redux/slices/NodeSlice/NodeApi"

const changesAdapter = createEntityAdapter<NodeChangeset>({
  selectId: (model) => model.nodeId,
})
// (will be in use soon)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const internalChangeSelectors = changesAdapter.getSelectors((state: NodeState) => state.changes)
const errorsAdapter = createEntityAdapter<NodeErrorSet>({
  selectId: (model) => model.nodeId,
})
const internalErrorSelectors = errorsAdapter.getSelectors((state: NodeState) => state.errors)

const InitialState: NodeState = {
  changes: changesAdapter.getInitialState(),
  errors: errorsAdapter.getInitialState(),
  idsMarkedForDeletion: [],
  pageInfoHistory: [],
  pageInfo: {
    endCursor: null,
    startCursor: null,
    hasNextPage: false,
    hasPreviousPage: false,
  },
  transitions: {},
}

const NodeSlice = createSlice({
  name: "node",
  initialState: InitialState,
  reducers: {
    removeNode: (state, { payload: id }: RemoveNodeAction) => {
      const markedIdIndex = fp.findIndex(fp.eq(id), state.idsMarkedForDeletion)
      if (markedIdIndex >= 0) state.idsMarkedForDeletion.splice(markedIdIndex, 1)
      // Relies on Immer here given the usage of entity adapters. Mutation is
      // ok since it's on a proxy and does not truly mutate state.
      delete state.transitions[id] // eslint-disable-line no-param-reassign
      changesAdapter.removeOne(state.changes, id)
      errorsAdapter.removeOne(state.errors, id)
    },

    markNodeIdForDeletion: (state, { payload: id }: MarkNodeIdForDeletionAction) =>
      fp.set("idsMarkedForDeletion", fp.union(state.idsMarkedForDeletion, [id]), state),

    nodeChangesetLoaded: (state, { payload }: NodeChangesetLoadedAction) => {
      const { bySelf, changeset, forgetFields } = payload
      const current = internalChangeSelectors.selectById(state, changeset.nodeId)
      const allKeysToForget = fp.union(fp.keys(changeset.changes), forgetFields)

      // The values in a changeset for the current user should be cleared from
      // state, as we don't show change information.
      if (bySelf && current) {
        const nextChanges = fp.omit(allKeysToForget, current.changes)
        changesAdapter.upsertOne(state.changes, { nodeId: current.nodeId, changes: nextChanges })
      }

      // Values for another user should be added to state. If a changeset
      // exists already, merge the changes into that so we don't clear prior
      // changes.
      if (!bySelf) {
        const nextChangeset = current ? fp.merge(current, changeset) : changeset
        changesAdapter.upsertOne(state.changes, nextChangeset)
      }

      const errorSet = internalErrorSelectors.selectById(state, changeset.nodeId)
      if (!errorSet) return

      const fieldKeys = fp.keys(changeset.changes)
      errorsAdapter.upsertOne(state.errors, clearFieldErrors(fieldKeys, errorSet))
    },

    nodeUpdateBegin: (state, { payload }: NodeUpdateBeginAction) => {
      const { attributes, id, primaryTrigger } = payload
      const fieldKeys = fp.concat(fp.keys(attributes), primaryTrigger)

      // Clear change information for fields included in this update.
      const currentChangeset = internalChangeSelectors.selectById(state, id)
      if (currentChangeset) {
        const nextChanges = fp.omit(fieldKeys, currentChangeset.changes)
        changesAdapter.upsertOne(state.changes, { nodeId: id, changes: nextChanges })
      }

      // Clear error information for fields included in this update.
      const existing = internalErrorSelectors.selectById(state, id)
      if (!existing) return

      const changes = { fieldErrors: fp.omit(fieldKeys, existing.fieldErrors) }
      errorsAdapter.updateOne(state.errors, { id, changes })
    },

    nodeUpdateFail: (state, { payload: { id, errors } }: NodeUpdateFailAction) => {
      const existing = internalErrorSelectors.selectById(state, id)
      const nextNodeErrorSet = makeNextNodeErrorSet(errors, id, existing)
      errorsAdapter.upsertOne(state.errors, nextNodeErrorSet)
    },

    startFieldTransition: (state, { payload }: StartFieldTransitionAction) =>
      fp.assoc(["transitions", payload.id, payload.fieldKey], payload.transition, state),

    endFieldTransition: (state, { payload }: EndFieldTransitionAction) =>
      fp.dissoc(["transitions", payload.id, payload.fieldKey], state),
  },
  extraReducers: (builder) =>
    // Hooks into extraReducers to update this state slice with page info as
    // queries fulfill. This is unnecessary, and I'll switch to pulling the
    // cursor out from the data returned by the hook later on.
    //
    // See: https://redux-toolkit.js.org/rtk-query/usage/migrating-to-rtk-query#slice
    builder.addMatcher(NodeApi.endpoints.fetchPageOfNodes.matchFulfilled, (state, { payload }) => {
      let pageInfo = state.pageInfo
      let pageInfoHistory = state.pageInfoHistory
      if (payload.info.pageInfo && payload.info.pageInfo !== pageInfo) {
        pageInfo = payload.info.pageInfo
        pageInfoHistory = [state.pageInfo, ...state.pageInfoHistory]
      }

      return fp.assign(state, { pageInfo, pageInfoHistory })
    }),
})

const changesetSelectors = changesAdapter.getSelectors((state: RootState) => state.node.changes)
const errorSetSelectors = errorsAdapter.getSelectors((state: RootState) => state.node.errors)

export const {
  markNodeIdForDeletion,
  removeNode,

  nodeChangesetLoaded,
  nodeUpdateBegin,
  nodeUpdateFail,

  endFieldTransition,
  startFieldTransition,
} = NodeSlice.actions
export { changesetSelectors, errorSetSelectors, NodeSlice }
