const defaultOptions = Object.freeze({
  actions: [],
  fields: [],
  displayFields: ["title", "name"],
  companyEditable: false,
  minimumZoom: 0.1,
  maximumZoom: 1.3,
  initialZoom: 0.9,
  animationDuration: 600,
  dragAndDropEnabled: true,
  verticalSpacing: 35,
  sortDirection: "ascending",
  sortField: "name",
  secondarySortField: "last_name",
  enableChartSections: false,
  loadAsync: true,
})

const defaultFields = ["avatar", "name", "title"]
const extraLineHeight = 10.5
const nodeHeight = 20
const nodeWidth = 176
const cardColor = "#fff"
const fontFamily = "'Satoshi', sans-serif"
const statsBars = {
  height: 30,
  borderColor: "rgba(012, 020, 075, 0.08)",
  borderWidth: 1,
}
const chainOfCommand = {
  height: 33,
  fill: "rgba(012, 020, 075, 0.03)",
  linkColor: "rgba(205, 220, 255, 1)",
  verticalSpacing: 35,
}
const threeLevelShiftAmount = 27
const threeLevelNodePadding = 16
const threeLevelThirdLevelNodeVerticalSpacing = 12

// The name and title text fields have a different font size/weight than the
// "Extra" text fields.
const nameTextFieldHeight = 18.5
const nameTextFieldMarginBottom = 2
const titleTextFieldHeight = 15
const extraFieldBaseHeight = 12.5
const extraFieldMarginBottom = 2
const topDividerMarginY = 8

// Spacing / margins below a field. Used for "extra" fields and the bottom of
// the avatar.
const fieldDefaultBottomSpacing = 4
const textFieldHeight = 20
const nodeRadius = 12
const linkColor = "rgba(205, 220, 255, 1)"
const linkStrokeWidth = 3
const linkDistanceFromTarget = 3
const linkRadius = 15
const avatarHeight = 40
const nodePadding = 12
const nodeMarginX = 16
const maxWidth = nodeWidth - nodePadding * 2
const clusterHorizontalSpacing = 60
const colorGroupPadding = 8
/**
 * In the DragHelper, we use this distance to determine if a node is close
 * enough to another node to register.
 */
const closestNodeDistance = 50
/**
 *  In the DragHelper, we use this distance to determine if a leaf node is close
 *  enough to another node to register.
 */
const condensedLeafDistance = 170

export const globals = Object.freeze({
  avatarHeight,
  cardColor,
  chainOfCommand,
  closestNodeDistance,
  clusterHorizontalSpacing,
  colorGroupPadding,
  condensedLeafDistance,
  defaultFields,
  extraFieldBaseHeight,
  extraFieldMarginBottom,
  extraLineHeight,
  fieldDefaultBottomSpacing,
  fontFamily,
  linkColor,
  linkDistanceFromTarget,
  linkRadius,
  linkStrokeWidth,
  maxWidth,
  nameTextFieldHeight,
  nameTextFieldMarginBottom,
  nodeHeight,
  nodeMarginX,
  nodePadding,
  nodeRadius,
  nodeWidth,
  statsBars,
  textFieldHeight,
  threeLevelNodePadding,
  threeLevelShiftAmount,
  threeLevelThirdLevelNodeVerticalSpacing,
  titleTextFieldHeight,
  topDividerMarginY,
})

export type DisplayModes = ["tree", "cards", "three_level", "grid"]

export default defaultOptions
