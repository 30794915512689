import React from "react"
import { CancelChartApprovalRequestInput } from "types/graphql.d"
import { Modal } from "v2/react/shared/Modal"
import { useCancelChartApprovalRequestMutation } from "v2/redux/GraphqlApi/ChartApprovalsApi"
import { setSelectedAction, toggleCancelModal, toggleDropdown } from "v2/redux/slices/ApprovalSlice"
import {
  selectCancelModal,
  selectCancelModalType,
  selectChartId,
} from "v2/redux/slices/ApprovalSlice/approvalSelectors"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

function CancelApprovalModal() {
  const showCancelModal = useAppSelector(selectCancelModal)
  const cancelType = useAppSelector(selectCancelModalType)
  const dispatch = useAppDispatch()
  const closeModal = () => dispatch(toggleCancelModal({ open: false, isEditType: false }))
  const closeDropdown = () => dispatch(toggleDropdown(false))
  const clearStatus = () => dispatch(setSelectedAction(null))
  const chartId = useAppSelector(selectChartId) as number
  const [cancelChartApproval, { isLoading }] = useCancelChartApprovalRequestMutation()

  const onSave = () => {
    const input: CancelChartApprovalRequestInput = {
      chartId: chartId.toString(),
    }

    const cancelRequest = async () => {
      await cancelChartApproval({ input }).unwrap()
      closeModal()
      closeDropdown()
      clearStatus()
    }

    cancelRequest()
  }

  let title: string
  let body: string
  let button: string

  if (cancelType === "cancel") {
    title = "are_you_sure".t("org_chart")
    body = "yes_cancel_note".t("org_chart")
    button = "yes_cancel".t("org_chart")
  } else {
    title = "edit_chart".t("org_chart")
    body = "begin_edit_note".t("org_chart")
    button = "begin_edit".t("org_chart")
  }

  return (
    <Modal isOpen={showCancelModal} onClose={closeModal} title={title}>
      <div className="p-6">{body}</div>
      <div className="relative border-t-neutral-8 p-6">
        {isLoading || !showCancelModal ? (
          <button className="btn--large btn--primary btn-disabled" type="button">
            {"save_disabled".t("button_defaults")}
          </button>
        ) : (
          <button className="btn--large btn--primary" onClick={onSave} type="button">
            {button}
          </button>
        )}
      </div>
    </Modal>
  )
}

export { CancelApprovalModal }
