import React from "react"
import { useCollectionSearch } from "v2/react/hooks/useCollectionSearch"
import { ForcedAutocompleteCell } from "v2/react/shared/TableDatasheet/ForcedAutocompleteCell"
import { StrikethroughCell } from "v2/react/shared/TableDatasheet/StrikethroughCell"
import { Option } from "types/graphql.d"
import { CompareValues } from "v2/react/shared/TableDatasheet/CompareValues"
import { HeadcountPlanDatasheetRow } from "./types"
import { useSaveCell } from "../hooks/useSaveCell"

type LocationCellProps = {
  row: HeadcountPlanDatasheetRow
  headcountPlanId: string
  participantId?: string
  readOnly: boolean
}
export function LocationCell({ row, headcountPlanId, participantId, readOnly }: LocationCellProps) {
  const currentValue = row.positionAttributesWithEdits.location
  const [searchTerm, setSearchTerm] = React.useState<string>(currentValue?.label ?? "")
  const [skipQuery, setSkipQuery] = React.useState<boolean>(true)
  const saveFn = useSaveCell<Option>(headcountPlanId, "location", row, participantId)
  const { collectionResult: locations } = useCollectionSearch({
    fieldKey: "location",
    filter: searchTerm,
    skipQuery,
  })

  const compareValue =
    row.type === "modified" && "location" in row.payload ? row.positionAttributes.location : null

  if (row.excluded) {
    return <StrikethroughCell value={currentValue?.label ?? ""} />
  }

  if (readOnly) {
    return (
      <CompareValues compareValue={compareValue?.label} className="non-editable-cell">
        <span>{currentValue?.label ?? ""}</span>
      </CompareValues>
    )
  }

  return (
    <ForcedAutocompleteCell
      columnId="location"
      rowId={row.id}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      options={locations}
      renderOption={(option) => <div>{option.label}</div>}
      saveFn={saveFn}
      currentValue={currentValue}
      compareValue={compareValue}
      onEditing={() => setSkipQuery(false)}
    />
  )
}
