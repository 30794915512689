import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { useGetCurrencyCodeQuery, useUpdateCurrencyCodeMutation } from "v2/redux/GraphqlApi"
import { SaveFooter } from "v2/react/shared/Modal/SaveFooter"
import { AlertBanner } from "v2/react/shared/AlertBanner"
import { CurrencyInfo, Error as ErrorType } from "types/graphql"
import { RadioGroup } from "v2/react/shared/Inputs/RadioGroup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CurrencyDropdown, SelectOption } from "./CurrencyDropdown"

interface Props {
  onSaveSuccess: (currencyInfo: CurrencyInfo) => void
}

interface Option {
  icon: string
  value: string
  label: string
}

const generateMiniCard = (option: Option, addMargin: boolean) => (
  <div className="items-center flex">
    {addMargin && <div className="mr-2" />}
    <p>{option.label}</p>
  </div>
)

const generateCardTypeOption = (option: Option) => ({
  value: option.value,
  icon: <i className={option.icon} />,
  text: option.label,
  fieldDisplay: generateMiniCard(option, true),
  headerDisplay: generateMiniCard(option, false),
})

const generateCardTypeOptions = (options: Option[]) => options.map(generateCardTypeOption)

function CurrencySettingsForm({ onSaveSuccess }: Props) {
  const { t } = useTranslation()
  const { data, isLoading } = useGetCurrencyCodeQuery({})
  const company = data?.currentCompany || null
  const [initialCurrency] = useState<string>(company?.currencyCode || "")
  const [currentCurrency, setCurrentCurrency] = useState<string>(company?.currencyCode || "")
  const [useUniqueCurrencySymbol, setUseUniqueCurrencySymbol] = useState<boolean>(
    company?.useUniqueCurrencySymbol || false,
  )
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [mutate, mutation] = useUpdateCurrencyCodeMutation()

  const onSelection = (option: SelectOption) => {
    setFormErrors([])
    setCurrentCurrency(option.value)
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const input = {
      currencyCode: currentCurrency,
      useUniqueCurrencySymbol,
    }

    try {
      const response = await mutate({ input }).unwrap()

      if (response.updateCompanyCurrencyCode.errors.length === 0) {
        const updatedCurrency = response.updateCompanyCurrencyCode.currency
        onSaveSuccess(updatedCurrency)
      } else {
        setFormErrors(response.updateCompanyCurrencyCode.errors.map((e: ErrorType) => e.message))
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : t("v2.defaults.error")
      setFormErrors([errorMessage])
    }
  }

  if (isLoading || !company) {
    return null
  }

  const options = (company.collections.currencies.options.nodes || []).map((option) => ({
    value: option.id.split(":")[0],
    label: option.label,
    icon: option.id.split(":")[1],
  }))

  const initialOption = options.find((option) => option.value === initialCurrency)
  if (!initialOption) return null

  return (
    <form onSubmit={onSubmit}>
      <div className="react-modal__body">
        {formErrors.length > 0 && (
          <AlertBanner type="critical" messages={formErrors.map((text) => ({ text }))} />
        )}
        {initialCurrency !== currentCurrency && (
          <div className="alert alert-caution mb-3 items-start gap-2 flex">
            <FontAwesomeIcon icon={["far", "exclamation-triangle"]} className="mt-1.5" />
            <p>{t("v2.general_settings.currency.currency_change_warning")}</p>
          </div>
        )}
        <h3>{t("v2.general_settings.currency.reporting_currency")}</h3>
        <h3 className="pb-2 text-sm text-neutral-64">
          {t("v2.general_settings.currency.reporting_currency_description")}
        </h3>
        <CurrencyDropdown
          options={generateCardTypeOptions(options)}
          initialOption={generateCardTypeOption(initialOption)}
          onSelection={onSelection}
        />
        <div className="mt-3">
          <h3>{t("v2.general_settings.currency.unique_symbols")}</h3>
          <h3 className="pb-2 text-sm text-neutral-64">
            {t("v2.general_settings.currency.unique_symbols_description")}
          </h3>
          <div className="ml-0.5">
            <RadioGroup
              groupName="unique-currency-key"
              options={[
                { id: "0", value: "0", label: t("v2.defaults.no") },
                { id: "1", value: "1", label: t("v2.defaults.yes") },
              ]}
              defaultChecked={useUniqueCurrencySymbol ? "1" : "0"}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setUseUniqueCurrencySymbol(event.target.value === "1")
              }
            />
          </div>
        </div>
      </div>
      <SaveFooter isSaving={mutation.isLoading} />
    </form>
  )
}

export { CurrencySettingsForm }
