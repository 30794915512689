import classNames from "classnames"
import React, { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { LinkMenuGroup } from "v2/react/shared/PageNavigation/LinkMenuGroup"
import { AnimatePresence, motion } from "framer-motion"
import { hasSingularOrEmptyLinks } from "./utils"

export interface Link {
  active: boolean
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  show: boolean
  text: string
  url: string
  width?: number
}

interface LinkGroupProps {
  links: Link[]
  showAll?: boolean
}

function LinkGroup({ showAll, links }: LinkGroupProps) {
  const shownLinks = useMemo(() => links.filter((link) => link.show), [links])
  const navRef = useRef<HTMLDivElement>(null)
  const [visibleLinks, setVisibleLinks] = useState<Link[]>(shownLinks)
  const [hiddenLinks, setHiddenLinks] = useState<Link[]>([])

  const updateLinksVisibility = useCallback(() => {
    const necessaryPadding = 64
    const nav = navRef.current
    if (!nav) return

    let availableWidth = nav.offsetWidth - necessaryPadding
    const newVisibleLinks: Link[] = []
    const newHiddenLinks: Link[] = []

    shownLinks.forEach((link) => {
      const linkElement = document.getElementById(link.url + link.text)

      if (linkElement?.offsetWidth) {
        // without setting this on the initial render, we have no width value for each link
        // which prevents links from becoming visible again when expanding the screen
        link.width = linkElement.offsetWidth // eslint-disable-line no-param-reassign
      }

      const linkWidth = link.width || linkElement?.offsetWidth || 0

      if (availableWidth - linkWidth > 0 || showAll) {
        newVisibleLinks.push(link)
        availableWidth -= linkWidth
      } else {
        newHiddenLinks.push(link)
        // if there is no room for the link, remove all available width
        // prevents odd flashing of links attempting to fit in the remaining space
        availableWidth = 0
      }
    })

    setVisibleLinks(newVisibleLinks)
    setHiddenLinks(newHiddenLinks)
  }, [shownLinks, showAll])

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (!entries[0].contentRect.width) return
      updateLinksVisibility()
    })

    if (navRef.current) {
      resizeObserver.observe(navRef.current)
    }

    return () => resizeObserver.disconnect()
  }, [navRef, shownLinks, updateLinksVisibility])

  if (hasSingularOrEmptyLinks(links)) return null

  const isFirst = (index: number) => index === 0
  const isLast = (index: number) => visibleLinks.length - 1 === index

  const pageLink = (link: Link, index: number) => (
    <motion.a
      key={link.url + link.text}
      href={link.url}
      id={link.url + link.text}
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.2, ease: "easeOut" }}
      data-url={link.url}
      className={classNames("btn visible-link opacity-1 visible visible", {
        active: link.active,
        "!rounded-r-lg": isLast(index) && !(hiddenLinks.length > 0),
        "!rounded-r-none": isLast(index) && hiddenLinks.length > 0,
        "!order-[-1]": isFirst(index),
      })}
      onClick={link.onClick}
    >
      {link.text}
    </motion.a>
  )

  return (
    <div ref={navRef} className="btn-group w-full justify-center flex">
      <div
        className={classNames("grow overflow-hidden py-2 flex", {
          "justify-center block": !(hiddenLinks.length > 0),
        })}
      >
        <AnimatePresence>
          {visibleLinks.map((link: Link, index: number) => pageLink(link, index))}
        </AnimatePresence>
        {hiddenLinks.length > 0 && (
          <LinkMenuGroup
            menuText={"More".t("defaults")}
            classes="!rounded-l-none !rounded-r-lg visible"
            links={hiddenLinks}
          />
        )}
      </div>
    </div>
  )
}

export { LinkGroup }
