import React from "react"
import { useTranslation } from "react-i18next"
import { FeatureFlags, IntegrationFlags, PositionType } from "types/graphql"
import RootProvider from "v2/react/components/RootProvider"
import { Description } from "v2/react/components/positionTypes/Show/Description"
import { Details } from "v2/react/components/positionTypes/Show/Details"
import { PeopleInterestedList } from "v2/react/components/positionTypes/Show/PeopleInterestedList"
import { Positions } from "v2/react/components/positionTypes/Show/Positions"
import { PositionTypePageNav } from "v2/react/components/positionTypes/PositionTypePageNav"
import { Requirements } from "v2/react/components/positionTypes/Show/Requirements"
import { Responsibilities } from "v2/react/components/positionTypes/Show/Responsibilities"
import { Spinner } from "v2/react/shared/Spinner"
import { Stats } from "v2/react/components/positionTypes/Show/Stats"
import { Summary } from "v2/react/components/positionTypes/Show/Summary"
import { UrlHelper } from "v2/react/utils/urls"
import { useGetFeatureFlagsQuery, useGetIntegrationFlagsQuery } from "v2/redux/GraphqlApi"
import { useGetPositionTypeDetailsQuery } from "v2/redux/GraphqlApi/PositionTypesApi"

interface ShowProps {
  positionTypeId: number
}

function WithProvider({ positionTypeId }: ShowProps) {
  const { t } = useTranslation()
  const { data: featureFlagsData } = useGetFeatureFlagsQuery()
  const { data: integrationFlagsData } = useGetIntegrationFlagsQuery()
  const { data: positionTypeResult, isLoading } = useGetPositionTypeDetailsQuery({
    id: `position_type_${positionTypeId}`,
  })
  const positionType: PositionType | null | undefined = positionTypeResult?.positionType
  const featureFlags: FeatureFlags | undefined = featureFlagsData?.currentCompany?.featureFlags
  const integrationFlags: IntegrationFlags | undefined =
    integrationFlagsData?.currentCompany?.integrationFlags

  if (isLoading || !positionType || !featureFlags || !integrationFlags) {
    return (
      <div className="relative h-screen">
        <Spinner testId="positionType-spinner" />
      </div>
    )
  }

  return (
    <>
      <PositionTypePageNav
        parentTitle={t("v2.positions.show.job_definitions")}
        title={positionType.title || t("v2.positions.show.untitled_position")}
        parentTitleUrl={UrlHelper.positionTypeRootPath()}
      />
      <div className="page-pad">
        <div className="grid-cols-1 gap-3 grid sm:grid-cols-[2fr_1fr] sm:gap-8">
          <div>
            {featureFlags.aiGeneration && <Summary positionType={positionType} />}
            <Details
              positionType={positionType}
              positionTypeId={positionType.id}
              integrationFlags={integrationFlags}
            />
            {featureFlags.aiGeneration && <Requirements positionType={positionType} />}
            {featureFlags.aiGeneration && <Responsibilities positionType={positionType} />}
            <Description positionType={positionType} />
          </div>
          <div>
            <Stats positionType={positionType} />
            <Positions positionType={positionType} />
            <PeopleInterestedList positionType={positionType} />
          </div>
        </div>
      </div>
    </>
  )
}

const Show = ({ positionTypeId }: ShowProps) => (
  <React.StrictMode>
    <RootProvider>
      <WithProvider positionTypeId={positionTypeId} />
    </RootProvider>
  </React.StrictMode>
)

export { Show, WithProvider }
