import React, { ReactNode } from "react"
import { Spinner } from "v2/react/shared/Spinner"

const WithLoader = ({ children, isLoading }: { children: ReactNode; isLoading: boolean }) => {
  if (isLoading)
    return (
      <div className="place-content-center grid">
        <Spinner />
      </div>
    )

  return children
}

export { WithLoader }
