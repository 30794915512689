import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react"
import cn from "classnames"
import { useTranslation } from "react-i18next"
import { useCareerLaddersUpdateCareerLadderTrackMutation } from "v2/redux/GraphqlApi/CareerLaddersApi"

const DEFAULT_INPUT_WIDTH = 112

interface Props {
  careerLadderId: string
  careerLadderTrackId: string
  focusInput: boolean
  onSave: () => void
  value: string
  setValue: (inputValue: string) => void
}

function BadgeInput({
  careerLadderId,
  careerLadderTrackId,
  focusInput,
  onSave,
  value,
  setValue,
}: Props) {
  const { t } = useTranslation()
  const [inputWidth, setInputWidth] = useState(calculateTextWidth(value))
  const [mutate] = useCareerLaddersUpdateCareerLadderTrackMutation()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (focusInput && inputRef) {
      inputRef.current?.focus()
    }
  }, [focusInput])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    setInputWidth(calculateTextWidth(e.target.value))
  }

  const handleEnterSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") handleSubmit()
  }

  const handleSubmit = async () => {
    await mutate({
      careerLadderId,
      careerLadderTrackId,
      name: value,
    })
    onSave()
  }

  return (
    <div
      className={cn("badge--gray !p-0 text-sm-bold !text-neutral-100", {
        "!w-[7rem]": !value.length,
        "!w-fit !min-w-[1.5rem]": value.length > 0,
      })}
    >
      <input
        type="text"
        placeholder={t("v2.career_ladders.builder.name_track")}
        style={{ width: inputWidth }}
        value={value}
        maxLength={40}
        onChange={handleChange}
        onBlur={handleSubmit}
        onKeyUp={handleEnterSubmit}
        ref={inputRef}
        className={cn(
          "h-full  border-none bg-transparent !px-1 placeholder:text-center placeholder:text-neutral-100",
          {
            "text-center": value.length > 0,
            "!px-2": value.length === 0,
          },
        )}
      />
    </div>
  )
}

export { BadgeInput }

// https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript
function calculateTextWidth(text: string) {
  const headerFont = "700 11px Satoshi, sans-serif"
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")
  if (!context) return 24
  context.font = headerFont
  const textWidth = context.measureText(text).width
  const noText = textWidth === 0
  if (noText) return DEFAULT_INPUT_WIDTH
  return textWidth < 8 ? 24 : textWidth + 16
}
