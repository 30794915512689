import React from "react"
import { DisplayValueCell } from "v2/react/shared/TableDatasheet/DisplayValueCell"
import { PositionMenu } from "../ShowParticipant/PositionMenu"
import { HeadcountPlanDatasheetRow } from "./types"

type Props = {
  row: HeadcountPlanDatasheetRow
  headcountPlanId: string
  headcountPlanStartDate: string | undefined
  participantId?: string
  participantPositionId?: string
}
export function PositionMenuCell({
  row,
  headcountPlanId,
  headcountPlanStartDate,
  participantId,
  participantPositionId,
}: Props) {
  return (
    <DisplayValueCell className="non-editable-cell" style={{ borderLeft: "none" }}>
      <PositionMenu
        excluded={row.excluded}
        rootEventId={String(row.rootEventId)}
        headcountPlanId={headcountPlanId}
        headcountPlanStartDate={headcountPlanStartDate}
        participantId={participantId}
        participantPositionId={participantPositionId}
        row={row}
      />
    </DisplayValueCell>
  )
}
