import React from "react"
import { downloadCsv, sanitizeCsvValue } from "v2/react/utils/csv"
import { useTranslation } from "react-i18next"
import { TimelineColumns, TimelineRow, TimelineTotalRow } from "types/graphql"

export function useCsvDownloadListener(
  csvDownloadRef: React.RefObject<HTMLButtonElement> | undefined,
  columns: TimelineColumns,
  rows: TimelineRow[],
  totals?: TimelineTotalRow[],
  csvDownloadName?: string,
) {
  const { t } = useTranslation()
  React.useEffect(() => {
    const handleClick = () => {
      const combinedColumns = [
        ...columns.groupBy.map((col) => sanitizeCsvValue(col.label)),
        t("v2.defaults.metric"),
        ...columns.timeline.map((col) => sanitizeCsvValue(col.label)),
      ]

      const csvData = [
        combinedColumns.join(","),
        ...rows.map((row) => {
          const groupByCells = row.groupByCells.map((cell) => sanitizeCsvValue(cell))
          const metric = sanitizeCsvValue(row.metric)
          const timelineCells = row.timelineCells.map((cell) => sanitizeCsvValue(cell))
          return [...groupByCells, metric, ...timelineCells].join(",")
        }),
      ]

      if (totals && totals.length > 0) {
        const totalsData = totals.map((total) => {
          const totalCells = [t("v2.defaults.total"), ...columns.groupBy.slice(1).map(() => "")]
          const metric = sanitizeCsvValue(total.metric)
          const timelineCells = total.timelineCells.map((cell) => sanitizeCsvValue(cell))
          return [...totalCells, metric, ...timelineCells].join(",")
        })
        csvData.push(...totalsData)
      }

      downloadCsv(csvData.join("\n"), csvDownloadName)
    }

    const button = csvDownloadRef?.current
    button?.addEventListener("click", handleClick)
    return () => button?.removeEventListener("click", handleClick)
  }, [csvDownloadRef, columns, rows, totals, t, csvDownloadName])
}
