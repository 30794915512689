import React, { ChangeEvent, FC } from "react"
import { ControlProps } from "@jsonforms/core"

import { isDisabledFromSchema } from "v2/react/shared/JsonFormControls/utils/forms"
import { oneOfDataItem } from "./types"

const JsonRadioInput: FC<ControlProps> = ({
  data,
  enabled,
  handleChange,
  id,
  label,
  path,
  schema,
  uischema,
}) => {
  const isDisabled = isDisabledFromSchema({ enabled, schema })
  const handleChangeEvent = (event: ChangeEvent<HTMLInputElement>, constValue: string) =>
    handleChange(path, constValue)

  const options: oneOfDataItem[] = schema.oneOf || []

  return (
    <div className="input-group">
      <label>{label}</label>
      {uischema.options?.subLabel && <p className="m-0">{uischema.options.subLabel}</p>}
      {options &&
        options.map((option: oneOfDataItem, index: number) => (
          <div key={option.const} className={index !== options.length - 1 ? "mb-1" : ""}>
            <label htmlFor={`${path} ${option.const}`} className="radio m-0">
              <input
                checked={data === option.const}
                className="input"
                disabled={isDisabled}
                id={`${path} ${option.const}`}
                name={id}
                onChange={(event) => handleChangeEvent(event, option.const as string)}
                type="radio"
                value={option.const}
              />
              {option.title}
            </label>
          </div>
        ))}
    </div>
  )
}

export { JsonRadioInput }
