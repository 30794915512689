import React from "react"

import { FieldKey } from "v2/redux/slices/NodeSlice/types"
import { SourcePay, BasePay } from "types/graphql.enums"
import {
  BudgetedPayFieldType,
  ActionType,
  FocusRef,
  InputRef,
} from "v2/react/components/orgChart/OrgChartDatasheet/Modals/BudgetedPayTypeFollowUp"
import {
  ErrorsType,
  errorMessagesFor,
  ClearErrors,
} from "v2/react/components/orgChart/OrgChartDatasheet/Modals/errors"
import {
  isVariablePayAmount,
  basePayTypeCast,
  variablePayTypeCast,
} from "v2/react/components/orgChart/OrgChartDatasheet/Modals/helpers"
import { Changes } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/types"

import { HourlyPayInput } from "v2/react/shared/Inputs/HourlyPayInput"
import { BasePayInput } from "v2/react/shared/Inputs/BasePayInput"
import { CurrencyInput } from "v2/react/shared/Inputs/CurrencyInput"
import { PercentInput } from "v2/react/shared/Inputs/PercentInput"
import { VariablePayInput } from "v2/react/shared/Inputs/VariablePayInput"

const ModalContent = ({
  fieldKey,
  fieldType,
  actionType,
  changes,
  label,
  errors,
  focusRef,
  clearErrors,
}: {
  fieldKey: FieldKey
  fieldType: BudgetedPayFieldType
  actionType: ActionType
  changes: Changes
  label?: string
  errors?: ErrorsType
  focusRef?: FocusRef
  clearErrors?: ClearErrors
}) => {
  if (actionType === "edit" && fieldType === "budgetedPayType") {
    return renderEditBudgetedPayTypeInput(fieldKey, changes, errors, focusRef as InputRef)
  }
  if (actionType === "edit" && fieldType === "variablePayType") {
    return renderEditVariablePayTypeInput(fieldKey, changes, errors, focusRef as InputRef)
  }
  if (
    actionType === "add" &&
    ["budgetedPayType", "budgetedPayAmount", "budgetedHoursPerWeek"].includes(fieldType)
  ) {
    return renderAddBudgetedPayInput(fieldKey, changes, label, errors, focusRef, clearErrors)
  }
  if (actionType === "add" && ["variablePayType", "variablePayAmount"].includes(fieldType)) {
    return renderAddVariablePayInput(fieldKey, changes, label, errors, focusRef, clearErrors)
  }
  return null
}

const renderEditBudgetedPayTypeInput = (
  fieldKey: FieldKey,
  changes: Changes,
  errors?: ErrorsType,
  focusRef?: InputRef,
) => {
  // This ensures the amount is targeted
  const name = fieldKey.replace(/_type$/, "")

  if (changes.to === BasePay.Hourly) {
    return (
      <HourlyPayInput
        ref={focusRef}
        idPrefix="hourly-pay"
        label={`${changes.to}`.t("org_chart")}
        currencyName={name}
        hoursName="position_hours_per_week"
        currencyErrors={errorMessagesFor("position_base_pay", errors)}
        hoursErrors={errorMessagesFor("position_hours_per_week", errors)}
        useAttentionState
      />
    )
  }
  return (
    <CurrencyInput
      ref={focusRef}
      id={`currency-input__${name}`}
      label={`${changes.to}`.t("org_chart") || undefined}
      name={name}
      useAttentionState
      errors={errorMessagesFor("position_base_pay", errors)}
    />
  )
}

const renderEditVariablePayTypeInput = (
  fieldKey: FieldKey,
  changes: Changes,
  errors?: ErrorsType,
  focusRef?: InputRef,
) => {
  // This ensures the amount is targeted
  const name = fieldKey.replace(/_type$/, "")

  if (changes.to === SourcePay.Amount) {
    return (
      <CurrencyInput
        ref={focusRef}
        id={`currency-input__${name}`}
        label={`${changes.to}`.t("org_chart")}
        name={name}
        errors={errorMessagesFor(name, errors)}
        useAttentionState
      />
    )
  }
  return (
    <PercentInput
      ref={focusRef}
      id={`percent-input__${name}`}
      label={`${changes.to}`.t("org_chart") || undefined}
      name={name}
      errors={errorMessagesFor(name, errors)}
      useAttentionState
    />
  )
}

const renderAddBudgetedPayInput = (
  fieldKey: FieldKey,
  changes: Changes,
  label?: string,
  errors?: ErrorsType,
  focusRef?: FocusRef,
  clearErrors?: ClearErrors,
) => {
  const isPositionBasePay = fieldKey === "position_base_pay"
  const isHoursPerWeek = fieldKey === "position_hours_per_week"

  let initialPayType = null
  if (isPositionBasePay) {
    initialPayType = BasePay.Salary
  } else if (isHoursPerWeek) {
    initialPayType = BasePay.Hourly
  } else {
    initialPayType = basePayTypeCast(changes.to)
  }
  const initialCurrencyValue = isPositionBasePay ? changes.to || "" : undefined
  const initialHoursPerWeek = isHoursPerWeek ? changes.to || "" : undefined

  const currencyErrors = errorMessagesFor("position_base_pay", errors)
  const hoursErrors = errorMessagesFor("position_hours_per_week", errors)
  const focusOn = isPositionBasePay ? "type" : "amount"

  return (
    <BasePayInput
      ref={focusRef}
      focusOn={focusOn}
      label={label}
      initialPayType={initialPayType}
      initialCurrencyValue={initialCurrencyValue}
      initialHoursPerWeek={initialHoursPerWeek}
      currencyErrors={currencyErrors}
      hoursErrors={hoursErrors}
      clearErrors={clearErrors}
      useAttentionState
    />
  )
}

const renderAddVariablePayInput = (
  fieldKey: FieldKey,
  changes: Changes,
  label?: string,
  errors?: ErrorsType,
  focusRef?: FocusRef,
  clearErrors?: ClearErrors,
) => {
  const isVariablePayAmountResult = isVariablePayAmount(fieldKey)
  const name = fieldKey.replace(/_type$/, "")
  const initialAmountValue = isVariablePayAmountResult ? changes.to || "" : undefined
  const initialPayType = isVariablePayAmountResult
    ? SourcePay.Amount
    : variablePayTypeCast(changes.to)

  return (
    <VariablePayInput
      ref={focusRef}
      focusOn={isVariablePayAmountResult ? "type" : "amount"}
      name={name}
      label={label}
      initialAmountValue={initialAmountValue}
      initialPayType={initialPayType}
      amountErrors={errorMessagesFor(name, errors)}
      clearErrors={clearErrors}
      useAttentionState
    />
  )
}

export { ModalContent }
