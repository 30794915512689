import React, { ReactElement } from "react"
import AnimateHeight from "react-animate-height"

import {
  LoginSettingsErrors,
  SsoProviderType,
} from "v2/react/components/company/generalSettings/types"
import { YesNoToggle } from "v2/react/shared/YesNoToggle"

import { SsoProviderOptions } from "./SsoProviderOptions"

interface AuthenticationFormProps {
  formErrors?: LoginSettingsErrors
  isLoading: boolean
  isSaving: boolean
  isOktaConfigured: boolean
  isSsoRequired: boolean
  onFormDataChange: (field: string, value: boolean | string | string[]) => void
  onSave: () => void
  permittedSsoProviders: SsoProviderType[]
  ssoAzureTenantIdRestrictions: string[]
  ssoGoogleDomainRestrictions: string[]
}

function AuthenticationForm({
  formErrors,
  isLoading,
  isSaving,
  isOktaConfigured,
  isSsoRequired,
  onFormDataChange,
  onSave,
  permittedSsoProviders,
  ssoAzureTenantIdRestrictions,
  ssoGoogleDomainRestrictions,
}: AuthenticationFormProps) {
  const onIsSsoRequiredChange = (selectedValue: boolean) => {
    onFormDataChange("isSsoRequired", selectedValue)
  }

  const handleSaveButtonClick = () => {
    onSave()
  }

  const hasErrors =
    formErrors?.permitted_sso_providers && formErrors.permitted_sso_providers.length > 0
  const height = isSsoRequired ? "auto" : 0

  return (
    <div>
      <div style={{ padding: "1.5rem" }}>
        <div className="font-bold">{"do_require_sso".t("general_settings")}</div>
        <YesNoToggle
          inputName="company[sso_required]"
          onChange={onIsSsoRequiredChange}
          value={isSsoRequired}
        />
        {!isLoading && (
          <AnimateHeight duration={250} height={height}>
            <div id="leave-warning" className="alert alert-caution mb-4">
              <div className="alert-description">
                {"when_sso_required".t("general_settings")}
                <ul>
                  <li>{"when_sso_required_1".t("general_settings")}</li>
                  <li>{"when_sso_required_2".t("general_settings")}</li>
                  <li>{"when_sso_required_3".t("general_settings")}</li>
                </ul>
              </div>
            </div>
            <div>
              <div className={hasErrors ? "font-bold text-status-critical" : "font-bold"}>
                {"sso_provider_options".t("general_settings")}
              </div>
              <ProviderErrors formErrors={formErrors} />
              <div style={{ marginTop: ".25rem" }}>
                <SsoProviderOptions
                  isOktaConfigured={isOktaConfigured}
                  onChange={onFormDataChange}
                  permittedSsoProviders={permittedSsoProviders}
                  ssoAzureTenantIdRestrictions={ssoAzureTenantIdRestrictions}
                  ssoGoogleDomainRestrictions={ssoGoogleDomainRestrictions}
                />
              </div>
            </div>
          </AnimateHeight>
        )}
      </div>
      <div className="react-modal__footer">
        {isSaving ? (
          <button className="btn--large btn--primary btn-disabled" type="button">
            {"Saving".t("defaults")}
          </button>
        ) : (
          <button className="btn--large btn--primary" onClick={handleSaveButtonClick} type="button">
            {"Save".t("defaults")}
          </button>
        )}
      </div>
    </div>
  )
}

interface ProviderErrorsProps {
  formErrors?: LoginSettingsErrors
}

function ProviderErrors({ formErrors }: ProviderErrorsProps): ReactElement | null {
  const providersError = formatErrors(formErrors)

  if (providersError === "") return null

  return (
    <div className="form-error">
      <div className="form-error-message mt-1">{formatErrors(formErrors)}</div>
    </div>
  )
}

const formatErrors = (formErrors?: LoginSettingsErrors): string => {
  if (!formErrors?.permitted_sso_providers || formErrors.permitted_sso_providers.length === 0) {
    return ""
  }
  return formErrors.permitted_sso_providers[0]
}

export { AuthenticationForm }
