import React, { ReactNode } from "react"

interface Props {
  children: ReactNode
}

function UtilityNav({ children }: Props) {
  return <div className="utility-nav">{children}</div>
}

export { UtilityNav }
