import React from "react"
import { CustomFieldValue, FeatureFlags } from "types/graphql"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSortedBoxes } from "v2/react/components/orgChart/ProfilePanel/useSortedBoxes"
import { AccordionModulePanel } from "v2/react/shared/AccordionModulePanel"
import { useAppSelector } from "v2/redux/store"

interface CustomFieldsProps {
  featureFlags: FeatureFlags
}

function CustomFields({ featureFlags }: CustomFieldsProps) {
  const { currentBox, toggleBox } = useSortedBoxes("custom_fields", "position")
  const position = useAppSelector((state) => state.profilePanel.position)

  if (!currentBox || !position) return null
  if (!featureFlags.customFields || !position.customFieldValues?.length) return null

  const headerContent = <div className="title">{"custom_fields".t("org_chart")}</div>

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      {position.customFieldValues?.map((customField: CustomFieldValue) => (
        <ModuleListItem key={customField.field_id}>
          <div>{customField.name}</div>
          <div>{customField.formatted_value}</div>
        </ModuleListItem>
      ))}
    </AccordionModulePanel>
  )
}

export { CustomFields }
