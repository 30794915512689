import React, { FC, useEffect, useRef, useState } from "react"
import { ControlProps } from "@jsonforms/core"
import tinymce, { Editor, RawEditorOptions } from "tinymce"

import { tinyMceDefaultConfig } from "v2/react/utils/tinyMceDefaultConfig"
import { isDisabledFromSchema } from "v2/react/shared/JsonFormControls/utils/forms"

const JsonTextAreaInput: FC<ControlProps> = ({
  data,
  enabled,
  schema,
  handleChange,
  id,
  label,
  path,
}: ControlProps) => {
  const [editorInitialized, setEditorInitialized] = useState(false)
  const isDisabled = isDisabledFromSchema({ enabled, schema })
  const editorRef = useRef<Editor | null>(null)
  const activeEditor = editorRef.current

  useEffect(() => {
    const tinyMceConfig: RawEditorOptions = {
      ...tinyMceDefaultConfig(id),
      setup: (editor: Editor) =>
        editor.on("change", () => {
          handleChange(path, editor.getContent())
        }),
    }

    tinymce.remove(`textarea[id="${id}"]`)
    tinymce.init(tinyMceConfig)
    editorRef.current = tinymce.get(id)
    setEditorInitialized(true)
  }, [id, path, handleChange])

  useEffect(() => {
    if (!editorInitialized) return
    if (!activeEditor) return

    if (isDisabled) {
      activeEditor.mode.set("readonly")
    } else if (!isDisabled) {
      activeEditor.mode.set("design")
    }

    const existingValue: string = activeEditor.getContent()

    // Sometimes the incoming data is not from the input values from keyups,
    // but from a manual setting of the data from an external source (like the
    // job description fetched from the title's job definition). If this is the
    // case, ensure the editor content is reset to the incoming data.
    if (existingValue !== data) {
      activeEditor.setContent(data)
    }
  }, [editorInitialized, data, isDisabled, activeEditor])

  return (
    <div className="input-group">
      <label htmlFor={id}>{label}</label>
      <textarea
        className="input input-med"
        defaultValue={data}
        id={id}
        name={id}
        disabled={isDisabled}
      />
    </div>
  )
}

export { JsonTextAreaInput }
