import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"
import { Person } from "types/graphql"
import { AlertBanner } from "v2/react/shared/AlertBanner"
import { Modal, ModalFooter } from "v2/react/shared/Modal"
import { PersonSearchInput } from "v2/react/shared/PersonSearchInput"
import { useUpdateHeadcountPlanCollaboratorsMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

type ExistingCollaborator = {
  id: string
  uniqueKey: string
  role: string
  person: {
    id: string
    name: string
    avatarThumbUrl: string
  }
}
type Collaborator = {
  id: string
  personId: string
  name: string
  role: string
  avatarThumbUrl?: string | null
}
type Props = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  planner: Omit<Collaborator, "role">
  existingCollaborators: ExistingCollaborator[]
  headcountPlanId: string
  participantId: string
  currentPersonId: string
}
export function ConfigureCollaboratorsModal({
  isOpen,
  setIsOpen,
  planner,
  existingCollaborators,
  headcountPlanId,
  participantId,
  currentPersonId,
}: Props) {
  const { t } = useTranslation()
  const [updateCollaborators, mutation] = useUpdateHeadcountPlanCollaboratorsMutation()
  const { collaborators, setCollaborators } = useCollaborators(existingCollaborators, isOpen)

  React.useEffect(() => {
    if (mutation.data?.updateHeadcountPlanCollaborators?.success) {
      mutation.reset()
      setIsOpen(false)
    }
  }, [mutation, setIsOpen])

  const onSelectPerson = (person: Person) => {
    setCollaborators((prevCollaborators) => [
      ...prevCollaborators,
      {
        id: person.id,
        personId: person.id,
        name: person.name,
        role: "collaborator",
        avatarThumbUrl: person.avatarThumbUrl,
      },
    ])
  }
  const onRemoveCollaborator = (collaborator: Collaborator) => {
    setCollaborators((prevCollaborators) =>
      prevCollaborators.filter((c) => c.id !== collaborator.id),
    )
  }

  const onClose = () => {
    mutation.reset()
    setIsOpen(false)
  }

  const onSave = () => {
    updateCollaborators({
      input: {
        headcountPlanId,
        participantId,
        collaborators: collaborators.map((c) => ({ personId: c.personId })),
      },
    })
  }

  const collaboratorPersonIds = [...collaborators.map((c) => c.personId), planner.personId]

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("v2.headcount_plan.collaborators_modal.title")}
      footer={
        <ModalFooter
          onClose={onClose}
          onSave={onSave}
          disabled={mutation.isLoading}
          saveButtonText={mutation.isLoading ? t("v2.defaults.saving") : t("v2.defaults.save")}
        />
      }
    >
      <div className="p-6">
        {mutation.data?.updateHeadcountPlanCollaborators?.success === false && (
          <div className="pb-4">
            <AlertBanner
              type="critical"
              messages={[
                {
                  icon: <FontAwesomeIcon icon={["far", "exclamation-circle"]} />,
                  text: (
                    <span className="font-medium">
                      {mutation.data?.updateHeadcountPlanCollaborators?.errors?.[0]?.message}
                    </span>
                  ),
                },
              ]}
            />
          </div>
        )}
        <label htmlFor="collaborators">
          {t("v2.headcount_plan.collaborators_modal.collaborators")}
        </label>
        <p className="pb-1 text-base text-neutral-64">
          {t("v2.headcount_plan.collaborators_modal.collaborators_description")}
        </p>
        <PersonSearchInput
          errorMessage=""
          htmlForId="approver_search_input"
          placeholder={`${"example_abbreviated".t("defaults")}: ${"example_name".t("defaults")}`}
          omitValues={collaboratorPersonIds}
          onSelect={onSelectPerson}
        />
        <div className="flex-col gap-2 pt-4 flex">
          <CollaboratorListItem collaborator={{ ...planner, role: "planner" }} />
          {collaborators.map((collaborator) => (
            <CollaboratorListItem
              key={collaborator.id}
              collaborator={collaborator}
              showRemovalIcon={currentPersonId !== collaborator.personId}
              onRemoveClick={onRemoveCollaborator}
            />
          ))}
        </div>
      </div>
    </Modal>
  )
}

type ListItemProps = {
  collaborator: Collaborator
  showRemovalIcon?: boolean
  onRemoveClick?: (collaborator: Collaborator) => void
}
function CollaboratorListItem({
  collaborator,
  showRemovalIcon = false,
  onRemoveClick,
}: ListItemProps) {
  const { t } = useTranslation()
  return (
    <div className="flex">
      <div className="border--main flex-1 justify-between rounded-lg bg-white px-3 py-2 text-neutral-64 flex">
        <div className="mr-3 items-center flex">
          {collaborator.avatarThumbUrl && (
            <div className="mr-3">
              <img
                className="h-6 w-6 rounded-full block"
                alt={collaborator.name}
                src={collaborator.avatarThumbUrl}
              />
            </div>
          )}
          <span>{collaborator.name}</span>
        </div>
        <div className="items-center flex">
          <p>{t(`v2.defaults.${collaborator.role}`)}</p>
        </div>
      </div>
      <div className="w-6 flex-col justify-center pl-2 flex">
        {showRemovalIcon ? (
          <button
            type="button"
            className="bg-transparent"
            onClick={() => onRemoveClick?.(collaborator)}
          >
            <FontAwesomeIcon icon={["far", "times"]} />
          </button>
        ) : null}
      </div>
    </div>
  )
}

function initializeCollaborators(existingCollaborators: ExistingCollaborator[]): Collaborator[] {
  return existingCollaborators.map((c) => ({
    id: c.uniqueKey,
    personId: c.person.id,
    name: c.person.name,
    role: c.role,
    avatarThumbUrl: c.person.avatarThumbUrl,
  }))
}

function useCollaborators(existingCollaborators: ExistingCollaborator[], isOpen: boolean) {
  const [collaborators, setCollaborators] = React.useState<Collaborator[]>(
    initializeCollaborators(existingCollaborators),
  )
  React.useEffect(() => {
    if (isOpen) {
      setCollaborators(initializeCollaborators(existingCollaborators))
    }
  }, [isOpen, existingCollaborators])

  return { collaborators, setCollaborators }
}
