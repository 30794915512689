import React from "react"
import AnimateHeight from "react-animate-height"
import cn from "classnames"
import fp from "lodash/fp"
import { useTranslation } from "react-i18next"

import { PositionSelection } from "v2/react/shared/PositionSelection"
import { AddonRole, Role, RoleScope } from "types/graphql.enums"
import { RoleItemUpdate } from "./types"

type MakeChangeHandler = (rs: RoleScope) => React.ChangeEventHandler<HTMLInputElement>

type RoleScopeInputsProps = {
  availableScopeTypes: RoleScope[]
  errorMessage?: string
  forBaseRole?: boolean
  linkKeys?: string[]
  onUpdate: (input: RoleItemUpdate) => void
  role: Role | AddonRole
  scopeType: RoleScope | null
}

const EMPTY_LINKS: string[] = []

export function RoleScopeInputs({
  availableScopeTypes,
  forBaseRole,
  errorMessage,
  linkKeys = EMPTY_LINKS,
  onUpdate,
  role,
  scopeType,
}: RoleScopeInputsProps) {
  const makeScopeChangeHandler: MakeChangeHandler = (scopeType) => (ev) =>
    ev.target.checked && onUpdate({ role, scopeType })
  const safeRoleScope = scopeType ?? fp.first(availableScopeTypes)
  const radioName = `${role}_scope`
  const handlePositionsUpdated = (linkKeys: string[]) => onUpdate({ role, linkKeys })
  const { t } = useTranslation()

  if (fp.isEmpty(availableScopeTypes)) return null
  if (availableScopeTypes.length === 1)
    return <input type="hidden" name={radioName} value={safeRoleScope} />

  // When we're rendering inputs for a base role, we don't want to render the
  // radio group within a card. But, we do want to render the position
  // autocomplete in a card. The inverse is true when we're rendering inputs for
  // an addon scope: render radio group in card and don't render position
  // autocomplete in its own card.
  const className = forBaseRole ? undefined : "card"
  const positionSelectionWrapperClassName = forBaseRole ? "card" : undefined

  return (
    <div className={cn(className, "flex-col gap-2 flex")}>
      <h3>{t(`v2.permissions.form.configure_role_scope_for_${role}_prompt`)}</h3>
      {availableScopeTypes.map((availableScope) => (
        <div key={`radio-${availableScope}`}>
          <label className="radio">
            <input
              type="radio"
              name={radioName}
              value={availableScope}
              checked={availableScope === safeRoleScope}
              onChange={makeScopeChangeHandler(availableScope)}
            />
            {t(`v2.permissions.form.scope_type_choice_${availableScope}_for_${role}`)}
          </label>
          {availableScope === RoleScope.LinkedSubordinates ? (
            <AnimateHeight duration={250} height={availableScope === safeRoleScope ? "auto" : 0}>
              <div className={cn("show", "mt-2", positionSelectionWrapperClassName)}>
                <PositionSelection
                  errorMessage={errorMessage}
                  label={t("v2.permissions.edit.which_positions")}
                  positionIds={linkKeys}
                  onPositionsUpdated={handlePositionsUpdated}
                />
              </div>
            </AnimateHeight>
          ) : null}
        </div>
      ))}
    </div>
  )
}
