import React from "react"
import { currentChartProps } from "v2/react/components/orgChart/ChartApproval/ApprovalDisplay"
import { CancelApprovalModal } from "v2/react/components/orgChart/ChartApproval/Modals/CancelApprovalModal"
import { ChartApprovalActionModal } from "v2/react/components/orgChart/ChartApproval/Modals/ChartApprovalActionModal"
import { ConfigureApprovalModal } from "v2/react/components/orgChart/ChartApproval/Modals/ConfigureApprovalModal"
import { useGetChartAndApproversQuery } from "v2/redux/GraphqlApi/ChartApprovalsApi"
import { selectConfigureModal } from "v2/redux/slices/ApprovalSlice/approvalSelectors"
import { useAppSelector } from "v2/redux/store"

interface Props {
  currentChart: currentChartProps
}

function ApprovalModals({ currentChart }: Props) {
  const showConfigureModal = useAppSelector(selectConfigureModal)

  const { data: chart } = useGetChartAndApproversQuery({ id: currentChart.chartId })

  if (!chart || !currentChart.chartId) return null

  return (
    <>
      <CancelApprovalModal />
      {showConfigureModal && currentChart.chartId && (
        <ConfigureApprovalModal
          chartApprovers={chart.chartApprovers || []}
          chartId={currentChart.chartId.toString()}
          chartStatus={chart.status || ""}
        />
      )}
      <ChartApprovalActionModal submitterName={chart?.submitterName} />
    </>
  )
}

export default ApprovalModals
