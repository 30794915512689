import React, { useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Modal } from "v2/react/shared/Modal"
import { SaveFooter } from "v2/react/shared/Modal/SaveFooter"

interface EditImageModalProps {
  isOpen: boolean
  onModalClose: () => void
  onModalSave: (blob: Blob) => void
  uploadedImage: string
}

const EditImageModal = ({
  isOpen,
  onModalClose,
  onModalSave,
  uploadedImage,
}: EditImageModalProps) => {
  const [isSaving, setIsSaving] = useState(false)

  const { imageRef, cropperActions, setImageLoaded, clearCropper } = useCropper({
    aspectRatio: 1,
    autoCropArea: 1,
  })

  const handleModalClose = () => {
    onModalClose()
    clearCropper()
  }

  const handleModalSave = (
    e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault()

    // Double clicking the save button can lead to issues unless we ensure
    // the cropped canvas exists here.
    const croppedCanvas = cropperActions.getCroppedCanvas()
    if (!croppedCanvas) return

    setIsSaving(true)

    croppedCanvas.toBlob((blob) => {
      if (blob) onModalSave(blob)
    })

    handleModalClose()
    setIsSaving(false)
  }

  return (
    <Modal isOpen={isOpen} title={"Edit Image".t("profile", "avatar")} onClose={handleModalClose}>
      <form className="image-editor-modal-contents">
        <div className="react-modal__body">
          <div className="crop-area">
            <img
              id="cropper-image"
              className="max-w-full block"
              ref={imageRef}
              src={uploadedImage}
              alt="To be cropped"
              onLoad={() => setImageLoaded(true)}
            />
          </div>
          <div id="cropper-actions" className="justify-center gap-2 flex">
            <button
              type="button"
              className="btn btn--secondary"
              onClick={cropperActions.rotateLeft}
            >
              <FontAwesomeIcon icon={["far", "undo-alt"]} />
            </button>
            <button
              type="button"
              className="btn btn--secondary"
              onClick={cropperActions.rotateRight}
            >
              <FontAwesomeIcon icon={["far", "redo-alt"]} />
            </button>
            <button type="button" className="btn btn--secondary" onClick={cropperActions.zoomIn}>
              <FontAwesomeIcon icon={["far", "search-plus"]} />
            </button>
            <button type="button" className="btn btn--secondary" onClick={cropperActions.zoomOut}>
              <FontAwesomeIcon icon={["far", "search-minus"]} />
            </button>
          </div>
        </div>
        <SaveFooter isSaving={isSaving} onSave={handleModalSave} />
      </form>
    </Modal>
  )
}

interface UseCropperOptions {
  aspectRatio?: number
  autoCropArea?: number
}

interface CropperActions {
  rotateRight: () => void
  rotateLeft: () => void
  zoomIn: () => void
  zoomOut: () => void
  getCroppedCanvas: () => HTMLCanvasElement
}

const useCropper = (options: UseCropperOptions) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const imageRef = useRef<HTMLImageElement | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cropperRef = useRef<any>(null)

  const cropperActions: CropperActions = {
    rotateRight: () => cropperRef.current?.rotate(90),
    rotateLeft: () => cropperRef.current?.rotate(-90),
    zoomIn: () => cropperRef.current?.zoom(0.1),
    zoomOut: () => cropperRef.current?.zoom(-0.1),
    getCroppedCanvas: () => cropperRef.current?.getCroppedCanvas(),
  }

  if (imageLoaded && imageRef.current && window.Cropper) {
    cropperRef.current = new window.Cropper(imageRef.current, options)
  }

  const clearCropper = () => {
    setImageLoaded(false)
    imageRef.current = null
    cropperRef.current = null
  }

  return { imageRef, cropperActions, setImageLoaded, clearCropper }
}

export { EditImageModal }
