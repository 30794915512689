import React from "react"
import { LinkBlockLarge } from "v2/react/components/navigation/PageNav/LinkBlock"
import PageNav from "v2/react/components/navigation/PageNav"
import { TitleBlockSmall } from "v2/react/components/navigation/PageNav/TitleBlock"
import { Link, LinkGroup } from "v2/react/shared/PageNavigation/LinkGroup"

interface Props {
  links: Link[]
}

function ReportsNav({ links }: Props) {
  return (
    <PageNav>
      <TitleBlockSmall>
        <h1>{"reports".t("reports")}</h1>
      </TitleBlockSmall>
      <LinkBlockLarge>
        <LinkGroup links={links} />
      </LinkBlockLarge>
    </PageNav>
  )
}

export { ReportsNav }
