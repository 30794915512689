import { useMemo } from "react"
import { useAppSelector } from "v2/redux/store"
import { selectDisplayFieldOptions } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"

import type { DisplayFieldOption } from "types/graphql"

type DisplayOptionsMap = Record<string, DisplayFieldOption>

const BASIC_DISPLAY_FIELD_OPTIONS = ["avatar", "name", "title"]

function useDisplayFieldOptions() {
  const displayFieldOptions = useAppSelector(selectDisplayFieldOptions)

  const unorderedBasicDisplayFieldOptions = useMemo(
    () => displayFieldOptions.filter((field) => field.type === "basic"),
    [displayFieldOptions],
  )
  const basicFieldMap: DisplayOptionsMap = useMemo(
    () =>
      unorderedBasicDisplayFieldOptions.reduce((acc, field) => {
        acc[field.id] = field
        return acc
      }, {} as DisplayOptionsMap),
    [unorderedBasicDisplayFieldOptions],
  )
  const basicDisplayFieldOptions = useMemo(
    () => BASIC_DISPLAY_FIELD_OPTIONS.map((fieldId) => basicFieldMap[fieldId]),
    [basicFieldMap],
  )

  const advancedDisplayFieldOptions = useMemo(
    () => displayFieldOptions.filter((field) => field.type === "advanced"),
    [displayFieldOptions],
  )
  const advancedFieldMap: DisplayOptionsMap = useMemo(
    () =>
      advancedDisplayFieldOptions.reduce((acc, field) => {
        acc[field.id] = field
        return acc
      }, {} as DisplayOptionsMap),
    [advancedDisplayFieldOptions],
  )

  return {
    displayFieldOptions,
    basicDisplayFieldOptions,
    advancedDisplayFieldOptions,
    advancedFieldMap,
    basicFieldMap,
  }
}

export { useDisplayFieldOptions }
