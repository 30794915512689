import React from "react"
import Tag from "v2/react/shared/Tag"
import { useGetPositionQuery } from "v2/redux/GraphqlApi"
import { Tag as TagType } from "types/graphql.d"
import PageSection from "./PageSection"

interface Props {
  positionId: string
}

function groupByDomain(tags: TagType[] | null): Record<string, string[]> {
  if (!tags) return {}
  return tags.reduce((acc: Record<string, string[]>, tag) => {
    const domainName = tag.tagDomain.name
    if (!acc[domainName]) {
      acc[domainName] = []
    }
    acc[domainName].push(tag.value)

    return acc
  }, {})
}

export default function PositionRequirements({ positionId }: Props) {
  const { data, isLoading } = useGetPositionQuery({ positionId })

  if (isLoading || !data?.position) return null
  const position = data.position
  const groupedTags = groupByDomain(position.positionType?.globalPositionType?.tags ?? null)

  return (
    <PageSection title="Requirements" columnCount={2}>
      <div className="border-r border-neutral-8 p-4">
        <p className="mb-4 font-bold">Skill Requirements</p>
        <div className="items-top flex-wrap justify-start gap-2 inline-flex">
          {[...(groupedTags?.skills ?? []), ...(groupedTags?.requirements ?? [])].map(
            (tagValue) => (
              <Tag content={tagValue} colorStyle="status-excite" />
            ),
          )}
        </div>
      </div>

      <div className="p-4">
        <p className="mb-4 font-bold">Key Tasks and Responsibilities</p>
        <div className="items-top flex-wrap justify-start gap-2 inline-flex">
          {groupedTags?.tasks_and_responsibilities?.map((tagValue) => (
            <Tag content={tagValue} colorStyle="neutral" />
          ))}
        </div>
      </div>

      <div className="border-r border-neutral-8 p-4">
        <p className="mb-4 font-bold">Worker Characteristics</p>
        <div className="items-top flex-wrap justify-start gap-2 inline-flex">
          {groupedTags?.worker_characteristics?.map((tagValue) => (
            <Tag content={tagValue} colorStyle="status-success" />
          ))}
        </div>
      </div>

      <div className="p-4">
        <p className="mb-4 font-bold">Knowledge, Education, and Certification</p>
        <div className="items-top flex-wrap justify-start gap-2 inline-flex">
          {[
            ...(groupedTags?.required_knowledge ?? []),
            ...(groupedTags?.required_education ?? []),
            ...(groupedTags?.certifications_required ?? []),
          ].map((tagValue) => (
            <Tag content={tagValue} colorStyle="info" />
          ))}
        </div>
      </div>
    </PageSection>
  )
}
