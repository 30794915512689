import React from "react"
import { Boundary } from "@floating-ui/react"

import {
  CELL_TOOLTIP_DELAY_MS,
  CELL_TOOLTIP_CLOSE_DELAY_MS,
} from "v2/react/shared/Datasheet/utils/constants"
import { StylesProps } from "v2/react/shared/Datasheet/Cell/StyleLayers/StyleLayers"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/Tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface Props {
  boundary?: Boundary
  errorMessage?: string
  styles: StylesProps
}

function CellErrorLayer({ boundary, errorMessage, styles }: Props) {
  if (!errorMessage) return null

  return (
    <Tooltip
      boundary={boundary}
      boundaryPadding={40}
      initialOpen
      placement="top"
      showDelay={CELL_TOOLTIP_DELAY_MS}
      closeDelay={CELL_TOOLTIP_CLOSE_DELAY_MS}
    >
      <TooltipContent
        type="content-animated-wrapped"
        className="react-tooltip-content react-tooltip-content-error"
      >
        <FontAwesomeIcon icon={["far", "exclamation-circle"]} />
        {errorMessage}
      </TooltipContent>
      <TooltipTrigger asChild style={styles} type="button">
        <div className="Cell__border Cell--error" />
      </TooltipTrigger>
    </Tooltip>
  )
}

export { CellErrorLayer }
