import React from "react"
import classNames from "classnames"

export type Tab = "edge" | "card"
type ColorCodeTypeTabsProps = {
  activeTab: Tab
  onTabClick: (tab: Tab) => void
}

function ColorCodeTypeTabs({ activeTab, onTabClick }: ColorCodeTypeTabsProps) {
  const triggerTabClickOn: (tab: Tab) => React.MouseEventHandler = (tab) => (ev) => {
    ev.preventDefault()
    onTabClick(tab)
  }

  return (
    <div className="btn-group flex" role="tablist">
      <button
        className={tabClassName(activeTab === "card")}
        type="button"
        onClick={triggerTabClickOn("card")}
      >
        {"Card".t("org_chart")}
      </button>
      <button
        className={tabClassName(activeTab === "edge")}
        type="button"
        onClick={triggerTabClickOn("edge")}
      >
        {"Edge".t("org_chart")}
      </button>
    </div>
  )
}

const tabClassName = (isActive: boolean) =>
  classNames("btn grow", {
    active: isActive,
  })

export { ColorCodeTypeTabs }
