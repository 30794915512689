import React from "react"
import { ListItem, OrderedList } from "v2/react/shared/OrderedList/OrderedList"
import { Approver, Person } from "types/graphql"
import { PersonSearchInput } from "v2/react/shared/PersonSearchInput"
import { approverSortOrderAsc } from "v2/react/utils/sorts"

interface Props {
  approvers: Approver[]
  onApproversUpdated: (updatedApprovers: Approver[]) => void
  description: string
  title: string
  translationDomain: string
}

function ApproverSelection({
  description,
  onApproversUpdated,
  approvers,
  title,
  translationDomain,
}: Props) {
  const listItems: ListItem[] = filteredListItemsFromApprovers(
    approvers,
    (a) => a.state === "pending_approval",
    translationDomain,
  )

  // Approvers with status needed to be filtered out and put in a different list
  // in order to make the drag and drop work effectively
  const disabledListItems: ListItem[] = filteredListItemsFromApprovers(
    approvers,
    (a) => a.state !== "pending_approval",
    translationDomain,
  )

  const itemToApprover = (item: ListItem): Approver => {
    const approver: Approver = approvers.find((a) => a.person.id === item.value) as Approver
    return {
      ...approver,
      sortOrder: item.index,
    }
  }

  const onListItemRemove = (removedItem: ListItem) => {
    const updatedApprovers: Approver[] = (approvers || [])
      .filter((a) => a.person.id !== removedItem.value)
      .map((a, i) => ({ ...a, sortOrder: i }))

    onApproversUpdated(updatedApprovers)
  }

  const onPersonSelect = (newPerson: Person) => {
    const newApprover: Approver = {
      id: "",
      person: newPerson,
      sortOrder: approvers.length,
      state: "pending_approval",
    }
    onApproversUpdated([...approvers, newApprover])
  }

  const reorderItems = (items: ListItem[]) => {
    const updatedEnabledApprovers: Approver[] = items
      .map(mapToResetActiveIndexes)
      .map(itemToApprover)
    const disabledApprovers = disabledListItems.map(itemToApprover)
    onApproversUpdated(disabledApprovers.concat(updatedEnabledApprovers))
  }

  const mapToResetActiveIndexes = (item: ListItem, i: number) => ({
    ...item,
    index: i + disabledListItems.length,
  })

  return (
    <div className="approvals-selection-form-section OrderedList__spread">
      <div className="approvals-selection-form-title">{title}</div>
      <div className="approvals-selection-form-description">{description}</div>
      <PersonSearchInput
        htmlForId="person_search_input"
        omitValues={(approvers || []).map((a) => a.person.id)}
        onSelect={onPersonSelect}
        placeholder={`${"example_abbreviated".t("defaults")}: ${"example_name".t("defaults")}`}
      />
      <div className="mb-2 mt-6">
        {/* classes adjust the list style to make sure borders/border-radius are applied appropriately */}
        <OrderedList
          listClasses={listItems.length ? "OrderedList-first" : ""}
          onRemove={onListItemRemove}
          values={disabledListItems}
          handleValues={reorderItems}
          draggableListClasses="bg-transparent shadow-none p-0"
        />
        <OrderedList
          listClasses={disabledListItems.length ? "OrderedList-last" : ""}
          onRemove={onListItemRemove}
          values={listItems}
          handleValues={reorderItems}
          draggableListClasses="bg-transparent shadow-none p-0"
        />
      </div>
    </div>
  )
}

const filteredListItemsFromApprovers = (
  approvers: Approver[],
  filterApprovers: (a: Approver) => void,
  translationDomain: string,
): ListItem[] =>
  (approvers && approvers.length > 0 ? approvers : [])
    .slice()
    .sort(approverSortOrderAsc)
    .filter((a) => filterApprovers(a))
    .map((a) => ({
      disable: formatStatusLabel(a.state, translationDomain, "pending_approval"),
      index: a.sortOrder || 0,
      label: a.person.name,
      thumbUrl: a.person.avatarThumbUrl || "",
      value: a.person.id.toString(),
    }))

const formatStatusLabel = (
  state: string,
  translationDomain: string,
  activeState: string,
): string | undefined => (state === activeState ? undefined : state.t(translationDomain))

export { ApproverSelection }
