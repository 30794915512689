import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState, useRef, useEffect, KeyboardEvent } from "react"

interface MenuOption<T extends React.Key> {
  value: T
  label: string
  labelDescription?: string
  iconPath?: string
}

interface Props<T extends React.Key> {
  label?: string
  options: MenuOption<T>[]
  selected: T
  onSelect: (value: T) => void
  inline?: boolean
  fullWidth?: boolean
  rightAlign?: boolean
}

function FormDropdown<T extends React.Key>({
  label,
  onSelect,
  options,
  selected,
  inline = false,
  fullWidth = true,
  rightAlign = true,
}: Props<T>) {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const selectedOption = options.find((option) => option.value === selected) || options[0]

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const handleSelect = (option: T) => {
    setIsOpen(false)
    onSelect(option)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>, option: T) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault()
      handleSelect(option)
    }
  }

  const alignClass = rightAlign ? "form-dropdown__content--right" : "form-dropdown__content--left"
  const visibiltyClass = isOpen ? "" : "form-dropdown__content--hidden"

  return (
    <div className="form-dropdown input-group">
      {label && <div className="form-dropdown__label">{label}</div>}
      <div ref={dropdownRef}>
        <button
          className="form-dropdown__dropdown-link"
          style={buttonStyle(inline)}
          type="button"
          onClick={toggleOpen}
        >
          <div className="form-dropdown__selected-text">{selectedOption.label}</div>
          <FontAwesomeIcon icon="caret-down" />
        </button>
        <div
          className={`form-dropdown__content ${alignClass} ${visibiltyClass}`}
          style={widthStyle(fullWidth, inline)}
        >
          {options.map((option) => (
            <div
              key={option.value}
              className={`form-dropdown__dropdown-menu-link ${
                option.value === selected ? "active" : ""
              }`}
              onClick={() => handleSelect(option.value)}
              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
                handleKeyDown(event, option.value)
              }
              tabIndex={0}
              role="button"
            >
              <div className="form-dropdown__icons-wrapper">
                <FontAwesomeIcon icon={["far", "check"]} className="selected-indicator" />
                {option.iconPath && <img src={option.iconPath} alt={option.label} />}
              </div>
              <div className="form-dropdown__option-text">
                {option.labelDescription ? (
                  <>
                    <div className="form-dropdown__option-title">{option.label}</div>
                    <div className="form-dropdown__option-description">
                      {option.labelDescription}
                    </div>
                  </>
                ) : (
                  option.label
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const buttonStyle = (isInline: boolean): React.CSSProperties => {
  if (isInline)
    return {
      border: 0,
      padding: "0 1rem",
      height: "auto",
    }
  return {}
}

const widthStyle = (isFullWidth: boolean, isInline: boolean): React.CSSProperties => {
  if (isFullWidth)
    return {
      maxWidth: "none",
      width: "100%",
      boxSizing: "border-box",
    }
  if (isInline)
    return {
      maxWidth: "20rem",
      width: "20rem",
    }
  return {}
}

export { FormDropdown }
