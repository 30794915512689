import { FieldType } from "v2/react/shared/TableDatasheet/types"

export enum CursorState {
  /** Cursor is on nothing. */
  Unit = "Unit",
  /** Cursor is on an editable cell. */
  OnEditable = "OnEditable",
  /** Cursor is on a non-editable cell. */
  OnNonEditable = "OnNonEditable",
  /** Cursor is writing changes to an editable cell. */
  WritingOnEditable = "WritingOnEditable",
}

export type CellCursor =
  | CursorUnit
  | CursorOnNonEditableCell
  | CursorOnEditableCell
  | CursorWritingOnEditableCell

export interface CursorUnit {
  state: CursorState.Unit
}

export interface CursorOnNonEditableCell extends ActiveCursor {
  editable: false
  fieldType: undefined
  state: CursorState.OnNonEditable
}

export interface CursorOnEditableCell extends ActiveCursor {
  editable: true
  fieldType: FieldType
  state: CursorState.OnEditable
}

export interface CursorWritingOnEditableCell extends ActiveCursor {
  editable: true
  fieldType: FieldType
  state: CursorState.WritingOnEditable
}

interface ActiveCursor {
  currentValue: string | null
  enteredBy: "movement" | "placement" | "transition"
  columnId: string
  rowId: string
  state: CursorState
}

export type CursorEventDetail = {
  originalCursor: CellCursor
  cursor: CellCursor
}
