import fp from "lodash/fp"

import { FieldKey, NodePropValue } from "v2/redux/slices/NodeSlice/types"
import { NodeInterface } from "types/graphql"

import RelationalNodeDataStore from "org_chart/chart/utils/relationalNodeDataStore"

const fieldsWithMultipleValues = ["funding_sources", "reports_to"]

function nodeProp(fieldKey: FieldKey, node: NodeInterface): NodePropValue {
  const related = RelationalNodeDataStore.instance
  if (related.hasFieldDefinition(fieldKey)) return related.valueOf(fieldKey, node, null)

  // This is a temporary measure until we can transition the backend to return
  // proper arrays for these fields.
  return fp.includes(fieldKey, fieldsWithMultipleValues)
    ? fp.split(", ", fp.propOr([], fieldKey, node))
    : fp.propOr(null, fieldKey, node)
}

function formattedNodeProp(fieldKey: FieldKey, node: NodeInterface) {
  const value = nodeProp(fieldKey, node)
  if (typeof value === "undefined" || value === null) return ""
  if (Array.isArray(value)) return fp.join(", ", value)

  return value.toString()
}

const formattedNodeProps = (fieldKeys: FieldKey[], node: NodeInterface) =>
  fp.map((fieldKey) => formattedNodeProp(fieldKey, node), fieldKeys)

const nodePropCurried = fp.curry(nodeProp)
const formattedNodePropCurried = fp.curry(formattedNodeProp)
const formattedNodePropsCurried = fp.curry(formattedNodeProps)

export {
  formattedNodePropCurried as formattedNodeProp,
  formattedNodePropsCurried as formattedNodeProps,
  nodePropCurried as nodeProp,
}
