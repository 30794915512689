import { z } from "zod"

import type { EntityId, EntityState, PayloadAction } from "@reduxjs/toolkit"

import type { List, Maybe, NodeInterface } from "types/graphql"

export interface ContainerState {
  chartSections: EntityState<ChartSection>
  chartSectionsCollapsedIndex: { [key in EntityId]: boolean }
  chartSectionTreeIndex: ChartSectionTreeIndex
  containerKey: Maybe<EntityId & string>
  fields: Field[]
  lists: List[]
}

export const ChartSectionSchema = z.object({
  id: z.number().transform((number) => number.toString()),
  name: z.string().nonempty(),
  color: z.string().nonempty(),
  head: z.object({
    id: z.number(),
    title: z.string().nullable(),
    person: z
      .object({
        id: z.number(),
        name: z.string().nonempty(),
      })
      .nullable(),
  }),
})

export type ChartSectionTreeIndex = {
  [key: string]: ChartSectionTreeIndex
}

export const ChartSectionTreeIndexSchema: z.ZodType<ChartSectionTreeIndex> = z.record(
  z.string(),
  z.lazy(() => ChartSectionTreeIndexSchema),
)

export type ChartSection = z.infer<typeof ChartSectionSchema>

export interface ChartSectionTreeNode extends ChartSection {
  children: ChartSectionTreeNode[]
  descendantsCount: number
  hasChildren: boolean
  level: number
  selfAndDescendantsCount: number
}

export const ChartSectionsFetchResponsePayloadSchema = z.object({
  chart_sections_nested: ChartSectionTreeIndexSchema,
  chart_sections: z.array(ChartSectionSchema),
})

export interface Field {
  fieldKey: keyof NodeInterface
  label: string
  enabled: boolean
  filterable: boolean
  restricted: boolean
  width?: number
  hideLabel: boolean
}

export type CollapseExpandChartSectionAction = PayloadAction<{ id: EntityId; collapsed?: boolean }>
