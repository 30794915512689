import React from "react"
import { Option } from "types/graphql"

interface TagListDisplayProps {
  tagList: Option[]
}

function TagListDisplay({ tagList }: TagListDisplayProps) {
  return (
    <div className="flex-wrap gap-2 flex">
      {tagList.map((tag, i) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <div key={tag.id + i} className="badge--blue">
          <span>{tag.label}</span>
        </div>
      ))}
    </div>
  )
}

export { TagListDisplay }
