import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"
import { ContentType } from "v2/react/components/positionTypes/modals/types"

interface Props {
  infoBannerType: ContentType
}

function InfoBanner({ infoBannerType }: Props) {
  const { t } = useTranslation()

  if (infoBannerType === "definition") {
    return (
      <div className="alert-neutral">
        <div className="mb-4 items-center gap-1.5 flex">
          <FontAwesomeIcon icon={["far", "info-circle"]} />
          {t("v2.position_types.show.edit_job_definition_alert_title")}
        </div>
        <div className="5 gap-1 flex">
          <div className="w-3.5" />
          <span>{t("v2.position_types.show.edit_job_definition_alert_body")}</span>
        </div>
      </div>
    )
  }

  return (
    <div className="alert-neutral">
      <div className="gap-1.5 flex">
        <FontAwesomeIcon icon={["far", "info-circle"]} className="mt-1" />
        {t("v2.position_types.show.edit_job_definition_alert_body")}
      </div>
    </div>
  )
}

export { InfoBanner }
