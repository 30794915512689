import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export function CreatePlanButton() {
  return (
    <a href="/headcount_plans/setup" className="btn--large btn--primary whitespace-nowrap">
      <FontAwesomeIcon icon={["far", "plus"]} />
      <span>{"create_new_plan_button".t("headcount_plan")}</span>
    </a>
  )
}
