import React, { MouseEvent } from "react"
import RootProvider from "v2/react/components/RootProvider"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "v2/redux/store"
import { LinkGroup } from "v2/react/shared/PageNavigation/LinkGroup"
import { asyncPatchPreferences } from "v2/redux/slices/ContainerSlice/containerThunks"

function OrgChartNavLinksInner() {
  const displayMode = useAppSelector((state) => state.visualization.displayMode)
  const displayModePrevious = useAppSelector((state) => state.visualization.displayModePrevious)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const isListView = window.location.pathname.indexOf("lists/") > 0

  const orgLinks = [
    {
      text: isListView ? t("v2.orgchart.pagenav.cards") : t("v2.orgchart.pagenav.org_chart"),
      url: "/orgchart",
      active: displayMode !== "grid",
      show: true,
      onClick: async (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (displayMode === "grid") {
          await dispatch(
            asyncPatchPreferences({
              display_mode: isListView ? "cards" : displayModePrevious || "tree",
            }),
          ).then(() => {
            window.location.reload()
          })
        }
      },
    },
    {
      text: t("v2.orgchart.pagenav.datasheet"),
      url: "/orgchart",
      active: displayMode === "grid",
      show: true,
      onClick: async (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (displayMode === "grid") return
        await dispatch(
          asyncPatchPreferences({ display_mode: "grid", display_mode_previous: displayMode }),
        ).then(() => {
          window.location.reload()
        })
      },
    },
  ]
  return <LinkGroup links={orgLinks} showAll />
}

function OrgChartNavLinks() {
  return (
    <RootProvider>
      <OrgChartNavLinksInner />
    </RootProvider>
  )
}

export { OrgChartNavLinks }
