import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DropdownItem } from "v2/react/shared/Dropdown/DropdownItem"
import { useCopyInline, CopyInlineContent, ValueToCopy } from "v2/react/shared/Dropdown/CopyInline"

const CopyInlineDropdownItem = ({
  valueToCopy,
  label,
}: {
  valueToCopy: ValueToCopy
  label: string
}) => {
  const { initiateCopyValue, showCopyIndicator } = useCopyInline({ valueToCopy })

  return (
    <DropdownItem
      onClick={initiateCopyValue}
      closeAfterClick={false}
      withIcon={<FontAwesomeIcon icon={["far", "clone"]} />}
    >
      <CopyInlineContent label={label} showCopyIndicator={showCopyIndicator} />
    </DropdownItem>
  )
}

export { CopyInlineDropdownItem }
