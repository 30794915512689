import React, { useState } from "react"
import { sortingFns } from "@tanstack/react-table"
import { useTranslation } from "react-i18next"
import { zipObject } from "lodash"
import { useCsvDownloadListener } from "v2/react/shared/TableDatasheet/hooks/useCsvDownloadListener"
import { useGetPositionsOverTimeDrillDownQuery } from "v2/redux/GraphqlApi/PositionReportsApi"
import { useClientTable } from "v2/react/hooks/useClientTable"

import type { OnCellClick } from "v2/react/shared/TimelineTable/types"
import { PositionsOverTimeMetrics, TimelineIntervalTypeEnum } from "types/graphql.enums"
import type { PositionsOverTimeDrillDownQuery } from "types/graphql"
import type { ColumnDef } from "@tanstack/react-table"

import { AsyncModal } from "v2/react/shared/Modal/AsyncModal"
import { ErrorFallback } from "v2/react/shared/Modal/ErrorFallback"
import { Table } from "v2/react/shared/Table"
import { ExportButton } from "v2/react/shared/ExportButton"

type DrillDownProps = Omit<Parameters<OnCellClick>[0], "metricKey" | "groupByCells"> & {
  metricKey: PositionsOverTimeMetrics
  intervalType: TimelineIntervalTypeEnum
  selectedGroups: string[]
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

type ColumnType = NonNullable<
  NonNullable<
    PositionsOverTimeDrillDownQuery["positionReports"]["positionsOverTime"]
  >["drillDownData"]
>["columns"][number]

const DrillDown = ({
  metricKey,
  metricLabel,
  intervalType,
  intervalStart,
  intervalEnd,
  selectedGroups,
  rowId,
  isOpen,
  setIsOpen,
}: DrillDownProps) => {
  const { t } = useTranslation()
  const [csvDownloadRef, setCsvDownloadRef] = useState<HTMLButtonElement | null>(null)
  const { data, isLoading, isFetching, isError } = useGetPositionsOverTimeDrillDownQuery(
    {
      metrics: [metricKey],
      startDate: intervalStart,
      endDate: intervalEnd,
      groupBy: selectedGroups,
      filterBy: buildFilterBy(rowId, selectedGroups),
      intervalType,
    },
    { skip: !isOpen },
  )

  const columns = data?.positionReports?.positionsOverTime?.drillDownData?.columns || []
  const rows = data?.positionReports?.positionsOverTime?.drillDownData?.rows || []

  const table = useClientTable({
    columns: useDrillDownTableColumns(columns),
    data: rows,
  })

  const isDataLoading = isLoading || isFetching

  const downloadName = makeDownloadName(metricKey, intervalStart, intervalEnd, selectedGroups)
  useCsvDownloadListener({ current: csvDownloadRef }, table, downloadName)

  return (
    <AsyncModal
      isOpen={isOpen}
      isLoading={isDataLoading}
      title={metricLabel}
      onClose={() => setIsOpen(false)}
      size="xl"
    >
      <ErrorFallback isError={isError} errorMessage={t("v2.defaults.error")}>
        <div className="react-modal__body">
          <div className="flex-row-reverse pb-6 flex">
            <ExportButton
              setRef={(element) => setCsvDownloadRef(element)}
              disabled={rows?.length === 0}
            />
          </div>
          <Table
            table={table}
            footerContent={t("v2.positions.index.positions_found", { count: rows.length })}
          />
        </div>
      </ErrorFallback>
    </AsyncModal>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useDrillDownTableColumns = (columns: ColumnType[]): ColumnDef<any>[] =>
  columns.map((column) => ({
    header: column.label,
    accessorKey: column.id,
    sortingFn: (rowA, rowB, columnId) =>
      isDateColumn(columnId)
        ? Date.parse(rowA.original[columnId]) - Date.parse(rowB.original[columnId])
        : sortingFns.alphanumeric(rowA, rowB, columnId),
  }))

const isDateColumn = (columnId: string) =>
  ["created_at", "deleted_at", "transferred_on"].includes(columnId)

const extractGroupIds = (rowId: string) => {
  // see: Positions::Reports::PositionsOverTimePresenter#row_id
  const regex = /####(.*?)####/g
  const matches = Array.from(rowId.matchAll(regex), (m) => m[1])
  return matches
}

const buildFilterBy = (rowId: string, selectedGroups: string[]) =>
  zipObject(selectedGroups, extractGroupIds(rowId))

const makeDownloadName = (
  metricKey: string,
  intervalStart: string,
  intervalEnd: string,
  selectedGroups: string[],
): string => {
  const groups = selectedGroups.length > 0 ? `_${selectedGroups.join("_")}` : ""
  return `${metricKey}${groups}_${intervalStart}_${intervalEnd}`
}

export { DrillDown }
export type { DrillDownProps }
