import React from "react"
import AnimateHeight from "react-animate-height"

import { ListTextInput } from "v2/react/shared/ListTextInput"
import { SsoProviderType } from "v2/react/components/company/generalSettings/types"

interface Props {
  initialValues: string[]
  onChange: (field: string, updatedData: string[]) => void
  providerName: string
  showRestriction: boolean
}

function SsoProviderOptionRestrictions({
  initialValues,
  onChange,
  providerName,
  showRestriction,
}: Props) {
  const height = showRestriction ? "auto" : 0

  const handleAzureRestrictionsChange = (updatedValue: string[]) => {
    onChange("ssoAzureTenantIdRestrictions", updatedValue)
  }

  const handleGoogleRestrictionsChange = (updatedValue: string[]) => {
    onChange("ssoGoogleDomainRestrictions", updatedValue)
  }

  return (
    <div>
      <AnimateHeight duration={250} height={height}>
        <div>
          {providerName === SsoProviderType.GOOGLE_SSO && (
            <div className="card show my-2">
              <label htmlFor="sso_google_domain_restrictions">
                {"sso_google_domains".t("general_settings")}
              </label>
              <ListTextInput
                onChange={handleGoogleRestrictionsChange}
                placeholder={`${tExample()}: builtapp.com`}
                value={initialValues}
              />
            </div>
          )}
          {providerName === SsoProviderType.AZURE_SSO && (
            <div className="card show my-2">
              <label htmlFor="sso_azure_tenant_id_restrictions">
                {"sso_azure_tenant_ids".t("general_settings")}
              </label>
              <ListTextInput
                onChange={handleAzureRestrictionsChange}
                placeholder={`${tExample()}: 00000000-0000-0000-0000-000000000000`}
                value={initialValues}
              />
            </div>
          )}
        </div>
      </AnimateHeight>
    </div>
  )
}

const tExample = () => "example_abbreviated".t("defaults").toLowerCase()

export { SsoProviderOptionRestrictions }
