import React from "react"
import { getNavLinks } from "v2/react/components/headcountPlanning/shared/navigation"
import { ActionBlock } from "v2/react/components/navigation/PageNav/ActionBlock"
import { LinkBlockSmall } from "v2/react/components/navigation/PageNav/LinkBlock"
import PageNav from "v2/react/components/navigation/PageNav"
import { TitleBlockLarge } from "v2/react/components/navigation/PageNav/TitleBlock"
import { LinkGroup } from "v2/react/shared/PageNavigation/LinkGroup"
import { Spinner } from "v2/react/shared/Spinner"
import { useGetOwnerDatasheetPageQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useWindowSize } from "usehooks-ts"
import { ExportButton } from "v2/react/shared/ExportButton"
import { UrlHelper } from "v2/react/utils/urls"
import { HeadcountPlanningNavTitleWithSubtitle } from "./HeadcountPlanningNav"
import { FinalizePlanButton, FinalizePlanModal } from "./Overview/FinalizePlanButton"
import { PlanTopbarMenu } from "./PlanTopbarMenu"
import { TimelineView } from "./shared/TimelineView"
import RootProvider from "../RootProvider"

export function OwnerTimeline({ headcountPlanId }: { headcountPlanId: string }) {
  const ownerPage = useGetOwnerDatasheetPageQuery({ headcountPlanId })
  const headcountPlan = ownerPage.data?.headcountPlan
  const isFinalized = !!headcountPlan?.lockedAt
  const { t } = useTranslation()
  const csvDownloadName = [
    headcountPlan?.name?.replace(" ", ""),
    t("v2.defaults.timeline"),
    dayjs(new Date()).format("YYYYMMDD"),
  ].join("_")

  const [csvDownloadRef, setCsvDownloadRef] = React.useState<HTMLButtonElement | null>(null)

  const [finalizePlanModalIsOpen, setFinalizePlanModalIsOpen] = React.useState(false)

  const w = useWindowSize()

  const isExtraSmallScreen = w.width < 640

  if (ownerPage.isLoading) return <Spinner />

  return (
    <RootProvider>
      <PageNav>
        <TitleBlockLarge>
          <HeadcountPlanningNavTitleWithSubtitle
            title={headcountPlan.name}
            subtitle="Headcount Planning"
            subtitleLinkTo={UrlHelper.headcountPlansPath()}
          />
        </TitleBlockLarge>
        <LinkBlockSmall>
          <LinkGroup links={getNavLinks({ isOwner: true, active: "Timeline", headcountPlanId })} />
        </LinkBlockSmall>
        <ActionBlock>
          <div className="gap-2 flex">
            {!isFinalized && !isExtraSmallScreen ? (
              <>
                <FinalizePlanModal
                  headcountPlanId={headcountPlanId}
                  headcountPlanName={headcountPlan.name}
                  isOpen={finalizePlanModalIsOpen}
                  setIsOpen={setFinalizePlanModalIsOpen}
                />
                <FinalizePlanButton
                  canBeFinalized={headcountPlan.canBeFinalized}
                  setIsOpen={setFinalizePlanModalIsOpen}
                />
              </>
            ) : null}
            {!isExtraSmallScreen ? (
              <ExportButton setRef={(element) => setCsvDownloadRef(element)} />
            ) : null}
            <PlanTopbarMenu
              isFinalized={isFinalized}
              headcountPlanId={headcountPlanId}
              showExportButton={isExtraSmallScreen}
              showFinalizeButton={
                isExtraSmallScreen && headcountPlan.canBeFinalized && !isFinalized
              }
              setRef={(element) => setCsvDownloadRef(element)}
            />
          </div>
        </ActionBlock>
      </PageNav>
      <TimelineView
        headcountPlanId={headcountPlanId}
        csvDownloadRef={{ current: csvDownloadRef }}
        csvDownloadName={csvDownloadName}
      />
    </RootProvider>
  )
}
