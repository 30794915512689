import React from "react"

interface ApprovalBarProps {
  styles: string
  title: React.ReactNode
  children: React.ReactNode
}

function ApprovalBar({ styles, title, children }: ApprovalBarProps) {
  return (
    <div className={styles}>
      <div className="m-0 font-bold">{title}</div>
      {children}
    </div>
  )
}

export { ApprovalBar }
