import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import classNames from "classnames"
import { motion } from "framer-motion"

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
}

interface ToggleProps {
  id?: string
  initialValue: boolean
  onToggle?: (value: boolean) => void
  children: ReactNode
}

interface ToggleLabelProps {
  classes?: string
  disabledLabel: ReactNode
  enabledLabel: ReactNode
}

interface ToggleButtonProps {
  children: ReactNode
  classes?: string
}

type ToggleContextType = { id: string | undefined; isOn: boolean; toggle: () => void }

const ToggleContext = createContext<ToggleContextType>({
  id: undefined,
  isOn: false,
  /* eslint-disable-next-line @typescript-eslint/no-empty-function */
  toggle: () => {},
})

/**
 * Renders a Toggle switch
 *
 * The Toggle is comprised of four components, all of which are exported from here.
 * This allows for customization of the Toggle click target as well as layout
 *
 * @example The Components
 *
 *     <Toggle id="dark-mode" initialValue={true} onToggle={setDarkMode}>
 *       <ToggleButton classes="flex items-center gap-2">
 *        <ToggleLabel disabledLabel="Off" enabledLabel="On" />
 *        <ToggleSwitch />
 *       </ToggleButton>
 *     </Toggle>
 *
 * @public
 */
const Toggle = ({ children, id, initialValue, onToggle }: ToggleProps) => {
  const [initialize, setInitialize] = useState(false)
  const [toggleContext, setToggleContext] = useState({ id, isOn: false })

  useEffect(() => {
    // It's necessary to set 'isOn' from the useEffect here.
    // Without this, it doesn't listen for changes in initialValue
    // eg switching between Card/Edge on the Color Coding Tab
    setToggleContext((prev) => ({ ...prev, isOn: initialValue }))
    setInitialize(true)
  }, [initialValue])

  const toggle = useCallback(() => {
    setToggleContext((prev) => ({ ...prev, isOn: !toggleContext.isOn }))
    if (onToggle) {
      onToggle(!toggleContext.isOn)
    }
  }, [onToggle, toggleContext])

  const value = useMemo(() => ({ ...toggleContext, toggle }), [toggleContext, toggle])

  if (!initialize) return null

  return <ToggleContext.Provider value={value}>{children}</ToggleContext.Provider>
}

const ToggleSwitch = () => {
  const { id, isOn } = useContext(ToggleContext)
  return (
    <div
      className={classNames("switch", { "is-on": isOn })}
      id={id}
      role="switch"
      aria-checked={isOn}
      tabIndex={0}
      data-ison={isOn}
    >
      <motion.div className="handle rounded-full" layout="position" transition={spring} />
    </div>
  )
}

const ToggleLabel = ({ classes, disabledLabel, enabledLabel }: ToggleLabelProps) => {
  const { isOn } = useContext(ToggleContext)

  return <span className={classes}>{isOn ? enabledLabel : disabledLabel}</span>
}

const ToggleButton = ({ children, classes }: ToggleButtonProps) => {
  const { toggle } = useContext(ToggleContext)

  return (
    <button
      type="button"
      onClick={toggle}
      className={classNames("m-0 border-none bg-transparent p-0", classes)}
    >
      {children}
    </button>
  )
}

export { Toggle, ToggleSwitch, ToggleLabel, ToggleButton }
