import React from "react"
import { motion } from "framer-motion"
import AnimateHeight from "react-animate-height"
import { Modal, ModalProps } from "v2/react/shared/Modal"
import { LoadingIndicator } from "v2/react/shared/LoadingIndicator"

interface AsyncModalProps extends ModalProps {
  isLoading: boolean
  initialHeight?: number
  animationDuration?: number
  children: React.ReactNode
}

const AsyncModal = ({
  isLoading,
  initialHeight = 200,
  animationDuration = 250,
  children,
  ...modalProps
}: AsyncModalProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Modal {...modalProps}>
    <AnimateHeight duration={animationDuration} height={isLoading ? initialHeight : "auto"}>
      <LoadingIndicator
        isLoading={isLoading}
        spinnerStyle={{ position: "relative" }}
        wrapperStyle={{ height: initialHeight }}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: animationDuration / 1000.0 }}
        >
          {children}
        </motion.div>
      </LoadingIndicator>
    </AnimateHeight>
  </Modal>
)

export { AsyncModal }
