/* eslint-disable no-param-reassign */
import React from "react"
import classNames from "classnames"
import { HeadcountPlanChangeProjection } from "types/graphql.d"

export type Column<T extends string> = {
  fieldKey: T
  label: string
  filterable: boolean
  draggable?: boolean
  hidden?: boolean
}
export type Row = {
  id: string
  data: HeadcountPlanChangeProjection
}

type DatasheetProps<T extends string> = {
  columns: Column<T>[]
  rows: Row[]
  renderCell: (row: Row, column: Column<T>) => React.ReactNode
}
export function Datasheet<T extends string>({ rows, columns, renderCell }: DatasheetProps<T>) {
  const [groupByCols, setGroupByCols] = React.useState<{ label: string; fieldKey: string }[]>([])
  const [isDraggingOver, setIsDraggingOver] = React.useState(false)

  const displayedColumns = columns.filter((column) => !column.hidden)

  return (
    <div>
      <div
        style={{
          background: isDraggingOver ? "rgba(012, 020, 075, 0.03)" : undefined,
          borderStyle: isDraggingOver ? "solid" : undefined,
        }}
        className={classNames(
          "rounded-sm border border-dotted border-neutral-64 bg-primary-3",
          "mb-8 gap-2 p-2 text-neutral-64 flex",
        )}
        onDrop={(event) => {
          event.preventDefault()
          const data = JSON.parse(event.dataTransfer.getData("application/json"))
          setGroupByCols((cols) => [...cols, data])
          setIsDraggingOver(false)
        }}
        onDragOver={(event) => {
          event.preventDefault()
          setIsDraggingOver(true)
        }}
        onDragExit={(event) => {
          event.preventDefault()
          setIsDraggingOver(false)
        }}
      >
        {groupByCols.length === 0 ? (
          <p>Drop a column here to group by it</p>
        ) : (
          groupByCols.map((col) => (
            <div
              key={col.fieldKey}
              className="gap-2 rounded-sm border border-solid border-neutral-64 p-2 flex"
            >
              <p className="m-0">{col.label}</p>
              <div className="flex-col justify-center flex">
                <button
                  type="button"
                  className="flex-col justify-center rounded-full bg-neutral-100 flex"
                  style={{ height: "1rem", width: "1rem" }}
                  onClick={() => {
                    setGroupByCols((cols) =>
                      cols.filter((grpCol) => col.fieldKey !== grpCol.fieldKey),
                    )
                  }}
                >
                  <img src="/static-assets/close.svg" alt="remove button" />
                </button>
              </div>
            </div>
          ))
        )}
      </div>
      <div className="w-full overflow-x-auto">
        <table>
          <thead>
            <tr>
              {displayedColumns.map((column) => (
                <th
                  key={column.fieldKey}
                  draggable={column.draggable}
                  onDragStart={(event) => {
                    event.dataTransfer.setData(
                      "application/json",
                      JSON.stringify({ label: column.label, fieldKey: column.fieldKey }),
                    )
                    event.dataTransfer.dropEffect = "copy"
                  }}
                  className="border border-solid border-neutral-8 bg-neutral-3 p-1"
                >
                  <h1 className="f-14 mb-1">{column.label}</h1>
                  {column.filterable && (
                    <select
                      className="w-fit rounded-sm border border-solid border-neutral-8 bg-white p-2"
                      aria-label={`Filter by ${column.label}`}
                      defaultValue=""
                    >
                      <option value="" disabled>{`Filter by ${column.label}`}</option>
                    </select>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.id}>
                {displayedColumns.map((column) => (
                  <td className="border border-solid border-neutral-8" key={column.fieldKey}>
                    {renderCell(row, column)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
