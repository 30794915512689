import React from "react"
import type { Meta, StoryObj } from "@storybook/react"

import { ParticipantTable } from "./ParticipantTable"

const meta: Meta<typeof ParticipantTable> = {
  title: "Components/ParticipantTable",
  component: ParticipantTable,
  parameters: {
    layout: "fullscreen",
  },
  decorators: [
    (story, context) => (
      <div style={{ margin: "2rem" }}>{story({ ...context, args: { ...context.args } })}</div>
    ),
  ],
}

export default meta

type Story = StoryObj<typeof ParticipantTable>

const participantsFixture = {
  data: {
    currentHeadcountPlan: {
      id: "1",
      name: "Test plan",
      company: {
        id: 1,
        logoThumbUrl: "https://placehold.co/32x32",
      },
      participantsTree: [
        {
          id: "1",
          children: [
            {
              id: "2",
              role: "participant",
              position: {
                id: 2,
                title: "VP of HR",
              },
              status: "invited",
              person: {
                id: "2",
                name: "Bill Williams",
                workEmail: "bill@example.com",
              },
              people: [
                {
                  id: "2",
                  name: "Bill Williams",
                  workEmail: "bill@example.com",
                },
              ],
              children: [
                {
                  children: [],
                  id: "21",
                  role: "participant",
                  position: {
                    id: 3,
                    title: "HR Manager",
                  },
                  status: "invited",
                  person: {
                    id: "3",
                    name: "Susan Wilson",
                    workEmail: "susan@example.com",
                  },
                  people: [
                    {
                      id: "3",
                      name: "Susan Wilson",
                      workEmail: "susan@example.com",
                    },
                  ],
                },
                {
                  children: [],
                  id: "10",
                  role: "participant",
                  position: {
                    id: 44,
                    title: "Designer",
                  },
                  status: "invited",
                  person: {
                    id: "51",
                    name: "Madalyn Auer",
                    workEmail: "madalyn_auer33@parker.com",
                  },
                  people: [
                    {
                      id: "51",
                      name: "Madalyn Auer",
                      workEmail: "madalyn_auer33@parker.com",
                    },
                  ],
                },
                {
                  children: [],
                  id: "8",
                  role: "participant",
                  position: {
                    id: 45,
                    title: "Manager",
                  },
                  status: "invited",
                  person: {
                    id: "52",
                    name: "Art Renner",
                    workEmail: "art_renner48@parker.com",
                  },
                  people: [
                    {
                      id: "52",
                      name: "Art Renner",
                      workEmail: "art_renner48@parker.com",
                    },
                  ],
                },
              ],
            },
            {
              id: "14",
              role: "participant",
              position: {
                id: 5,
                title: "VP of Technology",
              },
              status: "invited",
              person: {
                id: "5",
                name: "Paula Jones",
                workEmail: "paula@example.com",
              },
              people: [
                {
                  id: "5",
                  name: "Paula Jones",
                  workEmail: "paula@example.com",
                },
              ],
              children: [
                {
                  children: [
                    {
                      id: "19",
                      role: "participant",
                      position: {
                        id: 20,
                        title: "Developer",
                      },
                      status: "invited",
                      person: {
                        id: "20",
                        name: "Gabriel Koepp",
                        workEmail: "gabriel_koepp18@parker.com",
                      },
                      people: [
                        {
                          id: "20",
                          name: "Gabriel Koepp",
                          workEmail: "gabriel_koepp18@parker.com",
                        },
                      ],
                      children: [
                        {
                          id: "18",
                          role: "participant",
                          position: {
                            id: 21,
                            title: "Assistant",
                          },
                          status: "invited",
                          person: {
                            id: "21",
                            name: "Marinda Nader",
                            workEmail: "marinda_nader50@parker.com",
                          },
                          people: [
                            {
                              id: "21",
                              name: "Marinda Nader",
                              workEmail: "marinda_nader50@parker.com",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                  id: "20",
                  role: "participant",
                  position: {
                    id: 15,
                    title: "Supervisor",
                  },
                  status: "invited",
                  person: {
                    id: "15",
                    name: "Theda Nicolas",
                    workEmail: "theda_nicolas37@parker.com",
                  },
                  people: [
                    {
                      id: "15",
                      name: "Theda Nicolas",
                      workEmail: "theda_nicolas37@parker.com",
                    },
                  ],
                },
                {
                  children: [],
                  id: "11",
                  role: "participant",
                  position: {
                    id: 49,
                    title: "Assistant",
                  },
                  status: "invited",
                  person: {
                    id: "57",
                    name: "Bao Romaguera",
                    workEmail: "bao_romaguera18@parker.com",
                  },
                  people: [
                    {
                      id: "57",
                      name: "Bao Romaguera",
                      workEmail: "bao_romaguera18@parker.com",
                    },
                  ],
                },
                {
                  children: [],
                  id: "9",
                  role: "participant",
                  position: {
                    id: 47,
                    title: "Manager",
                  },
                  status: "invited",
                  person: {
                    id: "54",
                    name: "Julian Murray",
                    workEmail: "julian_murray14@parker.com",
                  },
                  people: [
                    {
                      id: "54",
                      name: "Julian Murray",
                      workEmail: "julian_murray14@parker.com",
                    },
                  ],
                },
                {
                  children: [],
                  id: "12",
                  role: "participant",
                  position: {
                    id: 41,
                    title: "Supervisor",
                  },
                  status: "invited",
                  person: {
                    id: "48",
                    name: "Berry Schumm",
                    workEmail: "berry_schumm43@parker.com",
                  },
                  people: [
                    {
                      id: "48",
                      name: "Berry Schumm",
                      workEmail: "berry_schumm43@parker.com",
                    },
                  ],
                },
              ],
            },
          ],
          role: "owner_and_participant",
          position: {
            id: 1,
          },
          status: "in_progress",
          person: {
            id: "1",
            name: "Jane Smith",
            workEmail: "jane@example.com",
          },
          people: [
            {
              id: "1",
              name: "Jane Smith",
              workEmail: "jane@example.com",
            },
          ],
        },
      ],
    },
  },
}

export const Main: Story = {
  args: {
    logoThumbUrl: participantsFixture.data.currentHeadcountPlan.company.logoThumbUrl,
    participants: [],
  },
}
