import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useId, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import tinymce, { Editor, RawEditorOptions } from "tinymce"
import { Pulser } from "v2/react/shared/Pulser"
import { Spinner } from "v2/react/shared/Spinner"
import { tinyMceDefaultConfig } from "v2/react/utils/tinyMceDefaultConfig"

interface Props {
  content: string
  handleGenerate: () => void
  isAiAvailable?: boolean
  labelText: string
  loadingContent: boolean
  setData: (data: string) => void
}

function AITextArea({
  content,
  handleGenerate,
  isAiAvailable = true,
  labelText,
  loadingContent,
  setData,
}: Props) {
  const { t } = useTranslation()
  const editorRef = useRef<Editor | null>(null)
  const [editorInitialized, setEditorInitialized] = useState(editorRef.current?.initialized)
  const id = useId()

  useEffect(() => {
    const cleanUpForEffect = () => {
      // Ensures we have the latest and greatest during clean up.
      const { current: editor } = editorRef

      if (editor) {
        tinymce.remove(editor)
        editorRef.current = null
        setEditorInitialized(false)
      }
    }

    const { current: editor } = editorRef
    if (editor?.initialized) return cleanUpForEffect

    const tinyMceConfig: RawEditorOptions = {
      ...tinyMceDefaultConfig(id),
      height: 200,
      setup: (editor: Editor) => {
        editor.on("init", () => {
          setEditorInitialized(true)
        })
        editor.on("change", () => {
          // setData on change event vs on keyup.
          // example: last thing a user does is create a link in the popup modal
          // this won't fire 'keyup' event and link would not be saved
          setData(editor.getContent())
          editor.save()
        })
      },
    }

    tinymce.init(tinyMceConfig)
    editorRef.current = tinymce.get(id)
    return cleanUpForEffect
  }, [id, setData])

  useEffect(() => {
    const { current: activeEditor } = editorRef
    if (!activeEditor || !editorInitialized) return

    if (loadingContent) {
      activeEditor.mode.set("readonly")
      activeEditor.getContentAreaContainer().style.display = "none"
    } else if (!loadingContent) {
      activeEditor.getContentAreaContainer().style.display = "initial"
      activeEditor.mode.set("design")
    }
    const existingValue: string = activeEditor.getContent()

    if (existingValue !== content) {
      activeEditor.setContent(content)
    }
  }, [content, editorInitialized, loadingContent])

  return (
    <div className="ai-textarea">
      <div className="mb-1 items-center justify-between flex">
        <label htmlFor={id}>{labelText}</label>
        {isAiAvailable ? (
          <button type="button" className="btn--sm btn--secondary" onClick={handleGenerate}>
            <FontAwesomeIcon icon={["far", "sparkles"]} />
            {t("v2.position_types.show.generate_with_ai")}
          </button>
        ) : null}
      </div>
      <div className="relative">
        <textarea
          className="input input-med tinymce"
          id={id}
          disabled={loadingContent}
          defaultValue={content}
        />
        {!editorInitialized && <Spinner />}
        {loadingContent && (
          <div className="absolute left-4 top-16 z-[2]">
            <Pulser />
          </div>
        )}
      </div>
    </div>
  )
}

export { AITextArea }
