import { AppDispatch, RootState } from "v2/redux/store"
import { ListenerEffectAPI } from "@reduxjs/toolkit"
import { selectCursor } from "./cursorSelectors"
import { onDifferent } from "./cursorStates"
import { CursorEventDetail } from "./types"

type DispatchArg = { api: ListenerEffectAPI<RootState, AppDispatch> }

export const cursorEventTarget = new EventTarget()

export function dispatchCursorEvents({ api: { getState, getOriginalState } }: DispatchArg) {
  const originalCursor = selectCursor(getOriginalState())
  const cursor = selectCursor(getState())

  const detail = { originalCursor, cursor }

  if (onDifferent(originalCursor, cursor))
    cursorEventTarget.dispatchEvent(makeEvent("cellCursorTargetChange", detail))

  if (originalCursor.state !== cursor.state)
    cursorEventTarget.dispatchEvent(makeEvent("cellCursorStateChange", detail))
}

const makeEvent = (eventKey: string, detail: CursorEventDetail) =>
  new CustomEvent<CursorEventDetail>(eventKey, { detail })
