import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/Tooltip"

interface SmallEditButtonProps {
  disabled?: boolean
  disabledTooltipText?: string
  onClick: () => void
}

function SmallEditButton({ disabled, disabledTooltipText, onClick }: SmallEditButtonProps) {
  const { t } = useTranslation()

  return (
    <Tooltip placement="top">
      <TooltipTrigger>
        <button
          className={cn("btn--sm btn--secondary", { "opacity-50": disabled })}
          onClick={onClick}
          type="button"
          disabled={disabled}
        >
          {t("v2.defaults.edit")}
        </button>
      </TooltipTrigger>
      {disabled && (
        <TooltipContent className="react-tooltip-content">
          <span>{disabledTooltipText}</span>
        </TooltipContent>
      )}
    </Tooltip>
  )
}

export { SmallEditButton }
