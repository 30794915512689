import classNames from "classnames"
import React from "react"
import { useObserveElement } from "v2/react/hooks/useObserveElement"

type Props = {
  children: React.ReactNode
  innerClassName?: string
}
export function StickyFooterBar({ children, innerClassName }: Props) {
  const { width, ref } = useObserveElement<HTMLDivElement>()

  return (
    <>
      <div
        style={{ width }}
        className={classNames(
          "fixed bottom-0 z-[5] justify-center border bg-white p-4 flex",
          "border-x-0 border-b-0 border-t border-solid border-t-neutral-8-solid",
        )}
      >
        <div className={classNames("justify-end gap-2 flex", innerClassName)}>{children}</div>
      </div>
      <div className="w-full" ref={ref} />
    </>
  )
}
