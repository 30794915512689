attachVariablePayRowEvents = ->
  $('.variable-pay-pay-type-field').off('change').on 'change', ->
    toggleVariableSymbol.call(this, true)
    calculateTotalPay()
  $('.variable-pay-amount-field').off('keyup').on 'keyup', calculateTotalPay
  $('.select select, .relative .input:not([readonly])')
    .on('focus', () -> $(this).parents('.select, .relative').addClass('active'))
    .on('blur', () -> $(this).parents('.select, .relative').removeClass('active'))
  $('.variable-pay-name-wrapper').off('mouseover').on('mouseover', maybeAttachOverflowTooltip)

maybeAttachOverflowTooltip = ->
  $variablePayNameWrapper = $(this)
  return if $variablePayNameWrapper.hasClass('tooltip')

  $variablePayName = $variablePayNameWrapper.find('.variable-pay-name')
  isOverflow = $variablePayName[0].offsetWidth < $variablePayName[0].scrollWidth

  if isOverflow
    tooltipElement = """
      <div class='tooltip-content tooltip-content--10rem whitespace-normal'>
        #{$variablePayName.text()}
      </div>
    """
    $variablePayNameWrapper.addClass('tooltip tooltip-center')
    $variablePayNameWrapper.append(tooltipElement)


initVariablePaySymbol = ->
  toggleVariableSymbol.call(this)

correspondingAmountField = (payTypeField) ->
  this_id = payTypeField.attr('id')
  correspondingAmountFieldSelector = this_id.replace(/_pay_type/, '_amount')
  $("##{correspondingAmountFieldSelector}")

toggleVariableSymbol = (resetAmountValue = false) ->
  payType = $(this).val()
  $amountField = correspondingAmountField($(this))
  if resetAmountValue
    $amountField.val('')
    $amountField.attr('value', '')
    $amountField.trigger('focus')

  $input_add_on = $amountField.siblings('.prefix')
  if payType == 'amount'
    $input_add_on.find('.currency-icon').show()
    $input_add_on.find('.fa-percent').hide()
  else
    $input_add_on.find('.currency-icon').hide()
    $input_add_on.find('.fa-percent').show()

resetVariablePayRowIndex = (index, row) ->
  $row = $(row)
  $row.attr('data-index', index)
  $row.find('[name*="[variable_pays_attributes]"]').each (i, el) ->
    $(this).attr('name', $(this).attr('name').replace(/(-?)\d+/, index))
  $row.find('[id*="_variable_pays_attributes"]').each (i, el) ->
    $(this).attr('id', $(this).attr('id').replace(/(-?)\d+/, index))

resetVariablePayRowIndexes = () ->
  $('[data-variable-pay-row]:not(.template-row)').each((index, row) ->
    resetVariablePayRowIndex(index, row)
  )
  attachVariablePayRowEvents()

setAddVariablePayButtonState = (disable) ->
  $addVariablePayButton = $('#add-variable-pay')
  $addVariablePayButton?.prop('disabled', disable)?.toggleClass('disabled', disable)

setModalSubmitButtonState = (disable) ->
  $modalSubmitButton = $('.modal-footer input[type=submit]')
  $modalSubmitButton?.prop('disabled', disable)?.toggleClass('disabled', disable)

handleSettingButtonStates = (disable) ->
  setAddVariablePayButtonState(disable)
  setModalSubmitButtonState(disable)

handleShowAddVariablePayForm = () ->
  $('#variable-pays-form').show()
  handleSettingButtonStates(disable = true)
  $('[name=new_variable_pay_type_name]').trigger('focus')

cancelAddVariablePay = () ->
  clearVariablePaySaveError()
  $('#variable-pays-form').hide()
  handleSettingButtonStates(disable = false)
  $('[name=new_variable_pay_type_name]').val('')

renderVariablePayRow = () ->
  name = $('#new_variable_pay_type_name').val()
  variable_pay_type_id = $('#new_variable_pay_type_id').val()
  $newForm = $('.variable-pay-form').last().clone()
  $newForm.removeClass('hidden template-row')
  $newForm.find('.form-error').removeClass('.form-error')
  $newForm.find('.form-error-message').remove()
  $newForm.find('select option[value=amount]').prop('selected', true)
  $newForm.find('.variable-pay-name').text(name)
  $newForm.find('input').attr('value', '')
  $newForm.find('input[name*=variable_pay_type_id]')
    .attr('value', variable_pay_type_id)
  appendAlphabetically($newForm, name)
  resetVariablePayRowIndexes()
  $newForm.show()
  $.onmount()
  attachVariablePayRowEvents()
  $newForm.find('.variable-pay-pay-type-field').trigger('change')

appendAlphabetically = ($form, name) ->
  names = variablePayTypeNames()
  return $('.variable-pays').append($form) if names.length == 0
  names.push(name)
  targetIndex = names.sort().indexOf(name)
  if targetIndex == 0
    $('.variable-pays').prepend($form)
  else
    $form.insertAfter($('.variable-pays').children(':visible').eq(targetIndex - 1))

variablePayTypeNames = () ->
  names = []
  $('[data-variable-pay-row]:not(.template-row):visible').each((index, row) ->
    names.push($(row).find('.variable-pay-name').text())
  )
  names

autofillNewVariablePayType = (data) ->
  $('#new_variable_pay_type_id').val(data.id)
  $('#new_variable_pay_type_name').val(data.name)

addVariablePaySaveError = (message) ->
  $inputGroup = $('[name=new_variable_pay_type_name]').closest('.input-group')
  $inputGroup.addClass('form-error')
  $inputGroup.find("#create-variable-pay-type-error")
    .append($('<div class="form-error-message"></div>').text(message))

clearHoursPerWeekErrors = () ->
  $inputGroup = $('#hours-per-week').closest('.input-group')
  $inputGroup.removeClass('form-error')
  $inputGroup.find(".form-error-message").remove()

clearBasePayErrors = () ->
  $inputGroup = $('#base-pay').closest('.input-group')
  $inputGroup.removeClass('form-error')
  $inputGroup.find(".form-error-message").remove()

clearVariablePaySaveError = () ->
  $inputGroup = $('[name=new_variable_pay_type_name]').closest('.input-group')
  $inputGroup.removeClass('form-error')
  $inputGroup.find("#create-variable-pay-type-error .form-error-message").remove()

saveNewVariablePayType = () ->
  clearVariablePaySaveError()
  model_type = $('.budget-table').data('model-type')
  name = $('[name=new_variable_pay_type_name]').val()
  $(this)
    .attr('disabled', 'disabled')
    .addClass('disabled')
    .text('add_disabled'.t('button_defaults'))
  $.post(
    url: App.endpoint(['companies', gon.current_company_id, 'variable_pay_types']),
    data: { name: name, model_type: model_type }
  ).done((response, status, xhr) ->
    autofillNewVariablePayType(response)
    clearVariablePaySaveError()
    renderVariablePayRow()
    cancelAddVariablePay()
  ).fail((jqXHR) ->
    errorMessage = jqXHR.responseJSON.error
    addVariablePaySaveError(errorMessage)
  ).always(=>
    $(this).attr('disabled', false)
      .text('add_enabled'.t('button_defaults'))
      .removeClass('disabled')
  )

calculateTotalPay = ->
  $basePayInput = $('#base-pay')
  return unless $basePayInput.length > 0

  $basePayTypeInput = $('#base-pay-type')
  basePay = App.Helpers.parseCurrency($basePayInput.val()) || 0

  if $basePayTypeInput.val() == 'hourly'
    hoursPerWeek = $('#hours-per-week').val()
    basePay = basePay * hoursPerWeek * 52

  variablePaySelector = (index, attribute_name) ->
    "[data-variable-pay-row]:not(.template-row) [id*='_variable_pays_attributes_#{index}_#{attribute_name}']"

  totalVariablePay = 0
  indexOfVariablePays = 0

  variablePayAmount = (index, basePay) ->
    $variablePayInput = $(variablePaySelector(index, 'amount'))
    $variablePayTypeInput = $(variablePaySelector(index, 'pay_type'))

    return 0 unless $variablePayInput.is(':visible')

    if $variablePayTypeInput.val() == 'percent'
      variablePay = $variablePayInput.val().replace(/[^0-9.]/g, '')
      variablePay = variablePay / 100 * basePay
    else
      variablePay = App.Helpers.parseCurrency($variablePayInput.val()) || 0

    variablePay

  loop
    variablePayExists = $(variablePaySelector(indexOfVariablePays, 'id')).length == 1
    break unless variablePayExists

    totalVariablePay += variablePayAmount(indexOfVariablePays, basePay)
    indexOfVariablePays += 1

  total = basePay + totalVariablePay
  $('#total_').val(App.Helpers.formatCurrency(total, omitSymbol: true, trailing: true))

  window.FundingSources.updateFundingSourceAmounts()

toggleHourlyFields = ->
  if $('#base-pay-type').val() == 'salary'
    $('.hourly-field').hide()
    $('.hourly-field').parents('.input-group').hide()
    $('#base-pay').parents('.input-group').addClass('last-three')
  else
    $('.hourly-field').show()
    $('.hourly-field').parents('.input-group').show()
    $('#base-pay').parents('.input-group').removeClass('last-three')

maybeDestroyVariablePayRecords = ->
  recordsToDestroy = $('[data-variable-pay-row]:not(.template-row)').filter(() ->
    variablePayAmount = $(this).find('.variable-pay-amount-field').val().trim()

    $(this).find('.id-hidden-field').val().length != 0 &&
      (variablePayAmount.length == 0 || variablePayAmount == '0')
  )
  recordsToDestroy.each(() ->
    $(this).find('[name*="_destroy"]').val('true')
  )

addVariablePayFormEvents = ($addVariablePayForm) ->
  return if $addVariablePayForm.length == 0
  formIsVisible = -> $addVariablePayForm.is(':visible')

  # Ensures the popover closes when clicking outside of it
  $(document).mousedown (e) ->
    return unless formIsVisible()
    isVariablePayFormClicked = $addVariablePayForm.is(e.target) or $addVariablePayForm.has(e.target).length > 0

    unless isVariablePayFormClicked
      cancelAddVariablePay()

  # Ensures the popover closes when tabbing out of it
  $addVariablePayForm.on 'focusout', (e) ->
    return unless formIsVisible()
    setTimeout ->
      return if document.activeElement == document.body
      return if $addVariablePayForm.has(document.activeElement).length
      cancelAddVariablePay()
    , 0

  $addVariablePayForm.find('[data-action=cancel-add-variable-pay]').off('click').on 'click', cancelAddVariablePay
  $addVariablePayForm.find('[data-action=save-new-variable-pay-type]').off('click').on 'click', saveNewVariablePayType

tableEvents = (scope) ->
  $scope = $(scope)
  toggleHourlyFields()
  $scope.find('.variable-pay-pay-type-field').each initVariablePaySymbol
  calculateTotalPay()

  addVariablePayFormEvents($scope.find('#variable-pays-form'))
  $scope.find('[data-action=add-variable-pay]').off('click').on 'click', handleShowAddVariablePayForm

  $scope.find('[data-action=save]').on 'click', maybeDestroyVariablePayRecords

  $scope.find('#base-pay-type').on 'change', ->
    $('#base-pay').val('')
    clearBasePayErrors()
    clearHoursPerWeekErrors()
    toggleHourlyFields()
    calculateTotalPay()

  attachVariablePayRowEvents()

  $scope.find('#base-pay, #hours-per-week').on 'keyup', calculateTotalPay

export { tableEvents }
