import React from "react"
import { useTranslation } from "react-i18next"

import { PositionType, PositionTypeMetric } from "types/graphql"
import { useGetPositionTypeMetricsQuery } from "v2/redux/GraphqlApi/PositionTypesApi"

interface Props {
  positionType: PositionType
}

function Stats({ positionType }: Props) {
  const { t } = useTranslation()

  const metricsQuery = useGetPositionTypeMetricsQuery({
    chart_key: `chart_${window.gon.official_chart_id}`,
  })

  const stats = metricsQuery.data ?? {}

  return (
    <div className="module-card">
      <div className="module-card__header">
        <div className="module-title">{t("v2.position_types.show.stats")}</div>
      </div>
      <div className="module-card__body border-0 p-0 pb-4">
        <ColumnRow
          title={t("v2.position_types.show.avg_vacancy_rate")}
          content={columnValue(
            positionType.id,
            stats?.positionTypeMetrics?.vacancyRateByType || [],
          )}
        />
        <ColumnRow
          title={t("v2.position_types.show.avg_turnover_rate")}
          content={columnValue(
            positionType.id,
            stats?.positionTypeMetrics?.turnoverRateByType || [],
          )}
        />
        {stats?.positionTypeMetrics?.employeeCostsByType ? (
          <ColumnRow
            title={t("v2.position_types.show.avg_budget")}
            content={columnValue(positionType.id, stats?.positionTypeMetrics?.employeeCostsByType)}
          />
        ) : null}
      </div>
    </div>
  )
}

const ColumnRow = ({ title, content }: { title: string; content: string }) => (
  <div className="border-0 border-t border-solid border-neutral-8 px-4 pt-4 flex">
    <div className="basis-1/2 text-neutral-64">{title}</div>
    <div className="basis-2/2">{content}</div>
  </div>
)

const columnValue = (positionTypeId: string, groupData?: PositionTypeMetric[]): string => {
  if (!groupData) return "-"
  const vacancyRateGroups: PositionTypeMetric[] = groupData || []
  const data: PositionTypeMetric | undefined = vacancyRateGroups.find(
    (vrg: PositionTypeMetric) => vrg.id === `position_type_${positionTypeId}`,
  )
  if (!data) return "-"
  return `${data.value}`
}

export { Stats }
