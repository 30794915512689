import React from "react"

import { useTranslation } from "react-i18next"

import { PositionTypeControlledAttributesWithValuesQuery, Maybe } from "types/graphql"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"

import { Modal } from "v2/react/shared/Modal"
import { SaveFooter, SaveFooterProps } from "v2/react/shared/Modal/SaveFooter"
import { Notice } from "v2/react/shared/Notice"

interface Props {
  maybeInitialTitle?: Maybe<string>
  maybeNewTitle?: Maybe<string>
  controlledAttributeNamesWithSetValuesHash: NonNullable<
    PositionTypeControlledAttributesWithValuesQuery["positionType"]
  >["controlledAttributeNamesWithSetValuesHash"]
  isOpen: boolean
  isSaving: boolean
  handleClose: () => void
  handleSubmit: SaveFooterProps["onSave"]
}

const TitleChangeFollowUpModal = ({
  maybeInitialTitle,
  maybeNewTitle,
  controlledAttributeNamesWithSetValuesHash,
  isOpen,
  isSaving,
  handleClose,
  handleSubmit,
}: Props) => {
  const { t } = useTranslation()

  const modalTitle = t("v2.positions.form.title_change_modal_title")
  const initialTitle = maybeInitialTitle || t("v2.defaults.blank")
  const newTitle = maybeNewTitle || t("v2.defaults.blank")
  const noticeDescription = t("v2.positions.form.title_change_notice", {
    old_title: initialTitle,
    new_title: newTitle,
  })

  return (
    <Modal
      isOpen={isOpen}
      title={modalTitle}
      onClose={handleClose}
      size="md"
      shouldReturnFocusAfterClose={false}
    >
      <div className="react-modal__body">
        <Notice icon={["far", "exclamation-triangle"]} type="caution">
          <NoticeContent
            noticeDescription={noticeDescription}
            newAttributesHash={controlledAttributeNamesWithSetValuesHash}
          />
        </Notice>
      </div>
      <SaveFooter onSave={handleSubmit} onCancel={handleClose} isSaving={isSaving} />
    </Modal>
  )
}

const NoticeContent = ({
  noticeDescription,
  newAttributesHash,
}: {
  noticeDescription: string
  newAttributesHash: Record<string, string>
}) => (
  <div className="flex-col flex">
    <div id="title-change-notice-description">{noticeDescription}</div>
    <ul id="title-change-notice-data-list" className="!mb-0 ml-2 text-neutral-64">
      {Object.entries(newAttributesHash).map(([key, value]) => (
        <li className="mb-0" key={key}>
          {key}: {sanitizeParse(value)}
        </li>
      ))}
    </ul>
  </div>
)

export { TitleChangeFollowUpModal }
