import React from "react"

interface Props {
  icon: React.ReactNode
  children: React.ReactNode
}

const IconWrapper = ({ icon, children }: Props) => (
  <div className="items-center gap-2 flex">
    {icon}
    {children}
  </div>
)

export { IconWrapper }
