import React, { useState, useEffect, useRef } from "react"
import cn from "classnames"
import { useAppSelector } from "v2/redux/store"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  ChartApprovalBar,
  alertType,
} from "v2/react/components/orgChart/ChartApproval/ChartApprovalBar"
import { useGetChartAndApproversQuery } from "v2/redux/GraphqlApi/ChartApprovalsApi"
import { skipToken } from "@reduxjs/toolkit/dist/query"

interface Props {
  chartId: number
  showApprovalDisplay: boolean
}

function Banners({ chartId, showApprovalDisplay }: Props) {
  const orgStatusRef = useRef<HTMLDivElement>(null)
  const [showApprovalBar, setShowApprovalBar] = useState(false)
  const displayMode = useAppSelector((state) => state.visualization.displayMode)
  const { data: chart } = useGetChartAndApproversQuery(
    showApprovalDisplay ? { id: chartId } : skipToken,
  )

  useEffect(() => {
    if (!orgStatusRef) return
    if (window.gon.selected_chart.duplicating && !orgStatusRef.current?.children.length) {
      window.App.OrgChart.initDuplicatingStatus()
    }
  }, [orgStatusRef])

  const bannerContainerStyles =
    "org-chart-banners flex flex-col items-center pt-3 gap-y-3 fixed bottom-3 sm:absolute sm:top-[100%] left-[50%] translate-x-[-50%] w-[calc(100%-2rem)] sm:w-fit max-w-[480px]"
  const triggerStyles =
    "fixed p-2 rounded-md w-8 h-8 bottom-3 right-3 grid place-content-center sm:hidden"

  return (
    <>
      <div className={bannerContainerStyles}>
        {showApprovalDisplay && chart && chart.status && (
          <div
            className={cn("relative order-2 w-full sm:order-1", {
              "block sm:hidden": displayMode === "grid",
              "flex sm:flex": showApprovalBar,
              "hidden sm:flex": !showApprovalBar,
            })}
          >
            <ChartApprovalBar setShowApprovalBar={setShowApprovalBar} chartId={chartId} />
          </div>
        )}
        <div ref={orgStatusRef} className="org-chart-status order-1 max-w-[480px] sm:order-2" />
      </div>
      {chart && chart.status && (
        <button
          type="button"
          onClick={() => setShowApprovalBar(true)}
          className={cn(alertType(chart.status, displayMode).alertType, triggerStyles, {
            hidden: showApprovalBar,
            "!p-2": !showApprovalBar,
          })}
        >
          <FontAwesomeIcon icon={["far", "paper-plane"]} />
        </button>
      )}
    </>
  )
}

export { Banners }
