import React from "react"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { determineReportsTo } from "v2/react/components/orgChart/ProfilePanel/PositionHeader"
import { useSortedBoxes } from "v2/react/components/orgChart/ProfilePanel/useSortedBoxes"
import { ProfilePanelAbilities } from "v2/react/components/orgChart/ProfilePanel/ProfilePanel"
import { AccordionModulePanel } from "v2/react/shared/AccordionModulePanel"
import { useAppSelector } from "v2/redux/store"
import { FeatureFlags, IntegrationFlags } from "types/graphql"

interface PositionInfoProps {
  abilities: ProfilePanelAbilities
  featureFlags: FeatureFlags
  integrationFlags: IntegrationFlags
}

function PositionInfo({ abilities, featureFlags, integrationFlags }: PositionInfoProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const position = useAppSelector((state) => state.profilePanel.position)
  const displayMode = useAppSelector((state) => state.visualization.displayMode)
  const { currentBox, toggleBox } = useSortedBoxes("position_info", "position")

  const canReadSensitivePositionData =
    window.gon.can_manage_chart || abilities.canReadSensitivePositionData

  if (!position || !currentBox) return null

  const titleDisplay = (
    <ModuleListItem>
      <div>{"Title".t("org_chart")}</div>
      <div>
        {displayMode !== "cards" && (
          <button
            className="link-text p-0"
            type="button"
            data-action="focus-position"
            data-id={position.id}
          >
            {position.title || "Untitled Position".t("org_chart")}
          </button>
        )}
        {displayMode === "cards" && (
          <a
            href={`/orgchart/positions/${position.id}`}
            data-action="select-chart"
            data-chart-id={window.gon.selected_chart.id}
          >
            {position.title || "Untitled Position".t("org_chart")}
          </a>
        )}
      </div>
    </ModuleListItem>
  )

  const positionIdDisplay = () => {
    if (canReadSensitivePositionData) {
      return (
        <ModuleListItem>
          <div>{"Position ID".t("org_chart")}</div>
          <div>{position.systemIdentifier}</div>
        </ModuleListItem>
      )
    }
    return null
  }

  const reportsToDisplay = (
    <ModuleListItem>
      <div>{"Reports To".t("org_chart")}</div>
      <div>{determineReportsTo(position)}</div>
    </ModuleListItem>
  )

  const alsoFilledBy = () => {
    if (
      window.gon.can_manage_chart ||
      (abilities.canReadSensitivePositionData && position.people?.length)
    ) {
      return (
        <ModuleListItem>
          <div>{"Also Filled By".t("org_chart")}</div>
          <div className="max-width-half">
            {position.people
              ?.filter((p) => p.id !== person?.id)
              .map((p) => p.name)
              .join(", ")}
          </div>
        </ModuleListItem>
      )
    }
    return null
  }

  const jobCodeDisplay = () => {
    if (canReadSensitivePositionData) {
      return (
        <ModuleListItem>
          <div>{"Job Code".t("org_chart")}</div>
          <div>{position.jobCode}</div>
        </ModuleListItem>
      )
    }
    return null
  }

  const externalIdDisplay = (
    <ModuleListItem>
      <div>{"external_id".t("org_chart")}</div>
      <div>{position.externalIdentifier}</div>
    </ModuleListItem>
  )

  const importanceDisplay = () => {
    if (
      canReadSensitivePositionData &&
      (featureFlags.successionPlanning || featureFlags.positionManagement)
    ) {
      return (
        <ModuleListItem>
          <div>{"Importance".t("org_chart")}</div>
          <div>
            {position.importance
              ? position.importance[0].toUpperCase() + position.importance.substring(1)
              : ""}
          </div>
        </ModuleListItem>
      )
    }
    return null
  }

  const successionPlanStatus = () => {
    if (canReadSensitivePositionData && featureFlags.successionPlanning) {
      return (
        <ModuleListItem>
          <div>{"Succession Plan".t("succession_plan", "")}</div>
          <div>
            {position.successionPlanStatus
              ? `succession_plan_${position.successionPlanStatus}`.t("succession_plan", "statuses")
              : ""}
          </div>
        </ModuleListItem>
      )
    }
    return null
  }

  const chartSectionDisplay = (
    <ModuleListItem>
      <div>{"Chart Section".t("org_chart")}</div>
      <div>{position.chartSection}</div>
    </ModuleListItem>
  )

  const companyCodeDisplay = () => {
    if (canReadSensitivePositionData) {
      return (
        <ModuleListItem>
          <div>{"Company Code".t("org_chart")}</div>
          <div>{position.companyCode}</div>
        </ModuleListItem>
      )
    }
    return null
  }

  const orgUnits = (position.orgUnits || []).map((unit) => (
    <ModuleListItem key={unit.field_id}>
      <div>{unit.orgUnitType?.name}</div>
      <div>
        {unit.formatted_value && unit.code
          ? `${unit.code} - ${unit.formatted_value}`
          : unit.formatted_value || unit.code}
      </div>
    </ModuleListItem>
  ))

  const jobLevel =
    integrationFlags.adpVantage && canReadSensitivePositionData ? (
      <ModuleListItem>
        <div>{"Job Level".t("org_chart")}</div>
        <div>{position.positionType?.jobLevel?.codeNameLabel}</div>
      </ModuleListItem>
    ) : null

  const location = (
    <ModuleListItem>
      <div>{"Location".t("org_chart")}</div>
      <div>{position.location?.label}</div>
    </ModuleListItem>
  )

  const officeRoomNumber = (
    <ModuleListItem>
      <div>{"Office/Room Number".t("org_chart")}</div>
      <div>{position.officeRoomNumber}</div>
    </ModuleListItem>
  )

  const fullTimeEquivalent = () => {
    if (canReadSensitivePositionData) {
      return (
        <ModuleListItem>
          <div>{"Full Time Equivalent".t("org_chart")}</div>
          <div>{position.fte?.toFixed(1)}</div>
        </ModuleListItem>
      )
    }
    return null
  }

  const eeocClassification = () => {
    if (canReadSensitivePositionData && featureFlags.positionManagement) {
      return (
        <ModuleListItem>
          <div>{"EEOC Classification".t("org_chart")}</div>
          <div>
            {position.eeocClassification
              ? position.eeocClassification.t("org_chart", "eeoc_classifications")
              : ""}
          </div>
        </ModuleListItem>
      )
    }
    return null
  }

  const flsaClassification = () => {
    if (canReadSensitivePositionData && featureFlags.positionManagement) {
      return (
        <ModuleListItem>
          <div>{"FLSA Classification".t("org_chart")}</div>
          <div>{position.flsa.name}</div>
        </ModuleListItem>
      )
    }
    return null
  }

  const headerContent = (
    <div className="w-full items-center justify-between flex">
      <div className="title">{"position_info".t("org_chart")}</div>
    </div>
  )

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      {positionIdDisplay()}
      {titleDisplay}
      {reportsToDisplay}
      {alsoFilledBy()}
      {jobCodeDisplay()}
      {externalIdDisplay}
      {importanceDisplay()}
      {successionPlanStatus()}
      {chartSectionDisplay}
      {companyCodeDisplay()}
      {orgUnits}
      {jobLevel}
      {location}
      {officeRoomNumber}
      {fullTimeEquivalent()}
      {eeocClassification()}
      {flsaClassification()}
    </AccordionModulePanel>
  )
}

export { PositionInfo }
