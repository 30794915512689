import { useState } from "react"
import { PositionsOverTimeMetrics, TimelineIntervalTypeEnum } from "types/graphql.enums"
import { DrillDownProps } from "v2/react/components/positions/reports/PositionsOverTime/DrillDown"

type DrillDownState = Omit<DrillDownProps, "setIsOpen">

const useDrillDownState = () => {
  const [drillDownProps, setDrillDownProps] = useState<DrillDownState>({
    isOpen: false,
    metricKey: PositionsOverTimeMetrics.Total,
    metricLabel: "",
    intervalStart: "",
    intervalEnd: "",
    selectedGroups: [],
    rowId: "",
    intervalType: TimelineIntervalTypeEnum.Month,
  })

  const setIsOpen = (isOpen: boolean) => {
    setDrillDownProps((prev) => ({
      ...prev,
      isOpen,
    }))
  }

  return {
    setIsOpen,
    drillDownProps,
    setDrillDownProps,
  }
}

export { useDrillDownState }
