import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import classNames from "classnames"
import { useMediaQuery } from "usehooks-ts"

interface PanelSidebarProps {
  children: React.ReactNode
  isOpen: boolean
  panelType: PanelSidebarType
}

type PanelSidebarType = "profile" | "succession" | "successionLarge"
type BreakPoints = { is437: boolean; is680: boolean; is1000: boolean }

const PanelSidebar: React.FC<PanelSidebarProps> = ({ children, isOpen, panelType }) => {
  const is437 = useMediaQuery("(min-width: 437px)")
  const is680 = useMediaQuery("(min-width: 680px)")
  const is1000 = useMediaQuery("(min-width: 1000px)")
  const breakpoints: BreakPoints = { is437, is680, is1000 }

  const sidebarVariants = {
    open: { x: 0, transition: { duration: 0.3 } },
    closed: { x: "100%", transition: { duration: 0.3 } },
  }

  // It is important that the animated div element has a width of a non-zero
  // value by using the inherited width of the parent.
  const baseStyles = "fixed bottom-0 right-0 top-0 z-10 overflow-auto h-screen"

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={classNames(baseStyles)}
          initial={{ width: 0 }}
          animate={{ width: panelWidth(panelType, breakpoints) }}
        >
          <motion.div
            animate="open"
            // A margin is needed here to let the drop shadow show
            className="680:elevation--profound h-auto min-h-screen bg-white 680:ml-4"
            exit="closed"
            initial="closed"
            variants={sidebarVariants}
          >
            {/* This is an important element to ensure specs have a reference so we know the sidebar
             has finished animating. */}
            <div
              className="absolute bottom-0 right-0 h-full w-1"
              data-testid="right-animated-complete"
            />
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

// Animations will not work unless the widths play very nicely here. It is
// important that the animated div element has a width of a non-zero value by
// using the inherited width of the parent. The fixed position plays a role in
// this. Also, using tailwind breakpoint values for widths will cause
// animations to not work. We need to know media types or css variables.
// See: https://samuelkraft.com/blog/responsive-animation-framer-motion
// See: https://www.youtube.com/watch?v=xSuxsfn13xg
// NOTE: This media query approach is not ideal for server side rendering,
// etc. in the future we may need to use some other approach.
//
// We avoid using these:
//   Nope: "max-w-screen w-screen 437:w-[437px]"
//   Nope: "max-w-screen w-screen 1000:w-[680px]"
//   Nope: "max-w-screen w-screen 680:w-[680px] 1000:w-[1000px]"
// In the absence of breakpoints, use explicit widths to appease the animations.
// In the future if we use breakpoints, dynamic values will not work:
//   const staticWidthStyles = `max-w-screen w-screen ${widthBreakpoint}:w-[${width}]`
// https://stackoverflow.com/questions/72889068/template-literal-not-working-correctly-with-tailwind-css
// https://tailwindcss.com/docs/content-configuration#dynamic-class-names
const panelWidth = (panelType: PanelSidebarType, breakpoints: BreakPoints): string => {
  if (panelType === "profile" && breakpoints.is437) return "437px"
  if (panelType === "succession" && breakpoints.is680) return "680px"
  if (panelType === "successionLarge" && breakpoints.is1000) return "1000px"
  if (panelType === "successionLarge" && breakpoints.is680) return "680px"
  return "100%"
}

export { PanelSidebar }
