import React, { useState } from "react"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/Tooltip"
import barsOpen from "images/bars-open.svg"
import barsClosed from "images/bars-closed.svg"
import { useNavController } from "v2/react/components/navigation/useNavController"
import { useTranslation } from "react-i18next"
import RootProvider from "v2/react/components/RootProvider"

function NavToggle() {
  const [showTooltip, setShowTooltip] = useState(false)
  const { toggleNav, nav } = useNavController()
  const { t } = useTranslation()

  const handleClick = () => {
    toggleNav()
    setShowTooltip(false)
  }

  return (
    <RootProvider>
      <Tooltip open={showTooltip} placement="right">
        <TooltipTrigger
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          className="w-full block"
        >
          <button
            onClick={handleClick}
            type="button"
            className="btn--icon btn--ghost nav-toggle group hover:bg-neutral-3"
          >
            <div className="nav-toggle--inner">
              <img
                src={barsOpen}
                alt="Navigation Toggle"
                className="open-icon opacity-[.64] group-hover:opacity-100"
              />
              <img
                src={barsClosed}
                alt="Navigation Toggle"
                className="closed-icon opacity-[.64] group-hover:opacity-100"
              />
            </div>
          </button>
        </TooltipTrigger>
        <TooltipContent className="react-tooltip-content">
          <span className="break-all">
            {nav?.classList.contains("shrink-nav")
              ? t("v2.shared.nav.nav_closed_tooltip")
              : t("v2.shared.nav.nav_open_tooltip")}
          </span>
        </TooltipContent>
      </Tooltip>
    </RootProvider>
  )
}

export { NavToggle }
