import fp from "lodash/fp"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import type {
  ChartSettings,
  ColorCodeIdByNameIndex,
  ColorCodesIndex,
  ColorCodingState,
  ConfigureColorCodingPayload,
  LoadColorCodesPayload,
  ReferenceColorCode,
  TabKey,
  VisualizationState,
} from "v2/redux/slices/VisualizationSlice/types"
import type { FieldKey } from "v2/redux/slices/NodeSlice/types"

import { GraphqlApi } from "v2/redux/GraphqlApi"
import { SortDirection } from "types/graphql.enums"

type ColorCode = ReferenceColorCode

type OpenColorPickerPayload = Pick<
  VisualizationState,
  "colorPickerPosition" | "colorPickerColorCode" | "colorPickerSelectedColor"
>

type SetColorPayload = {
  colorType?: "primary" | "secondary"
  themeOptionCategory: keyof VisualizationState["positionThemeOptions"]
  color: string
  id: number | string
}

type SetDataOptionsPayload = Pick<
  VisualizationState,
  "selectedDisplayFields" | "displayFieldOptions"
>

const InitialColorCodingState: ColorCodingState = {
  cardColorField: null,
  edgeColorField: null,
  cardColors: {},
  edgeColors: {},
  cardColorIdsByName: {},
  edgeColorIdsByName: {},
  isCardColorEnabled: false,
  isEdgeColorEnabled: false,
  activeColorCodingTab: "card",
  colorCodeAttributes: [],
}

const InitialState: VisualizationState = {
  ...InitialColorCodingState,
  builderPositionsMin: false,
  childrenCount: "all",
  collapseDepth: "2",
  colorPickerColorCode: null,
  colorPickerPosition: { top: 0, left: 0 },
  colorPickerSelectedColor: null,
  condensed: false,
  countDottedRelationships: true,
  countOpenPositions: true,
  displayFields: ["avatar", "name", "title"],
  displayFieldOptions: [],
  displayMode: "tree",
  displayModePrevious: "tree",
  enableChartSections: false,
  enableTextwrap: false,
  editMode: false,
  forceList: 0,
  gridPositionFilter: "all",
  metricsBreakdownOpenFor: [],
  metricsIncludeDotted: true,
  panOnScroll: true,
  positionThemeOptions: {},
  secondarySortField: "title",
  selectedColor: null,
  selectedChartType: "charts",
  selectedDisplayFields: [],
  selectedTab: "tab-color-coding",
  showColorPicker: false,
  showGroupData: true,
  showExpandedMetrics: true,
  showLabels: true,
  showPositionColors: false,
  showMetrics: false,
  showMetricsPercentage: false,
  sortDirection: SortDirection.Asc,
  sortField: "sort_order",
  superPanelOpen: false,
  superPanelFooter: false,
  themeOptionCategory: "employee_type",
}

const VisualizationSlice = createSlice({
  name: "visualization",
  initialState: InitialState,
  reducers: {
    patchSettings: (state, { payload }: PayloadAction<Partial<ChartSettings>>) =>
      fp.assign(state, payload),

    toggleVisualizationTools: (
      state,
      { payload: forceState }: PayloadAction<number | boolean | undefined>,
    ) =>
      fp.update("superPanelOpen", (current) =>
        forceState !== undefined ? !!forceState : !current,
      )(state),
    toggleShowGroupData: (state, { payload }: PayloadAction<boolean>) =>
      fp.set("showGroupData", payload)(state),
    toggleSuperPanelFooter: (state, { payload }: PayloadAction<boolean>) =>
      fp.set("superPanelFooter", payload)(state),
    toggleTab: (state, { payload: tab }: PayloadAction<TabKey | undefined>) =>
      fp.pipe(
        fp.set("superPanelOpen", true),
        fp.update("selectedTab", (curr) => (tab !== undefined ? tab : curr)),
      )(state),

    configureColorCoding: (state, { payload }: PayloadAction<ConfigureColorCodingPayload>) => {
      const pickConfiguration = fp.pick([
        "cardColorField",
        "edgeColorField",
        "isCardColorEnabled",
        "isEdgeColorEnabled",
        "activeColorCodingTab",
      ])

      return fp.assign(state, pickConfiguration(payload))
    },

    colorCodesPreloaded: (state, { payload }: PayloadAction<LoadColorCodesPayload>) =>
      putColorCodesIntoState(state, payload),

    setPositionThemeOptions: (
      state,
      { payload: options }: PayloadAction<VisualizationState["positionThemeOptions"]>,
    ) => fp.set("positionThemeOptions", options)(state),
    setThemeOptionCategory: (state, { payload }: PayloadAction<FieldKey>) => {
      if (state.superPanelOpen && state.themeOptionCategory !== payload)
        return fp.assign(state, { themeOptionCategory: payload, showPositionColors: true })

      return fp.set("themeOptionCategory", payload, state)
    },

    toggleChartSectionVisibility: (
      state,
      { payload }: PayloadAction<number | boolean | undefined>,
    ) =>
      fp.update("enableChartSections", (current) => (payload !== undefined ? !!payload : !current))(
        state,
      ),

    togglePositionColorVisibility: (
      state,
      { payload }: PayloadAction<number | boolean | undefined>,
    ) =>
      fp.update("showPositionColors", (current) => (payload !== undefined ? !!payload : !current))(
        state,
      ),

    openColorPicker: {
      prepare: (
        colorPickerPosition: VisualizationState["colorPickerPosition"],
        colorPickerSelectedColor: VisualizationState["colorPickerSelectedColor"],
        colorPickerColorCode: VisualizationState["colorPickerColorCode"],
      ): { payload: OpenColorPickerPayload } => ({
        payload: { colorPickerPosition, colorPickerColorCode, colorPickerSelectedColor },
      }),

      reducer: (state, { payload }: PayloadAction<OpenColorPickerPayload>) =>
        fp.assign(state, { ...payload, showColorPicker: true }),
    },

    closeColorPicker: (state) =>
      fp.assign(state, { showColorPicker: false, colorPickerColorCode: null }),

    setColor: {
      reducer: (
        state,
        {
          payload: { themeOptionCategory, id, color, colorType = "primary" },
        }: PayloadAction<SetColorPayload>,
      ) => fp.set(["positionThemeOptions", themeOptionCategory, id, colorType], color)(state),

      prepare: (
        themeOptionCategory: keyof VisualizationState["positionThemeOptions"],
        id: number | string,
        color: string,
        colorType: SetColorPayload["colorType"],
      ): { payload: SetColorPayload } => ({
        payload: { themeOptionCategory, id, color, colorType },
      }),
    },

    toggleColorCodingEnabled: (
      state,
      { payload }: PayloadAction<{ type?: "card" | "edge"; enabled?: boolean } | void>,
    ) => {
      const assignForType = payload?.type || state.activeColorCodingTab
      const boolKey = assignForType === "card" ? "isCardColorEnabled" : "isEdgeColorEnabled"
      const to = typeof payload?.enabled === "undefined" ? !state[boolKey] : payload.enabled
      return fp.set(boolKey, to, state)
    },

    changeColorCodingField: (
      state,
      action: PayloadAction<{ field: string; type?: "card" | "edge" }>,
    ) => handleChangeColorCodingField(state, action),

    changeColorInCode: (
      state,
      {
        payload,
      }: PayloadAction<{
        type: "card" | "edge"
        field?: string
        id: number | string
        color: string
      }>,
    ) => {
      const { type, field, id, color } = payload
      const colorsKey = type === "card" ? "cardColors" : "edgeColors"
      const fieldKey = field || state[type === "card" ? "cardColorField" : "edgeColorField"]
      if (!fieldKey) return state

      return fp.set([colorsKey, fieldKey, id, "color"], color, state)
    },

    changeActiveTab: (state, { payload }: PayloadAction<{ tabType?: "card" | "edge" }>) =>
      fp.set("activeColorCodingTab", payload.tabType, state),

    setDataOptions: (state, { payload }: PayloadAction<SetDataOptionsPayload>) =>
      fp.assign(state, payload),

    setEditMode: (state, { payload }: PayloadAction<boolean>) => fp.set("editMode", payload, state),
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(GraphqlApi.endpoints.getColorCodes.matchFulfilled, (state, { payload }) =>
        maybeHandleDroppedColorCodingDomain(state, payload),
      )
      .addMatcher(GraphqlApi.endpoints.getColorCodes.matchFulfilled, (state, { payload }) =>
        putColorCodesIntoState(state, payload),
      ),
})

/**
 * Handles potential edge case where a user is color coding with a specific
 * attribute that is no longer available.
 */
const maybeHandleDroppedColorCodingDomain = (
  state: VisualizationState,
  { colorCodeAttributes }: LoadColorCodesPayload,
): VisualizationState => {
  const { cardColorField, edgeColorField } = state
  const nextColorCodeAttributes = colorCodeAttributes ?? state.colorCodeAttributes

  const makeAction = (type: "card" | "edge") =>
    VisualizationSlice.actions.changeColorCodingField({ field: nextColorCodeAttributes[0], type })

  const mustChangeCard = cardColorField && !nextColorCodeAttributes.includes(cardColorField)
  const mustChangeEdge = edgeColorField && !nextColorCodeAttributes.includes(edgeColorField)

  let nextState = {
    ...state,
    isCardColorEnabled: mustChangeCard ? false : state.isCardColorEnabled,
    isEdgeColorEnabled: mustChangeEdge ? false : state.isEdgeColorEnabled,
  }

  if (mustChangeCard) nextState = handleChangeColorCodingField(nextState, makeAction("card"))
  if (mustChangeEdge) nextState = handleChangeColorCodingField(nextState, makeAction("edge"))

  return nextState
}

const putColorCodesIntoState = (
  state: VisualizationState,
  { colorCodes, colorCodeAttributes }: LoadColorCodesPayload,
) => {
  const incomingCardColors: ColorCodesIndex = {}
  const incomingEdgeColors: ColorCodesIndex = {}
  const incomingCardColorIdsByName: ColorCodeIdByNameIndex = {}
  const incomingEdgeColorIdsByName: ColorCodeIdByNameIndex = {}

  fp.each((colorCode) => {
    if (state.edgeColorField === colorCode.colorable_attribute)
      incomingEdgeColorIdsByName[colorCode.name] = colorCode.id
    if (state.cardColorField === colorCode.colorable_attribute)
      incomingCardColorIdsByName[colorCode.name] = colorCode.id

    if (!incomingCardColors[colorCode.colorable_attribute])
      incomingCardColors[colorCode.colorable_attribute] = {}
    if (!incomingEdgeColors[colorCode.colorable_attribute])
      incomingEdgeColors[colorCode.colorable_attribute] = {}

    incomingCardColors[colorCode.colorable_attribute][colorCode.id] = {
      id: colorCode.id,
      colorable_attribute: colorCode.colorable_attribute,
      name: colorCode.name,
      true_value: colorCode.true_value,
      color: colorCode.secondary,
      order: colorCode.order,
    }

    incomingEdgeColors[colorCode.colorable_attribute][colorCode.id] = {
      id: colorCode.id,
      colorable_attribute: colorCode.colorable_attribute,
      name: colorCode.name,
      true_value: colorCode.true_value,
      color: colorCode.primary,
      order: colorCode.order,
    }
  }, colorCodes)

  return {
    ...state,
    colorCodeAttributes: colorCodeAttributes ?? state.colorCodeAttributes,
    cardColors: fp.assign(state.cardColors, incomingCardColors),
    edgeColors: fp.assign(state.edgeColors, incomingEdgeColors),
    cardColorIdsByName: fp.assign(state.cardColorIdsByName, incomingCardColorIdsByName),
    edgeColorIdsByName: fp.assign(state.edgeColorIdsByName, incomingEdgeColorIdsByName),
  }
}

const handleChangeColorCodingField = (
  state: VisualizationState,
  { payload }: PayloadAction<{ field: string; type?: "card" | "edge" }>,
): VisualizationState => {
  const assignForType = payload.type || state.activeColorCodingTab
  const key = assignForType === "card" ? "cardColorField" : "edgeColorField"
  const colorsKey = assignForType === "card" ? "cardColors" : "edgeColors"
  const colorsById = state[colorsKey][payload.field] || {}
  const byNameKey = assignForType === "card" ? "cardColorIdsByName" : "edgeColorIdsByName"
  const pairs = fp.map(fp.props(["name", "id"]), fp.values(colorsById))

  return fp.assign(state, {
    [key]: payload.field,
    [byNameKey]: fp.fromPairs(pairs),
  })
}

export { ColorCode, VisualizationSlice, VisualizationState }
export const {
  toggleVisualizationTools,
  toggleTab,
  toggleColorCodingEnabled,
  changeColorCodingField,
  changeActiveTab,
  colorCodesPreloaded,
  configureColorCoding,
  changeColorInCode,
  setPositionThemeOptions,
  setThemeOptionCategory,
  toggleChartSectionVisibility,
  togglePositionColorVisibility,
  toggleShowGroupData,
  toggleSuperPanelFooter,
  openColorPicker,
  closeColorPicker,
  patchSettings,
  setColor,
  setDataOptions,
  setEditMode,
} = VisualizationSlice.actions
