import { EntityId } from "@reduxjs/toolkit"
import fp from "lodash/fp"

import type { Maybe } from "types/graphql"

// -- Id & Name
export const toEncodedIdNamePair = (id: EntityId, name: string) => `id:${id}<SEP>name:${name}`
export const getIdAndNameFromEncoded = (value: string) => {
  const [id, name] = fp.pipe(fp.split("<SEP>name:"), fp.map(fp.replace(/^id:/, "")))(value)
  return { id, name }
}

// -- Initial Data

const removeInitialDataPrefix = fp.replace(/^initializeWith:/, "")
const splitByDash = fp.split(/\s*-\s*/)
const joinTailWithDash = fp.pipe(fp.tail, fp.join(" - "))
const buildInitialData = (parts: string[]) => ({
  code: fp.head(parts),
  name: joinTailWithDash(parts),
})

export const toEncodedInitialData = (value: string) => `initializeWith:${value}`
export const isEncodedInitialData = (value: Maybe<string> | undefined): value is string =>
  fp.startsWith("initializeWith:", value ?? "")
export const getInitialDataFromEncoded = (value: Maybe<string> | undefined) =>
  fp.pipe(removeInitialDataPrefix, splitByDash, buildInitialData)(value ?? "")
