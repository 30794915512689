import React from "react"
import { AnimatePresence, motion } from "framer-motion"
import { EntityId } from "@reduxjs/toolkit"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"

import { AdpPausedChangeBatchAction } from "types/graphql.enums"
import {
  AdpChangeScreen,
  FlattenedAdpChangeBatch,
  selectChangeBatchesOverview,
  useScreenStackForAdpChangeModal,
} from "v2/redux/slices/AdpChangeSlice"
import {
  isBusy,
  isOpen,
  isPausedWithErrors,
  propIdFromUniqueKey,
  useAdpInteractiveChangeBatchesQuery,
  useAdpTransitionPausedBatchMutation,
} from "v2/redux/GraphqlApi/AdpChangeQueueApi"
import { Modal } from "v2/react/shared/Modal"
import { useAppSelector } from "v2/redux/store"

import {
  BatchStatus,
  ChangeBatchListTable,
} from "./ChangeBatchScreenStackModal/ChangeBatchListTable"
import { ChangeBatchTable } from "./ChangeBatchScreenStackModal/ChangeBatchTable"
import { ChangeRequestDetails } from "./ChangeBatchScreenStackModal/ChangeRequestDetails"
import { PausedWithErrorsModalFooter } from "./ChangeBatchScreenStackModal/PausedWithErrorsModalParts"
import { ScreenStackModalFooter } from "./ChangeBatchScreenStackModal/ScreenStackModalFooter"

type ChangeBatchScreenStackModalProps = {
  isMutating: boolean
  isOpen: boolean
  onSendChangesPress: (event: React.MouseEvent) => void
}

type ChangeBatchScreenStackModalFooterProps = {
  activeBatch?: FlattenedAdpChangeBatch
  isMutating: boolean
  openBatchConfig: {
    onClosePress: () => void
    onSendChangesPress: React.MouseEventHandler
  }
  pausedWithErrorsBatchConfig: {
    onCopyErrorsToNewBatchPress: (changeBatchId: EntityId) => void
    onIgnoreErrorsPress: (changeBatchId: EntityId) => void
  }
  screen: AdpChangeScreen
}

function deriveModalFooter({
  activeBatch,
  isMutating,
  openBatchConfig,
  pausedWithErrorsBatchConfig,
  screen,
}: ChangeBatchScreenStackModalFooterProps) {
  // We only ever show a footer when drilled into a batch.
  if (screen.screenKey !== "Batch") return undefined

  if (activeBatch && isPausedWithErrors(activeBatch)) {
    const { onCopyErrorsToNewBatchPress, onIgnoreErrorsPress } = pausedWithErrorsBatchConfig

    return (
      <PausedWithErrorsModalFooter
        batch={activeBatch}
        isMutating={isMutating}
        onCopyErrorsToNewBatchPress={onCopyErrorsToNewBatchPress}
        onIgnoreErrorsPress={onIgnoreErrorsPress}
      />
    )
  }

  if (activeBatch && isOpen(activeBatch)) {
    const { onClosePress, onSendChangesPress } = openBatchConfig

    return (
      <ScreenStackModalFooter
        isMutating={isMutating}
        onClosePress={onClosePress}
        onSendChangesPress={onSendChangesPress}
        screen={screen}
      />
    )
  }

  return undefined
}

const ChangeBatchScreenStackModal = ({
  isMutating,
  isOpen,
  onSendChangesPress,
}: ChangeBatchScreenStackModalProps) => {
  const {
    activeScreen,
    stack,
    changeLatestScrollTopFromUIEvent,
    closeStackModal,
    popStackModalScreen,
    scrollContainerRef,
  } = useScreenStackForAdpChangeModal()

  const [mutate] = useAdpTransitionPausedBatchMutation()
  const makePausedWithErrorsTransitionHandler =
    (actionType: AdpPausedChangeBatchAction) => (changeBatchId: EntityId) =>
      mutate({ actionType, changeBatchId: `${changeBatchId}` })
  const { t } = useTranslation()

  // Force a refetch when the modal first opens, and poll if a batch is being
  // actively processed. Need to tweak the flow a bit.
  const { active } = useAppSelector(selectChangeBatchesOverview)
  useAdpInteractiveChangeBatchesQuery(undefined, {
    pollingInterval: active && isBusy(active) ? 1500 : undefined,
    refetchOnMountOrArgChange: true,
  })

  const handleModalClose = () => closeStackModal()
  const handleBackActionClick = () => popStackModalScreen()

  if (!activeScreen) return null

  return (
    <Modal
      className="react-modal--max-h-screen flex-col flex"
      contentClassName="overflow-auto max-h-full"
      contentRef={scrollContainerRef}
      footer={deriveModalFooter({
        activeBatch: "batch" in activeScreen ? activeScreen.batch : undefined,
        isMutating,
        openBatchConfig: {
          onClosePress: handleModalClose,
          onSendChangesPress,
        },
        pausedWithErrorsBatchConfig: {
          onCopyErrorsToNewBatchPress: makePausedWithErrorsTransitionHandler(
            AdpPausedChangeBatchAction.RollOverToNextBatch,
          ),
          onIgnoreErrorsPress: makePausedWithErrorsTransitionHandler(
            AdpPausedChangeBatchAction.IgnoreErrors,
          ),
        },
        screen: activeScreen,
      })}
      headerClassName="!h-[76px]"
      id="adp-change-batch-screen-stack-modal"
      isOpen={isOpen}
      onBackActionClick={handleBackActionClick}
      onClose={handleModalClose}
      onContentScroll={changeLatestScrollTopFromUIEvent}
      showBackAction={stack.length > 1}
      size="xl"
      title={deriveTitle(activeScreen, t)}
      titleComponent={deriveTitleComponent(activeScreen, handleBackActionClick, t)}
    >
      <motion.div className="max-h-full overflow-auto p-6">
        <AnimatePresence initial={false}>
          {stack.map((stackScreen) => (
            <motion.div
              key={stackScreen.screenKey}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.25, type: "easeInEaseOut" }}
              style={stackScreen.screenKey !== activeScreen.screenKey ? { display: "none" } : {}}
            >
              {stackScreen.screenKey === "Batch" ? (
                <ChangeBatchTable batch={stackScreen.batch} />
              ) : null}
              {stackScreen.screenKey === "ChangeRequest" ? (
                <ChangeRequestDetails changeRequest={stackScreen.changeRequest} />
              ) : null}
              {stackScreen.screenKey === "TableOfBatches" ? <ChangeBatchListTable /> : null}
            </motion.div>
          ))}
        </AnimatePresence>
      </motion.div>
    </Modal>
  )
}

const deriveTitleComponent = (
  screen: AdpChangeScreen,
  handleBackActionClick: () => void,
  t: TFunction,
) => {
  // If this isn't for a batch, defer to default handling...
  if (screen.screenKey !== "Batch") return undefined

  return (
    <div className="items-center gap-4 flex">
      <button onClick={handleBackActionClick} type="button" className="btn--ghost mx-[-6px]">
        <FontAwesomeIcon className="icon-16" icon={["far", "arrow-left"]} />
      </button>
      <div className="h-9 w-px bg-neutral-8" />
      <span className="text-large">
        {t("v2.adp.change_batches.screen_title", {
          id: propIdFromUniqueKey("id")(screen.batch),
        })}
      </span>
      <BatchStatus batch={screen.batch} />
    </div>
  )
}

const deriveTitle = (screen: AdpChangeScreen, t: TFunction) => {
  switch (screen.screenKey) {
    case "TableOfBatches":
      return t("v2.adp.change_requests.change_log")
    case "ChangeRequest":
      return t("v2.adp.change_requests.screen_title", {
        id: propIdFromUniqueKey("id")(screen.changeRequest),
        batch_id: propIdFromUniqueKey("batchId")(screen.changeRequest),
      })
    case "Batch":
      return t("v2.adp.change_batches.screen_title", {
        id: propIdFromUniqueKey("id")(screen.batch),
      })
    default:
      return ""
  }
}

const MemoedChangeBatchScreenStackModal = React.memo(ChangeBatchScreenStackModal)

export { ChangeBatchScreenStackModal, MemoedChangeBatchScreenStackModal }
