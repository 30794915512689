import React, { Fragment } from "react"

import { HeadcountPlanParticipant } from "types/graphql.d"

import { EntireOrgRow } from "./EntireOrgRow"
import { ParticipantTableRow } from "./ParticipantTableRow"

interface Props {
  headcountPlanId: string
  logoThumbUrl: string
  participants: HeadcountPlanParticipant[]
}

function ParticipantTable({ headcountPlanId, logoThumbUrl, participants }: Props) {
  return (
    <div className="table hcp-overview-table">
      <table>
        <thead>
          <tr>
            <th>{"overview_table_column_planning_environments".t("headcount_plan")}</th>
            <th>{"overview_table_column_plan_owner".t("headcount_plan")}</th>
            <th>{"overview_table_column_department".t("headcount_plan")}</th>
            <th>{"overview_table_column_status".t("headcount_plan")}</th>
          </tr>
        </thead>
        <tbody>
          <EntireOrgRow headcountPlanId={headcountPlanId} logoThumbUrl={logoThumbUrl} />
          {rowsAtLevel(participants, 1, [1], headcountPlanId)}
        </tbody>
      </table>
    </div>
  )
}

const rowsAtLevel = (
  rows: HeadcountPlanParticipant[],
  level: number,
  continuationLineLevels: number[],
  headcountPlanId: string,
): React.ReactNode[] =>
  rows.map((participant: HeadcountPlanParticipant, index: number) => {
    const isLastSibling = index === rows.length - 1
    const updatedContinuationLineLevels = updateContinuationLineLevels(
      isLastSibling,
      level,
      continuationLineLevels,
    )

    return (
      <Fragment key={`${participant.id}-${participant.role || ""}`}>
        <ParticipantTableRow
          continuationLineLevels={updatedContinuationLineLevels}
          headcountPlanId={headcountPlanId}
          isLastSibling={isLastSibling}
          level={level}
          participant={participant}
        />
        {participant.children &&
          rowsAtLevel(
            participant.children,
            level + 1,
            updatedContinuationLineLevels,
            headcountPlanId,
          )}
      </Fragment>
    )
  })

// Dictates at which levels there is a continuation line to the next sibling.
// When a node is the last child, end any continuation line by removing the
// level from this lookup array.
const updateContinuationLineLevels = (
  isLastSibling: boolean,
  level: number,
  continuationLineLevels: number[],
) => {
  if (!isLastSibling) return [...continuationLineLevels, level]
  return continuationLineLevels.filter((continuationLevel) => continuationLevel !== level)
}

export { ParticipantTable }
