import fp from "lodash/fp"

import {
  CustomFieldValueAttributes,
  NodeInterface,
  OrgUnitAttributes,
  PersonPositionNodeAttributes,
  VariablePayAttributes,
} from "types/graphql"

type PartialOrUndefined = Partial<NodeInterface> | undefined

/**
 * Builds a "change" hash to apply to a node immediately while waiting for the
 * result of the mutation from the server.
 *
 * @public
 */
function prepareOptimisticChanges(
  record: NodeInterface | undefined,
  attributes: PersonPositionNodeAttributes,
) {
  return fp.reduce(
    (memo, [key, value]) => {
      switch (key) {
        case "lock_version":
          // Don't overwrite the lock version when applying changes
          // optimistically.
          return memo
        case "custom_field_values_attributes":
          return fp.update(
            "custom_field_values",
            fp.merge(
              buildDynamicFieldsHash(
                record?.custom_field_values as PartialOrUndefined,
                ["custom_field_id", "value"],
                value as CustomFieldValueAttributes[],
              ),
            ),
            memo,
          )
        case "variable_pays_attributes":
          return fp.update(
            "variable_pays",
            fp.merge(
              buildDynamicFieldsHash(
                record?.variable_pays as PartialOrUndefined,
                ["variable_pay_type_id", "amount"],
                value as VariablePayAttributes[],
              ),
            ),
            memo,
          )
        case "org_units_attributes":
          return fp.update(
            "org_units",
            fp.merge(
              buildDynamicFieldsHash(
                record?.org_units as PartialOrUndefined,
                ["org_unit_type_id", "org_unit_full_name"],
                value as OrgUnitAttributes[],
              ),
            ),
            memo,
          )
        default:
          return fp.set(key, value, memo)
      }
    },
    {} as Partial<NodeInterface>,
    fp.toPairs(attributes),
  )
}

const buildDynamicFieldsHash = <T>(
  into: Partial<NodeInterface> | undefined,
  parts: string[],
  arr: T[],
): Partial<NodeInterface> =>
  fp.pipe(fp.map(fp.props(parts)), fp.fromPairs, fp.merge(into || {}))(arr)

export { prepareOptimisticChanges }
