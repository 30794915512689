import { EntityId, bindActionCreators } from "@reduxjs/toolkit"

import type { AppDispatch, RootState } from "v2/redux/store"
import type {
  BeginWriteWithCursorPayload,
  CellCursor,
  MoveCursorPayload,
  PlaceOrTransitionCursorPayload,
  CursorOnEditableCell,
  CursorOnNonEditableCell,
  CursorUnit,
  EndWriteWithCursorPayload,
} from "v2/redux/slices/GridSlice/cursor/types"
import type { FieldKey } from "v2/redux/slices/NodeSlice/types"

import { CursorState } from "v2/redux/slices/GridSlice/cursor/types"
import { Direction } from "v2/react/utils/enums"
import { fieldUsesCollection } from "v2/redux/slices/GridSlice/gridHelpers/collections"
import { formattedNodeProp } from "v2/redux/slices/NodeSlice/nodeHelpers/nodeProps"
import { getFieldType } from "v2/redux/slices/GridSlice/gridHelpers/getFieldType"
import { nodeSelectors } from "v2/redux/slices/NodeSlice/NodeApi"
import {
  inEitherReadOnEditable,
  inEitherWriteState,
  onNothing,
} from "v2/redux/slices/GridSlice/cursor/cursorStates"
import { selectAdjacentCell } from "v2/redux/slices/GridSlice/cursor/cursorSelectors"
import { skeletonSelectors, transitionCursor } from "v2/redux/slices/GridSlice"

/** Special cursor which is not on anything. */
export const cursorUnit: CursorUnit = Object.freeze({ state: CursorState.Unit })

/**
 * Transitions the cell cursor into "write" mode, provided the cell is
 * editable.
 */
export const beginWriteWithCursor = ({ clobber }: BeginWriteWithCursorPayload) =>
  thunkWithCursorApi(({ transitionCursor, cursor }) => {
    if (!inEitherReadOnEditable(cursor)) return undefined
    const next = { ...cursor, enteredBy: "transition" as const }
    return typeof clobber === "object"
      ? transitionCursor({
          ...next,
          state: CursorState.WritingOnEditableWithInitial,
          initial: clobber.withValue ?? "",
        })
      : transitionCursor({ ...next, state: CursorState.WritingOnEditable })
  })

/**
 * Transitions the cell cursor from "write" mode to "read" mode. This isn't
 * necessary when moving the cursor to a new cell, but is handy if the goal
 * is to leave the cursor in place.
 */
export const endWriteWithCursor = (arg?: EndWriteWithCursorPayload) =>
  thunkWithCursorApi(({ cursor, transitionCursor }) => {
    if (!inEitherWriteState(cursor)) return

    const nextState = arg?.transitionKeyCanMove
      ? CursorState.OnEditableTransitionNext
      : CursorState.OnEditable
    transitionCursor({ ...cursor, state: nextState })
  })

/**
 * Places the cursor on a cell. If the cursor is already on the cell, attempts
 * transitioning the cursor to "writingOnEditable" (enabling changes).
 */
export const placeOrTransitionCursor = ({ rowId, fieldKey }: PlaceOrTransitionCursorPayload) =>
  thunkWithCursorApi(({ cursor, dispatch, getState, transitionCursor }) => {
    const sameCell = !onNothing(cursor) && cursor.rowId === rowId && cursor.fieldKey === fieldKey
    if (inEitherReadOnEditable(cursor) && sameCell) {
      dispatch(beginWriteWithCursor({}))
      return
    }

    const nextCursor = makeCursor(rowId, fieldKey, "placement", getState)
    if (sameCell || !nextCursor) return

    transitionCursor(nextCursor)
  })

export const moveCursor = ({ direction }: MoveCursorPayload) =>
  thunkWithCursorApi(({ cursor, getState, transitionCursor }) => {
    if (onNothing(cursor)) return

    const [nextRowId, nextFieldKey] = selectAdjacentCell(getState(), direction)
    const nextCursor = makeCursor(nextRowId, nextFieldKey, "movement", getState)
    if (!nextCursor) return

    transitionCursor(nextCursor)
  })

// Utilities

type GetRootState = () => RootState

type ThunkCursorApi = {
  cursor: CellCursor
  dispatch: AppDispatch
  getState: () => RootState
  transitionCursor: typeof transitionCursor
}

/**
 * Utility creating a thunk which only invokes the wrapped function if the
 * current state permits. The wrapped function is invoked with extra values
 * that are useful for managing the cursor.
 *
 * @private
 */
const thunkWithCursorApi =
  (func: (api: ThunkCursorApi) => void) => (dispatch: AppDispatch, getState: GetRootState) => {
    // No changes should be made to the cell cursor if the spreadsheet is not
    // in "edit" mode.
    if (!getState().visualization.editMode) return

    const cursor = getState().grid.cursor
    const actions = bindActionCreators({ transitionCursor }, dispatch)

    func({ dispatch, getState, cursor, transitionCursor: actions.transitionCursor })
  }

function makeCursor(
  rowId: EntityId,
  fieldKey: FieldKey,
  enteredBy: "movement" | "placement",
  getState: () => RootState,
): CursorOnEditableCell | CursorOnNonEditableCell | null {
  const node = nodeSelectors.selectById(getState(), rowId)
  const hasEntryInSkeleton = !!skeletonSelectors.selectById(getState(), rowId)
  if (!node || !hasEntryInSkeleton) return null

  const hasCollection = fieldUsesCollection(fieldKey)
  const isEditable = (node.editable_fields || []).indexOf(fieldKey) >= 0
  const currentValue = formattedNodeProp(fieldKey, node)
  const activeAttrs = { rowId: node.id, fieldKey, currentValue, enteredBy }
  const fieldType = getFieldType({ fieldKey, isEditable, hasCollection })

  return isEditable
    ? { state: CursorState.OnEditable, editable: true, fieldType, ...activeAttrs }
    : { state: CursorState.OnNonEditable, editable: false, fieldType: undefined, ...activeAttrs }
}

// Re-export direction for convenience
export { Direction }
