import { DisplayMode, NodeData, PositioningHelper } from "../node/types"

import threeLevelLayout from "./threeLevelLayout"
import condensedTree, { isCondensibleLeafNode } from "./condensedTree"
import options, { globals } from "../constants/options"

interface LayoutOptions {
  displayMode: DisplayMode
  condensed: boolean
}

const isThirdLevelNode = (d: NodeData, layoutOptions: LayoutOptions): boolean =>
  layoutOptions.displayMode === "three_level" && d.depth >= 2

/* eslint-disable no-param-reassign */

/**
 * Mutates NodeData in order to set the x and y coordinates based on the
 * layout modification being applied.
 *
 * Types of layout modifications:
 *
 *   - Three level tree mode; set third level siblings to stack vertically
 *   - Y-axis adjustments for cases where assistants are shown above
 *   - Condensed tree mode: set leaf nodes to stack vertically in two columns
 */
const applyLayoutAdjustments = (
  d: NodeData,
  layoutOptions: LayoutOptions,
  assistantsShownAbove: (d: NodeData) => boolean,
  nodeHeightWithSpacing: number,
  extraChartSectionHeight: number,
  positioningHelper: PositioningHelper,
): NodeData => {
  if (isThirdLevelNode(d, layoutOptions)) {
    threeLevelLayout.adjustLayoutForThirdLevel(
      d,
      nodeHeightWithSpacing,
      extraChartSectionHeight,
      positioningHelper,
    )
  } else {
    d.y = d.depth * nodeHeightWithSpacing
    d.visualDepth = d.depth
  }

  if (layoutOptions.displayMode === "tree" && layoutOptions.condensed) {
    condensedTree.condenseLeafNodes(d, nodeHeightWithSpacing, assistantsShownAbove)
  }

  if (
    assistantsShownAbove(d) &&
    !isThirdLevelNode(d, layoutOptions) &&
    !(layoutOptions.condensed && isCondensibleLeafNode(d))
  ) {
    d.y += nodeHeightWithSpacing - options.verticalSpacing
    if (layoutOptions.displayMode !== "cards") {
      d.y -= globals.statsBars.height
    }

    d.visualDepth = (d.visualDepth ?? 0) + 1
  }

  return d
}

/**
 * function to calculate separation between cousin or sibling nodes. Constant 1
 * value; the default is to separate by a factor of 2 if the nodes do not share
 * a parent.
 * @see +d3_layout_treeSeparation+ -- the original is something like this:
 *
 *   a.parent === b.parent ? 1 : 2
 */
export const d3TreeSeparation = (a: NodeData, b: NodeData): number => {
  if (a.parent === b.parent) {
    return 1
  }

  return a.parent?.children?.length === 1 && b.parent?.children?.length === 1 ? 1 : 1.2
}

export default applyLayoutAdjustments

export { threeLevelLayout, condensedTree }
