import { useCallback } from "react"
import { isEmpty } from "lodash/fp"
import { PositionSeatChangeIntent } from "types/graphql.enums"
import {
  useUpdatePersonPositionNodeMutation,
  nodeSelectors,
} from "v2/redux/slices/NodeSlice/NodeApi"
import { useAppDispatch } from "v2/redux/store"

interface PersonName {
  first_name: string
  last_name: string
  middle_initial: string
}
interface PositionSeatChangeProps {
  personName?: PersonName
  personId?: string
  rowId: string
  intent: PositionSeatChangeIntent
  cancelOptimisticUpdate?: boolean
}
const usePositionSeatChange = () => {
  const dispatch = useAppDispatch()
  const [performUpdateMutation] = useUpdatePersonPositionNodeMutation()

  const saveNewPerson = useCallback(
    async ({
      personName,
      personId,
      rowId,
      intent,
      cancelOptimisticUpdate = false,
    }: PositionSeatChangeProps) =>
      dispatch(async (_dispatch, getState) => {
        const node = nodeSelectors.selectById(getState(), rowId)
        if (!node) return { ok: false }

        const attributes = {
          lock_version: node.lock_version,
          person_change_attributes: {
            intent,
            ...(personId ? { person_id: personId } : {}),
            ...(!isEmpty(personName) ? { ...personName } : {}),
          },
        }

        const result = await performUpdateMutation({
          id: node.id,
          attributes,
          primaryTrigger: "name",
          cancelOptimisticUpdate,
        }).unwrap()

        const topLevel = result.updatePersonPositionNode
        const errors = (topLevel && topLevel.errors) || []
        return { ok: topLevel && errors.length === 0, errors }
      }),
    [dispatch, performUpdateMutation],
  )

  return [saveNewPerson]
}

export { usePositionSeatChange }
