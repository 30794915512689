import React from "react"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"

import { AdpChangeBatch } from "types/graphql"
import { countFailedRequests } from "v2/redux/GraphqlApi/AdpChangeQueueApi"

type PausedWithErrorsTriggerProps = {
  batch: AdpChangeBatch
}

type PausedWithErrorsContentProps = PausedWithErrorsTriggerProps & {
  contentOnly?: boolean
  onViewDetailsPress: () => void
}

export function PausedWithErrorsBadgeDropdownTrigger({ batch }: PausedWithErrorsTriggerProps) {
  const { t } = useTranslation()

  return (
    <>
      <FontAwesomeIcon icon={["far", "exclamation-triangle"]} />
      {t("v2.adp.change_batches.paused_with_errors_badge", {
        count: batch.changeRequests.length - countFailedRequests(batch),
        total: batch.changeRequests.length,
      })}
      <FontAwesomeIcon icon={["fas", "caret-down"]} size="1x" />
    </>
  )
}

export function PausedWithErrorsContent({
  batch,
  contentOnly,
  onViewDetailsPress,
}: PausedWithErrorsContentProps) {
  const { t } = useTranslation()

  return (
    <>
      <div className={cn({ "p-6": !contentOnly, "p-0 pb-4": contentOnly })}>
        <div className={cn("alert alert-caution", { "min-w-[312px]": contentOnly })}>
          <div className="flex">
            <div className="text-base-bold">
              <FontAwesomeIcon icon={["far", "exclamation-triangle"]} className="mr-2" />
            </div>
            <div className="flex-1">
              <div className="text-base-bold">
                {t("v2.adp.change_batches.paused_with_errors_title", {
                  count: batch.changeRequests.length - countFailedRequests(batch),
                  total: batch.changeRequests.length,
                })}
              </div>
              <p>{t("v2.adp.change_batches.paused_with_errors_badge_content")}</p>
            </div>
          </div>
        </div>
      </div>
      <hr className={cn("my-0", { "mx-[-1rem]": contentOnly })} />
      <div
        className={cn("justify-end gap-2 whitespace-normal py-3 text-left flex", {
          "px-6": !contentOnly,
        })}
      >
        <button className="btn btn--secondary" onClick={onViewDetailsPress} type="button">
          {t("v2.defaults.view_details")}
        </button>
      </div>
      {contentOnly && <hr className="mx-[-1rem] mb-4 mt-0" />}
    </>
  )
}
