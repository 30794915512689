import { defaultMemoize } from "reselect"
import exportStyles from "../constants/exportStyles"
import { NodeData } from "../node/types"

type TextStyleType =
  | "text"
  | "chart_section_name"
  | "stats_bar_text"
  | "chain_of_command"
  | "direct_reports_text"
  | "name"
  | "title_text"
type TextMetricsFake = { width: number }

const measureText = defaultMemoize(
  (text, style: TextStyleType = "text"): TextMetrics | TextMetricsFake => {
    // Canvas used to measure text
    const canvas = document.createElement("canvas")
    const canvasContext = canvas.getContext("2d")
    if (!canvasContext) return { width: 0 }
    canvasContext.font = [
      exportStyles[style]["font-weight"] as string,
      exportStyles[style]["font-size"],
      exportStyles[style]["font-family"],
    ].join(" ")

    return canvasContext.measureText(text)
  },
)

const wrap = (
  text: d3.Selection<NodeData>,
  width: number,
  style: TextStyleType = "text",
  lineHeight = 0.1,
) =>
  text.attr("text-anchor", "middle").each(function (this: SVGTextElement) {
    const text = window.d3.select(this)
    const words = text.text().trim().split(/\s+/).reverse()
    let line: string[] = []
    let lineNumber = 0
    const dy = 1
    let tspan = text.text("").append("tspan").attr("x", 0)
    let word
    // eslint-disable-next-line no-cond-assign
    while ((word = words.pop())) {
      if (measureText(word, style).width > width) {
        const halfIndex = Math.floor(word.length / 2)
        const firstHalf = `${word.substr(0, halfIndex)}-`
        const secondHalf = word.substr(halfIndex)
        words.push(secondHalf)
        words.push(firstHalf)
      } else {
        line.push(word)
        const currentLineText = line.join(" ")
        tspan.text(currentLineText)
        if (measureText(currentLineText, style).width > width) {
          line.pop()
          tspan.text(line.join(" "))
          line = [word]
          lineNumber += 1
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("dy", `${lineNumber * lineHeight + dy}em`)
            .text(word)
        }
      }
    }

    this.dataset.wrappedIntoLines = String(lineNumber + 1)
  })

const toTitleCase = (str: string): string =>
  str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())

const textUtils = {
  measureText,
  toTitleCase,
  wrap,
}

export default textUtils

export { wrap, measureText, toTitleCase }
