import React from "react"

import { HeadcountPlan } from "types/graphql.d"

interface Props {
  headcountPlan: HeadcountPlan
}

function PositionParticipantsInfo({ headcountPlan }: Props) {
  const positionCount = headcountPlan.planAggregations?.newPositionsCount

  return headcountPlan.lockedAt ? null : (
    <div className="hcp-overview-top-manage-info">
      <div>
        <span className="font-bold">
          {"plan_info_position_count".t("headcount_plan", null, "placeholder", positionCount, [
            positionCount,
          ])}
        </span>
      </div>
    </div>
  )
}

export { PositionParticipantsInfo }
