import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from "react"
import { ControlProps } from "@jsonforms/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"

import { DropdownMenu } from "v2/react/shared/DropdownMenu"
import { Person } from "types/graphql"
import { useAutoComplete } from "v2/react/hooks/useAutocomplete"
import { usePersonSearch } from "v2/react/hooks/usePersonSearch"

import { InputErrorText } from "./InputErrorText"
import { useValidation } from "./hooks/useValidation"

const JsonPersonInput: FC<ControlProps> = ({
  config,
  data,
  id,
  handleChange,
  label,
  path,
  schema,
}) => {
  const { showError, errorMessage } = useValidation({
    data,
    path,
    schema,
    submitting: config.submitting,
  })
  const [isFocused, setIsFocused] = useState(false)

  const {
    showResultList,
    setShowResultList,
    inputValue,
    setInputValue,
    peopleResult,
    handleInputChange,
  } = usePersonSearch({
    omitValues: data?.name ? [data?.name] : [],
    fieldValue: data?.name || "",
  })

  const {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useAutoComplete({ showList: showResultList, setShowList: setShowResultList })

  useEffect(() => {
    if (isFocused) {
      refs.domReference.current?.focus()
    } else {
      refs.domReference.current?.blur()
    }
  }, [isFocused, refs])

  const handleChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    handleInputChange(event)

    if (value.length > 0) {
      setShowResultList(true)
      setActiveIndex(0)
    } else {
      setShowResultList(false)
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && activeIndex != null && peopleResult[activeIndex]) {
      handleResultClick(peopleResult[activeIndex])
    }
  }

  const handleResultClick = (person: Person) => {
    setInputValue(person.name)
    setActiveIndex(null)
    setShowResultList(false)
    setIsFocused(false)
    handleChange(path, { name: person.name, id: person.id })
  }

  const handleClearValue = () => {
    setInputValue("")
    handleChange(path, { name: "", id: "" })
    setIsFocused(true)
  }

  return (
    <div className={classNames("input-group", { "form-error": showError })}>
      <label htmlFor={id} className="cursor-default items-center whitespace-pre-wrap flex">
        {label}
      </label>

      <div className="relative">
        <input
          aria-autocomplete="list"
          id={id}
          className="input suffix-pad"
          value={inputValue}
          ref={refs.setReference}
          /* eslint-disable react/jsx-props-no-spreading */
          {...getReferenceProps({
            onKeyDown: handleKeyDown,
            onChange: handleChangeEvent,
          })}
        />
        {data?.name?.length ? (
          <button className="suffix" onClick={handleClearValue} type="button">
            <FontAwesomeIcon icon={["far", "times"]} />
          </button>
        ) : null}
      </div>
      <DropdownMenu
        showList={showResultList && !!inputValue.trim().length}
        floatingRef={refs.setFloating}
        floatingStyles={floatingStyles}
        floatingProps={getFloatingProps}
        wrapperClasses="AutocompleteField"
        context={context}
      >
        <>
          {peopleResult.map((person, index) => (
            <div
              role="option"
              data-id={person.id}
              data-name={person.name}
              aria-selected={activeIndex === index}
              key={person.id}
              className={personClassName(activeIndex, index)}
              ref={(node) => {
                listRef.current[index] = node
              }}
              /* eslint-disable react/jsx-props-no-spreading */
              {...getItemProps({
                onClick: () => handleResultClick(person),
              })}
            >
              <div className="AutocompleteField__result-thumb">
                <img alt={person.name || ""} src={person.avatarThumbUrl || ""} />
              </div>
              <div className="AutocompleteField__result-text">
                <div className="AutocompleteField__result-title">{person.name}</div>
                <div>{person.primaryPosition?.title || " "}</div>
              </div>
            </div>
          ))}
          {peopleResult?.length === 0 && inputValue.trim().length > 0 && (
            <div className="AutocompleteField__no-result">
              <p className="AutocompleteField__no-result-text">
                {"No Positions Found".t("org_chart")}
              </p>
            </div>
          )}
        </>
      </DropdownMenu>
      <InputErrorText message={errorMessage} showError={showError} />
    </div>
  )
}

export { JsonPersonInput }

const personClassName = (activeIndex: number | null, index: number) =>
  classNames("AutocompleteField__result", { highlight: activeIndex === index })
