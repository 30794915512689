import React, { useState } from "react"
import { BioSocialLink } from "types/graphql"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/Tooltip"

interface SocialLinkProps {
  socialLink: BioSocialLink
}

function SocialLink({ socialLink }: SocialLinkProps) {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <Tooltip open={showTooltip}>
      <TooltipTrigger
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <a
          href={socialLink.url}
          target="_blank"
          rel="noreferrer"
          className="text-neutral-80 inline-block"
        >
          <i className={window.App.Helpers.socialNetworkFa(socialLink.socialNetwork)} />
        </a>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content">
        <span className="break-all">{socialLink.url}</span>
      </TooltipContent>
    </Tooltip>
  )
}

export { SocialLink }
