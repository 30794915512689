import React, { useState } from "react"
import classNames from "classnames"

import { CareerLadder, CareerLadderTrack } from "types/graphql"
import { BadgeInput } from "v2/react/components/careerLadders/Builder/BadgeInput"
import { AnimatePresence, motion } from "framer-motion"
import { opaqueScaleDelay } from "v2/react/utils/framerAnimationVariants"
import { TruncatedTitle } from "v2/react/components/careers/Index/TruncatedTitle"

type TitleColumnsForTrackProps = {
  careerLadder: CareerLadder
  editMode: boolean
  smallMode?: boolean
  track: CareerLadderTrack
}

const TitleColumnsForTrack = ({
  careerLadder,
  editMode,
  track,
  smallMode = false,
}: TitleColumnsForTrackProps) => {
  const childTracks = (careerLadder.careerLadderTracks || []).filter(
    (t: CareerLadderTrack) => t.parentTrackId === track.id,
  )

  // If there is just a primary track, we omit any labels. The primary track
  // does not have a label.
  if (!childTracks) return null

  return (
    <div
      className={classNames("mb-1 justify-center gap-2 grid", `grid-cols-${childTracks.length}`)}
    >
      {childTracks.map((track: CareerLadderTrack) => (
        <div className="w-full justify-center flex" key={track.id}>
          <TrackTitle
            careerLadder={careerLadder}
            editMode={editMode}
            smallMode={smallMode}
            track={track}
          />
        </div>
      ))}
    </div>
  )
}

export { TitleColumnsForTrack }

interface TrackTitleProps {
  careerLadder: CareerLadder
  editMode: boolean
  smallMode: boolean
  track: CareerLadderTrack
}

const TrackTitle = ({ careerLadder, editMode, smallMode, track }: TrackTitleProps) => {
  const [editingTitle, setEditingTitle] = useState(!track.name)
  const [inputValue, setInputValue] = useState(track.name || "")

  if (!editMode && !track.name) return <span />
  const activelyEditing = editMode && (!track.name || editingTitle || inputValue !== track.name)
  const displayOnly = smallMode

  return (
    <AnimatePresence>
      {displayOnly ? (
        <DisplayBadge displayValue={track.name || ""} />
      ) : (
        <EditableBadge
          activelyEditing={activelyEditing}
          careerLadderId={careerLadder.id}
          careerLadderTrackId={track.uniqueKey}
          displayValue={track.name || ""}
          editingInput={editingTitle}
          setEditingInput={setEditingTitle}
          setValue={setInputValue}
          value={inputValue}
        />
      )}
    </AnimatePresence>
  )
}

interface EditableBadgeProps {
  activelyEditing: boolean
  careerLadderId: string
  careerLadderTrackId: string
  displayValue: string
  editingInput: boolean
  setEditingInput: (value: boolean) => void
  setValue: (value: string) => void
  value: string
}

const EditableBadge = ({
  activelyEditing,
  careerLadderId,
  careerLadderTrackId,
  displayValue,
  editingInput,
  setEditingInput,
  setValue,
  value,
}: EditableBadgeProps) =>
  activelyEditing ? (
    <motion.span initial="hidden" animate="shown" variants={opaqueScaleDelay}>
      <BadgeInput
        careerLadderTrackId={careerLadderTrackId}
        careerLadderId={careerLadderId}
        focusInput={editingInput}
        onSave={() => setEditingInput(false)}
        value={value}
        setValue={setValue}
      />
    </motion.span>
  ) : (
    <motion.button
      initial="hidden"
      animate="shown"
      variants={opaqueScaleDelay}
      className="badge--blue badge--sm sm:badge py-0 text-xs sm:text-sm-bold"
      type="button"
      onClick={() => setEditingInput(true)}
    >
      <span>{displayValue}</span>
    </motion.button>
  )

const DisplayBadge = ({ displayValue }: { displayValue: string }) => (
  <motion.span
    initial="hidden"
    animate="shown"
    variants={opaqueScaleDelay}
    className="badge--blue badge--sm !min-w-[auto] !max-w-[160px] truncate py-0"
  >
    <TruncatedTitle title={displayValue} titleClasses="text-xs" />
  </motion.span>
)
