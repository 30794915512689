import { AnyAction, EntityId, createAction, createEntityAdapter } from "@reduxjs/toolkit"

import { RootState } from "v2/redux/store"
import { NotificationTopic } from "types/graphql.enums"

import { EntityEvents, ProcessingAction, ProcessingRecord, SubscribePayload } from "./types"

const selectId = ({ subjectId }: { subjectId: EntityId }) => subjectId
export const processingAdapter = createEntityAdapter<ProcessingRecord>({ selectId })
export const processingSelectors = processingAdapter.getSelectors(
  (state: RootState) => state.notification.processing,
)

export const entityEventLogsAdapter = createEntityAdapter<EntityEvents>({ selectId })
export const entityEventLogsSelectors = entityEventLogsAdapter.getSelectors(
  (state: RootState) => state.notification.entityEventLogs,
)

export const batchAdapter = createEntityAdapter<{ subjectId: EntityId; count: number }>({
  selectId,
})
export const batchSelectors = batchAdapter.getSelectors(
  (state: RootState) => state.notification.batches,
)

export const subscribed = createAction<SubscribePayload>("notification/subscribed")
export const unsubscribed = createAction<SubscribePayload>("notification/unsubscribed")

export const matchProcessingSubscribed = (action: AnyAction): action is ProcessingAction =>
  subscribed.match(action) && action.payload.topic === NotificationTopic.Processing
