import fp from "lodash/fp"
import { EntityId, createSelector } from "@reduxjs/toolkit"

import type { RootState } from "v2/redux/store"
import type { Transition } from "v2/redux/slices/NodeSlice/types"

import { errorSetSelectors } from "v2/redux/slices/NodeSlice"

const withFieldKey = (_i1: unknown, _i2: unknown, f: string) => f
const withBool = (_i1: unknown, _id2: unknown, _i3: unknown, i4: boolean) => i4

/**
 * @public
 * @returns an error message for the cell if applicable; undefined otherwise.
 */
const selectCellErrorMessage = createSelector(
  [errorSetSelectors.selectById, withFieldKey, withBool],
  (errorSet, fieldKey, isFirst) => {
    if (!errorSet) return undefined
    if (isFirst && errorSet.baseErrors.length > 0) return fp.join(". ", errorSet.baseErrors)
    if (fieldKey in errorSet.fieldErrors && errorSet.fieldErrors[fieldKey].length > 0)
      return fp.join(". ", errorSet.fieldErrors[fieldKey])

    // This appeases ESLint.
    return undefined
  },
)

const selectTransitionsIndex = (state: RootState) => state.node.transitions
const withNodeId = (_: unknown, id: EntityId) => id

/**
 * @public
 * @returns an active transition for the node's field; undefined otherwise.
 */
const selectFieldTransition = createSelector(
  [selectTransitionsIndex, withNodeId, withFieldKey],
  (transitions, id, fieldKey): Transition | undefined => fp.prop([id, fieldKey], transitions),
)

export { selectCellErrorMessage, selectFieldTransition }
