import React from "react"
import { LongTextEditableCell } from "v2/react/shared/TableDatasheet/LongTextEditableCell"
import { StrikethroughCell } from "v2/react/shared/TableDatasheet/StrikethroughCell"
import { CompareValues } from "v2/react/shared/TableDatasheet/CompareValues"
import { HeadcountPlanDatasheetRow } from "./types"
import { useSaveCell } from "../hooks/useSaveCell"

type Props = {
  row: HeadcountPlanDatasheetRow
  headcountPlanId: string
  participantId?: string
  readOnly: boolean
}
export function JustificationCell({ row, headcountPlanId, participantId, readOnly }: Props) {
  const saveFn = useSaveCell(headcountPlanId, "justification", row, participantId)

  const currentValue = row.positionAttributesWithEdits.justification ?? ""

  if (row.excluded) {
    return <StrikethroughCell value={currentValue} />
  }

  if (readOnly) {
    return <CompareValues className="non-editable-cell">{currentValue}</CompareValues>
  }

  return (
    <LongTextEditableCell
      rowId={row.id}
      columnId="justification"
      saveFn={saveFn}
      currentValue={currentValue}
    />
  )
}
