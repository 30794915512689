import React from "react"

import { Approver, Person } from "types/graphql"
import { ApproverSelection } from "v2/react/shared/Approvals/ApproverSelection"
import { PersonSelection } from "v2/react/shared/Approvals/PersonSelection"

interface Props {
  approvers: Person[]
  onPeopleUpdated: (selectedPeople: Person[], type: RecruitingSettingPersonType) => void
  notificationRecipients: Person[]
}

type RecruitingSettingPersonType = "approver" | "notificationRecipient"

function SettingsForm({ approvers, onPeopleUpdated, notificationRecipients }: Props) {
  const onNotificationRecipientUpdated = (selectedPeople: Person[]) =>
    onPeopleUpdated(selectedPeople, "notificationRecipient")
  const onApproversUpdated = (selectedApprovers: Approver[]) => {
    const selectedPeople: Person[] = selectedApprovers.map((a) => ({
      ...a.person,
      sortOrder: a.sortOrder,
    }))
    onPeopleUpdated(selectedPeople, "approver")
  }

  return (
    <div className="pb-4">
      <ApproverSelection
        approvers={approvers.map((p) => ({
          sortOrder: p.sortOrder,
          state: "pending_approval",
          id: "",
          person: p,
        }))}
        description={"settings_default_approvers_description".t("job_requisition")}
        onApproversUpdated={onApproversUpdated}
        title={"settings_default_approvers_title".t("job_requisition")}
        translationDomain="jon_requisition"
      />
      <PersonSelection
        description={"settings_default_notification_recipients_description".t("job_requisition")}
        title={"settings_default_notification_recipients_title".t("job_requisition")}
        onPeopleUpdated={onNotificationRecipientUpdated}
        people={notificationRecipients}
      />
    </div>
  )
}

export { RecruitingSettingPersonType, SettingsForm }
