import React from "react"
import { useHeadcountPlanDatasheet } from "v2/react/hooks/useHeadcountPlanDatasheet"
import { Collections, HeadcountPlan } from "types/graphql"
import { OwnerBanner } from "./OwnerBanner"
import { AddNewPositionButton } from "../AddNewPositionButton"
import { HeadcountPlanDatasheet } from "../HeadcountPlanDatasheet"

interface Props {
  csvDownloadRef: React.RefObject<HTMLButtonElement>
  currentPerson: {
    name: string
    primaryPosition: {
      id: string
      title: string
    }
  }
  displayState: "approved" | "original"
  dropdownCellCollections: Collections | undefined
  headcountPlan: HeadcountPlan | undefined
  headcountPlanId: string
  positionId: string
}

export function OwnerDatasheet({
  csvDownloadRef,
  currentPerson,
  displayState,
  dropdownCellCollections,
  headcountPlan,
  headcountPlanId,
  positionId,
}: Props) {
  const allPositionsInPlan = headcountPlan?.allPositionsInPlan ?? []
  const approvedPositions = headcountPlan?.approvedPositions ?? []
  const isFinalized = !!headcountPlan?.lockedAt
  const positionCount = headcountPlan?.planAggregations?.newPositionsCount
  const currentDate = new Date().toISOString().split("T")[0].replace(/-/g, "")
  const csvDownloadName = `${headcountPlan?.name}_${currentDate}`.replace(/\s/g, "")

  const { rows, columns } = useHeadcountPlanDatasheet({
    attributes: headcountPlan?.allowedAttributes ?? [],
    positions: isFinalized && displayState === "approved" ? approvedPositions : allPositionsInPlan,
    dropdownCellCollections: dropdownCellCollections ?? {},
    hideOptions: isFinalized,
    showStatus: isFinalized && displayState === "original",
    newPositionIdPrefix: headcountPlan?.newPositionIdPrefix,
  })

  return (
    <>
      <OwnerBanner headcountPlan={headcountPlan} />
      {headcountPlan?.lockedAt ? null : (
        <div id="hcp-positions-addbar" className="page-margin-b">
          <div id="hcp-positions-addbar__positions-msg">
            <p>
              <span className="font-bold">
                {"plan_info_position_count".t(
                  "headcount_plan",
                  null,
                  "placeholder",
                  positionCount,
                  [positionCount],
                )}
              </span>
            </p>
          </div>
          <div>
            <AddNewPositionButton
              reportsTo={{
                id: currentPerson.primaryPosition.id,
                name: currentPerson.name,
                title: currentPerson.primaryPosition.title,
                label:
                  currentPerson.name ||
                  currentPerson.primaryPosition.title ||
                  currentPerson.primaryPosition.id,
              }}
              headcountPlanId={headcountPlanId}
              positionId={positionId}
              startDate={headcountPlan?.startDate}
            />
          </div>
        </div>
      )}
      <HeadcountPlanDatasheet
        forEntireOrg
        headcountPlanId={headcountPlanId}
        headcountPlan={headcountPlan}
        rows={rows}
        columns={columns}
        readOnly={isFinalized}
        csvDownloadRef={csvDownloadRef}
        csvDownloadName={csvDownloadName}
      />
    </>
  )
}
