import React from "react"
import cn from "classnames"
import { Chart } from "types/graphql"
import { approvalInProgress } from "v2/react/components/orgChart/ChartApproval/approvalUtils"
import { toggleCancelModal } from "v2/redux/slices/ApprovalSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface Props {
  chart: Chart
}

function EditApprovalButton({ chart }: Props) {
  const displayMode = useAppSelector((state) => state.visualization.displayMode)
  const dispatch = useAppDispatch()

  const openCancelModal = () =>
    dispatch(
      toggleCancelModal({
        open: true,
        isEditType: approvalInProgress(chart?.chartApprovers || []),
      }),
    )

  return (
    <button
      type="button"
      onClick={openCancelModal}
      className={cn("btn--sm btn--secondary ml-auto", {
        "sm:btn--large sm:btn--secondary": displayMode === "grid",
      })}
    >
      {"edit_chart".t("org_chart")}
    </button>
  )
}

export { EditApprovalButton }
