import { IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"
import { ApprovalActionType } from "v2/react/shared/Status/statusUtils"
import { useAppSelector } from "v2/redux/store"

interface ButtonDetails {
  name: string
  text: string
  icon: IconName
  iconClass: string
  action: () => void
}

interface ApprovalActionsProps {
  selectedAction: ApprovalActionType | null
  onStatusSelection: (action: ApprovalActionType) => void
}

function ApprovalActions({ selectedAction, onStatusSelection }: ApprovalActionsProps) {
  const displayMode = useAppSelector((state) => state.visualization.displayMode)
  const actionButtonContent: ButtonDetails[] = [
    {
      name: "approve",
      text: "approve".t("status_defaults"),
      icon: "check-circle" as IconName,
      iconClass: "icon--success",
      action: () => onStatusSelection("approved"),
    },
    {
      name: "changes_requested",
      text: "request_changes".t("status_defaults"),
      icon: "minus-circle" as IconName,
      iconClass: "icon--caution",
      action: () => onStatusSelection("changes_requested"),
    },
    {
      name: "denied",
      text: "deny_approval".t("status_defaults"),
      icon: "times-circle" as IconName,
      iconClass: "icon--critical",
      action: () => onStatusSelection("denied"),
    },
  ]

  return (
    <div className="items-center justify-between gap-2 flex">
      {actionButtonContent.map(
        (button) =>
          button.name !== selectedAction && (
            <button
              key={button.name}
              type="button"
              className={cn("btn--sm btn--secondary", {
                "sm:btn--large sm:btn--secondary": displayMode === "grid",
              })}
              onClick={button.action}
            >
              <FontAwesomeIcon icon={["fad", button.icon]} className={button.iconClass} />
              {button.text}
            </button>
          ),
      )}
    </div>
  )
}

export { ApprovalActions }
