import type { ListenerEffectAPI } from "@reduxjs/toolkit"

import type { AppDispatch, RootState } from "v2/redux/store"
import type { CursorEventDetail } from "v2/redux/slices/GridSlice/cursor/types"

import { onDifferent } from "v2/redux/slices/GridSlice/cursor/cursorStates"
import { selectCursor, selectCursorIndices } from "v2/redux/slices/GridSlice/cursor/cursorSelectors"

type DispatchArg = { api: ListenerEffectAPI<RootState, AppDispatch> }

export const cursorEventTarget = new EventTarget()

export function dispatchCursorEvents({ api: { getState, getOriginalState } }: DispatchArg) {
  const originalCursor = selectCursor(getOriginalState())
  const originalCursorIndices = selectCursorIndices(getOriginalState())
  const cursor = selectCursor(getState())
  const cursorIndices = selectCursorIndices(getState())

  const detail: CursorEventDetail = { originalCursor, originalCursorIndices, cursor, cursorIndices }

  if (onDifferent(originalCursor, cursor))
    cursorEventTarget.dispatchEvent(makeEvent("cellCursorTargetChange", detail))

  if (originalCursor.state !== cursor.state)
    cursorEventTarget.dispatchEvent(makeEvent("cellCursorStateChange", detail))
}

const makeEvent = (eventKey: string, detail: CursorEventDetail) =>
  new CustomEvent<CursorEventDetail>(eventKey, { detail })
