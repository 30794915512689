import React from "react"
import { useTranslation } from "react-i18next"

import PageNav from "v2/react/components/navigation/PageNav"
import { ActionBlock } from "v2/react/components/navigation/PageNav/ActionBlock"
import {
  TitleBlock,
  TitleHeaderWithParent,
} from "v2/react/components/navigation/PageNav/TitleBlock"
import { UrlHelper } from "v2/react/utils/urls"

function Header() {
  const { t } = useTranslation()
  return (
    <PageNav>
      <TitleBlock>
        <TitleHeaderWithParent
          parentTitle={t("v2.career_ladders.header.job_architecture")}
          parentTitleUrl={UrlHelper.jobArchitecturePath()}
          title={t("v2.career_ladders.header.career_ladders")}
        />
      </TitleBlock>
      <ActionBlock> </ActionBlock>
    </PageNav>
  )
}
export { Header }
