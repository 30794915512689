import React from "react"
import { HeadcountPlanParticipant } from "types/graphql.d"
import { AlertBanner } from "v2/react/shared/AlertBanner"

type Props = {
  participant: HeadcountPlanParticipant
}

export function ProposalSubmittedBanner({ participant }: Props) {
  const lockedAt = new Date(participant.lockedAt)
  const lockedAtDateStr = `${
    lockedAt.getMonth() + 1
  }/${lockedAt.getDate()}/${lockedAt.getFullYear()}`

  return (
    <AlertBanner
      type="success"
      className="mb-4"
      messages={[
        {
          text: participant.submitsTo ? (
            <span
              className="font-normal"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: "proposal_submitted".t(
                  "headcount_plan",
                  null,
                  "1",
                  participant.planAggregations?.newPositionsCount,
                  [
                    participant.planAggregations?.newPositionsCount,
                    participant.lockedBy?.name,
                    participant.submitsTo.person.name,
                    lockedAtDateStr,
                  ],
                ),
              }}
            />
          ) : (
            <span
              className="font-normal"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: "proposal_submitted_to_org".t(
                  "headcount_plan",
                  null,
                  "1",
                  participant.planAggregations?.newPositionsCount,
                  [
                    participant.planAggregations?.newPositionsCount,
                    participant.lockedBy?.name,
                    lockedAtDateStr,
                  ],
                ),
              }}
            />
          ),
          icon: <i className="far fa-check-circle h-4 w-4 text-status-success" />,
        },
      ]}
    />
  )
}
