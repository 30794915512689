import React, { useState } from "react"

import {
  useGetAtsOptionsQuery,
  useGetPositionForRequisitionQuery,
} from "v2/redux/GraphqlApi/JobRequisitionsApi"
import { AtsOptions, PositionActiveJobRequisition } from "types/graphql"
import { Spinner } from "v2/react/shared/Spinner"
import { StatusBadge } from "v2/react/shared/Status/StatusBadge"
import { StoreProvider } from "v2/redux/StoreProvider"

import { InitFailure } from "./utils"
import { GreenhouseForm, initGreenhouseForm } from "./GreenhouseForm"
import { Trigger } from "./Trigger"
import { LeverForm, initLeverForm } from "./LeverForm"

function init(title: string, departments: string[], location: string, atsOptions: AtsOptions) {
  if (atsOptions.integration === "greenhouse") {
    return initGreenhouseForm(title, departments, location, atsOptions)
  }
  if (atsOptions.integration === "lever") {
    return initLeverForm(title, departments, location)
  }
  return {
    success: false,
    message: "unable_to_get_ats_data".t("ats"),
  } as InitFailure
}

export type SetFormDataFieldActionType = {
  field: keyof SendToAtsFormData
  value: string
}

interface SendToAtsFormData {
  externalTemplateId?: string
  externalDepartmentId?: string
  externalLocationId?: string
  jobTitle?: string
  systemUid?: string
}

type SendToAtsProps = {
  asDropdownMenuLink: boolean
  positionId: string
  title: string
  orgUnitNames: string[]
  locationName: string
  connectedRequisitions: { id: number; reqId: string; status: string }[]
  withIcon?: boolean
}

function WithProvider({
  asDropdownMenuLink,
  positionId,
  title,
  orgUnitNames,
  locationName,
  connectedRequisitions,
  withIcon,
}: SendToAtsProps) {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const { data, isLoading: atsOptionsLoading } = useGetAtsOptionsQuery()
  const { data: positionData, isLoading: positionDataLoading } = useGetPositionForRequisitionQuery({
    positionId: `position_${positionId}`,
  })

  if (atsOptionsLoading || positionDataLoading) {
    return (
      <div className="text-center">
        <Spinner style={spinnerStyleProps} />
      </div>
    )
  }

  if (!data?.atsOptions) {
    return (
      <div>
        <p>ATS Integration Error</p>
      </div>
    )
  }

  if (connectedRequisitions.length > 0) {
    return (
      <div className="w-full">
        {connectedRequisitions.map((cr) => (
          <div key={cr.reqId}>
            <div className="mb-4 justify-between flex">
              <div>{"external_req_id".t("ats")}</div>
              <div>{cr.reqId}</div>
            </div>
            <div className="mb-4 justify-between flex">
              <div>{"external_req_status".t("ats")}</div>
              <div>{cr.status ? <StatusBadge status={cr.status} displayType="button" /> : "-"}</div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  const initData = init(title, orgUnitNames, locationName, data.atsOptions)

  if (!initData.success) {
    return (
      <div>
        <p>ATS Integration Error</p>
        <p>{initData.message}</p>
      </div>
    )
  }

  if (
    positionData?.position?.activeJobRequisitions &&
    positionData.position.activeJobRequisitions.length > 0
  ) {
    return <ActiveRequisitionList requisitions={positionData.position.activeJobRequisitions} />
  }

  return (
    <div className="w-full justify-center flex">
      <Trigger asDropdownMenuLink={asDropdownMenuLink} onClick={openModal} withIcon={withIcon} />
      {initData.integration === "greenhouse" && (
        <GreenhouseForm
          closeModal={closeModal}
          formSchema={initData.schema}
          initialFormData={initData.data}
          isOpen={isOpen}
          options={initData.options}
          positionId={positionId}
        />
      )}
      {initData.integration === "lever" && (
        <LeverForm
          closeModal={closeModal}
          formSchema={initData.schema}
          initialFormData={initData.data}
          isOpen={isOpen}
          positionId={positionId}
        />
      )}
    </div>
  )
}

const spinnerStyleProps: React.CSSProperties = {
  position: "relative",
  top: 0,
  left: 0,
  margin: 0,
}

const ActiveRequisitionList = ({
  requisitions,
}: {
  requisitions: PositionActiveJobRequisition[]
}) => (
  <div className="w-full">
    {requisitions.map((req: PositionActiveJobRequisition) => (
      <div key={req.id}>
        <div className="justify-between flex">
          <p>{"req_id".t("ats")}</p>
          <p>{req.systemUid}</p>
        </div>
        <div className="justify-between flex">
          <div>{"approval_status".t("ats")}</div>
          <div>
            {req.approvalState ? (
              <StatusBadge status={req.approvalState} displayType="button" />
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>
    ))}
  </div>
)

function SendToAts({
  asDropdownMenuLink = false,
  positionId,
  title,
  locationName,
  orgUnitNames,
  connectedRequisitions,
  withIcon,
}: SendToAtsProps) {
  return (
    <StoreProvider>
      <WithProvider
        asDropdownMenuLink={asDropdownMenuLink}
        positionId={positionId}
        title={title}
        locationName={locationName}
        orgUnitNames={orgUnitNames}
        connectedRequisitions={connectedRequisitions}
        withIcon={withIcon}
      />
    </StoreProvider>
  )
}

export { SendToAts }
