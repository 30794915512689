import React from "react"

import { Link } from "v2/react/shared/Links/Link"
import { UrlHelper } from "v2/react/utils/urls"

const PositionLink = ({ id, value }: { id: string; value: string }) => (
  <Link to={UrlHelper.positionOnChartPath(id)} newTab>
    {value}
  </Link>
)

export { PositionLink }
