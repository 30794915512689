import { FloatingPortal } from "@floating-ui/react"
import dayjs from "dayjs"
import React, { useState } from "react"
import { BioLicense, FeatureFlags, FileUpload } from "types/graphql"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { ProfilePanelAbilities } from "v2/react/components/orgChart/ProfilePanel/ProfilePanel"
import { useSortedBoxes } from "v2/react/components/orgChart/ProfilePanel/useSortedBoxes"
import { AccordionModulePanel } from "v2/react/shared/AccordionModulePanel"
import { AccordionModulePanelOverlay } from "v2/react/shared/AccordionModulePanelOverlay"
import { PreviewContainer } from "v2/react/shared/PreviewContainer"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"
import { useAppSelector } from "v2/redux/store"

interface BioLicensesProps {
  abilities: ProfilePanelAbilities
  featureFlags: FeatureFlags
}

function BioLicenses({ abilities, featureFlags }: BioLicensesProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const { currentBox, toggleBox } = useSortedBoxes("bio_licenses", "person")
  const [showOverlay, setShowOverlay] = useState(false)
  const [overlayContent, setOverlayContent] = useState<BioLicense | null>(null)
  const [filePreview, setFilePreview] = useState<FileUpload | null>(null)

  if (!currentBox || !person) return null
  if (!featureFlags.bio || !person.bioLicenses?.length) return null

  const headerContent = <div className="title">{"bio_licenses".t("org_chart")}</div>
  const overlayHeader = <div className="title">{overlayContent?.name}</div>
  const overlayBody = (
    <>
      <ModuleListItem>
        <div>
          <div>{"Description".t("org_chart")}</div>
          <div>{sanitizeParse(overlayContent?.description || "")}</div>
        </div>
      </ModuleListItem>
      {abilities.canReadSensitivePersonData && (
        <>
          <ModuleListItem>
            <div>{"License or Certification Number".t("org_chart")}</div>
            <div>{overlayContent?.number}</div>
          </ModuleListItem>
          <ModuleListItem>
            <div>{"Expiration Date".t("org_chart")}</div>
            <div>
              {overlayContent?.expirationDate &&
                dayjs(overlayContent?.expirationDate).format("MMM D, YYYY")}
            </div>
          </ModuleListItem>
          {overlayContent?.fileUploads && (
            <ModuleListItem>
              <div>{"Documentation".t("org_chart")}</div>
              <div>
                {overlayContent.fileUploads.map((upload) => (
                  <div key={upload.id}>
                    <button
                      className="link-text"
                      type="button"
                      onClick={() => setFilePreview(upload)}
                    >
                      {window.App.Helpers.truncate(upload.title, 25, true)}
                    </button>
                    <FloatingPortal>
                      <PreviewContainer
                        url={filePreview?.file || ""}
                        title={filePreview?.title || ""}
                        showPreview={!!filePreview}
                        onClose={() => setFilePreview(null)}
                      />
                    </FloatingPortal>
                  </div>
                ))}
              </div>
            </ModuleListItem>
          )}
        </>
      )}
    </>
  )

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
      showOverlay={showOverlay}
      overlayContent={
        <AccordionModulePanelOverlay
          isOpen={showOverlay}
          closePanel={() => {
            setOverlayContent(null)
            setShowOverlay(false)
          }}
          headerContent={overlayHeader}
        >
          {overlayBody}
        </AccordionModulePanelOverlay>
      }
    >
      <ModuleListItem>
        <div className="flex-wrap gap-2.5 flex">
          {person.bioLicenses?.map((license) => (
            <button
              key={license.id}
              type="button"
              className="badge--link hover mb-2"
              onClick={() => {
                setOverlayContent(license)
                setShowOverlay(true)
              }}
            >
              {license.name}
            </button>
          ))}
        </div>
      </ModuleListItem>
    </AccordionModulePanel>
  )
}

export { BioLicenses }
