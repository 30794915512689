import { DayHeaderContentArg, EventContentArg, EventHoveringArg } from "@fullcalendar/core"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import dayjs from "dayjs"
import isToday from "dayjs/plugin/isToday"
import { animate, motion, useMotionValue } from "framer-motion"
import React, { useEffect, useRef, useState } from "react"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSortedBoxes } from "v2/react/components/orgChart/ProfilePanel/useSortedBoxes"
import { AccordionModulePanel } from "v2/react/shared/AccordionModulePanel"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/Tooltip"
import { useAppSelector } from "v2/redux/store"

function Schedule() {
  const person = useAppSelector((state) => state.profilePanel.person)
  const { currentBox, toggleBox } = useSortedBoxes("schedule", "person")

  const calendarRef = useRef<HTMLDivElement>(null)
  const eventRef = useRef<HTMLDivElement>(null)
  const [tooltip, setTooltip] = useState({ id: "", show: false })
  const left = useMotionValue(0)
  const top = useMotionValue(0)
  dayjs.extend(isToday)

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      const box = eventRef.current?.getBoundingClientRect()
      if (!box) return
      animate(left, e.clientX - box.left)
      animate(top, e.clientY - box.top)
    }
    window.addEventListener("mousemove", handleMouseMove)

    return () => {
      window.removeEventListener("mousemove", handleMouseMove)
    }
  }, [top, left])

  if (!currentBox || !person) return null

  const hasPtoToday = person.calendarEvents?.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => event.id.includes("pto_request") && dayjs(event.start).isToday(),
  )

  const headerContent = (
    <div className="title">
      {"schedule".t("org_chart")}
      {!!hasPtoToday && (
        <span className="label label-xl" id="pto-today">
          {"PTO today".t("org_chart")}
        </span>
      )}
    </div>
  )

  const createHeaderContent = (param: DayHeaderContentArg) => (
    <div>
      <div>{dayjs(param.date).format("ddd").toUpperCase()}</div>
      <div className="secondary-text">{dayjs(param.date).format("MMM D")}</div>
    </div>
  )

  const createEvent = (args: EventContentArg) => {
    // eslint-disable-next-line no-underscore-dangle
    const { title, publicId } = args.event._def

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const event = mappedDates.find((ev: any) => ev.id === publicId)
    const isMultiDay = dayjs(event?.end).diff(dayjs(event?.start), "day")

    let timeDisplay
    if (isMultiDay) {
      timeDisplay = `${dayjs(event?.start).format("M/D h:mm")} - ${dayjs(event?.end).format(
        "M/D h:mm",
      )}`
    } else {
      timeDisplay = args.timeText
    }

    if (!title || publicId !== tooltip.id || !tooltip.show) return <span />
    return (
      <div className="h-full w-full" ref={eventRef}>
        <motion.div className="absolute h-5 w-5" style={{ top, left }}>
          <TooltipTrigger>
            <div className="h-5 w-5" />
          </TooltipTrigger>
          <TooltipContent className="react-tooltip-content pointer-events-none">
            <span className="break-all">{`${timeDisplay} ${title}`}</span>
          </TooltipContent>
        </motion.div>
      </div>
    )
  }

  const makeFutureDate = (dayOfWeek: number) =>
    dayjs().day(dayOfWeek).isBefore(dayjs(), "day")
      ? dayjs().day(dayOfWeek + 7)
      : dayjs().day(dayOfWeek)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mappedDates = person.calendarEvents?.map((event: any) => {
    if (!event.title) {
      return {
        ...event,
        title: !event.title && "",
        start: makeFutureDate(event.dow[0])
          .set("hour", event.start.split(":")[0])
          .set("minute", event.start.split(":")[1] || 0)
          .toDate(),
        end: makeFutureDate(event.dow[0])
          .set("hour", event.end.split(":")[0])
          .set("minute", event.end.split(":")[1] || 0)
          .toDate(),
      }
    }
    const startParts = event.start.split("+")[0].split("-").slice(0, 3).join("-")
    const endParts = event.end.split("+")[0].split("-").slice(0, 3).join("-")

    return {
      ...event,
      start: dayjs(startParts).toDate(),
      end: dayjs(endParts).toDate(),
    }
  })

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      <ModuleListItem>
        <div>{"Name".t("org_chart")}</div>
        <div>{person.defaultSchedule}</div>
      </ModuleListItem>
      <ModuleListItem>
        <div className="schedule-calendar w-full" ref={calendarRef}>
          <Tooltip open placement="left">
            <FullCalendar
              height={300}
              dayHeaderContent={createHeaderContent}
              plugins={[timeGridPlugin]}
              initialView="timeGridWeek"
              headerToolbar={{ start: "", end: "" }}
              scrollTime={`${dayjs().hour()}:00:00`}
              firstDay={dayjs().day()}
              slotMinWidth={50}
              events={mappedDates}
              eventTextColor="transparent"
              eventDisplay="block"
              eventClassNames={(args) =>
                // eslint-disable-next-line no-underscore-dangle
                args.event._def.publicId.includes("schedule") ? "schedule-event" : "alt-event"
              }
              eventContent={createEvent}
              eventMinWidth={46}
              eventMouseEnter={(event: EventHoveringArg) => {
                setTooltip({
                  // eslint-disable-next-line no-underscore-dangle
                  id: event.event._def.publicId,
                  show: true,
                })
              }}
              eventMouseLeave={() => setTooltip({ id: "", show: false })}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              eventOrder={(event: any) => (event.publicId.includes("schedule") ? -1 : 1)}
              views={{
                week: {
                  slotMinWidth: 50,
                  slotDuration: "00:30:00",
                  slotLabelInterval: { hours: 1 },
                  allDaySlot: false,
                },
              }}
            />
          </Tooltip>
        </div>
      </ModuleListItem>
    </AccordionModulePanel>
  )
}

export { Schedule }
