import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { HeadcountPlan, HeadcountPlanParticipant } from "types/graphql.d"
import { HeadcountPlanParticipantStatus } from "types/graphql.enums"
import { AlertBanner } from "v2/react/shared/AlertBanner"
import { FinalizedBanner } from "../banners/FinalizedBanner"

interface Props {
  headcountPlan: HeadcountPlan
  onClose: () => void
  showNotifications: boolean
}

function Notifications({ headcountPlan, onClose, showNotifications }: Props) {
  if (!showNotifications) return null

  const isEveryoneInvited: boolean = allInvited(headcountPlan)
  const isEveryoneSubmitted: boolean = allSubmitted(headcountPlan)

  if (headcountPlan.lockedAt) {
    return (
      <div className="pb-4">
        <FinalizedBanner
          positionsApproved={
            headcountPlan.allPositionsInPlan?.filter(
              (position) => position.type === "new" && position.status === "approved",
            ).length ?? 0
          }
        />
      </div>
    )
  }

  return (
    <div className="pb-4">
      <AlertBanner
        messages={[
          {
            text: message(isEveryoneInvited, isEveryoneSubmitted, headcountPlan),
            icon: <FontAwesomeIcon icon={["far", "paper-plane"]} />,
          },
        ]}
        type="info"
        onClose={onClose}
      />
    </div>
  )
}

const allInvited = (headcountPlan: HeadcountPlan) => {
  const uninvitedParticipants: HeadcountPlanParticipant[] = (
    headcountPlan.participants || []
  ).filter((p: HeadcountPlanParticipant) => !p.status)
  return uninvitedParticipants.length === 0
}

const allSubmitted = (headcountPlan: HeadcountPlan) => {
  const unsubmittedParticipants: HeadcountPlanParticipant[] = (
    headcountPlan.participants || []
  ).filter((p: HeadcountPlanParticipant) => p.status !== HeadcountPlanParticipantStatus.Complete)
  return unsubmittedParticipants.length === 0
}

const message = (
  isEveryoneInvited: boolean,
  isEveryoneSubmitted: boolean,
  headcountPlan: HeadcountPlan,
) => {
  if (isEveryoneSubmitted) {
    return (
      <p
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: "plan_notification_all_submitted_content".t("headcount_plan", null, null, null, [
            `/headcount_plans/${headcountPlan.id}/owner`,
            headcountPlan.name,
          ]),
        }}
      />
    )
  }
  if (isEveryoneInvited) {
    return "plan_notification_all_invited".t("headcount_plan")
  }
  return ""
}

export { Notifications }
