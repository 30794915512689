import { useCallback, useState } from "react"

interface Props {
  renderedPositionIds?: number[]
  exportAsCSV?: (ids: number[], columns: string[]) => void
}

function useGridExportModal({ renderedPositionIds, exportAsCSV }: Props) {
  const [exportModalIsOpen, setExportModalIsOpen] = useState(false)
  const [selectedExportColumns, setSelectedExportColumns] = useState<string[]>([])

  const handleSelectedExportColumns = useCallback((columns: string[]) => {
    setSelectedExportColumns(columns)
  }, [])

  const onExportModalSave = async () => {
    if (!exportAsCSV || !renderedPositionIds) return
    await exportAsCSV(renderedPositionIds, selectedExportColumns)
    setExportModalIsOpen(false)
  }

  return {
    exportModalIsOpen,
    handleSelectedExportColumns,
    onExportModalSave,
    setExportModalIsOpen,
  }
}

export { useGridExportModal }
