import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { selectNumericIdsForExport } from "v2/redux/combinedSelectors"
import { exportAsCSV } from "org_chart/grid/actions/toolbarActions"
import { Peripherals } from "packs/org_chart"

const Toolbar = ({ exportAsCSV, renderedPositionIds, peripheralsProps }) => {
  return (
    <div className="org-chart-toolbar datasheet">
      <Peripherals
        abilities={peripheralsProps.abilities}
        approvalProps={peripheralsProps.approvalProps}
        enableHistory={peripheralsProps.enableHistory}
        importDropdownProps={peripheralsProps.importDropdownProps}
        isOfficialChart={peripheralsProps.isOfficialChart}
        sharePath={peripheralsProps.sharePath}
        renderedPositionIds={renderedPositionIds}
        exportAsCSV={exportAsCSV}
      />
    </div>
  )
}

Toolbar.propTypes = {
  exportAsCSV: PropTypes.func.isRequired,
  renderedPositionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  peripheralsProps: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ exportAsCSV }, dispatch)

const mapStateToProps = (state) => ({ renderedPositionIds: selectNumericIdsForExport(state) })

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar)
