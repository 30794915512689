import { useState } from "react"
import { isEqual, isEmpty } from "lodash"
import { useDebounce } from "usehooks-ts"

import { usePositionTypesConnectionQuery } from "v2/redux/GraphqlApi/PositionTypesApi"
import { AUTOCOMPLETE_LIMIT } from "v2/react/constants"

interface Props {
  filter: string
  excludeEmpty?: boolean
  notInCareerLadder?: boolean
}

const usePositionTypeSearch = ({
  filter,
  excludeEmpty = false,
  notInCareerLadder = false,
}: Props) => {
  const [returnEmpty, setReturnEmpty] = useState(false)

  const searchTerm = useDebounce(filter || "", 350)

  const { data, isFetching } = usePositionTypesConnectionQuery(
    { searchTerm, first: AUTOCOMPLETE_LIMIT, excludeEmpty, notInCareerLadder },
    { skip: !searchTerm },
  )

  const positionTypes = data?.nodes || []

  // Handles the case where the input changes from empty to non-empty, and we
  // need to return an empty list of options until the fresh data returns.
  if (isEmpty(filter) && !returnEmpty) {
    setReturnEmpty(true)
  } else if (!isEmpty(filter) && isEqual(filter, searchTerm) && !isFetching && returnEmpty) {
    setReturnEmpty(false)
  }

  return {
    positionTypes: returnEmpty ? [] : positionTypes,
    returnEmpty,
    searchTerm,
  }
}

export { usePositionTypeSearch }
