import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { prepareIconClass } from "v2/react/utils/misc"
import { CareerLadderTrack } from "types/graphql"

type PositionConnectorProps = {
  canSplitTrack?: boolean
  editMode: boolean
  onSplitTrack: () => void
  showLine?: boolean
}

function PositionConnector({
  canSplitTrack = false,
  editMode,
  showLine = true,
  onSplitTrack,
}: PositionConnectorProps) {
  const { t } = useTranslation()

  return (
    <div className="group relative w-full justify-center flex">
      <div
        className={classNames("my-1 h-4 w-0", {
          "border-0": !showLine,
          "rounded-full border border-solid border-primary-50": showLine,
        })}
      />
      {canSplitTrack && editMode && (
        <button
          type="button"
          onClick={onSplitTrack}
          className="link absolute top-1 h-4 bg-white text-xs text-primary-100 opacity-0 transition-all duration-200 ease-in-out group-hover:opacity-100"
        >
          <FontAwesomeIcon icon={prepareIconClass("fac code-fork")} className="fa-lg mr-1" />
          {t("v2.career_ladders.builder.split_track")}
        </button>
      )}
    </div>
  )
}

type TracksConnectorProps = {
  tracks: CareerLadderTrack[]
}
// More/nested tracks may be supported in the future. This track connector is
// experimental at this point with with svg. In the future we may use d3,
// borders, or multiple/better svg scaling.
const TracksConnector = ({ tracks }: TracksConnectorProps) => {
  if (tracks.length === 1) return null
  return (
    <div className="my-1 flex">
      <div className="flex-1" />
      <div className="h-[38px] w-[12px] text-right">
        <svg className="w-[9px]" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#8DB0FF"
            d="m0,4 c0,-0.55,0.45,-1,1,-1 c0.55,0,1,0.45,1,1 v7 c0,3.87,3.13,7,7,7 v2 c-4.97,0,-9,-4.03,-9,-9 v-2 Z"
          />
        </svg>
      </div>
      <div className="my-1 box-border h-4 flex-1 border-0 border-b-2 border-solid border-primary-50" />
      <div className="h-[38px] w-[16px]">
        <svg width="16px" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#8DB0FF"
            d="m16,20 c-3.87,0,-7,3.13,-7,7 v5 c0,0.55,-0.45,1,-1,1 c-0.55,0,-1,-0.45,-1,-1 v-5 c0,-3.87,-3.13,-7,-7,-7 v-2 c3.53,0,6.58,2.03,8.06,4.99 c1.52,-2.83,4.5,-4.76,7.94,-4.99 Z"
          />
        </svg>
      </div>
      <div className="my-1 box-border h-4 flex-1 border-0 border-b-2 border-solid border-primary-50" />
      <div className="h-[38px] w-[14px]">
        <svg xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#8DB0FF"
            d="m9,4 c0,-0.55,0.45,-1,1,-1 c0.55,0,1,0.45,1,1 v7 c0,4.97,-4.03,9,-9,9 h-2 v-2 h2 c3.87,0,7,-3.13,7,-7 v7.14 Z"
          />
        </svg>
      </div>
      <div className="flex-1" />
    </div>
  )
}

export { PositionConnector, TracksConnector }
