import React, { FC } from "react"
import { ControlProps } from "@jsonforms/core"
import { useJsonForms } from "@jsonforms/react"

import { formNumericValue, formStringValue } from "./utils/forms"
import { basePayLabel, compensationLabelPrefix } from "./utils/compensation"

type ControlPropsSubset = Pick<ControlProps, "visible">

// https://jsonforms.discourse.group/t/computed-field-values/114
const JsonModuleListBaseCompensation: FC<ControlPropsSubset> = ({ visible }) => {
  const context = useJsonForms()
  const formData = context?.core?.data

  if (!visible) return null

  const hours = formNumericValue(formData, ["position", "basePay", "hoursPerWeek"])
  const payAmount = formNumericValue(formData, ["position", "basePay", "amount"])
  const payTypeId = formStringValue(formData, ["position", "basePay", "payType", "id"])

  return (
    <div>
      <div className="module-card-list-item">
        <label className="text-neutral-64">{compensationLabelPrefix(formData)} Base Pay</label>
        <div data-testid="module-list-item-value">{basePayLabel(payTypeId, payAmount, hours)}</div>
      </div>
    </div>
  )
}

export { JsonModuleListBaseCompensation }
