import fp from "lodash/fp"

import { Collection, Collections, NodeInterface } from "types/graphql"
import { Field } from "v2/redux/slices/ContainerSlice/types"
import { FieldKey as NodeFieldKey } from "v2/redux/slices/NodeSlice/types"

import { AUTOCOMPLETE_LIMIT, NONE_KEY } from "v2/react/constants"
import { orgUnitTypeRegex, variablePayTypeRegex } from "v2/react/utils/regex"

type FieldKey = NodeFieldKey | "business_unit" | "cost_number" | "department" | "tags"

type CollectionMap = {
  [K in FieldKey]?: keyof Collections
}

interface CollectionQueryArgs {
  filter?: string
  chartId?: string
  includeSubFamilies?: boolean
  tagDomain?: string
  taggableEntity?: string
  excludeRecord?: string
  keys?: string[]
  filters?: { field: string; value: string }[]
}

type CollectionQueryConfig = {
  [K in keyof Collections]?: CollectionQueryArgs
}

/**
 * Used to identify "None" keys in select options.
 */
const NONE_KEY_SUFFIX = NONE_KEY

/**
 * These fields are not editable in the grid, and should not
 * be included in the collections query. Keeping this list
 * here for reference.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NON_EDITABLE_FIELDS_TO_COLLECTION_MAP: CollectionMap = {
  chart_section: "chartSections",
  job_family: "jobFamilies",
}

const SUGGESTED_AUTOCOMPLETE_FIELD_TO_COLLECTION_MAP: CollectionMap = {
  title: "positionTypes",
  tags: "tags",
  // orgUnits are included here, but need pattern matching to work.
}

const FORCED_AUTOCOMPLETE_FIELD_TO_COLLECTION_MAP: CollectionMap = {
  location: "locations",
}

const AUTOCOMPLETE_FIELD_TO_COLLECTION_MAP = {
  ...SUGGESTED_AUTOCOMPLETE_FIELD_TO_COLLECTION_MAP,
  ...FORCED_AUTOCOMPLETE_FIELD_TO_COLLECTION_MAP,
}

/**
 * These fields all posess "static collections", and should
 * have a classic select-like dropdown in the grid.
 */
const FIELD_TO_STATIC_COLLECTION_MAP: CollectionMap = {
  base_pay_type: "basePayTypes",
  eeoc_classification: "eeocClassifications",
  employee_status: "employeeStatuses",
  employee_type: "employeeTypes",
  flight_risk: "flightRisks",
  flsa_classification: "flsaClassifications",
  hiring_priority: "hiringPriorities",
  performance: "performances",
  position_base_pay_type: "positionBasePayTypes",
  position_importance: "positionImportances",
  position_status: "positionStatuses",
  potential: "potentials",
  // Schedule is a special case because it's a dynamic field, but we're
  // treating it as static.
  schedule: "schedules",
}

const COLLECTIONS_WITH_SYSTEM_TYPE = ["employeeStatuses"]

const isSuggestedAutocompleteField = (fieldKey: FieldKey) =>
  !!SUGGESTED_AUTOCOMPLETE_FIELD_TO_COLLECTION_MAP[fieldKey] || orgUnitTypeRegex.test(fieldKey)

const isForcedAutocompleteField = (fieldKey: FieldKey) =>
  !!FORCED_AUTOCOMPLETE_FIELD_TO_COLLECTION_MAP[fieldKey]

/**
 * Certain fields on the spreadhsheet should have a "None" option
 * present in the dropdown. This list is used to tack on these
 * None options after the collection data loads.
 */
const COLLECTIONS_WITH_NONE_OPTION: string[] = ["position_importance"]

/**
 * Similar to collectionsWithNoneOption, but for these fields,
 * the "None" option should have a blank label.
 *
 * NOTE: At some point, we plan to unify how we're handling this,
 * so that either all these non-required fields have a "None" label,
 * or all have a blank label.
 */
const COLLECTIONS_WITH_BLANK_OPTION: string[] = [
  "employee_type",
  "flight_risk",
  "performance",
  "potential",
  "flsa_classification",
  "eeoc_classification",
  "hiring_priority",
  "position_status",
]

const fieldUsesCollection = (fieldKey: string) =>
  fieldKey in FIELD_TO_STATIC_COLLECTION_MAP || variablePayTypeRegex.test(fieldKey)

const getStaticCollectionFieldsFromColumns = (columns: Field[]) =>
  columns.reduce<(keyof Collections)[]>((acc, c) => {
    const collectionKey = FIELD_TO_STATIC_COLLECTION_MAP[c.fieldKey]
    if (collectionKey) {
      acc.push(collectionKey)
    } else if (variablePayTypeRegex.test(c.fieldKey)) {
      acc.push("variablePayPayTypes")
    }
    return acc
  }, [])

const getStaticCollectionFieldsFromFieldKeys = (fieldKeys: (keyof NodeInterface | "tags")[]) =>
  fieldKeys.reduce<(keyof Collections)[]>((acc, fieldKey) => {
    const collectionKey = FIELD_TO_STATIC_COLLECTION_MAP[fieldKey]
    if (collectionKey) {
      acc.push(collectionKey)
    }
    return acc
  }, [])

const buildCollectionsQueryFromKeys = (
  keys: (keyof Collections)[],
) => `query CurrentCompanyCollections
    {
      currentCompany{
        collections{
          ${keys
            .map(
              (key) => `
            ${key}{
              name
              label
              options {
                nodes { id label ${COLLECTIONS_WITH_SYSTEM_TYPE.includes(key) ? "systemType" : ""} }
              }
            }
          `,
            )
            .join("\n")}
        }
      }
    }`

const buildCollectionsQuery = (args: CollectionQueryConfig) => {
  const collectionQueries = Object.entries(args).map(([collectionName, arg]) => {
    let args = ""
    if (arg.filter) {
      args += `filter: "${parseEscapeChars(arg.filter)}", `
    }
    if (arg.chartId) {
      args += `chartId: "${arg.chartId}", `
    }
    if (arg.includeSubFamilies !== undefined) {
      args += `includeSubFamilies: ${arg.includeSubFamilies}, `
    }
    if (arg.tagDomain) {
      args += `tagDomain: "${arg.tagDomain}", `
    }
    if (arg.taggableEntity) {
      args += `taggableEntity: "${arg.taggableEntity}", `
    }
    if (arg.excludeRecord) {
      args += `excludeRecord: "${arg.excludeRecord}", `
    }
    if (arg.keys) {
      const keys = arg.keys.map((key: string) => `"${key}"`).join(", ")
      args += `keys: [${keys}], `
    }
    if (arg.filters) {
      const filters = arg.filters
        .map(
          (filter: { field: string; value: string }) =>
            `{ field: "${filter.field}", value: "${parseEscapeChars(filter.value)}" }`,
        )
        .join(", ")
      args += `filters: [${filters}], `
    }
    return `${collectionName}(${args})
      { name label options(first: ${AUTOCOMPLETE_LIMIT}){ nodes{ id label } } }`
  })

  return `query CurrentCompanyCollections
        {
          currentCompany{
            collections{
              ${collectionQueries.join("\n")}
            }
          }
        }`
}

/**
 * Replaces each `\` in the input with `\\\\` to correctly handle
 * backslashes in GraphQL query inputs within JavaScript.
 *
 * @param input - The string potentially containing backslashes
 * @returns The input string with each `\` replaced by `\\\\`
 */
const parseEscapeChars = (input: string) => input.replace(/\\/g, "\\\\\\\\")

function buildCollectionArgs(
  dynamicFieldKey: string | undefined,
  collectionName: keyof Collections,
  searchTerm: string,
  extraArgs?: CollectionQueryArgs,
): CollectionQueryConfig {
  const queryArgs: CollectionQueryConfig = { [collectionName]: {} }
  const extraQueryArgs = extraArgs || {}
  if (dynamicFieldKey) {
    queryArgs[collectionName] = {
      keys: [dynamicFieldKey],
      filters: [{ field: dynamicFieldKey, value: searchTerm }],
      ...extraQueryArgs,
    }
  } else {
    queryArgs[collectionName] = { filter: searchTerm, ...extraQueryArgs }
  }

  return queryArgs
}

const buildCollectionSearchQueryFromFieldKey = ({
  fieldKey,
  searchTerm,
  extraArgs,
}: {
  fieldKey: FieldKey
  searchTerm: string
  extraArgs?: object
}) => {
  let collectionName = AUTOCOMPLETE_FIELD_TO_COLLECTION_MAP[fieldKey]
  let dynamicFieldKey

  if (orgUnitTypeRegex.test(fieldKey)) {
    collectionName = "orgUnitTypes"
    dynamicFieldKey = fieldKey
  }

  if (!collectionName) {
    throw new Error(`No collection found for field key: ${fieldKey}`)
  }

  const queryArgs = buildCollectionArgs(dynamicFieldKey, collectionName, searchTerm, extraArgs)
  return buildCollectionsQuery(queryArgs)
}

const addNoneOption = (collection: Collection, label: string): Collection => ({
  ...collection,
  options: {
    ...collection.options,
    nodes: [
      {
        id: `${collection.name}_${NONE_KEY_SUFFIX}`,
        label,
      },
      ...collection.options.nodes,
    ],
  },
})

const addNoneOptionToMatchedCollections = (collections: Collection[]): Collection[] =>
  collections.map((collection) => {
    const fieldKey = collection.name
    if (COLLECTIONS_WITH_NONE_OPTION.includes(fieldKey)) {
      return addNoneOption(collection, "None".t("defaults"))
    }
    if (COLLECTIONS_WITH_BLANK_OPTION.includes(fieldKey)) {
      return addNoneOption(collection, "")
    }
    return collection
  })

const addCollectionsToFields = ({
  fields,
  collections,
  collectionFields,
}: {
  fields: Field[]
  collections: Collection[] | undefined
  collectionFields: (keyof Collections)[]
}) => {
  if (!collections || collectionFields.length === 0) return fields

  const collectionsByName = fp.keyBy("name", addNoneOptionToMatchedCollections(collections))

  return fields.map((field) => {
    let collectionName: string = field.fieldKey
    if (variablePayTypeRegex.test(field.fieldKey)) {
      collectionName = "variable_pay_pay_type"
    }

    return {
      ...field,
      collection: collectionsByName[collectionName],
    }
  })
}

export {
  FIELD_TO_STATIC_COLLECTION_MAP,
  NONE_KEY_SUFFIX,
  addCollectionsToFields,
  addNoneOptionToMatchedCollections,
  buildCollectionSearchQueryFromFieldKey,
  buildCollectionsQuery,
  buildCollectionsQueryFromKeys,
  fieldUsesCollection,
  getStaticCollectionFieldsFromColumns,
  getStaticCollectionFieldsFromFieldKeys,
  isForcedAutocompleteField,
  isSuggestedAutocompleteField,
}
