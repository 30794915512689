import { includes } from "lodash/fp"
import React, { useState } from "react"

import RootProvider from "v2/react/components/RootProvider"
import { ModalForm, ModalFormProps } from "v2/react/components/company/permissions/ModalForm"

const RIGHT_ARROW_KEY_CODE = "39"
const ENTER_KEY_CODE = "13"
const KEYS = [RIGHT_ARROW_KEY_CODE, ENTER_KEY_CODE]

type ModalLinkProps = {
  addonRoles: ModalFormProps["addonRoles"]
  availableScopeTypes: ModalFormProps["availableScopeTypes"]
  className?: string
  label: string
  personId: string
  personName: string
  role: ModalFormProps["role"]
  linkKeys: ModalFormProps["linkKeys"]
  scopeType: ModalFormProps["scopeType"]
}

function WithProvider({
  addonRoles,
  availableScopeTypes,
  className,
  label,
  personId,
  personName,
  linkKeys,
  role,
  scopeType,
}: ModalLinkProps) {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <>
      {personId && (
        <a
          href="/"
          className={className}
          onClick={openModal}
          onKeyUp={(e) => (includes(e.key, KEYS) ? openModal(e) : null)}
        >
          {label}
        </a>
      )}
      {!personId && (
        <button className={className} onClick={openModal} type="button">
          {label}
        </button>
      )}
      <ModalForm
        addonRoles={addonRoles}
        availableScopeTypes={availableScopeTypes}
        closeModal={closeModal}
        isOpen={isOpen}
        personId={personId}
        personName={personName}
        role={role}
        linkKeys={linkKeys}
        scopeType={scopeType}
      />
    </>
  )
}

function ModalLink({
  addonRoles,
  availableScopeTypes,
  className,
  label,
  personId,
  personName,
  linkKeys,
  role,
  scopeType,
}: ModalLinkProps) {
  return (
    <RootProvider>
      <WithProvider
        addonRoles={addonRoles}
        availableScopeTypes={availableScopeTypes}
        className={className}
        label={label}
        personId={personId}
        personName={personName}
        role={role}
        linkKeys={linkKeys}
        scopeType={scopeType}
      />
    </RootProvider>
  )
}

export { ModalLink }
