import React, { useMemo } from "react"
import { Boundary } from "@floating-ui/react"
import { CellAuthorLayer } from "v2/react/shared/Datasheet/Cell/StyleLayers/CellAuthorLayer"
import { CellErrorLayer } from "v2/react/shared/Datasheet/Cell/StyleLayers/CellErrorLayer"
import { LightMode } from "v2/react/utils/colors"
import { updatePositionStyles } from "v2/react/shared/Datasheet/utils/styles"
import { ChangeMeta, FieldType } from "../../types"
import { SaveLayer } from "./SaveLayer"

const { ForegroundColors } = LightMode

export interface StylesProps {
  top: number
  left: number
  height: number
  width: number
  color: string
}

export interface StylesPosition {
  top: number
  left: number
  height: number
  width: number
}

export interface LayerProps {
  fieldType: FieldType
  showStyle: boolean
  styles: StyleProps
}

export type CellStyleType = "enter" | "save" | "default"

export interface StyleProps {
  boundary?: Boundary
  cellStyleLayer: CellStyleType
  currentValue?: string
  changeInfo?: ChangeMeta
  errorMessage?: string
  isLast: boolean
  styles: React.CSSProperties
}

function StyleLayers({
  boundary,
  cellStyleLayer,
  changeInfo,
  currentValue,
  errorMessage,
  isLast,
  styles,
}: StyleProps) {
  const position = useMemo(() => {
    const base = {
      top: Number(styles.top),
      left: Number(styles.left),
      height: Number(styles.height),
      width: Number(styles.width),
    }
    const withUpdates = updatePositionStyles(styles.top === 0, isLast, base)

    return { ...base, ...withUpdates }
  }, [styles.top, styles.left, styles.height, styles.width, isLast])

  return (
    position && (
      <>
        <SaveLayer
          showStyle={cellStyleLayer === "save"}
          styles={{ ...position, color: ForegroundColors.Primary }}
        />
        <CellAuthorLayer
          boundary={boundary}
          changeInfo={changeInfo}
          currentValue={currentValue}
          hidden={cellStyleLayer === "enter"}
          styles={position}
        />
        <CellErrorLayer
          boundary={boundary}
          errorMessage={errorMessage}
          styles={{ ...position, color: ForegroundColors.Error }}
        />
      </>
    )
  )
}

export { StyleLayers }
