import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { statusColorLookup, statusTextLookup } from "v2/react/shared/Status/statusUtils"
import RootProvider from "v2/react/components/RootProvider"
import { openSuccessionPlanSidebar } from "v2/redux/slices/PanelSidebarSlice"
import { useAppDispatch } from "v2/redux/store"

import { PanelOpenerWithTooltipPageAction } from "./PanelOpenerWithTooltip"

interface Props {
  positionId: string
  status: string
}

const WithProvider = ({ positionId, status }: Props) => {
  const dispatch = useAppDispatch()

  const showPlanPanel = () => {
    dispatch(openSuccessionPlanSidebar({ positionId }))
  }

  return (
    <PanelOpenerWithTooltipPageAction
      onClick={showPlanPanel}
      contentClasses="react-tooltip-content--sm mt-1"
    >
      <div className={`badge--${statusColorLookup(status)}`}>
        <span>{statusTextLookup(status)}</span>
        <div className=" ml-1 text-sm text-neutral-50">
          <FontAwesomeIcon icon={["far", "chevron-right"]} />
        </div>
      </div>
    </PanelOpenerWithTooltipPageAction>
  )
}

const BadgeButton = ({ positionId, status }: Props) => (
  <RootProvider>
    <WithProvider positionId={positionId} status={status} />
  </RootProvider>
)

export { BadgeButton }
