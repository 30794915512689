import classNames from "classnames"
import React from "react"
import { Option } from "types/graphql.d"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useOnClickOutside } from "usehooks-ts"
import { useTranslation } from "react-i18next"
import { DraggableList } from "./DraggableList/DraggableList"

export type MenuOption<T> = Omit<Option, "id"> & { id: T; selected: boolean }

type ReorderableCheckboxMenuProps<T> = {
  id: string
  className?: string
  listClassName?: string
  options: MenuOption<T>[]
  setOptions: (options: MenuOption<T>[]) => void
}
export function ReorderableCheckboxMenu<T extends string = string>({
  id,
  className,
  listClassName,
  options,
  setOptions,
}: ReorderableCheckboxMenuProps<T>) {
  const [showList, setShowList] = React.useState(false)
  const dragContainerRef = React.useRef<HTMLDivElement>(null)
  const menuRef = React.useRef<HTMLDivElement>(null)
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  useOnClickOutside(menuRef, () => {
    setShowList(false)
    buttonRef.current?.blur()
  })
  const { t } = useTranslation()

  const getButtonText = () => {
    const selectedOptions = options.filter((option) => option.selected)
    if (selectedOptions.length === 0) {
      return t("v2.defaults.select_an_option")
    }
    if (selectedOptions.length === 1) {
      return selectedOptions[0].label
    }
    return `${selectedOptions[0].label} +${selectedOptions.length - 1}`
  }

  const handleButtonKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === "Tab" && showList) {
      setShowList(false)
    }
  }

  return (
    <div ref={menuRef}>
      <div className={classNames("select--responsive", className)}>
        <button
          id={id}
          type="button"
          onClick={() => setShowList((s) => !s)}
          ref={buttonRef}
          onKeyDown={handleButtonKeyDown}
          className="whitespace-nowrap"
        >
          {getButtonText()}
        </button>
      </div>
      <div
        className={classNames("basic-select-menu absolute mt-1 w-[216px]", listClassName, {
          invisible: !showList,
        })}
      >
        <DraggableList<(typeof options)[0]>
          items={options}
          handleReorder={setOptions}
          dragContainerRef={dragContainerRef}
          draggableListClasses="shadow-none p-0"
        >
          {options.map((option) => (
            <DraggableList.Item<typeof option>
              key={option.id}
              item={option}
              dragContainerRef={dragContainerRef}
              itemClasses="p-[10px] pl-1"
            >
              <div className="grid-cols-[0.5rem_1rem_1fr] items-center gap-[0.4rem] grid">
                <FontAwesomeIcon
                  icon={["fas", "grip-vertical"]}
                  className="h-[8.94px] w-[5.5px] text-neutral-64"
                />
                <input
                  type="checkbox"
                  id={option.id}
                  checked={option.selected}
                  className="h-4 w-4"
                  onChange={(event) =>
                    setOptions(
                      options.map((opt) =>
                        opt.id === option.id ? { ...opt, selected: event.target.checked } : opt,
                      ),
                    )
                  }
                />
                <label htmlFor={option.id} className="side-label">
                  {option.label}
                </label>
              </div>
            </DraggableList.Item>
          ))}
        </DraggableList>
      </div>
    </div>
  )
}
