import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion } from "framer-motion"
import React, { ReactNode, useState } from "react"
import { useTranslation } from "react-i18next"
import { ApprovedBanner } from "v2/react/components/positionTypes/Show/ApprovedBanner"
import { opaqueScale } from "v2/react/utils/framerAnimationVariants"

interface ActionBannerProps {
  awaitingAction: boolean
  fields: string[]
  hideActionBanner?: () => void
  onAcceptFieldSuggestions?: (fields: string[]) => Promise<unknown>
  onDeclineFieldSuggestions?: (fields: string[]) => Promise<unknown>
  onEdit: () => void
}

function ActionBanner({
  awaitingAction,
  fields,
  hideActionBanner,
  onAcceptFieldSuggestions,
  onDeclineFieldSuggestions,
  onEdit,
}: ActionBannerProps) {
  const [showApprovedBanner, setShowApprovedBanner] = useState(false)
  const { t } = useTranslation()

  const handleAccept = async () => {
    await onAcceptFieldSuggestions?.(fields)
    hideActionBanner?.()
    setShowApprovedBanner(true)
  }

  const handleRemove = async () => {
    await onDeclineFieldSuggestions?.(fields)
    hideActionBanner?.()
  }

  if (showApprovedBanner) {
    return <ApprovedBanner />
  }

  return (
    awaitingAction && (
      <motion.div
        initial={false}
        animate="shown"
        variants={opaqueScale}
        className="banner alert--purple justify-between rounded-none border border-solid border-neutral-8 p-2 pl-3 shadow-none 1120:items-center 1120:p-3 1120:pl-4 1120:flex"
      >
        <div className="gap-1.5 pb-2 flex 1120:pb-0">
          <FontAwesomeIcon className="mt-1" icon={["far", "sparkles"]} />
          <div>
            <p className="font-bold">{t("v2.position_types.show.approval_banner_title")}</p>
            <p>{t("v2.position_types.show.approval_banner_body")}</p>
          </div>
        </div>
        <div className="gap-2 flex">
          <ActionButton onClick={handleAccept}>
            <FontAwesomeIcon className="icon--success" icon={["fad", "check-circle"]} />
            <span>{t("v2.defaults.accept")}</span>
          </ActionButton>
          <ActionButton onClick={onEdit}>
            <FontAwesomeIcon icon={["far", "pencil"]} />
            <span>{t("v2.defaults.edit")}</span>
          </ActionButton>
          <ActionButton onClick={handleRemove}>
            <FontAwesomeIcon className="icon--critical" icon={["fad", "times-circle"]} />
            <span>{t("v2.defaults.remove")}</span>
          </ActionButton>
        </div>
      </motion.div>
    )
  )
}

export { ActionBanner }

interface ApprovalButtonProps {
  onClick: () => void
  children: ReactNode
}

const ActionButton = ({ onClick, children }: ApprovalButtonProps) => (
  <button className="btn--sm sm:btn 1225:btn--large btn--secondary" onClick={onClick} type="button">
    {children}
  </button>
)
