import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { useRef, useState } from "react"
import { JobRequisition } from "types/graphql"
import { useOnClickOutside } from "usehooks-ts"
import { ConnectedPositions } from "v2/react/components/jobRequisitions/Overview/ConnectedPositions"
import { CreatePosition } from "v2/react/components/jobRequisitions/Overview/CreatePosition/CreatePosition"
import { FormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/FormShape"
import { JobRequisitionAbilities } from "v2/react/components/jobRequisitions/types"
import { StatusBadge } from "v2/react/shared/Status/StatusBadge"
import { setReqModalOpen } from "v2/redux/slices/RequisitionSlice"
import { useAppDispatch } from "v2/redux/store"

interface Props {
  abilities: JobRequisitionAbilities
  data: FormShape
  handleCancelButtonClick: () => void
  handleEditButtonClick: () => void
  handleCloseButtonClick: () => void
  jobRequisition: JobRequisition
  status: string
}

const HeaderButtons = ({
  abilities,
  data,
  handleCancelButtonClick,
  handleEditButtonClick,
  handleCloseButtonClick,
  jobRequisition,
  status,
}: Props) => {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsOpen(false))
  const positions = jobRequisition.positions || []

  const approvalStates = ["approved", "closed"]
  const approvedWithConnections =
    approvalStates.includes(status) && positions && positions.length > 0
  const approvedWithNoConnections =
    approvalStates.includes(status) &&
    abilities.canCreatePosition &&
    (!positions || positions.length === 0)
  const pendingWithConnections =
    status !== "approved" &&
    status !== "canceled" &&
    status !== "closed" &&
    data.backfillPosition.id &&
    positions &&
    positions.length > 0
  const openStatuses = ["canceled", "closed"]
  const closeStatuses = ["approved", "denied"]
  const showConnectedPositions = pendingWithConnections || approvedWithConnections
  const canModifyReq = (abilities.canCancel || abilities.canEdit) && !openStatuses.includes(status)
  const canEdit = abilities.canEdit && status !== "canceled"
  const canCancel = abilities.canCancel && status !== "canceled"
  const canClose =
    abilities.canClose &&
    !openStatuses.includes(status) &&
    closeStatuses.includes(jobRequisition.approvalState || "")

  const cancelButtonClick = () => {
    handleCancelButtonClick()
    setIsOpen(false)
  }

  const editButtonClick = () => {
    handleEditButtonClick()
    setIsOpen(false)
  }

  const closeButtonClick = () => {
    handleCloseButtonClick()
    setIsOpen(false)
  }

  const reopenPosition = () => {
    if (jobRequisition.approvalState === "canceled") {
      dispatch(setReqModalOpen(true))
    } else {
      handleCloseButtonClick()
    }
  }

  const reopenPositionButton = openStatuses.includes(jobRequisition.approvalState ?? "") &&
    abilities.canEdit && (
      <button type="button" className="btn--large btn--secondary" onClick={() => reopenPosition()}>
        <FontAwesomeIcon icon={["far", "redo-alt"]} flip="horizontal" />
        {"reopen".t("job_requisition")}
      </button>
    )

  const createPositionButton = approvedWithNoConnections && (
    <CreatePosition
      data={data}
      jobRequisitionId={jobRequisition.id}
      jobRequisitionStatus={jobRequisition.approvalState || ""}
      jsonFormSchemaType={jobRequisition.jsonForm?.options?.schema_type}
    />
  )

  const connectedPositionsDisplay = showConnectedPositions && (
    <ConnectedPositions
      abilities={abilities}
      jobRequisitionId={jobRequisition.id}
      positions={positions}
      status={status}
    />
  )

  return (
    <div className="items-center gap-2 flex">
      <div className="hidden sm:block">
        {createPositionButton}
        {connectedPositionsDisplay}
      </div>
      <div>{reopenPositionButton}</div>

      <div className={classNames("dropdown", { "sm:hidden": !canModifyReq })} ref={ref}>
        <button
          id="requisition-overview-actions-button"
          className="btn--large btn--secondary"
          onClick={() => setIsOpen(!isOpen)}
          type="button"
        >
          <FontAwesomeIcon icon={["far", "ellipsis-v"]} size="lg" />
        </button>

        <div className={classNames("dropdown-menu", { "!block": isOpen })}>
          <div className="items-center justify-between p-2 flex sm:hidden">
            <StatusBadge status={status} displayType="button" checkmarkOnApproved />
            <div>
              {createPositionButton}
              {reopenPositionButton}
            </div>
          </div>
          {showConnectedPositions && (
            <>
              <hr />
              <div className="items-center justify-between p-2 flex sm:hidden">
                {connectedPositionsDisplay}
              </div>
            </>
          )}
          {canModifyReq && <hr />}
          <div className={classNames({ "p-2 sm:p-0": canModifyReq })}>
            {canEdit && (
              <button
                className="dropdown-menu-link items-center gap-2 flex"
                onClick={editButtonClick}
                type="button"
              >
                <FontAwesomeIcon icon={["far", "edit"]} />
                {"edit_requisition".t("job_requisition")}
              </button>
            )}
            {canCancel && (
              <button
                className="dropdown-menu-link items-center gap-2 flex"
                onClick={cancelButtonClick}
                type="button"
              >
                <FontAwesomeIcon icon={["far", "ban"]} />
                {"cancel_requisition".t("job_requisition")}
              </button>
            )}
            {canClose && (
              <button
                className="dropdown-menu-link items-center gap-2 flex"
                onClick={closeButtonClick}
                type="button"
              >
                <FontAwesomeIcon icon={["far", "archive"]} />
                {"close_requisition".t("job_requisition")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export { HeaderButtons }
