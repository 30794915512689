import React from "react"
import classNames from "classnames"

interface InputCollectionProps {
  children: React.ReactNode
  className?: string
}

const InputCollection = ({ children, className }: InputCollectionProps) => (
  <div className={classNames("input-collection", className)}>{children}</div>
)

export { InputCollection }
