import fp from "lodash/fp"
import { createAction, TaskAbortError } from "@reduxjs/toolkit"

import type { AnyAction, EntityId, ListenerEffectAPI } from "@reduxjs/toolkit"
import type { AppDispatch, RootState } from "v2/redux/store"
import type { GroupRow } from "v2/redux/slices/GridSlice/types"
import type { NodeInterface } from "types/graphql"

import {
  augmentGroupRow,
  deriveGroupId,
  groupIdFromGroupValues,
  pickTieredGroupValues,
  toSkeletonRow,
} from "v2/redux/slices/GridSlice/gridHelpers"
import { groupSelectors, setSkeletonState } from "v2/redux/slices/GridSlice"
import { makeComparator } from "v2/redux/slices/GridSlice/gridHelpers/makeComparator"
import { makePredicate } from "v2/redux/slices/GridSlice/gridHelpers/makePredicate"
import { nodeSelectors } from "v2/redux/slices/NodeSlice/NodeApi"
import { nodeProp } from "v2/redux/slices/NodeSlice/nodeHelpers/nodeProps"
import {
  selectChartSectionColorsByName,
  selectOrderingDetails,
} from "v2/redux/slices/ContainerSlice/containerSelectors"
import {
  selectFilters,
  selectGroupFieldKeys,
  selectPositionStatusFilter,
} from "v2/redux/slices/GridSlice/gridSelectors"

export const arrangeDatasheet = createAction<void>("datasheetListeners/arrangeDatasheet")

type HandlerArg = {
  action: ReturnType<typeof arrangeDatasheet>
  api: ListenerEffectAPI<RootState, AppDispatch>
}

const controlYieldWith = async (delay: HandlerArg["api"]["delay"]) => delay(0)
const mapToId = fp.map<NodeInterface, EntityId>(fp.prop("id"))
const mapToPredicate = fp.map(makePredicate)
const entitiesCompact = <T>(ids: EntityId[], lookup: { [key in EntityId]: T }): T[] =>
  fp.pipe(fp.props(ids), fp.compact)(lookup)

/**
 * Sorts, filters, and groups nodes per the latest configuration.
 *
 * @public
 */
export async function arrangeDatasheetEffect({ api }: HandlerArg): Promise<void> {
  const { cancelActiveListeners, delay, dispatch, fork, take, subscribe, unsubscribe } = api

  try {
    // Cancel earlier invocations of this function if they haven't began
    // writing to the store. We're ok with discarding their initial effort in
    // order to be more responsive to the user's latest action.
    cancelActiveListeners()

    // Sort and filter while periodically yielding control. Sorting tends to
    // be costly (w/r/t time) as node count grows. This avoids blocking user
    // interactions and other code.
    const orderingAndFilteringTasks = Promise.all([
      forkTaskToOrderNodes(api).result,
      forkTaskToFilterNodes(api).result,
    ])

    // Wait for both tasks to finish, and bail if either fails.
    const [orderNodesResult, filterNodesResult] = await orderingAndFilteringTasks
    if (orderNodesResult.status !== "ok" || filterNodesResult.status !== "ok")
      throw new Error("Unexpected results from sorting/filtering")

    // Pull out and combine the values from sorting/filtering.
    const sortedNodeIds = orderNodesResult.value
    const filteredNodesLookup = filterNodesResult.value
    const filteredAndSortedNodes = entitiesCompact(sortedNodeIds, filteredNodesLookup)

    // Apply configured grouping in order to get the final result of group and
    // node rows.
    await controlYieldWith(delay)
    const groupTask = forkTaskToGroupNodes(filteredAndSortedNodes, api)
    const groupResult = await groupTask.result
    if (groupResult.status !== "ok") throw groupResult.error

    // Once here, we're ready to write to the store. Prevent any new instances
    // of this function from kicking off to avoid cancelation. Track incoming
    // arrange actions during this time, and if 1+ actions come in, kick off
    // the latest when we finish.
    unsubscribe()

    let latestPendingArrangeAction: AnyAction | null = null
    const debounceIncomingArrangeActions = fork(async (forkApi) => {
      // This is meant to be run as a never ending loop while active, to match
      // on any arrangeDatasheet actions dispatched while we write to the
      // store. Disable ESLint rules here that complain due to this.
      /* eslint-disable no-await-in-loop, no-constant-condition */
      while (true) {
        const takeNextAction = take(arrangeDatasheet.match)
        const nextAction = await forkApi.pause(takeNextAction)
        if (Array.isArray(nextAction)) {
          const [matchedAction, ,] = nextAction
          latestPendingArrangeAction = matchedAction
        }
      }
      /* eslint-enable no-await-in-loop, no-constant-condition */
    })

    // Pull out the values of our long running tasks and write them into the
    // store.
    const groupFieldKeys = selectGroupFieldKeys(api.getState())
    const arrangedNodes = fp.map((nodeOrGroup) => {
      if ("rowType" in nodeOrGroup) return nodeOrGroup
      const nodeGroupId = deriveGroupId(groupFieldKeys, nodeOrGroup)
      return toSkeletonRow(nodeOrGroup, nodeGroupId)
    }, groupResult.value)

    await controlYieldWith(delay)
    dispatch(setSkeletonState(arrangedNodes))

    // Allow new instances to start up again and dispatch the latest, blocked,
    // arrange action (if any).
    subscribe()
    debounceIncomingArrangeActions.cancel()
    if (latestPendingArrangeAction) dispatch(latestPendingArrangeAction)
  } catch (error) {
    // No worries if the task was aborted.
    if (error instanceof TaskAbortError) return

    const { Sentry } = window
    if (typeof Sentry !== "undefined") Sentry.captureException(error)
    else if (typeof console !== "undefined") console.error(error) // eslint-disable-line no-console

    // Re-subscribe to ensure we receive future actions (no-op if subscribed).
    subscribe()
  }
}

const forkTaskToOrderNodes = ({ delay, fork, getState }: HandlerArg["api"]) =>
  fork(async () => {
    const orderClause = selectOrderingDetails(getState())
    const comparator = makeComparator({
      fieldKey: orderClause.sortColumn.fieldKey,
      direction: orderClause.sortDirection,
      toActual: (node: NodeInterface) => nodeProp(orderClause.sortColumn.fieldKey, node),
    })

    // Vanilla JS sort mutates, so create a copy of the array.
    const sortedNodes = [...nodeSelectors.selectAll(getState())].sort(comparator)
    await controlYieldWith(delay)
    return mapToId(sortedNodes)
  })

const forkTaskToFilterNodes = ({ delay, fork, getState }: HandlerArg["api"]) =>
  fork(async () => {
    const fromFilters = selectFilters(getState())
    const whereAllPredicatesPass = fp.allPass(mapToPredicate(fromFilters))
    const wherePositionStatusMatches = selectPositionStatusFilter(getState())

    await controlYieldWith(delay)
    return fp.pipe(
      fp.filter(wherePositionStatusMatches),
      fp.filter(whereAllPredicatesPass),
      fp.indexBy<NodeInterface>("id"),
    )(nodeSelectors.selectAll(getState()))
  })

const forkTaskToGroupNodes = (
  nodes: NodeInterface[],
  { delay, fork, getState }: HandlerArg["api"],
) =>
  fork(async () => {
    if (groupSelectors.selectTotal(getState()) === 0) return nodes

    const nodesByGroupId: { [key: EntityId]: NodeInterface[] } = {}
    const childrenCounts: { [key: EntityId]: number } = {}
    const groupFieldKeys = selectGroupFieldKeys(getState())

    fp.each((node) => {
      const nodeGroupId = deriveGroupId(groupFieldKeys, node)
      const tieredGroupValues = pickTieredGroupValues(groupFieldKeys, node)

      if (!(nodeGroupId in nodesByGroupId)) nodesByGroupId[nodeGroupId] = []
      nodesByGroupId[nodeGroupId].push(node)

      fp.each((groupValues) => {
        const key = groupIdFromGroupValues(groupValues)
        if (!(key in childrenCounts)) childrenCounts[key] = 0
        childrenCounts[key] += 1
      }, tieredGroupValues)
    }, nodes)

    await controlYieldWith(delay)
    const allGroupRows = groupSelectors.selectAll(getState())
    const activeGroupRows = fp.filter((group) => fp.isEmpty(childrenCounts[group.id]), allGroupRows)
    const chartSectionsColor = selectChartSectionColorsByName(getState())

    await controlYieldWith(delay)
    return fp.flatMap((groupRow): [GroupRow, ...NodeInterface[]] | [] => {
      const augmentedGroupRow = augmentGroupRow(childrenCounts, chartSectionsColor, groupRow)
      if (augmentedGroupRow.isHidden) return []
      return [augmentedGroupRow, ...(nodesByGroupId[groupRow.id] || [])]
    }, activeGroupRows)
  })
