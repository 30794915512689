import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Cell, Row as TanRow } from "@tanstack/react-table"
import { useVirtualizer } from "@tanstack/react-virtual"
import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { useWindowSize } from "usehooks-ts"
import { ROW_HEIGHT_PX } from "v2/react/shared/Datasheet/utils/constants"
import { TableDropZone } from "v2/react/shared/TableDropZone"
import { TableHeader } from "v2/react/shared/TableHeader"
import { ActiveCursor } from "./TableDatasheet/ActiveCursor"
import { CursorBeacon } from "./TableDatasheet/CursorBeacon"
import { DatasheetContextProvider } from "./TableDatasheet/context"
import { useCsvDownloadListener } from "./TableDatasheet/hooks/useCsvDownloadListener"
import { useDatasheetCellCursor } from "./TableDatasheet/hooks/useDatasheetCellCursor"
import { useTableDatasheet } from "./TableDatasheet/hooks/useTableDatasheet"
import { Column } from "./TableDatasheet/types"

const rowHeight = ROW_HEIGHT_PX

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function showGrayBar(row: TanRow<any>, rowIndex: number) {
  return row.depth === 0 && rowIndex > 0
}

type DatasheetProps<TRow> = {
  columns: Column<TRow>[]
  rows: TRow[]
  renderCell: (row: TRow, column: Column<TRow>) => React.ReactNode
  csvDownloadRef?: React.RefObject<HTMLButtonElement>
  csvDownloadName?: string
}
export function TableDatasheet<TRow extends { id: string }>({
  rows,
  columns,
  renderCell,
  csvDownloadRef,
  csvDownloadName,
}: DatasheetProps<TRow>) {
  const table = useTableDatasheet(rows, columns)
  useCsvDownloadListener(csvDownloadRef, table, csvDownloadName)
  const beaconRef = React.useRef<HTMLButtonElement>(null)
  const cursorRef = React.useRef<HTMLDivElement>(null)
  const tableContainerRef = React.useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)
  const datasheetRef = React.useRef<HTMLDivElement>(null)
  const { height: windowHeight } = useWindowSize()

  useEffect(() => {
    if (!datasheetRef.current) return
    const newHeight = windowHeight - datasheetRef.current.getBoundingClientRect().top - 55
    setHeight(newHeight)
  }, [datasheetRef, windowHeight])

  const { rows: tableRows } = table.getRowModel()
  const rowVirtualizer = useVirtualizer({
    count: tableRows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: (index) => {
      const row = tableRows[index]
      if (row.getIsGrouped()) {
        return showGrayBar(row, index) ? 52 : 36
      }
      return rowHeight
    },
    overscan: 4,
  })
  useDatasheetCellCursor({
    table,
    beaconRef,
    cursorRef,
    tableContainerRef,
    virtualizer: rowVirtualizer,
  })

  const items = rowVirtualizer.getVirtualItems()

  return (
    <div
      ref={datasheetRef}
      style={{ height }}
      className="grid-rows-[auto_1fr] overflow-hidden grid"
    >
      <DatasheetContextProvider table={table}>
        <TableDropZone table={table} />

        <div className="table relative h-full w-full overflow-x-auto">
          <div
            id="table-container"
            ref={tableContainerRef}
            className={classNames(rowVirtualizer.getTotalSize() < height ? "h-fit" : "")}
          >
            <table className="table-datasheet bg-white">
              <TableHeader table={table} />
              <tbody style={{ transformStyle: "preserve-3d" }}>
                {items.map((virtualRow, rowIndex) => {
                  const row = tableRows[virtualRow.index]
                  const visibleCells = row.getVisibleCells()
                  const nextRow = table.getRowModel().rows[virtualRow.index + 1]
                  const prevRow = table.getRowModel().rows[virtualRow.index - 1]
                  return (
                    <tr
                      key={row.id}
                      className="border-b-0"
                      data-virtual-row-index={virtualRow.index}
                      data-virtual-row-start={virtualRow.start}
                      style={{
                        height: `${virtualRow.size}px`,
                        transform: `translateY(${items[0]?.start ?? 0}px) translateZ(${
                          100 - 1 * rowIndex
                        }px)`,
                      }}
                    >
                      {row.getIsGrouped() ? (
                        <GroupCell
                          row={row}
                          showGrayBar={showGrayBar(row, rowIndex)}
                          mergeWithNextRow={nextRow?.getIsGrouped() && nextRow.depth > row.depth}
                          mergeWithPrevRow={prevRow?.getIsGrouped() && prevRow.depth < row.depth}
                        />
                      ) : (
                        visibleCells.map((cell: Cell<TRow, unknown>) => (
                          <td
                            key={cell.id}
                            className="p-0"
                            style={{
                              height: `${virtualRow.size}px`,
                            }}
                          >
                            {renderCell(
                              cell.row.original as TRow,
                              cell.column.columnDef.meta?.original as Column<TRow>,
                            )}
                          </td>
                        ))
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <CursorBeacon beaconRef={beaconRef} />
            <ActiveCursor cursorRef={cursorRef} />
          </div>
        </div>
      </DatasheetContextProvider>
    </div>
  )
}

type GroupCellProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  row: TanRow<any>
  showGrayBar: boolean
  mergeWithNextRow: boolean
  mergeWithPrevRow: boolean
}
function GroupCell({ row, showGrayBar, mergeWithNextRow, mergeWithPrevRow }: GroupCellProps) {
  const cell = row.getVisibleCells().find((cell) => cell.getIsGrouped())

  if (!cell) {
    return null
  }

  const getButtonHeight = () => {
    if (showGrayBar) {
      return "calc(52px - 1rem)"
    }
    if (mergeWithNextRow) {
      return "36px"
    }
    return "35px"
  }

  return (
    <td
      colSpan={row.getVisibleCells().length}
      className={classNames("datasheet-grouped-cell", {
        "h-[52px]": showGrayBar,
        "h-[36px]": !showGrayBar,
      })}
    >
      {showGrayBar ? (
        <div
          className="h-4 border-b border-b-neutral-8-solid bg-neutral-3"
          style={{ borderBottomStyle: "solid" }}
        />
      ) : null}
      <button
        type="button"
        onClick={row.getToggleExpandedHandler()}
        className={classNames(
          "grid-cols-[min-content_min-content] items-center gap-2 bg-transparent grid",
          {
            "border-b border-solid border-neutral-8": !mergeWithNextRow,
          },
        )}
        style={{
          paddingLeft: `${row.depth * 1 + 1}rem`, // eslint-disable-line no-mixed-operators
          paddingTop: mergeWithPrevRow ? 0 : undefined,
          paddingBottom: mergeWithNextRow ? 0 : undefined,
          height: getButtonHeight(),
        }}
      >
        <div className="flex-col justify-center flex">
          <FontAwesomeIcon icon={["fas", row.getIsExpanded() ? "caret-down" : "caret-right"]} />
        </div>
        <span className="font-bold">
          {`${cell.column.columnDef.header}: ${cell.getValue() as string} (${
            row.getLeafRows().filter((row) => !row.getIsGrouped()).length
          })`}
        </span>
      </button>
    </td>
  )
}
