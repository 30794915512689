import React from "react"
import { ListRowProps } from "react-virtualized"

import type { ReferenceColorCode } from "v2/redux/slices/VisualizationSlice/types"
import type { EditColorClickHandler } from "v2/react/components/orgChart/SuperPanel/ColorCodingTab/hooks/useColorCodingActions"

import {
  ColorCode,
  ColorCodeProps,
} from "v2/react/components/orgChart/SuperPanel/ColorCodingTab/ColorCode"
import { useAppSelector } from "v2/redux/store"

/**
 * Prepares a callback for react-virtualized's list view; renders a single color
 * square that a user may click to change a color code's actual color.
 */
function useColorCodeRenderer(
  colorCodes: ReferenceColorCode[],
  handleEditColorClick: EditColorClickHandler,
) {
  const colorPickerColorCode = useAppSelector((state) => state.visualization.colorPickerColorCode)

  return ({ key, index, style }: ListRowProps) => {
    const themeOption = colorCodes[index]
    const handleInitiatingEdit: ColorCodeProps["editColor"] = (domPosition, color) =>
      handleEditColorClick(themeOption, domPosition, color)

    return (
      <ColorCode
        key={key}
        color={themeOption.color}
        name={themeOption.name}
        isActive={themeOption.id === colorPickerColorCode?.id}
        editColor={handleInitiatingEdit}
        style={style}
      />
    )
  }
}

export { useColorCodeRenderer }
