import { useState } from "react"
import fp from "lodash/fp"

import type { Maybe, OrgUnit } from "types/graphql"

import { useAddOrgUnitMutation } from "v2/redux/GraphqlApi"

type OnSaveHandler = (orgUnit: Pick<OrgUnit, "id" | "name" | "code" | "org_unit_type_id">) => void

function useAddOrgUnitMutationWithHandling(onSave?: OnSaveHandler) {
  const [generalError, setGeneralError] = useState<Maybe<string>>(null)
  const [mutate, state] = useAddOrgUnitMutation()
  const handleSave = async (input: Parameters<typeof mutate>[0]) => {
    setGeneralError(null)
    let res: Maybe<Awaited<ReturnType<typeof mutate>>> = null
    let error: undefined | string | null | Error
    try {
      res = await mutate(input)
      if ("error" in res && res.error) error = res.error as string
    } catch (caughtError) {
      error = caughtError as Error
    }

    // Fail when there is no response or a root level error ocurred.
    if (!res || error) {
      error ??= new Error("No response when trying to add an org unit")
      window?.Sentry?.captureException(error)
      setGeneralError("error".t("defaults"))
      return
    }

    // `errorHash` will capture any errors in `res.data.addOrgUnit.errors`
    if (!("data" in res) || !res.data.addOrgUnit?.orgUnit) setGeneralError("error".t("defaults"))
    else if (fp.isEmpty(res.data.addOrgUnit.errors)) onSave?.(res.data.addOrgUnit.orgUnit)
  }

  return { handleSave, state, generalError }
}

export { useAddOrgUnitMutationWithHandling }
