import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { useState } from "react"
import { preventPropagationOfSyntheticEvent, useOutsideClick } from "v2/react/hooks/useOutsideClick"

export interface SelectOption {
  value: string
  icon: React.ReactNode
  headerDisplay: React.ReactNode
  fieldDisplay: React.ReactNode
  text: string
}

interface Props<ItemOption extends SelectOption> {
  options: ItemOption[]
  initialOption: ItemOption
  onSelection: (selected: ItemOption) => void
}

function CurrencyDropdown<ItemOption extends SelectOption>({
  options,
  initialOption,
  onSelection,
}: Props<ItemOption>) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<ItemOption>(initialOption)
  const toggleDropdown = () => setIsOpen(!isOpen)
  const ref = useOutsideClick<HTMLDivElement>(() => setIsOpen(false))

  const onOptionClicked = (value: ItemOption) => {
    setSelectedOption(value)
    onSelection(value)
    setIsOpen(false)
  }

  return (
    <div
      className="select-dropdown bg-white"
      onClick={preventPropagationOfSyntheticEvent}
      onKeyDown={preventPropagationOfSyntheticEvent}
      role="presentation"
      ref={ref}
    >
      <button
        onClick={toggleDropdown}
        type="button"
        className="select-dropdown__header items-center justify-between p-0 flex"
      >
        {selectedOption.headerDisplay}
        <FontAwesomeIcon icon={["far", "chevron-down"]} />
      </button>
      {isOpen && (
        <ul className="select-dropdown__list h-[30rem] overflow-scroll">
          {options.map((option) => (
            <li
              role="menuitem"
              onClick={() => onOptionClicked(option)}
              onKeyDown={() => onOptionClicked(option)}
              className={classNames(`select-dropdown__option ${option.value}`, {
                active: option.text === selectedOption.text,
              })}
              key={option.text}
            >
              <span className="items-center flex">
                {option.icon}
                {option.fieldDisplay}
              </span>
              <FontAwesomeIcon
                icon={["fas", "check-circle"]}
                className="selected-indicator ml-auto"
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export { CurrencyDropdown }
