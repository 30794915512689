import fp from "lodash/fp"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface AbilityState {
  canExportChart: boolean
  canEditChartNodes: boolean
  canManageChart: boolean
  canManageOfficialChart: boolean
  isLimitedAdminUsingOfficialChart: boolean
}

const InitialState: AbilityState = {
  canExportChart: false,
  canEditChartNodes: false,
  canManageChart: false,
  canManageOfficialChart: false,
  isLimitedAdminUsingOfficialChart: false,
}

const AbilitySlice = createSlice({
  name: "ability",
  initialState: InitialState,
  reducers: {
    setAbilities: (state, { payload }: PayloadAction<AbilityState>) => fp.merge(state, payload),
  },
})

export const { setAbilities } = AbilitySlice.actions
export { AbilitySlice, AbilityState }
