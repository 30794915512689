import React from "react"
import { AnimatePresence, motion } from "framer-motion"
import { FloatingPortal } from "@floating-ui/react"

import {
  TooltipContext,
  TooltipOptions,
  useTooltip,
  useTooltipContext,
} from "v2/react/shared/Tooltip/useTooltip"

interface TooltipProps extends TooltipOptions {
  children: React.ReactNode
}

interface TooltipContentProps extends React.HTMLProps<HTMLDivElement> {
  type?: "content" | "content-animated-wrapped"
}

interface TooltipTriggerProps extends React.HTMLProps<HTMLElement> {
  asChild?: boolean
  type?: "button" | "span"
}

const TooltipAnimationValues = Object.freeze({
  Initial: Object.freeze({ opacity: 0, scale: 0.8 }),
  Target: Object.freeze({ opacity: 1, scale: 1 }),
})

/**
 * Renders an element with an associated "tooltip" or "popover".
 *
 * The tooltip comprises three components, all of which are exported from here.
 *
 * @example The Components
 *
 *     <Tooltip>
 *       <TooltipTrigger>Hover over this</TooltipTrigger>
 *       <TooltipContent>To reveal this content.</TooltipContent>
 *     </Tooltip>
 *
 * @public
 */
function Tooltip({ children, ...options }: { children: React.ReactNode } & TooltipOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options)
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>
}

/* eslint-disable react/jsx-props-no-spreading */

// eslint-disable-next-line react/prop-types
function TooltipContent({ style, children, type = "content", ...props }: TooltipContentProps) {
  const tooltipContext = useTooltipContext()
  const ref = tooltipContext.refs.setFloating

  if (!tooltipContext.open) return null

  const floatingProps = tooltipContext.getFloatingProps(props)
  const wrapperStyle = { ...tooltipContext.floatingStyles, ...style }

  if (type === "content") {
    return (
      <FloatingPortal>
        <div ref={ref} style={wrapperStyle} {...floatingProps}>
          {children}
        </div>
      </FloatingPortal>
    )
  }

  if (!tooltipContext.open) return null

  return (
    <FloatingPortal>
      <div ref={ref} style={wrapperStyle} {...floatingProps}>
        <AnimatePresence>
          <motion.div
            key="tooltip-container"
            animate={TooltipAnimationValues.Target}
            className="react-tooltip__container"
            initial={TooltipAnimationValues.Initial}
            exit={TooltipAnimationValues.Initial}
          >
            <div>{children}</div>
          </motion.div>
        </AnimatePresence>
      </div>
    </FloatingPortal>
  )
}

function TooltipTrigger({ children, asChild = false, type, ...props }: TooltipTriggerProps) {
  const context = useTooltipContext()
  const ref = context.refs.setReference
  // The user can style the trigger based on the state
  const dataState = context.open ? "open" : "closed"

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        "data-state": context.open ? "open" : "closed",
      }),
    )
  }

  const referenceProps = context.getReferenceProps(props)
  if (typeof type === "undefined" || type === "span") {
    return (
      // eslint-disable-next-line react/prop-types
      <span ref={ref} className={props.className} data-state={dataState} {...referenceProps}>
        {children}
      </span>
    )
  }

  return (
    <button ref={ref} data-state={dataState} type="button" {...referenceProps}>
      {children}
    </button>
  )
}

/* eslint-enable react/jsx-props-no-spreading */

export { Tooltip, TooltipContent, TooltipProps, TooltipTrigger, useTooltip, useTooltipContext }
