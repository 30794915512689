import React from "react"
import { Person } from "types/graphql"
import { useTruncatedTooltip } from "v2/react/hooks/useTruncatedTooltip"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/Tooltip"

interface Props {
  person: Person
}

function PersonName({ person }: Props) {
  const { ref, showTooltip, setShowTooltip, determineTooltip } =
    useTruncatedTooltip<HTMLParagraphElement>()

  return (
    <Tooltip open={showTooltip}>
      <TooltipTrigger
        className="m-0 inline-block"
        onMouseEnter={determineTooltip}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <p ref={ref} className="person-name max-w-12 truncate">
          {person.name}
        </p>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content--sm">{person.name}</TooltipContent>
    </Tooltip>
  )
}

export { PersonName }
