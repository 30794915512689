import React from "react"
import { TruncatedTitle } from "v2/react/components/careers/Index/TruncatedTitle"

interface Props {
  jobFamily?: string
  parentFamily?: string
}

function JobFamilyDisplay({ jobFamily, parentFamily }: Props) {
  return (
    <div className="flex">
      {parentFamily && (
        <div className="max-w-[50%]">
          <TruncatedTitle title={parentFamily} titleClasses="text-sm text-neutral-64" />
        </div>
      )}
      {parentFamily && <p className="text-sm text-neutral-64">&nbsp;›&nbsp;</p>}
      <TruncatedTitle title={jobFamily || ""} titleClasses="h-4 text-sm text-neutral-64" />
    </div>
  )
}

export { JobFamilyDisplay }
