import React from "react"
import fp from "lodash/fp"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Maybe } from "types/graphql.d"
import { GroupRow } from "v2/react/shared/Datasheet/types"

interface GroupCellProps {
  onExpandCollapseGrouping: (row: GroupRow) => void
  isFirst: boolean
  row: GroupRow
  style: React.CSSProperties
}

const PaddingLeftBase = 10
const PaddingLeftMultiplier = 20

function GroupCell({ isFirst, row, style, onExpandCollapseGrouping }: GroupCellProps) {
  if (!isFirst) return null

  const toggleGroupCollapseState = () => onExpandCollapseGrouping(row)

  return (
    <div style={{ ...style, width: "100%" }} className="grouping">
      <button
        className="Grid-groupingToggle"
        onClick={toggleGroupCollapseState}
        style={{ paddingLeft: paddingLeft(row) }}
        type="button"
      >
        <div className="flex-row items-center flex">
          <FontAwesomeIcon icon={row.isExpanded ? "caret-down" : "caret-right"} />
          {row.color ? (
            <span className="Grid-groupingToggle__color-box" style={colorSpanStyle(row.color)} />
          ) : null}
          <span className="Grid-groupingToggle__space-left">
            {` ${row.label}: `}
            {!fp.isEmpty(row.value) ? (
              <span>
                {"group_heading".t("org_chart", null, null, null, [row.value, row.childrenCount])}
              </span>
            ) : (
              <em>
                {"group_heading_no_value".t("org_chart", null, null, null, [row.childrenCount])}
              </em>
            )}
          </span>
        </div>
      </button>
    </div>
  )
}

const colorSpanStyle = (color?: Maybe<string>) => (color ? { backgroundColor: color } : {})

const paddingLeft = (row: GroupRow) => {
  // Prettier and eslint are at odds! ESLint disputes the idea that the order of
  // operations is common knowledge, and demands parens. Prettier believes in
  // beauty at the cost of clarity. Who will win? Neither.
  const depthAdjustedPaddingLeft = PaddingLeftMultiplier * row.depth
  return PaddingLeftBase + depthAdjustedPaddingLeft
}

export { GroupCell, GroupCellProps }
