import React, { useRef } from "react"
import cn from "classnames"

import { Table as ReactTable } from "@tanstack/react-table"
import { useMergeRefs } from "@floating-ui/react"

import { TableBody } from "./Table/TableBody"
import { TableFooter } from "./Table/TableFooter"
import { Pagination } from "./Table/Pagination"
import { TableHeader } from "./Table/TableHeader"
import { Spinner } from "./Spinner"

interface TableProps<TData> {
  table: ReactTable<TData>
  className?: string
  footerContent?: string
  isFetching?: boolean
  onPageChange?: () => void
  optOutOfScrollReset?: boolean
  tableRef?: React.RefObject<HTMLDivElement>
}

export const Table = <TData extends object>({
  table,
  className,
  footerContent,
  isFetching,
  onPageChange,
  optOutOfScrollReset,
  tableRef: theirTableRef,
}: TableProps<TData>) => {
  const numPages = table.getPageCount()
  const numRows = table.getRowModel().rows.length
  const tableRef = useRef<HTMLDivElement>(null)
  const refSetter = useMergeRefs([tableRef, theirTableRef])

  // Reset scroll on new render
  if (tableRef.current && !isFetching && !optOutOfScrollReset) {
    tableRef.current.scrollTop = 0
    tableRef.current.scrollLeft = 0
  }

  return (
    <>
      <div style={{ position: "relative" }}>
        <div className={cn("table", className)} ref={refSetter}>
          <table>
            <TableHeader table={table} />
            <TableBody table={table} isFetching={isFetching} />
            <TableFooter
              columnLength={table.getAllColumns().length}
              content={footerContent || ""}
            />
          </table>
        </div>
        {isFetching && numRows > 0 && <Spinner style={{ zIndex: 2, marginTop: "-20px" }} />}
      </div>
      {numPages > 1 && <Pagination onPageChange={onPageChange} table={table} />}
    </>
  )
}
