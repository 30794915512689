import fp from "lodash/fp"

import { AppDispatch, RootState } from "v2/redux/store"
import { FieldKey } from "v2/redux/slices/NodeSlice/types"
import { GroupRow, RowType } from "v2/redux/slices/GridSlice/types"
import { Maybe } from "types/graphql.d"

import {
  addFilter,
  GroupByAction,
  removeFilter,
  setGroupEntities,
  setGroupFieldKeys,
  skeletonSelectors,
  toggleOrderBy,
  ToggleOrderByAction,
  updateManySkeletonRows,
} from "v2/redux/slices/GridSlice"
import { arrangeDatasheet } from "v2/redux/listeners/datasheetListeners/arrangeDatasheetEffect"
import { selectDistinctGroupRows } from "v2/redux/slices/GridSlice/gridSelectors/selectDistinctGroupRows"
import { selectFilters, selectGroupFieldKeys } from "v2/redux/slices/GridSlice/gridSelectors"

/**
 * Rebuilds the grid's skeleton using loaded nodes and applying filters,
 * groups, and the order clause.
 *
 * @public
 */
function asyncComputeAndSetSkeleton() {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const distinctGroupRows = selectDistinctGroupRows(getState())
    dispatch(setGroupEntities(distinctGroupRows))

    setTimeout(() => dispatch(arrangeDatasheet()), 0)
  }
}

function asyncGroupBy(payload: GroupByAction["payload"]) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const groupKeys = selectGroupFieldKeys(getState())
    const nextKeys =
      payload.useTo === "add"
        ? fp.union([payload.fieldKey], groupKeys)
        : fp.without([payload.fieldKey], groupKeys)
    dispatch(setGroupFieldKeys(nextKeys))

    return dispatch(asyncComputeAndSetSkeleton())
  }
}

/**
 * Toggles the `isExpanded` flag of a group row; updates the skeleton group
 * group row that is being expanded or collapsed as well as any descendant
 * groups that will become hidden or shown as a result of this action. This
 * does not rebuild the skeleton so rows with changed values remain in place.
 *
 * @public
 */
function asyncToggleGrouping(payload: GroupRow) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const skeletonGroupRow = skeletonSelectors.selectById(state, payload.id) as GroupRow | undefined
    if (!skeletonGroupRow) return null

    const nestedSkeletonGroupRows = fp.filter((row) => {
      if (row.rowType !== RowType.Group) return false
      if (row.id === skeletonGroupRow.id) return false

      return fp.equals(
        skeletonGroupRow.groupPath,
        fp.slice(0, skeletonGroupRow.groupPath.length, row.groupPath),
      )
    }, skeletonSelectors.selectAll(state)) as GroupRow[]

    const isExpanded = !payload.isExpanded
    const isHidden = !isExpanded

    const updates = [
      { id: skeletonGroupRow.id, changes: { isExpanded } },
      ...fp.map(({ id }) => ({ id, changes: { isHidden } }), nestedSkeletonGroupRows),
    ]

    return dispatch(updateManySkeletonRows(updates))
  }
}

function asyncToggleOrderBy(payload: ToggleOrderByAction["payload"]) {
  return async (dispatch: AppDispatch) => {
    // Change the order clause in state and rebuild the grid "skeleton".
    dispatch(toggleOrderBy(payload))
    return dispatch(asyncComputeAndSetSkeleton())
  }
}

function asyncAddFilter(payload: { fieldKey: FieldKey; term: string }) {
  return async (dispatch: AppDispatch) => {
    dispatch(addFilter(payload))
    return dispatch(asyncComputeAndSetSkeleton())
  }
}

function asyncRemoveFilter(payload: FieldKey) {
  return async (dispatch: AppDispatch) => {
    dispatch(removeFilter(payload))
    return dispatch(asyncComputeAndSetSkeleton())
  }
}

/**
 * @public
 * @returns {function} an async thunk which toggles the node container to/from
 *   the given chart section. This executes a network request which stores the
 *   change in the user's preference. Resolves to nothing.
 */
function asyncToggleChartSectionFilter(id: string | number, term: Maybe<string>) {
  return async (dispatch: (action: unknown) => unknown, getState: () => RootState) => {
    const { $, gon, history } = window
    const displayMode = getState().visualization.displayMode

    const filters = selectFilters(getState())
    const current = fp.find(fp.propEq("fieldKey", "chart_section"), filters)

    const normalizedTerm = typeof term === "string" ? term.trim() : term
    const incomingIsEmpty = fp.isEmpty(normalizedTerm)
    const shouldClear = incomingIsEmpty || current?.term === normalizedTerm

    // This does not need to do anything if it doesn't result in a state
    // change. The URL is updated so hard refresh behaves the way the user
    // might expect. Without this, a user who visits the org chart while it's
    // initially drilled in, proceeds to drill back out to the full chart, and
    // then performs a hard refresh will see a filtered view again.
    if (!shouldClear && current?.term !== normalizedTerm) {
      await dispatch(asyncAddFilter({ fieldKey: "chart_section", term: normalizedTerm }))
      await history.replaceState(history.state, "", `/orgchart/chart_sections/${id}`)
      window.App.OrgChart.setChartViewOptions("chart_section", id)
      if (displayMode !== "grid") {
        window.App.OrgChart.router.navigate(`orgchart/chart_sections/${id}`, true)
      }
      await $.get({ url: `/chart_sections/${id}/select` })
    } else if (shouldClear && current) {
      await dispatch(asyncRemoveFilter("chart_section"))
      await history.replaceState(history.state, "", "/orgchart")
      window.App.OrgChart.setChartViewOptions("full_chart")
      if (displayMode !== "grid") {
        window.App.OrgChart.router.navigate("orgchart", true)
      }
      await $.get({ url: `/charts/${gon.selected_chart.id}/select` })
    }
  }
}

export {
  asyncAddFilter,
  asyncComputeAndSetSkeleton,
  asyncGroupBy,
  asyncRemoveFilter,
  asyncToggleChartSectionFilter,
  asyncToggleGrouping,
  asyncToggleOrderBy,
}
