import { times } from "lodash"
import { NodeData } from "../node/types"

const isColorWhite = (color: string): boolean => {
  // Remove all whitespace and convert to lowercase for uniformity
  const normalizedColor = color.replace(/\s/g, "").toLowerCase()

  if (!normalizedColor) {
    return false
  }

  // Check for white in hex format
  if (normalizedColor === "#fff" || normalizedColor === "#ffffff") {
    return true
  }

  // Check for white in RGB/RGBA format
  if (normalizedColor.startsWith("rgb")) {
    // Extract the numerical values
    const rgb = normalizedColor.match(/\d+/g)?.map(Number)
    if (!rgb) return false

    // Check if R, G, B are all 255
    return rgb.length > 0 && rgb[0] === 255 && rgb[1] === 255 && rgb[2] === 255
  }

  return false
}

const flashOutAnimation = (node: SVGGElement, card: d3.Selection<NodeData>): void => {
  const data = window.d3.select(node).data()
  const clonedCard = card.node().cloneNode(true) as SVGRectElement

  node.insertBefore(clonedCard, card.node())
  const scale = 1.1
  const originalHeight = clonedCard.getBBox().height
  const scaledHeight = originalHeight * scale
  const verticalOffset = (scaledHeight - originalHeight) / 2
  const pulseShape = window.d3.select(clonedCard)

  card
    .transition()
    .duration(50)
    .attr("stroke", "rgba(139, 231, 255, 1)")
    .transition()
    .duration(3000)
    .attr("stroke", "none")

  pulseShape
    .data(data)
    .attr("opacity", "1")
    .attr("filter", "url(#card-pulse-shadow)")
    .style("fill", (d) => {
      const assignedCardColor = d.card_color
      // accent-breeze
      const defaultColor = "rgba(139, 231, 255, 1)"

      if (assignedCardColor && !isColorWhite(assignedCardColor)) {
        return assignedCardColor
      }
      return defaultColor
    })
    .transition()
    .duration(3000)
    .attr("transform", `translate(0,-${verticalOffset})scale(${scale})`)
    .attr("opacity", "0")
    .each("end", function (this: SVGRectElement) {
      window.d3.select(this).remove() // Remove after animation
    })
}

const pulseDuration = 600

const pulseNodeSize = (node: SVGGElement): void => {
  const nodeGroup = window.d3.select(node)
  const scale = 1.08
  const currentTransform = nodeGroup.attr("transform")
  const currentHeight = node.getBBox().height
  const scaledHeight = currentHeight * scale
  const verticalOffset = (scaledHeight - currentHeight) / 2
  /* eslint-disable no-useless-escape */
  const translateCoordinatesMatch = currentTransform.match(
    /translate\(([-\d\.]+)(?:,|\s)([-\d\.]+)\)/,
  )
  /* eslint-enable no-useless-escape */

  if (!translateCoordinatesMatch) return

  const currentTranslate = [translateCoordinatesMatch[1], translateCoordinatesMatch[2]].map(
    (coordinate) => Number(coordinate),
  )

  window.d3
    .select(node)
    .transition()
    .duration(pulseDuration / 2)
    .attr(
      "transform",
      `translate(${currentTranslate[0]},${currentTranslate[1] - verticalOffset})scale(${scale})`,
    )
    .transition()
    .duration(pulseDuration / 2)
    .attr("transform", `translate(${currentTranslate[0]},${currentTranslate[1]})`)
}

const flashCard = function (node: SVGGElement): SVGGElement {
  const card = window.d3.select(node).select("rect.card")
  flashOutAnimation(node, card)
  times(2, (n) => {
    setTimeout(() => {
      pulseNodeSize(node)
    }, n * pulseDuration)
  })

  return node
}

export default flashCard
