import { NodeData, PositioningHelper } from "./types"
import { globals } from "../constants/options"
import exportStyles from "../constants/exportStyles"

const update = (
  selection: d3.Selection<NodeData>,
  positioningHelper: PositioningHelper,
): d3.Selection<NodeData> => {
  const edgeColors = selection.selectAll("rect.color").data((d) => [d])

  edgeColors.enter().append("rect").attr("class", "color")

  edgeColors
    .attr("rx", globals.nodeRadius)
    .attr("ry", globals.nodeRadius)
    .attr("clip-path", "url(#color-clip)")
    .style(exportStyles.color)
    // This needs to be at least double the width of the radius to have the
    // same curvature of the card.
    .attr("width", globals.nodeRadius * 2)
    .attr("height", (d) => positioningHelper.getNodeHeightInContext(d))
    .attr("x", -(globals.nodeWidth / 2))
    .attr("y", 0)

    /**
     * The fill of this element is handled by the color coder.
     * @see org_chart/chart/node/colorCodeChartNodes.js
     */
    .attr("fill", "transparent")

  edgeColors.exit().remove()

  return selection
}

export default update
