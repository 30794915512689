import fp from "lodash/fp"
import { createSlice, EntityId } from "@reduxjs/toolkit"

import {
  batchAdapter,
  entityEventLogsAdapter,
  matchProcessingSubscribed,
  processingAdapter,
} from "v2/redux/slices/NotificationSlice/utils"

import {
  BatchChangedAction,
  EntityEventOccurredAction,
  NotificationState,
  ProcessingDoneAction,
  ProcessingIncrementedAction,
  ProcessingRecord,
  ProcessingStatus,
  ProcessingUpdatedAction,
} from "./NotificationSlice/types"
import { PositionTypesApi } from "../GraphqlApi/PositionTypesApi"

const makeInitialProcessingRecord = (subjectId: EntityId) => ({
  subjectId,
  status: ProcessingStatus.Inactive,
  current: 0,
  outOf: 0,
  log: [],
})

const initialState: NotificationState = {
  batchOpenedHits: 0,
  entityEventLogs: entityEventLogsAdapter.getInitialState(),
  batches: batchAdapter.getInitialState(),
  processing: processingAdapter.getInitialState(),
}

// The Slice
// ---------

const NotificationSlice = createSlice({
  name: "notification",
  initialState,

  reducers: {
    batchChanged(state, { payload: { subjectId, count } }: BatchChangedAction) {
      batchAdapter.upsertOne(state.batches, { subjectId, count })
    },
    batchOpened(state) {
      state.batchOpenedHits += 1 // eslint-disable-line no-param-reassign
    },
    entityEventOccurred(state, { payload: { subjectId, data } }: EntityEventOccurredAction) {
      const entry = state.entityEventLogs.entities[subjectId] ?? { subjectId, events: [] }
      const eventLog = { ...entry, events: [data, ...entry.events] }

      entityEventLogsAdapter.upsertOne(state.entityEventLogs, eventLog)
    },
    processingUpdated(state, { payload: { subjectId, ...updates } }: ProcessingUpdatedAction) {
      const changes: Partial<ProcessingRecord> = fp.omitBy(fp.isNil, updates)
      processingAdapter.updateOne(state.processing, { id: subjectId, changes })
    },
    processingIncremented(state, { payload: increment }: ProcessingIncrementedAction) {
      const existing = state.processing.entities[increment.subjectId]
      if (!existing) return

      const log = increment.label ? [...existing.log, increment.label] : existing.log
      const changes = { current: existing.current + increment.by, log }
      processingAdapter.updateOne(state.processing, { id: increment.subjectId, changes })
    },
    processingDone(state, { payload: { subjectId: id } }: ProcessingDoneAction) {
      const changes = { status: ProcessingStatus.Done }
      processingAdapter.updateOne(state.processing, { id, changes })
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(matchProcessingSubscribed, (state, { payload: subscribeTo }) => {
        processingAdapter.addOne(
          state.processing,
          makeInitialProcessingRecord(subscribeTo.subjectId),
        )
      })
      .addMatcher(
        PositionTypesApi.endpoints.getPositionTypeDetails.matchFulfilled,
        (state, { payload: positionTypeDetailsQuery }) => {
          // Temporary code that clobbers the entity event log now that we've
          // loaded the latest and greatest.
          const subjectId = positionTypeDetailsQuery?.positionType?.uniqueKey
          const eventLog = subjectId && state.entityEventLogs.entities[subjectId]
          if (!eventLog) return

          entityEventLogsAdapter.setOne(state.entityEventLogs, { subjectId, events: [] })
        },
      ),
})

export * from "v2/redux/slices/NotificationSlice/hooks"
export * from "v2/redux/slices/NotificationSlice/types"
export * from "v2/redux/slices/NotificationSlice/utils"
export const {
  batchChanged,
  batchOpened,
  entityEventOccurred,
  processingUpdated,
  processingIncremented,
  processingDone,
} = NotificationSlice.actions
export { NotificationSlice, ProcessingStatus }
