import React, { forwardRef } from "react"
import classnames from "classnames"

import { Option } from "types/graphql"

interface SelectProps {
  className?: string
  defaultValue?: string
  disabled?: boolean
  id?: string
  name?: string
  onSelect?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  options: Option[]
  required?: boolean
  selected?: string
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    { className, defaultValue, disabled, id, name, onSelect, options, required = false, selected },
    ref,
  ) => (
    <div className={classnames("select", className)}>
      <select
        defaultValue={selected}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onSelect}
        ref={ref}
        required={required}
      >
        {defaultValue && <option value="">{defaultValue}</option>}
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  ),
)

export { Select }
