import React from "react"
import { Maybe } from "graphql/jsutils/Maybe"
import { OrderListItemStatic } from "v2/react/shared/OrderedList/OrderListItemStatic"
import {
  ApproverType,
  NoteType,
  checkIfNextApprover,
  initialStatuses,
} from "v2/react/shared/Status/statusUtils"
import { ApproverCard } from "v2/react/shared/Status/ApproverList/ApproverCard"

interface ApproverOrderedListProps {
  approvers: ApproverType[]
  notes: NoteType[] | ApproverType[]
  workflowRequestDate: Date
  workflowStatus: Maybe<string> | undefined
}

function ApproverOrderedList({
  approvers,
  notes,
  workflowRequestDate,
  workflowStatus,
}: ApproverOrderedListProps) {
  return (
    <>
      {approvers.map((approver: ApproverType, index: number) => (
        <OrderListItemStatic
          key={approver.id}
          isFirst={index === 0}
          isLast={index === approvers.length - 1}
          indexPanelColor={
            checkIfNextApprover(approvers, approver) &&
            workflowStatus &&
            initialStatuses.includes(workflowStatus)
              ? "primary-light"
              : "secondary-light"
          }
          indexPanelContent={<p>{index + 1}</p>}
          mainPanelContent={
            <ApproverCard
              approver={approver}
              isNextApprover={checkIfNextApprover(approvers, approver)}
              notes={notes}
              workflowRequestDate={workflowRequestDate}
              workflowStatus={workflowStatus}
            />
          }
        />
      ))}
    </>
  )
}

export { ApproverOrderedList }
