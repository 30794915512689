import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTogglePositionExclusionMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import { PositionMenuButton } from "./PositionMenuButton"

type Props = {
  headcountPlanId: string
  participantId?: string
  rootEventId: string
  excluded: boolean | undefined | null
  onSuccess: () => void
}

export function TogglePositionExclusionButton({
  headcountPlanId,
  participantId,
  rootEventId,
  excluded,
  onSuccess,
}: Props) {
  const [mutate, mutation] = useTogglePositionExclusionMutation()

  React.useEffect(() => {
    if (mutation.data?.togglePositionExclusion?.success) {
      onSuccess()
    }
  }, [mutation.data, onSuccess])

  return (
    <PositionMenuButton
      icon={
        excluded ? (
          <FontAwesomeIcon icon={["fas", "eye"]} />
        ) : (
          <FontAwesomeIcon icon={["far", "eye-slash"]} />
        )
      }
      text={excluded ? "Include Position" : "Exclude Position"}
      onClick={() =>
        mutate({
          input: {
            headcountPlanId,
            participantId,
            rootEventId,
          },
        })
      }
    />
  )
}
