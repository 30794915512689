import React, { useState } from "react"
import { BioAward, FeatureFlags } from "types/graphql"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSortedBoxes } from "v2/react/components/orgChart/ProfilePanel/useSortedBoxes"
import { AccordionModulePanel } from "v2/react/shared/AccordionModulePanel"
import { AccordionModulePanelOverlay } from "v2/react/shared/AccordionModulePanelOverlay"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"
import { useAppSelector } from "v2/redux/store"

interface BioAwardsProps {
  featureFlags: FeatureFlags
}

function BioAwards({ featureFlags }: BioAwardsProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const { currentBox, toggleBox } = useSortedBoxes("bio_awards", "person")
  const [showOverlay, setShowOverlay] = useState(false)
  const [overlayContent, setOverlayContent] = useState<BioAward | null>(null)

  if (!currentBox || !person) return null
  if (!featureFlags.bio || !person.bioAwards?.length) return null

  const headerContent = <div className="title">{"bio_awards".t("org_chart")}</div>
  const overlayHeader = <div className="title">{overlayContent?.name}</div>

  const overlayBody = () => (
    <ModuleListItem>
      {overlayContent?.description ? (
        <div>
          <div>{"Description".t("org_chart")}</div>
          <p>{sanitizeParse(overlayContent.description)}</p>
        </div>
      ) : (
        <span />
      )}
    </ModuleListItem>
  )

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
      showOverlay={showOverlay}
      overlayContent={
        <AccordionModulePanelOverlay
          isOpen={showOverlay}
          closePanel={() => {
            setOverlayContent(null)
            setShowOverlay(false)
          }}
          headerContent={overlayHeader}
        >
          {overlayBody()}
        </AccordionModulePanelOverlay>
      }
    >
      <ModuleListItem>
        <div className="flex-wrap gap-2.5 flex">
          {person.bioAwards?.map((award) => (
            <button
              key={award.id}
              type="button"
              className="badge--link hover mb-2"
              onClick={() => {
                setOverlayContent(award)
                setShowOverlay(true)
              }}
            >
              {award.name}
            </button>
          ))}
        </div>
      </ModuleListItem>
    </AccordionModulePanel>
  )
}

export { BioAwards }
