import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { StrictMode, useState } from "react"
import RootProvider from "v2/react/components/RootProvider"
import { asyncPatchPreferences } from "v2/redux/slices/ContainerSlice/containerThunks"
import { useAppDispatch, useAppSelector } from "v2/redux/store"
import { Modal, ModalFooter } from "v2/react/shared/Modal"
import { useTranslation } from "react-i18next"

function MetricsSettingsButtonInner() {
  const { t } = useTranslation()
  const [isChecked, setIsChecked] = useState(
    useAppSelector((state) => state.visualization.metricsIncludeDotted),
  )
  const [isLoading, setIsLoading] = useState(false)
  const [settingsModalIsOpen, setSettingsModalIsOpen] = useState(false)
  const dispatch = useAppDispatch()

  const onSubmit = async () => {
    setIsLoading(true)
    await dispatch(asyncPatchPreferences({ metrics_include_dotted: isChecked })).then(() => {
      window.App.OrgChart.reloadMetrics(true)
      closeModal()
    })
  }

  const handleSettingsButtonClick = () => {
    setSettingsModalIsOpen(true)
  }

  const closeModal = () => {
    setIsLoading(false)
    setSettingsModalIsOpen(false)
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.currentTarget.checked)
  }

  return (
    <div>
      <button type="button" onClick={handleSettingsButtonClick} className="btn--sm btn--ghost">
        <FontAwesomeIcon icon={["far", "sliders-h"]} />
      </button>
      <Modal
        isOpen={settingsModalIsOpen}
        onClose={closeModal}
        title={t("orgchart.metrics.configure_key_metrics")}
        footer={
          <ModalFooter
            disabled={isLoading}
            onClose={closeModal}
            onSave={onSubmit}
            saveButtonText={t("v2.defaults.save")}
          />
        }
      >
        <div className="react-modal__body flex-col gap-2 flex">
          <div className="input-group">
            <label className="radio" htmlFor="include-dotted" style={{ marginTop: ".313rem" }}>
              <input
                type="checkbox"
                checked={isChecked}
                id="include-dotted"
                name="company[permitted_sso_providers][]"
                onChange={handleCheckboxChange}
                value={1}
              />
              {t("orgchart.metrics.include_dotted_line_positions")}
            </label>
          </div>
        </div>
      </Modal>
    </div>
  )
}

function MetricsSettingsButton() {
  return (
    <StrictMode>
      <RootProvider>
        <MetricsSettingsButtonInner />
      </RootProvider>
    </StrictMode>
  )
}

export default MetricsSettingsButton
