import React, { useState, useRef, useMemo, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import type { DropdownProps } from "v2/react/shared/Dropdown/Dropdown"
import type { OnOpenChange } from "v2/react/shared/Dropdown/hooks/useDropdownSelect"
import { useDropdownSelect } from "v2/react/shared/Dropdown/hooks/useDropdownSelect"
import { Dropdown, DropdownContext } from "v2/react/shared/Dropdown/Dropdown"

interface SearchableItemType {
  id: string
  label: string
}

interface SearchableDropdownProps<T> {
  id: string
  items: T[]
  onSelect: (selectedItem: T) => void
  align?: DropdownProps["align"]
  searchPlaceholder?: string
  noResultsPlaceholder?: string
  makeLabel?: (item: T) => ReactNode
}

const SearchableDropdown = <T extends SearchableItemType>({
  id,
  items,
  onSelect,
  align,
  searchPlaceholder,
  noResultsPlaceholder,
  makeLabel,
}: SearchableDropdownProps<T>) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const onOpenChange: OnOpenChange = (isOpen, event, reason) => {
    // Hack: When tabbing out of the search field, the reason here is undefined.
    // We leverage this to close the dropdown.
    if (!reason) {
      setIsOpen(false)
      floatingInfo.setActiveIndex(0)
    }

    const target = event?.target
    const targetInContainer =
      target instanceof HTMLElement && containerRef.current?.contains(target)
    if (targetInContainer) {
      return
    }

    setIsOpen(isOpen)
  }

  const floatingInfo = useDropdownSelect({
    showDropdown: isOpen,
    setShowDropdown: onOpenChange,
    align,
    ariaRole: "combobox",
    virtual: true,
    useDynamicSizing: true,
    maxHeight: 384, // 24rem
  })

  const contextValue = useMemo(
    () => ({ isOpen, setIsOpen, floatingInfo }),
    [isOpen, setIsOpen, floatingInfo],
  )

  const handleItemSelect = (item: T) => {
    onSelect?.(item)
    setIsOpen(false)
    setInputValue("")
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value
    setInputValue(searchTerm)
    floatingInfo.setActiveIndex(0)
  }

  const handleClearValue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setInputValue("")
    floatingInfo.setActiveIndex(0)
  }

  const visibleItems = items
    .filter((item) => item.label.toLowerCase().includes(inputValue.toLowerCase().trim()))
    .sort((a, b) => a.label.localeCompare(b.label))
  const noResults = visibleItems.length === 0

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "Enter" &&
      floatingInfo.activeIndex != null &&
      visibleItems[floatingInfo.activeIndex]
    ) {
      e.preventDefault()
      handleItemSelect(visibleItems[floatingInfo.activeIndex])
      setInputValue("")
      floatingInfo.setActiveIndex(null)
      setIsOpen(false)
      floatingInfo.refs.domReference.current?.blur()
    }
  }

  const handleFocus = () => {
    if (!isOpen) {
      floatingInfo.setActiveIndex(0)
      setIsOpen(true)
    }
  }

  return (
    <DropdownContext.Provider value={contextValue}>
      <div id={`${id}-wrapper`} className="dropdown w-full" ref={containerRef}>
        <div className="search-field">
          <FontAwesomeIcon icon={["far", "search"]} className="search-icon prefix" />
          <input
            id={id}
            className="input prefix-pad suffix-pad"
            data-testid="combobox-trigger"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...floatingInfo.getReferenceProps({
              ref: floatingInfo.refs.setReference,
              onChange: handleSearch,
              value: inputValue,
              placeholder: searchPlaceholder ?? t("v2.shared.searchable_list.search"),
              onKeyDown: handleKeyDown,
              onFocus: handleFocus,
            })}
          />
          <button className="suffix" onMouseDown={handleClearValue} type="button" tabIndex={-1}>
            <FontAwesomeIcon icon={["far", "times"]} className="pointer-events-none" />
          </button>
        </div>
        <Dropdown.Menu id="search-results" captureInitialFocus={false} returnFocus={false}>
          {noResults ? (
            <div id="no-search-results-message" className="p-2">
              {noResultsPlaceholder ?? t("v2.defaults.no_results_found")}
            </div>
          ) : (
            visibleItems.map((item) => (
              <Dropdown.Item
                key={`search-result-${item.id}`}
                id={`search-result-${item.id}`}
                onClick={() => handleItemSelect(item)}
                className="max-w-sm break-words"
                as="div"
                useActiveStyles
              >
                {makeLabel ? makeLabel(item) : item.label}
              </Dropdown.Item>
            ))
          )}
        </Dropdown.Menu>
      </div>
    </DropdownContext.Provider>
  )
}

export { SearchableDropdown }
