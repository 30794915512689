import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { StylesProps } from "./StyleLayers"

interface Props {
  styles: StylesProps
  showStyle: boolean
}

function SaveLayer({ styles, showStyle }: Props) {
  return (
    <AnimatePresence>
      {showStyle && (
        <>
          <motion.div
            key="bg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ ...styles, position: "absolute", background: "#e8f5fb", zIndex: -1 }}
          />
          <motion.div
            key="border"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="GridBody-cell__saving"
            style={{
              ...styles,
              border: `1px solid ${styles.color}`,
            }}
          >
            <FontAwesomeIcon
              icon={["far", "check"]}
              style={{
                top: "50%",
                right: "1rem",
                color: styles.color,
                position: "absolute",
                transform: "translate(0, -50%)",
                fontSize: "1.5rem",
              }}
              className="GridBody-cell__saving-icon"
            />
          </motion.div>
        </>
      )}
    </AnimatePresence>
  )
}

export { SaveLayer }
