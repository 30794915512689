import React from "react"
import { SaveLayer } from "./StyleLayers/SaveLayer"
import { ErrorLayer } from "./StyleLayers/ErrorLayer"
import { FieldType } from "./types"
import { Cell } from "./hooks/useCellState"

export const StyleLayers = React.memo(
  // eslint-disable-next-line prefer-arrow-callback
  function StyleLayers({ cell, fieldType }: { cell: Cell; fieldType: FieldType }) {
    return (
      <>
        <SaveLayer showStyle={cell.isSaved} />
        <ErrorLayer
          showStyle={cell.isErrored || cell.isErroredSaving}
          errorMessage={cell.errorMessage}
          fieldType={fieldType}
        />
      </>
    )
  },
)
