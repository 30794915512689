import React, { FC } from "react"
import { GroupLayout, JsonSchema, LayoutProps } from "@jsonforms/core"
import { JsonFormsDispatch } from "@jsonforms/react"
import { AccordionModulePanel } from "v2/react/shared/AccordionModulePanel"

interface PanelGroupProps extends LayoutProps {
  schema: JsonSchema
  uischema: GroupLayout
}

const JsonPanelGroupLayout: FC<PanelGroupProps> = (props) => {
  const { schema, path, cells, enabled, uischema, renderers } = props as PanelGroupProps

  return (
    <AccordionModulePanel title={uischema.label || ""} headerContent={uischema.label || ""}>
      {uischema.elements.map((child, index) => (
        <JsonFormsDispatch
          schema={schema}
          uischema={child}
          path={path}
          enabled={enabled}
          renderers={renderers}
          cells={cells}
          /* eslint-disable react/no-array-index-key */
          key={index}
        />
      ))}
    </AccordionModulePanel>
  )
}

export { JsonPanelGroupLayout }
