import { GraphqlApi } from "v2/redux/GraphqlApi"

import {
  CancelChartApprovalRequestInput,
  ChartApprover,
  RequestChartApprovalInput,
  SaveChartApproversInput,
  SaveChartApproverStatusInput,
  ScenarioChartApproversAndStatusQuery,
} from "types/graphql.d"

import OperationStore from "v2/operation_store"

const cancelChartApprovalRequestOperationId = OperationStore.getOperationId(
  "CancelChartApprovalRequest",
)
const chartAndApproversOperationId = OperationStore.getOperationId(
  "ScenarioChartApproversAndStatus",
)
const requestChartApprovalOperationId = OperationStore.getOperationId("RequestChartApproval")
const saveApproverStatusOperationId = OperationStore.getOperationId("SaveChartApproverStatus")
const saveApproversOperationId = OperationStore.getOperationId("SaveChartApprovers")

const ChartApprovalsApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    cancelChartApprovalRequest: builder.mutation({
      query: (params: { input: CancelChartApprovalRequestInput }) => ({
        operationId: cancelChartApprovalRequestOperationId,
        variables: { input: params.input },
      }),
      invalidatesTags: ["Chart"],
    }),
    getChartAndApprovers: builder.query({
      query: (params: { id: number }) => ({
        operationId: chartAndApproversOperationId,
        variables: { id: params.id },
      }),
      providesTags: ["Chart", "Approvers"],
      transformResponse: (response: ScenarioChartApproversAndStatusQuery) => {
        // approvers are sorted before returning the response so that they appear
        // in the correct sortOrder in the dropdowns as well as the Configure Modal
        const sortedApprovers: ChartApprover[] =
          response.currentCompany?.scenarioCharts[0].chartApprovers
            .filter((a) => a.sortOrder !== undefined && a.sortOrder !== null)
            .sort((a, b) => a.sortOrder! - b.sortOrder!) || []
        return (
          response.currentCompany?.scenarioCharts[0] && {
            ...response.currentCompany?.scenarioCharts[0],
            chartApprovers: sortedApprovers,
            chartPositionNodes: [],
          }
        )
      },
    }),
    requestChartApproval: builder.mutation({
      query: (params: { input: RequestChartApprovalInput }) => ({
        operationId: requestChartApprovalOperationId,
        variables: { input: params.input },
      }),
      invalidatesTags: ["Chart"],
    }),
    saveApprovers: builder.mutation({
      query: (params: { input: SaveChartApproversInput }) => ({
        operationId: saveApproversOperationId,
        variables: { input: params.input },
      }),
      invalidatesTags: ["Approvers"],
    }),
    saveApproverStatus: builder.mutation({
      query: (params: { input: SaveChartApproverStatusInput }) => ({
        operationId: saveApproverStatusOperationId,
        variables: { input: params.input },
      }),
      invalidatesTags: (result) =>
        result.saveChartApproverStatus?.errors.length > 0 ? [] : ["Chart", "Approvers"],
    }),
  }),
})

export const {
  useCancelChartApprovalRequestMutation,
  useGetChartAndApproversQuery,
  useRequestChartApprovalMutation,
  useSaveApproversMutation,
  useSaveApproverStatusMutation,
} = ChartApprovalsApi
