import React, { useEffect } from "react"
import type { Table } from "@tanstack/react-table"
import { downloadCsv, sanitizeCsvValue } from "v2/react/utils/csv"

export function useCsvDownloadListener(
  csvDownloadRef: React.RefObject<HTMLButtonElement> | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: Table<any>,
  csvDownloadName?: string,
) {
  useEffect(() => {
    const handleClick = () => {
      downloadCsv(
        [
          table
            .getFlatHeaders()
            .filter((header) => header.column.columnDef.header !== "")
            .map((header) => sanitizeCsvValue(header.column.columnDef.header as string))
            .join(","),
          ...table
            // We use the 'PrePagination' row model here to ensure that our CSV
            // download is as close to the current table view as possible,
            // excluding pagination.
            // See: https://tanstack.com/table/latest/docs/guide/row-models#the-order-of-row-model-execution
            .getPrePaginationRowModel()
            .rows.filter((row) => !row.getIsGrouped())
            .map((row) =>
              row
                .getVisibleCells()
                .filter((cell) => cell.column.columnDef.header !== "")
                .map((cell) => {
                  const value = cell.getValue() as string | undefined
                  return value ? sanitizeCsvValue(value) : ""
                })
                .join(","),
            ),
        ].join("\n"),
        csvDownloadName,
      )
    }

    const button = csvDownloadRef?.current
    button?.addEventListener("click", handleClick)
    return () => button?.removeEventListener("click", handleClick)
  }, [csvDownloadRef, table, csvDownloadName])
}
