import { GraphqlApi } from "v2/redux/GraphqlApi"

import {
  PositionsOverTimeDataQuery,
  PositionsOverTimeDataQueryVariables,
  PositionsOverTimeDrillDownQuery,
  PositionsOverTimeDrillDownQueryVariables,
  PositionReportTableSettingsQuery,
  PositionReportTableSettingsQueryVariables,
  UpdatePersonPositionReportSettingsInput,
  UpdatePersonPositionReportSettingsMutation,
} from "types/graphql.d"
import OperationStore from "v2/operation_store"
import { queryOperation } from "v2/redux/utils/endpoints"

export const PositionReportsApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getPositionsOverTimeData: builder.query<
      PositionsOverTimeDataQuery,
      PositionsOverTimeDataQueryVariables
    >({
      query: queryOperation<PositionsOverTimeDataQueryVariables>("PositionsOverTimeData"),
    }),
    getPositionsOverTimeDrillDown: builder.query<
      PositionsOverTimeDrillDownQuery,
      PositionsOverTimeDrillDownQueryVariables
    >({
      query: queryOperation<PositionsOverTimeDrillDownQueryVariables>("PositionsOverTimeDrillDown"),
    }),
    getPositionReportTableSettings: builder.query<
      PositionReportTableSettingsQuery,
      PositionReportTableSettingsQueryVariables
    >({
      query: queryOperation<PositionReportTableSettingsQueryVariables>(
        "PositionReportTableSettings",
      ),
      providesTags: ["PositionReportTableSettings"],
    }),
    updatePersonPositionReportSettings: builder.mutation<
      UpdatePersonPositionReportSettingsMutation,
      UpdatePersonPositionReportSettingsInput
    >({
      query: (input) => ({
        operationId: OperationStore.getOperationId("UpdatePersonPositionReportSettings"),
        variables: { input },
      }),
      invalidatesTags: ["PositionReportTableSettings"],
    }),
  }),
})

export const {
  useGetPositionsOverTimeDataQuery,
  useGetPositionsOverTimeDrillDownQuery,
  useGetPositionReportTableSettingsQuery,
  useUpdatePersonPositionReportSettingsMutation,
} = PositionReportsApi
