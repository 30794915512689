const REST_API_BASE_URL = "/api/app/v1"

const DEFAULT_PAGE_SIZE = 30

/**
 * The number of autocomplete options to fetch from the server.
 */
const AUTOCOMPLETE_LIMIT = 5

/**
 * The key used to represent the absence of a value. Used in collection options
 * and filtering, among other places. Matches the value used in the Rails backend
 * in `Collections::Base::NONE_KEY`.
 */
const NONE_KEY = "NONE-KEY"

export { REST_API_BASE_URL, DEFAULT_PAGE_SIZE, AUTOCOMPLETE_LIMIT, NONE_KEY }
