import React, { KeyboardEvent } from "react"

import { preventPropagationOfSyntheticEvent, useOutsideClick } from "v2/react/hooks/useOutsideClick"

import { Person, PeopleConnectionQuery } from "types/graphql.d"
import { usePersonSearch } from "../hooks/usePersonSearch"

interface PersonSearchInputProps {
  autoFocus?: boolean
  dataFetchFn?: () => PeopleConnectionQuery
  errorMessage?: string
  htmlForId?: string
  omitValues?: string[]
  onSelect?: (selectedPerson: Person, index: number) => void
  placeholder?: string
  retainNameOnSelect?: boolean
}

function PersonSearchInput({
  autoFocus,
  dataFetchFn,
  errorMessage,
  htmlForId,
  omitValues,
  onSelect,
  placeholder,
  retainNameOnSelect,
}: PersonSearchInputProps) {
  const {
    showResultList,
    setShowResultList,
    inputValue,
    setInputValue,
    peopleResult,
    handleInputChange,
    selectedIndex,
  } = usePersonSearch({
    omitValues: omitValues || [],
    fieldValue: "",
    dataFetchFn,
  })

  const componentRef = useOutsideClick<HTMLDivElement>(() => {
    setShowResultList(false)
  })

  const onOptionSelected = (name: string) => {
    if (name === "") return

    const submittedIndex = peopleResult && peopleResult.findIndex((pr) => pr.name === name)
    const person: Person = peopleResult[submittedIndex]

    if (onSelect && typeof submittedIndex === "number" && submittedIndex >= 0 && person)
      onSelect(person, submittedIndex)

    if (retainNameOnSelect) {
      setInputValue(person.name)
    } else {
      setInputValue("")
    }

    setShowResultList(false)
  }

  const onArrowDown = () => {
    if (!showResultList || selectedIndex === peopleResult.length - 1) return

    if (inputValue === "") {
      setInputValue(peopleResult[0].name)
    } else {
      setInputValue(peopleResult[selectedIndex + 1].name)
    }

    document
      .getElementsByClassName("highlight")[0]
      .scrollIntoView({ block: "start", behavior: "smooth" })
  }

  const onArrowUp = () => {
    if (!showResultList) return

    if (inputValue === "") {
      setInputValue(peopleResult[0].name)
    } else {
      const targetIndex = selectedIndex === 0 ? 0 : selectedIndex - 1
      setInputValue(peopleResult[targetIndex].name)
    }

    document
      .getElementsByClassName("highlight")[0]
      .scrollIntoView({ block: "start", behavior: "smooth" })
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowUp") {
      onArrowUp()
    }

    if (event.key === "ArrowDown") {
      onArrowDown()
    }

    if (event.key === "Enter") {
      onOptionSelected(inputValue)
    }
  }

  const renderEmptyResults = () => (
    <div className="disabled border border-solid border-neutral-8 p-4">
      {"No people found".t("autocomplete")}
    </div>
  )

  const renderResultsList = () =>
    peopleResult.map((p) => {
      const highlightClass = inputValue === p.name ? "highlight" : ""
      return (
        <button
          type="button"
          className={`PersonSearchInput__result-item ${highlightClass}`}
          data-id={p.id}
          data-name={p.name}
          onClick={() => onOptionSelected(p.name)}
          key={`PersonSearchInput__result-item-${p.id}`}
        >
          <div className="PersonSearchInput__result-item-thumb">
            <img alt={p.name || ""} src={p.avatarThumbUrl || ""} />
          </div>
          <div>
            <div className="PersonSearchInput__result-item-title">{p.name}</div>
            <div className="PersonSearchInput__result-item-subtitle">
              {p.primaryPosition?.title || ""}
            </div>
          </div>
        </button>
      )
    })

  const renderInput = () => (
    <input
      // The use of autoFocus may not be the best choice from an accessibility
      // perspective. Consider revising if possible.
      // https://brucelawson.co.uk/2009/the-accessibility-of-html-5-autofocus/
      // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/no-autofocus.md
      //
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      className="input"
      id={htmlForId}
      onChange={handleInputChange}
      placeholder={placeholder}
      type="text"
      value={inputValue}
      onKeyDown={handleKeyPress}
    />
  )
  const dropdownRef = useOutsideClick<HTMLDivElement>(() => setShowResultList(false))
  return (
    <div className="PersonSearchInput" ref={componentRef}>
      <div className={errorMessage ? "form-error show" : ""}>
        {renderInput()}
        {errorMessage && <span className="form-error-message">{errorMessage}</span>}
      </div>
      {showResultList && peopleResult && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          className="PersonSearchInput__results-container"
          ref={dropdownRef}
          onClick={preventPropagationOfSyntheticEvent}
          onKeyDown={preventPropagationOfSyntheticEvent}
        >
          <div className="PersonSearchInput__result-items">
            {peopleResult.length > 0 ? renderResultsList() : renderEmptyResults()}
          </div>
        </div>
      )}
    </div>
  )
}

export { PersonSearchInput }
