import React, { useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import { motion } from "framer-motion"

import { UrlHelper } from "v2/react/utils/urls"
import { pjaxReload } from "v2/react/utils/pjax"
import { opaqueBasic } from "v2/react/utils/framerAnimationVariants"

import { Tabs } from "./SuccessionPlanPanel/Tabs"

interface Props {
  positionId: string | null
  hidePanel: () => void
}

function SuccessionPlanPanel({ positionId, hidePanel }: Props) {
  const { t } = useTranslation()
  const pjaxContainerRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!positionId) return

    const selector = "#succession-plan-panel-pjax-container"

    // Close any modals that are open, see: preview.coffee
    //
    // Note: This is a temporary solution to handle the case where the panel is opened from an open
    // modal. This happens on the Succession Planning -> Insights page, when clicking on the
    // "<count> Plans" for any given widget. We will revisit using multiple modals when more global
    // refactoring is done (e.g. handling multiple modals, managing links from the panel, using
    // react with managed state/events vs global JS events/variables/jQuery selectors). The compare
    // settings modal is not presently supported when the panel is open over the widget modal.
    //
    // @ts-ignore
    if (window.currentModal !== undefined) window.currentModal.close()

    pjaxReload({
      url: UrlHelper.successionPlanPanelPath(positionId),
      container: selector,
    })
  }, [positionId])

  return (
    <motion.div
      className="px-[1.1rem] py-6"
      data-position-id={positionId}
      variants={opaqueBasic}
      initial={opaqueBasic.hidden}
      animate={opaqueBasic.shown}
      exit={opaqueBasic.hidden}
    >
      <div className="-mb-1 justify-between flex">
        <h3 className="font-light uppercase text-neutral-64">
          {t("v2.positions.show.succession_plan")}
        </h3>
        <button
          type="button"
          className="close -mt-3 bg-transparent p-0 text-xl"
          onClick={hidePanel}
        >
          <FontAwesomeIcon icon={["far", "times"]} className="my-1" />
        </button>
      </div>
      <Tabs pjaxContainerRef={pjaxContainerRef} positionId={positionId || ""} />
      <div id="succession-plan-panel-pjax-container" ref={pjaxContainerRef} />
    </motion.div>
  )
}

export { SuccessionPlanPanel }
