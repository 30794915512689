import { HeadcountPlanChangeProjectionAction, Maybe } from "types/graphql.d"
import { useUpdateHeadcountPlanPositionMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import { SaveFn } from "v2/react/shared/TableDatasheet/types"
import { HeadcountPlanDatasheetRow } from "../HeadcountPlanDatasheet/types"

export function useSaveCell<T = string>(
  headcountPlanId: string,
  columnId: string,
  row: HeadcountPlanDatasheetRow,
  participantId?: string,
): SaveFn<T> {
  const [mutate] = useUpdateHeadcountPlanPositionMutation()

  const saveFn = async (value?: Maybe<T>) => {
    const response = await mutate({
      fieldKey: columnId,
      input: {
        action: HeadcountPlanChangeProjectionAction.UpdateExisting,
        headcountPlanParticipantId: participantId,
        headcountPlanId,
        revisionNumber: row.action === null ? 0 : row.revisionNumber + 1,
        rootEventId: row.rootEventId,
        positionId: row.action === null ? row.position?.id : null,
        payload: {
          ...row.payload,
          [columnId]: value,
        },
      },
    })

    if ("data" in response) {
      return response.data
    }

    return { ok: false, error: { message: "Unknown error" } }
  }

  return saveFn
}
