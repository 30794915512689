import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { useState } from "react"
import { preventPropagationOfSyntheticEvent, useOutsideClick } from "v2/react/hooks/useOutsideClick"

export interface SelectOption {
  headerDisplay: React.ReactNode
  fieldDisplay: React.ReactNode
  text: string
}
interface Props<ItemOption extends SelectOption> {
  options: ItemOption[]
  initialOption: ItemOption
  onSelection: (selected: ItemOption) => void
}

function SelectDropdown<ItemOption extends SelectOption>({
  options,
  initialOption,
  onSelection,
}: Props<ItemOption>) {
  const [isOpen, setIsOpen] = useState(false)
  const [updatedOption, setUpdatedOption] = useState<ItemOption>()
  const toggleDropdown = () => setIsOpen(!isOpen)
  const ref = useOutsideClick<HTMLDivElement>(() => setIsOpen(false))

  const selectedOption: ItemOption = initialOption || updatedOption
  const onOptionClicked = (value: ItemOption) => {
    setUpdatedOption(value)
    onSelection(value)
    setIsOpen(false)
  }

  return (
    <div
      className="select-dropdown"
      onClick={preventPropagationOfSyntheticEvent}
      onKeyDown={preventPropagationOfSyntheticEvent}
      role="presentation"
      ref={ref}
    >
      <button
        onClick={toggleDropdown}
        type="button"
        className="select-dropdown__header items-center justify-between flex"
      >
        {selectedOption.headerDisplay}
        <FontAwesomeIcon icon={["far", "chevron-down"]} />
      </button>
      {isOpen && (
        <ul className="select-dropdown__list">
          {options.map((option) => (
            <li
              role="menuitem"
              onClick={() => onOptionClicked(option)}
              onKeyDown={() => onOptionClicked(option)}
              className={classNames("select-dropdown__option", {
                active: option.text === selectedOption.text,
              })}
              key={option.text}
            >
              {option.fieldDisplay}
              <FontAwesomeIcon
                icon={["fas", "check-circle"]}
                className="selected-indicator ml-auto"
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export { SelectDropdown }
