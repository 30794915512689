import React from "react"
import { Provider } from "react-redux"

import { store } from "./store"

const StoreProvider = ({
  children,
  store: callerStore,
}: React.PropsWithChildren<{ store?: typeof store }>) => (
  <Provider store={callerStore ?? store}>{children}</Provider>
)

export { StoreProvider }
