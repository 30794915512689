import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { CareerLadder, CareerLadderTrack, CareerLadderTrackPositionType } from "types/graphql"
import { AddJobDefinitionItem } from "v2/react/components/careerLadders/Builder/AddJobDefinitionItem"
import { useCareerLaddersDestroyCareerLadderTrackMutation } from "v2/redux/GraphqlApi/CareerLaddersApi"
import { prepareIconClass } from "v2/react/utils/misc"

import { JobDefinitionForTrack } from "./JobDefinitionForTrack"
import { TracksConnector } from "./Connectors"

type JobDefinitionsForTrackProps = {
  activePositionTypeId?: string
  canSplitTrack?: boolean
  careerLadder: CareerLadder
  editMode: boolean
  positionAsLink?: boolean
  smallMode?: boolean
  track: CareerLadderTrack
}

function JobDefinitionsForTrack({
  activePositionTypeId,
  careerLadder,
  editMode,
  track,
  canSplitTrack = false,
  positionAsLink = false,
  smallMode = false,
}: JobDefinitionsForTrackProps) {
  const childTracks = (careerLadder.careerLadderTracks || []).filter(
    (t: CareerLadderTrack) => t.parentTrackId === track.id,
  )
  const { t } = useTranslation()
  const [mutate] = useCareerLaddersDestroyCareerLadderTrackMutation()

  const onRemoveTrack = (careerLadderTrackId: string) => {
    mutate({
      careerLadderTrackId,
      careerLadderId: careerLadder.uniqueKey,
    })
  }

  // At the root level, end the recursion and render the single parent track.
  if (!childTracks || childTracks.length === 0) {
    return (
      <>
        {editMode && (
          <AddJobDefinitionItem
            careerLadderId={careerLadder.uniqueKey || ""}
            isFocused={isLastTrack(track, careerLadder)}
            key={track.id}
            sortOrder={maxSortOrder(track) + 1}
            trackCount={(careerLadder.careerLadderTracks || []).length}
            trackId={track.uniqueKey || ""}
            trackLength={(track.careerLadderTrackPositionTypes || []).length}
          />
        )}
        <JobDefinitionItems
          activePositionTypeId={activePositionTypeId}
          canSplitTrack={canSplitTrack}
          careerLadderUniqueKey={careerLadder.uniqueKey || ""}
          editMode={editMode}
          positionAsLink={positionAsLink}
          positionTypes={track.careerLadderTrackPositionTypes || []}
          smallMode={smallMode}
          track={track}
        />
      </>
    )
  }

  // Render each child of the parent track.
  return (
    <>
      <div className={classNames("gap-6 grid", `grid-cols-${childTracks.length}`)}>
        {childTracks.map((track: CareerLadderTrack, index: number) => (
          <div className="items-end flex" key={track.id}>
            <div className="flex-grow flex-col flex">
              <JobDefinitionsForTrack
                activePositionTypeId={activePositionTypeId}
                careerLadder={careerLadder}
                editMode={editMode}
                positionAsLink={positionAsLink}
                smallMode={smallMode}
                track={track}
              />
              <div className="relative justify-center flex">
                <div className="group absolute inset-0 top-0 z-10 min-h-[24px] min-w-[140px] items-center justify-center flex">
                  <div className="w-full grid-cols-2 gap-6 grid">
                    <div
                      className={classNames("items-center justify-center flex", {
                        "col-start-2 col-end-3 ": index === 0,
                      })}
                    >
                      {editMode ? (
                        <button
                          type="button"
                          className={classNames(
                            "link mt-5 h-4 bg-white text-xs text-status-critical opacity-0 transition-all duration-200 ease-in-out active:text-status-critical group-hover:opacity-100",
                            { "left-6": index === 1 },
                            { "right-6": index === 0 },
                          )}
                          onClick={() => onRemoveTrack(track.uniqueKey)}
                        >
                          <FontAwesomeIcon
                            icon={prepareIconClass("far trash-alt")}
                            className="fa-lg mr-1"
                          />
                          {t("v2.career_ladders.builder.delete_track")}
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <TracksConnector tracks={childTracks} />
      <JobDefinitionItems
        activePositionTypeId={activePositionTypeId}
        canSplitTrack={canSplitTrack}
        careerLadderUniqueKey={careerLadder.uniqueKey || ""}
        editMode={editMode}
        positionAsLink={positionAsLink}
        positionTypes={track.careerLadderTrackPositionTypes || []}
        smallMode={smallMode}
        track={track}
      />
    </>
  )
}

const maxSortOrder = (track: CareerLadderTrack) => {
  const ladderPositionTypes: CareerLadderTrackPositionType[] =
    track?.careerLadderTrackPositionTypes || []

  return ladderPositionTypes.reduce(
    (max: number, cltpt: CareerLadderTrackPositionType) =>
      cltpt.sortOrder && cltpt.sortOrder > max ? cltpt.sortOrder : max,
    0,
  )
}

const isLastTrack = (track: CareerLadderTrack, careerLadder: CareerLadder): boolean => {
  const tracks: CareerLadderTrack[] = careerLadder?.careerLadderTracks || []

  if (tracks.length <= 1) return true

  // Presently, we only support one level of splitting.
  // Assumption: In the absence of a sort order, for the purposes of focusing
  // a track, we can use the id sequence to determine the track to focus.
  const childTracks: CareerLadderTrack[] = tracks
    .filter((t: CareerLadderTrack) => t.parentTrackId)
    .sort((a: CareerLadderTrack, b: CareerLadderTrack) => {
      const firstId: number = Number(a.id) || 0
      const secondId: number = Number(b.id) || 0
      return firstId - secondId
    })

  if (childTracks.length < 1) return false
  const lastTrack = childTracks[childTracks.length - 1]

  return lastTrack.id === track.id
}

type JobDefinitionItemsProps = {
  activePositionTypeId?: string
  canSplitTrack?: boolean
  careerLadderUniqueKey: string
  editMode: boolean
  positionAsLink?: boolean
  positionTypes: CareerLadderTrackPositionType[]
  smallMode?: boolean
  track: CareerLadderTrack
}
const JobDefinitionItems = ({
  activePositionTypeId,
  canSplitTrack,
  careerLadderUniqueKey,
  editMode,
  positionAsLink,
  positionTypes,
  smallMode,
  track,
}: JobDefinitionItemsProps) => {
  const inRootTrack = !track.parentTrackId
  const onlyOneJobDefinitionInTrack = track?.careerLadderTrackPositionTypes?.length === 1
  return [
    (positionTypes || [])
      .slice()
      .sort(sortPositionTypes)
      .map((cltpt: CareerLadderTrackPositionType, index: number) => (
        <JobDefinitionForTrack
          activePositionTypeId={activePositionTypeId}
          editMode={editMode}
          canDestroy={!inRootTrack || !onlyOneJobDefinitionInTrack}
          key={cltpt?.positionType?.id}
          positionAsLink={positionAsLink}
          positionType={cltpt?.positionType}
          showConnector={index !== (track?.careerLadderTrackPositionTypes || []).length - 1}
          smallMode={smallMode}
          canSplitTrack={canSplitTrack}
          showSplitTrackAbove={index === 0 && canSplitTrack}
          careerLadderId={careerLadderUniqueKey}
          trackId={track.uniqueKey}
          trackPositionType={cltpt}
        />
      )),
  ]
}

const sortPositionTypes = (
  a: CareerLadderTrackPositionType,
  b: CareerLadderTrackPositionType,
): number => (b.sortOrder || 0) - (a.sortOrder || 0)

export { JobDefinitionsForTrack }
