import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { map } from "lodash"
import React, { MouseEvent } from "react"
import { useTranslation } from "react-i18next"
import { ChartSectionRow } from "v2/react/components/orgChart/SuperPanel/ChartSectionsTab/ChartSectionRow"
import ChartSectionsTabGuide from "v2/react/components/orgChart/SuperPanel/ChartSectionsTab/ChartSectionsTabGuide"
import ClosePanelButton from "v2/react/components/orgChart/SuperPanel/ClosePanelButton"
import { Toggle, ToggleButton, ToggleLabel, ToggleSwitch } from "v2/react/shared/Toggle"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"
import {
  selectCanManageChart,
  selectIsLimitedAdminUsingOfficialChart,
} from "v2/redux/slices/AbilitySlice/abilitySelectors"
import { chartSectionSelectors, collapseOrExpandChartSection } from "v2/redux/slices/ContainerSlice"
import {
  selectChartSectionsTree,
  selectIsChartSectionCollapsed,
} from "v2/redux/slices/ContainerSlice/containerSelectors"
import { asyncPatchPreferences } from "v2/redux/slices/ContainerSlice/containerThunks"
import { asyncToggleChartSectionFilter } from "v2/redux/slices/GridSlice/gridThunks"
import { selectShowChartSectionColors } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

function ChartSectionsTab() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const canManageChart = useAppSelector(selectCanManageChart)
  const isLimitedAdminUsingOfficialChart = useAppSelector(selectIsLimitedAdminUsingOfficialChart)

  const displayMode = useAppSelector((state) => state.visualization.displayMode)
  const enableChartSections = useAppSelector(selectShowChartSectionColors)
  const chartSectionsTree = useAppSelector(selectChartSectionsTree)
  const chartSectionsTotal = useAppSelector((state) => chartSectionSelectors.selectTotal(state))
  const isChartSectionCompanyRootCollapsed = useAppSelector((state) =>
    selectIsChartSectionCollapsed(state, "0"),
  )
  const filters = useAppSelector((state) => state.grid.filters)

  const collapseOrExpandRoot = () => dispatch(collapseOrExpandChartSection({ id: "0" }))

  const createNewChartSection = (event: MouseEvent) => {
    event.preventDefault()
    pjaxModalFor(UrlHelper.chartSectionNewPath())
    window.$.pjax.xhr = null
  }

  const handleCompanyClick = () => {
    dispatch(asyncToggleChartSectionFilter("", ""))
  }

  const toggleChartSections = (checked: boolean) =>
    dispatch(asyncPatchPreferences({ enable_chart_sections: checked }))

  return (
    <div id="tab-chart-sections" className="drawer-contents panel grid-rows-[auto_1fr] grid">
      <div className="drawer-header">
        <div className="items-center gap-2 flex">
          <p className="drawer-title">{t("v2.orgchart.utilitynav.chart_sections")}</p>

          <div className="tooltip tooltip-left tooltip-wrap inline-block">
            <FontAwesomeIcon icon={["far", "info-circle"]} />
            <div className="tooltip-content w-40">
              {"Chart sections include a top position and all subordinate positions.".t(
                "org_chart",
              )}
            </div>
          </div>
        </div>
        <ClosePanelButton />
      </div>

      <div
        className={cn("drawer-section-content chart-section-section p-3", {
          "colors-on":
            canManageChart || isLimitedAdminUsingOfficialChart ? enableChartSections : false,
        })}
      >
        <ChartSectionsTabGuide chartSectionsTotal={chartSectionsTotal} />
        {(canManageChart || isLimitedAdminUsingOfficialChart) &&
          chartSectionsTotal > 0 &&
          displayMode !== "grid" && (
            <Toggle
              id="enable_chart_sections"
              initialValue={enableChartSections}
              onToggle={toggleChartSections}
            >
              <ToggleButton classes="flex items-center gap-1.5 mb-4 toggle-sections">
                <ToggleSwitch />
                <ToggleLabel
                  disabledLabel={t("v2.orgchart.super_panel.show_chart_section_colors")}
                  enabledLabel={t("v2.orgchart.super_panel.show_chart_section_colors")}
                />
              </ToggleButton>
            </Toggle>
          )}
        {chartSectionsTotal > 0 && (
          <>
            <div className="mb-1 items-center justify-between flex">
              <p className="text-base-bold">{t("v2.orgchart.super_panel.sections")}</p>
              {canManageChart && (
                <button
                  type="button"
                  className={cn("add btn--sm btn--primary tooltip tooltip-right", {
                    disabled: window.gon.editing_disabled,
                  })}
                  disabled={window.gon.editing_disabled}
                  onClick={createNewChartSection}
                >
                  <FontAwesomeIcon icon={["far", "plus"]} className="fa-w-14" />
                  <div className="tooltip-content tooltip-content--sm">
                    {t("v2.orgchart.super_panel.add_chart_section")}
                  </div>
                </button>
              )}
            </div>
            <ul>
              <li className={cn({ collapsed: isChartSectionCompanyRootCollapsed })}>
                <div
                  className={cn("company-wrapper mb-2 h-8 w-full rounded rounded-r-lg", {
                    "bg-neutral-3": filters.length === 0,
                  })}
                >
                  <div className="expand-collapse">
                    <div className="section-content">
                      <button
                        type="button"
                        className="expand h-3 w-3 rounded-lg p-0"
                        onClick={collapseOrExpandRoot}
                      >
                        <FontAwesomeIcon icon="caret-right" className="icon-regular" />
                      </button>
                      <button
                        type="button"
                        className="collapse h-3 w-3 rounded-lg p-0"
                        onClick={collapseOrExpandRoot}
                      >
                        <FontAwesomeIcon icon="caret-down" className="icon-regular" />
                      </button>
                    </div>
                  </div>

                  <button
                    className="company-name w-full bg-transparent text-start leading-8"
                    onClick={handleCompanyClick}
                    type="button"
                  >
                    {window.gon.company_name}
                  </button>
                </div>
                <ul
                  data-container="chart_sections"
                  className={cn({ hidden: isChartSectionCompanyRootCollapsed })}
                >
                  {map(chartSectionsTree, (chartSection) => (
                    <ChartSectionRow
                      key={`chart-section-row-${chartSection.id}`}
                      chartSection={chartSection}
                      editingDisabled={window.gon.editing_disabled}
                      hasAccessToEdit={canManageChart}
                    />
                  ))}
                </ul>
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  )
}

export { ChartSectionsTab }
