import pluralize from "pluralize"
import { STATIC_OPTIONS, config as widgetConfig } from "v2/react/components/dashboard/config"
import type { Collection, FilterAttributes } from "types/graphql"
import { FilterType } from "types/graphql.enums"
import type { Widget } from "v2/react/components/dashboard/types"

const getFiltersForDrillDown = (name: Widget, filter?: FilterAttributes): FilterAttributes[] => {
  if (filter)
    return [
      ...widgetConfig[name].baseFilters,
      { type: FilterType.Inclusion, field: filter.field, value: filter.value },
    ]
  return widgetConfig[name].baseFilters
}

const getPlaceholderLabel = (entry: Collection) => {
  const fieldKey = entry.name

  const translationKey = `${fieldKey}_placeholder`
  let translatedLabel

  if (STATIC_OPTIONS.includes(fieldKey)) {
    translatedLabel = translationKey.t("options")
  } else {
    translatedLabel = "generic_placeholder".t("options", null, null, null, [pluralize(entry.label)])
  }

  return translatedLabel
}

export { getFiltersForDrillDown, getPlaceholderLabel }
