import React from "react"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"

type BusyStateProps = {
  contentOnly?: boolean
  onReviewChangesClick: () => void
  percent: number
}

export function BusyState({ contentOnly, onReviewChangesClick, percent }: BusyStateProps) {
  const { t } = useTranslation()

  return (
    <>
      <div className={cn({ "p-6": !contentOnly, "p-0 pb-4": contentOnly })}>
        <div className={cn("alert alert-pending", { "min-w-[312px]": contentOnly })}>
          <div className="flex">
            <div className="mr-2 w-4">
              <FontAwesomeIcon icon={["far", "sync-alt"]} size="1x" />
            </div>
            <div className="flex-1">
              <h3>{t("v2.adp.change_requests.sync_changes_busy_header", { percent })}</h3>
              <p>{t("v2.adp.change_requests.sync_changes_busy_overview")}</p>
            </div>
          </div>
        </div>
      </div>
      <hr className={cn("my-0", { "mx-[-1rem]": contentOnly })} />
      <div
        className={cn("justify-end gap-2 whitespace-normal py-3 text-left flex", {
          "px-6": !contentOnly,
        })}
      >
        <button className="btn btn--secondary" onClick={onReviewChangesClick} type="button">
          {t("v2.defaults.review_changes")}
        </button>
      </div>
      {contentOnly && <hr className="mx-[-1rem] mb-4 mt-0" />}
    </>
  )
}
