import React from "react"
import { AlertBanner } from "v2/react/shared/AlertBanner"

type Props = {
  positionsApproved: number
}

export function FinalizedBanner({ positionsApproved }: Props) {
  return (
    <AlertBanner
      type="success"
      messages={[
        {
          text: "plan_notification_finalized".t("headcount_plan", null, "1", positionsApproved, [
            positionsApproved,
          ]),
          icon: <i className="far fa-check-circle h-4 w-4 text-status-success" />,
        },
      ]}
    />
  )
}
