import React from "react"
import cn from "classnames"
import { autoUpdate, flip, FloatingPortal, offset, size, useFloating } from "@floating-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useBoolean, useEventListener } from "usehooks-ts"
import { useTranslation } from "react-i18next"

type TrackJobDefinitionMenuProps = {
  canDestroy: boolean
  onDestroyPress: React.MouseEventHandler
  onReplacePress: React.MouseEventHandler
  viewHref: string
}

function TrackJobDefinitionMenu({
  canDestroy,
  onDestroyPress,
  onReplacePress,
  viewHref,
}: TrackJobDefinitionMenuProps) {
  const { closeMenu, floatingStyles, isMenuOpen, refs, toggleMenu } = useFloatingMenu()
  const { t } = useTranslation()

  useEventListener("click", (ev) => {
    const { current: dropdownMenu } = refs.floating
    const { current: toggle } = refs.reference

    const onHTMLElement = ev.target && ev.target instanceof HTMLElement
    if (onHTMLElement && dropdownMenu?.contains(ev.target)) return
    if (onHTMLElement && toggle && "contains" in toggle && toggle.contains(ev.target)) return

    closeMenu()
  })

  return (
    <>
      <div className="absolute right-[4px] top-[4px]" ref={refs.setReference}>
        <button className="btn btn--ghost btn--sm h-5 w-5 p-0" onClick={toggleMenu} type="button">
          <FontAwesomeIcon icon={["far", "ellipsis-h"]} size="sm" pointerEvents="none" />
        </button>
      </div>
      {isMenuOpen ? (
        <FloatingPortal>
          <div
            className="dropdown-menu z-initial z-[11] mt-0 !block"
            ref={refs.setFloating}
            style={floatingStyles}
          >
            <a className="dropdown-menu-link min-w-[initial]" href={viewHref}>
              <div className="items-center gap-2 flex">
                <FontAwesomeIcon icon={["far", "eye"]} size="sm" />
                {t("v2.career_ladders.builder.track_position_type_menu.view")}
              </div>
            </a>
            <button
              className="dropdown-menu-link min-w-[initial]"
              onPointerDown={onReplacePress}
              type="button"
            >
              <div className="items-center gap-2 flex">
                <FontAwesomeIcon icon={["far", "pen"]} size="sm" />
                {t("v2.career_ladders.builder.track_position_type_menu.replace")}
              </div>
            </button>
            <hr className="mx-[-0.5rem] my-2" />
            <button
              className={cn(
                "dropdown-menu-link min-w-[initial] text-status-critical",
                !canDestroy && "disabled",
              )}
              disabled={!canDestroy}
              onClick={onDestroyPress}
              type="button"
            >
              <div className="items-center gap-2 flex">
                <FontAwesomeIcon icon={["far", "trash-alt"]} size="sm" />
                {t("v2.career_ladders.builder.track_position_type_menu.destroy")}
              </div>
            </button>
          </div>
        </FloatingPortal>
      ) : null}
    </>
  )
}

function useFloatingMenu() {
  const { isMenuOpen, setMenuOpen, ...stateAndActions } = useFloatingMenuState()

  const { floatingStyles, refs } = useFloating<HTMLDivElement>({
    placement: "bottom-end",
    whileElementsMounted: autoUpdate,
    open: isMenuOpen,
    onOpenChange: setMenuOpen,
    middleware: [
      flip({ padding: 10 }),
      offset(4),
      size({
        apply({ availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: "160px",
            maxHeight: `${availableHeight}px`,
          })
        },
      }),
    ],
  })

  return { ...stateAndActions, floatingStyles, isMenuOpen, refs, setMenuOpen }
}

function useFloatingMenuState() {
  const {
    setFalse: closeMenu,
    setTrue: openMenu,
    setValue: setMenuOpen,
    toggle: toggleMenu,
    value: isMenuOpen,
  } = useBoolean()

  return { closeMenu, openMenu, setMenuOpen, toggleMenu, isMenuOpen }
}

export { TrackJobDefinitionMenu }
