import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { MouseEvent, useState } from "react"
import { AddPositionsToChartInput } from "types/graphql"
import { FormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/FormShape"
import { GenericFormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/GenericFormShape"
import { PosigenFormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/PosigenFormShape"
import { Modal, useModalOverlayRef } from "v2/react/shared/Modal"
import { useAddPositionsToChartMutation } from "v2/redux/GraphqlApi/JobRequisitionsApi"
import { StoreProvider } from "v2/redux/StoreProvider"
import { GenericModal } from "./GenericModal"
import { PosigenModal } from "./PosigenModal"

interface Props {
  data: FormShape
  jobRequisitionStatus: string
  jobRequisitionId: string
  jsonFormSchemaType: string
  btnClass?: string
}

function CreatePositionInner({
  btnClass,
  data,
  jobRequisitionStatus,
  jobRequisitionId,
  jsonFormSchemaType,
}: Props) {
  const { modalRef } = useModalOverlayRef()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [mutate, { isLoading: isSaving }] = useAddPositionsToChartMutation()
  const positionCount = data.sourceOpenings
  const [errorMessage, setErrorMessage] = useState(null)

  const handleButtonClick = (event: MouseEvent) => {
    event.preventDefault()
    setModalIsOpen(true)
  }

  const handleSave = async () => {
    setErrorMessage(null)
    const input: AddPositionsToChartInput = {
      jobRequisitionId: `job_requisition_${jobRequisitionId}`,
    }

    const result = await mutate({ input }).unwrap()

    if (!result.addPositionsToChart || result.addPositionsToChart.errors.length > 0) {
      setErrorMessage(result.addPositionsToChart?.errors[0]?.message)
    } else {
      closeModal()
      if (document.getElementById("job-requisitions-list-table")) {
        window.location.reload()
      }
    }
  }

  const closeModal = () => {
    setErrorMessage(null)
    setModalIsOpen(false)
  }

  return (
    <>
      <button
        type="button"
        className={btnClass || "btn--large btn--primary w-fit flex sm:mr-2"}
        onClick={handleButtonClick}
        disabled={jobRequisitionStatus === "closed"}
      >
        <FontAwesomeIcon icon={["far", "user-plus"]} />
        {"create_position".t("job_requisition", null, "Create Position", positionCount)}
      </button>
      <Modal
        footer={Buttons({ handleSave, isSaving, positionCount })}
        isOpen={modalIsOpen}
        onClose={closeModal}
        size="md"
        title={"add_positions".t("job_requisition", null, "Add Position(s)", positionCount, [
          positionCount,
        ])}
        overlayRef={modalRef}
      >
        <div className="p-6">
          {errorMessage && <div className="alert alert-critical mb-4">{errorMessage}</div>}
          <p className="mb-6">
            {"are_you_sure_create_positions".t(
              "job_requisition",
              null,
              "Are you sure?",
              positionCount,
              [positionCount],
            )}
          </p>
          {jsonFormSchemaType === "posigen" ? (
            <PosigenModal data={data as PosigenFormShape} />
          ) : (
            <GenericModal data={data as GenericFormShape} />
          )}
        </div>
      </Modal>
    </>
  )
}

const Buttons = ({
  handleSave,
  isSaving,
  positionCount,
}: {
  handleSave: React.MouseEventHandler<HTMLButtonElement>
  isSaving: boolean
  positionCount: number
}) => {
  const buttonTextKey = isSaving ? "creating_position" : "create_position"
  const clickHandler = isSaving ? undefined : handleSave

  return (
    <button
      type="submit"
      className={classNames("btn--large btn--primary", { isSaving: "btn-disabled" })}
      onClick={clickHandler}
    >
      <FontAwesomeIcon icon={["far", "user-plus"]} />
      {buttonTextKey.t("job_requisition", null, "Create Position", positionCount)}
    </button>
  )
}

const CreatePosition = ({
  btnClass,
  data,
  jobRequisitionStatus,
  jobRequisitionId,
  jsonFormSchemaType,
}: Props) => (
  <StoreProvider>
    <CreatePositionInner
      btnClass={btnClass}
      data={data}
      jobRequisitionStatus={jobRequisitionStatus}
      jobRequisitionId={jobRequisitionId}
      jsonFormSchemaType={jsonFormSchemaType}
    />
  </StoreProvider>
)

export { CreatePosition }
