import React, { useState } from "react"

import { Person, SaveRecruitingSettingsInput } from "types/graphql"
import {
  useGetRecruitingSettingsQuery,
  useSaveRecruitingSettingsMutation,
} from "v2/redux/GraphqlApi/JobRequisitionsApi"
import { Modal, ModalFooter } from "v2/react/shared/Modal"
import { StoreProvider } from "v2/redux/StoreProvider"
import { personNameAsc } from "v2/react/utils/sorts"

import { RecruitingSettingPersonType, SettingsForm } from "./SettingsForm"

function SettingsInner() {
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  const { data: settingsData, isLoading } = useGetRecruitingSettingsQuery({})
  const [approvers, setApprovers] = useState<Person[] | null>(null)
  const [notificationRecipients, setNotificationRecipients] = useState<Person[] | null>(null)
  const [mutate] = useSaveRecruitingSettingsMutation()

  if (isLoading) return null

  const existingApprovers: Person[] =
    settingsData.currentCompany?.settings?.recruiting?.defaultJobRequisitionApprovers || []
  const existingNotificationRecipients: Person[] = (
    settingsData.currentCompany?.settings?.recruiting
      ?.defaultJobRequisitionNotificationRecipients || []
  )
    .slice()
    .sort(personNameAsc)

  const selectedApprovers: Person[] = approvers || existingApprovers || []
  const selectedNotificationRecipients: Person[] =
    notificationRecipients || existingNotificationRecipients || []

  const cancelModal = () => {
    setApprovers(null)
    setNotificationRecipients(null)
    setEditModalIsOpen(false)
  }

  const closeModal = () => {
    setEditModalIsOpen(false)
  }

  const handleEditButtonClick = () => setEditModalIsOpen(true)

  const onPeopleUpdated = (selectedPeople: Person[], type: RecruitingSettingPersonType) => {
    if (type === "approver") {
      setApprovers(selectedPeople)
      return
    }

    setNotificationRecipients(selectedPeople)
  }

  const handleSave = async () => {
    const input: SaveRecruitingSettingsInput = {
      defaultJobRequisitionApprovers: selectedApprovers.map((p) => ({
        id: p.id,
        sortOrder: p.sortOrder || 0,
      })),
      defaultJobRequisitionNotificationRecipients: selectedNotificationRecipients.map((p) => p.id),
    }

    const result = await mutate({ input }).unwrap()

    if (result.saveRecruitingSettings && result.saveRecruitingSettings.errors.length === 0) {
      setApprovers(selectedApprovers)
      setNotificationRecipients(selectedNotificationRecipients)
      closeModal()
    } else {
      // eslint-disable-next-line no-console
      console.error(result.saveRecruitingSettings?.errors)
      closeModal()
    }
  }

  return (
    <>
      <div className="module-card">
        <div className="module-card__header items-center justify-between flex">
          <div className="text-base-bold">{"settings_title".t("job_requisition")}</div>
          <button
            type="button"
            className="module-action company-settings-org-chart-defaults-action"
            onClick={handleEditButtonClick}
          >
            {"Edit".t("defaults")}
          </button>
        </div>
        <div className="module-card__body">
          <div>
            <div>{"settings_default_approvers_title".t("job_requisition")}</div>
            <div>
              {existingApprovers.map((person) => (
                <div className="job-requisition-settings-list-item-person" key={person.id}>
                  {person.name}
                </div>
              ))}
            </div>
          </div>
          <div>
            <div>{"settings_default_notification_recipients_title".t("job_requisition")}</div>
            <div>
              {existingNotificationRecipients.map((person) => (
                <div className="job-requisition-settings-list-item-person" key={person.id}>
                  {person.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal
        footer={
          <ModalFooter
            disabled={isLoading}
            onClose={closeModal}
            onSave={handleSave}
            saveButtonText={
              isLoading ? "save_disabled".t("button_defaults") : "save_enabled".t("button_defaults")
            }
          />
        }
        isOpen={editModalIsOpen}
        onClose={cancelModal}
        title={"settings_title".t("job_requisition")}
      >
        <SettingsForm
          approvers={selectedApprovers}
          onPeopleUpdated={onPeopleUpdated}
          notificationRecipients={selectedNotificationRecipients}
        />
      </Modal>
    </>
  )
}

const Settings = () => (
  <StoreProvider>
    <SettingsInner />
  </StoreProvider>
)

export { Settings }
