import { CursorConnection } from "v2/react/shared/Datasheet/types"

export const CELL_TOOLTIP_DELAY_MS = 350
export const CELL_TOOLTIP_CLOSE_DELAY_MS = 250
export const ROW_HEIGHT_PX = 56
export const MIN_COL_WIDTH = 120
export const MAX_COL_WIDTH = 320
export const TABLE_BORDER = 2

const throwNotImplemented = () => {
  throw new Error("Fallback cursorConnection does not support any functionality")
}

export const fallbackCursorConnection: CursorConnection = {
  cellInputRef: null,
  inCursor: false,
  initialWriteValue: undefined,
  inWrite: false,
  keyDownListenerWhileWriting: throwNotImplemented,
  keyUpListenerWhileWriting: throwNotImplemented,
  requestCursorTransition: throwNotImplemented,
  saveWrite: throwNotImplemented,
  stopWriting: throwNotImplemented,
}

export function isMaxWidthColumn(fieldKey: string) {
  return (
    [
      "business_unit",
      "cost_number",
      "department",
      "filled_by_name",
      "funding_sources",
      "job_family",
      "justification",
      "location",
      "name",
      "reports_to",
      "title",
    ].includes(fieldKey) || fieldKey.startsWith("org_unit_type")
  )
}
