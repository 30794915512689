import fp from "lodash/fp"
import { createSelector } from "reselect"

import type { NodeInterface } from "types/graphql.d"
import type { ReduxState } from "v2/redux/types"

import { makePredicate } from "v2/redux/slices/GridSlice/gridHelpers/makePredicate"

/**
 * @public
 * @returns {array} an array of field keys used for grouping in sorted order.
 */
const selectGroupFieldKeys = (state: ReduxState) => state.grid.groupFieldKeys

/**
 * @public
 * @returns {array} an array of filter hashes where each hash holds a field key and term.
 */
const selectFilters = (state: ReduxState) => state.grid.filters

const withFieldKey = (_1: unknown, fieldKey: keyof NodeInterface) => fieldKey
const withTerm = (_1: unknown, _2: unknown, term: string) => term

const selectIsFilterActive = createSelector(
  [selectFilters, withFieldKey, withTerm],
  (filters, fieldKey, term) =>
    !!fp.find((filter) => filter.fieldKey === fieldKey && filter.term === term, filters),
)

/**
 * @public
 * @returns {"all" | "open" | "filled"} a value indicating which positions to show by status.
 */
const selectPositionStatusFilterOption = (state: ReduxState) =>
  state.visualization.gridPositionFilter

/**
 * @public
 * @returns {array} an array of field keys actively filtering data in the spreadsheet.
 */
const selectFieldKeysWithActiveFilters = createSelector(selectFilters, (filters) =>
  fp.map(fp.prop("fieldKey"), filters),
)

/**
 * @public
 * @returns {function} a function accepting a node and returning its position status.
 */
const selectPositionStatusFilter = createSelector(
  selectPositionStatusFilterOption,
  (option) =>
    ({ name }: NodeInterface) => {
      switch (option) {
        case "filled":
          return !!name
        case "open":
          return !name
        default:
          return true
      }
    },
)

/**
 * @public
 * @returns {function} a function accepting a node array and returning those matching all filters.
 */
const selectNodeFilterFunc = createSelector(
  selectFilters,
  selectPositionStatusFilter,
  (filters, positionStatusFilter) => {
    const predicates = fp.map(makePredicate, filters)
    const whereAllPredicatesPass = fp.allPass([...predicates, positionStatusFilter])

    return (nodes: NodeInterface[]) => fp.filter(whereAllPredicatesPass, nodes)
  },
)

export {
  selectFieldKeysWithActiveFilters,
  selectFilters,
  selectGroupFieldKeys,
  selectIsFilterActive,
  selectNodeFilterFunc,
  selectPositionStatusFilter,
}
