interface Props {
  isOwner: boolean
  active: string
  headcountPlanId: string
  participantId?: string
}

export function getNavLinks({ isOwner, active, headcountPlanId, participantId }: Props) {
  if (isOwner) {
    return [
      {
        text: "Overview",
        url: `/headcount_plans/${headcountPlanId}/overview`,
        active: active === "Overview",
        show: true,
      },
      {
        text: "Datasheet",
        url: `/headcount_plans/${headcountPlanId}/owner`,
        active: active === "Datasheet",
        show: true,
      },
      {
        text: "Timeline",
        url: `/headcount_plans/${headcountPlanId}/owner/timeline`,
        active: active === "Timeline",
        show: true,
      },
    ]
  }
  if (participantId) {
    return [
      {
        text: "Datasheet",
        url: `/headcount_plans/${headcountPlanId}/participants/${participantId}`,
        active: active === "Datasheet",
        show: true,
      },
      {
        text: "Timeline",
        url: `/headcount_plans/${headcountPlanId}/participants/${participantId}/timeline`,
        active: active === "Timeline",
        show: true,
      },
    ]
  }

  return []
}
