import React from "react"
import classNames from "classnames"
import { CellContext } from "@tanstack/react-table"

export type MinStylingCellProps = React.PropsWithChildren<{
  alignment?: "center" | "end" | "start"
}>

const ALIGNMENT_CLASSES = Object.freeze({
  end: "text-end",
  start: "text-start",
  center: "text-center",
})

export function MinStylingCell({ alignment, children }: MinStylingCellProps) {
  const cellClass = classNames(alignment && ALIGNMENT_CLASSES[alignment])

  return <div className={cellClass}>{children}</div>
}

MinStylingCell.prepare = function (props: Omit<MinStylingCellProps, "children">) {
  /* eslint-disable react/jsx-props-no-spreading */
  const PreparedMinStylingCell = <S, T extends React.ReactNode>({
    renderValue,
  }: CellContext<S, T>) => <MinStylingCell {...props}>{renderValue()}</MinStylingCell>
  /* eslint-enable react/jsx-props-no-spreading */

  return PreparedMinStylingCell
}
