import React, { ReactNode, useLayoutEffect, useState } from "react"
import cn from "classnames"
import { useObserveElement } from "v2/react/hooks/useObserveElement"

interface Props {
  children: ReactNode
}

function PageNav({ children }: Props) {
  const { width, ref } = useObserveElement<HTMLDivElement>()
  const { height, ref: barRef } = useObserveElement<HTMLDivElement>()
  const [hasAlertTop, setHasAlertTop] = useState(false)

  useLayoutEffect(() => {
    // Until SSR is wired up, we'll inevitably render after the initial page
    // load, thereby shifting page content down.
    //
    // Since reducing these layout shifts is an important factor in providing a
    // good user experience, the server may include placeholder elements in the
    // page that reserve the space which we'll ultimately use.
    //
    // The following removes these placeholders now that we're rendering. Once
    // SSR is wired up, we should remove this.
    //
    // See: https://web.dev/articles/cls
    document
      .querySelectorAll("[data-nav-placeholder-removed-by-react-page-nav]")
      .forEach((el) => el.remove())

    const alertTop = document.querySelector<HTMLDivElement>(".alert-top")
    setHasAlertTop(Boolean(alertTop))

    // If `useObserveElement` hasn't had a chance to measure the ref yet, then
    // width will be zero. We have access to the initial width, however, via the
    // ref. Fall back to measuring the ref until `useObserveElement` begins
    // reporting a non-zero width.
    if (width === 0) {
      if (ref.current?.offsetWidth && alertTop) {
        alertTop.style.width = `${ref.current?.offsetWidth}px`
        alertTop.style.marginLeft = "initial"
      }
    }

    if (width && alertTop) {
      alertTop.style.width = `${width}px`
      alertTop.style.marginLeft = "initial"
    }
  }, [ref, width])

  /**
   * These two divs are linked together.
   * The ref div is positioned within the main content, so it will give an accurate read on
   * the width to use for the page nav. The page nav div will either wrap into one or two rows
   * depending on whether there are links in the nav. We can use the height of this div
   * to set the height of the ref div giving us the proper offset needed for the page content that sits below.
   */

  return (
    <>
      <div
        className={cn("page-nav zPageNav no-print", { "top-[50px]": hasAlertTop })}
        style={{ width }}
        ref={barRef}
      >
        {children}
      </div>
      <div
        style={{ height }}
        className={cn("min-h-[56px]", { "mt-[50px]": hasAlertTop })}
        ref={ref}
      />
    </>
  )
}

export default PageNav
