import { get } from "lodash"
import { FormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/FormShape"
import { JsonSchema } from "@jsonforms/core"

const formNumericValue = (formData: FormShape, pathToValue: string[]): number => {
  const dataValue = get(formData, pathToValue)
  if (!dataValue) return 0

  const numberValue = Number(dataValue)
  if (Number.isNaN(numberValue)) return 0
  return numberValue
}

const formStringValue = (formData: FormShape, pathToValue: string[]): string => {
  const dataValue = get(formData, pathToValue)
  if (!dataValue) return ""

  return dataValue
}

/**
 * Determines if a control should be disabled based on the schema and the enabled prop.
 *
 * The enabled prop is provided to controls internally based on an evaluation order of
 * schema conditions outlined in the docs here:
 * https://jsonforms.io/docs/readonly/#evaluation-order
 *
 * The consequence of this evaluation order is that if there is an existing DISABLED
 * or ENABLED rule set, any adjustments to the JSON Schema or UI schema will not be
 * reflected in the control's enabled prop. This is unfortunate, b/c occasionally we
 * need to have multiple rules handling the disabled state of a control. There are ways
 * to adjust the schema to allow for multiple rules
 * (see: https://jsonforms.discourse.group/t/how-to-give-multiple-rule-effects-on-the-element/244)
 * but it adds complexity to the schema design. This function enables a workaround where if
 * the readOnly prop is set on the json schema, it will take that into consideration in
 * addition to what is provided through the enabled prop.
 */
const isDisabledFromSchema = ({
  enabled,
  schema,
}: {
  enabled: boolean
  schema: JsonSchema
}): boolean => !enabled || !!schema?.properties?.readOnly

export { formNumericValue, formStringValue, isDisabledFromSchema }
