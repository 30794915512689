import cn from "classnames"
import React from "react"
import { ApproverOrderedList } from "v2/react/shared/Status/ApproverList/ApproverOrderedList"
import { ApproverType } from "v2/react/shared/Status/statusUtils"

interface ApproversProps {
  approvers: ApproverType[]
  approvalState: string
  onShowUpdateModal: React.MouseEventHandler<HTMLButtonElement>
  workflowDate: Date
  workflowStatus: string | null | undefined
}

function Approvers({
  approvers,
  approvalState,
  onShowUpdateModal,
  workflowDate,
  workflowStatus,
}: ApproversProps) {
  const noEditStates = ["approved", "canceled", "closed"]
  return (
    <div className="module-card mb-0">
      <div className="module-card__header">
        <p className="mb-0 text-base-bold">{"approvers".t("job_requisition")}</p>
        {!window.gon.limited_or_nonadmin_manager && !noEditStates.includes(approvalState) && (
          <button className="btn--sm btn--secondary" onClick={onShowUpdateModal} type="button">
            {"Edit".t("defaults")}
          </button>
        )}
      </div>
      <div className={cn("module-card__body gap-0", { "border-none !p-0": approvers.length })}>
        {!approvers.length && <p>{"approvers_empty".t("job_requisition")}</p>}
        {approvers.length > 0 && (
          <ApproverOrderedList
            approvers={approvers}
            notes={approvers}
            workflowRequestDate={workflowDate}
            workflowStatus={workflowStatus}
          />
        )}
      </div>
    </div>
  )
}

export { Approvers }
