/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createApi } from "@reduxjs/toolkit/query/react"
import {
  AddOrgUnitInput,
  AddOrgUnitMutation,
  Collection,
  ColorCodesQuery,
  ColorCodesQueryVariables,
  CurrentCompanyCurrencyCodeQuery,
  CurrentCompanyCurrencyCodeQueryVariables,
  CurrentCompanyFeatureFlagsQuery,
  CurrentCompanyIntegrationFlagsQuery,
  CurrentCompanySuccessionMatrixSettingsQuery,
  CurrentPersonQuery,
  CurrentPersonQueryVariables,
  GetImportTriggerStateQuery,
  GetPersonQuery,
  GetPersonQueryVariables,
  GetPositionQuery,
  GetPositionQueryVariables,
  GetPositionDetailsQuery,
  GetPositionDetailsQueryVariables,
  ImportTriggerState,
  PeopleConnectionQuery,
  PeopleConnectionQueryVariables,
  PositionAutocompleteConnectionQuery,
  PositionAutocompleteConnectionQueryVariables,
  PositionForListQuery,
  PositionForListQueryVariables,
  PositionsUpdateMutation,
  SaveRoleInput,
  SaveRoleMutation,
  SearchExistingSubordinatePositionsQuery,
  PositionsUpdateInput,
  UpdateCompanyCurrencyCodeInput,
  UpdateCompanySuccessionMatrixSettingsInput,
  UsersDestroyInput,
  UsersDestroyMutation,
} from "types/graphql.d"
import { baseGqlQueryFn } from "v2/graphqlClient/baseGqlQueryFn"
import { flatMutationOperation, mutationOperation, queryOperation } from "v2/redux/utils/endpoints"
import OperationStore from "v2/operation_store"
import { CollectionsQueryResponse } from "./types"

export const GraphqlApi = createApi({
  reducerPath: "GraphqlApi",
  baseQuery: baseGqlQueryFn,
  tagTypes: [
    "Approvers",
    "CareerLadder",
    "Chart",
    "CompanyCurrencyCode",
    "HeadcountPlan",
    "HeadcountPlanChanges",
    "JobRequisition",
    "Matrix",
    "Node",
    "PositionType",
    "PositionTypeDetails",
    "PositionTypes",
    "RecruitingSettings",
    "Position",
    "User",
  ] as string[],
  endpoints: (builder) => ({
    addOrgUnit: builder.mutation<AddOrgUnitMutation, AddOrgUnitInput>({
      query: (input) => ({
        operationId: OperationStore.getOperationId("AddOrgUnit"),
        variables: { input },
      }),
    }),
    fetchCollections: builder.query({
      query: (query: string) => ({ query, operationName: "CurrentCompanyCollections" }),
      transformResponse: (response: CollectionsQueryResponse): Collection[] => {
        const { collections } = response.currentCompany || {}
        return Object.values(collections || {}).flat()
      },
    }),
    getColorCodes: builder.query({
      query: queryOperation<ColorCodesQueryVariables>("ColorCodes"),
      transformResponse: (response: ColorCodesQuery) => ({
        colorCodeAttributes: response.nodeContainer?.color_code_attributes || [],
        colorCodes: response.nodeContainer?.color_codes || [],
      }),
    }),
    getCurrencyCode: builder.query<
      CurrentCompanyCurrencyCodeQuery,
      CurrentCompanyCurrencyCodeQueryVariables
    >({
      query: queryOperation("CurrentCompanyCurrencyCode"),
      providesTags: ["CompanyCurrencyCode"],
    }),
    getCurrentPerson: builder.query<CurrentPersonQuery, CurrentPersonQueryVariables>({
      query: queryOperation("CurrentPerson"),
    }),
    getFeatureFlags: builder.query<CurrentCompanyFeatureFlagsQuery, void>({
      query: queryOperation("CurrentCompanyFeatureFlags"),
    }),
    getImportTriggerState: builder.query<ImportTriggerState | null, void>({
      query: queryOperation("GetImportTriggerState"),
      transformResponse: (res: GetImportTriggerStateQuery) =>
        res?.currentCompany?.importTriggerState ?? null,
    }),
    getIntegrationFlags: builder.query<CurrentCompanyIntegrationFlagsQuery, void>({
      query: queryOperation("CurrentCompanyIntegrationFlags"),
    }),
    getJsonForm: builder.query({ query: queryOperation<{ modelType: string }>("JsonForm") }),
    getPerson: builder.query<GetPersonQuery, GetPersonQueryVariables>({
      query: queryOperation<GetPersonQueryVariables>("GetPerson"),
    }),
    getPosition: builder.query<GetPositionQuery, GetPositionQueryVariables>({
      query: queryOperation<GetPositionQueryVariables>("GetPosition"),
      providesTags: (result, error, id) => [{ type: "Position", positionId: id }],
    }),
    getPositionDetails: builder.query<GetPositionDetailsQuery, GetPositionDetailsQueryVariables>({
      query: queryOperation<GetPositionDetailsQueryVariables>("GetPositionDetails"),
      providesTags: (response) =>
        response?.position?.id
          ? ["PositionDetails", `position_${response.position.id}`]
          : ["PositionDetails"],
    }),
    getPositionAutocomplete: builder.query<
      PositionAutocompleteConnectionQuery,
      PositionAutocompleteConnectionQueryVariables
    >({
      query: queryOperation<PositionAutocompleteConnectionQueryVariables>(
        "PositionAutocompleteConnection",
      ),
    }),
    getPositionForList: builder.query<PositionForListQuery, PositionForListQueryVariables>({
      query: queryOperation<PositionForListQueryVariables>("PositionForList"),
    }),
    getSuccessionMatrixSettings: builder.query({
      query: queryOperation("CurrentCompanySuccessionMatrixSettings"),
      providesTags: ["Matrix"],
      transformResponse: (response: CurrentCompanySuccessionMatrixSettingsQuery) =>
        response.currentCompany?.settings.succession.matrix,
    }),
    positionsUpdate: builder.mutation<PositionsUpdateMutation, PositionsUpdateInput>({
      query: flatMutationOperation<PositionsUpdateInput>("PositionsUpdate"),
      invalidatesTags: (_result, _rootErrors, { id }) => ["PositionDetails", id],
    }),
    saveRole: builder.mutation<SaveRoleMutation, { input: SaveRoleInput }>({
      query: mutationOperation<SaveRoleInput>("SaveRole"),
    }),
    saveSuccessionMatrixSettings: builder.mutation({
      query: mutationOperation<UpdateCompanySuccessionMatrixSettingsInput>(
        "UpdateCompanySuccessionMatrixSettings",
      ),
      invalidatesTags: ["Matrix"],
    }),
    searchPeople: builder.query<PeopleConnectionQuery, PeopleConnectionQueryVariables>({
      query: queryOperation<PeopleConnectionQueryVariables>("PeopleConnection"),
    }),
    searchSubordinatePositions: builder.query({
      query: queryOperation<{
        participantId?: string
        positionId?: string
        headcountPlanId: string
        searchTerm: string
      }>("SearchExistingSubordinatePositions"),
      transformResponse: (response: SearchExistingSubordinatePositionsQuery) =>
        response.headcountPlan?.existingPositionsInScope?.map((position) => ({
          id: position.id,
          title: position.title,
          name: position.person?.name,
          avatar: position.person?.avatarThumbUrl,
          label: position.person?.name || position.title || "",
        })),
    }),
    updateCurrencyCode: builder.mutation({
      query: mutationOperation<UpdateCompanyCurrencyCodeInput>("UpdateCompanyCurrencyCode"),
      invalidatesTags: ["CompanyCurrencyCode"],
    }),
    usersDestroy: builder.mutation<UsersDestroyMutation, UsersDestroyInput>({
      query: flatMutationOperation<UsersDestroyInput>("UsersDestroy"),
      invalidatesTags: ["User"],
    }),
  }),
})

export const {
  useAddOrgUnitMutation,
  useFetchCollectionsQuery,
  useGetColorCodesQuery,
  useGetCurrencyCodeQuery,
  useGetCurrentPersonQuery,
  useGetFeatureFlagsQuery,
  useGetImportTriggerStateQuery,
  useGetIntegrationFlagsQuery,
  useGetJsonFormQuery,
  useGetPersonQuery,
  useGetPositionAutocompleteQuery,
  useGetPositionForListQuery,
  useGetPositionQuery,
  useGetPositionDetailsQuery,
  useGetSuccessionMatrixSettingsQuery,
  useLazySearchPeopleQuery,
  usePositionsUpdateMutation,
  useSaveRoleMutation,
  useSaveSuccessionMatrixSettingsMutation,
  useSearchPeopleQuery,
  useSearchSubordinatePositionsQuery,
  useUpdateCurrencyCodeMutation,
  useUsersDestroyMutation,
} = GraphqlApi
