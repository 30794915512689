import dayjs from "dayjs"
import fp from "lodash/fp"
import { createSelector } from "reselect"
import { EntityId } from "@reduxjs/toolkit"

import {
  AdpChangeBatch,
  AdpPagedChangeBatchesQuery,
  AdpPagedChangeBatchesQueryVariables,
  AdpChangeRequest,
  AdpInteractiveChangeBatchesQuery,
  AdpProcessQueuedChangeRequestsInput,
  AdpProcessQueuedChangeRequestsMutation,
  AdpTransitionPausedChangeBatchInput,
  AdpTransitionPausedChangeBatchMutation,
  PageInfo,
} from "types/graphql"

import { AdpChangeBatchState, AdpChangeRequestState } from "types/graphql.enums"
import { flatMutationOperation, queryOperation } from "v2/redux/utils/endpoints"
import { FlattenedAdpChangeBatch } from "v2/redux/slices/AdpChangeSlice/types"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"
import {
  mapToFlattenedBatches,
  toFlattenedBatch,
} from "v2/redux/slices/AdpChangeSlice/toFlattenedBatch"

export type BatchStatePredicate = Predicate<AdpChangeBatch | string>
export type Counter<T> = (record: T) => number
export type Predicate<T> = (record: T) => boolean
export type Accessor<Result> = (record: unknown) => Result

const Tag = {
  enhancements: { addTagTypes: ["AdpChangeQueue"] },
  baseList: [{ type: "AdpChangeQueue", id: "list" }],
  interactiveBatches: [{ type: "AdpChangeQueue", id: "interactiveBatches" }],
}

type AdpChangeBatchPage = {
  pageInfo: PageInfo
  batches: FlattenedAdpChangeBatch[]
}

const AdpChangeQueueApi = GraphqlApi.enhanceEndpoints(Tag.enhancements).injectEndpoints({
  endpoints: (builder) => ({
    adpPagedChangeBatches: builder.query<AdpChangeBatchPage, AdpPagedChangeBatchesQueryVariables>({
      query: queryOperation("AdpPagedChangeBatches"),
      providesTags: Tag.baseList,
      transformResponse: (res: AdpPagedChangeBatchesQuery) => ({
        pageInfo: res.currentCompany?.pagedAdpChangeBatches.pageInfo ?? {
          hasNextPage: false,
          hasPreviousPage: false,
        },
        batches: mapToFlattenedBatches(res.currentCompany?.pagedAdpChangeBatches.nodes ?? []),
      }),
    }),
    adpInteractiveChangeBatches: builder.query<
      AdpInteractiveChangeBatchesQuery["currentCompany"],
      void
    >({
      providesTags: Tag.interactiveBatches,
      query: queryOperation("AdpInteractiveChangeBatches"),
      transformResponse: (res: AdpInteractiveChangeBatchesQuery) => res.currentCompany,
    }),
    adpProcessQueuedChangeRequests: builder.mutation<
      AdpProcessQueuedChangeRequestsMutation,
      AdpProcessQueuedChangeRequestsInput
    >({
      query: flatMutationOperation("AdpProcessQueuedChangeRequests"),
      invalidatesTags: [...Tag.baseList, ...Tag.interactiveBatches],
    }),
    adpTransitionPausedBatch: builder.mutation<
      AdpTransitionPausedChangeBatchMutation,
      AdpTransitionPausedChangeBatchInput
    >({
      query: flatMutationOperation("AdpTransitionPausedChangeBatch"),
      invalidatesTags: [...Tag.baseList, ...Tag.interactiveBatches],
    }),
  }),
})

const prettyDate = (val: string) => dayjs(val).format("MMM D, YYYY h:mm a")
export const maybePrettyDate = (val?: string | null) => (val ? prettyDate(val) : null)

export const propFormattedDate =
  (key: string | string[]) =>
  <T>(record: T) =>
    maybePrettyDate(fp.prop(key, record))
export const propIdFromUniqueKey =
  (key: string | string[]): Accessor<EntityId> =>
  <T>(record: T) =>
    fp.pipe(fp.prop(key), idFromUniqueKey)(record)
export const propSyncInitiatorName: Accessor<string | null> = fp.prop(["syncInitiator", "name"])
export const changeProp =
  (key: string) =>
  (changeRequest: AdpChangeRequest): string | null =>
    fp.prop(["change", key], changeRequest) ?? null
export const changePropFormattedDate = (key: string) => propFormattedDate(["change", key])

const makeBatchStatePredicateFor =
  (state: AdpChangeBatchState) => (value: AdpChangeBatch | string) =>
    fp.isString(value) ? value === state : value.state === state
export const isBusy = makeBatchStatePredicateFor(AdpChangeBatchState.Busy)
export const isOpen = makeBatchStatePredicateFor(AdpChangeBatchState.Open)
export const isPausedWithErrors = makeBatchStatePredicateFor(AdpChangeBatchState.PausedWithErrors)
export const isSent = makeBatchStatePredicateFor(AdpChangeBatchState.Sent)
export const isSentWithErrors = makeBatchStatePredicateFor(AdpChangeBatchState.SentWithErrors)
export const isActive: BatchStatePredicate = fp.anyPass([isBusy, isPausedWithErrors])
export const isDone: BatchStatePredicate = fp.anyPass([isSent, isSentWithErrors])

export const selectOpenAndActiveBatches = createSelector(
  AdpChangeQueueApi.endpoints.adpInteractiveChangeBatches.select(),
  ({ data }) => ({
    active: data?.adpActiveChangeBatch ? toFlattenedBatch(data?.adpActiveChangeBatch) : undefined,
    open: data?.adpOpenChangeBatch ? toFlattenedBatch(data?.adpOpenChangeBatch) : undefined,
  }),
)

const makeRequestStateFns = (state: AdpChangeRequestState) => {
  const whereFn: Predicate<AdpChangeRequest> = fp.propEq("state", state)
  const countFn: Counter<AdpChangeRequest[]> = fp.pipe(fp.filter(whereFn), fp.size)
  const countRequestsFn: Counter<AdpChangeBatch> = fp.pipe(fp.prop("changeRequests"), countFn)

  return [whereFn, countRequestsFn] as const
}

const { Discarded, Failed, FailedCopiedToNextBatch, FailedIgnored, Succeeded } =
  AdpChangeRequestState

export const didFail: Predicate<AdpChangeRequest> = fp.anyPass([
  fp.propEq("state", Failed),
  fp.propEq("state", FailedCopiedToNextBatch),
  fp.propEq("state", FailedIgnored),
])
const countFailed: Counter<AdpChangeRequest[]> = fp.pipe(fp.filter(didFail), fp.size)
export const countFailedRequests: Counter<AdpChangeBatch> = fp.pipe(
  fp.prop("changeRequests"),
  countFailed,
)

export const [didDiscard, countDiscardedRequests] = makeRequestStateFns(Discarded)
export const [didSucceed, countSuccessfulRequests] = makeRequestStateFns(Succeeded)
export const countRequests: Counter<AdpChangeBatch> = fp.pipe(fp.prop("changeRequests"), fp.size)

export const { adpInteractiveChangeBatches, adpTransitionPausedBatch } = AdpChangeQueueApi.endpoints
export const {
  useAdpProcessQueuedChangeRequestsMutation,
  useAdpTransitionPausedBatchMutation,
  useAdpPagedChangeBatchesQuery,
  useAdpInteractiveChangeBatchesQuery,
} = AdpChangeQueueApi
