import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DraggableList } from "v2/react/shared/DraggableList/DraggableList"
import { HeadcountPlanColumn } from "./types"

export function PlanColumns({
  columns,
  setColumns,
}: {
  columns: HeadcountPlanColumn[]
  setColumns: (columns: HeadcountPlanColumn[]) => void
}) {
  const filteredColumns = columns.filter((column) => !column.dependsOn)
  const ref = React.useRef<HTMLDivElement>(null)
  return (
    <div className="input-group">
      <label htmlFor="columns">{"setup_columns_label".t("headcount_plan")}</label>
      <fieldset id="columns">
        <DraggableList
          items={columns}
          dragContainerRef={ref}
          handleReorder={setColumns}
          useListGroupStyle={false}
          draggableListClasses="shadow-none p-0 z-auto list-group is-draggable"
        >
          {filteredColumns.map((column) => (
            <DraggableList.Item
              key={column.id}
              item={column}
              dragContainerRef={ref}
              itemClasses="py-1 pl-1 pr-2"
            >
              <div className="w-full gap-3 flex">
                <FontAwesomeIcon
                  icon={["fas", "grip-vertical"]}
                  size="sm"
                  className="mt-1 items-start self-stretch"
                />
                <label
                  htmlFor={column.id}
                  className="!m-0 w-full min-w-0 cursor-pointer items-start self-stretch font-medium transition-colors flex"
                >
                  <input
                    type="checkbox"
                    className="!mr-2 mt-1"
                    id={column.id}
                    name="columns[]"
                    value={column.id}
                    disabled={!column.isRemovable}
                    defaultChecked={column.isSelected}
                  />
                  <div className="w-full min-w-0 break-words">{column.name}</div>
                </label>
              </div>
            </DraggableList.Item>
          ))}
        </DraggableList>
      </fieldset>
    </div>
  )
}
