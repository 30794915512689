import React, { useCallback } from "react"
import cn from "classnames"
import { Chart } from "types/graphql"
import { approvalInProgress } from "v2/react/components/orgChart/ChartApproval/approvalUtils"
import { toggleCancelModal, toggleDropdown } from "v2/redux/slices/ApprovalSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface Props {
  chart: Chart
  size?: "sm"
}

function CancelApprovalButton({ chart, size }: Props) {
  const displayMode = useAppSelector((state) => state.visualization.displayMode)

  const dispatch = useAppDispatch()
  const closeDropdownModal = useCallback(() => dispatch(toggleDropdown(false)), [dispatch])

  const openCancelModal = () => {
    dispatch(
      toggleCancelModal({
        open: true,
        isEditType: approvalInProgress(chart?.chartApprovers || []),
      }),
    )
    closeDropdownModal()
  }

  return (
    <button
      className={cn("btn btn--secondary", {
        "btn--sm": size,
        "sm:btn--large sm:btn--secondary": displayMode === "grid",
      })}
      type="button"
      onClick={openCancelModal}
    >
      {"cancel_approval_request".t("org_chart")}
    </button>
  )
}

export default CancelApprovalButton
