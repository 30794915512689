import React from "react"
import classNames from "classnames"

type TimelineTableTdProps = {
  id?: string
  className?: string
  rowSpan?: number
  colSpan?: number
  hideBorderRight?: boolean
  hideBorderLeft?: boolean
  hideBorderBottom?: boolean
  children: React.ReactNode
  onClick?: () => void
}
export function TimelineTableTd({
  id,
  className,
  rowSpan,
  colSpan,
  hideBorderLeft,
  hideBorderRight,
  hideBorderBottom,
  children,
  onClick,
}: TimelineTableTdProps) {
  return (
    <td
      id={id}
      rowSpan={rowSpan}
      colSpan={colSpan}
      className={classNames("border border-solid border-neutral-8 px-4 py-2", className, {
        "border-l-0": hideBorderLeft,
        "border-r-0": hideBorderRight,
        "border-b-0": hideBorderBottom,
      })}
      onClick={onClick}
    >
      {children}
    </td>
  )
}
