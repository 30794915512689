import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import condensedTreeNo from "images/condensed-tree-no.png"
import condensedTreeYes from "images/condensed-tree-yes.png"
import viewAsCards from "images/view-as-cards-icon.png"
import viewAsGrid from "images/view-as-grid-icon.png"
import viewAsThreeLevel from "images/view-as-three_level-icon.png"
import viewAsTree from "images/view-as-tree-icon.png"
import { isEqual } from "lodash/fp"
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { NodeInterface } from "types/graphql"
import ClosePanelButton from "v2/react/components/orgChart/SuperPanel/ClosePanelButton"
import { DrawerFooter } from "v2/react/components/orgChart/SuperPanel/DrawerFooter"
import { WithLoader } from "v2/react/shared/WithLoader"
import { asyncPatchPreferences } from "v2/redux/slices/ContainerSlice/containerThunks"
import { PositionStatusFilterOption } from "v2/redux/slices/GridSlice/types"
import { toggleSuperPanelFooter } from "v2/redux/slices/VisualizationSlice"
import { DisplayMode, SortFields } from "v2/redux/slices/VisualizationSlice/types"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface initialDataState {
  collapseDepth: string
  condensed: boolean
  displayMode: DisplayMode
  enableTextwrap: boolean
  gridPositionFilter: PositionStatusFilterOption
  panOnScroll: boolean
  secondarySortField: keyof NodeInterface
  showLabels: boolean
  sortField: "sort_order" | SortFields
}

function DisplayOptionsTab() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dataState, setDataState] = useState<initialDataState>({
    collapseDepth: "2",
    condensed: false,
    displayMode: "tree",
    enableTextwrap: false,
    gridPositionFilter: "all",
    panOnScroll: true,
    secondarySortField: "title",
    showLabels: true,
    sortField: "sort_order",
  })
  const isListView = window.location.pathname.indexOf("lists/") > 0
  const dispatch = useAppDispatch()
  const collapseDepthOptions = ["1", "2", "3", "4"]
  const collapseDepth = useAppSelector((state) => state.visualization.collapseDepth)
  const condensed = useAppSelector((state) => state.visualization.condensed)
  const displayMode = useAppSelector((state) => state.visualization.displayMode)
  const enableTextwrap = useAppSelector((state) => state.visualization.enableTextwrap)
  const gridPositionFilter = useAppSelector((state) => state.visualization.gridPositionFilter)
  const panOnScroll = useAppSelector((state) => state.visualization.panOnScroll)
  const secondarySortField = useAppSelector((state) => state.visualization.secondarySortField)
  const showLabels = useAppSelector((state) => state.visualization.showLabels)
  const sortField = useAppSelector((state) => state.visualization.sortField)
  const superPanelFooter = useAppSelector((state) => state.visualization.superPanelFooter)

  useEffect(() => {
    setIsLoading(true)
    setDataState({
      collapseDepth,
      condensed,
      displayMode,
      enableTextwrap,
      gridPositionFilter,
      panOnScroll,
      secondarySortField,
      showLabels,
      sortField,
    })
  }, [
    collapseDepth,
    condensed,
    displayMode,
    enableTextwrap,
    gridPositionFilter,
    panOnScroll,
    secondarySortField,
    showLabels,
    sortField,
  ])

  useEffect(() => {
    if (
      isEqual(dataState, {
        collapseDepth,
        condensed,
        displayMode,
        enableTextwrap,
        gridPositionFilter,
        panOnScroll,
        secondarySortField,
        showLabels,
        sortField,
      })
    ) {
      setIsLoading(false)
    }
    /**
     * This compares the local dataState values to the store values.
     * If the local dataState has been changed back to its original state,
     * then we want to hide the footer again instead
     */
    if (
      superPanelFooter &&
      isEqual(dataState, {
        collapseDepth,
        condensed,
        displayMode,
        enableTextwrap,
        gridPositionFilter,
        panOnScroll,
        secondarySortField,
        showLabels,
        sortField,
      })
    ) {
      dispatch(toggleSuperPanelFooter(false))
    }
  }, [
    dispatch,
    dataState,
    collapseDepth,
    condensed,
    displayMode,
    enableTextwrap,
    gridPositionFilter,
    panOnScroll,
    secondarySortField,
    showLabels,
    sortField,
    superPanelFooter,
  ])

  const displayModeOptions = () => {
    if (isListView) return ["cards", "grid"]
    if (window.gon.company_settings.org_chart.force_three_level) return ["three_level", "grid"]
    return ["tree", "three_level", "grid"]
  }

  const displayImageSrc = (view: string) => {
    const displayModes: Record<string, string> = {
      cards: viewAsCards,
      grid: viewAsGrid,
      three_level: viewAsThreeLevel,
      tree: viewAsTree,
    }

    return displayModes[view]
  }

  const changeDisplayMode = (option: DisplayMode) => {
    setDropdownOpen(false)
    setDataState((prev) => ({
      ...prev,
      displayMode: option,
    }))

    dispatch(toggleSuperPanelFooter(true))
  }

  const saveRadio = (e: ChangeEvent<HTMLInputElement>) => {
    setDataState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value === "true",
    }))

    dispatch(toggleSuperPanelFooter(true))
  }

  const saveDepth = (depth: string) => {
    setDataState((prev) => ({
      ...prev,
      collapseDepth: depth,
    }))

    dispatch(toggleSuperPanelFooter(true))
  }

  const resetOptionsForm = () => {
    setDataState({
      collapseDepth,
      condensed,
      displayMode,
      enableTextwrap,
      gridPositionFilter,
      panOnScroll,
      secondarySortField,
      showLabels,
      sortField,
    })
  }

  const saveSelectBox = (e: ChangeEvent<HTMLSelectElement>) => {
    setDataState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))

    dispatch(toggleSuperPanelFooter(true))
  }

  const saveDisplayOptions = (e: FormEvent) => {
    e.preventDefault()

    const preferencesPatch = {
      collapse_depth: dataState.collapseDepth,
      condensed: dataState.condensed,
      display_mode: dataState.displayMode,
      enable_textwrap: dataState.enableTextwrap,
      grid_position_filter: dataState.gridPositionFilter,
      pan_on_scroll: dataState.panOnScroll,
      secondary_sort_field: dataState.secondarySortField,
      show_labels: dataState.showLabels,
      sort_field: dataState.sortField,
    }

    dispatch(asyncPatchPreferences(preferencesPatch)).then(() => {
      window.location.reload()
    })
  }

  return (
    <div id="tab-display-options" className="drawer-contents panel grid-rows-[auto_1fr] grid">
      <div className="drawer-header">
        <div className="drawer-title">{t("v2.orgchart.utilitynav.display_options")}</div>
        <ClosePanelButton />
      </div>

      <WithLoader isLoading={isLoading}>
        <form className="h-full overflow-auto">
          <div
            className={classNames("drawer-section-content p-3", {
              "!pb-14": superPanelFooter,
            })}
          >
            <div className="input-with-dropdown-selector">
              <div className="input-group dropdown-with-image">
                <label>{t("v2.orgchart.super_panel.view_as")}</label>
                <span className="dropdown">
                  <button
                    className="dropdown-link w-full"
                    key={dataState.displayMode}
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    type="button"
                  >
                    <div className="selection-icon">
                      <div className="icons">
                        <img
                          src={displayImageSrc(dataState.displayMode)}
                          alt={t(`v2.orgchart.super_panel.view_as_${dataState.displayMode}`)}
                        />
                      </div>
                      <div className="text">
                        {t(`v2.orgchart.super_panel.view_as_${dataState.displayMode}`)}
                      </div>
                    </div>
                    <FontAwesomeIcon icon={["fas", "caret-down"]} />
                  </button>

                  <div className={classNames("dropdown-menu", { "!block": dropdownOpen })}>
                    {displayModeOptions().map((option) => (
                      <button
                        className={classNames("dropdown-menu-link", {
                          active: dataState.displayMode === option,
                        })}
                        data-selector-value={option}
                        key={option}
                        onClick={() => changeDisplayMode(option as DisplayMode)}
                        type="button"
                      >
                        <div className="selection-icon">
                          <div className="icons">
                            <FontAwesomeIcon
                              className="selected-indicator"
                              icon={["fas", "check"]}
                            />
                            <img
                              src={displayImageSrc(option)}
                              alt={t(`v2.orgchart.super_panel.view_as_description_${option}`)}
                            />
                          </div>
                          <div className="text">
                            <div className="title">
                              {t(`v2.orgchart.super_panel.view_as_${option}`)}
                            </div>
                            <div className="description">
                              {t(`v2.orgchart.super_panel.view_as_description_${option}`)}
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                  </div>
                  <input
                    id="display_mode"
                    name="displayMode"
                    type="hidden"
                    value={dataState.displayMode}
                  />
                </span>
              </div>
            </div>

            {dataState.displayMode === "grid" && !isListView && (
              <div className="input-group">
                <label>{t("v2.orgchart.super_panel.show")}</label>
                <div className="select">
                  <select
                    name="gridPositionFilter"
                    value={dataState.gridPositionFilter}
                    onChange={saveSelectBox}
                  >
                    <option value="all">All Positions</option>
                    <option value="filled">Filled Positions</option>
                    <option value="open">Open Positions</option>
                  </select>
                </div>
              </div>
            )}

            {dataState.displayMode !== "grid" && (
              <div>
                <div className="form-inline-label-top form-inline-grow mb-4 items-start gap-2">
                  <div className="input-group m-0">
                    <label>{t("v2.orgchart.super_panel.sort_by")}</label>
                    <div className="select">
                      <select name="sortField" value={dataState.sortField} onChange={saveSelectBox}>
                        <option value="first_name">
                          {t("v2.orgchart.super_panel.sort_fname")}
                        </option>
                        <option value="last_name">{t("v2.orgchart.super_panel.sort_lname")}</option>
                        <option value="title">{t("v2.orgchart.super_panel.sort_title")}</option>
                        <option value="chart_section">
                          {t("v2.orgchart.super_panel.sort_chart_section")}
                        </option>
                        <option value="sort_order">
                          {t("v2.orgchart.super_panel.sort_custom")}
                        </option>
                      </select>
                    </div>
                  </div>

                  {dataState.sortField !== "sort_order" && (
                    <div className="input-group m-0">
                      <label>{t("v2.orgchart.super_panel.secondary_sort_by")}</label>
                      <div className="select">
                        <select
                          name="secondarySortField"
                          value={dataState.secondarySortField}
                          onChange={saveSelectBox}
                        >
                          <option value="first_name">
                            {t("v2.orgchart.super_panel.sort_fname")}
                          </option>
                          <option value="last_name">
                            {t("v2.orgchart.super_panel.sort_lname")}
                          </option>
                          <option value="title">{t("v2.orgchart.super_panel.sort_title")}</option>
                          <option value="chart_section">
                            {t("v2.orgchart.super_panel.sort_chart_section")}
                          </option>
                        </select>
                      </div>
                    </div>
                  )}
                </div>

                {dataState.displayMode === "tree" && (
                  <>
                    <div className="input-group">
                      <label>{t("v2.orgchart.super_panel.condense_chart")}</label>
                      <p>{t("v2.orgchart.super_panel.condense_description")}</p>
                      <div className="row col-gap-sm mt-2">
                        <div className="col">
                          <label>
                            <input
                              type="radio"
                              name="condensed"
                              id="condensed_yes"
                              value="true"
                              className="hidden"
                              checked={dataState.condensed}
                              onChange={saveRadio}
                            />
                            <div className="module selector-module selector-module-inline">
                              <div className="module-body">
                                <img src={condensedTreeYes} alt="Condensed Tree" />
                                {t("v2.defaults.yes")}
                              </div>
                              <div className="check">
                                <FontAwesomeIcon icon={["fas", "check"]} />
                              </div>
                            </div>
                          </label>
                        </div>

                        <div className="col">
                          <label>
                            <input
                              type="radio"
                              name="condensed"
                              id="condensed_no"
                              value="false"
                              className="hidden"
                              checked={!dataState.condensed}
                              onChange={saveRadio}
                            />
                            <div className="module selector-module selector-module-inline">
                              <div className="module-body">
                                <img src={condensedTreeNo} alt="No Condensed Tree" />
                                {t("v2.defaults.no")}
                              </div>
                              <div className="check">
                                <FontAwesomeIcon icon={["fas", "check"]} />
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="input-group">
                      <label>{t("v2.orgchart.super_panel.collapse_depth")}</label>
                      <p>{t("v2.orgchart.super_panel.collapse_depth_description")}</p>
                      <div className="row col-gap-sm mt-2">
                        {collapseDepthOptions.map((collapseDepth) => (
                          <div className="col" key={collapseDepth}>
                            <label>
                              <input
                                type="radio"
                                name="collapse_depth"
                                id={`collapse_depth_${collapseDepth}`}
                                value={collapseDepth}
                                className={classNames(
                                  "hidden",
                                  dataState.collapseDepth === collapseDepth ? "checked" : "",
                                )}
                              />
                              <button
                                className={classNames(
                                  "module selector-module selector-module-inline mb-0",
                                  { selected: dataState.collapseDepth === collapseDepth },
                                )}
                                onClick={() => saveDepth(collapseDepth)}
                                type="button"
                              >
                                <div className="module-body justify-center">
                                  <span className="select-none">{collapseDepth}</span>
                                </div>

                                <div className="check">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </div>
                              </button>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}

                <div className="input-group">
                  <label>{t("v2.orgchart.super_panel.hide_field_labels")}</label>
                  <label className="radio">
                    <input
                      type="radio"
                      name="showLabels"
                      checked={!dataState.showLabels}
                      value="false"
                      onChange={saveRadio}
                    />
                    {t("v2.defaults.yes")}
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      name="showLabels"
                      checked={dataState.showLabels}
                      value="true"
                      onChange={saveRadio}
                    />
                    {t("v2.defaults.no")}
                  </label>
                </div>

                <div className="input-group">
                  <label>{t("v2.orgchart.super_panel.wrap_long_titles")}</label>
                  <label className="radio">
                    <input
                      type="radio"
                      name="enableTextwrap"
                      checked={dataState.enableTextwrap}
                      value="true"
                      onChange={saveRadio}
                    />
                    {t("v2.defaults.yes")}
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      name="enableTextwrap"
                      checked={!dataState.enableTextwrap}
                      value="false"
                      onChange={saveRadio}
                    />
                    {t("v2.defaults.no")}
                  </label>
                </div>

                <div id="pan_on_scroll" className="input-group">
                  <label>{t("v2.orgchart.super_panel.pan_on_scroll")}</label>
                  <label className="radio">
                    <input
                      type="radio"
                      name="panOnScroll"
                      checked={dataState.panOnScroll}
                      value="true"
                      onChange={saveRadio}
                    />
                    {t("v2.orgchart.super_panel.pan_via_scroll")}
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      name="panOnScroll"
                      checked={!dataState.panOnScroll}
                      value="false"
                      onChange={saveRadio}
                    />
                    {t("v2.orgchart.super_panel.pan_via_click_drag")}
                  </label>
                </div>
              </div>
            )}
          </div>
          <DrawerFooter onCancel={resetOptionsForm} onSave={saveDisplayOptions} />
        </form>
      </WithLoader>
    </div>
  )
}

export { DisplayOptionsTab }
