import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { ToggleInterestInJobInput } from "types/graphql"
import { useToggleInterestInJobMutation } from "v2/redux/GraphqlApi/PositionTypesApi"

interface Props {
  favorited: boolean
  positionTypeId: string
}

function Interest({ favorited, positionTypeId }: Props) {
  const { t } = useTranslation()
  const [interested, setInterested] = useState(favorited)
  const [toggleInterestMutation] = useToggleInterestInJobMutation()

  const toggleInterestInJob = async () => {
    const input: ToggleInterestInJobInput = {
      positionTypeId: `position_type_${positionTypeId}`,
      showInterestIndicator: !interested,
    }

    await toggleInterestMutation({ input }).unwrap()
    setInterested(!interested)
  }

  return (
    <div className="card--primary page-margin-b flex-col gap-3 flex">
      <p className="text-base-bold">{t("v2.careers.show.interested_in_job")}</p>
      <p>{t("v2.careers.show.let_your_org_know")}</p>
      <button
        type="button"
        className="btn--large btn--secondary w-full"
        onClick={toggleInterestInJob}
      >
        <div className={cn({ "text-accent-sunburst": interested })}>
          <FontAwesomeIcon icon={[interested ? "fas" : "fal", "star"]} />
        </div>
        {t(`v2.careers.show.${interested ? "im_interested" : "show_interest"}`)}
      </button>
    </div>
  )
}

export default Interest
