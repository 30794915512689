import React from "react"
import cn from "classnames"
import { useTruncatedTooltip } from "v2/react/hooks/useTruncatedTooltip"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/Tooltip"

interface Props {
  title: string
  titleClasses: string
}

function TruncatedTitle({ title, titleClasses }: Props) {
  const { ref, showTooltip, setShowTooltip, determineTooltip } =
    useTruncatedTooltip<HTMLParagraphElement>()

  return (
    <div className="truncate">
      <Tooltip open={showTooltip}>
        <TooltipTrigger onMouseEnter={determineTooltip} onMouseLeave={() => setShowTooltip(false)}>
          <p ref={ref} className={cn("truncate", titleClasses)}>
            {title}
          </p>
        </TooltipTrigger>
        <TooltipContent className="react-tooltip-content react-tooltip-content--sm">
          {title}
        </TooltipContent>
      </Tooltip>
    </div>
  )
}

export { TruncatedTitle }
