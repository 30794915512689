import React, { useState, forwardRef, useMemo } from "react"

import { SourcePay } from "types/graphql.enums"
import { UpdateSumProps } from "v2/react/shared/CompensationTable"

import { CurrencyInput } from "v2/react/shared/Inputs/CurrencyInput"
import { InputFieldset } from "v2/react/shared/Inputs/InputFieldset"
import { PercentInput } from "v2/react/shared/Inputs/PercentInput"
import { Select } from "v2/react/shared/Select"
import { prepareCurrencyValue } from "v2/react/utils/currency"

type InputRef = React.RefObject<HTMLInputElement>
type SelectRef = React.RefObject<HTMLSelectElement>

interface VariablePayInputProps {
  name: string
  focusOn?: "type" | "amount"
  label?: string
  labelPlacement?: "top" | "left"
  iconClass?: string
  initialAmountValue?: string
  initialPayType?: SourcePay
  amountErrors?: string
  useAttentionState?: boolean
  clearErrors?: (keys: string[]) => void
  updateSum?: (props: UpdateSumProps) => void
}

const VariablePayInput = forwardRef<HTMLInputElement | HTMLSelectElement, VariablePayInputProps>(
  (
    {
      name,
      focusOn,
      label,
      labelPlacement = "top",
      iconClass = window.gon.currency_icon_class || "far fa-dollar-sign",
      initialAmountValue,
      initialPayType,
      amountErrors,
      useAttentionState,
      clearErrors,
      updateSum,
    },
    ref,
  ) => {
    const [payType, setPayType] = useState(initialPayType || SourcePay.Amount)
    const [amount, setAmount] = useState(initialAmountValue || "")

    const typeRef = focusOn === "type" ? (ref as SelectRef) : undefined
    const amountRef = focusOn === "amount" ? (ref as InputRef) : undefined

    const payTypeName = `${name}_type`
    const payRateName = name

    const errors = []
    if (amountErrors) errors.push(formatErrors({ errors: amountErrors, inputType: payType }))

    // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-pattern
    let updateSumIfPresent = ({}: Partial<UpdateSumProps>) => {}
    if (updateSum) {
      updateSumIfPresent = ({ value, payType: type }: Partial<UpdateSumProps>) =>
        updateSum({
          key: payRateName,
          value: value === undefined ? amount : value,
          payType: type === undefined ? payType : type,
        })
    }

    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (clearErrors) {
        clearErrors([payRateName])
      }
      setPayType(event.target.value as SourcePay)
      updateSumIfPresent({ payType: event.target.value as SourcePay })
    }

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setAmount(e.target.value)
      updateSumIfPresent({ value: e.target.value })
    }

    const formattedInitialAmount = useMemo(
      () => prepareCurrencyValue({ value: initialAmountValue }),
      [initialAmountValue],
    )

    return (
      <InputFieldset label={label} errors={errors} labelPlacement={labelPlacement}>
        <div className="flex-col gap-4 flex md:flex-row">
          <div className="flex-2">
            <Select
              ref={typeRef}
              id={payTypeName}
              name={payTypeName}
              selected={payType}
              onSelect={handleSelect}
              options={[
                { id: SourcePay.Amount, label: "amount".t("org_chart") },
                { id: SourcePay.Percent, label: "percent".t("org_chart") },
              ]}
            />
          </div>

          <div className="flex-3">
            {payType === SourcePay.Amount ? (
              <CurrencyInput
                ref={amountRef}
                id={payRateName}
                iconClass={iconClass}
                name={payRateName}
                defaultValue={initialAmountValue === amount ? formattedInitialAmount : amount}
                errors={amountErrors}
                showErrorMessage={false}
                useAttentionState={useAttentionState}
                onChange={handleAmountChange}
              />
            ) : (
              <PercentInput
                ref={amountRef}
                id={payRateName}
                name={payRateName}
                errors={amountErrors}
                showErrorMessage={false}
                defaultValue={amount}
                useAttentionState={useAttentionState}
                onChange={handleAmountChange}
              />
            )}
          </div>
        </div>
      </InputFieldset>
    )
  },
)

/**
 * Formats the given errors by prefixing them with a label that corresponds to the input type.
 *
 * @example
 * const result = formatErrors({ errors: "Some error message", inputType: SourcePay.Amount });
 * // Output: "Amount: Some error message"
 */
const formatErrors = ({ errors, inputType }: { errors: string; inputType: SourcePay }) => {
  const errorLabel = inputType === SourcePay.Amount ? "amount" : "percent"
  return `${errorLabel.t("org_chart")}: ${errors}`
}

export { VariablePayInput }
