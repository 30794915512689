import React, { useState } from "react"
import { useDispatch } from "react-redux"
import {
  ChartApprover,
  Error,
  Maybe,
  Person,
  SaveChartApproversInput,
  SaveChartApproversInputObject,
} from "types/graphql.d"
import { Modal, ModalFooter } from "v2/react/shared/Modal"
import { ListItem, OrderedList } from "v2/react/shared/OrderedList/OrderedList"
import { PersonSearchInput } from "v2/react/shared/PersonSearchInput"
import { useSaveApproversMutation } from "v2/redux/GraphqlApi/ChartApprovalsApi"
import { useAppSelector } from "v2/redux/store"
import { toggleConfigureModal, toggleDropdown } from "v2/redux/slices/ApprovalSlice"
import { selectConfigureModal } from "v2/redux/slices/ApprovalSlice/approvalSelectors"
import { pjaxReload } from "v2/react/utils/pjax"

interface Props {
  chartApprovers: ChartApprover[]
  chartId: string
  chartStatus: string
}

function ConfigureApprovalModal({ chartApprovers, chartId, chartStatus }: Props) {
  const isOpen = useAppSelector(selectConfigureModal)
  const [approverErrorMessage, setApproverErrorMessage] = useState<string>()
  const [generalErrorMessage, setGeneralErrorMessage] = useState<string | React.ReactNode[]>()
  // Approvers with status needed to be filtered out and put in a different list in order to make the drag and drop work effectively
  const disabledListItems: ListItem[] = filteredListItemsFromApprovers(
    chartApprovers,
    (a) => a.status,
  )

  const [listItems, setListItems] = useState<ListItem[]>(
    filteredListItemsFromApprovers(chartApprovers, (a) => !a.status),
  )
  const [saveChartApprovers, { isLoading }] = useSaveApproversMutation()
  const dispatch = useDispatch()

  if (!chartId) return null

  const closeConfigureModal = () => dispatch(toggleConfigureModal(false))

  const onPersonSelect = (addedPerson: Person, index: number) => {
    if (listItems.findIndex((li) => li.value === addedPerson.id.toString()) >= 0) return

    setApproverErrorMessage(undefined)

    const updated: ListItem[] = [
      ...listItems,
      ...[
        {
          label: addedPerson.name,
          thumbUrl: addedPerson.avatarThumbUrl,
          value: addedPerson.id.toString(),
          index,
          disable: undefined,
        },
      ],
    ].map(mapToIndex)
    setListItems(updated)
  }

  const onListItemRemove = (removedItem: ListItem) => {
    const updated: ListItem[] = listItems
      .filter((li) => li.value !== removedItem.value)
      .map(mapToIndex)
    setListItems(updated)
    resetValidation()
  }

  const reorderItems = (items: ListItem[]) => {
    const updated: ListItem[] = items.map(mapToIndex)
    setListItems(updated)
  }

  const mapToIndex = (item: ListItem, i: number) => ({
    ...item,
    index: i + disabledListItems.length,
  })

  const onSave = () => {
    resetValidation()

    const approverData: [SaveChartApproversInputObject] = [...disabledListItems, ...listItems].map(
      (i) => ({
        personId: i.value,
        sortOrder: i.index,
      }),
    ) as [SaveChartApproversInputObject]

    const input: SaveChartApproversInput = {
      chartId: chartId?.toString() || "",
      chartApprovers: approverData,
    }

    const saveApprovers = async () => {
      const result = await saveChartApprovers({ input }).unwrap()
      if (result.saveChartApprovers?.chartApprovers) {
        pjaxReload({
          url: "/charts/collaborators",
          container: "[data-pjax-container='collaborators']",
        })
        closeConfigureModal()
        dispatch(toggleDropdown(false))
      } else {
        const errors = result.saveChartApprovers?.errors || []
        const message: React.ReactNode[] = errors.map((s: Error) => (
          <span key={s.message}>
            {s.message}
            <br />
          </span>
        ))
        setGeneralErrorMessage(message)
      }
    }

    saveApprovers()
  }

  const resetValidation = () => {
    setApproverErrorMessage(undefined)
    setGeneralErrorMessage(undefined)
  }

  const selectedIds: string[] = ([...disabledListItems, ...listItems] || []).map((li) => li.value)
  const disabledSave = isLoading || (chartStatus === "awaiting_approval" && listItems.length === 0)

  return (
    <Modal
      footer={
        <ModalFooter
          disabled={disabledSave || isLoading}
          onClose={closeConfigureModal}
          onSave={onSave}
          saveButtonText={
            isLoading ? "save_disabled".t("button_defaults") : "save_enabled".t("button_defaults")
          }
        />
      }
      isOpen={isOpen}
      onClose={closeConfigureModal}
      title={"configure_approvers".t("org_chart")}
    >
      <div className="p-6">
        {generalErrorMessage && (
          <div className="alert-critical mb-6">
            <span>{generalErrorMessage}</span>
          </div>
        )}
        {chartStatus && (
          <div className="alert-neutral mb-6">{"approvers_list_info".t("org_chart")}</div>
        )}
        <div className="flex-col gap-1 flex">
          {/*
            This accessibility markup is compliant but difficult to handle with a custom component.
            No thanks on getting it to pass with configuration.
            See: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/label-has-associated-control.md#case-my-label-and-input-components-are-custom-components
          */}
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="approver_search_input">{"approvers".t("org_chart")}</label>
          <p>{"approval_modal_instructions".t("org_chart")}</p>
          <PersonSearchInput
            errorMessage={approverErrorMessage}
            htmlForId="approver_search_input"
            omitValues={selectedIds}
            onSelect={onPersonSelect}
            placeholder={`${"example_abbreviated".t("defaults")}: ${"example_name".t("defaults")}`}
          />
        </div>
        <div className="OrderedList__spread mt-6">
          {/* classes adjust the list style to make sure borders/border-radius are applied appropriately */}
          <OrderedList
            handleValues={reorderItems}
            draggableListClasses="bg-transparent shadow-none p-0"
            listClasses={listItems.length ? "OrderedList-first" : ""}
            onRemove={onListItemRemove}
            values={disabledListItems}
          />
          <OrderedList
            handleValues={reorderItems}
            draggableListClasses="bg-transparent shadow-none p-0"
            onRemove={onListItemRemove}
            listClasses={disabledListItems.length ? "OrderedList-last" : ""}
            values={listItems}
          />
        </div>
      </div>
    </Modal>
  )
}

export { ConfigureApprovalModal }

const filteredListItemsFromApprovers = (
  approvers: ChartApprover[],
  filterApprovers: (a: ChartApprover) => void,
): ListItem[] =>
  (approvers && approvers.length > 0 ? approvers : [])
    .filter((a) => filterApprovers(a))
    .map((a) => ({
      disable: formatStatusLabel(a.status),
      index: a.sortOrder || 0,
      label: a.person.name,
      thumbUrl: a.person.avatarThumbUrl,
      value: a.person.id.toString(),
    }))

const formatStatusLabel = (status: Maybe<string> | undefined) =>
  status ? status.t("org_chart") : undefined
