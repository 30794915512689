import React from "react"

import { StoreProvider } from "v2/redux/StoreProvider"
import { useGetPositionQuery } from "v2/redux/GraphqlApi"
import PositionRequirements from "./PositionRequirements"
import PositionDetails from "./PositionDetails"
import PageSection from "./PageSection"

interface Props {
  positionId: string
}

function ShowInner({ positionId }: Props) {
  const { data, isLoading } = useGetPositionQuery({ positionId })

  if (isLoading || !data?.position) return null

  const position = data.position

  return (
    <div className="position-page min-h-screen bg-primary-3">
      <div className="border-b border-neutral-8 bg-white p-2">
        <h2 className="font-bold">
          {position.title} - {position.systemIdentifier}
        </h2>
      </div>
      <div className="items-start gap-8 p-12 flex">
        <div className="w-full lg:w-3/4">
          <PositionDetails positionId={positionId} />
          <PositionRequirements positionId={positionId} />
          <PageSection title="Description">
            <div className="col-span-2 p-4">{position.description}</div>
          </PageSection>
        </div>

        <div className="w-full lg:w-1/4">
          <PageSection columnCount={1} title="Stats">
            <div className="justify-between p-4 flex">
              <div>Avg. Tenure</div>
              <div>3.2 Years</div>
            </div>
            <div className="justify-between p-4 flex">
              <div>Avg. Performance</div>
              <div>High</div>
            </div>
            <div className="justify-between p-4 flex">
              <div>Avg. Base Pay</div>
              <div>$18,000</div>
            </div>
          </PageSection>
        </div>
      </div>
    </div>
  )
}

const Show = ({ positionId }: Props) => (
  <StoreProvider>
    <ShowInner positionId={positionId} />
  </StoreProvider>
)

export default Show
