import React from "react"
import { motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { inEitherWriteState, onNothing } from "v2/redux/slices/DatasheetSlice/cursor/cursorStates"
import { useAppSelector } from "v2/redux/store"
import { selectCursor } from "v2/redux/slices/DatasheetSlice/cursor/cursorSelectors"
import { selectCellErrorMessage } from "v2/redux/slices/DatasheetSlice/datasheetSelectors"
import { FieldType } from "v2/react/shared/TableDatasheet/types"
import { LightMode } from "v2/react/utils/colors"

// The `offsetTop` property is calculated relative to the closest ancestor element
// whose `position` property is not `static`.
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetTop
function calculateTop(cellElement: HTMLElement) {
  const virtualRowStart = cellElement.closest("tr")?.dataset.virtualRowStart
  const header = document.getElementById("table-datasheet-thead")
  if (!virtualRowStart || !header) return 0
  return parseFloat(virtualRowStart) + header.offsetHeight
}

export function ActiveCursor({ cursorRef }: { cursorRef: React.RefObject<HTMLDivElement> }) {
  const cursor = useAppSelector(selectCursor)

  const hasError = useAppSelector((state) =>
    onNothing(cursor)
      ? false
      : !!selectCellErrorMessage(state, cursor.rowId, cursor.columnId, false),
  )

  if (!cursor || onNothing(cursor)) return null

  const element = document.getElementById(`${cursor.columnId}-${cursor.rowId}`)

  if (!element) return null

  const style = {
    left: `${element.offsetLeft}px`,
    top: `${calculateTop(element)}px`,
    position: "absolute",
    width: `${element.offsetWidth}px`,
    maxWidth: "20rem",
    height: `${element.offsetHeight}px`,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: selectionColor(cursor.editable, hasError),
    pointerEvents: "none",
    zIndex: 6,
  } as React.CSSProperties

  return (
    <motion.div
      id="cell-cursor"
      ref={cursorRef}
      style={style}
      animate={{ left: style.left, top: style.top }}
      transition={{ ease: "easeInOut", duration: cursor.enteredBy !== "placement" ? 0.1 : 0 }}
    >
      {cursor.fieldType === FieldType.SelectDropdown ? (
        <motion.div
          key="caret"
          animate={{ opacity: 1, rotate: inEitherWriteState(cursor) ? "180deg" : "0deg" }}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          transition={{ opacity: { delay: 0.1, duration: 0.05 }, rotate: { duration: 0.2 } }}
          className={classNames("GridBody-cell__caret")}
        >
          <FontAwesomeIcon style={{ color: style.borderColor }} icon={["fas", "caret-down"]} />
        </motion.div>
      ) : null}
      {!inEitherWriteState(cursor) ? (
        <motion.div
          key="handle"
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="GridBody-cell__selected-handle"
          style={{
            bottom: 0,
            right: 0,
            width: 6,
            height: 6,
            backgroundColor: style.borderColor,
            transform: "translate(50%, 50%)",
            position: "absolute",
          }}
        />
      ) : null}
    </motion.div>
  )
}

const { ForegroundColors } = LightMode

export function selectionColor(isEditable: boolean, hasError: boolean) {
  if (hasError) return ForegroundColors.Error
  if (isEditable) return ForegroundColors.Primary
  return ForegroundColors.Muted
}
