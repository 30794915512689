import { useState } from "react"
import { isEqual, isEmpty } from "lodash"
import { useDebounce } from "usehooks-ts"

import { FieldKey } from "v2/redux/slices/NodeSlice/types"
import { Option } from "types/graphql.d"

import { buildCollectionSearchQueryFromFieldKey } from "v2/redux/slices/GridSlice/gridHelpers/collections"
import { useFetchCollectionsQuery } from "v2/redux/GraphqlApi"

interface Props {
  fieldKey: FieldKey | "tags"
  omitValues?: string[]
  skipQuery?: boolean
  filter: string
  tagDomain?: "skills"
  taggableEntity?: "position_types"
  excludeFrom?: string
  extraArgs?: object
}

const useCollectionSearch = ({ fieldKey, omitValues, filter, skipQuery, extraArgs }: Props) => {
  const [returnEmpty, setReturnEmpty] = useState(false)

  const searchTerm = useDebounce(filter || "", 350)

  const collectionQuery = buildCollectionSearchQueryFromFieldKey({
    fieldKey,
    searchTerm,
    extraArgs,
  })

  const { data, isFetching } = useFetchCollectionsQuery(collectionQuery, {
    skip: skipQuery || !searchTerm,
  })

  const options = filterOptions(data?.[0]?.options?.nodes || [], omitValues || [])

  // Handles the case where the input changs from empty to non-empty, and we
  // need to return an empty list of options until the fresh data returns.
  if (isEmpty(filter) && !returnEmpty) {
    setReturnEmpty(true)
  } else if (!isEmpty(filter) && isEqual(filter, searchTerm) && !isFetching && returnEmpty) {
    setReturnEmpty(false)
  }

  return {
    collectionResult: returnEmpty ? [] : options,
    returnEmpty,
  }
}

const filterOptions = (options: Option[], omitValues: string[]) =>
  options.filter((option) => !(omitValues || []).includes(option.id))

export { useCollectionSearch }
