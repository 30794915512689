import React from "react"
import { useColorCodingActions } from "v2/react/components/orgChart/SuperPanel/ColorCodingTab/hooks/useColorCodingActions"
import { Toggle, ToggleButton, ToggleLabel, ToggleSwitch } from "v2/react/shared/Toggle"
import { selectColorCodingConfigForActiveTab } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useAppSelector } from "v2/redux/store"

const useActiveTabColorCodingConfig = () => useAppSelector(selectColorCodingConfigForActiveTab)

function ColorCodingToggle() {
  const { activeTab, isEnabled } = useActiveTabColorCodingConfig()
  const { handleColorCodingToggleClick } = useColorCodingActions()

  const toggleLabel = () => {
    const edgeClasses = "border border-solid border-neutral-100"
    const cardClasses = "bg-neutral-100"

    return (
      <div className="items-center gap-1.5 flex">
        <div
          className={`h-2.5 w-3.5 rounded-sm ${activeTab === "card" ? cardClasses : edgeClasses}`}
        >
          {activeTab === "edge" && <div className="h-full w-[3px] bg-neutral-100" />}
        </div>
        <h2 className="drawer-section-title">
          {activeTab === "edge" ? "Show Edge".t("org_chart") : "Show Color".t("org_chart")}
        </h2>
      </div>
    )
  }

  return (
    <Toggle
      id={`${activeTab}-toggle-color-coding-enabled`}
      initialValue={isEnabled}
      onToggle={handleColorCodingToggleClick}
    >
      <ToggleButton classes="flex items-center justify-between w-full toggle-color-coding">
        <ToggleLabel disabledLabel={toggleLabel()} enabledLabel={toggleLabel()} />
        <ToggleSwitch />
      </ToggleButton>
    </Toggle>
  )
}

export { ColorCodingToggle }
