import { useEffect } from "react"
import { getCookie, setCookie } from "v2/react/utils/cookies"

export const FIXED_NAV_SCREEN_WIDTH = 1025
export const NAV_FULL_WIDTH = 224
export const NAV_COLLAPSED_WIDTH = 48
export const ALERT_HEIGHT = 50

function useNavController() {
  const nav = document.querySelector(".nav")

  const determineNavAndMarginSizes = () => {
    /*
      if on a smaller screen, we want to keep the nav
      width and main-content margin fixed to 3rem (width of collapsed nav)
      keeping the main-content margin fixed allows the fixed-position nav to slide on top of content

      if on a larger screen, we want the nav width and main-content
      margin to evaluate to the same value
    */

    const navShrunk = nav?.classList.contains("shrink-nav")
    setCookie("nav_open_state", `${!navShrunk}`, 365)
    if (window.innerWidth > FIXED_NAV_SCREEN_WIDTH && navShrunk) {
      addSmallPadToMain()
    } else if (window.innerWidth > FIXED_NAV_SCREEN_WIDTH && !navShrunk) {
      addLargePadToMain()
    } else {
      addSmallPadToMain()
    }

    if (navShrunk) handleCompanyMenuHeight()
  }

  const addSmallPadToMain = () => {
    document.querySelector(".main-content")?.classList.remove("main-nav-open")
    document.querySelector(".main-content")?.classList.add("main-nav-closed")
  }

  const addLargePadToMain = () => {
    document.querySelector(".main-content")?.classList.remove("main-nav-closed")
    document.querySelector(".main-content")?.classList.add("main-nav-open")
  }

  const handleCompanyMenuHeight = () => {
    const companyMenu = document.querySelector<HTMLElement>(".company-menu")
    const companyMenuInner = document.querySelector<HTMLElement>(".company-menu .dropdown-link")

    if (!companyMenu || !companyMenuInner) return

    // unhides company menu components so we can calculate desired height
    companyMenu.classList.remove("hide")
    companyMenuInner.classList.remove("hide")
    const menuHeight = companyMenu.getBoundingClientRect().height

    // sets the height and then rehides the components
    companyMenu.style.height = `${menuHeight}px`
    companyMenu.classList.add("hide")
    companyMenuInner.classList.add("hide")
  }

  useEffect(() => {
    window.addEventListener("resize", determineNavAndMarginSizes)

    return () => {
      window.removeEventListener("resize", determineNavAndMarginSizes)
    }
  })

  const toggleNav = () => {
    // Switch class to the opposite of existing state
    if (getCookie("nav_open_state") === "true") {
      nav?.classList.add("shrink-nav")
    } else {
      nav?.classList.remove("shrink-nav")
    }
    determineNavAndMarginSizes()
  }

  return { toggleNav, nav }
}

export { useNavController }
