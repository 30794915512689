import React from "react"

function Responsive() {
  return (
    <div className="page-pad mx-auto max-w-6xl">
      <h1 className="mb-2">Responsive Design</h1>
      <p>
        Responsive design ensures Built adapts to various devices and screen sizes, enhancing
        accessibility and user experience. By seamlessly scaling and rearranging content, responsive
        design eliminates the need for users to pinch, zoom or scroll excessively, thus reducing
        frustration.
      </p>
      <p>
        In order to create consistency within the app across screen sizes, a handful of helper
        classes have been created. These helper classes combine multiple classes into one, reducing
        duplication and allows the size of spacing to adapt to different screen sizes.
      </p>

      <section className="page-margin-t">
        <h2>Page Padding</h2>
        <p className="my-2">
          Generally speaking, page padding and gap between blocks of content will fit the following
          guidelines:
        </p>
        <ul>
          <li>Under 640px/40rem, page padding/margin should be 12px/0.75rem.</li>
          <li>From 640px/40rem to 1120px/70rem, page padding/margin should be 24px/1.5rem.</li>
          <li>At 1120px/70rem and above, page padding/margin should be 32px/2rem.</li>
        </ul>

        <p className="page-margin-t mb-2">
          The following padding classes apply the above principles:
        </p>
        <div>
          <p>
            <code>page-pad</code>: applies padding to all 4 sides
          </p>
          <p>
            <code>page-pad-x</code>: applies left and right padding
          </p>
          <p>
            <code>page-pad-y</code>: applies top and bottom padding
          </p>
          <p>
            <code>page-pad-b</code>: applies bottom padding
          </p>
          <p>
            <code>page-pad-t</code>: applies top padding
          </p>
        </div>

        <p className="page-margin-t mb-2">
          The following margin classes apply the above principles:
        </p>
        <div>
          <p>
            <code>page-margin</code>: applies margin to all 4 sides
          </p>
          <p>
            <code>page-margin-x</code>: applies left and right margins
          </p>
          <p>
            <code>page-margin-y</code>: applies top and bottom margins
          </p>
          <p>
            <code>page-margin-b</code>: applies bottom margin
          </p>
          <p>
            <code>page-margin-t</code>: applies top margin
          </p>
        </div>

        <p className="page-margin-t mb-2">The following gap classes apply the above principles:</p>
        <div>
          <p>
            <code>page-gap</code>: applies both row and column gap
          </p>
        </div>
      </section>

      <section className="page-margin-t">
        <h2>Table Padding</h2>
        <p className="my-2">Generally speaking, table padding will fit the following guidelines:</p>
        <ul>
          <li>Under 640px/40rem, table padding/margin should be 0.</li>
          <li>From 640px/40rem to 1120px/70rem, table padding/margin should be 24px/1.5rem.</li>
          <li>At 1120px/70rem and above, table padding/margin should be 32px/2rem.</li>
        </ul>

        <p className="page-margin-t mb-2">
          The following padding classes apply the above principles:
        </p>
        <div>
          <p>
            <code>table-pad</code>: applies padding to all 4 sides
          </p>
          <p>
            <code>table-pad-x</code>: applies left and right padding
          </p>
          <p>
            <code>table-pad-b</code>: applies bottom padding
          </p>
        </div>
      </section>
    </div>
  )
}

export default Responsive
