import React from "react"
import { EntityId } from "@reduxjs/toolkit"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"

import { countFailedRequests } from "v2/redux/GraphqlApi/AdpChangeQueueApi"
import { FlattenedAdpChangeBatch } from "v2/redux/slices/AdpChangeSlice"

export type PausedWithErrorsModalContentProps = {
  batch: FlattenedAdpChangeBatch
}

export function PausedWithErrorsAlert({ batch }: PausedWithErrorsModalContentProps) {
  const { t } = useTranslation()

  return (
    <div className="alert alert-caution mb-6">
      <div className="flex">
        <div className="mr-2 text-base-bold">
          <FontAwesomeIcon icon={["far", "exclamation-triangle"]} />
        </div>
        <div className="flex-1">
          <div className="text-base-bold">
            {t("v2.adp.change_batches.paused_with_errors_title", {
              count: batch.changeRequests.length - countFailedRequests(batch),
              total: batch.changeRequests.length,
            })}
          </div>
          <p>{t("v2.adp.change_batches.paused_with_errors_continuation")}</p>
        </div>
      </div>
    </div>
  )
}

export type PausedWithErrorsModalFooterProps = {
  batch: FlattenedAdpChangeBatch
  isMutating: boolean
  onCopyErrorsToNewBatchPress: (batchId: EntityId) => void
  onIgnoreErrorsPress: (batchId: EntityId) => void
}

export function PausedWithErrorsModalFooter({
  batch,
  isMutating,
  onCopyErrorsToNewBatchPress,
  onIgnoreErrorsPress,
}: PausedWithErrorsModalFooterProps) {
  const { t } = useTranslation()

  const handleIgnoreErrorsPress = () => onIgnoreErrorsPress(batch.id)
  const handleCopyErrorsToNewBatchPress = () => onCopyErrorsToNewBatchPress(batch.id)

  return (
    <div className="items-center justify-end gap-2 flex">
      <button
        className="btn btn--secondary"
        disabled={isMutating}
        onClick={handleIgnoreErrorsPress}
        type="button"
      >
        {t("v2.adp.change_batches.paused_with_errors_buttons.ignore_errors")}
      </button>
      <button
        disabled={isMutating}
        className="btn btn--primary"
        onClick={handleCopyErrorsToNewBatchPress}
        type="button"
      >
        {t("v2.adp.change_batches.paused_with_errors_buttons.copy_changes")}
      </button>
    </div>
  )
}
