import React, { useEffect, useState } from "react"
import RootProvider from "v2/react/components/RootProvider"
import { ImportDropdownProps } from "v2/react/components/imports/ImportDropdown"
import { Banners } from "v2/react/components/orgChart/Banners"
import { OrgChartNav } from "v2/react/components/orgChart/Navigation/OrgChartNav"
import OrgChartUtilityNav from "v2/react/components/orgChart/Navigation/OrgChartUtilityNav"
import { SuperPanel } from "v2/react/components/orgChart/SuperPanel/SuperPanel"
import { useGetFeatureFlagsQuery } from "v2/redux/GraphqlApi"
import { useWindowSize } from "usehooks-ts"
import { FeatureFlags } from "types/graphql"

export const TOOLBAR_LARGE_SC_HEIGHT = 112
export const TOOLBAR_SMALL_SC_HEIGHT = 169

interface ApprovalProps {
  currentUser: {
    currentPersonId: number
    canConfigure: boolean
    isCollaborator: boolean
  }
  currentChart: {
    chartId: number
  }
}

export interface PeripheralsProps {
  abilities: {
    canAddPosition: boolean
    canCreateChart: boolean
    canExportChart: boolean
    canManageChart: boolean
    canManageOfficialChart: boolean
    canManageAdpChangeBatches: boolean
    canViewMetrics: boolean
  }
  approvalProps: ApprovalProps
  enableHistory: boolean
  importDropdownProps: ImportDropdownProps
  isOfficialChart: boolean
  sharePath: string
  renderedPositionIds?: number[]
  exportAsCSV?: (ids: number[], columns: string[]) => void
}

function PeripheralsInner({
  abilities,
  approvalProps,
  enableHistory,
  importDropdownProps,
  isOfficialChart,
  sharePath,
  renderedPositionIds,
  exportAsCSV,
}: PeripheralsProps) {
  const { width = 0 } = useWindowSize()
  const { data, isFetching } = useGetFeatureFlagsQuery()
  const [offsetHeight, setOffsetHeight] = useState(
    width < 768 ? TOOLBAR_SMALL_SC_HEIGHT : TOOLBAR_LARGE_SC_HEIGHT,
  )

  useEffect(() => {
    const alertTop = document.querySelector(".alert-top")?.getBoundingClientRect().height || 0
    const toolbarHeight =
      (width < 768 ? TOOLBAR_SMALL_SC_HEIGHT : TOOLBAR_LARGE_SC_HEIGHT) + alertTop
    setOffsetHeight(toolbarHeight)
  }, [width])

  if (!data || !data?.currentCompany?.featureFlags || isFetching) return null
  const ff: FeatureFlags = data.currentCompany.featureFlags

  const isListView = window.location.pathname.indexOf("lists/") > 0
  const showApprovalDisplay = !!(
    ff.positionManagement &&
    !isListView &&
    !isOfficialChart &&
    abilities.canManageChart
  )

  return (
    <>
      <div className="zOrgChartNav relative bg-white">
        <OrgChartNav
          abilities={abilities}
          approvalProps={approvalProps}
          enableHistory={enableHistory}
          ff={ff}
          importDropdownProps={importDropdownProps}
          isOfficialChart={isOfficialChart}
          sharePath={sharePath}
          renderedPositionIds={renderedPositionIds}
          exportAsCSV={exportAsCSV}
        />
        <OrgChartUtilityNav
          canAddPosition={abilities.canAddPosition}
          canManageChart={abilities.canManageChart}
          canViewMetrics={abilities.canViewMetrics}
          ff={ff}
        />
      </div>
      <div className="relative bg-white">
        <SuperPanel ff={ff} offsetHeight={offsetHeight} />
      </div>
      <div className="zOrgChartBanners relative">
        <Banners
          showApprovalDisplay={showApprovalDisplay}
          chartId={approvalProps.currentChart.chartId}
        />
      </div>
    </>
  )
}

function Peripherals({
  abilities,
  approvalProps,
  enableHistory,
  importDropdownProps,
  isOfficialChart,
  sharePath,
  renderedPositionIds,
  exportAsCSV,
}: PeripheralsProps) {
  return (
    <RootProvider>
      <PeripheralsInner
        abilities={abilities}
        approvalProps={approvalProps}
        enableHistory={enableHistory}
        isOfficialChart={isOfficialChart}
        importDropdownProps={importDropdownProps}
        sharePath={sharePath}
        renderedPositionIds={renderedPositionIds}
        exportAsCSV={exportAsCSV}
      />
    </RootProvider>
  )
}

export { Peripherals }
