import React, { useState, useMemo } from "react"
import fp from "lodash/fp"

import { FollowUpModalProps } from "v2/react/components/orgChart/OrgChartDatasheet/FollowUpModals"

import { useAppSelector } from "v2/redux/store"
import { useDatasheetListenerActions } from "v2/redux/listeners/datasheetListeners"
import { useFetchCurrentCompensationOfNodeQuery } from "v2/redux/slices/NodeSlice/NodeApi"
import { useUpdateNodeWithMultipleInputs } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/hooks/useUpdateNodeWithMultipleInputs"
import {
  prepareErrors,
  errorMessagesFor,
  useErrors,
  ErrorsType,
} from "v2/react/components/orgChart/OrgChartDatasheet/Modals/errors"
import { InputFields } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/types"
import {
  getFieldType,
  getCompensationData,
  maybeAddErrorsToVariablePays,
  maybeAddErrorsToBasePay,
  getModalCopy,
  getChanges,
} from "v2/react/components/orgChart/OrgChartDatasheet/Modals/CompensationFollowUp/helpers"
import { processInputs } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/CompensationFollowUp/dataProcessing"

import { AsyncModal } from "v2/react/shared/Modal/AsyncModal"
import { CompensationTable } from "v2/react/shared/CompensationTable"
import { DateInput } from "v2/react/shared/Inputs/DateInput"
import { SaveFooter } from "v2/react/shared/Modal/SaveFooter"
import { ErrorFallback } from "v2/react/shared/Modal/ErrorFallback"

type CompensationFieldType =
  | "basePayType"
  | "basePayAmount"
  | "hoursPerWeek"
  | "variablePayType"
  | "variablePayAmount"

const CompensationFollowUp = ({ isOpen, field, row }: FollowUpModalProps) => {
  const { cancelFollowUp: handleClose, saveModalSuccess } = useDatasheetListenerActions()
  const [isSaving, setIsSaving] = useState(false)
  const { errors, setErrors, clearErrors } = useErrors()

  const [updateAttributesForNode] = useUpdateNodeWithMultipleInputs()

  const chartKey = useAppSelector((state) => state.container.containerKey)
  if (!chartKey) {
    throw new Error("chartKey is not defined")
  }

  const {
    data: queryData,
    isLoading,
    isError,
  } = useFetchCurrentCompensationOfNodeQuery({
    chartKey,
    id: row.id,
  })

  const { fieldKey, label: fieldLabel, value } = field

  const fieldType = useMemo(() => getFieldType(fieldKey), [fieldKey])

  const changes = useMemo(
    () => getChanges({ fieldType, fieldKey, row, value }),
    [fieldType, fieldKey, row, value],
  )

  const { title, description } = useMemo(
    () =>
      getModalCopy({
        fieldType,
        label: fieldLabel,
        changes,
        personName: row.data.name || "Unknown Person".t("org_chart"),
      }),
    [fieldType, fieldLabel, changes, row.data.name],
  )

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsSaving(true)
    e.preventDefault()

    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const formDataEntries = Array.from(formData.entries())
    const processedInputs = processInputs(formDataEntries)

    // Validate the inputs before saving
    const validationErrors = validateAndReturnErrors(
      processedInputs.filter((input) =>
        fp.includes(input.fieldKey)(["base_pay", "hours_per_week"]),
      ) as InputFields[],
    )
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      setIsSaving(false)
      return
    }

    const response = await updateAttributesForNode(row.data, processedInputs, fieldKey)

    if (response?.ok) {
      saveModalSuccess()
    } else {
      const errors = response?.errors
      setErrors(errors ? prepareErrors(errors) : {})
      setIsSaving(false)
    }
  }

  const { variablePays, basePay } = useMemo(
    () => getCompensationData({ result: queryData, changes, fieldType, fieldKey }),
    [queryData, changes, fieldType, fieldKey],
  )

  return (
    <AsyncModal
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      isLoading={isLoading}
      size="md"
      shouldReturnFocusAfterClose={false}
    >
      <ErrorFallback isError={isError}>
        <form onSubmit={handleSave}>
          <div className="react-modal__body">
            <div className="mb-4 cursor-default">{description}</div>
            <div>
              <DateInput
                name="pay_effective_date"
                label={"Effective Date".t("profile", "compensation")}
                focusOnMount
                wrapperClassName="mb-4"
                errors={errorMessagesFor("pay_effective_date", errors)}
              />
              <CompensationTable
                headerLabel={"Compensation".t("profile")}
                basePay={maybeAddErrorsToBasePay(basePay, errors)}
                variablePays={maybeAddErrorsToVariablePays(variablePays, errors) || []}
                clearErrors={clearErrors}
              />
            </div>
          </div>
          <SaveFooter isSaving={isSaving} />
        </form>
      </ErrorFallback>
    </AsyncModal>
  )
}

const validateAndReturnErrors = (inputs: InputFields[]) =>
  inputs.reduce((acc, input) => {
    if (input.fieldKey && !input.value?.trim()) {
      const fieldKey = input.fieldKey
      acc[fieldKey] = "blank_validation".t("defaults")
    }
    return acc
  }, {} as ErrorsType)

export { CompensationFollowUp, CompensationFieldType }
