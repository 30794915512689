import get from "lodash/get"

export const TextColors = Object.freeze({
  darkText: {
    extraLight: "rgba(012, 020, 075, 0.08)",
    extraLight20: "rgba(012, 020, 075, 0.20)",
    light: "rgba(012, 020, 075, 0.64)",
    medium: "rgba(012, 020, 075, 0.80)",
    solid: "rgba(012, 020, 075, 1)",
  },

  lightText: {
    extraLight: "rgba(255, 255, 255, 0.08)",
    extraLight20: "rgba(255, 255, 255, 0.20)",
    light: "rgba(255, 255, 255, 0.64)",
    medium: "rgba(255, 255, 255, 0.80)",
    solid: "rgba(255, 255, 255, 1)",
  },
})

/** @private */
const getColorDomainValue = (node, key) => get(node.color_code_domain_values || {}, [key])

/** @private */
function codeColor(node, field, idByName, colorCodes, fallback) {
  const colorDomainValue = getColorDomainValue(node, field, idByName)
  return get(colorCodes, [field, colorDomainValue, "color"], fallback)
}

/**
 * Codes `node` into a card color; if coding fails, or is disabled, returns
 * `fallback`.
 * @param {object} state Current state snapshot.
 * @param {object} node The position/person/list person to color code.
 * @param {string?} fallback Optional fallback color to use.
 * @return {string?} The resulting color; otherwise `fallback`.
 */
function codeCardColor(state, node, fallback = null) {
  if (!state.isCardColorEnabled || !node) return fallback

  return codeColor(node, state.cardColorField, state.cardColorIdsByName, state.cardColors, fallback)
}

/**
 * Codes `node` into an edge color; if coding fails, or is disabled, returns
 * `fallback`.
 *
 * @param {object} state Current state snapshot.
 * @param {object} node The position/person/list person to color code.
 * @param {string?} fallback Optional fallback color to use.
 * @return {string?} The resulting color; otherwise `fallback`.
 */
function codeEdgeColor(state, node, fallback = null) {
  if (!state.isEdgeColorEnabled || !node) return fallback

  return codeColor(node, state.edgeColorField, state.edgeColorIdsByName, state.edgeColors, fallback)
}

/**
 * Returns either "darkText" or "lightText", depending on the perceptive
 * luminance of the color coding color.
 */
function codeTextColorDomain(color) {
  // converting hex to rgb
  const test = color.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i)
  const r = parseInt(test[1], 16)
  const g = parseInt(test[2], 16)
  const b = parseInt(test[3], 16)

  // Counting the perceptive luminance
  // human eye favors green color...
  const alpha = (0.299 * r + 0.587 * g + 0.114 * b) / 255
  const baseColorDomain = alpha > 0.5 ? "darkText" : "lightText"

  return baseColorDomain
}

/**
 * Codes `node` into a text color _based on its card color_; otherwise returns
 * `fallback`.
 *
 * @param {object} state Current state snapshot.
 * @param {object} node The position/person/list person to color code.
 * @param {string?} fallback Optional fallback color to use.
 * @param {"light" | "medium" | "solid" | null} opacityVariant Optional opacity variant to use
 * @return {string?} The resulting color; otherwise `fallback`.
 */
function codeTextColor(
  state,
  node,
  fallback = TextColors.darkText.solid,
  opacityVariant = "solid",
) {
  const color = codeCardColor(state, node)
  if (!color) return fallback
  const baseColorDomain = codeTextColorDomain(color)

  if (opacityVariant) {
    return TextColors[baseColorDomain][opacityVariant]
  }

  return TextColors[baseColorDomain].solid
}

const ColorCoder = Object.freeze({
  codeCardColor,
  codeEdgeColor,
  codeTextColor,
})

export default ColorCoder

export { codeCardColor, codeEdgeColor, codeTextColor, ColorCoder, codeTextColorDomain }
