import React, { ReactNode } from "react"
import { Pulser } from "v2/react/shared/Pulser"

interface Props {
  children: ReactNode
  title: string
  isFetching?: boolean
}

function Section({ children, title, isFetching }: Props) {
  return (
    <div className="relative min-h-[13.75rem]">
      <div className="gap-2 flex">
        <h3 className="pb-4 text-base-bold">{title}</h3>
        {isFetching && <Pulser />}
      </div>

      <div>{children}</div>
    </div>
  )
}

export { Section }

export const GridColumnWrapper = ({ children }: { children: ReactNode }) => (
  <div className="page-gap grid-cols-1 grid sm:grid-cols-2 md:grid-cols-3">{children}</div>
)
