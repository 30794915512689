import React, { ReactNode } from "react"
import cn from "classnames"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import { IntegrationFlags, Position, VariablePay } from "types/graphql"
import { UrlHelper } from "v2/react/utils/urls"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { EmptyState } from "v2/react/components/positionTypes/Show/EmptyState"
import { SmallEditButton } from "v2/react/components/positionTypes/Show/SmallEditButton"

interface Abilities {
  canManageCompensation: boolean
  canReadCompensation: boolean
}

interface Props {
  abilities: Abilities
  integrationFlags: IntegrationFlags
  position: Position
}

const Budget = ({ abilities, integrationFlags, position }: Props) => {
  const { t } = useTranslation()

  return (
    <div className="module-card">
      <div className="module-card__header">
        <div className="module-title">{t("v2.positions.show.budget")}</div>
        {!isBudgetEmpty(position, integrationFlags) ? (
          <SmallEditButton
            disabled={!abilities.canManageCompensation}
            disabledTooltipText={t("v2.positions.show.edit_disabled")}
            onClick={() =>
              pjaxModalFor(UrlHelper.editPositionPath(position.id, "?selected_tab=planning"))
            }
          />
        ) : null}
      </div>
      <div
        className={cn("module-card__body border-0 p-0 pb-4", {
          "border-0": position.positionPayAnnualized,
        })}
      >
        {!isBudgetEmpty(position, integrationFlags) ? (
          <>
            <PayGrade position={position} integrationFlags={integrationFlags} t={t} />
            <BasePay position={position} t={t} />
            {(position.variablePays || []).map((variablePay: VariablePay) => (
              <VariablePayRow key={variablePay.field_id} variablePay={variablePay} />
            ))}
            <TotalCompensation position={position} t={t} />
          </>
        ) : (
          <EmptyState
            buttonText={t("v2.positions.show.add_budget_description_cta")}
            icon="usd-square"
            note={t("v2.positions.show.add_budget_description_note")}
            onClick={() =>
              pjaxModalFor(UrlHelper.editPositionPath(position.id, "?selected_tab=planning"))
            }
            showButton={abilities.canManageCompensation}
          />
        )}
      </div>
    </div>
  )
}

const isBudgetEmpty = (position: Position, integrationFlags: IntegrationFlags): boolean => {
  if (integrationFlags.supportsPayGrades) {
    const payGrade = position.positionType?.payGrade
    return !payGrade?.codeNameLabel && !position.positionPayAnnualized
  }

  return !position.positionPayAnnualized
}

const BasePay = ({ position, t }: { position: Position; t: TFunction }) => {
  const hours = position.positionHoursPerWeek || 0
  const payTypeId = position.budgetedBasePayType.id

  return (
    <BudgetRow
      label={t("v2.positions.show.budgeted_base_pay")}
      value={
        position.positionBasePayFormatted
          ? basePayLabel(payTypeId, position.positionBasePayFormatted, hours, t)
          : "-"
      }
    />
  )
}

const PayGrade = ({
  integrationFlags,
  position,
  t,
}: {
  integrationFlags: IntegrationFlags
  position: Position
  t: TFunction
}) => {
  if (!integrationFlags.supportsPayGrades) return null
  return (
    <BudgetRow
      label={t("v2.positions.show.pay_grade")}
      value={
        <div className="tw-grid tw-gap-0.5">
          <p>{position.positionType?.payGrade?.codeNameLabel || "-"}</p>
        </div>
      }
    />
  )
}

const VariablePayRow = ({ variablePay }: { variablePay: VariablePay }) => (
  <BudgetRow label={variablePay.name} value={variablePay.formatted_value} />
)

const TotalCompensation = ({ position, t }: { position: Position; t: TFunction }) => (
  <BudgetRow
    label={t("v2.positions.show.total_annual_budget")}
    value={position.positionPayAnnualizedFormatted || "-"}
  />
)

const BudgetRow = ({
  label,
  value,
}: {
  label?: string | null
  value?: string | ReactNode | null
}) => (
  <div className="module-card-list-item items-center justify-between border-t-neutral-8 px-4 pt-4 flex">
    <div className="flex-1 whitespace-pre-wrap">{label || ""}</div>
    <div className="flex-1 text-right">{value || "-"}</div>
  </div>
)

const basePayLabel = (
  basePayTypeId: string,
  basePayFormatted: string,
  hours: number | null,
  t: TFunction,
): string => {
  if (basePayTypeId === "position_base_pay_type_hourly") {
    const typeLabel = t("v2.positions.show.pay_type_hourly")
    const typeRateLabel = t("v2.positions.show.pay_type_hourly_per_week")
    if (basePayFormatted === "") return `- (${typeLabel})`
    return `${basePayFormatted} (${typeLabel}), ${hours || "0"} ${typeRateLabel}`
  }
  const typeLabel = t("v2.positions.show.pay_type_salary")
  if (basePayFormatted === "") return `- (${typeLabel})`
  return `${basePayFormatted} (${typeLabel})`
}

export { Budget }
