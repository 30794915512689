import React, { KeyboardEvent, useState } from "react"
import { ControlProps } from "@jsonforms/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NodeInterface } from "types/graphql"
import classNames from "classnames"
import { useJsonForms } from "@jsonforms/react"

import {
  addNoneOptionToMatchedCollections,
  buildCollectionsQueryFromKeys,
  getStaticCollectionFieldsFromFieldKeys,
} from "v2/redux/slices/GridSlice/gridHelpers/collections"
import { DropdownMenu } from "v2/react/shared/DropdownMenu"
import { FormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/FormShape"
import { useFetchCollectionsQuery } from "v2/redux/GraphqlApi"
import { useSelectList } from "v2/react/hooks/useSelectList"

import { isDisabledFromSchema } from "v2/react/shared/JsonFormControls/utils/forms"
import { InputErrorText } from "./InputErrorText"
import { compensationLabelPrefix } from "./utils/compensation"
import { useValidation } from "./hooks/useValidation"

interface Option {
  id: string
  label: string
}

type SelectControlProps = Pick<
  ControlProps,
  "config" | "data" | "enabled" | "handleChange" | "id" | "label" | "path" | "schema" | "uischema"
>

const JsonSelectInput = ({
  config,
  data,
  enabled,
  handleChange,
  id,
  label,
  path,
  schema,
  uischema,
}: SelectControlProps) => {
  const jsonFormsContext = useJsonForms()
  const formData = jsonFormsContext?.core?.data
  const isDisabled = isDisabledFromSchema({ enabled, schema })

  const fieldKey: keyof NodeInterface = uischema.options?.fieldKey
  let collectionField = getStaticCollectionFieldsFromFieldKeys([fieldKey])
  // Presently variable_pay_types field does not exist on the NodeInterface
  if ((fieldKey as string) === "variable_pay_types") collectionField = ["variablePayPayTypes"]
  const { data: collections } = useFetchCollectionsQuery(
    buildCollectionsQueryFromKeys(collectionField),
    {
      skip: collectionField.length === 0,
    },
  )

  const collectionResult: Option[] =
    (collections && addNoneOptionToMatchedCollections(collections)[0].options?.nodes) || []
  const selectedItem = collectionResult.find((option) => option.id === data?.id)

  const { showError, errorMessage } = useValidation({
    data,
    path,
    schema,
    submitting: config.submitting,
  })
  const [showResultList, setShowResultList] = useState(false)
  const {
    activeIndex,
    context,
    floatingStyles,
    getFloatingProps,
    getItemProps,
    getReferenceProps,
    listRef,
    refs,
    setActiveIndex,
  } = useSelectList({ showList: showResultList, setShowList: setShowResultList })

  const handleOpen = () => {
    refs.domReference.current?.focus()
    setActiveIndex(showResultList ? null : 0)
  }

  const handleResultClick = (value: Option) => {
    setActiveIndex(null)
    setShowResultList(false)
    handleChange(path, { name: value.label, id: value.id })
  }

  const closingList = (key: string) => key === "Escape" && showResultList
  const togglingInput = (key: string) => key === "Enter" && activeIndex === null && showResultList

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      (closingList(event.key) || togglingInput(event.key)) &&
      activeIndex !== null &&
      collectionResult[activeIndex]
    ) {
      handleResultClick(collectionResult[activeIndex])
    }

    if (event.key === "Enter" && activeIndex != null && collectionResult[activeIndex]) {
      handleResultClick(collectionResult[activeIndex])
    }
  }

  return (
    <div className="form-dropdown input-group">
      {/* Assumption: We use either a label or sublabel, not both */}
      {label && !uischema.options?.subLabel && (
        <label className="cursor-default" htmlFor={id}>
          {labelWithPrefix(formData, id, label)}
        </label>
      )}
      <div
        className={classNames("form-dropdown__dropdown-link gap-2", {
          readonly: isDisabled,
        })}
        ref={refs.setReference}
        role="button"
        tabIndex={0}
        /* eslint-disable react/jsx-props-no-spreading */
        {...getReferenceProps({
          onClick: handleOpen,
          onKeyDown: handleKeyDown,
        })}
      >
        {selectedItem && (
          <div className="form-dropdown__selected-text">{selectedItem.label || ""}</div>
        )}
        <FontAwesomeIcon icon="caret-down" />
      </div>
      <InputErrorText message={errorMessage} showError={showError} />
      <DropdownMenu
        context={context}
        floatingProps={getFloatingProps}
        floatingRef={refs.setFloating}
        floatingStyles={floatingStyles}
        showList={showResultList && !isDisabled}
        wrapperClasses="AutocompleteField"
      >
        {collectionResult && collectionResult.length > 0
          ? collectionResult.map(
              (option, index) =>
                option && (
                  <div
                    aria-selected={activeIndex === index}
                    className={textClassName(activeIndex, index)}
                    key={option.id}
                    ref={(node) => {
                      listRef.current[index] = node
                    }}
                    role="option"
                    /* eslint-disable react/jsx-props-no-spreading */
                    {...getItemProps({
                      onClick: () => handleResultClick(option),
                      onKeyDown: handleKeyDown,
                    })}
                  >
                    <div className="AutocompleteField__result-text truncate">{option.label}</div>
                  </div>
                ),
            )
          : null}
      </DropdownMenu>
    </div>
  )
}

export { JsonSelectInput }

const labelWithPrefix = (formData: FormShape, id: string, label?: string | null): string => {
  if (!label) return ""
  if (id !== "#/properties/position/properties/basePay/properties/payType") return label
  return `${compensationLabelPrefix(formData)} ${label}`
}

const textClassName = (activeIndex: number | null, index: number) =>
  classNames("SelectField__result", {
    highlight: activeIndex === index,
  })
