import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import {
  fieldsSelectors,
  subscribedField,
  unsubscribedField,
} from "v2/redux/slices/FieldSuggestionSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

import {
  FieldSuggestion,
  RecordWithSuggestions,
  StateChangeCallbackFn,
  StateValue,
  SuggestionErrorsArg,
} from "./types"
import {
  isEphemeralState,
  isRegenerateFailed,
  isStringSuggestion,
  isTagsSuggestion,
} from "./fieldSuggestionHelpers"

const NONE: StateValue = "none"

type StateChangeCallbackArg<SuggestionType extends FieldSuggestion, ValueType> = {
  callbackFn: StateChangeCallbackFn<SuggestionType, ValueType> | undefined
  fallback: ValueType
  initiallySuggested: ValueType | null
  suggested: ValueType | null
  suggestion: SuggestionType | null
  targetStates: StateValue[] | undefined
}

/**
 * Captures/tracks the result of `getActual`.
 */
export function useActual<Value, Record extends RecordWithSuggestions = RecordWithSuggestions>(
  record: Record,
  field: string,
  getActual: (record: Record, field: string) => Value | null,
) {
  // Purposely omits `getActual` from deps since we don't care/want it to
  // trigger a state change.
  //
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => getActual(record, field), [record, field])
}

/**
 * Proof of concept for streaming updates to field suggestions via socket.
 * Besides some general clean up, the key to-dos left would be:
 *
 * 1. Provide mechanism to clear event log. E.g. clear after reloading the
 *    entire position type via GQL. There's a temporary patch in place in
 *    the NotificationSlice that probably isn't ideal for the long term.
 * 2. Properly type `useEntityEventLog` and the events it holds. Right now this
 *    just uses `any` in in the interest of time.
 * 3. Need to double check that multiple calls to `useEntityEventLog` isn't
 *    opening unnecessary cable connections.
 *
 * This hook works by simply merging the partial "suggestion_changed" hashes on
 * top of the matching field suggestion (that we got when we last loaded the
 * position type).
 */
export function useFieldSuggestionWithAppliedEvents<T extends FieldSuggestion>(
  suggestion: T | undefined,
  entityId: string,
  withEphemeralEventsApplied?: boolean,
): T | null {
  // Subscribe the component to events from the server. This is how we're
  // connecting to the socket.
  const dispatch = useAppDispatch()
  const extraFromServer = useAppSelector((state) =>
    fieldsSelectors.selectById(state, `${entityId}-${suggestion?.field}`),
  )
  useEffect(() => {
    const id = suggestion?.field ? `${entityId}-${suggestion?.field}` : undefined
    if (suggestion && id) {
      const { state, field, hasInitialized, isAwaitingAction } = suggestion
      const common = { id, entityId, field, state, hasInitialized, isAwaitingAction }

      if (isStringSuggestion(suggestion))
        dispatch(subscribedField({ ...common, suggestion, value: null, type: "string" }))
      if (isTagsSuggestion(suggestion))
        dispatch(subscribedField({ ...common, suggestion, value: null, type: "tags" }))
    }

    return () => {
      if (id) dispatch(unsubscribedField(id))
    }
  }, [dispatch, entityId, suggestion])

  // Store a snapshot of the suggestion we've gotten from the server and update
  // it when relevant events come across the wire.
  const [snapshot, setSnapshot] = useState<T | null>(suggestion ?? null)
  useEffect(() => {
    setSnapshot(suggestion ?? null)
  }, [setSnapshot, suggestion])
  useEffect(() => {
    setSnapshot((current) => {
      // Ignore the event if the caller doesn't want to track ephemeral events.
      if (!withEphemeralEventsApplied && isEphemeralState(extraFromServer?.state)) {
        return current
      }

      if (current && isTagsSuggestion(current) && extraFromServer?.type === "tags") {
        return {
          ...current,
          tags: extraFromServer.value ?? current.tags,
          initializedTags: extraFromServer.initializedValue ?? current.initializedTags,
          state: extraFromServer.state ?? current.state,
          isAwaitingAction: extraFromServer?.isAwaitingAction ?? current.isAwaitingAction,
        }
      }

      if (current && isStringSuggestion(current) && extraFromServer?.type === "string") {
        const { stringValue, initializedStringValue } = current

        return {
          ...current,
          stringValue: extraFromServer.value ?? stringValue,
          initializedStringValue: extraFromServer.initializedValue ?? initializedStringValue,
          state: extraFromServer.state ?? current.state,
          isAwaitingAction: extraFromServer?.isAwaitingAction ?? current.isAwaitingAction,
        }
      }

      return current
    })
  }, [extraFromServer, setSnapshot, withEphemeralEventsApplied])

  return snapshot
}

/**
 * Manages invoking a caller supplied callback when the field suggestion
 * changes state. The goal is to provide the caller an easy way to update
 * component state due to a transition.
 */
export function useStateChangeCallbackEffect<SuggestionType extends FieldSuggestion, ValueType>({
  callbackFn,
  fallback,
  initiallySuggested,
  suggested,
  suggestion,
  targetStates,
}: StateChangeCallbackArg<SuggestionType, ValueType>) {
  // Only update the ref while in the `useEffect` so we can detect the prior
  // state in the effect and pass it to the callback.
  const suggestionState = suggestion?.state ?? NONE
  const priorStateRef = useRef<StateValue>(suggestionState)

  // We only want a change in `suggestionState` to trigger an effect here.
  // Everything else should be considered "constant" (even if its reference
  // might change from render-to-render, e.g. `callbackFn`).
  useEffect(() => {
    const { current: priorState } = priorStateRef

    const stateChanged = priorState !== suggestionState
    const hasCallbackFn = !!callbackFn
    const changedToTargetState = stateChanged && targetStates?.includes(suggestionState)

    if (changedToTargetState && hasCallbackFn) {
      callbackFn({ fallback, initiallySuggested, priorState, suggested, suggestion })
    }

    priorStateRef.current = suggestionState
  }, [suggestionState]) // eslint-disable-line react-hooks/exhaustive-deps
}

/**
 * Adds errors related to interacting with field suggestions.
 *
 * Currently only wired to handle "generate" errors for now, since we want that
 * to interplay nicely with update mutation calls. This could be expanded to
 * handle "initialize" errors, too.
 *
 * At present, this re-uses an existing (and translated) message. It's possible
 * we could rework this to support tailored errors and/or adding more than one
 * error.
 */
export function useSuggestionErrorsEffect({
  addGenerateError,
  uniqueKey,
  field,
}: SuggestionErrorsArg) {
  const { t } = useTranslation()
  const currentState = useAppSelector(
    (state) => fieldsSelectors.selectById(state, `${uniqueKey}-${field}`)?.state ?? "NONE",
  )

  useEffect(() => {
    if (isRegenerateFailed(currentState) && addGenerateError) {
      addGenerateError(t("v2.position_types.show.unable_to_generate"))
    }
  }, [addGenerateError, currentState, t])
}
