import fp from "lodash/fp"
import { EntityId } from "@reduxjs/toolkit"

import { Person, Position } from "types/graphql"

type OrgChartNode = { attributes: OrgChartNodeAttributes } | OrgChartNodeAttributes
type OrgChartNodeAttributes = {
  children?: OrgChartNode[] | null
  _children?: OrgChartNode[] | null
  parent_id?: number | null
  id: number
  x: number
}
type OrgChartNodeProp = keyof OrgChartNodeAttributes

/**
 * Transforms the list of person-position nodes into a sorted list based on
 * their x coordinate on the chart.
 *
 * @public
 */
export function constructSiblingsNavigationList<T extends Position | Person>(
  activePosition: Position,
  siblings: T[],
  toPositionId: (node: T) => EntityId,
) {
  const { resolvePosition, preload } = makeLookup()
  const current = resolvePosition(activePosition.id)

  // When we can, we use the parent's collection of children to avoid using the
  // chart's `find` function. This can be significantly faster since the lookup
  // is constant and the nodes have x coordinates loaded.
  const parentId = orgChartNodeProp("parent_id", current)
  if (parentId) {
    const parent = resolvePosition(parentId)
    preload(orgChartNodeProp("children", parent))
    preload(orgChartNodeProp("_children", parent))
  }

  const xProp = (node: T) => orgChartNodeProp("x", resolvePosition(toPositionId(node)))
  return fp.sortBy(xProp, siblings)
}

const orgChartNodeProp = <Key extends OrgChartNodeProp>(
  key: Key,
  node: OrgChartNode | undefined,
): OrgChartNodeAttributes[Key] | undefined => {
  if (!node) return undefined
  return "attributes" in node ? node.attributes[key] : node[key]
}

const makeLookup = () => {
  const lookup: { [key in number]: OrgChartNode } = {}

  const preload = (arr: OrgChartNode[] | undefined | null) =>
    (arr ?? []).forEach((node) => {
      const id = orgChartNodeProp("id", node)
      if (id && !lookup[id]) lookup[id] = node
    })

  const resolvePosition = (id: string | number) => {
    const numericId = Number(id)
    if (!(numericId in lookup)) {
      const node = window.App.OrgChart.getNodeByPosition(numericId)
      lookup[numericId] = node
    }
    return lookup[numericId]
  }

  return { resolvePosition, preload }
}
